<template>
  <base-side-nav :class="{ closed: sideNavClosed }">
    <template v-slot:toolbar />
    <template v-slot:side-nav-content>
      <side-nav-toolbar @toggle="toggleSideNav">
        <template v-slot:return-link>
          <router-link class="nav-link no-scroll" title="Return to previous page" :aria-label="$t('control_that_returns_to_previous_page')" :to="{ name: 'administration' }">
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width />
            <span class="sr-only">
              Return to previous page
            </span>
          </router-link>
        </template>
      </side-nav-toolbar>
      <div class="side-nav-container">
        <div class="nav-block-wrap">
          <user-links/>
          <hr>
          <ul class="nav nav-block flex-column active" v-if="checkAllowed('/services/ctr/integration_log', 'GET')">
            <li class="nav-item">
              <router-link class="nav-link open" :to="{ name: 'ctrIntegration' }">
                <font-awesome-icon :icon="['fas', (show ? 'chevron-down' : 'chevron-right')]" class="nav-caret" fixed-width @click.prevent="toggleNav" />  
                CTR Integration
              </router-link>
            </li>
            <ul class="nav flex-column"  v-if="show">
              <li class="nav-item" @click="clickToggle">
                <router-link class="nav-link" :to="{ hash: '#administration-ctr' }">
                  CTR Event Log
                </router-link>
              </li>
            </ul>
          </ul>
        </div>
      </div>
    </template>
  </base-side-nav>
</template>

<i18n src="@/components/_locales/common.json"></i18n>

<script lang="ts">
import { State, Getter } from 'vuex-class';
import BaseSideNav from '@/components/shared/side-nav/BaseSideNav.vue';
import SideNavToolbar from '@/components/shared/side-nav/SideNavToolbar.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import UserLinks from '@/components/administration/side-nav/UserLinks.vue';

@Component({
  components: {
    BaseSideNav,
    SideNavToolbar,
    UserLinks
  }
})
export default class SideNavCtr extends Vue {
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;

  public sideNavClosed = false;
  public show = true;

  public toggleNav() {
    this.show = !this.show;
  }

  /**
   * Boolean used to show the side menu, if true side-nav is open
   * Changes the 'closed' class on the nav
   *
   * @returns {void} changes the sideNavClosed boolean
   */
  public toggleSideNav(): void {
    this.sideNavClosed = !this.sideNavClosed;
  }

  public clickToggle(): void {
    if (window.innerWidth < 992) { 
       this.sideNavClosed = !this.sideNavClosed;
    }
  }
     
}
</script>

