<template>
  <card-section
    section-id="transportation-and-mobility"
    :isAccordion="prototypeFeatureEnabled('proto_ability_to_expand_collapse')"
  >
    <template v-slot:header>
      {{ title }}
    </template>
    <template v-slot:body>
      <div class="row">
        <div class="col-sm-12">
          <sub-section
            :title='title'
            sub-section-id="transportation-and-mobility"
          >
            <template v-slot:contents>

              <div class="row">

                <div class="col-sm-12 considerations-section">
                  <fieldset>
                    <div class="row">
                      <div class="standard-form-group form-group-checkbox">
                        <label>Patient has own transportation?</label>
                        <div class="row">
                          <div class="col-12">
                            <checkbox-input name="interpreter-needed" hideLabel="true" labelName="has-transportation-true"
                              inputId="has-transportation-true" v-model="hasTransportationTrue" label='Yes' :disabled="hasTransportationFalse"/>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <checkbox-input name="interpreter-needed" hideLabel="true" labelName="has-transportation-false"
                              inputId="has-transportation-false" v-model="hasTransportationFalse" label='No' :disabled="hasTransportationTrue"/>
                          </div>
                        </div>
                      </div>
                      <div class="standard-form-group form-group-checkbox">
                        <label>Flags</label>
                        <div class="row">
                          <div class="col-12">
                            <checkbox-input name="avoid-cities" hideLabel="true" labelName="Avoid cities" inputId="avoid-cities"
                              v-model="avoidCities" label='Avoid cities' />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <checkbox-input name="avoid-highways" hideLabel="true" labelName="Avoid highways" inputId="highways"
                              v-model="avoidHighways" label='Avoid highways' />
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-12">
                            <checkbox-input name="gas-money-sensitivity" hideLabel="true" labelName="Gas money sensitivity"
                              inputId="gas-money-sensitivity" v-model="gasMoneySensitivity" label='Gas money sensitivity' />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <checkbox-input name="mobility-challenges" hideLabel="true" labelName="Mobility challenges"
                              inputId="mobility-challenges" v-model="mobilityChallenges" label='Mobility challenges' />
                          </div>
                        </div>
                      </div>
                      <div class="standard-form-group-large">
                        <div class="row">
                          <div class="col-6">
                            <number-input inputId="distance-miles" name="Max Distance (miles)" v-model="maxMiles" />

                          </div>
                          <div class="col-6">
                            <number-input inputId="max-time" name="Max Time (minutes)" v-model="maxMinuites" />

                          </div>
                        </div>
                      </div>
     
                      <div class="standard-form-group-large form-group-checkbox">
                        <label>Preferred Transportation Methods</label>
                        <div class="row">
                          <div class="col-6 mb-2">
                            <checkbox-input name="method-car" hideLabel="true" labelName="Car" inputId="method-car"
                              v-model="methodCar" label='Car' />
                          </div>
                          <div class="col-6 align-vertical">
                            <text-input inputId="car-details" name="car-details" hideLabel="true" v-model="carDetails" />
                          </div>
                          <div class="col-6 mb-2">
                            <checkbox-input name="method-public" hideLabel="true" labelName="Public Transportation"
                              inputId="method-public" v-model="methodPublic" label='Public Transportation' />
                          </div>
                          <div class="col-6 align-vertical">
                            <text-input inputId="public-details" name="public-details" hideLabel="true" v-model="publicDetails" />
                          </div>
                          <div class="col-6 mb-2">
                            <checkbox-input name="method-ride" hideLabel="true" labelName="Ride Share" inputId="method-ride"
                              v-model="methodRide" label='Ride Share' />
                          </div>
                          <div class="col-6 align-vertical">
                            <text-input inputId="ride-details" name="ride-details" hideLabel="true" v-model="rideDetails" />
                          </div>
                          <div class="col-6 mb-2">
                            <checkbox-input name="method-medicaid" hideLabel="true" labelName="Medicaid Transportation"
                              inputId="method-medicaid" v-model="methodMedicaid" label='Medicaid Transportation' />
                          </div>
                          <div class="col-6 align-vertical">
                            <text-input inputId="medicaid-details" name="medicaid-details" hideLabel="true"
                              v-model="medicaidDetails" />
                          </div>
                          <div class="col-6 mb-2">
                            <checkbox-input name="method-other" hideLabel="true" labelName="Other" inputId="method-other"
                              v-model="methodOther" label='Car' />
                          </div>
                          <div class="col-6 align-vertical">
                            <text-input inputId="other-details" name="other-details" hideLabel="true" v-model="otherDetails" />
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="standard-form-group-3-quarters">
                        <text-area-input input-id="additional-information" name="Mobility Impairments and Devices"
                          rows="4"
                          :showToolTip="true"
                          toolTipText="Describe the patient's mobility impairments and devices and how they impact transportation and scheduling."
                          v-model="mobilityInpairmentsDevices" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="standard-form-group-3-quarters">
                        <text-area-input input-id="inpairments-devices" name="Comments"
                          rows="4"
                          v-model="comments" />
                      </div>
                    </div>
                  
                    <save-toolbar
                      class="save-toolbar action-row temp-saving d-flex"
                      buttonClass="btn btn-success sec-submit"
                      :label="`Save ${title}`"
                      @save="performSave()"  
                      showCancelButton="true" 
                      cancelButtonText="Cancel" 
                      @cancel="clearForm()"
                    />
                  </fieldset>

                </div>
              </div>
            </template>
          </sub-section>
        </div>
      </div>
        
    </template>

  </card-section>
</template>


<script lang="ts">
  import {
    Component,
    Vue, Prop
  } from 'vue-property-decorator';
  import SaveToolbar from '@/components/shared/SaveToolbar.vue';
  import SelectInput from '@/components/shared/SelectInput.vue';
  import CardSection from '@/components/shared/CardSection.vue';
  import SubSection from '@/components/shared/SubSection.vue';
  import TextInput from '@/components/shared/TextInput.vue';
  import NumberInput from '@/components/shared/NumberInput.vue';
  import TextAreaInput from '@/components/shared/TextAreaInput.vue';
  import DateInput from '@/components/shared/DateInput.vue';
  import CheckboxInput from '@/components/shared/CheckboxInput.vue';
  import { Getter } from 'vuex-class';

  @Component({
    components: {
      CardSection,
      SubSection,
      SaveToolbar,
      SelectInput,
      DateInput,
      NumberInput,
      CheckboxInput,
      TextInput,
      TextAreaInput
    }
  })
  export default class TransportationAndMobility extends Vue {
    @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;

    title = 'Transportation and Mobility';

    private hasTransportationTrue = null;
    private hasTransportationFalse = null;
    private avoidCities = null;
    private avoidHighways = null;
    private gasMoneySensitivity = null;
    private mobilityChallenges = null;
    private maxMiles = null;
    private maxMinuites = null;
    private methodCar = '';
    private carDetails = '';
    private methodPublic = '';
    private publicDetails = '';
    private methodRide = '';
    private rideDetails = '';
    private methodMedicaid = '';
    private medicaidDetails = '';
    private methodOther = '';
    private otherDetails = '';
    private mobilityInpairmentsDevices = '';
    private comments = '';

    @Prop({
    default: null
  }) localStorageRef!: string;

  loaded() {
    // Parse tasks from local storage if found, otherwise default to JSON file contents
    let formState;

    const items: any = localStorage.getItem(this.localStorageRef);
    if (items) {
      try {
        formState = JSON.parse(items);
      } catch (error) {
        console.warn('Warning: Cannot parse tasklist from local storage, setting default tasks.', error);
      }
    } 

    if (formState) {
      this.hasTransportationTrue =  formState.hasTransportationTrue;
      this.hasTransportationFalse =  formState.hasTransportationFalse;
      this.avoidCities = formState.avoidCities;
      this.avoidHighways = formState.avoidHighways;
      this.gasMoneySensitivity = formState.gasMoneySensitivity;
      this.mobilityChallenges = formState.mobilityChallenges;
      this.maxMiles = formState.maxMiles;
      this.maxMinuites =  formState.maxMinuites;
      this.methodCar = formState.methodCar;
      this.carDetails = formState.carDetails;
      this.methodPublic = formState.methodPublic;
      this.publicDetails = formState.publicDetails;
      this.methodRide = formState.methodRide;
      this.rideDetails =  formState.rideDetails;
      this.methodMedicaid = formState.methodMedicaid;
      this.medicaidDetails = formState.medicaidDetails;
      this.methodOther = formState.methodOther;
      this.otherDetails = formState.otherDetails;
      this.mobilityInpairmentsDevices = formState.mobilityInpairmentsDevices;
      this.comments = formState.mobilityInpairmentsDevices;
    }
  }

  private mounted(): void {
    this.loaded();
  }


  public performSave(): any {

    const payload: any = {
      hasTransportationTrue: this.hasTransportationTrue,
      hasTransportationFalse: this.hasTransportationFalse,
      avoidCities: this.avoidCities,
      avoidHighways: this.avoidHighways,
      gasMoneySensitivity : this.gasMoneySensitivity,
      mobilityChallenges : this.mobilityChallenges,
      maxMiles : this.maxMiles,
      maxMinuites :  this.maxMinuites,
      methodCar : this.methodCar,
      carDetails : this.carDetails,
      methodPublic : this.methodPublic,
      publicDetails : this.publicDetails,
      methodRide : this.methodRide,
      rideDetails :  this.rideDetails,
      methodMedicaid : this.methodMedicaid,
      medicaidDetails : this.medicaidDetails,
      methodOther : this.methodOther,
      otherDetails : this.otherDetails,
      mobilityInpairmentsDevices : this.mobilityInpairmentsDevices,
      comments : this.mobilityInpairmentsDevices
    };
      
    localStorage.setItem(this.localStorageRef, JSON.stringify(payload));
  }
  }



</script>

<style>


</style>
