<script lang="ts">
import { State, Getter } from 'vuex-class';
import { Component, Vue, } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {UrlUtilsMixin} from "@/mixins/url-utils-mixin";

@Component({})
export default class Login extends mixins(UrlUtilsMixin) {
  @State(state => state.pageState.currentPage.login) private editState!: any;

  // redirect to url after login
  redirectAfterLogin() {
    const afterLoginUrl = localStorage.getItem('after_login_url') || null;
    if (afterLoginUrl) {
      // redirect to page on login
      localStorage.removeItem('after_login_url');
      window.location.href = afterLoginUrl;
    } else {
      // redirect with page refresh to force api calls to reload
      window.location.href = "/";
    }
  }

  private mounted(): void {
    // get access token
    const accessToken = this.getUrlQueryValue('access_token');
    // if token, deal with it
    if (accessToken) {
      localStorage.setItem('access_token', accessToken);
      // after capturing the access token,
      // reload the page without any access token in the url
      this.redirectAfterLogin();
      // this.$store.dispatch('users/redirectAfterLogin');
    }
  }
}

</script>
