<template>
  <card-section
    section-id="test-component-2"
    ref="testComponent2"
    :lookups-to-load="lookupsToLoad"
    @loaded="loaded()"
  >
    <template v-slot:header>
      Test Component 2
    </template>
    <template v-slot:body>
      <template v-if="!editState">
        loading
      </template>
      <template v-else>
        <fieldset>
          <legend class="sr-only">
            <h4 class="legend-title">
              <span>test component 2</span>
            </h4>
          </legend>
          <!-- Identifiers, Referral Hospital, and Out of Province Donation -->
          <div class="row">
            <div class="standard-form-group">
              <select-input
                selectId="referralInformation-sex"
                name='Sex'
                v-model="editState.sex"
                :options="sexOptions"
              />
            </div>
            <div class="standard-form-group">
              <select-input
                selectId="referralInformation-ethnicity"
                name='Ethnicity'
                v-model="editState.ethnicity"
                :options="ethnicityLookup"
              />
            </div>
          </div>
          <div class="row">
            <div class="standard-form-group">
              <select-input
                selectId="referralInformation-country"
                name='Country'
                v-model="editState.country"
                :options="countryLookup"
              />
            </div>
          </div>
        </fieldset>
      </template>
    </template>
  </card-section>
</template>

<script lang="ts">
import { mixins } from "vue-class-component";
import { Getter, State } from 'vuex-class';
import { titleCase, mergeClasses, isMasked } from '@/utils';
import CardSection from '@/components/shared/CardSection.vue';
import SubSection from '@/components/shared/SubSection.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ClassObject } from '@/types';
import { EP } from '@/api-endpoints';

@Component({
  components: {
    CardSection,
    SubSection,
    SelectInput,
  }
})
export default class TestComponent2 extends Vue {
  @State(state => state.pageState.currentPage.testComponent1) editState!: any;
  @State(state => state.lookups.ethnicity) ethnicityLookup!: any[];

  @Getter('sexOptions', { namespace: 'lookups' }) sexOptions!: boolean;
  @Getter("insurance", { namespace: "lookups" }) private insuranceLookup: any;
  @Getter('country', { namespace: 'lookups' }) private countryLookup: any;

  // Lookup tables to be loaded by the CardSection component
  public lookupsToLoad = [
    "ethnicity",
    "gender",
    "insurance_plan_codes",
    "country",
  ];

  private isFinishedLoadingEvents = false;

  private mounted(): void {
    this.initializeForm();
  }

  public loaded(): void {
    this.$emit('loaded', 'testComponent1');
  }

  // Private methods
  public initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'testComponent1',
      value: {
        sex: undefined,
        ethnicity: undefined,
        country: undefined,
        referral_hospital: undefined
      }
    });
  }
}

</script>
