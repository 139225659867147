<template>
  <sub-section
    :title="$t('thoracic_measurements')"
    ref="saveThoracicMeasurement"
    :tabbableValue="$t('date_of_measurement')"
    :saveButton="canEdit"
    :disabled="!canSave || newJourney"
    :divider="true"
    @save="saveThoracicMeasurement()"
    :saveButtonText="$t('save_thoracic_measurement')"
    sub-section-id="thoracic-measurements"
    :table-config="thoracicMeasurementsTableConfig()"
    @table-create-row="createThoracicMeasurement()"
    @table-row-click="selectThoracicMeasurement($event)"
    @loaded="loaded()"
  >
    <template v-slot:contents>
      <legend>
        <h5 v-if="editState && editState._id" class="legend-title">
          {{$t('selected_thoracic_measurement')}}
        </h5>
        <h5 v-else class="legend-title">
          {{$t('new_thoracic_measurement')}}
        </h5>
      </legend>
      <fieldset v-if="editState" :disabled="!canEdit">
      <div class="row">
        <div class="standard-form-group">
          <date-input
            input-id="thoracic-measurements-date"
            :name="$t('date_of_measurement')"
            rules="required"
            v-model="editState.date"
          />
        </div>
        <div class="standard-form-group-large">
          <number-input
            :name="$t('thoracic_circumference_cm')"
            inputId="thoracic-measurements-circumference"
            v-model="editState.thoracicCircumference"
          />
        </div>
        <div class="standard-form-group-large">
          <number-input
            :name="$t('left_vertical_cm')"
            inputId="thoracic-measurements-left-vertical"
            v-model="editState.leftVertical"
          />
        </div>
        <div class="standard-form-group-large">
          <number-input
            :name="$t('right_vertical_cm')"
            inputId="thoracic-measurements-right-vertical"
            v-model="editState.rightVertical"
          />
        </div>
        <div class="standard-form-group-large">
          <number-input
            :name="$t('height_cm')"
            inputId="thoracic-measurements-height"
            v-model="editState.height"
          />
        </div>
      </div>
      </fieldset>
    </template>
  </sub-section>
</template>

<i18n src="@/components/organs/shared/_locales/ThoracicMeasurements.json"></i18n>

<script lang="ts">
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { State, Getter } from 'vuex-class';
import { TableConfig } from '@/types';
import { SaveProvider, SaveResult } from '@/types';
import { Recipient } from '@/store/recipients/types';
import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import NumberInput from '@/components/shared/NumberInput.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import { RecipientJourney } from '@/store/recipientJourney/types';
import { ThoracicMeasurement } from '@/store/organSpecificDetails/types';
import { HeartSpecificPageState } from '@/components/organs/heart/HeartSpecificDetails.vue';
import { ObjectId } from '@/store/types';

export interface ThoracicMeasurementPageState {
  thoracicMeasurement: ThoracicMeasurementForm;
}

export interface ThoracicMeasurementForm {
  _id?: { $oid: string };
  date?: string|null;
  thoracicCircumference?: number;
  leftVertical?: number;
  rightVertical?: number;
  height?: number;
}

interface ThoracicMeasurementRow {
  _id?: { $oid: string };
  date?: string;
  thoracicCircumferenceText?: string;
  leftVerticalText?: string;
  rightVerticalText?: string;
  heightText?: string;
}

@Component({
  components: {
    TextInput,
    DateInput,
    SubSection,
    NumberInput,
    TextAreaInput,
  }
})
export default class ThoracicMeasurements extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.thoracicMeasurements) editState!: ThoracicMeasurementForm;
  @State(state => state.organSpecificDetails.thoracicMeasurements) thoracicMeasurements!: ThoracicMeasurement[];

  // Getters
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('isLastEntry', { namespace: 'utilities' }) private isLastEntry!: (id: ObjectId, entries: any[]) => boolean;
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  /**
   * Return true if we can edit the selected measurement
   *
   * @returns {boolean} true if we can edit
   */
  get canEdit(): boolean{
    if (this.newJourney || this.journey.completed) {
      return false;
    }
    
    const selectedId = this.editState?._id;
    if (!!selectedId && !!this.thoracicMeasurements) {
      return this.isLastEntry(selectedId, this.thoracicMeasurements);
    }
    return true;
  }

  // Event handlers
  public mounted(): void {
    this.initializeForm();
  }

  /**
   * Emits a loaded event after all subcomponents have finished loading.
   *
   * @listens allocationRecommendationListing#loaded
   * @emits loaded
   */
  public loaded(): void {
    this.$emit('loaded', 'saveThoracicMeasurement');
    this.initializeForm();
  }

  // Begin fetching the recipient's Thoracic Measurements
  public loadThoracicMeasurements(): void {
    const recipientId = this.recipient.client_id;
    this.$store.dispatch('organSpecificDetails/loadThoracicMeasurements', recipientId);
  }

  /**
   * Gets configuration for the Thoracic Measurements table
   *
   * @returns {TableConfig} Thoracic Measurement Table configuration
   */
  public thoracicMeasurementsTableConfig(): TableConfig {
    return {
      data: this.thoracicMeasurementRows || [],
      columns: [
        { label: this.$t('date_of_measurement').toString(), field: 'date' },
        { label: this.$t('thoracic_circumference').toString(), field: 'thoracicCircumferenceText' },
        { label: this.$t('left_vertical').toString(), field: 'leftVerticalText' },
        { label: this.$t('right_vertical').toString(), field: 'rightVerticalText' },
        { label: this.$t('height').toString(), field: 'heightText' },
      ],
      empty: this.$t('use_form_below').toString(),
      createButton: true,
      createText: this.$t('create_thoracic_measurement').toString()
    };
  }

  /**
   * Gets table row data for the Thoracic Measurement table
   *
   * @returns {ThoracicMeasurementRow[]} Thoracic Measurement table rows
   */
  get thoracicMeasurementRows(): ThoracicMeasurementRow[] {
    if (!this.thoracicMeasurements || this.thoracicMeasurements.length < 0) {
      return [];
    }
    const result: ThoracicMeasurementRow[] = [];
    this.thoracicMeasurements.forEach((thoracicMeasurement: ThoracicMeasurement) => {
      const row: ThoracicMeasurementRow = {
        _id: thoracicMeasurement._id,
        date: thoracicMeasurement.date ? this.parseDisplayDateUi(thoracicMeasurement.date) : undefined,
        thoracicCircumferenceText: thoracicMeasurement.thoracic_circumference == null ? '-' : thoracicMeasurement.thoracic_circumference.toString(),
        leftVerticalText: thoracicMeasurement.left_vertical  == null ? '-' : thoracicMeasurement.left_vertical.toString(),
        rightVerticalText: thoracicMeasurement.right_vertical == null ? '-' : thoracicMeasurement.right_vertical.toString(),
        heightText: thoracicMeasurement.height == null ? '-' : thoracicMeasurement.height.toString(),
      };
      result.push(row);
    });
    return result;
  }

  /**
   * Loads the selected row into editState.thoracicMeasurement
   *
   * @listens hsd-measurements#table-row-click
   * @param event selected row from the table
   */
  public selectThoracicMeasurement(event: { row: ThoracicMeasurement }): void {
    const selectedId = event.row._id && event.row._id.$oid ? event.row._id!.$oid : undefined;
    if (this.thoracicMeasurements && this.thoracicMeasurements.length > 0) {
      const thoracicMeasurments = this.thoracicMeasurements as ThoracicMeasurement[];
      const foundThoracicMeasurement: ThoracicMeasurementForm|undefined = thoracicMeasurments
        .find((each: ThoracicMeasurementForm) => {
          return each._id && each._id.$oid === selectedId;
        });
      if (foundThoracicMeasurement) {
        this.$store.commit('pageState/set', {
          pageKey: 'thoracicMeasurements',
          value: this.sanitizeThoracicMeasurement(foundThoracicMeasurement)
        });
      }
    }
  }

  public initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'thoracicMeasurements',
      value: {}
    });
    this.$store.dispatch('organSpecificDetails/loadThoracicMeasurements', this.recipientId);
  }

  /**
   * Converts a selected ThoracicMeasurement entry into ThoracicMeasurementForm
   *
   * @param ThoracicMeasurement Thoracic Measurement
   * @returns {ThoracicMeasurementForm} Thoracic Measurement Form entry
   */
  public sanitizeThoracicMeasurement(measurement: ThoracicMeasurement): ThoracicMeasurementForm {
    return {
      _id: measurement._id,
      date: measurement.date ? this.parseDateUi(measurement.date) : undefined,
      thoracicCircumference: measurement.thoracic_circumference,
      leftVertical: measurement.left_vertical,
      rightVertical: measurement.right_vertical,
      height: measurement.height,
    };
  }

  /**
   * Deselects any selected Thoracic Measurement, and clears the entry for
   * editState.thoracicDetails.thoracicMeasurement
   *
   * @listens hsd-measurements#table-create-row
   */
  public createThoracicMeasurement(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'thoracicMeasurements',
      value: {}
    });
    this.$emit('clear');
    this.resetSaveToolbar();
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    const gci = this.$refs.saveThoracicMeasurement as unknown as SaveProvider;
    // Reset the save provider's save toolbar
    gci.resetSaveToolbar();
  }

  // API response keys on the left, id for our UI on the right
  public idLookup: IdLookup = {
    'thoracic_measurement.date'                     : 'thoracic-measurements-date',
    'thoracic_measurement.thoracic_circumference'   : 'thoracic-measurements-circumference',
    'thoracic_measurement.left_vertical'            : 'thoracic-measurements-left-vertical',
    'thoracic_measurement.right_vertical'           : 'thoracic-measurements-right-vertical',
    'thoracic_measurement.height'                   : 'thoracic-measurements-height',
  };

  /**
   * PRIVATE
   */

  /**
   * Gets the entry for editSate.thoracicDetails.thoracicMeasurement
   *
   * Converts the entry for Thoracic Measurement from editState
   * into a payload ready for the API.
   *
   * @returns {ThoracicMeasurement} object containing any field changes
   */
  private extractThoracicMeasurementPatch(): ThoracicMeasurement {
    if (!this.editState) {
      return {};
    }
    const thoracicMeasurement = this.editState;
    return {
      date: thoracicMeasurement.date ? this.sanitizeDateApi(thoracicMeasurement.date) : null,
      thoracic_circumference: thoracicMeasurement.thoracicCircumference,
      left_vertical: thoracicMeasurement.leftVertical,
      right_vertical: thoracicMeasurement.rightVertical,
      height: thoracicMeasurement.height,
    };
  }

  /**
   * Attempt to save the Thoracic Measurement.
   *
   * Prepares an update payload for Thoracic Measurement,
   * dispatches a save action, and registers the save result.
   */
  private saveThoracicMeasurement(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveThoracicMeasurement as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('saving', 'thoracicMeasurement');
    // Generate payload based on current edit state
    const thoracicMeasurementPayload = {
      id: this.editState!._id,
      recipientId: this.recipient.client_id,
      thoracicMeasurement: this.extractThoracicMeasurementPatch()
    };

    // Dispatch save action and register the response
    this.$store.dispatch('organSpecificDetails/saveThoracicMeasurement', thoracicMeasurementPayload).then((success: SaveResult) => {
      // If successful, reload all of the recipient's measurements and show success notification
      this.loadThoracicMeasurements();
      this.createThoracicMeasurement();
      saveProvider.registerSaveResult(success);
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }
}
</script>
