<template>
  <sub-section
    sub-section-id="pancreas-whole-transplant-details"
    :save-button="canEdit"
    :save-button-text="$t('save_transplant_details')"
    @save="savePatch()"
    @loaded="loaded()"
    ref="saveTransplantDetails"
    :disabled="!isGroupWriteable('journey_transplant_details')"
    :confirmation="confirmationText"
  >
    <template v-slot:contents>
      <div class="hr-break" />
      <fieldset :disabled="newJourney">
        <legend>
          <h5 class="legend-title">
            {{$t('or_details')}}
          </h5>
        </legend>
        <template v-if="!editState || !editState.transplantDetails || !editState.transplantDetails.organTransplantDetails">
          {{$t('loading')}}
        </template>
        <template v-else>
          <div class="row">
            <div class="standard-form-group">
              <date-input
                ruleKey="transplant_start_date"
                input-id="transplantStartDate"
                :name="$t('transplant_start_date')"
                rules="required_if_filled:@transplantStartTime"
                :cross-values="{ transplantStartTime: editState.transplantDetails.organTransplantDetails.transplantStartTime }"
                v-model="editState.transplantDetails.organTransplantDetails.transplantStartDate"
              />
            </div>
            <div class="standard-form-group">
              <time-input
                ruleKey="transplant_start_date"
                inputId="transplantStartTime"
                :name="$t('transplant_start_time')"
                rules="required_if_filled:@transplantStartDate"
                :cross-values="{ transplantStartDate: editState.transplantDetails.organTransplantDetails.transplantStartDate }"
                v-model="editState.transplantDetails.organTransplantDetails.transplantStartTime"
              />
            </div>
            <div class="standard-form-group">
              <date-input
                input-id="transplantEndDate"
                name="Transplant End Date"
                v-model="transplant_end_date"
              />
            </div>
            <div class="standard-form-group">
               <time-input
                inputId="transplantEndTime"
                name="Transplant End Time"
                v-model="transplant_end_time"        
              />
            </div>
            <div class="standard-form-group">
              <date-input
                ruleKey="end_cold_storage_date"
                input-id="removedFromColdDate"
                :name="$t('removed_from_cold_date')"
                rules="required_if_filled:@removedFromColdTime"
                :cross-values="{ removedFromColdTime: editState.transplantDetails.organTransplantDetails.removedFromColdTime }"
                v-model="editState.transplantDetails.organTransplantDetails.removedFromColdDate"
              />
            </div>
            <div class="standard-form-group">
              <time-input
                ruleKey="end_cold_storage_date"
                inputId="removedFromColdTime"
                :name="$t('removed_from_cold_time')"
                rules="required_if_filled:@removedFromColdDate"
                :cross-values="{ removedFromColdDate: editState.transplantDetails.organTransplantDetails.removedFromColdDate }"
                v-model="editState.transplantDetails.organTransplantDetails.removedFromColdTime"
              />
            </div>
            <div class="standard-form-group">
              <date-input
                ruleKey="clamp_removal_date"
                input-id="clampOffDate"
                :name="$t('clamp_removal_date')"
                rules="required_if_filled:@clampOffTime"
                :cross-values="{ clampOffTime: editState.transplantDetails.organTransplantDetails.clampOffTime }"
                v-model="editState.transplantDetails.organTransplantDetails.clampOffDate"
              />
            </div>
            <div class="standard-form-group">
              <time-input
                ruleKey="clamp_removal_date"
                inputId="clampOffTime"
                :name="$t('clamp_removal_time')"
                rules="required_if_filled:@clampOffDate"
                :cross-values="{ clampOffDate: editState.transplantDetails.organTransplantDetails.clampOffDate }"
                v-model="editState.transplantDetails.organTransplantDetails.clampOffTime"
              />
            </div>
            <div class="standard-form-group">
              <text-input
                ruleKey="rewarm_time"
                inputId="rewarmTime"
                :name="$t('rewarm_time')"
                v-model="editState.transplantDetails.organTransplantDetails.rewarmTime"
                :calculated="true"
                :disabled="true"
                :append="true"
                appendText="hrs"
              />
            </div>
            <div class="standard-form-group-6column-xlarge-only">
              <text-input
                ruleKey="cold_isch_time"
                inputId="coldIschTime"
                :name="$t('cold_ischemic_time')"
                :calculated="true"
                :disabled="true"
                :append="true"
                appendText="hrs"
                v-model="editState.transplantDetails.organTransplantDetails.coldIschTime"
              />
            </div>
            <div class="standard-form-group">
              <boolean-radio-input
                  ruleKey="vessels_used"
                  input-id="vesselsUsed"
                  :labelName="$t('vessels_used')"
                  :acceptId="true"
                  :declineId="false"
                  :acceptLabel="$t('yes')"
                  :declineLabel="$t('no')"
                  v-model="editState.transplantDetails.organTransplantDetails.vesselsUsed"
              />
            </div> 
            <div class="standard-form-group-large"
              v-if="editState.transplantDetails.organTransplantDetails.vesselsUsed" 
            >
              <text-area-input
                ruleKey="list_of_vessels_used"
                input-id="listOfVessels"
                :name="$t('list_of_vessels')"
                v-model="editState.transplantDetails.organTransplantDetails.listOfVessels"
              />
            </div>        
          </div>
        </template>
      </fieldset>
    </template>
  </sub-section>
</template>

<i18n src="./../shared/_locales/common.json"></i18n>
<i18n src="@/components/organs/pancreas/_locales/PancreasWholeTransplantDetails.json"></i18n>

<script lang="ts">
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TransplantMixin } from "@/mixins/transplant-mixin";
import { Getter, State } from "vuex-class";
import { Component, Vue, Prop } from "vue-property-decorator";
import { IdLookup } from '@/store/validations/types';
import SubSection from "@/components/shared/SubSection.vue";
import { PancreasTransplantAttributes, RecipientJourney, TransplantFactors } from "@/store/recipientJourney/types";
import DateInput from "@/components/shared/DateInput.vue";
import { RecipientTransplantAttributes } from "@/store/recipientJourney/types";
import TimeInput from "@/components/shared/TimeInput.vue";
import NumberInput from '@/components/shared/NumberInput.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { Recipient } from '@/store/recipients/types';
import BooleanRadioInput from '@/components/shared/BooleanRadioInput.vue';
import { TransplantSectionPageState } from '@/mixins/transplant-mixin';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import TextInput from "@/components/shared/TextInput.vue";

export interface PancreasTransplantDetailsPageState {
  transplantStartDate?: string;
  transplantStartTime?: string;
  removedFromColdDate?: string;
  removedFromColdTime?: string;
  clampOffDate?: string;
  clampOffTime?: string;
  vesselsUsed?: boolean;
  listOfVessels?: string;
  rewarmTime?: string;
  coldIschTime?: string;
}

@Component({
  components: {
    SubSection,
    DateInput,
    TimeInput,
    NumberInput,
    BooleanRadioInput,
    TextAreaInput,
    TextInput
  }
})
export default class PancreasWholeTransplantDetails extends mixins(DateUtilsMixin, TransplantMixin) implements SaveableSection {
  // State
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) private recipientId!: string;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('convertTimeInSeconds', { namespace: 'utilities' }) convertTimeInSeconds!: (time?: number) => string;
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  private transplant_end_date = '';
  private transplant_end_time = '';

  public loaded(): void {
    this.$emit('loaded', 'pancreasWholeTransplantDetails');
  }

  public mounted(): void {

    let items = localStorage.getItem(`${this.journey.organ}transplantDetailsOR`);

    if (items) {
      let parsed = JSON.parse(items);
      this.transplant_end_date = parsed.transplant_end_date;
      this.transplant_end_time = parsed.transplant_end_time;
    }
  }

  /**
   * Return true if we can edit the transplant details
   *
   * @returns {boolean} true if we can edit
   */
  get canEdit(): boolean{
    if (this.newJourney || this.journey.completed) {
      return false;
    }
    return true;
  }

  /**
   * Build state for the PancreasTransplantDetails form area
   *
   * @param PancreasTransplantAttributes from RecipientTransplantAttributes
   * @returns {PancreasTransplantDetailsPageState} form state for PancreasTransplant
   */
  public buildTransplantDetailsState(transplantDetails?: RecipientTransplantAttributes): PancreasTransplantDetailsPageState {
    const pancreasTransplantAttributes = transplantDetails as PancreasTransplantAttributes || {};
    const result: PancreasTransplantDetailsPageState = {
      transplantStartDate: this.parseDateUiFromDateTime(pancreasTransplantAttributes.transplant_start_date || undefined),
      transplantStartTime: this.parseTimeUiFromDateTime(pancreasTransplantAttributes.transplant_start_date || undefined),
      removedFromColdDate: this.parseDateUiFromDateTime(pancreasTransplantAttributes.end_cold_storage_date || undefined),
      removedFromColdTime: this.parseTimeUiFromDateTime(pancreasTransplantAttributes.end_cold_storage_date || undefined),
      clampOffDate: this.parseDateUiFromDateTime(pancreasTransplantAttributes.clamp_removal_date || undefined),
      clampOffTime: this.parseTimeUiFromDateTime(pancreasTransplantAttributes.clamp_removal_date || undefined),
      vesselsUsed: pancreasTransplantAttributes.vessels_used,
      listOfVessels: pancreasTransplantAttributes.list_of_vessels_used,
      rewarmTime: this.convertTimeInSeconds(pancreasTransplantAttributes.rewarm_time || 0), 
      coldIschTime: this.convertTimeInSeconds(pancreasTransplantAttributes.cold_isch_time || 0)
    };
    return result;
  }

  /**
   * Gets a patch object representing form edit state changes for this form
   *
   * Delegates the logic of building the patch to a local private method
   *
   * @returns {any} patch object containing field changes
   */
  public extractPatch(): any {
    if (!this.editState || !this.editState.transplantDetails || !this.editState.transplantDetails.organTransplantDetails) {
      return {};
    } else {
      return this.extractPancreasTransplantDetailsPatch(this.editState.transplantDetails.organTransplantDetails);
    }
  }

  // String for confirmation dialog for partial cluster transplant hold
  get confirmationText(): string {
    if (!this.isConfirmationRequired()) return '';

    return this.$t('confirm_partial_cluster_transplant_hold').toString();
  }

  /**
   * Saves the form edit state.
   *
   * Prepares a payload for Transplant Atributes, dispatches a save action, and registers the save result.
   * @emits clear clear validation errors because saving has begun
   * @emits saved saving has completed successful
   */
  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveTransplantDetails as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('clear');
    // Generate payload based on current edit state
    const transplantAttributesPatch = this.extractPatch();
    // Setup saving payload
    const payload = {
      journeyId: this.journey._id ? this.journey._id.$oid : undefined,
      recipientId: this.recipientId,
      transplantAttributes: transplantAttributesPatch,
    };
    // Dispatch save action and register the response
    this.$store.dispatch('journeyState/saveTransplantDetails', payload).then((success: SaveResult) => {
      // Report to parent that saving has completed
      this.$emit('saved', 'pancreasTransplantDetails');
      saveProvider.registerSaveResult(success);
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });

    const transplantDetailsStorage = {
      "transplant_end_date":  this.transplant_end_date,
      "transplant_end_time": this.transplant_end_time,
    };

    localStorage.setItem(`${this.journey.organ}transplantDetailsOR`, JSON.stringify(transplantDetailsStorage));
  }

   /**
   * Clears all save notifications shown by the form.
   *
   * Gets the Save Provider associated with the form, and requests that it reset its own Save Toolbar
   */
  public resetSaveToolbar(): void {
    const saveProvider = this.$refs.saveTransplantDetails as unknown as SaveProvider;
    saveProvider.resetSaveToolbar();
  }


  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    return {
      'transplant_start_date' : 'transplantStartDate',
      'end_cold_storage_date' : 'removedFromColdDate',
      'clamp_removal_date'  : 'clampOffDate',
      'transplant_start_time' : 'transplantStartTime',
      'end_cold_storage_time' : 'removedFromColdTime',
      'clamp_removal_time'  : 'clampOffTime',
      'vessels_used' : 'vesselsUsed',
      'list_of_vessels_used' : 'listOfVessels'
    };
  }

  /**
   * Returns a journey patch object containing changes from a Transplant Details form
   *
   * @returns {RecipientTransplantAttributes}
   */
  private extractPancreasTransplantDetailsPatch(transplantDetails: PancreasTransplantDetailsPageState): PancreasTransplantAttributes {
    const transplant = this.editState.transplantDetails || {};
    const pancreasTransplantDetails = transplant.organTransplantDetails as PancreasTransplantDetailsPageState || {};

    // Fetch Transplant Date from the top-level of the form
    const transplantDate = this.editState.transplantDetails?.transplantDate;

    // Build nested 'factors' object to include in the Transplant Attributes patch
    const factors: TransplantFactors = {
      transplant_date: transplantDate ? this.sanitizeDateApi(transplantDate) : null,
    };

    // Build patch for Transplant Attributes object
    const result: PancreasTransplantAttributes = {
      transplant_start_date: this.sanitizeDateTimeApi(pancreasTransplantDetails.transplantStartDate, pancreasTransplantDetails.transplantStartTime),
      end_cold_storage_date: this.sanitizeDateTimeApi(pancreasTransplantDetails.removedFromColdDate, pancreasTransplantDetails.removedFromColdTime),
      clamp_removal_date: this.sanitizeDateTimeApi(pancreasTransplantDetails.clampOffDate, pancreasTransplantDetails.clampOffTime),
      vessels_used: pancreasTransplantDetails.vesselsUsed,
      list_of_vessels_used: pancreasTransplantDetails.listOfVessels,
      factors,
      donor_id: transplant.donorId ? { $oid: transplant.donorId } : null,
    };

    // Copy Details to Clustered Organs for details see https://shore.tpondemand.com/entity/7541-722-v42-transplant-details
    if (this.isClustered) {
      result.copy_to_cluster = transplant.copyToCluster;
    }

    return result;
  }
}
</script>
