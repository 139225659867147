<template>
  <sub-section subSectionId="transplant-in-progress-report">
    <template v-slot:contents v-if="editState">
      <div class="card tip-card">
        <div class="card-header">
          <div class="media align-items-center">
            <div class="media-left">
              <font-awesome-icon :icon="['far', 'file-chart-line']" fixed-width />
            </div>
            <div class="media-body flex-row align-items-center">
              <h3 class="tip-report-name">
                {{$t('waiting_list_report')}}
              </h3>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="content-wrap">
            <div class="container-fluid">
              <div class="row">
                <div class="standard-form-group" v-if="showHospitalandOrgan">
                  <select-input
                    selectId="waitingListReport-organ_type"
                    :name="$t('organ_type')"
                    v-model="editState.organCode"
                    :options="filteredOrganLookup"
                    rules="required"
                  />
                </div>
                <div class="standard-form-group" v-if="showHospitalandOrgan">
                  <checkbox-input
                    input-id='waitingListReport-clustered'
                    :labelName="$t('clustered_with')"
                    label="Yes"
                    v-model="editState.clustered"
                  />
                </div>
                <div class="standard-form-group" v-if="showHospitalandOrgan">
                  <select-input
                    selectId="waitingListReport-clustered_list"
                    :name="$t('clustered_list')"
                    v-model="editState.clusteredList"
                    :options="clusteredOrganLookup(editState.organCode)"
                    :multiple="true"
                    :disabled="editState.organCode && editState.clustered ? false : true"
                    :placeholder="allOptionsPlaceholderValue(editState.clusteredList, editState.organCode && editState.clustered ? false : true)"
                    :max-tags="3"
                  />
                </div>
                <div class="standard-form-group" v-if="showHospitalandOrgan">
                  <checkbox-input
                    input-id='waitingListReport-include_non_clustered'
                    :labelName="$t('include_non_clustered')"
                    label="Yes"
                    v-model="editState.includeNonClustered"
                  />
                </div>
                <div class="standard-form-group" v-if="showSuspendedPatients">
                  <select-input
                    selectId="waitingListReport-suspended_patients"
                    :name="$t('suspended_patients')"
                    v-model="editState.suspendedPatients"
                    :options="yesNoOptions"
                    :multiple="true"
                    :placeholder="allOptionsPlaceholderValue(editState.suspendedPatients)"
                  />
                </div>
              </div>

              <div class="row">
                <div class="standard-form-group"  v-if="showHospitalandOrgan">
                  <select-input
                    selectId="waitingListReport-hospital"
                    :name="$t('hospital')"
                    v-model="editState.hospital"
                    :options="filteredHospitalsByUser"
                    :multiple="true"
                    :undefinedText="hospitalUndefinedText"
                    :placeholder="allOptionsPlaceholderValue(editState.hospital, editState.organCode ? false : true)"
                    :disabled="editState.organCode ? false : true"
                  />
                </div>
                <div class="standard-form-group" v-if="showAgeBloodTypeDonorTypeECD">
                  <select-input
                    selectId="waitingListReport-blood_type"
                    :name="$t('blood_type')"
                    v-model="editState.bloodType"
                    :options="bloodTypeOptions"
                    :multiple="true"
                    :placeholder="allOptionsPlaceholderValue(editState.bloodType)"
                  />
                </div>
                <div class="standard-form-group" v-if="showAgeBloodTypeDonorTypeECD">
                  <select-input
                    selectId="waitingListReport-accept_living_donor"
                    :name="$t('accept_living_donor')"
                    v-model="editState.acceptLivingDonor"
                    :options="yesNoOptions"
                    :multiple="true"
                    :placeholder="allOptionsPlaceholderValue(editState.acceptLivingDonor)"
                  />
                </div>
                <div class="standard-form-group" v-if="showAgeBloodTypeDonorTypeECD">
                  <select-input
                    selectId="waitingListReport-accept_deceased_donor"
                    :name="$t('accept_deceased_donor')"
                    v-model="editState.acceptDeceasedDonor"
                    :options="yesNoOptions"
                    :multiple="true"
                    :placeholder="allOptionsPlaceholderValue(editState.acceptDeceasedDonor)"
                  />
                </div>
                <div class="standard-form-group" v-if="showECD">
                  <select-input
                    selectId="waitingListReport-ecd"
                    :name="$t('ecd')"
                    v-model="editState.extendedCriteriaDonor"
                    :options="yesNoOptions"
                    :multiple="true"
                    :placeholder="allOptionsPlaceholderValue(editState.extendedCriteriaDonor)"
                  />
                </div>
              </div>

              <div class="row">
                <div class="standard-form-group" v-if="showDACAcceptance">
                  <select-input
                    selectId="waitingListReport-dac_acceptance_hcvab"
                    :name="$t('hcv_ab')"
                    v-model="editState.dacAcceptanceHCVAB"
                    :options="yesNoOptions"
                    :multiple="true"
                    :placeholder="allOptionsPlaceholderValue(editState.dacAcceptanceHCVAB)"
                  />
                </div>

                <div class="standard-form-group" v-if="showDACAcceptance">
                  <select-input
                    selectId="waitingListReport-dac_acceptance_hcnat"
                    :name="$t('hcv_nat')"
                    v-model="editState.dacAcceptanceHCNAT"
                    :options="yesNoOptions"
                    :multiple="true"
                    :placeholder="allOptionsPlaceholderValue(editState.dacAcceptanceHCNAT)"
                  />
                </div>

                <div class="standard-form-group" v-if="showDACAcceptance">
                  <select-input
                    selectId="waitingListReport-posituve_acceptance_hepabcore"
                    :name="$t('hep_ab_core')"
                    v-model="editState.dacAcceptanceHepABCore"
                    :options="yesNoOptions"
                    :multiple="true"
                    :placeholder="allOptionsPlaceholderValue(editState.dacAcceptanceHepABCore)"
                  />
                </div>
                <div class="standard-form-group" v-if="showAgeBloodTypeDonorTypeECD">
                  <number-input
                    inputId="waitingListReport-lower_age"
                    :name="$t('lower_age')"
                    v-model="editState.lowerAge"
                    rules="required"
                  />
                </div>
                <div class="standard-form-group" v-if="showAgeBloodTypeDonorTypeECD">
                  <number-input
                    inputId="waitingListReport-upper_age"
                    :name="$t('upper_age')"
                    v-model="editState.upperAge"
                    rules="required"
                  />
                </div>
                <div class="standard-form-group" v-if="showSort">
                  <select-input
                    selectId="waitingListReport-sorting"
                    :name="$t('sort')"
                    v-model="editState.sortOrder"
                    :options="sortOptions"
                    placeholder="Sort By"
                  />
                </div>
              </div>

              <div class="form-row">
                <save-toolbar
                  class="form-inline action-row"
                  buttonClass="btn btn-success"
                  ref="generateReport"
                  :savingText="$t('saving_text')"
                  :successText="$t('success_text')"
                  :defaultErrorText="$t('default_error_text')"
                  @save="generateReport()"
                >
                  <template v-slot:label>
                    <font-awesome-icon :icon="['far', 'file-chart-line']" class="mr-2" /> {{$t('generate_report')}}
                  </template>
                </save-toolbar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </sub-section>
</template>

<i18n src="./_locales/common.json"></i18n>
<i18n src="./_locales/WaitingListReport.json"></i18n>

<script lang="ts">
import { Getter, State } from 'vuex-class';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { SaveResult } from '@/types';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import TextInput from '@/components/shared/TextInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import NumberInput from '@/components/shared/NumberInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { WaitingListReportState, WaitingListReportQuery, waitingListReports } from '@/store/reports/types';
import { Organ, BloodType, OrganCodeValue, BloodTypeValue } from '@/store/lookups/types';
import { Hospital } from '@/store/hospitals/types';
import { User, Role } from '@/store/users/types';
import { GenericCodeValue, RootState } from '@/store/types';
import { mixins } from "vue-class-component";
import { TranslationUtilsMixin } from "@/mixins/translation-utils-mixin";

@Component({
  components: {
    SaveToolbar,
    SelectInput,
    NumberInput,
    TextInput,
    CheckboxInput,
    SubSection
  }
})

export default class WaitingListReport extends mixins(TranslationUtilsMixin) {
  // State
  @State(state => state.reports.WaitingListReportState) editState!: WaitingListReportState;

  @State(state => state.lookups.organ) organLookup!: Organ[];
  @State(state => state.lookups.blood_type) bloodTypeLookup!: BloodType[];
  @State(state => state.hospitals.all) hospitals!: Hospital[];
  @State(state => state.users.user) user!: User;

  @Getter('waitlistDonorTypeOptions', { namespace: 'reports' }) private waitlistDonorTypeOptions!: [];
  @Getter('getTransplantProgramsByOrgan', { namespace: 'hospitals' }) getTransplantProgramsByOrgan!: (organ_code: number[]) => any;
  @Getter('allOptionsPlaceholderValue', { namespace: 'reports' }) allOptionsPlaceholderValue!: (state: any, disabled?: boolean) => string;

  @Prop({ default: null}) report!: string;

  public mounted(): void {
    this.$store.dispatch('hospitals/load').then(() => {
      this.$store.commit('reports/setWaitingListReportState', this.buildWaitingListReportState());
    });
  }

  get hospitalUndefinedText(): string {
    return this.editState.organCode ? 'Select...' : 'Select organ(s) to continue';
  }

  // Used to check which reports should show the Hospital and Organ Type field parameters
  get showHospitalandOrgan(): boolean {
    const listOfWaitingListReports : string[] = Object.values(waitingListReports);
    return listOfWaitingListReports.includes(this.report!);
  }

  // Used to check which reports should show the Age, Blood Type, Donor Type and ECD field parameters
  get showAgeBloodTypeDonorTypeECD(): boolean {
    // Set default Lower Age and Upper Age values
    Vue.set(this.editState, 'lowerAge', 0);
    Vue.set(this.editState, 'upperAge', 100);
    return [
      waitingListReports.WaitingListReport,
      waitingListReports.WaitingListKidneyPancreas,
      waitingListReports.WaitingListPancreasIslets,
      waitingListReports.WaitingListHeartClusters,
      waitingListReports.WaitingListLiverClusters,
      waitingListReports.WaitingListPancreasWhole
    ].toString().includes(this.report!);
  }

    // Used to check which reports should show the Age, Blood Type, Donor Type and ECD field parameters
  get showECD(): boolean {
    return [
      waitingListReports.WaitingListReport,
      waitingListReports.WaitingListKidneyPancreas,
      waitingListReports.WaitingListPancreasIslets,
      waitingListReports.WaitingListPancreasWhole
    ].toString().includes(this.report!);
  }

  // Used to check which reports should show the DAC Acceptance field parameter
  get showDACAcceptance(): boolean {
    return [
      waitingListReports.WaitingListReport,
      waitingListReports.WaitingListKidneyPancreas,
      waitingListReports.WaitingListLungHeart,
      waitingListReports.WaitingListPancreasIslets,
      waitingListReports.WaitingListHeartClusters,
      waitingListReports.WaitingListLiverClusters,
      waitingListReports.WaitingListPancreasWhole
    ].toString().includes(this.report!);
  }

  // Used to check which reports should show the Suspended Patients field parameters
  get showSuspendedPatients(): boolean {
    return [
      waitingListReports.WaitingListReport,
      waitingListReports.WaitingListHeartClusters,
      waitingListReports.WaitingListLiverClusters
    ].toString().includes(this.report!);
  }

  // Used to check which reports should show the Sort Order field parameter
  get showSort(): boolean {
    if(!this.editState.sortOrder) {
      Vue.set(this.editState, 'sortOrder', 1);
    }

    return [
      waitingListReports.WaitingListLungHeart
    ].toString().includes(this.report!);
  }

    // Used to check which reports should show the Sort Order field parameter
  get sortOptions(): GenericCodeValue[] {
    if (this.report && this.report === waitingListReports.WaitingListReport && this.editState.organCode?.toString() !== OrganCodeValue.Lung.toString()) {
      return [
        { code: '1', value: 'Medical Status' },
        { code: '2', value: 'Blood Type' },
        { code: '3', value: 'List Date' },
        { code: '4', value: 'Recipient ID' },
        { code: '5', value: 'Recipient Surname' }
      ];
    }

    if (this.report && this.report === waitingListReports.WaitingListLungHeart ||
       (this.report === waitingListReports.WaitingListReport && this.editState.organCode?.toString() === OrganCodeValue.Lung.toString())) {
      return [
        { code: '1', value: 'Medical Status' },
        { code: '2', value: 'Recipient Surname' }
      ];
    }
    return [];
  }


  /**
   * get clustered organ lookup which takes in a single organ code number to exclude from the lookup
   * only organs that are 'single' type
   */
  public clusteredOrganLookup(organ_to_exclude: number) {
    if (!organ_to_exclude) {
      return [];
    }

    // only get unexpired organs
    const unexpiredOrgans = this.organLookup.filter((organ: Organ) => {
      return !organ.expired_date;
    });

    // remove vessels for banking
    const singleOrgans = unexpiredOrgans.filter((organ: Organ) => {
      return organ.code != OrganCodeValue.VesselsForBanking;
    });

    // remove organ that was selected in Organ Type
    return singleOrgans.filter((organ: Organ) => {
      return organ.code != organ_to_exclude;
    });
  }

  /**
   * Filter the organ code lookup depending on what report is currently selected
   *
   * @returns {Organ[]} filtered list of Organs
   */
  get filteredOrganLookup(): Organ[] {
    const waitlistGenericOrgans = [
      OrganCodeValue.Heart,
      OrganCodeValue.Kidney,
      OrganCodeValue.Liver,
      OrganCodeValue.Lung,
      OrganCodeValue.PancreasIslets,
      OrganCodeValue.PancreasWhole,
      OrganCodeValue.SmallBowel,
      OrganCodeValue.VCA,
      OrganCodeValue.VesselsForBanking
    ];

    const waitlistHeartClusterOrgans = [
      OrganCodeValue.Heart
    ];

    const waitlistKidneyPancreasOrgans = [
      OrganCodeValue.Kidney,
      OrganCodeValue.PancreasIslets,
      OrganCodeValue.PancreasWhole
    ];

    const waitlistLiverKidneyOrgans = [
      OrganCodeValue.Liver
    ];

    const waitlistLungHeartOrgans = [
      OrganCodeValue.Lung
    ];

    const waitlistPancreasIsletsOrgans = [
      OrganCodeValue.PancreasIslets
    ];

    const waitlistPancreasWholeOrgans = [
      OrganCodeValue.PancreasWhole
    ];

    return this.organLookup.filter((organ: Organ) => {
      switch (this.report) {
        case waitingListReports.WaitingListReport:
          return this.filterOrgansByUser(waitlistGenericOrgans).includes(organ.code);
        case waitingListReports.WaitingListKidneyPancreas:
          return this.filterOrgansByUser(waitlistKidneyPancreasOrgans).includes(organ.code);
        case waitingListReports.WaitingListLungHeart:
          return this.filterOrgansByUser(waitlistLungHeartOrgans).includes(organ.code);
        case waitingListReports.WaitingListPancreasIslets:
          return this.filterOrgansByUser(waitlistPancreasIsletsOrgans).includes(organ.code);
        case waitingListReports.WaitingListHeartClusters:
          return this.filterOrgansByUser(waitlistHeartClusterOrgans).includes(organ.code);
        case waitingListReports.WaitingListLiverClusters:
          return this.filterOrgansByUser(waitlistLiverKidneyOrgans).includes(organ.code);
        case waitingListReports.WaitingListPancreasWhole:
          return this.filterOrgansByUser(waitlistPancreasWholeOrgans).includes(organ.code);
      }
    });
  }

  /**
   * Shows all ontario transplant programs for the organ code selected in Organ Type field
   *
   * @returns {Organ[]} filtered list of ontario transplant programs
   */
  get filteredHospitals(): Hospital[]|undefined {
    if (this.editState.organCode) {
      const organCode = this.editState.organCode;

      return this.getTransplantProgramsByOrgan( [parseFloat(organCode.toString())] ).map((hospital: Hospital) => {
        return {
          code: hospital._id.$oid,
          value: hospital.hospital_name_info ? hospital.hospital_name_info.name : '-'
        };
      });
    } else {
      return [];
    }
  }

  /**
   * If user.hospital_organ_codes is empty then returns all hospitals from filteredHospitals()
   * otherwise filters the hospitals based on the hospital_id keys in user.hospital_organ_codes
   *
   * @returns {Organ[]} filtered list of ontario transplant programs by user
   */
  get filteredHospitalsByUser(): Hospital[]|undefined {
    const hospitals = this.filteredHospitals || [];

    const filterBy = this.user.hospital_organ_codes || {};

    if (Object.keys(filterBy).length === 0) {
      // if there are no user hospital organ codes then return all hospitals
      return hospitals;
    } else {
      // get all hospital keys from user.hospital_organ_codes and filter hospitals by the hospital_id key
      return hospitals.filter((hospital: any) => {
        return Object.keys(filterBy).includes(hospital.code);
      });
    }
  }

  get bloodTypeOptions(): BloodType[]|undefined {
    return this.bloodTypeLookup.filter((blood: BloodType) => {
      return blood.code != BloodTypeValue.Unknown;
    });
  }

  /**
   * If user.hospital_organ_codes is empty then returns all given organs
   * otherwise filters the organs based on the organ codes in user.hospital_organ_codes
   *
   * @returns {Organ[]} filtered list of organs by user
   */
  public filterOrgansByUser(organs: OrganCodeValue[]): OrganCodeValue[] {
    const filterBy: { [key: string]: any } = this.user.hospital_organ_codes || {};

    const userOrganCodes = Object.keys(filterBy).map((hospital: string) => filterBy[hospital]).flat();
    if(userOrganCodes.length === 0) {
      return organs;
    }

    return organs.filter((organ) => userOrganCodes.includes(organ));
  }

  // Clears all the field parameters
  public clearParametersOnReportChange(): void {
    Promise.all([
      this.$store.dispatch('reports/newWaitingListReport')
    ]).finally(() => {
      this.resetSaveToolbar();
      const validationObserver = this.$refs.transplantInProgressReportFilters as any;
      if (validationObserver) validationObserver.reset();
    });
  }

  public buildWaitingListReportState(): WaitingListReportState {
    const result: WaitingListReportState = {
      lowerAge: 0,
      upperAge: 100,
      sortOrder: '1'
    };
    return result;
  }

  // pass lookup options to get all options code
  public defaultAllOptions(options: any) {
    return options.map((option: any) => {
      return option.code;
    });
  }

  public buildWaitlistReportQuery(): WaitingListReportQuery {
    if (!this.editState) {
      return {};
    }

    const bloodTypeValues = this.defaultAllOptions(this.bloodTypeOptions);
    const yesNoValues     = this.defaultAllOptions(this.yesNoOptions);

    // We send only the values that are visible using the field parameter show getters
    // If we don't show the field then send undefined
    // If the field is visible but no value is selected then send all possible values otherwise send the values in v-model editState
    const result = {
      organ_type: this.editState.organCode,
      clustered: this.editState.clustered,
      clustered_list: this.editState.clusteredList,
      sorting: this.editState.sortOrder,
      include_non_clustered: this.editState.includeNonClustered,
      hospital: (this.editState.hospital || []).length == 0 ? this.defaultAllOptions(this.filteredHospitalsByUser) : this.editState.hospital,
      lower_age: this.showAgeBloodTypeDonorTypeECD ? this.editState.lowerAge : undefined,
      upper_age: this.showAgeBloodTypeDonorTypeECD ? this.editState.upperAge : undefined,
      blood_type: this.showAgeBloodTypeDonorTypeECD ? (this.editState.bloodType ? this.editState.bloodType : bloodTypeValues) : undefined,
      extended_criteria_donor: this.showAgeBloodTypeDonorTypeECD && this.showECD? (this.editState.extendedCriteriaDonor ? this.editState.extendedCriteriaDonor : null) : undefined,
      accept_living_donor: this.showAgeBloodTypeDonorTypeECD ? (this.editState.acceptLivingDonor ? this.editState.acceptLivingDonor : null) : undefined,
      accept_deceased_donor: this.showAgeBloodTypeDonorTypeECD ? (this.editState.acceptDeceasedDonor ? this.editState.acceptDeceasedDonor : null) : undefined,
      suspended_patients: this.showSuspendedPatients ? (this.editState.suspendedPatients ? this.editState.suspendedPatients : yesNoValues) : undefined,
      dac_acceptance_hcvab: this.showDACAcceptance ? (this.editState.dacAcceptanceHCVAB ? yesNoValues[0] : null) : undefined,
      dac_acceptance_hcnat: this.showDACAcceptance ? (this.editState.dacAcceptanceHCNAT ? yesNoValues[0] : null) : undefined,
      dac_acceptance_hepabcore: this.showDACAcceptance ? (this.editState.dacAcceptanceHepABCore ? yesNoValues[0] : null) : undefined,
      report_code: this.report
    };
    return result;
  }

  /**
   * Clears all save notifications shown by the form.
   *
   * Gets the Save Toolbar associated with the form and resets it
   */
  public resetSaveToolbar(): void {
    const saveToolbar = this.$refs.generateReport as SaveToolbar;
    if (saveToolbar) {
      saveToolbar.reset();
    }
  }

  public generateReport(): void {
    const saveToolbar = this.$refs.generateReport as SaveToolbar;
    saveToolbar.startSaving();
    // Submit query
    const waitlistQuery = this.buildWaitlistReportQuery();
    this.$store.dispatch('reports/printWaitingListReport', waitlistQuery).then((result: SaveResult) => {
      // Get the filename
      const fileName = result.responseData.report;
      // Is there actually a filename
      if (!!fileName) {
        // Create a link
        const link = document.createElement('a');
        link.href = fileName;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        // Then click it forcing a save/open dialogue
        link.click();
        // Show success notification
      }
      saveToolbar.stopSaving(result);
    }).catch((result: SaveResult) => {
      this.$emit('handleErrors', result);
      saveToolbar.stopSaving(result);
    });
  }
}
</script>
