<template>
  <card-section
    section-id="pace-and-engagement"
    :isAccordion="prototypeFeatureEnabled('proto_ability_to_expand_collapse')"
  >
    <template v-slot:header>
      {{ title }}
    </template>
    <template v-slot:body>
      <div class="row">
        <div class="col-sm-12">
          <sub-section
            :title='title'
            sub-section-id="pace-and-engagement-subsection"
          >
            <template v-slot:contents>

              <div class="row">

                <div class="col-sm-12 considerations-section">
                  <fieldset>
                    <div class="row">
                      <div class="standard-form-group form-group-checkbox">
                        <checkbox-input            
                          name="centre-fast-track"
                          labelName="Transplant Center Fast-Track?"
                          inputId="centre-fast-track"
                          v-model="fastTrack"
                          label='Yes'
                        />
                      </div>
                        <div class="standard-form-group form-group-checkbox">
                          <label>Patient Preferred Pace / Motivation</label>
                          <div class="row">
                            <div class="col-12">
                              <checkbox-input name="slow" hideLabel="true" labelName="Slow / minimize burden" inputId="slow"
                                v-model="slow" label='Slow / minimize burden'  :disabled="fast" />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <checkbox-input name="fast" hideLabel="true" labelName="Go fast" inputId="fast"
                                v-model="fast" label='Go fast' :disabled="slow" />
                            </div>
                          </div>

                        
                        </div>
    
                  
                        <div class="standard-form-group-large">
                          <text-area-input input-id="additional-information" name="Patient Engagement"
                            rows="4"
                            :showToolTip="true"
                            toolTipText="Is the patient engaged in this process?"
                            v-model="patientEngagement" />
                        </div>
                
                    </div>
                  
            
                     <save-toolbar
                      class="save-toolbar action-row temp-saving d-flex"
                      buttonClass="btn btn-success sec-submit"
                      :label="`Save ${title}`"
                      @save="performSave()"  
                      showCancelButton="true" 
                      cancelButtonText="Cancel" 
                      @cancel="clearForm()"
                    />
                  </fieldset>

                </div>
              </div>
            </template>
          </sub-section>
        </div>
      </div>
        
    </template>

  </card-section>
</template>


<script lang="ts">

import { Component, Vue, Prop} from 'vue-property-decorator';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import CardSection from '@/components/shared/CardSection.vue';
import SubSection from '@/components/shared/SubSection.vue';
import DateInput from '@/components/shared/DateInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import { Getter } from 'vuex-class';

@Component({
  components: {
    CardSection,
    SubSection,
    SaveToolbar,
    SelectInput,
    DateInput,
    CheckboxInput,
    TextAreaInput
  }
})
export default class PaceAndEngagement extends Vue {  
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;

  title = 'Pace and Engagement';
  private fastTrack = null;
  private slow = null;
  private fast = null;
  private patientEngagement = '';

  @Prop({
    default: null
  }) localStorageRef!: string;

  loaded() {
    // Parse tasks from local storage if found, otherwise default to JSON file contents
    let formState;

    const items: any = localStorage.getItem(this.localStorageRef);
    if (items) {
      try {
        formState = JSON.parse(items);
      } catch (error) {
        console.warn('Warning: Cannot parse tasklist from local storage, setting default tasks.', error);
      }
    } 

    if (formState) {
      this.fastTrack =  formState.fastTrack;
      this.slow = formState.slow;
      this.fast = formState.fast;
      this.patientEngagement = formState.patientEngagement;
    }

  }

  private mounted(): void {
    this.loaded();
  }


  public performSave(): any {

    const payload: any = {
      fastTrack: this.fastTrack,
      slow: this.slow,
      fast: this.fast,
      patientEngagement: this.patientEngagement
    };
      
    localStorage.setItem(this.localStorageRef, JSON.stringify(payload));
  }

  
}
</script>

<style>


</style>
