<template>

  <div class="filter-wrapper">
    <template v-if="isMobile">


        <button class="btn btn-outline-secondary btn-sm filterButton" type="button" id="dropdownMenuButton"
          @click.prevent="toggleFilter" :aria-label="$t('toggle_accordian_section')">
          Filters
          <font-awesome-icon :icon="['fas', 'caret-down']" class="accordion-icon"/>

        </button>


        <div v-if="displayFilterSection" class="filter-dropmenu" aria-labelledby="dropdownMenuButton">
          <button @click.prevent="toggleFilter" class="btn btn-sm closeDropdown" >
            <font-awesome-icon :icon="['fas', 'times']" class="nav-caret" fixed-width />
          </button>
          <filter-links :displayFilter="displayFilter" :keywordSearch="keywordSearch" :checklistFilter="checklistFilter"
            :fieldID="fieldID" :checklistStatusOptions="checklistStatusOptions" :serologyFilter="serologyFilter" :notesFilter="notesFilter"
            :notesTagOptions="notesTagOptions" @prepareFilter="prepareFilter" :completedFilter="completedFilter" :archivedFilter="archivedFilter"
            :notesAuthorOptions="notesAuthorOptions" :appliedFilters="appliedFilters" />
            
        
        </div>
        <template v-for="filter in appliedFilters">   
      
          <template v-if="filter.name == 'filterTag'"> 
            <template v-if="filter.value.data.length > 0">
              <span class="filterTag" v-for="(tag, index) in filter.value.data" :key="index">

                {{getFilterName(filter)}}: {{tag.name}}
            
                <button @click.prevent="removeFilter(filter, tag.code)" class="removeFilterButton btn" >
                  <font-awesome-icon :icon="['fas', 'times']" class="nav-caret" fixed-width />
                </button>
          
              </span>
            </template>
          </template>
          <template v-else>

          <span class="filterTag"  :key="filter.key" v-if="filter.value.value == true"> 
            {{getFilterName(filter)}}
        
            <button @click.prevent="removeFilter(filter)" class="removeFilterButton btn" >
              <font-awesome-icon :icon="['fas', 'times']" class="nav-caret" fixed-width />
            </button>
          </span>
            
          </template>
        </template>
    </template>

      <template v-else>
        <div class="filter-section">
       

          <filter-links :displayFilter="displayFilter" :keywordSearch="keywordSearch" :checklistFilter="checklistFilter"
            :fieldID="fieldID" :checklistStatusOptions="checklistStatusOptions" :notesFilter="notesFilter" :serologyFilter="serologyFilter"
            :notesTagOptions="notesTagOptions" :notesAuthorOptions="notesAuthorOptions"
            @prepareFilter="prepareFilter"  :completedFilter="completedFilter" :archivedFilter="archivedFilter" :appliedFilters="appliedFilters" :hideSpacer='hideSpacer'/>


        </div>
      </template>
    </div>
</template>

<script lang="ts">
  import {
    Getter,
    State
  } from 'vuex-class';
  import {
    Component,
    Vue,
    Prop,
    Watch
  } from 'vue-property-decorator';
  import CheckboxInput from '@/components/shared/CheckboxInput.vue';
  import TextInput from '@/components/shared/TextInput.vue';
  import SelectInput from '@/components/shared/SelectInput.vue';
  import DateInput from '@/components/shared/DateInput.vue';
  import FilterLinks from './_FilterLinks.vue';
  @Component({
    components: {
      TextInput,
      SelectInput,
      DateInput,
      FilterLinks
    }
  })
  export default class FilterComponent extends Vue {

    @Prop({
      default: null
    }) displayFilter!: any;
    @Prop({
      default: null
    }) fieldID!: any;
    @Prop({
      default: null
    }) notesFilter!: any;
    @Prop({
      default: null
    }) serologyFilter!: any;
    @Prop({
      default: null
    }) notesTagOptions!: any;
    @Prop({
      default: null
    }) notesAuthorOptions!: any;
    @Prop({
      default: null
    }) checklistFilter!: any;
    @Prop({
      default: null
    }) checklistStatusOptions!: any;
    @Prop({
      default: null
    }) keywordSearch!: any;
    @Prop({
      default: null
    }) hideSpacer!: any;
    @Prop({
      default: null
    }) archivedFilter!: any;

    @Prop({
      default: null
    }) completedFilter!: any;
    @Prop({
      default: null
    }) setFilters!: any;
    
    @Watch('setFilters', { immediate: true, deep: true })
    public checkFilters(): void {
      this.appliedFilters = [];
      for (const [key, value] of Object.entries(this.setFilters)) {
        const i = this.appliedFilters.findIndex((e: any) => e.name === key);
          if (i == -1) {
            this.appliedFilters.push({name: key, value: value});  
          }    
      }
    }


    private displayFilterSection = false;
    private appliedFilters: any = [];
    private isMobile = false;

    private mounted(): void {
      window.addEventListener('resize', this.checkwidth);
      this.checkwidth();
    }


  public checkwidth() {
    this.isMobile = window.innerWidth < 1400;
  }


  toggleFilter() {
    this.displayFilterSection = !this.displayFilterSection;
  }
    
  getFilterName(filter: any) {

    switch(filter.name) {
      case "includeArchived":
        // primary
        return "Include Archived";
        break;
      case "includeCompleted":
        // primary
        return "Include Completed";
        break;
      case "filterStatus":
        // primary
        return `Status : ${filter.value.data}`;
        break;
      case "filterTag":
        // primary
        return "Note Tag";
        break;
      }
    }

    private prepareFilter(FilterObject: any): void {

      this.toggleFilter();
      
      this.$emit('updateFilter', FilterObject);
    }

    private removeTagFilter(tag: any) {
     let filterArray: any = [];
      
      this.appliedFilters.map((item: any) => {

        if ( item.name == 'filterTag') {
          
          let filterArray = item.value.data.filter((filter: any) => {
            return filter.code !== tag;
          });
    
          item.value.data = filterArray;
          filterArray.push(item);
        } else {
          filterArray.push(item);
        }
      });

      this.appliedFilters = filterArray;
    }
        
    private removeFilter(filter: any, tag: any) {

      let filterArray: any = [];

      this.appliedFilters.map((item: any) => {
        if ( filter.name == item.name) {
          if (item.name == "includeArchived") {
            item.value.value= false;
            filterArray.push(item);
            this.appliedFilters = filterArray;
  
          } 
          if ( item.name == 'filterTag') {
            
            let tags = item.value.data.filter((filter: any) => {
              return filter.code !== tag;
            });
      
            item.value.data = tags;
            filterArray.push(item);
            this.appliedFilters = filterArray;
        
        } 
        } else {
          filterArray.push(item);
          this.appliedFilters = filterArray;
        }
      });         
    }

  }

</script>

<style>


</style>
