<template>
  <div class="nav-block-wrap">
    <ul class="nav flex-column nav-block" 
      :class="{ active: isActive && !offerPage }">
      <li class="nav-item" >
        <span class="nav-link">
          <router-link class="no-scroll" style="color: #2e2e2e !important" :to="{ name: 'edit-organ', params: { organ_id: journeyId } }">
             <font-awesome-icon class="nav-caret" :icon="['fas', (show ? 'chevron-down' : 'chevron-right')]" fixed-width @click.prevent="toggleNav"/>
            {{ $t(organName) }}
          </router-link>
        </span>
        <template v-if="show">
          <ul class="nav flex-column">
            <li class="nav-item" @click="toggleSideNav" v-if="isClinicalTransactionEnabled">
              <a class="nav-link" :href="getUrl('clinical-transaction')">{{$t('clinical_transaction')}}</a>
            </li> 
            <li class="nav-item" @toggle="toggleSideNav" v-if="prototypeFeatureEnabled('proto_show_organ_journey_overview')">
              <a class="nav-link" :href="getUrl('organJourneyOverview')"> {{$t(organName)}} Journey Overview</a>
            </li>
            <li class="nav-item" @toggle="toggleSideNav" v-if="prototypeFeatureEnabled('demo_phase_state')">
              <a class="nav-link" :href="getUrl('phase_status')">Journey Status</a>
            </li>
            <li class="nav-item" @toggle="toggleSideNav" v-if="(organName == 'Lung'||organName == 'Liver' ||organName == 'Kidney') && prototypeFeatureEnabled('proto_living_donation')" >
              <a class="nav-link" :href="getUrl('living_donation')">Living Donation</a>
            </li>
            <li class="nav-item" @toggle="toggleSideNav" v-if="prototypeFeatureEnabled('demo_tasklists')">
              <a class="nav-link" :href="getUrl('checklists')">Checklists</a>
            </li>
            <li class="nav-item" @toggle="toggleSideNav" v-if="prototypeFeatureEnabled('demo_actions')">
              <a class="nav-link" :href="getUrl('proto_actions')">Actions</a>
            </li>
            <li class="nav-item" @toggle="toggleSideNav" v-if="prototypeFeatureEnabled('demo_notes')">
              <a class="nav-link" :href="getUrl('proto_notes')">Notes</a>
            </li>


            <li class="nav-item" @toggle="toggleSideNav" v-if="prototypeFeatureEnabled('proto_notes_encounters')">
              <a class="nav-link" :href="getUrl('notes_and_encounters')">Notes / Encounters</a>
            </li>
            <li class="nav-item" @toggle="toggleSideNav" v-if="prototypeFeatureEnabled('proto_worksheets_tasklists')">
              <a class="nav-link" :href="getUrl('worksheet_and_tasklists')">Worksheets / Tasklists</a>
            </li>

            <li class="nav-item" @click="toggleSideNav">
              <a class="nav-link" :href="getUrl('referral-section')">{{$t('referral')}}</a>
            </li>   

            <li class="nav-item" @click="toggleSideNav" v-if="groupExists('journey_decision') && isConsultationSectionEnabled && prototypeFeatureEnabled('demo_consultations')">
              <a class="nav-link" :href="getUrl('consultation-section')">{{$t('consultation')}}</a>
            </li>
            <li class="nav-item" @click="toggleSideNav" v-if="groupExists('journey_decision') && isMedicalAssessmentSectionEnabled && prototypeFeatureEnabled('demo_medical_assessment')">
              <a class="nav-link" :href="getUrl('medical-assessment-section')">{{$t('medical_assessment')}}</a>
            </li>
            <li class="nav-item" @click="toggleSideNav">
              <a class="nav-link" :href="getUrl(`${organRoute}-specific-details`)">{{$t('spec', { specific_details: $t('specific_details'), organ: $t(organName+"1").toString() } )}}</a>
            </li>
            <li class="nav-item" @click="toggleSideNav">
              <a class="nav-link" :href="getUrl('donor-acceptability-section')">{{$t('donor_acceptability_criteria')}}</a>
            </li>
            <li class="nav-item" @click="toggleSideNav">
              <a class="nav-link" :href="getUrl('waitlist-section')">{{$t('waitlist')}}</a>
            </li>
            <li class="nav-item" @click="toggleSideNav">
              <a class="nav-link" :href="getUrl('transplant-details-section')">{{$t('transplant_details')}}</a>
            </li>
            <li class="nav-item" @click="toggleSideNav">
              <a class="nav-link" :href="getUrl('post-transplant-section')">{{$t('post_transplant_follow_up')}}</a>
            </li>
            <li class="nav-item" @click="toggleSideNav" v-if="groupExists('journey_policy_exemption') && isPolicyExemptionsEnabled">
              <a class="nav-link" :href="getSubPage('policy-exemptions-section')">{{$t('policy_exemptions')}}</a>
            </li>
          </ul>
        </template>
      </li>
    </ul>
    <selected-recipient-offer-links
      @toggle="toggleSideNav"
      :journeyName="journeyName"
      :journeyId="journeyId"
      :organCode="organCode"
      :key="journeyId"
      :active="offerPage"
    />
  </div>
</template>

<i18n src="../../_locales/Organs.json"></i18n>
<i18n src="@/components/recipients/_locales/SelectedRecipientJourneyLinks.json"></i18n>

<script lang="ts">
import { State, Getter } from 'vuex-class';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { organCodeLookup } from '@/types';
import { Recipient } from '@/store/recipients/types';
import { RecipientJourney } from '@/store/recipientJourney/types';
import { OrganCodeValue } from '@/store/lookups/types';
import { SmallBowelDetails } from '@/store/organSpecificDetails/types';
import { SystemModules } from '@/store/features/types';
import SelectedRecipientOfferLinks from '@/components/prototypes/offers/SelectedRecipientOfferLinks.vue';



@Component({
  components: {
    SelectedRecipientOfferLinks
  }
})
export default class SelectedRecipientJourneyLinks extends Vue {
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) private journey!: RecipientJourney;

  @Getter("recipientAge", { namespace: "recipients" }) recipientAge!: number;
  @Getter("includeStomach", { namespace: "recipients" }) includeStomach!: (journeyId?: string) => boolean;
  @Getter("journeyName", { namespace: "recipients" }) journeyNameWithStomach!: (organCode?: number, journeyId?: string) => string;
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;
  @Getter('groupExists', { namespace: 'validations' }) private groupExists!: (groupName: string) => boolean;
  @Getter("moduleEnabled", { namespace: "features" }) private moduleEnabled!: (module: string) => boolean;
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;

  @Prop() journeyId!: string;
  @Prop() journeyName!: string;
  @Prop() organCode!: number;
  @Prop({ default: false }) offerPage!: boolean;

  show = false;

  public toggleNav() {
    this.show = !this.show;
  }

  public mounted() {
    this.show = this.isActive;
  }

  public toggleSideNav(): void {
    if (window.innerWidth < 992) { 
       this.$emit("toggle");
    }
  }

  @Watch('$route', { immediate: true, deep: true })
  public onUrlChange() {
    this.show = this.isActive;
  }

  get isActive(): boolean {
    const organId = this.$route.params.organ_id;
    if (!!organId) {
      return this.journeyId == organId;
    }
    return false;
  }
  
  /**
   * Return organ name based on the organ code, with + Stomach if needed
   * 
   * @returns {string} organ name 
   */
  get organName(): string {
    return this.journeyNameWithStomach(this.organCode, this.journeyId);
  }

  // Is the Consultation Section module enabled?
  get isConsultationSectionEnabled(): boolean {
    return this.moduleEnabled(SystemModules.CONSULTATION_SECTION);
  }

  // Is the Medical Assessment Section module enabled?
  get isMedicalAssessmentSectionEnabled(): boolean {
    return this.moduleEnabled(SystemModules.MEDICAL_ASSESSMENT_SECTION);
  }

  // Is the Policy Exemptions module enabled?
  get isPolicyExemptionsEnabled(): boolean {
    return this.moduleEnabled(SystemModules.POLICY_EXEMPTIONS);
  }

  // Is the Clinical Transaction module enabled?
  get isClinicalTransactionEnabled(): boolean {
    return this.moduleEnabled(SystemModules.CLINICAL_TRANSACTIONS);
  }

  /**
   * Return organ route from the organ code using organCodeLookup
   * 
   * @returns {string} organ name 
   */
  get organRoute(): string {
    if (!this.organCode) {
      return '';
    }
    return organCodeLookup[this.organCode] || '';
  }

  public getUrl(hash: string): string {
    hash = `#${hash}`;
    
    const currentRoute = this.$router.currentRoute;
    if (currentRoute.name == 'edit-organ-policy_exemptions') {
      const location = this.$router.resolve({ name: 'edit-organ', params: { organ_id: this.journeyId }, hash: hash });
      return location.href;
    }

    if (this.isActive && !this.offerPage) {
      return hash;
    } else {
      // We are fine not checking for the existence of client_id as for new recipients we only ever show this in active mode
      const location = this.$router.resolve({ name: 'edit-organ', params: { organ_id: this.journeyId }, hash: hash });
      return location.href;
    }
  }

  public getSubPage(subPage: string): string {
    const location = this.$router.resolve({ name: 'edit-organ-policy_exemptions', params: { organ_id: this.journeyId } });
    return location.href;
  }
}
</script>
