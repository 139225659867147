<template>
    <div v-if="!journey">
      <page-top>
        <span class="skeleton-box" style="width: 200px" />
      </page-top>
      <div class="content-wrap">
        <div class="container-fluid">
          <div class="nav-wrapper">
            <loading-side-nav />
            <div class="page-content">
              <loading-donor-page />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
    <page-top>
      <router-link :to="{ name: 'list-recipients' }">
          {{$t('recipients')}}
      </router-link>
      /
      <router-link :to="{ name: 'edit-recipient', params: { id: recipientId} }">
          {{recipientDisplayName}}
      </router-link>
      / {{$t('recipient_offers')}} - {{organDescription}}
    </page-top>
    <recipient-sticky-summary></recipient-sticky-summary>
    <div class="content-wrap">
      <div class="container-fluid">
        <div class="nav-wrapper">
          <side-nav-recipient-offers />
          <div class="page-content">
            <div class="offers-wrap" style="padding-top: 10px;">
              <form>
                <!-- NOTE: here we are re-using the actual Recipient Summary -->
                <recipient-summary/>

                <!-- Afflo Prototype: Offer Statistics -->
                <offer-statistics />

                <!-- Afflo Prototype: Current Offers -->
                <current-offers
                  @openCompareModal="openCompareModal"
                />

                <!-- Afflo Prototype: Offer History -->
                <offer-history
                  @openCompareModal="openCompareModal"
                />

                <!-- NOTE: here we are re-using the actual Serum Crossmatch Section -->
                <serum-crossmatch-section ref="serumCrossmatch" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <compare-modal ref="recipientOffersCompareModal" />
  </div>
</template>

<i18n src="@/views/offers/_locales/RecipientOffers.json"></i18n>

<script lang="ts">
import CompareModal from '@/components/organs/shared/offers/CompareModal.vue';
import { OrganCodeValue } from '@/store/lookups/types';
import { Getter, State } from 'vuex-class';
import { Recipient } from '@/store/recipients/types';
import PageTop from '@/components/shared/PageTop.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import RecipientSummary from '@/components/recipients/RecipientSummary.vue';
import RecipientStickySummary from '@/components/recipients/RecipientStickySummary.vue';
import { organCodeLookup } from '@/types';
import { RecipientJourney } from '@/store/recipientJourney/types';
import SideNavRecipientOffers from "@/components/recipients/side-nav/SideNavRecipientOffers.vue";
import OfferStatistics from '@/components/prototypes/offers/OfferStatistics.vue';
import CurrentOffers from '@/components/prototypes/offers/CurrentOffers.vue';
import OfferHistory from '@/components/prototypes/offers/OfferHistory.vue';
import SerumCrossmatchSection from '@/components/deceasedDonors/_SerumCrossmatchSection.vue';
import LoadingSideNav from '@/components/shared/side-nav/LoadingSideNav.vue';
import LoadingDonorPage from '@/components/shared/LoadingDonorPage.vue';

@Component({
  components: {
    CompareModal,
    PageTop,
    RecipientSummary,
    RecipientStickySummary,
    SideNavRecipientOffers,
    OfferStatistics,
    CurrentOffers,
    OfferHistory,
    SerumCrossmatchSection,
    LoadingSideNav,
    LoadingDonorPage
  },
})
export default class RecipientOffers extends Vue {
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;

  // Getters
  @Getter('recipientDisplayName', { namespace: 'recipients' } ) private recipientDisplayName!: string;
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter("includeStomach", { namespace: "recipients" }) includeStomach!: (journeyId?: string) => boolean;
  @Getter('journeyId', { namespace: 'journeyState', }) journeyId!: string|undefined;

  private sectionsLoaded = new Set();
  private allSectionsLoaded = false;

  get isLoaded(): boolean {
    return this.allSectionsLoaded;
  }

  public loaded(ref: string): void {
    if (!ref) return;
    // Create a set of all the sections to load filtering out validations and the save button
    const sectionsToLoad = new Set(Object.keys(this.$refs).filter((ref: string) => !ref.match(/validations/)));
    // Add the ref we just loaded
    this.sectionsLoaded.add(ref);
    if (this.sectionsLoaded.size === sectionsToLoad.size) {
      this.$store.dispatch('utilities/scrollBehavior');
      this.allSectionsLoaded = true;
    }
  }
  
  /**
   * Gets the recipients client_id.
   *
   * Converts the recipients client_id to a string
   *
   * @returns {String} organ as lowercase string or empty string
   */
  get recipientId(): string {
    return String(this.recipient.client_id);
  }

  // run scrollbehaviour on child component being fully loaded

  // Vue lifecycle hooks
  private mounted(): void {
    this.$store.commit('setPageTitle', `${this.$t('recipients')} / ${this.recipientDisplayName} / ${this.$t('recipient_offers')}`);
  }

  // Show the Recipient/Donor comparison pop-up for the specified row
  private openCompareModal(donorId: any): void {
    (this.$refs.recipientOffersCompareModal as CompareModal).initializeAllocationCompare(donorId);
  }

  /**
   * Return titlized organ name
   *
   * @returns {string} organ name titlized
   */
  get organDescription(): string {
    let organName = this.organComponent;
    if (this.includeStomach(this.journeyId) && this.journey.organ_code == OrganCodeValue.SmallBowel) {
      return organName = this.$t(`${organName} + Stomach`).toString();
    } else {
      return this.$t(organName).toString();
    }
  }

  /**
   * Gets the current journey as lower case
   *
   * Using the organ code, return the lower case organ name
   *
   * @returns {string} organ as lower case
   */
  get organComponent(): string {
    return organCodeLookup[`${this.journey ? this.journey.organ_code : ''}`] || '';
  }
}
</script>
