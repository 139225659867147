<template>
  <base-widget :title="$t('transplant_in_progress')" :preferences="preferences.transplantInProgress" v-if="editState">
    <template v-slot:icon>
      <div class="circle dashboard-icon circle-active-allocations">
        <font-awesome-icon :icon="['fac', 'heart']" />
      </div>
    </template>
    <template v-slot:linkID>
      <a href="#idTransplantInProgress" class="nav-link card-header-btn py-0" data-toggle="collapse">
        <font-awesome-icon :icon="['far', 'cog']" data-toggle="tooltip" :title="$t('customize_panel')" />
      </a>
      <a href="#bodyTransplantInProgress" class="nav-link card-header-btn py-0" data-toggle="collapse">
        <font-awesome-icon :icon="['far', 'caret-down']" data-toggle="tooltip" :title="$t('collapse_panel')" />
      </a>
    </template>
    <template v-slot:options>
      <div class="card-body card-options p-0 collapse" id="idTransplantInProgress">
        <div class="customize-panel">
          <h4>{{$t('panel_options')}}</h4>
          <!-- Widget options-->
          <slot name="options">
            <form>
              <div class="form-row mb-3">
                <div class="col-sm-6">
                  <select-input
                    name="panel-style"
                    select-id='dashTransplantInProgressStyle'
                    v-model="editState.style"
                    :label="$t('panel_style')"
                    :options="getPanelStyles"
                    />
                </div>
              </div>
              <div class="sub-divider"></div>
              <div class="form-row mb-3">
                <div class="col-sm-6">
                  <checkbox-input
                    input-id='dashTransplantInProgressVisible'
                    v-model="editState.visible"
                    :label="$t('show_panel')"
                    :disabled="requiredWidget"
                  />
                </div>
              </div>
              <div class="sub-divider"></div>
              <div class="col-sm-12" v-if="errorMessage">
                <p>{{errorMessage}}</p>
              </div>
              <button
                type="button"
                class="btn btn-sm btn-wide btn-success"
                data-target="#idTransplantInProgress"
                @click="saveSettings()"
              >
                {{$t('save')}}
              </button>
            </form>
          </slot>
        </div>
      </div>
    </template>
    <template v-slot:widget-contents v-if="isWidgetLoaded">
      <div class="card-body collapse show" id="bodyTransplantInProgress">
      <template v-if="!getTransplantInProgress">
        <div class="content-wrap">
          <div class="container-fluid">
            <loading-table-view :rows="5" :columns="8"/>
          </div>
        </div>
       </template>
        <sub-section
          sub-section-id="transplantInProgressTable"
          :lookupsToLoad="lookupsToLoad"
          title=""
          :total-records="getTransplantInProgress.count"
          :table-config="tableConfig"
          :pagination-options="tableConfig.paginationOptions"
          v-if="getTransplantInProgress"
        >
          <template v-slot:table-cell="props">
            <template v-if="props.column.field == 'deceased_donor_id'">
              <router-link
                class="table-link"
                :to="{ name: 'edit-deceased-donor', params: { id: props.row.client_id } }"
                :title="$t('link_donor_page')"
                :alt="props.row.deceased_donor_id"
                target="_blank"
              >
                {{ props.row.deceased_donor_id ? props.formattedRow[props.column.field] : $t('unknown') }}
              </router-link>
            </template>
            <template v-else-if="props.column.field == 'recipient_id'">
              <router-link
                class="table-link"
                :to="{ name: 'edit-recipient', params: { id: props.row.recipient_id } }"
                :title="$t('link_recipient_page')"
                :alt="props.row.recipient_id"
                target="_blank"
              >
                {{ props.row.deceased_donor_id ? props.formattedRow[props.column.field] : $t('unknown') }}
              </router-link>
            </template>
            <template v-else>
              {{ props.formattedRow[props.column.field] }}
            </template>
          </template>
          <template slot="emptystate">
            {{$t('no_tip')}}
          </template>
        </sub-section>
      </div>
    </template>
  </base-widget>
</template>

<i18n src="./_locales/common.json"></i18n>
<i18n src="./_locales/widgetTransplantInProgress.json"></i18n>

<script lang="ts">
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-class';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import BaseWidget from '@/components/dashboard/widgets/_BaseWidget.vue';
import { DashboardSettingState, WidgetSettings, TransplantInProgressResponse, TransplantInProgressRow } from '@/store/dashboard/types';
import { TableConfig } from '@/types';
import SubSection from '@/components/shared/SubSection.vue';
import TextInput from '@/components/shared/TextInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import { SaveResult } from '@/types';
import LoadingTableView from '@/components/shared/LoadingTableView.vue';
import { Format } from "@/store/utilities/types";

@Component({
  components: {
    BaseWidget,
    SubSection,
    TextInput,
    CheckboxInput,
    SelectInput,
    LoadingTableView
  }
})
export default class WidgetTransplantInProgress extends mixins(DateUtilsMixin) {
  @Prop({ default: false }) requiredWidget!: boolean;

  @State(state => state.users.user.preferences) private preferences!: any;
  @State(state => state.pageState.currentPage.dashTransplantInProgress) private editState!: any;

  @Getter('getPreferences', { namespace: 'users'}) getPreferences!: any;
  @Getter('getTransplantInProgress', { namespace: 'dashboard' }) getTransplantInProgress!: TransplantInProgressResponse[];
  @Getter('getPanelStyles', { namespace: 'dashboard' }) getPanelStyles!: any[];
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;
  @Getter('organName', { namespace: 'lookups' }) organNameLookup!: (organCode?: number) => string;

  public errorMessage = null;

  public lookupsToLoad = ['organ', 'tgln_original_region'];

  private isWidgetLoaded = false;

  mounted(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _vm = this;
    setTimeout(() => {
      _vm.loadIfStillViewingDashboard();
    }, 5000);
  }

  /**
   * NOTE: we are relying on checking route 'name' to handle a special case for widget loading,
   * so we need to ensure this route name check matches dashboard route name in router.ts
   */
  loadIfStillViewingDashboard(): void {
    const viewingDashboard = this.$route.name === 'index';
    if (!viewingDashboard) return;

    Promise.all([
      this.$store.dispatch('dashboard/loadTransplantInProgress')
    ]).finally(() => {
      this.initializeWidget();
    });
  }

  // Setup temporary edit state for unsaved widget settings form fields
  initializeWidget(): void {
    this.errorMessage = null; // clear error message
    const preferences = this.getPreferences;
    this.$store.commit('pageState/set', {
      pageKey: 'dashTransplantInProgress',
      value: this.buildTransplantInProgressEditState(preferences),
    });
    this.isWidgetLoaded = true;
  }

  // Commit edit state field model values to vue-x store
  saveSettings(): void {
    const newState = this.extractDashboardSettings(this.preferences, this.editState);
    this.$store.dispatch('users/savePreferences', { preferences: newState }).then((success: SaveResult) => {
      // If successful dismiss dialog
      this.dismiss();
      this.initializeWidget();
      // Reinitialize if overall dashboard settings change
      this.$emit('reloadDashboard');
    }).catch((error: any) => {
      // Show error notification
      this.errorMessage = error.message;
    });
  }

  public dismiss(): void {
    $("#idTransplantInProgress").collapse('toggle');
  }

  // Build edit state based on overall dashboard settings
  buildTransplantInProgressEditState(preferences: DashboardSettingState): WidgetSettings {
    return Object.assign({}, preferences.transplantInProgress);
  }

  // Build new overall dashboard settings with new settings for this specific widget
  extractDashboardSettings(currentSettings: DashboardSettingState, editState: WidgetSettings): DashboardSettingState {
    const newSettings = Object.assign({ transplantInProgress: {} }, currentSettings);
    newSettings.transplantInProgress = {
      style: editState.style,
      visible: editState.visible
    };
    return newSettings;
  }

  get getTransplantInProgressRows(): TransplantInProgressRow[]{
    const data = this.getTransplantInProgress;
    return data.map((item: TransplantInProgressResponse) => {
      return {
        deceased_donor_id: item.deceased_donor_id,
        client_id: item.client_id,
        donor_hospital: item.donor_hospital || "--",
        recipient_id: item.recipient_id,
        recipient_name: item.recipient_first_name + ' ' + item.recipient_last_name,
        organ: item.organ_code ? this.organNameLookup(item.organ_code) : '--',
        offer_date: this.parseDisplayDateUiFromDateTime(item.offer_date),
        transplant_program: item.transplant_program,
        organ_status: item.organ_status ? item.organ_status : this.$t('tip').toString()
      };
    });
  }

  /**
   * Gets configuration for the table
   *
   * @returns {TableConfig} Configuration for the table
   */
  get tableConfig(): TableConfig {
    return {
      data: this.getTransplantInProgressRows,
      columns: [
        { label: this.$t('recipient_id'),       field: 'recipient_id', sortable: true },
        { label: this.$t('organ_name'),         field: 'organ',               sortable: true },
        { label: this.$t('recipient_name'),     field: 'recipient_name',      sortable: true },
        // { label: this.$t('transplant_program'), field: 'transplant_program',  sortable: true },
        { label: this.$t('donor_id'),           field: 'deceased_donor_id',   sortable: false},
        { label: this.$t('donor_location'),     field: 'donor_hospital',            sortable: true },
        { label: this.$t('offer_date'),         field: 'offer_date',          sortable: true,  type: 'date', dateInputFormat: Format(this.getDateFormat).DISPLAY_DATE, dateOutputFormat: Format(this.getDateFormat).DISPLAY_DATE},
        { label: this.$t('organ_status'),       field: 'organ_status',        sortable: true }
      ],
      sortOptions: {
        enabled: true,
        initialSortBy: {field: 'deceased_donor_id', type: 'asc'}
      },
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPage: 5,
        mode: 'records',
        perPageDropdown: [5, 10, 25, 100],
        dropdownAllowAll: false,
        nextLabel: '',
        prevLabel: '',
        rowsPerPageLabel: this.$t('rows_per_page_label'),
      }
    };
  }
}
</script>
