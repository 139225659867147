var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('validation-provider',{ref:"provider",attrs:{"rules":_vm.formRules,"name":_vm.name,"vid":_vm.validationId ? _vm.validationId : _vm.inputId},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{class:{ 'sr-only': _vm.hideLabel },attrs:{"for":_vm.inputId}},[_vm._v(" "+_vm._s(_vm.label || _vm.name)+" "),_c('validation-asterisk',{attrs:{"rules":_vm.formRules,"crossValues":_vm.crossValues,"ruleKey":_vm.ruleKey}}),_vm._t("link")],2),(_vm.calculated)?_c('small',{staticClass:"form-text text-muted"},[_c('font-awesome-icon',{staticClass:"text-grey",attrs:{"icon":['far', 'exclamation-circle'],"fixed-width":""}}),_vm._v(" "+_vm._s(_vm.calculatedText)+" ")],1):_vm._e(),(_vm.showToolTip)?_c('Tooltip',{attrs:{"toolTipText":_vm.toolTipText,"inputID":_vm.inputId}}):_vm._e(),(_vm.append)?[_c('div',{staticClass:"input-group mb-3"},[(_vm.isLoading)?_c('span',{staticClass:"skeleton-box w-100"}):_c('input',_vm._g({directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],class:{
          'is-invalid': !_vm.disabled && errors[0], 
          'form-control': !_vm.isReadOnly(_vm.readonly), 
          'form-control-plaintext': _vm.isReadOnly(_vm.readonly), ..._vm.cssClasses,
          'is-warning': _vm.showWarning 
          },attrs:{"id":_vm.inputId,"type":"text","readonly":_vm.isReadOnly(_vm.readonly||_vm.disabled),"maxlength":_vm.maxLength,"tabindex":_vm.readonly ? -1 : 0,"title":_vm.showTitle ? _vm.getValue : '',"aria-describedby":`${_vm.inputId}-tooltip`},domProps:{"value":_vm.getValue},on:{"input":_vm.checkLength}},_vm.inputEvents())),_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_vm._v(_vm._s(_vm.appendText))])]),(errors[0])?_c('div',{staticClass:"invalid-feedback",attrs:{"id":`${_vm.inputId}-error`}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'exclamation-circle'],"fixed-width":""}}),_vm._v(" "+_vm._s(_vm.translateError(errors, _vm.label || _vm.name))+" ")],1):_vm._e(),(!errors[0] && _vm.showWarning)?_c('div',{staticClass:"warning-feedback",attrs:{"id":`${_vm.inputId}-error`}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'exclamation-circle'],"fixed-width":""}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.getWarningMessage)}})],1):_vm._e()])]:[(_vm.isLoading)?_c('span',{staticClass:"skeleton-box w-100"}):_c('input',_vm._g({directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],class:{ 
        'is-invalid': !_vm.disabled && errors[0], 
        'form-control': !_vm.isReadOnly(_vm.readonly), 
        'form-control-plaintext': _vm.isReadOnly(_vm.readonly), ..._vm.cssClasses,
        'is-warning': _vm.showWarning 
        },attrs:{"id":_vm.inputId,"type":"text","readonly":_vm.isReadOnly(_vm.readonly||_vm.disabled),"tabindex":_vm.readonly ? -1 : 0,"placeholder":_vm.placeHolder,"title":_vm.showTitle ? _vm.getValue : '',"maxlength":_vm.maxLength,"aria-describedby":`${_vm.inputId}-tooltip`},domProps:{"value":_vm.getValue},on:{"input":_vm.checkLength}},_vm.inputEvents())),(errors[0])?_c('div',{staticClass:"invalid-feedback",attrs:{"id":`${_vm.inputId}-error`}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'exclamation-circle'],"fixed-width":""}}),_vm._v(" "+_vm._s(_vm.translateError(errors, _vm.label || _vm.name))+" ")],1):_vm._e(),(!errors[0] && _vm.showWarning)?_c('div',{staticClass:"warning-feedback",attrs:{"id":`${_vm.inputId}-error`}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'exclamation-circle'],"fixed-width":""}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.getWarningMessage)}})],1):_vm._e(),(_vm.showCharacterWarning)?_c('div',{staticClass:"warning-message",attrs:{"id":`${_vm.inputId}-warning`}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'question-circle'],"fixed-width":""}}),_vm._v(" "+_vm._s(` ${ _vm.$t('maxLengthMessage')} ${ _vm.maxLength} ${ _vm.$t('characters')}`)+" ")],1):_vm._e()]]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }