<template>
  <base-side-nav :class="{ closed: sideNavClosed }">
    <template v-slot:side-nav-content>
      <side-nav-toolbar @toggle="toggleSideNav">
        <template v-slot:return-link>
          <router-link class="nav-link no-scroll" title="Return to previous page" :aria-label="$t('control_that_returns_to_previous_page')" :to="{ name: 'list-living-donors' }">
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width />
            <span class="sr-only">
              {{$t('return_to_previous_page')}}
            </span>
          </router-link>
        </template>
      </side-nav-toolbar>
      <div class="side-nav-container">
        <template v-if="newLivingDonor">
          <selected-living-donor-profile-links @toggle="toggleSideNav" :active="true" :newDonor="newLivingDonor"/>
        </template>
        <template v-else>
          <template v-if="prototypeFeatureEnabled('demo_living_donor_inactive_liver_registration_example')">
            <selected-living-donor-organ-links @toggle="toggleSideNav" organ="Liver" :organId="1" :organCode="1" :organRoute="{ name: 'living-donor-organ', params: { organ_code: 1 } }" />
          </template>

          <template v-for="organ in selectedLivingDonorConsentedOrganList">
            <selected-living-donor-organ-links @toggle="toggleSideNav" :organ="organ.display_name" :organId="organ.id" :organCode="organ.organ_code" :organRoute="organ.route" :key="organ.id" />
          </template>
          <hr>
          <selected-living-donor-profile-links @toggle="toggleSideNav" :active="true" />
          <hr>
          <selected-living-donor-hla-links @toggle="toggleSideNav" :active="true"/>
        </template>
      </div>
    </template>
  </base-side-nav>
</template>

<i18n src="@/components/_locales/common.json"></i18n>
<i18n src="../_locales/common.json"></i18n>

<script lang="ts">
import { State, Getter } from 'vuex-class';
import { Component, Vue, Prop } from 'vue-property-decorator';
import BaseSideNav from '@/components/shared/side-nav/BaseSideNav.vue';
import SideNavToolbar from '@/components/shared/side-nav/SideNavToolbar.vue';
import SelectedLivingDonorHlaLinks from "@/components/livingDonors/side-nav/SelectedLivingDonorHlaLinks.vue";
import SelectedLivingDonorOrganLinks from "@/components/livingDonors/side-nav/SelectedLivingDonorOrganLinks.vue";
import SelectedLivingDonorProfileLinks from "@/components/livingDonors/side-nav/SelectedLivingDonorProfileLinks.vue";
import { LivingDonor, LivingDonorAllocationSummary } from '@/store/livingDonors/types';

@Component({
  components: {
    BaseSideNav,
    SideNavToolbar,
    SelectedLivingDonorHlaLinks,
    SelectedLivingDonorOrganLinks,
    SelectedLivingDonorProfileLinks,
  }
})
export default class SideNavLivingDonor extends Vue {
  @State(state => state.livingDonors.selectedLivingDonor) private livingDonor!: LivingDonor;

  @Getter('selectedLivingDonorConsentedOrganList', { namespace: 'livingDonors'}) private selectedLivingDonorConsentedOrganList!: LivingDonorAllocationSummary[];
  @Getter('getDonorsUrl', { namespace: 'users' }) private getDonorsUrl!: string;

  // Afflo Prototype
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;

  @Prop({default: false }) newLivingDonor!: boolean;

  public sideNavClosed = false;

  /**
   * Boolean used to show the side menu, if true side-nav is open
   * Changes the 'closed' class on the nav
   *
   * @returns {void} changes the sideNavClosed boolean
   */
  public toggleSideNav(): void {
    this.sideNavClosed = !this.sideNavClosed;
  }
}
</script>
