<template>
  <sub-section
    :title="$t('therapy')"
    sub-section-id="lsd-new-therapy"
    :tabbableValue="$t('bridging_therapy_date')"
    :table-config="therapyTableConfig()"
    @table-row-click="selectTherapy($event)"
    @table-create-row="createTherapy()"
  >
    <template v-slot:contents>
      <fieldset id="lsd-therapy">
        <legend>
          <h5 v-if="selectedTherapy" class="legend-title">
            {{$t('new_therapy')}}
          </h5>
          <h5 v-else class="legend-title">
            {{$t('selected_therapies')}}
          </h5>
        </legend>
        <div class="row">
          <div class="form-group col-md-4 col-lg-3">
            <label for="lsd-hcc-bridge-date">{{$t('bridging_therapy_date')}}</label>
            <input id="lsd-hcc-bridge-date" v-model="journey.date_analysis" type="date" class="form-control">
          </div>
          <div class="form-group col-md-4 col-lg-3">
            <label for="lsd-hcc-bridge-type">{{$t('bridging_therapy_type')}}</label>
            <select id="lsd-hcc-bridge-type" v-model="journey.bridging_therapy_type" class="form-control">
              <option hidden disabled selected value>
                {{$t('select')}}
              </option>
              <option>{{$t('tbd')}}</option>
            </select>
          </div>
          <div class="form-group col-md-4 col-lg-3">
            <label for="lsd-hcc-downstage-date">{{$t('downstaging_date')}}</label>
            <input id="lsd-hcc-downstage-date" v-model="journey.downstaging_date" type="date" class="form-control">
          </div>
          <div class="form-group col-md-4 col-lg-3">
            <label for="lsd-hcc-downstage-type">{{$t('downstaging_type')}}</label>
            <select id="lsd-hcc-downstage-type" v-model="journey.downstaging_type" class="form-control">
              <option hidden disabled selected value>
                {{$t('select')}}
              </option>
              <option>{{$t('tbd')}}</option>
            </select>
          </div>
          <div class="row">
          <div class="col-sm-12">
            <button
              type="button"
              :disabled="!isValidTherapy"
              :class="{ disabled: !isValidTherapy() }"
              class="btn btn-sm btn-primary btn-add-new-row mb-3"
              @click="saveTherapy()"
            >
              {{$t('save_therapy')}}
            </button>
          </div>
        </div>
        </div>
      </fieldset>
    </template>
  </sub-section>
</template>

<i18n src="@/components/organs/liver/_locales/TherapiesSection.json"></i18n>

<script lang="ts">
import { Getter } from 'vuex-class';
import { TableConfig } from '@/types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import SubSection from '@/components/shared/SubSection.vue';
import { RecipientJourney } from '@/store/recipientJourney/types';

@Component({
  components: {
    SubSection,
  }
})
export default class TherapiesSection extends Vue {
  @Getter('selectedJourney', { namespace: 'journeyState'}) private journey!: RecipientJourney;
  
  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  get therapies(): RecipientJourney {
    return this.journey;
  }

  // TODO: remove all old table config and saving
  public selectedTherapy: RecipientJourney|null = null; // value of actual existing entry, or null when creating a new one
  public therapyTableConfig(): TableConfig {
    return {
      data: [],
      columns: [
        { label: this.$t('bridging_therapy_date').toString(), field: 'date_analysis', width: '25%' },
        { label: this.$t('bridging_therapy_type').toString(), field: 'bridging_therapy_type', width: '25%' },
        { label: this.$t('downstaging_date').toString(), field: 'downstaging_date', width: '25%' },
        { label: this.$t('downstaging_type').toString(), field: 'downstaging_type', width: '25%' }
      ],
      empty: this.$t('use_form_below').toString(),
      createButton: true,
      createText: this.$t('create_therapy').toString()
    };
  }
  public isValidTherapy(): boolean {
    return true;
  }
  public saveTherapy() {
    return true;
  }
  public selectTherapy(event: any) {
    return true;
  }
  public createTherapy() {
    return true;
  }
}
</script>
