<template>
  <div>
    <div class="content-wrap page-top">
      <div class="container-fluid">
        <h2>{{$t('dashboard')}}</h2>
        <div class="page-top-right">
          <div class="system-title d-inline">{{uiEnvVersion}}&nbsp;</div>

          <div class="dropdown b-dropdown menu-big page-top-link" v-if="editState">
            <a
              href="#"
              class="dropdown-toggle"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              id="customizeDash"
            >
              <font-awesome-icon :icon="['far', 'cog']" /> {{$t('customize')}}
            </a>

            <div
              class="dropdown-menu keep-open dropdown-menu-end"
              aria-labelledby="customizeDash"
              style="max-width: 350px;"
            >
              <div class="container">
                <div class="row">
                  <form>
                    <div class="col-sm-12">
                      <h5 class="menu-big-heading">{{$t('available_dashboard_panels')}}</h5>
                    </div>
                    <div class="col-sm-12">
                      <ul class="nav flex-column" v-for="(key) in Object.keys(editState).filter((key) => widgetAllowedByKey(key))" :key="key">
                        <li>
                          <div class="checklist border-0">
                            <div class="form-check">
                              <input
                                type="checkbox"
                                class="form-check-input d-none"
                                :id="key"
                                v-model="editState[key]"
                                :disabled="widgetLockedByKey(key)"
                                />
                              <label class="form-check-label" :for="key">
                                <font-awesome-icon :icon="['far', editState[key] ? 'check-square' : 'square']" /> {{$t('show')}}
                                <span>{{$t(key)}}</span>
                              </label>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="col-sm-12" v-if="errorMessage">
                      <p>{{errorMessage}}</p>
                    </div>
                    <div class="col-sm-12">
                      <div class="big-menu-actions">
                        <button
                          type="button"
                          class="btn btn-sm btn-success mt-2 mb-3"
                          @click="savePatch()"
                        >
                          {{$t('save_preferences')}}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <!--  /.container  -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content-wrap dashboard-widgets">
      <div class="container-fluid">
        <!-- Dashboard row -->
        <div class="row">

          <!-- Notifications
          <widget-notifications
            ref="dashNotifications"
            v-if="isWidgetAllowed('notifications')"
            :requiredWidget="isWidgetRequired('notifications')"
            @reloadDashboard='initializeWidget'
          /> -->

          <!-- Afflo Prototype: Actions -->
          <widget-actions
            ref="dashActions"
            :requiredWidget="isWidgetRequired('actions')"
            @reloadDashboard='initializeWidget'
          />

          <!-- Active Allocations -->
          <!-- TP-11677 The columns: Active Donors and Active Allocations will be hidden until the related CR 2021-018 is done (After Go-Live) -->
          <!-- <widget-active-allocations
            ref="dashAllocations"
            v-if="isWidgetAllowed('active_allocations')"
            :requiredWidget="isWidgetRequired('active_allocations')"
            @reloadDashboard='initializeWidget'
          /> -->

          <!-- Active Primary Offers -->
          <widget-primary-offers
            ref="dashPrimary"
            v-if="isWidgetAllowed('active_primary_offers')"
            :requiredWidget="isWidgetRequired('active_primary_offers')"
            @reloadDashboard='initializeWidget'
          />

          <!-- Active Backup Offers -->
          <widget-backup-offers
            ref="dashBackup"
            v-if="isWidgetAllowed('active_backup_offers')"
            :requiredWidget="isWidgetRequired('active_backup_offers')"
            @reloadDashboard='initializeWidget'
          />

          <!-- Active Donors  -->
          <!-- <widget-active-donors/> TP-12599 Hidding until CR "Allocation states and Allocation Complete button" is implemented -->

          <!-- Transplant in Progress -->
          <widget-transplant-in-progress
            ref="dashTransplantInProgress"
            v-if="isWidgetAllowed('transplant_in_progress')"
            :requiredWidget="isWidgetRequired('transplant_in_progress')"
            @reloadDashboard='initializeWidget'
          />

          <!-- Bookmarks -->
          <widget-bookmarks
            ref="dashBookmark"
            v-if="isWidgetAllowed('bookmarks')"
            :requiredWidget="isWidgetRequired('bookmarks')"
            @reloadDashboard='initializeWidget'
          />

          <!-- Recent Recipients  -->
          <widget-recent-recipients
            ref="dashRecentRecipients"
            v-if="isWidgetAllowed('recent_recipient_registrations')"
            :requiredWidget="isWidgetRequired('recent_recipient_registrations')"
            @reloadDashboard='initializeWidget'
          />

          <!-- Recent Living Donor Registrations -->
          <widget-recent-living-donors
            ref="dashRecentLivingDonors"
            v-if="isWidgetAllowed('recent_living_donor_registrations')"
            :requiredWidget="isWidgetRequired('recent_living_donor_registrations')"
            @reloadDashboard='initializeWidget'
          />

          <!-- Recent Urgent Listings -->
          <widget-urgent-listings
            ref="dashUrgentListings"
            v-if="isWidgetAllowed('urgent_listings')"
            :requiredWidget="isWidgetRequired('urgent_listings')"
            @reloadDashboard='initializeWidget'
          />

          <!-- ExD Donors -->
          <widget-exd-donors
            ref="dashExdDonors"
            v-if="isWidgetAllowed('exd_donors')"
            :requiredWidget="isWidgetRequired('exd_donors')"
            @reloadDashboard='initializeWidget'
          />

          <!-- Donor Lab Results -->
          <widget-lab-results
            ref="dashLabResults"
            v-if="isWidgetAllowed('donor_lab_results')"
            :requiredWidget="isWidgetRequired('donor_lab_results')"
            @reloadDashboard='initializeWidget'
          />
        </div>
        <!-- /Dashboard row -->
      </div>
    </div>
  </div>
</template>

<i18n src="@/components/dashboard/_locales/Dashboard.json"></i18n>

<script lang="ts">
import { Getter, State } from 'vuex-class';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { WidgetSettings } from '@/store/dashboard/types';
import WidgetNotifications from '@/components/dashboard/widgets/WidgetNotifications.vue';
import WidgetActiveAllocations from '@/components/dashboard/widgets/WidgetActiveAllocations.vue';
import WidgetPrimaryOffers from '@/components/dashboard/widgets/WidgetPrimaryOffers.vue';
import WidgetBookmarks from '@/components/dashboard/widgets/WidgetBookmarks.vue';
import WidgetBackupOffers from '@/components/dashboard/widgets/WidgetBackupOffers.vue';
import WidgetActiveDonors from '@/components/dashboard/widgets/WidgetActiveDonors.vue';
import WidgetLabResults from '@/components/dashboard/widgets/WidgetLabResults.vue';
import WidgetRecentRecipients from '@/components/dashboard/widgets/WidgetRecentRecipients.vue';
import WidgetRecentLivingDonors from '@/components/dashboard/widgets/WidgetRecentLivingDonors.vue';
import WidgetUrgentListings from '@/components/dashboard/widgets/WidgetUrgentListings.vue';
import WidgetExdDonors from '@/components/dashboard/widgets/WidgetExdDonors.vue';
import WidgetActions from '@/components/dashboard/widgets/WidgetActions.vue';
import WidgetTransplantInProgress from '@/components/dashboard/widgets/WidgetTransplantInProgress.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { UserPreferenceData } from '@/store/users/types';
import { WidgetPermission } from '@/store/dashboard/types';

@Component({
  components: {
    WidgetActions,
    WidgetNotifications,
    WidgetActiveAllocations,
    WidgetPrimaryOffers,
    WidgetBookmarks,
    WidgetBackupOffers,
    // WidgetActiveDonors, TP-12599 Hidding until CR "Allocation states and Allocation Complete button" is implemented
    WidgetLabResults,
    WidgetRecentRecipients,
    WidgetRecentLivingDonors,
    WidgetUrgentListings,
    WidgetExdDonors,
    WidgetTransplantInProgress
  }
})

export default class Dashboard extends Vue {
  @State(state => state.uiEnvVersion) private uiEnvVersion!: string|null;
  @State(state => state.users.user.preferences) private preferences!: any;
  @State(state => state.pageState.currentPage.dashHome) private editState!: any;

  // Getters
  @Getter('getPreferences', { namespace: 'users'}) getPreferences!: any;
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('getUserWidgetPermissions', { namespace: 'users'}) getUserWidgetPermissions!: WidgetPermission[];
  @Getter('isWidgetRequired', { namespace: 'users'}) isWidgetRequired!: (widgetName: string) => boolean
  @Getter('isWidgetAllowed', { namespace: 'users'}) isWidgetAllowed!: (widgetName: string) => boolean
  @Getter('getWidgetPermission', { namespace: 'users'}) getWidgetPermission!: WidgetPermission;

  public errorMessage = null;

  private dashboardShowArray: { [key: string]: any } = {
      showActions: 'actions',
      showNotifications: 'notifications',
      // showAllocations: 'active_allocations',
      showPrimaryOffers: 'active_primary_offers',
      showBackupOffers: 'active_backup_offers',
      showActiveDonors: 'active_donors',
      showTransplantInProgress: 'transplant_in_progress',
      showBookmarks: 'bookmarks',
      showRecentRecipientsRegistrations: 'recent_recipient_registrations',
      showRecentLivingDonors: 'recent_living_donor_registrations',
      showUrgentListings: 'urgent_listings',
      showExdDonors: 'exd_donors',
      showLabResults: 'donor_lab_results'
    };

  /**
   * Returns a boolean indicating if the widget is available for the user based on a given key
   *
   * @returns {boolean} true if widget is allowed to be shown.
   */
  public widgetAllowedByKey(stateKey: string): boolean{
    if (stateKey == 'showActions') return true;

    return this.checkAllowed(`/dashboard/${this.dashboardShowArray[stateKey]}`, 'GET');
  }

  /**
   * Returns a boolean indicating if the widget is a required widget to stop editing for that widget
   *
   * @returns {boolean} true if widget is required to be shown.
   */
  public widgetLockedByKey(stateKey: string): boolean{
    if (stateKey == 'showActions') return false;

    return this.isWidgetRequired(this.dashboardShowArray[stateKey]);
  }

  /**
   * Gets the preferences payload to save.
   *
   * @returns {Oject} current Node environment
   */
  get preferencesPayload(): UserPreferenceData {
    const preferences = this.getPreferences;

    return {
      actions:              preferences.actions ? preferences.actions : {},
      notifications:        preferences.notifications ? preferences.notifications : {},
      allocations:          preferences.allocations ? preferences.allocations : {},
      primary:              preferences.primary ? preferences.primary  : {},
      transplantInProgress: preferences.transplantInProgress ? preferences.transplantInProgress  : {},
      bookmarks:            preferences.bookmarks ? preferences.bookmarks : {},
      backup:               preferences.backup ? preferences.backup : {},
      activeDonors:         preferences.activeDonors ? preferences.activeDonors : {},
      recentRecipients:     preferences.recentRecipients ? preferences.recentRecipients : {},
      recentLivingDonors:   preferences.recentLivingDonors ? preferences.recentLivingDonors : {},
      urgentListings:       preferences.urgentListings ? preferences.urgentListings : {},
      exdDonors:            preferences.exdDonors ? preferences.exdDonors : {},
      labResults:           preferences.labResults ? preferences.labResults : {},
      };
  }

  /**
   * Converts key value into titleized string, e.g. showActiveDonors -> Active Donors
   *
   * @returns {string} titleized string
   */
  convertKeyToTitleString(key: string): string {
    const str = key ? key : '';
    return str.replace('show', '').replace(/([A-Z])/g, ' $1').trim();
  }

  mounted(): void {
    Promise.all([
      this.$store.commit('setPageTitle', `Dashboard`),
      this.$store.dispatch('dashboard/loadWidgetRolePermissions'),
    ]).finally(() => {
      this.initializeWidget();
      const result = this.getUserWidgetPermissions;
    });
  }

  initializeWidget(): void {
    this.errorMessage = null; // clear error message
    const preferences = this.getPreferences || {}; // clear settings

    // rebuild preferences
    this.$store.commit('pageState/set', {
      pageKey: 'dashHome',
      value: {
        showActions: preferences.actions?.visible,
        // showNotifications: this.isWidgetRequired('notifications') || preferences.notifications?.visible,
        // showAllocations: this.isWidgetRequired('active_allocations') || preferences.allocations?.visible,
        showPrimaryOffers: this.isWidgetRequired('active_primary_offers') || preferences.primary?.visible,
        showBackupOffers: this.isWidgetRequired('active_backup_offers') || preferences.backup?.visible,
        // showActiveDonors: preferences.activeDonors?.visible, //TP-12599 Hidding until CR "Allocation states and Allocation Complete button" is implemented
        showTransplantInProgress: this.isWidgetRequired('transplant_in_progress') || preferences.transplantInProgress?.visible,
        showBookmarks: this.isWidgetRequired('bookmarks') || preferences.bookmarks?.visible,
        showRecentRecipientsRegistrations: this.isWidgetRequired('recent_recipient_registrations') || preferences.recentRecipients?.visible,
        showRecentLivingDonors: this.isWidgetRequired('recent_living_donor_registrations') || preferences.recentLivingDonors?.visible,
        showUrgentListings: this.isWidgetRequired('urgent_listings') || preferences.urgentListings?.visible,
        showExdDonors: this.isWidgetRequired('exd_donors') || preferences.exdDonors?.visible,
        showLabResults: this.isWidgetRequired('donor_lab_results') || preferences.labResults?.visible
      }
    });
  }

  // RE-initialize here
  private initializeAllSections(): void {
    // if (this.$refs.dashActions) (this.$refs.dashActions as WidgetActions).initializeWidget();
    if (this.$refs.dashPrimary) (this.$refs.dashPrimary as WidgetPrimaryOffers).initializeWidget();
    if (this.$refs.dashNotifications) (this.$refs.dashNotifications as WidgetNotifications).initializeWidget();
    if (this.$refs.dashBackup) (this.$refs.dashBackup as WidgetBackupOffers).initializeWidget();
    if (this.$refs.dashTransplantInProgress) (this.$refs.dashTransplantInProgress as WidgetTransplantInProgress).initializeWidget();
    if (this.$refs.dashBookmark) (this.$refs.dashBookmark as WidgetBookmarks).initializeBookmarkWidget();
    if (this.$refs.dashRecentRecipients) (this.$refs.dashRecentRecipients as WidgetRecentRecipients).initializeWidget();
    if (this.$refs.dashRecentLivingDonors) (this.$refs.dashRecentLivingDonors as WidgetRecentLivingDonors).initializeWidget();
    if (this.$refs.dashUrgentListings) (this.$refs.dashUrgentListings as WidgetUrgentListings).initializeWidget();
    if (this.$refs.dashExdDonors) (this.$refs.dashExdDonors as WidgetExdDonors).initializeWidget();
    if (this.$refs.dashLabResults) (this.$refs.dashLabResults as WidgetLabResults).initializeWidget();
  }

  /**
   * Saves the form edit state.
   *
   * Prepares an update payload for Donor, dispatches a save action, handle errors and registers the save result.
   */
  public savePatch(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'dashHome',
      value: this.editState
    });

    const preferencesPatch : UserPreferenceData = this.preferencesPayload;

    preferencesPatch.actions.visible = this.editState.showActions;
    preferencesPatch.notifications.visible =  this.isWidgetRequired('notifications') || this.editState.showNotifications;
    // preferencesPatch.allocations.visible = this.isWidgetRequired('active_allocations') || this.editState.showAllocations;
    preferencesPatch.primary.visible = this.isWidgetRequired('active_primary_offers') || this.editState.showPrimaryOffers;
    preferencesPatch.backup.visible = this.isWidgetRequired('active_backup_offers') || this.editState.showBackupOffers;
    // preferencesPatch.activeDonors.visible = this.editState.showActiveDonors;
    preferencesPatch.transplantInProgress.visible = this.isWidgetRequired('transplant_in_progress') || this.editState.showTransplantInProgress;
    preferencesPatch.bookmarks.visible = this.isWidgetRequired('bookmarks') || this.editState.showBookmarks;
    preferencesPatch.recentRecipients.visible = this.isWidgetRequired('recent_recipient_registrations') || this.editState.showRecentRecipientsRegistrations;
    preferencesPatch.recentLivingDonors.visible = this.isWidgetRequired('recent_living_donor_registrations') || this.editState.showRecentLivingDonors;
    preferencesPatch.urgentListings.visible = this.isWidgetRequired('urgent_listings') || this.editState.showUrgentListings;
    preferencesPatch.exdDonors.visible = this.isWidgetRequired('exd_donors') || this.editState.showExdDonors;
    preferencesPatch.labResults.visible = this.isWidgetRequired('donor_lab_results') || this.editState.showLabResults;

    this.$store.dispatch('users/savePreferences', { preferences: preferencesPatch }).then((success: SaveResult) => {
      // If successful dismiss dialog
      this.dismiss();
      this.initializeWidget();
      this.initializeAllSections();
    }).catch((error: any) => {
      // Show error notification
      this.errorMessage = error.message;
    });

  }

  public dismiss(): void {
    $("#customizeDash").dropdown('toggle');
  }
}
</script>



