<template>
  <div class="row mt-3">
    <div class="col-sm-12">
      <sub-section :title='title' sub-section-id="recipients-uploaded-files" :table-config="tableConfig"
        tabbableValue="Organ Transferred Date"
        @table-row-click="editSelected"
        @table-create-row="clearForm"
        row-style-class="tr-link"
      @on-row-click="editSelected"></sub-section>
      <sub-section ref="uploadDocument"
        :title="editstate ? 'Edit Follow-Up' : 'New Follow-Up'"
        sub-section-id="recipient-upload-document" saveButton="true"
        :save-button-text="editstate ? `${$t('Update Follow Up')}` : `${$t('Save Follow Up')}` "
        @save="uploadDocument()">
        <template v-slot:contents>
          <fieldset>
            <div class="row">
              <div class="standard-form-group">
                <select-input
                  select-id="follow-up-type"
                  name="Type of Follow-Up"
                  :options="followUpTypeOptions"
                  v-model="followUpType"
                  @change="handleFollowUpTypeChanged"
                  rules="required"
                />
              </div>
              <div class="standard-form-group">
                <date-input
                  input-id="follow-up-date-of-entry"
                  name="Date of Entry"
                  v-model="dateOfEntry"
                  :disabled="true"
                />
              </div>
            </div>

            <div class="row">
              <div class="standard-form-group-6column-xlarge-only">
                <select-input select-id="follow_up_plan" name='Long-Term Follow-Up Plan Established' :options="Options"
                  v-model="follow_up_plan" />
              </div>
              <div class="standard-form-group">
                <date-input :name="$t('Death Date')" input-id="organ_transfer_date" v-model="death_date" />
              </div>
              <select-other-input selectId="cause_of_death" name="Cause of Death" v-model="cause_of_death"
                :options="causeOfDeathCategory" :showOther="cause_of_death == '13'" @change="clearCauseOfDeathOther"
                reduceColumnWidth="standard-form-group" colStyling="standard-form-group-with-other">

                <template v-slot:other>
                  <text-input input-id="cause_of_death_other" name="Cause of Death - Other"
                    v-model="cause_of_death_other" />
                </template>
              </select-other-input>
            </div>
            <div class="row">
              <div class="standard-form-group">
                <date-input :name="$t('Date of Loss of Follow-Up')" input-id="date_of_loss_of_follow_up"
                  v-model="date_of_loss_of_follow_up" />
              </div>

              <div class="standard-form-group">
                <text-input inputId="route_of_loss_of_contact" :name="$t('Route of Loss of Contact')"
                  v-model="route_of_loss_of_contact" />
              </div>


              <div class="standard-form-group">
                <date-input inputId="kidney_failure_start_date" :name="$t('Kidney Failure Start Date')"
                  v-model="kidney_failure_start_date" />
              </div>

              
            </div>

            <div class="row">
              <div class="standard-form-group-large">
                <date-input
                  input-id="follow-up-contact-date"
                  name="Date of Clinic Visit or Contact with Source"
                  v-model="contactDate"
                />
              </div>
              <div class="standard-form-group-large">
                <text-area-input
                  input-id="follow-up-comments"
                  name="Comments"
                  v-model="comments"
                />
              </div>
            </div>

            <!-- Checklists -->
            <template v-if="selectedFollowUpTypeHasChecklist && selectedFollowUpTypeChecklistReference && !!checklist">
              <!-- Three-Month -->
              <template v-if="selectedFollowUpTypeChecklistReference == 'threeMonth'">
                <div class="hr-break" />
                <fieldset>
                  <legend>
                    <h4 class="legend-title">
                      <span>Three-Month Follow-Up Checklist</span>
                    </h4>
                  </legend>
                  <div class="row">
                    <div class="standard-form-group">
                      <select-input
                        select-id="three-month-checklist-follow-up-care"
                        name="Follow-Up Care"
                        :options="followUpCareOptions"
                        v-model="checklist.followUpCare"
                      />
                    </div>
                    <div class="standard-form-group">
                      <date-input
                        select-id="three-month-checklist-date-of-loss-of-contact"
                        name="Date of Loss of Contact"
                        v-model="checklist.dateOfLossOfContact"
                      />
                    </div>
                    <div class="standard-form-group">
                      <select-input
                        select-id="three-month-checklist-reason-for-loss-of-contact"
                        name="Reason for loss of contact"
                        :options="reasonForLossOfContactOptions"
                        v-model="checklist.reasonForLossOfContact"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="standard-form-group">
                      <select-input
                        select-id="three-month-checklist-patient-status"
                        name="Living Donor Status"
                        :options="livingDonorStatusOptions"
                        v-model="checklist.livingDonorStatus"
                      />
                    </div>
                    <div class="standard-form-group">
                      <select-input
                        select-id="three-month-checklist-if-deceased-primary-cod"
                        name="If Deceased: Primary COD"
                        v-model="causeOfDeathCategory"
                        :options="causeOfDeathCategoryLookup"
                        :disabled="true"
                      />
                    </div>
                    <div class="standard-form-group">
                      <select-input
                        select-id="three-month-checklist-if-deceased-secondary-cod"
                        name="If Deceased: Secondary COD"
                        v-model="causeOfDeathType"
                        :options="causeOfDeathTypeLookup"
                        :disabled="true"
                      />
                    </div>
                    <div class="standard-form-group">
                      <date-input
                        inputId="three-month-checklist-date-of-death"
                        name="Date of Death"
                        v-model="deathDate"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </fieldset>
              </template>
              <!-- One-Year -->
              <template v-if="selectedFollowUpTypeChecklistReference == 'oneYear'">
                <div class="hr-break" />
                <fieldset>
                  <legend>
                    <h4 class="legend-title">
                      <span>One-Year Follow-Up Checklist</span>
                    </h4>
                  </legend>
                  <div class="row">
                    <div class="standard-form-group">
                      <select-input
                        select-id="one-year-checklist-follow-up-care"
                        name="Follow-Up Care"
                        :options="followUpCareOptions"
                        v-model="checklist.followUpCare"
                      />
                    </div>
                    <div class="standard-form-group">
                      <date-input
                        input-id="one-year-checklist-date-of-loss-of-contact"
                        name="Date of Loss of Contact"
                        v-model="checklist.dateOfLossOfContact"
                      />
                    </div>
                    <div class="standard-form-group">
                      <select-input
                        select-id="one-year-checklist-reason-for-loss-of-contact"
                        name="Reason for loss of contact"
                        :options="reasonForLossOfContactOptions"
                        v-model="checklist.reasonForLossOfContact"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="standard-form-group">
                      <select-input
                        select-id="one-year-checklist-patient-status"
                        name="Living Donor Status"
                        :options="livingDonorStatusOptions"
                        v-model="checklist.livingDonorStatus"
                      />
                    </div>
                    <div class="standard-form-group">
                      <select-input
                        select-id="one-year-checklist-if-deceased-primary-cod"
                        name="If Deceased: Primary COD"
                        v-model="causeOfDeathCategory"
                        :options="causeOfDeathCategoryLookup"
                        :disabled="true"
                      />
                    </div>
                    <div class="standard-form-group">
                      <select-input
                        select-id="one-year-checklist-if-deceased-secondary-cod"
                        name="If Deceased: Secondary COD"
                        v-model="causeOfDeathType"
                        :options="causeOfDeathTypeLookup"
                        :disabled="true"
                      />
                    </div>
                    <div class="standard-form-group">
                      <date-input
                        inputId="one-year-checklist-date-of-death"
                        name="Date of Death"
                        v-model="deathDate"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </fieldset>
              </template>
            </template>

          </fieldset>
        </template>

      </sub-section>

    </div>
  </div>
</template>

<i18n src="./_locales/PostDonation.json"></i18n>

<script lang="ts">
  import {
    Getter,
    State,
  } from 'vuex-class';
  import {
    mixins
  } from "vue-class-component";
  import {
    Component,
    Vue,
    Prop
  } from 'vue-property-decorator';
  import CardSection from "@/components/shared/CardSection.vue";
  import SaveToolbar from '@/components/shared/SaveToolbar.vue';
  import {
    VueGoodTable
  } from 'vue-good-table';
  import SelectInput from '@/components/shared/SelectInput.vue';
  import CheckboxInput from '@/components/shared/CheckboxInput.vue';
  import {
    GenericCodeValue
  } from '@/store/types';
  import TextInput from '@/components/shared/TextInput.vue';
  import TextAreaInput from '@/components/shared/TextAreaInput.vue';
  import SubSection from '@/components/shared/SubSection.vue';
  import DateInput from '@/components/shared/DateInput.vue';
  import TimeInput from '@/components/shared/TimeInput.vue';
  import SelectOtherInput from '@/components/shared/SelectOtherInput.vue';
  import {
    Hospital
  } from '@/store/hospitals/types';
  import {
    TableConfig
  } from '@/types';
  import {
    Recipient
  } from "@/store/recipients/types";
  import {
    UserDetails
  } from '@/store/userAccounts/types';
  import {
    DateUtilsMixin
  } from "@/mixins/date-utils-mixin";
  import BooleanRadioInput from '@/components/shared/BooleanRadioInput.vue';

  interface TableRow {
    id: string;
    category ? : string;

    date ? : string;
    fileName ? : string;

    fileType ? : string;
    description ? : string;
    uploadedBy ? : string;
  }

  export interface FollowUpTypeOption extends GenericCodeValue {
    hasChecklist: boolean;
    checklistReference?: string;
  }

  @Component({
    components: {
      CardSection,
      SaveToolbar,
      SubSection,
      DateInput,
      TimeInput,
      SelectInput,
      TextInput,
      CheckboxInput,
      TextAreaInput,
      VueGoodTable,
      SelectOtherInput,
      BooleanRadioInput,
    }
  })
  export default class RecipientDocuments extends mixins(DateUtilsMixin) {

    @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
    @State(state => state.userAccounts.selected) private selected!: UserDetails;
    @State(state => state.pageState.currentPage.journeyStatus) editState!: any;


    @Prop({
      default: null
    }) columns!: any;
    @Prop({
      default: null
    }) tableData!: any;
    @Prop({
      default: null
    }) title!: string;
    @Prop({
      default: null
    }) localStorageRef!: string;
    @Getter('getUser', {
      namespace: 'users'
    }) private getUser!: any;

    @Prop({
      default: ''
    }) currentHospital!: string;
    @Getter('prototypeFeatureEnabled', {
      namespace: 'features'
    }) private prototypeFeatureEnabled!: (featureName: string) => boolean;
    @Getter('regionTransplantOptionsByOrgan', {
      namespace: 'hospitals'
    }) regionTransplantOptionsByOrgan!: (organCode ? : number) => GenericCodeValue[];
    @Getter('currentFollowUpHospitalId', {
      namespace: 'journeyState'
    }) currentFollowUpHospitalId!: string | null;
    @Getter('getHospitalById', {
      namespace: 'hospitals'
    }) getHospitalById!: (hospitalCode ? : string | null) => Hospital | null;
    @Getter('optionsFor', { namespace: 'lookups' }) optionsFor!: (items: string[]) => GenericCodeValue[];

    loaded() {
      // Parse tasks from local storage if found, otherwise default to JSON file contents
      let formState;
      const defaultState = {
        transfers: this.tableData.rows
      };
      const items: any = localStorage.getItem(this.localStorageRef);
      if (items) {
        try {
          formState = JSON.parse(items);
        } catch (error) {
          console.warn('Warning: Cannot parse tasklist from local storage, setting default tasks.', error);
          formState = defaultState;
        }
      } else {
        formState = defaultState;
      }
      this.$store.commit("pageState/set", {
        pageKey: "journeyStatus",
        value: formState,
      });
      this.transfers = formState.transfers || [];
    }

    private mounted(): void {
      Promise.all([
        this.$store.dispatch('hospitals/load'),
      ]).finally(() => {
        this.loaded();
      });
    }

    private id = null;
    private dateOfEntry = this.currentDateUi();
    private followUpType: string|null = null;
    private follow_up_plan = '';
    private death_date = '';
    private cause_of_death = '';
    private cause_of_death_other = '';
    private date_of_loss_of_follow_up = '';
    private route_of_loss_of_contact = '';
    private kidney_failure_start_date = null;
    private contactDate = null;
    private comments = '';
    private checklist: {
      type: string|null;
      [key: string]: any;
    } = {
      type: null,
    };

    private isTableUpdating = false;
    private taskInProgress = false;
    public perPage = 10;
    public currentPageTable = 1;
    private transfers: any = [];
    private isLoading = true;
    private isSubSectionLoading = true;
    private editstate = false
    public hospitalsToLoad = ['all'];

    private Options = [{
      code: 0,
      value: 'Yes'
    }, {
      code: 1,
      value: 'No'
    }, {
      code: 2,
      value: 'Unknown'
    }];
    private causeOfDeathCategory = [{
        code: 0,
        value: 'Accident'
      },
      {
        code: 1,
        value: 'Cardiac'
      },
      {
        code: 2,
        value: 'Gastrointestinal'
      },
      {
        code: 3,
        value: 'Hematologic'
      },
      {
        code: 4,
        value: 'Infection'
      },
      {
        code: 5,
        value: 'Liver disease'
      },
      {
        code: 6,
        value: 'Metabolic'
      },
      {
        code: 7,
        value: 'Miscellaneous'
      },
      {
        code: 8,
        value: 'Neurologic'
      },
      {
        code: 9,
        value: 'Renal disease'
      },
      {
        code: 10,
        value: 'Respiratory'
      },
      {
        code: 11,
        value: 'Social'
      },
      {
        code: 12,
        value: 'Vascular'
      },
      {
        code: 13,
        value: 'Other',
        other_selected: true
      },
      {
        code: 14,
        value: 'Unknown'
      }
    ];


    // Transplant programs that have access to the same {organ} as the selected journey
    get followUpHospitalOptions(): GenericCodeValue[] {

      return this.regionTransplantOptionsByOrgan(3);
    }

    public clearCauseOfDeathOther() {
      this.cause_of_death_other = '';
    }

    // Process changes to Follow-Up Hospital dropdown in edit state=

    get tableRows() {
      const transfers = this.transfers || [];
      const results = transfers.map((item: any) => {
        return {
          id: item.id,
          dateOfEntry: this.parseDisplayDateUi(item.dateOfEntry),
          followUpType: null,
          follow_up_plan: item.follow_up_plan,
          follow_up_plan_value: item.follow_up_plan ? this.getOptionValue(item.follow_up_plan, this.Options, null) : null,
          death_date: this.parseDisplayDateUi(item.death_date),
          cause_of_death: item.cause_of_death,
          cause_of_death_value: item.cause_of_death ? this.getOptionValue(item.cause_of_death, this.causeOfDeathCategory, item.cause_of_death_other) : null,
          date_of_loss_of_follow_up: this.parseDisplayDateUi(item.date_of_loss_of_follow_up),
          route_of_loss_of_contact: item.route_of_loss_of_contact,
          kidney_failure_start_date: this.parseDisplayDateUi(item.kidney_failure_start_date),
          contactDate: null,
          comments: '',
          checklist: {
            type: null,
          },
        };
      });
      return results;
    }

    get tableConfig(): TableConfig {
      return {
        data: this.tableRows,
        columns: this.columns,
        createButton: true,
        createText: `${this.$t('attach_new_document')}`,
        sortOptions: {
          enabled: false,
        },
        paginationOptions: {
          enabled: true,
          mode: 'records',
          perPage: this.perPage,
          perPageDropdown: [10, 25, 50],
          setCurrentPage: this.currentPageTable,
          dropdownAllowAll: true,
          nextLabel: '',
          prevLabel: '',
          rowsPerPageLabel: this.$t('table.results'),
        },
      };
    }

    private getHospitalName(id: any) {
      const hospital = this.getHospitalById(id);
      return hospital ? hospital.hospital_name_info.name : null;
    }

    private editSelected(row: any) {
      if (row) {
        this.id = row.row.id;
        this.editstate = true;
        this.dateOfEntry = row.row.dateOfEntry;
        this.followUpType = null;
        this.follow_up_plan = row.row.follow_up_plan;
        this.death_date = row.row.death_date;        
        this.cause_of_death = row.row.cause_of_death;
        this.cause_of_death_other = row.row.cause_of_death_value;        
        this.date_of_loss_of_follow_up = row.row.date_of_loss_of_follow_up;
        this.route_of_loss_of_contact = row.row.route_of_loss_of_contact;
        this.kidney_failure_start_date = row.row.kidney_failure_start_date;
        this.contactDate = row.row.contactDate;
        this.comments = row.row.comments;
        this.checklist = {
          type: null,
        };
      }
    }

    get emptyMessage(): string {
      if (!this.transfers) {
        return this.$t('loading').toString();
      } else {
        return this.$t('use_form_below_follow_up').toString();
      }
    }


    public resetSaveToolbar(): void {
      // Refer to the save toolbar that handles this page
      const saveToolbar = (this.$refs.uploadDocument as SubSection).$refs[
        'save-recipient-upload-document'] as SaveToolbar;
      saveToolbar.reset();
    }


    get getUserFullName(): string | undefined {
      let user = this.getUser;
      return user.name;
    }

    public getOptionValue(item: any, options: any, other: any) {
      if (other) {
        return other;
      } else {

      
      return options[item as any].value;
      }
    }

    public clearForm(): any {
      this.id = null;
      this.dateOfEntry = this.currentDateUi();
      this.followUpType = null,
      this.follow_up_plan = '';
      this.death_date = '';
      this.cause_of_death = '';
      this.cause_of_death_other = '';
      this.date_of_loss_of_follow_up = '';
      this.route_of_loss_of_contact = '';
      this.kidney_failure_start_date = null;
      this.contactDate = null;
      this.comments = '';
      this.checklist = {
        type: null,
      };
      this.$emit('clear');
      this.resetSaveToolbar();
      this.editstate = false;
    }

    public uploadDocument(): any {
      this.isTableUpdating = true;
      let transfers = this.transfers || [];

      


      if (!this.editstate) {
        const payload: any = {
          id: Math.random().toString(16).slice(2),
          dateOfEntry: this.currentDateUi(),
          followUpType: null,
          follow_up_plan: this.follow_up_plan,
          death_date: this.death_date,
          cause_of_death: this.cause_of_death,
          cause_of_death_other: this.cause_of_death_other,
          date_of_loss_of_follow_up: this.date_of_loss_of_follow_up,
          route_of_loss_of_contact: this.route_of_loss_of_contact,
          kidney_failure_start_date: this.kidney_failure_start_date,
          contactDate: this.contactDate,
          comments: this.comments,
          checklist: {
            type: null,
          },
        };

        transfers.push(payload);
      } else {
        transfers.map((item: any) => {
          if (item.id === this.id) {
            item.dateOfEntry = this.currentDateUi(),
            item.followUpType = this.followUpType;
            item.follow_up_plan = this.follow_up_plan;
            item.death_date = this.death_date;
            item.cause_of_death = this.cause_of_death;
            item.cause_of_death_other = this.cause_of_death_other;
            item.date_of_loss_of_follow_up = this.date_of_loss_of_follow_up;
            item.route_of_loss_of_contact = this.route_of_loss_of_contact;
            item.kidney_failure_start_date = this.kidney_failure_start_date;
            item.contactDate = this.contactDate;
            item.comments = this.comments;
            item.checklist = this.checklist;
          }
        });

      }

      this.transfers = transfers;

      const defaultState = {
        transfers: transfers
      };

      localStorage.setItem(this.localStorageRef, JSON.stringify(defaultState));

      this.isTableUpdating = false;
      this.editstate = false;
      this.clearForm();
    }


  // Afflo Prototype

  get followUpTypeOptions(): FollowUpTypeOption[] {
    return [
      {
        code: 'three-month-follow-up',
        value: "Three-Month Follow-Up",
        hasChecklist: true,
        checklistReference: 'threeMonth',
      },
      {
        code: 'one-year-follow-up',
        value: "One-Year Follow-Up",
        hasChecklist: true,
        checklistReference: 'oneYear',
      },
      {
        code: 'ad-hoc',
        value: "Ad hoc (no checklist)",
        hasChecklist: false,
      },
    ];
  }

  get followUpCareOptions(): GenericCodeValue[] {
    return this.optionsFor([
      "TxC",
      "Non-TxP specialty",
      "PCP",
    ]);
  }

  get reasonForLossOfContactOptions(): GenericCodeValue[] {
    return this.optionsFor([
      'Change TxC',
      'Contact info outdated',
      'Death',
      'Moved',
      'Unresponsive',
    ]);
  }

  get livingDonorStatusOptions(): GenericCodeValue[] {
    return this.optionsFor([
      'Living',
      'Deceased',
    ]);
  }

  get diseaseRecurranceOptions(): GenericCodeValue[] {
    return this.optionsFor([
      'No recurrence',
      'Suspected recurrence',
      'Biopsy confirmed recurrence',
      'Unknown',
    ]);
  }

  get graftStatusOptions(): GenericCodeValue[] {
    return this.optionsFor([
      'Failed',
      'Functioning',
    ]);
  }

  get dialysisChronicTemporaryOptions(): GenericCodeValue[] {
    return this.optionsFor([
      'Chronic',
      'Temporary',
    ]);
  }

  get antiRejectionOptions(): GenericCodeValue[] {
    return this.optionsFor([
      'Yes-Treated w/ Anti Rejection',
      'Yes-Not treated w/ Anti Rejection',
      'No',
      'Unknown',
    ]);
  }

  get dsaAtTimeOfRejectionOptions(): GenericCodeValue[] {
    return this.optionsFor([
      'Absent',
      'Present (de novo)',
      'Present (Pre-existing)',
      'Test not done',
      'Undetermined',
    ]);
  }

  get infectionTypeOptions(): GenericCodeValue[] {
    return this.optionsFor([
      'Bacterial',
      'Fungal',
      'Viral',
      'Unknown',
    ]);
  }

  get infectionSourceOptions(): GenericCodeValue[] {
    return this.optionsFor([
      'Reactivation of Latent Infection',
      'Donor Derived Infection',
      'Newly Acquired',
      'Unknown',
    ]);
  }

  get malignancyTypeOptions(): GenericCodeValue[] {
    return this.optionsFor([
      'Bladder CA',
      'Breast CA',
      'Cervical CA',
      'CNS Neoplasm',
      'Colorectal CA',
      'Gastric CA',
      'Genitourinary CA',
      'Esophageal CA',
      'Hepatocellular CA',
      'Leukemia',
      'Liver CA',
      'Multiple Myeloma',
      'Oropharynx CA',
      'Prostate CA',
      'Renal Cell CA',
      'Sarcoma',
      'Melanoma',
      'Skin Non-Melanoma',
      'Testis CA',
    ]);
  }

  get selectedFollowUpTypeLookup(): FollowUpTypeOption|null {
    if (!this.followUpType) return null;

    const matching = this.followUpTypeOptions.find((option: FollowUpTypeOption) => {
      return option.code == this.followUpType;
    });
    if (!matching) return null;

    return matching;
  }

  get selectedFollowUpTypeHasChecklist(): boolean {
    if (!this.selectedFollowUpTypeLookup) return false;

    return this.selectedFollowUpTypeLookup.hasChecklist || false;
  }

  get selectedFollowUpTypeChecklistReference(): string|null {
    if (!this.selectedFollowUpTypeLookup) return null;

    return this.selectedFollowUpTypeLookup.checklistReference || null;
  }

  private handleFollowUpTypeChanged(): void {
    if (!this.checklist) return;

    this.checklist = {
      type: this.selectedFollowUpTypeChecklistReference,
    };
  }
  }

</script>

<style>


</style>
