<template>
  <card-section section-id="proto_notes"
    :isAccordion="prototypeFeatureEnabled('proto_ability_to_expand_collapse')">
    <template v-slot:header>
      {{ title }}
    </template>
    <template v-slot:body>
      <div class="row">
        <div class="col-sm-12 my-3">




          <sub-section title="List of Notes" sub-section-id="recipients-filter">
            <template v-slot:contents>
              <div class="filterSectionWrapper">
                <div class="filter-section-action-row">
                  <filter-component :displayFilter="displayFilter" @updateFilter="updateFilter" keywordSearch="true"
                    :notesFilter="true" fieldID="notesFilter" :notesTagOptions="taskOptions" :archivedFilter="true"
                    :notesAuthorOptions="userOptions" :setFilters='setFilters'/>

                  <button type="button" class="btn btn-primary createButton" @click.prevent="clearForm()">
                    {{$t('Create note')}}
                  </button>
                </div>

              </div>




            </template>
          </sub-section>

          <sub-section :title='`${title} History`' sub-section-id="recipient-patient-notes">
            <template v-slot:contents>

              <div class="row">
                <!-- Uploaded Files -->
                <div class="col-sm-12">
                  <div class="table-responsive-md">
                    <div class="mb-4">
                      <div class="table-with-margin">


                      <DataTable 
                        :expandedRows.sync="tableConfig.data" 
                        :value="tableConfig.data"
                        @row-click="editSelected" 
                        :rowClass="rowStyleClass" 
                        :rowKey='id' responsiveLayout="stack"
                        rowGroupMode="subheader" groupRowsBy="id">
                        <Column v-for="col of tableConfig.columns" :field="col.field" :className="col.style"
                          :header="col.header" :key="col.field"></Column>
                          <template #groupheader="" >
                            <span aria-hidden="true">{{''}}</span>
                          </template>
                        <template #expansion="slotProps">
                          <div>
                            <div class="expansion-section expansion-tags-area" v-if="slotProps.data.tags">
                              <div class="notes-tags-wrapper">
                                <span class="notesTag" v-for="(tag, index) in slotProps.data.tags" :key="index">
                                  {{getTagValue(tag)}}
                                </span>
                              </div>
                            </div>
                            <div class="expansion-section expansion-description-area">
                              <more-less :descriptionText="slotProps.data.description" />
                            </div>
                          </div>
                        </template>
                      </DataTable>
                      </div>
                      <template >
                        <!-- <Paginator
                          :first.sync="firstSync"
                          :rows.sync="rowsSync"
                          :totalRecords="tableConfig.data.length" 
                          :rowsPerPageOptions="getPaginationDropdown"
                          @page="onPageNumber($event)"
                          template="CurrentPageReport PrevPageLink NextPageLink"
                          currentPageReportTemplate="{first} - {last} of {totalRecords}"
                          >
                          <template v-slot:start>
                            <span>{{ $t('results_per_page') }}:</span>
                            <select-input
                              selectId="results-per-page"
                              :name="$t('rows_per_page')"
                              v-model="pageSize"
                              :hideLabel="true"
                              :options="getPaginationDropdown"
                              @change="onPageSize($event)"
                              :inline="true"
                              :nullable="false"
                            />
                          </template>
                        </Paginator> -->
    </template>



                    </div>
                  </div>
                </div>
              </div>

            </template>

          </sub-section>
          <modal-section modalId="notesArchiveModal" ref="notesArchiveModal" class="modal-sticky-header"
            :centered="true" :closeButton="false" size="md" :wide="false">
            <template v-slot:title>
              {{`${modalMode} this item`}}
            </template>
            <template v-slot:body>
              <fieldset>
                <small>{{modalGuidingText}}</small>
                <div class="mt-2">
                  <div class="col-xs-12">
                    <text-input rules="required" input-id="subject" required="true" :name="`Rationale for ${modalMode}`"
                      v-model="archiveRationale" />
                  </div>
                </div>
              </fieldset>

            </template>
            <template v-slot:footer>
              <div class="modal-footer-body">
                <button type="button" data-dismiss="modal" @click="resetArchive()" class="btn btn-secondary">
                  {{ $t('Cancel') }}
                </button>
                <a class="btn btn-success" data-dismiss="modal" :class="archiveRationale == null ? 'btn-disabled': ''"
                  @click="archiveDocument(modalMode)">
                  {{ $t('OK') }}
                </a>
              </div>
            </template>
          </modal-section>

          <sub-section ref="uploadDocument" :title="editstate ? 'Edit Patient Note' : 'Add New Patient Note'"
            sub-section-id="recipient-notes-form" showCancelButton="true" cancelButtonText="Cancel"
            @cancel="clearForm()" saveButton="true"
            :save-button-text="editstate ? 'Edit Patient Note' :  'Save New Patient Note'" @archive="confirmPopup()"
            :archivedButtonText="status == 'active'? 'Archive' : 'Restore' "
            :showArchivedButton="editstate ? true : false" @save="saveNote()">
            <template v-slot:contents>
              <fieldset>
                <div class="row mt-4">
                  <div class="standard-form-group">
                    <text-input rules="required" 
                      maxLength="6" 
                      input-id="subject" 
                      v-model="subject" 
                      :name="$t('Subject')" 
                      v-modal="subject"
                      :disabled="status == 'archived'" />
                  </div>
                  <div class="standard-form-group-large">
                    <multi-select-input 
                      rules="required" 
                      select-id="tags" 
                      aria-label="Options"
                      name="Notes Tags" 
                      placeholder="Select Tags" 
                      v-model="tags" 
                      displayType="chip"
                      :disabled="status == 'archived'" 
                      :options="taskOptions"
                      showToggleAll="false" />
                  </div>

                  <div class="row-break d-none d-xl-block"></div>
                  <div class="col-sm-12">
                    <text-area-input 
                      input-id="recipientAttachment-description"
                      :name='$t("Note Entry")' 
                      maxLength="600"
                      rows="4"
                      showCounter="true"
                      :disabled="status == 'archived'" v-model="description">
                    </text-area-input>
                  </div>

                  <template v-if="editstate">
                    <div class="row-break d-none d-xl-block"></div>
                    <div class="col-sm-12 mb-4 mt-3">
                      <text-input input-id="recipientAttachment-rationale" :disabled="status == 'archived'"
                        :rules="( editstate && status == 'active')  ? 'required' : ''" :name='$t("Rationale for Edit")'
                        v-model="editRationale">
                      </text-input>
                    </div>

                    <div class="row-break d-none d-xl-block"></div>
                    <div class="col-sm-12">
                      <label>History of Edits</label>
                      <DataTable :showGridlines="true" :value="rationale_table" responsiveLayout="stack">
                        <Column field="date" header="Date" className="small-width-column">
                        </Column>
                        <Column field="rationale" header="Rationale for Edit">
                        </Column>
                        <Column field="edited_by" header="Edited By" headerStyle="width:4rem"
                          className="small-width-column">
                        </Column>
                      </DataTable>
                    </div>
                  </template>




                </div>

              </fieldset>


            </template>

          </sub-section>
        </div>
      </div>

    </template>
  </card-section>
</template>

<i18n src="../_locales/Notes.json"></i18n>

<script lang="ts">
  import {
    Getter,
    State
  } from 'vuex-class';
  import {
    mixins
  } from "vue-class-component";
  import {
    Component,
    Vue,
    Watch,
    Prop
  } from 'vue-property-decorator';
  import CardSection from "@/components/shared/CardSection.vue";
  import SaveToolbar from '@/components/shared/SaveToolbar.vue';
  import {
    VueGoodTable
  } from 'vue-good-table';
  import SelectInput from '@/components/shared/SelectInput.vue';
  import MultiSelectInput from '@/components/shared/MultiSelectInput.vue';
  import CheckboxInput from '@/components/shared/CheckboxInput.vue';
  import TextInput from '@/components/shared/TextInput.vue';
  import TextAreaInput from '@/components/shared/TextAreaInput.vue';
  import SubSection from '@/components/shared/SubSection.vue';
  import DateInput from '@/components/shared/DateInput.vue';
  import SelectOtherInput from "@/components/shared/SelectOtherInput.vue";
  import MoreLess from '@/components/shared/MoreLessComponent.vue';
  import {
    TableConfig
  } from '@/types';
  import {
    Recipient
  } from "@/store/recipients/types";
  import {
    UserDetails
  } from '@/store/userAccounts/types';
  import {
    DateUtilsMixin
  } from "@/mixins/date-utils-mixin";
  import DataTable from 'primevue/datatable';
  import Column from 'primevue/column';
  import ModalSection from '@/components/shared/ModalSection.vue';
  import FilterComponent from '@/components/prototypes/shared/_FilterComponent.vue';
  import Paginator from 'primevue/paginator';

  interface TableRow {
    id: string;
    category ? : string;

    date ? : string;
    fileName ? : string;

    fileType ? : string;
    description ? : string;
    uploadedBy ? : string;


  }
  @Component({
    components: {
      CardSection,
      SaveToolbar,
      SubSection,
      SelectInput,
      MultiSelectInput,
      TextInput,
      CheckboxInput,
      TextAreaInput,
      VueGoodTable,
      DateInput,
      DataTable,
      Column,
      MoreLess,
      ModalSection,
      FilterComponent,
      Paginator
    }
  })
  export default class RecipientDocuments extends mixins(DateUtilsMixin) {
    @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
    @State(state => state.userAccounts.selected) private selected!: UserDetails;
    // @State(state => state.pageState.currentPage.Notes) editstate!: any;


    @Prop({
      default: null
    }) columns!: any;
    @Prop({
      default: null
    }) tableData!: any;
    @Prop({
      default: null
    }) title!: string;
    @Prop({
      default: null
    }) localStorageRef!: string;

    @Getter('getUser', {
      namespace: 'users'
    }) private getUser!: any;
    @Getter('prototypeFeatureEnabled', {
      namespace: 'features'
    }) private prototypeFeatureEnabled!: (featureName: string) => boolean;

    @Watch('$route')
    loadFromStorage() {
      this.loaded();
    }


    private selectedId = '';
    private id = null;
    private subject = '';
    private date = null;
    private description = '';
    private user = '';
    private tags: any[] = [];
    private editRationale = null;
    private archiveRationale = null;
    private status = '';
    private displayArchive = false;
    private firstSync: number|null = 0;
    private rowsSync: number|null = 10;
    private pageNumber: number|null = 1;
    private pageSize: number|null = 10;

    private isTableUpdating = false;
    private taskInProgress = false;
    public perPage = 10;
    public currentPageTable = 1;
    private notes: any = [];
    private message = '';
    private editstate = false;
    private rationale_table = [];
    private search = '';
    private author = '';
    private addedDateTo = '';
    private addedDateFrom = '';
    private modalMode = ""
    private modalGuidingText = "";
    private displayFilter = true;
    private setFilters: any = {};

    private taskOptions = [{
      code: '1',
      value: 'Social Work Consult'
    }, {
      code: '2',
      value: 'Drug Screen'
    }, {
      code: '3',
      value: 'Labs'
    }, {
      code: '4',
      value: 'Education'
    }, {
      code: '5',
      value: 'Chest X-Ray'
    }, {
      code: '6',
      value: 'Cardiac Cath'
    }, {
      code: "7",
      value: 'Cardiology Consult'
    }, {
      code: "8",
      value: 'Echocardiogram'
    }, {
      code: '9',
      value: 'Stress test'
    }, {
      code: '11',
      value: 'Donor Evaluation'
    }, {
      code: '12',
      value: 'Lab Panel'
    }, {
      code: '13',
      value: 'Med/Soc Evaluation'
    }, {
      code: '14',
      value: 'Incoming Living Donor Referral'
    }, {
      code: '15',
      value: 'Inquiry about Living donor'
    }, {
      code: '10',
      value: 'N/A'
    }];

    public getTagValue(item: any) {
      return this.taskOptions[item as any].value;
    }

    private updateFilter(filterArray: any) {
      if (filterArray.length == 0) {
        return false;
      }
      this.setFilters = filterArray;
    }

    private confirmPopup(): any {
      this.modalMode = this.status == 'active' ? 'Archive' : 'Restore';
      this.modalGuidingText =
        `You're ${this.status == 'active' ? 'archiving' :'restoring'} this note and any unsaved changes will be cleared.`;
      this.toggleModal('notesArchiveModal');
    }

    // Toggle a modal based on a ref
    private toggleModal(ref: string): void {
      const targetModal = this.$refs[ref] as ModalSection;
      targetModal.toggleModal();
    }

    get getPaginationDropdown(): {code: number, value: number}[] {
      const perPageDropdown = [10,25,50];
      return perPageDropdown.map((item: number) => {
        return { code: item, value: item };
      });
    }



    getArchivedStatus(row: any) {
      if (this.setFilters && this.setFilters.includeArchived) {
        return row.status === 'archived' ? 'trArchived' : '';
      } else if (row.status === 'archived') {
        return 'trArchived trArchivedHidden';
      }
    }

    getSelectedStyle(row: any) {
      return row.id == this.selectedId ? 'trSelected trCLass' : '';
    }

    rowStyleClass(row: any) {
      return `${this.getArchivedStatus(row)} ${this.getSelectedStyle(row)} trCLass`;
    }



    private userOptions = [{
      code: 0,
      value: 'S. Wood'
    }, {
      code: 1,
      value: 'S. McPhee'
    }, {
      code: 2,
      value: 'M. Couch'
    }, {
      code: 3,
      value: 'A. Johnson'
    }];

    loaded() {
      // Parse tasks from local storage if found, otherwise default to JSON file contents
      let formState;

      const defaultNotesState = {
        notes: this.tableData.rows
      };

      const itemsNotes: any = localStorage.getItem(this.localStorageRef);
      if (itemsNotes) {
        try {
          formState = JSON.parse(itemsNotes);
        } catch (error) {
          console.warn('Warning: Cannot parse Notes from local storage, setting default tasks.', error);
          formState = defaultNotesState;
        }
      } else {
        formState = defaultNotesState;
      }

      this.$store.commit("pageState/set", {
        pageKey: "Notes",
        value: formState,
      });

      this.notes = formState.notes || [];

    }

    mounted() {
      this.loaded();
    }




    private resetArchive(): any {
      setTimeout(() => {
        this.scrollToMethod('recipient-notes-form');
      }, 500);

    }

    private scrollToMethod(id: any): any {


      const location = document.getElementById(id);

      if (location) {
        const elementPosition = location.getBoundingClientRect().top;
        const offsetPosition = elementPosition - 105;


        window.scrollBy({
          top: offsetPosition,
          behavior: "smooth"
        });
      }


    }

    private archiveDocument(type: any): any {



      this.isTableUpdating = true;
      let notes = this.notes || [];

      const rationale_table: any = this.rationale_table;
      rationale_table.unshift({
        date: this.formatDateObject(Date.now()),
        rationale: `${type == "Archive" ? 'Archived' : 'Restored'}: ${this.archiveRationale}`,
        edited_by: this.getUserFullName
      });


      notes.map((item: any) => {
        if (item.id === this.id) {
          if (item.status == 'active') {
            item.status = 'archived';
          } else {
            item.status = "active";
          }
          item.rationale_table = rationale_table;
        }
      });
      this.notes = notes;

      const defaultState = {
        notes: notes
      };

      localStorage.setItem(this.localStorageRef, JSON.stringify(defaultState));

      this.isTableUpdating = false;
      this.editstate = false;
      this.clearForm();
      this.selectedId == '';
      setTimeout(() => {
        this.scrollToMethod('recipient-patient-notes');
      }, 500);
    }


    public saveNote(): any {
      this.isTableUpdating = true;
      let notes = this.notes || [];
      if (!this.editstate) {
        const payload: any = {
          id: Math.random().toString(16).slice(2),
          date: Date.now(),
          subject: this.subject,
          description: this.description,
          user: this.getUserFullName,
          tags: this.tags,
          rationale: null,
          rationale_table: [{
            date: this.formatDateObject(Date.now()),
            rationale: "Original Entry",
            edited_by: this.getUserFullName
          }]
        };
        notes.push(payload);
      } else {
        const rationale_table: any = this.rationale_table;
        rationale_table.unshift({
          date: this.formatDateObject(Date.now()),
          rationale: this.editRationale,
          edited_by: this.getUserFullName
        });

        notes.map((item: any) => {
          if (item.id === this.id) {
            item.date = this.date;
            item.subject = this.subject;
            item.description = this.description;
            item.user = this.user;
            item.tags = this.tags;
            item.rationale = this.editRationale;
            item.rationale_table = rationale_table;
          }
        });

      }

      this.notes = notes;

      const defaultState = {
        notes: notes
      };

      localStorage.setItem(this.localStorageRef, JSON.stringify(defaultState));

      this.isTableUpdating = false;
      this.editstate = false;
      this.clearForm();
    }




    get tableRows() {
      const notes = this.notes || [];
      return notes.map((item: any) => {
        return {
          id: item.id,
          date: this.formatDateObject(item.date),
          user: item.user,
          tags: item.tags,
          subject: item.subject,
          status: item.status,
          statusName: item.status ? item.status.charAt(0).toUpperCase() + item.status.slice(1) : null,
          description: item.description ? item.description : "None",
          rationale: item.rationale,
          rationale_table: item.rationale_table
        };
      });
    }

    get tableConfig(): any {
      const config = {
        data: this.tableRows,
        columns: this.columns
      };
      return config;
    }

    private clearMessage() {
      this.message = '';
    }


    private editSelected(data: any) {
      if (data) {
        this.selectedId = data.data.id;
        this.id = data.data.id;
        this.subject = data.data.subject;
        this.date = data.data.date;
        this.tags = data.data.tags;
        this.user = data.data.user;
        this.status = data.data.status;
        this.editRationale = null;
        this.rationale_table = data.data.rationale_table;
        this.description = data.data.description;
        this.editstate = true;
      }

      this.scrollToMethod('recipient-notes-form');

    }

    get emptyMessage(): string {
      if (!this.notes) {
        return this.$t('loading').toString();
      } else {
        return this.$t('use_form_below').toString();
      }
    }


    get getUserFullName(): string | undefined {
      let user = this.getUser;
      return user.name;
    }


    public clearForm(): any {
      this.id = null;
      this.subject = '';
      this.tags = [];
      this.user = '';
      this.description = '';
      this.status = 'active';
      this.editRationale = null;
      this.archiveRationale = null;
      this.selectedId = '';
      this.$emit('clear');
      this.editstate = false;
    }

  }

</script>

<style>



</style>
