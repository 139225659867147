<template>
  <card-section
    :title="$t('heart_specific_details')"
    section-id="heart-specific-details"
    :key="journeyId"
    @loaded="loaded()"
    :lookupsToLoad="lookupsToLoad"
    :hospitals-to-load="hospitalsToLoad"
    @save="savePatch()"
    :save-button-text="$t('save_heart_specific_details')"
    :saveButton="canEdit"
    :disabled="!canSave || newJourney"
     :isAccordion="prototypeFeatureEnabled('proto_ability_to_expand_collapse')"
    ref="saveHeartSpecificDetails">
    <template v-slot:header>
      {{$t('heart_specific_details')}}
    </template>
    <template v-slot:body v-if="editState">
      <heart-measurements
        v-if="isMeasurementsHeartEnabled"
        ref="heartMeasurement"
        :new-journey="newJourney"
        @handleErrors="handleErrors"
        @saving="saving"
        @clear="clear"
        :canSave="canSave"
      />
      <thoracic-measurements v-if="isThoracicMeasurementsEnabled"
        ref="thoracicMeasurement"
        :new-journey="newJourney"
        @handleErrors="handleErrors"
        @saving="saving"
        @clear="clear"
        :canSave="canSave"
      />
      <hemodynamic-information
        v-if="isHemodynamicMeasurementsEnabled"
        ref="hemodynamicInformation"
        :new-journey="newJourney"
        @handleErrors="handleErrors"
        @saving="saving"
        @clear="clear"
        :canSave="canSave"
      />
       <vad-specific-details
        v-if="isVadProcedureEnabled"
        ref="VadSpecificDetails"
        :new-journey="newJourney"
        @handleErrors="handleErrors"
        @saving="saving"
        @clear="clear"
        :canSave="canSave"
      />
      <sub-section
        sub-section-id="hsd-other-heart-details"
        :title="$t('other_heart_details')"
        :disabled="!canSave || newJourney"
        >
        <template v-slot:contents>
          <fieldset :disabled="!canSave || newJourney" v-if="isMechanicalCirculatorySupportEnabled">
            <legend>
              <h5 class="legend-title">
                {{$t('mechanical_circulatory_support')}}
              </h5>
            </legend>
            <div class="row">
              <div class="standard-form-group-small-checkbox">
                <checkbox-input
                  input-id="hsd-ecmo"
                  :labelName="$t('ecmo')"
                  v-model="editState.generalInfo.ecmo"
                  :label="$t('yes')"
                />
              </div>
              <div class="standard-form-group-small-checkbox">
                <checkbox-input
                  input-id="hsd-iabp"
                  :labelName="$t('iabp')"
                  v-model="editState.generalInfo.iabp"
                  :label="$t('yes')"
                />
              </div>
              <div class="standard-form-group-small-checkbox">
                <checkbox-input
                  input-id="hsd-impella"
                  :labelName="$t('impella')"
                  v-model="editState.generalInfo.impella"
                  :label="$t('yes')"
                />
              </div>
              <div class="standard-form-group">
                <text-input
                  input-id="hsd-other"
                  :name="$t('other')"
                  v-model="editState.generalInfo.other"
                />
              </div>
            </div>
          </fieldset>

          <fieldset :disabled="!canSave || newJourney">
            <legend>
              <h5 class="legend-title">
                {{$t('other_notes')}}
              </h5>
            </legend>
            <div class="row">
              <div class="standard-form-group">
                <text-input
                  input-id="hsd-anticoagulant"
                  :name="$t('anticoagulant_medications')"
                  v-model="editState.generalInfo.anticoagulantMedications" />
              </div>
              <div class="standard-form-group">
                <checkbox-input
                  input-id="hsd-congenital"
                  :labelName="$t('congenital_heart_disease')"
                  v-model="editState.generalInfo.congenitalHeartDisease"
                  :label="$t('yes')"
                />
              </div>
              <div class="row-break d-none d-md-block d-xxxl-none"></div>
              <div class="standard-form-group-large">
                <text-area-input
                  input-id="hsd-surgical-comments"
                  :name="$t('surgical_comments')"
                  v-model="editState.generalInfo.surgicalComments" />
              </div>
               <div class="standard-form-group-large">
                <text-area-input
                  input-id="hsd-medical-comments"
                  :name="$t('medical_comments')"
                  v-model="editState.generalInfo.medicalComments" />
              </div>
              <div class="standard-form-group-large">
                <text-area-input
                  input-id="hsd-comments"
                  :name="$t('general_comments')"
                  v-model="editState.generalInfo.comments" />
              </div>
            </div>
          </fieldset>
        </template>
      </sub-section>
    </template>
  </card-section>
</template>

<i18n src="@/components/organs/heart/_locales/HeartSpecificDetails.json"></i18n>

<script lang="ts">
import { Getter, State } from 'vuex-class';
import { Recipient } from '@/store/recipients/types';
import TextInput from '@/components/shared/TextInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import CardSection from '@/components/shared/CardSection.vue';
import { RecipientJourney } from '@/store/recipientJourney/types';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { HeartDetails } from '@/store/organSpecificDetails/types';
import HeartMeasurements, { HeartMeasurementForm } from '@/components/organs/heart/HeartMeasurements.vue';
import ThoracicMeasurements, { ThoracicMeasurementForm } from '@/components/organs/shared/ThoracicMeasurements.vue';
import HemodynamicInformation, { HemodynamicForm } from '@/components/organs/heart/HemodynamicInformation.vue';
import VadSpecificDetails, { VadSpecificForm } from '@/components/organs/heart/VadSpecificDetails.vue';
import { SystemModules } from '@/store/features/types';

export interface HeartSpecificPageState {
  generalInfo: HeartSpecificDetailsForm;
  heartMeasurement: HeartMeasurementForm;
  thoracicMeasurement: ThoracicMeasurementForm;
  hemodynamicInfo: HemodynamicForm;
  vadInfo: VadSpecificForm;
}

interface HeartSpecificDetailsForm {
  ecmo?: boolean;
  iabp?: boolean;
  impella?: boolean;
  other?: string;
  anticoagulantMedications?: string;
  congenitalHeartDisease?: boolean;
  surgicalComments?: string;
  medicalComments?: string;
  comments?: string;
}

@Component({
  components: {
    TextInput,
    SubSection,
    CardSection,
    TextAreaInput,
    CheckboxInput,
    HeartMeasurements,
    ThoracicMeasurements,
    HemodynamicInformation,
    VadSpecificDetails
  }
})
export default class HeartSpecificDetails extends Vue implements SaveableSection {
  // State
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.heartDetails) editState!: HeartSpecificPageState;

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter("moduleEnabled", { namespace: "features" }) private moduleEnabled!: (module: string) => boolean;
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;

  public lookupsToLoad = ['inotrope_types','vad_type','vad_indication','vad_side','vad_device','vad_discontinued_reason'];

  public hospitalsToLoad: string[] = ['all'];


  /**
   * Return true if other heart details section can be edited
   *
   * cannot be edited if new journey
   * cannot be edited if journey is completed
   *
   * @returns {boolean} true if we can edit
   */
  get canEdit(): boolean{
    if (this.newJourney || this.journey.completed) {
      return false;
    }
    return true;
  }

  // is the measurements HEART enabled
  get isMeasurementsHeartEnabled(): boolean {
    return this.moduleEnabled(SystemModules.MEASUREMENTS_HEART);
  }

  // is the vad procedure enabled
  get isVadProcedureEnabled(): boolean {
    return this.moduleEnabled(SystemModules.PROCEDURE_VAD);
  }

  // is the mechanical circulatory details enabled
  get isMechanicalCirculatorySupportEnabled(): boolean {
    return this.moduleEnabled(SystemModules.HEART_MECHANICAL_CIRCULATORY_SUPPORT);
  }

  // is the hemodynamic enabled
  get isHemodynamicMeasurementsEnabled(): boolean {
    return this.moduleEnabled(SystemModules.MEASUREMENTS_HEART_HEMODYNAMIC);
  }

  // Is the thoracic measurements system module enabled?
  get isThoracicMeasurementsEnabled(): boolean {
    return this.moduleEnabled(SystemModules.THORACIC_MEASUREMENTS);
  }

  /**
   * Emits a loaded event after all subcomponents have finished loading.
   *
   * The Heart Specific Details card section emits a loaded event when
   * it finishes loading lookup tables (if any).
   *
   * @listens saveHeartSpecificDetails#loaded
   * @emits loaded
   */
  public loaded(): void {
    this.initializeForm();
    this.$emit('loaded', 'saveHeartSpecificDetails');
  }

  /**
   * Populates the Heart Specific Details form state with data from
   * the selected Journey.  Also triggers Heart Measurements and
   * Hemodynamic Information loading.
   */
  public initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'heartDetails',
      value: {
        generalInfo: this.extractHeartSpecificDetails(this.journey),
        heartMeasurement: {},
        hemodynamicInfo: {},
        vadInfo:{},
      }
    });
    // Begin fetching the recipient's Heart Measurements
    this.$store.dispatch('organSpecificDetails/loadHeartMeasurements', this.recipientId);
    // Begin fetching the recipient's Thoracic Measurements
    this.$store.dispatch('organSpecificDetails/loadThoracicMeasurements', this.recipientId);
    // Begin fetching the recipient's Hemodynamic Information
    this.$store.dispatch('organSpecificDetails/loadHemodynamicInfo', this.recipientId);
    //Begin fetching the recipient's Vad Information
    this.$store.dispatch('organSpecificDetails/loadVadInfo', this.recipientId);
  }

  /**
   * Generates Heart Specific Details form state based on the selected journey
   *
   * @param journey current selected jounrey
   * @returns {HeartSpecificDetailsForm} Heart Specific Details form state
   */
  public extractHeartSpecificDetails(journey: RecipientJourney): HeartSpecificDetailsForm {
    if (!journey) {
      return {};
    }
    const organSpecificDetails = journey.organ_specific_details as HeartDetails;
    return {
      ecmo: (organSpecificDetails.extra_corporeal_membraneous_oxygenator === 'Y' ? true : false),
      iabp: (organSpecificDetails.intra_aortal_balloon_pump === 'Y' ? true : false),
      impella: (organSpecificDetails.impella_heart_pump === 'Y' ? true : false),
      other: organSpecificDetails.mechanical_circulatory_support_other,
      anticoagulantMedications: organSpecificDetails.anticoagulant_medications,
      congenitalHeartDisease: organSpecificDetails.congenital_heart_disease,
      surgicalComments: organSpecificDetails.surgical_comments,
      medicalComments: organSpecificDetails.medical_comments,
      comments: journey.comments
    };
  }

  /**
   * Saves the form edit state.
   *
   * Prepares an update payload for Heart Specific Details,
   * dispatches a save action, and registers the save result.
   */
  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveHeartSpecificDetails as unknown as SaveProvider;
    // Generate payload based on current edit state
    const payload = {
      recipientId: this.recipientId,
      journeyId: this.journeyId,
      journey: this.extractPatch()
    };
    // Dispatch save action and register the response
    this.$store.dispatch('journeyState/saveJourney', payload).then((success: SaveResult) => {
      // If successful, reload the current recipient
      this.$store.dispatch('recipients/get', this.recipientId);
      // Register success result
      saveProvider.registerSaveResult(success);
    }).catch((error: SaveResult) => {
      // Show error notification
      saveProvider.registerSaveResult(error);
      // Emit event to handle errors
      this.$emit('handleErrors', error);
    });
  }

  /**
   * Gets changes from the editState as a patch for the journey's Heart Specific Details
   *
   * If the edit state doesn't exist return null
   *
   * @returns {any} object containing field changes
   */
  public extractPatch(): any {
    if (!this.editState || !this.editState.generalInfo) {
      return {};
    } else {
      return this.extractHeartSpecificDetailsPatch(this.editState.generalInfo);
    }
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    const gci = this.$refs.saveHeartSpecificDetails as unknown as SaveProvider;
    // Reset the save provider's save toolbar
    gci.resetSaveToolbar();
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    // Mechanical Circulatory Support
    const result = {
      'extra_corporeal_membraneous_oxygenator': 'hsd-ecmo',
      'intra_aortal_balloon_pump'             : 'hsd-iabp',
      'impella_heart_pump'                    : 'hsd-impella',
      'mechanical_circulatory_support_other'  : 'hsd-other',
      'anticoagulant_medications'             : 'hsd-anticoagulant',
      'congenital_heart_disease'              : 'hsd-congenital',
      'surgical_comments'                     : 'hsd-surgical-comments',
      'medical_comments'                      : 'hsd-medical-comments',
      'comments'                              : 'hsd-comments'
    };

    // Heart Measurements
    const heartMeasurement = this.$refs.heartMeasurement as HeartMeasurements;
    if (heartMeasurement) {
      Object.assign(result, { ...heartMeasurement.idLookup });
    }

    // Thoracic Measurements
    const thoracicMeasurement = this.$refs.thoracicMeasurement as ThoracicMeasurements;
    if (thoracicMeasurement) {
      Object.assign(result, { ...thoracicMeasurement.idLookup });
    }

    // Hemodynamic Information
    const hemodynamicInformation = this.$refs.hemodynamicInformation as HemodynamicInformation;
    if (hemodynamicInformation) {
      Object.assign(result, { ...hemodynamicInformation.idLookup });
    }

    // Vad Information
    const vadInformation = this.$refs.VadSpecificDetails as VadSpecificDetails;
    if (vadInformation) {
      Object.assign(result, { ...vadInformation.idLookup() });
    }

    return result;
  }

  /**
   * PRIVATE
   */

  /**
   * Gets changes from the edit state as a patch for the Heart Specific Details
   *
   * @returns {RecipientJourney} patch object containing field changes
   */
  private extractHeartSpecificDetailsPatch(heartSpecificDetails: HeartSpecificDetailsForm): RecipientJourney {
    return {
      comments: heartSpecificDetails.comments,
      organ_specific_details: {
        extra_corporeal_membraneous_oxygenator: (heartSpecificDetails.ecmo ? 'Y' : 'N'),
        intra_aortal_balloon_pump: (heartSpecificDetails.iabp ? 'Y' : 'N'),
        impella_heart_pump: (heartSpecificDetails.impella ? 'Y' : 'N'),
        mechanical_circulatory_support_other: heartSpecificDetails.other,
        anticoagulant_medications: heartSpecificDetails.anticoagulantMedications,
        congenital_heart_disease: heartSpecificDetails.congenitalHeartDisease ? true : false,
        surgical_comments: heartSpecificDetails.surgicalComments,
        medical_comments: heartSpecificDetails.medicalComments,
      }
    };
  }

  // Emit event to parent so it can handle validations
  private handleErrors(errors: any): void {
    this.$emit('handleErrors', errors);
  }

  // Emit event to parent so it can handle clearing validations when saving
  private saving(formReference: string) {
    this.$emit('saving', formReference);
  }

  // Emit event to parent only for clear validations
  private clear() {
    this.$emit('clear');
  }

}
</script>
