<template>
  <sub-section 
    sub-section-id="post-operative-care"
    ref="saveLivingDonorPostOperativeCare"
    :title="$t('post_operative_care')" 
    :save-button-text="$t('save_post_operative_care')"
    :save-button="true"
    :is-parent-loading="!editState"
    :lookups-to-load="lookupsToLoad"
    :confirmation="confirmationText"
    @save="handleSave"
  >
    <template v-slot:contents>
      <fieldset>
        <template v-if="editState">
          <!-- Discharge and Comments -->
          <div class="row">
            <div class="standard-form-group">
              <date-input
                input-id="post-operative-care-date-of-hospital-discharge"
                :name="$t('date_of_hospital_discharge')"
                rules="required_if_filled:@timeOfHospitalDischarge"
                :cross-values="{ timeOfHospitalDischarge: editState.timeOfHospitalDischarge }"
                v-model="editState.dateOfHospitalDischarge"
              />
            </div>
            <div class="standard-form-group">
              <time-input
                input-id="post-operative-care-time-of-hospital-discharge"
                :name="$t('time_of_hospital_discharge')"
                rules="required_if_filled:@dateOfHospitalDischarge"
                :cross-values="{ dateOfHospitalDischarge: editState.dateOfHospitalDischarge }"
                v-model="editState.timeOfHospitalDischarge"
              />
            </div>
          </div>
          <div class="row">
            <div class="standard-form-group-large">
              <text-area-input
                input-id="post-operative-care-donation-comments"
                :name="$t('donation_comments')"
                v-model="editState.donationComments"
              />
            </div>
          </div>

          <!-- Complications and Death -->
          <div class="hr-break" />
          <div class="row">
            <div class="standard-form-group">
              <checkbox-input
                input-id="post-operative-care-surgical-complications"
                :label-name="$t('surgical_complications')"
                :label="$t('yes')"
                v-model="editState.surgicalComplications"
                @change="handleSurgicalComplcationsChanged"
              />
            </div>
            <div class="standard-form-group">
              <select-input
                select-id="post-operative-care-clavien-dindo-classification"
                :name="$t('clavien_dindo_classification')"
                rules="required_if_filled:@surgicalComplications"
                :cross-values="{ surgicalComplications: editState.surgicalComplications }"
                :options="clavienDindoClassificationOptions"
                :disabled="!editState.surgicalComplications"
                v-model="editState.clavienDindoClassification"
              />
            </div>
            <div class="standard-form-group">
              <select-input
                select-id="post-operative-care-surgical-complication-category"
                :name="$t('surgical_complication_category')"
                :options="surgicalComplicationCategoryOptions"
                :disabled="!editState.surgicalComplications"
                v-model="editState.surgicalComplicationCategory"
                @change="handleSurgicalComplicationCategoryChanged"
              />
            </div>
            <div class="standard-form-group">
              <select-input
                select-id="post-operative-care-surgical-complication-type"
                :name="$t('surgical_complication_type')"
                :numeric="true"
                :options="surgicalComplicationTypeOptions"
                :disabled="!editState.surgicalComplications"
                v-model="editState.surgicalComplicationType"
              />
            </div>
          </div>
          <div class="row">
            <div class="standard-form-group">
              <checkbox-input
                input-id="post-operative-care-death-related-to-donation-surgery"
                :label-name="$t('death_related_to_donation_surgery')"
                :label="$t('yes')"
                v-model="editState.deathRelatedToDonationSurgery"
              />
            </div>
            <div class="standard-form-group" v-if="editState.deathRelatedToDonationSurgery">
              <boolean-radio-input
                input-id="post-operative-care-intra-operative-death"
                :label-name="$t('intra_operative_death')"
                :accept-id="true"
                :decline-id="false"
                :accept-label="$t('yes')"
                :decline-label="$t('no')"
                rules="required"
                v-model="editState.intraOperativeDeath"
              />
            </div>
            <div class="standard-form-group" v-if="editState.deathRelatedToDonationSurgery">
              <boolean-radio-input
                input-id="post-operative-care-death-in-hospital"
                :label-name="$t('death_in_hospital')"
                :accept-id="true"
                :decline-id="false"
                :accept-label="$t('yes')"
                :decline-label="$t('no')"
                rules="required"
                v-model="editState.deathInHospital"
              />
            </div>
          </div>
          <fieldset v-if="editState.deathRelatedToDonationSurgery">
            <div class="row">
              <div class="standard-form-group">
                <select-input
                  select-id="post-operative-care-death-category"
                  :name="$t('cause_of_death_category')"
                  rules="required"
                  :options="causeOfDeathCategoryOptions"
                  v-model="editState.causeOfDeathCategory"
                />
              </div>
              <div class="standard-form-group">
                <select-input
                  select-id="post-operative-care-death-type"
                  :name="$t('cause_of_death_type')"
                  rules="required"
                  :numeric="true"
                  :options="causeOfDeathTypeOptions"
                  v-model="editState.causeOfDeathType"
                />
              </div>
              <div class="standard-form-group">
                <date-input
                  input-id="post-operative-care-death-date"
                  :name="$t('death_date')"
                  rules="required"
                  v-model="editState.deathDate"
                />
              </div>
              <div class="standard-form-group">
                <checkbox-input
                  input-id="journey-post-donation-follow-up-estimated"
                  :label-name="$t('estimated')"
                  :label="$t('yes')"
                  v-model="editState.estimated"
                />
              </div>
            </div>
          </fieldset>
        </template>
      </fieldset>
    </template>
  </sub-section>
</template>

<i18n src="./_locales/LivingDonorPostOperativeCare.json"></i18n>

<script lang="ts">
import { SaveProvider } from '@/types';
import { mixins } from "vue-class-component";
import { State, Getter }  from 'vuex-class';
import { Component, Vue } from "vue-property-decorator";
import DateInput from "@/components/shared/DateInput.vue";
import TimeInput from "@/components/shared/TimeInput.vue";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import SubSection from "@/components/shared/SubSection.vue";
import SelectInput from "@/components/shared/SelectInput.vue";
import TextAreaInput from "@/components/shared/TextAreaInput.vue";
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { RecipientJourney } from '@/store/recipientJourney/types';
import { GenericCodeValue, NumericCodeValue } from "@/store/types";
import BooleanRadioInput from '@/components/shared/BooleanRadioInput.vue';

export interface LivingDonorPostOperativeCareForm {
  dateOfHospitalDischarge:       string|null;
  timeOfHospitalDischarge:       string|null;
  donationComments:              string|null;

  surgicalComplications:         boolean;
  surgicalComplicationCategory:  number|null;
  surgicalComplicationType:      number|null;
  clavienDindoClassification:    string|null;

  deathRelatedToDonationSurgery: boolean;
  intraOperativeDeath:           boolean|null;
  deathInHospital:               boolean|null;
  causeOfDeathCategory:          string|null;
  causeOfDeathType:              number|null;
  deathDate:                     string|null;
  estimated:                     boolean|null;
}

@Component({
  components: {
    SubSection,
    DateInput,
    TimeInput,
    TextAreaInput,
    CheckboxInput,
    SelectInput,
    BooleanRadioInput,
  }
})
export default class LivingDonorPostOperativeCare extends mixins(DateUtilsMixin) {
  @State(state => state.pageState.currentPage.postOperativeCare) editState!: LivingDonorPostOperativeCareForm;
  @State(state => state.lookups.complication_categories) private surgicalComplicationCategoryOptions!: NumericCodeValue[];
  @State(state => state.lookups.cause_of_death_category) private causeOfDeathCategoryOptions!: GenericCodeValue[];
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;

  @Getter("causeOfDeathTypeLookup", { namespace: "lookups" }) causeOfDeathTypeByCategory!: (deathCode: string) => NumericCodeValue[];
  @Getter('optionsFor', { namespace: 'lookups' }) optionsFor!: (items: string[]) => GenericCodeValue[];

  lookupsToLoad = [
    'complication_categories',
    'cause_of_death_category',
  ];

  get clavienDindoClassificationOptions(): GenericCodeValue[] {
    return this.optionsFor([
      "Grade I",
      "Grade II",
      "Grade IIIa",
      "Grade IIIb",
      "Grade IVa",
      "Grade IVb",
      "Grade V", 
    ]);
  }

  get causeOfDeathTypeOptions(): NumericCodeValue[] {
    const causeOfDeathCategory = this.editState?.causeOfDeathCategory;
    if (causeOfDeathCategory == null) return [];

    return this.causeOfDeathTypeByCategory(causeOfDeathCategory);
  }

  get surgicalComplicationTypeOptions(): NumericCodeValue[] {
    const surgicalComplicationCategory = this.editState?.surgicalComplicationCategory;
    if (surgicalComplicationCategory == null) return [];

    const surgicalComplicationCategoryLookup = this.surgicalComplicationCategoryOptions.find((lookup: NumericCodeValue) => {
      return lookup.code == surgicalComplicationCategory;
    });
    if (!surgicalComplicationCategoryLookup || !surgicalComplicationCategoryLookup.sub_tables) return [];

    return surgicalComplicationCategoryLookup.sub_tables?.complication_types || [];
  }

  get confirmationText(): string|undefined {
    if (!this.editState || !this.editState.deathRelatedToDonationSurgery) return undefined;

    const deathDate = this.editState.deathDate;
    return this.$t('death_confirmation', { death_date: this.parseDisplayDateUi(deathDate) || 'TODAY' }).toString();
  }

  private mounted(): void {
    this.initializeEditState();
  }

  private initializeEditState(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'postOperativeCare',
      value: this.buildEditState(),
    });
  }

  private buildEditState(): LivingDonorPostOperativeCareForm {
    return {
      dateOfHospitalDischarge:       null,
      timeOfHospitalDischarge:       null,
      donationComments:              null,

      surgicalComplications:         false,
      clavienDindoClassification:    null,
      surgicalComplicationCategory:  null,
      surgicalComplicationType:      null,

      deathRelatedToDonationSurgery: false,
      intraOperativeDeath:           null,
      deathInHospital:               null,
      causeOfDeathCategory:          null,
      causeOfDeathType:              null,
      deathDate:                     null,
      estimated:                     null,
    };
  }
  
  private handleSurgicalComplcationsChanged(): void {
    if (!this.editState) return;

    Vue.set(this.editState, 'clavienDindoClassification', null);
    Vue.set(this.editState, 'surgicalComplicationCategory', null);
    Vue.set(this.editState, 'surgicalComplicationType', null);
  }

  private handleSurgicalComplicationCategoryChanged(): void {
    if (!this.editState) return;

    Vue.set(this.editState, 'surgicalComplicationType', null);
  }

  private handleSave(): void {
    const saveProvider = this.$refs.saveLivingDonorPostOperativeCare as unknown as SaveProvider;
    if (!saveProvider) return;

    this.$emit('saving', 'postOperativeCare');
    setTimeout(() => {
      saveProvider.registerSaveResult({ success: true });
    }, 500);
  }

}
</script>
