<template>
  <base-side-nav :class="{ closed: sideNavClosed }">
    <template v-slot:side-nav-content>
      <side-nav-toolbar @toggle="toggleSideNav">
        <template v-slot:return-link>
          <router-link class="nav-link no-scroll" :title="$t('return_to_previous_page.span')" :aria-label="$t('control_that_returns_to_previous_page')" :to="{ name: getDonorsUrl }">
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width />
            <span class="sr-only">
              {{$t('return_to_previous_page.span')}}
            </span>
          </router-link>
        </template>
      </side-nav-toolbar>
      <div class="side-nav-container">
        <template v-if="newDonor">
          <selected-deceased-donor-profile-links @toggle="toggleSideNav" :active="true" :newDonor="newDonor"/>
        </template>
        <template v-else>
          
          <template v-if="!prototypeFeatureEnabled('demo_hide_donor_journey')">
            <template v-for="organ in selectedDonorConsentedOrganList">      
              <selected-deceased-donor-organ-links @toggle="toggleSideNav" :organ="$t(organ.display_name)" :organId="organ.id" :organCode="organ.organ_code" :organRoute="organ.route" :key="organ.id" />
            </template>
            <hr>
          </template>

          <selected-deceased-donor-profile-links @toggle="toggleSideNav" :active="true" />
          <hr>
          <selected-deceased-donor-hla-links @toggle="toggleSideNav" :active="true"/>
        </template>
      </div>
    </template>
  </base-side-nav>
</template>

<i18n src="@/components/_locales/common.json"></i18n>
<i18n src="@/components/deceasedDonors/_locales/SideNav.json"></i18n>
<i18n src="@/components/_locales/Organs.json"></i18n>

<script lang="ts">
import { State, Getter } from 'vuex-class';
import { DeceasedDonor, DeceasedDonorAllocationSummary } from '@/store/deceasedDonors/types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import BaseSideNav from '@/components/shared/side-nav/BaseSideNav.vue';
import SideNavToolbar from '@/components/shared/side-nav/SideNavToolbar.vue';
import SelectedDeceasedDonorHlaLinks from "@/components/deceasedDonors/side-nav/SelectedDeceasedDonorHlaLinks.vue";
import SelectedDeceasedDonorOrganLinks from "@/components/deceasedDonors/side-nav/SelectedDeceasedDonorOrganLinks.vue";
import SelectedDeceasedDonorProfileLinks from "@/components/deceasedDonors/side-nav/SelectedDeceasedDonorProfileLinks.vue";


@Component({
  components: {
    BaseSideNav,
    SideNavToolbar,
    SelectedDeceasedDonorHlaLinks,
    SelectedDeceasedDonorOrganLinks,
    SelectedDeceasedDonorProfileLinks,
  }
})
export default class SideNavDeceasedDonor extends Vue {
  @State(state => state.deceasedDonors.selected) private donor!: DeceasedDonor;

  @Getter('selectedDonorConsentedOrganList', { namespace: 'deceasedDonors'}) private selectedDonorConsentedOrganList!: DeceasedDonorAllocationSummary[];
  @Getter('getDonorsUrl', { namespace: 'users' }) private getDonorsUrl!: string;

  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;

  @Prop({default: false }) newDonor!: boolean;

  public sideNavClosed = false;
  
  /**
   * Boolean used to show the side menu, if true side-nav is open
   * Changes the 'closed' class on the nav
   *
   * @returns {void} changes the sideNavClosed boolean
   */
  public toggleSideNav(): void {
    this.sideNavClosed = !this.sideNavClosed;
  }
}
</script>
