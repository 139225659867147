<template>
  <sub-section
    subSectionId="dialysis-report"
    :lookupsToLoad="lookupsToLoad"
  >
    <template v-slot:contents v-if="editState">
      <div class="card tip-card">
        <div class="card-header">
          <div class="media align-items-center">
            <div class="media-left">
              <font-awesome-icon :icon="['far', 'file-chart-line']" fixed-width />
            </div>
            <div class="media-body flex-row align-items-center">
              <h3 class="tip-report-name">
                Dialysis Report
              </h3>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="content-wrap">
            <div class="container-fluid">
              <validation-observer id="dialysis-filters" ref="DialysisReportFilters" autocomplete="off" tag="form" v-slot="{ handleSubmit }">

              <div class="row">
                <div class="form-group col-md-12 col-lg-5">
                  <select-input
                    selectId="dialysis-region"
                    :name="$t('transplant_hospital')"
                    v-model="editState.transplantHospital"
                    :options="transplantHospitals"
                    :multiple="true"
                    :placeholder="allOptionsPlaceholderValue(editState.transplantHospital)"
                  />
                </div>
                <div class="form-group col-md-12 col-lg-5">
                  <select-input
                    selectId="dialysis-hospital"
                    :name="$t('dialysis_provider')"
                    v-model="editState.dialysisProvider"
                    :options="dialysisProviders"
                    :multiple="true"
                    :numeric="true"
                    :placeholder="allOptionsPlaceholderValue(editState.dialysisProvider)"
                  />
                </div>
                <div class="form-group col-md-12 col-lg-2">
                  <select-input
                    selectId="dialysis-blood_type"
                    :name="$t('blood_type')"
                    v-model="editState.bloodType"
                    :options="bloodTypeLookup"
                    :multiple="true"
                    :placeholder="allOptionsPlaceholderValue(editState.bloodType)"
                  />
                </div>
              </div>

              <div class="form-row">
                <save-toolbar
                  class="form-inline action-row"
                  buttonClass="btn btn-success"
                  ref="generateReport"
                  savingText="Generating"
                  successText="Report generated"
                  defaultErrorText="There was a problem generating the report"
                  @save="handleSubmit(generateReport)"
                >
                  <template v-slot:label>
                    <font-awesome-icon :icon="['far', 'file-chart-line']" class="mr-2" /> Generate Dialysis Report
                  </template>
                </save-toolbar>
              </div>
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </template>
  </sub-section>
</template>

<i18n src="./_locales/common.json"></i18n>
<i18n src="./_locales/DialysisReport.json"></i18n>

<script lang="ts">
import { Getter, State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { organCodeLookup, SaveResult } from '@/types';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { DialysisReportState, DialysisReportQuery } from '@/store/reports/types';
import { NumericCodeValue } from '@/store/types';
import TextInput from '@/components/shared/TextInput.vue';
import { BloodType, BloodTypeValue } from '@/store/lookups/types';
import { Hospital} from '@/store/hospitals/types';
import { GenericCodeValue } from '@/store/types';

@Component({
  components: {
    TextInput,
    SaveToolbar,
    SelectInput,
    SubSection
  }
})

export default class DialysisReport extends Vue {
  // State
  @State(state => state.reports.DialysisReportState) editState!: DialysisReportState;
  @State(state => state.lookups.blood_type) bloodTypeLookup!: BloodType[];
  @State(state => state.lookups.dialysis_providers) dialysisProviderLookup!: NumericCodeValue[];

  @State(state => state.hospitals.all) hospitals!: Hospital[];

  @Getter('allOptionsPlaceholderValue', { namespace: 'reports' }) allOptionsPlaceholderValue!: (state: any, disabled?: boolean) => string;
  @Getter('getTransplantProgramsByOrgan', { namespace: 'hospitals' }) getTransplantProgramsByOrgan!: (organ_code: number[]) => any;

  public lookupsToLoad = ['blood_type', 'dialysis_providers'];

  /**
   * Shows all ontario transplant programs for the organ code selected in Organ Type field
   *
   * @returns {Organ[]} filtered list of ontario transplant programs
   */
  get dialysisProviders(): NumericCodeValue[] {
    return this.dialysisProviderLookup.map((provider: any) => {
      return {
        code: provider.code,
        value: provider.value
      };
    });
  }

    /**
   * Shows all ontario transplant programs for the organ code selected in Organ Type field
   *
   * @returns {Organ[]} filtered list of ontario transplant programs
   */
  get transplantHospitals(): Hospital[]|undefined {
    return this.getTransplantProgramsByOrgan( Object.keys(organCodeLookup).map(Number) ).map((hospital: Hospital) => {
      return {
        code: hospital._id.$oid,
        value: hospital.hospital_name_info ? hospital.hospital_name_info.name : '-'
      };
    });
  }

  public mounted(): void {
    this.$store.dispatch('hospitals/load').then(() => {
      this.$store.commit('reports/setDialysisReportState', {});
    });
  }

  // pass lookup options to get all options code
  public defaultAllOptions(options: any) {
    return options.map((option: any) => {
      return option.code;
    });
  }

  public buildDialysisPayload(): DialysisReportQuery {
    if (!this.editState) {
      return {};
    }

    const transplantHospitalValues = this.defaultAllOptions(this.transplantHospitals);
    const dialysisProviderValues = this.defaultAllOptions(this.dialysisProviders);
    const bloodTypeValues = this.defaultAllOptions(this.bloodTypeLookup);

    const result = {
      transplant_hospital: (this.editState.transplantHospital || []).length == 0 ? transplantHospitalValues : this.editState.transplantHospital,
      dialysis_provider: (this.editState.dialysisProvider || []).length == 0 ? dialysisProviderValues : this.editState.dialysisProvider,
      blood_type: (this.editState.bloodType || []).length == 0 ? bloodTypeValues : this.editState.bloodType,
    };

    return result;
  }

  public generateReport(): void {
    const saveToolbar = this.$refs.generateReport as SaveToolbar;
    saveToolbar.startSaving();
    const payload = this.buildDialysisPayload();
    // Submit query
    this.$store.dispatch('reports/printDialysisReport', payload).then((result: SaveResult) => {

      // Get the filename
      const fileName = result.responseData.report;

      // Is there actually a filename
      if (!!fileName) {
        // Create a link
        const link = document.createElement('a');
        link.href = fileName;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        // Then click it forcing a save/open dialogue
        link.click();
        // Show success notification
      }
      saveToolbar.stopSaving(result);

    }).catch((result: SaveResult) => {
      saveToolbar.stopSaving(result);
    });
  }
}
</script>
