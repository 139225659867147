<template>
  <base-side-nav :class="{ closed: sideNavClosed }">
    <template v-slot:toolbar />
    <template v-slot:side-nav-content>
      <side-nav-toolbar @toggle="toggleSideNav">
        <template v-slot:return-link>
          <router-link class="nav-link no-scroll" :title="$t('return_to_prev')" :aria-label="$t('control_that_returns_to_previous_page')" :to="{ name: 'edit-recipient', params: { id: recipient.client_id }, hash: '#organ-referral' }">
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width />
            <span class="sr-only">
              {{$t('return_to_prev')}}
            </span>
          </router-link>
        </template>
      </side-nav-toolbar>
      <div class="side-nav-container">
        <template v-for="journey in selectedRecipientJourneysList">
          <selected-recipient-journey-links-oop @toggle="toggleSideNav" :journeyName="journey.name" :journeyId="journey.id" :organCode="journey.organCode" :key="journey.id" />
        </template>
        <template v-if="newOrgan">
          <div class="nav-block-wrap">
            <ul class="nav flex-column nav-block active">
              <li class="nav-item">
                <span class="nav-link no-scroll" style="text-transform: capitalize">
                  <font-awesome-icon class="nav-caret" :icon="['fas', (show ? 'chevron-down' : 'chevron-right')]" fixed-width @click.prevent="toggleNav" />
                  {{ $t(newOrgan) }}
                </span>
              </li>
              <ul class="nav flex-column" v-show="show">
                <div >
                  <li class="nav-item" @toggle="toggleSideNav">
                    <a class="nav-link" href="#transplant-details-section">{{$t('transplant_details')}}</a>
                  </li>
                </div>
              </ul>
            </ul>
          </div>
        </template>
        <hr>
        <selected-recipient-profile-links-oop @toggle="toggleSideNav">
          <template v-slot:profile-return-link>
            <router-link class="no-scroll open" style="color: #2e2e2e !important" :to="{ name: 'edit-recipient-oop', params: { id: recipient.client_id } }">
              {{$t('profile')}}
            </router-link>
          </template>
        </selected-recipient-profile-links-oop>
        <hr>
      </div>
    </template>
  </base-side-nav>
</template>

<i18n src="@/components/_locales/common.json"></i18n>
<i18n src="@/components/_locales/Organs.json"></i18n>
<i18n src="@/components/recipientsOop/_locales/side-nav.json"></i18n>

<script lang="ts">
import { State, Getter } from 'vuex-class';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Recipient } from '@/store/recipients/types';
import { organCodeLookup } from '@/types';
import { RecipientJourney } from '@/store/recipientJourney/types';
import BaseSideNav from '@/components/shared/side-nav/BaseSideNav.vue';
import SelectedRecipientHlaLinks from '@/components/recipients/side-nav/SelectedRecipientHlaLinks.vue';
import SelectedRecipientJourneyLinksOop from '@/components/recipientsOop/side-nav/SelectedRecipientJourneyLinksOop.vue';
import SelectedRecipientProfileLinksOop from '@/components/recipientsOop/side-nav/SelectedRecipientProfileLinksOop.vue';
import SideNavToolbar from '@/components/shared/side-nav/SideNavToolbar.vue';

@Component({
  components: {
    BaseSideNav,
    SideNavToolbar,
    SelectedRecipientJourneyLinksOop,
    SelectedRecipientProfileLinksOop,
  }
})
export default class SideNavJourneyOop extends Vue {
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) private journey!: RecipientJourney;
  
  @Getter('selectedRecipientJourneysList', { namespace: 'recipients' }) private selectedRecipientJourneysList!: { id: string; name: string, organCode: number }[];

  @Prop({ default: false }) active!: boolean;
  @Prop({ default: null }) newOrgan!: string|null;
  @Prop({ default: null }) organCode!: string;
  @Prop({ default: false }) newRecipient!: boolean;

  public show = true;
  public sideNavClosed = false;

  public toggleNav() {
    this.show = !this.show;
  }

  // TODO: Remove when we have Small Bowel specific details section
  public ORGAN_CODES_TO_EXCLUDE = ['7'];

  /**
   * Return if we should show the organ specfic details link on the side nav
   * 
   * @returns {boolean} true if we should show the nav item
   */
  get showOrganSpecificDetails(): boolean {
    // Check if the journey organ code should be excluded
    const isExcluded = this.ORGAN_CODES_TO_EXCLUDE.includes(this.organCode.toString());
    // Hide field if organ is excluded
    return !isExcluded;
  }

  /**
   * Return organ name from the organ code using organCodeLookup
   * 
   * @returns {string} organ name 
   */
  get organName(): string {
    return this.organCode ? organCodeLookup[this.organCode] : '';
  }

  /**
   * Boolean used to show the side menu, if true side-nav is open
   * Changes the 'closed' class on the nav
   *
   * @returns {void} changes the sideNavClosed boolean
   */
  public toggleSideNav(): void {
    this.sideNavClosed = !this.sideNavClosed;
  }

  
}
</script>
