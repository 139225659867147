<template>
  <div>
    <template v-if="!dispatchEventsComplete">
      <div class="col-sm-12">
        <div class="col-sm-12">
          {{ $t('loading') }}
        </div>
      </div>
    </template>
    <template v-else>
      <page-top>
        <router-link :to="{ name: 'list-recipients-oop' }" class="router-link-active">
          {{ $t('out_of_province_recipients') }}
        </router-link>
        / {{recipientDisplayName}}
      </page-top>
      <recipient-sticky-summary />
      <div class="content-wrap">
        <div class="container-fluid">
          <div class="nav-wrapper">
            <side-nav-recipient-profile-oop />
            <div class="page-content">
              <!-- Recipient Summary Oop -->
              <recipient-summary-oop />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<i18n src="../_locales/common.json"></i18n>

<script lang="ts">
import { Getter, State } from 'vuex-class';
import { recipients } from '@/store/recipients';
import { Recipient } from '@/store/recipients/types';
import { RecipientJourney } from '@/store/recipientJourney/types';
import PageTop from '@/components/shared/PageTop.vue';
import { Component, Vue } from 'vue-property-decorator';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import { SaveProvider, SaveResult } from '@/types';
import RecipientSummaryOop from '@/components/recipientsOop/RecipientSummaryOop.vue';
import SideNavRecipientProfileOop from '@/components/recipientsOop/side-nav/SideNavRecipientProfileOop.vue';
import RecipientStickySummary from '@/components/recipients/RecipientStickySummary.vue';

@Component({
  components: {
    PageTop,
    SaveToolbar,
    RecipientSummaryOop,
    SideNavRecipientProfileOop,
    RecipientStickySummary,
  },
})
export default class EditRecipientOop extends Vue {
  // State
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;

  // Getters
  @Getter('recipientDisplayName', { namespace: 'recipients' } ) private recipientDisplayName!: string;
  @Getter('clientId', { namespace: 'recipients' }) private clientId!: string|undefined;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;

  private dispatchEventsComplete = false;

  // Vue lifecycle hooks
  public mounted(): void {
    const recipientId = this.clientId;
    const organ_id = this.recipient.journeys ? this.recipient.journeys[0]._id?.$oid : undefined;
    Promise.all([
      this.$store.commit('setPageTitle', `Out of Province Recipients / ${this.recipientDisplayName}`),
      this.$store.dispatch('recipients/loadMeasurements', recipientId),
      this.$store.dispatch('journeyState/getJourney', organ_id)
    ]).finally(() => {
      this.dispatchEventsComplete = true;
      this.$store.dispatch('utilities/scrollBehavior');
    });
  }
}
</script>
