<template>
  <sub-section
    :title="$t('waitlist_summary')"
    sub-section-id="waitlist-summary"
    :divider="true"
    :disabled="true"
  >
    <template v-slot:contents>
      <template v-if="!editState || !editState.summary">
        {{$t('loading')}}
      </template>
      <fieldset v-else id="waitlist-summary" :disabled="true">
        <template v-if="showIposForJourney">
          <!-- IPOS Heart Fields -->
        <div class="row">
          <div class="standard-form-group">
            <date-input
              input-id="ws-initial_list_date"
              :name="$t('initial_list_date')"
              v-model="editState.summary.listDate"
              :readonly="true"
              :disabled="true"
            />
          </div>
          <div class="standard-form-group">
            <date-input
              input-id="ws-on_wait_time_date"
              :name="$t('on_wait_time_date')"
              v-model="waitlistDurations.on_wait_time_date"
              :readonly="true"
              :disabled="true"
              :isLoading="!isWaitTimeLoaded"
            />
          </div>
          <div class="standard-form-group">
            <date-input
              input-id="ws-ctr_wait_time_date"
              :name="$t('ctr_wait_time_date')"
              v-model="waitlistDurations.ctr_wait_time_date"
              :readonly="true"
              :disabled="true"
              :isLoading="!isWaitTimeLoaded"
            />
          </div>
          <div class="standard-form-group">
            <text-input
              input-id="ws-hsh"
              :name="$t('hsh')"
              v-model="editState.summary.hsh"
              :readonly="true"
              :disabled="true"
              class="text-input-no-border"
            />
          </div>
        </div>
        </template>
        <!-- All organs -->
        <div class="row" v-if="!showIposForJourney">
          <div class="standard-form-group">
            <date-input
              input-id="ws-list-date"
              :name="$t('list_date')"
              v-model="editState.summary.listDate"
              :readonly="true"
              :disabled="true"
            />
          </div>
        </div>
        <!-- Kidney -->
        <template v-if="showKidneyFields">
        <div class="row">
          <div class="standard-form-group">
            <date-input
              input-id="ws-dialysis-date"
              :name="$t('dialysis_date')"
              v-model="editState.summary.dialysisDate"
              :readonly="true"
              :disabled="true"
            />
          </div>
        </div>
        </template>
      </fieldset>
    </template>
  </sub-section>
</template>

<i18n src="@/components/_locales/common.json"></i18n>
<i18n src="@/components/_locales/iposFields.json"></i18n>
<i18n src="@/components/organs/shared/_locales/WaitlistSummary.json"></i18n>
<i18n src="./_locales/common.json"></i18n>

<script lang="ts">
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-class';
import { OrganCodeValue } from '@/store/lookups/types';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { WaitlistSectionPageState } from '@/components/organs/shared/_WaitlistSection.vue';
import { RecipientJourney, RecipientWaitlistAttributes, RecipientWaitlistFactors, JourneyDurations } from '@/store/recipientJourney/types';
import TextInput from "@/components/shared/TextInput.vue";
import CheckboxInput from '@/components/shared/CheckboxInput.vue';

export interface WaitlistSummaryState {
  listDate: string|null;
  dialysisDate: string|null;
  hsh: string;
}

// NOTE: the ctr_wait_time (days) number should NOT be displayed on journey page (see TPGLI-5939)
export interface WaitlistDurations {
  on_wait_time_date: string|null;
  ctr_wait_time_date: string|null;
}

@Component({
  components: {
    DateInput,
    TextInput,
    CheckboxInput,
    SubSection,
  },
})
export default class WaitlistSummary extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.waitlistSection) editState!: WaitlistSectionPageState;
  @State(state => state.journeyState.journeyDurations) journeyDurations!: JourneyDurations;

  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;
  @Getter('showIposForJourney', { namespace: 'journeyState' }) private showIposForJourney!: boolean;

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) isWaitTimeLoaded!: boolean;

  // Organ code from top-level journey organ_code or from the route params
  get organCode(): number {
    if (this.newJourney) return Number(this.$route.params.organ_code);
    return this.journey?.organ_code ? this.journey.organ_code : 0;
  }

  // Whether or not to show fields associated with Kidney organ
  get showKidneyFields(): boolean {
    return this.organCode === OrganCodeValue.Kidney;
  }

  // Initialize state based on Recipient Journey data
  private mounted(): void {
    const payload = { recipientId: this.recipientId, journeyId: this.journeyId };
    this.$store.dispatch('journeyState/loadJourneyDurations', payload).then(() => {
      this.initializeForm();
      this.$emit('loaded', 'waitlistSummary');
    });
  }

  /**
   * Initialize form state based on latest Recipient Journey data
   *
   * Note: assumes that the 'waitlistSection' page key has been already been set in the
   * _WaitlistSection card-section component before this component sets its nested state
   */
  private initializeForm(): void {
    // Commit our state
    this.$store.commit('pageState/set', {
      pageKey: 'waitlistSection',
      componentKey: 'summary',
      value: this.buildWaitlistSummaryState()
    });
  }

  /**
   * Build state for the Waitlist Summary area based on selected journey
   * 
   * @returns {WaitlistSummaryState} form state for Waitlist Summary
   */
  private buildWaitlistSummaryState(): WaitlistSummaryState {
    const waitlistAttributes = this.journey?.stage_attributes?.waitlist || {};
    const waitlistFactors = waitlistAttributes?.factors || {};
    const result = {
      listDate: this.parseDateUi(waitlistFactors.listing_date) || null,
      dialysisDate: this.parseDateUi(waitlistFactors.dialysis_start_date) || null,
      hsh: this.journey?.recipient_programs?.hsx_eligible ? this.$t('yes').toString() : this.$t('no').toString(),
    };
    return result;
  }

  /**
   * Get values derived as part of 'Durations' request e.g. Wait Time Date
   *
   * NOTE: here we are using a getter separate from the 'build state' pattern, because this data
   * is an ad-hoc calculation and the latest values are not available on the recipient journey.
   */
  get waitlistDurations(): WaitlistDurations {
    const ontarioWaitTimeDate = this.parseDateUiFromDateTime(this.journeyDurations?.waitlist?.wait_days_date) || null;
    const canadianTransplantRegistryWaitTimeDate = this.parseDateUiFromDateTime(this.journeyDurations?.waitlist?.ctr_wait_days_date) || null;
    return {
      on_wait_time_date: ontarioWaitTimeDate,
      ctr_wait_time_date: canadianTransplantRegistryWaitTimeDate,
    };
   }

  // Reload component when any the journey's waitlist attributes and/or factors change
  public reload(): void {
    this.initializeForm();
  }
}
</script>
