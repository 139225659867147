<template>
  <validation-provider 
    ref="provider"
    :rules="formRules" 
    :name="name" 
    :vid="validationId ? validationId : inputId" 
    v-slot="{ errors }">
    <label :for="inputId" :class="{ 'sr-only': hideLabel }">
      {{label || name}}
      <validation-asterisk :rules=formRules :crossValues="crossValues" :ruleKey="ruleKey"/>
      <slot name="link" />
    </label>
    <small v-if="calculated" class="form-text text-muted">
      <font-awesome-icon class="text-grey" :icon="['far', 'exclamation-circle']" fixed-width />
      {{calculatedText}}
    </small>

    <Tooltip v-if="showToolTip" :toolTipText="toolTipText" :inputID="inputId"/>
    <template v-if="isMasked">
      <input
        type="text"
        :class="{ 'is-invalid': !disabled && errors[0], 'form-control': !isReadOnly(readonly), 'form-control-plaintext': isReadOnly(readonly) }"
        :id="inputId"
        :disabled="true"
        :readonly="true"
        :value="value"
      />
    </template>
    <template v-else-if="append">
      <div class="input-group mb-3">
        <input 
          :id="inputId"
          type="text"
          :class="{ 'is-invalid': !disabled && errors[0], 'form-control': !isReadOnly(readonly), 'form-control-plaintext': isReadOnly(readonly) }"
          :value="value"
          :readonly="isReadOnly(readonly||disabled)"
          v-on="maskedInputEvents()"
          :placeholder="timeFormatDisplay"
          :aria-label="timeFormatAriaLabel"
          v-mask="timeFormatMask"
         />
        <div class="input-group-append">
          <span class="input-group-text">{{appendText}}</span>
        </div>
        <div class="invalid-feedback" :id="`${inputId}-error`" v-if="errors[0]">
          <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
          {{ translateError(errors, label || name) }}
        </div>
      </div>
    </template>
    <template v-else>
      <input
        :id="inputId"
        type="text"
        :class="{ 'is-invalid': !disabled && errors[0], 'form-control': !isReadOnly(readonly), 'form-control-plaintext': isReadOnly(readonly) }"
        :value="value"
        :readonly="isReadOnly(readonly||disabled)"
        v-on="maskedInputEvents()"
        :placeholder="timeFormatDisplay"
        :aria-label="timeFormatAriaLabel"
        v-mask="timeFormatMask"
      />
      <div class="invalid-feedback" :id="`${inputId}-error`" v-if="errors[0]">
        <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
        {{ translateError(errors, label || name) }}
      </div>
    </template>
  </validation-provider>
</template>

<script lang="ts">
import '@/vee-validate-rules.ts';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';
import { Rules } from '@/store/validations/types';
import ValidationAsterisk from '@/components/shared/ValidationAsterisk.vue';
import { isMasked } from '@/utils';
import { Format } from '@/store/utilities/types';

@Component({
  components: {
    ValidationAsterisk
  }
})
export default class TimeInput extends Vue {
  @Getter('getRuleSet', { namespace: 'validations' }) private ruleSet!: Rules;
  @Getter('getDateFormat', { namespace: 'features' }) private getDateFormat!: string;
  @Getter('getRules', { namespace: 'validations' }) private getRules!: (ruleSet: any, ruleKey: string, rules: string) => any;
  @Getter('isReadOnly', { namespace: 'validations' }) private isReadOnly!: (readonly?: any) => boolean;
  @Getter('translateError', { namespace: 'utilities' }) private translateError!: (error?: any, field?: string|null) => string;

  // V-model
  @Prop() value!: string;

  // Standard properties
  @Prop({ required: true }) inputId!: string; // MANDATORY actual HTML element ID, set indirectly using properties like 'inputId' and 'selectId'
  @Prop({ required: true }) name!: string; // Field name, also used as the label

  // Optional properties
  @Prop({ default: null }) validationId!: string; // OPTIONAL specify a 'vid' property for validation-provider, if it must be different than the element ID
                                                  // used by parent component after attempting to save to decide where server-side validation errors are shown
  @Prop({ default: null }) label!: string; // Alternate Label property
  @Prop({ default: false }) disabled!: boolean; // Turn input data entry off
  @Prop({ default: false }) append!: boolean; // Input label addon
  @Prop({ default: '' }) appendText!: string; // Input label addon
  @Prop({ default: false }) calculated!: boolean|string // Show Calculated indicator
  @Prop({ default: 'Calculated' }) calculatedText!: string; // Customize label for Calculated indicator

  @Prop({ default: false }) showToolTip!: boolean|string // Show tooltip
  @Prop({default: ''}) toolTipText!: string|null; // Customize label for toolTipText
  @Prop({ default: false }) readonly!: boolean; // Render input as if it were plain text and turn input data entry off
  @Prop({ default: false }) hideLabel!: boolean; // Hide label visually, while still being readable for screen readers

  @Prop({ default: null }) rules!: string; // OPTIONAL lets us hard-code the client-side vee-validate rules in the front-end instead of using anything provided by the back-end
  @Prop({ default: null }) ruleKey!: string // OPTIONAL parameter path to load client-side validation e.g. new_validations, edit_validations
                                            // used by input components to set 'rules' properties in their validation providers based on the client-side validations loaded from the back-end 
                                            
  @Prop({ default: null }) crossValues!: any; // valus needed for cross field validation for the asterix

  // masked input
  get isMasked(): boolean {
    return isMasked(`${this.value}`);
  }

  get formRules(): any {
    return this.getRules(this.ruleSet, this.ruleKey, this.rules);
  }

  // Default time input format expressed as a human-readable string
  get timeFormatDisplay(): string {
    return Format(this.getDateFormat).TIME_DISPLAY;
  }

  // Default date input format expressed as a text masking directive
  get timeFormatMask(): string {
    return Format(this.getDateFormat).TIME_MASK;
  }

  // Default time input format described for screen readers
  get timeFormatAriaLabel(): string {
    return Format(this.getDateFormat).TIME_ARIA_DISPLAY;
  }

  // Forward events to the parent component
  public maskedInputEvents(): any {
    const _vm = this as TimeInput;
    return Object.assign({},
      // parent listeners
      this.$listeners,
      {
        // custom listeners
        input(event: any) {
          // Emit updated value for v-model
          _vm.$emit('input', event.target.value);
        }
      }
    );
  }
}
</script>
