<template>
  <div>
    <page-top>
      {{recipientListSettings && getListOop ? $t('out_of_province_recipients') : $t('recipients')}}
    </page-top>
    <template v-if="!recipients">
      <div class="content-wrap">
        <div class="container-fluid">
          <loading-list-view />
        </div>
      </div>
    </template>
    <full-page-list-layout v-else>
      <template v-slot:content>
        <nav class="nav list-patients action-row">
          <column-config
            columnKey="recipientListColumns"
            :optionDefaults="getColumnDefaults"
            config-id="-list-columns"
            :options="recipientsColumns"
            :hiddenOptions="hiddenOptions"
            v-model="columnsFilter.selectedColumns"
          />
          <div class="ml-auto">
            <button class="btn btn-success" @click="toggleTableByOOP" v-if="canAccessOopRecipients && prototypeFeatureEnabled('demo_hide_oop')" :disabled="isLoading">
              {{getOOPToggleButtonText}}
              <font-awesome-icon class="fa-1x fa-spin" :icon="['far', 'spinner-third']" v-if="isLoading" />
            </button>
            <router-link class="btn btn-primary" :class="{ disabled: !canCreate }" :to="{ name: 'new-recipient' }">
              {{$t('add_new_recipient')}}
            </router-link>
          </div>
        </nav>
        <template>
          <p>{{ $t("system_returned")}} <b>{{recipients.count}}</b> {{ $t('recipients') }}</p>
          <div class="highlight-scroll" v-if="isOverflowed">
            {{ $t('scroll_right_to_see_more') }}
            <font-awesome-icon :icon="['fas', 'chevron-right']" class="nav-caret" fixed-width />
          </div>
          <sub-section
            sub-section-id="list-recipient"
            :lookupsToLoad="lookupsToLoad"
            title=""
            mode="remote"
            :tabbableValue="$t('client_id')"
            :total-records="recipients.count"
            :table-config="recipientTableConfig()"
            :isParentLoading="isLoading"
            @on-page-change="updatePagination($event)"
            @on-per-page-change="updatePagination($event)"
            @on-column-filter="filterList($event)"
            @on-sort-change="filterList($event)"
            @table-row-click="selectRecipient($event)">
          </sub-section>
        </template>
      </template>
    </full-page-list-layout>
  </div>
</template>

<i18n src="../_locales/common.json"></i18n>
<i18n src="../../_locales/recipients/recipientsListTableColumns.json"></i18n>
<i18n src="../../components/_locales/Organs.json"></i18n>

<script lang="ts">
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { Getter, State } from 'vuex-class';
import { VueGoodTable } from 'vue-good-table';
import { Recipient } from '@/store/recipients/types';
import PageTop from '@/components/shared/PageTop.vue';
import SubSection from '@/components/shared/SubSection.vue';
import journeyStatus from '@/assets/config/journeyStatus.json';
import { JourneyStage, JourneyStatus, RecipientJourney } from '@/store/recipientJourney/types';
import { journeyStatusOption } from '@/store/recipients/types';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { Organ, InsurancePlanCode, OrganCodeValue } from '@/store/lookups/types';
import { NumericCodeValue, GenericCodeValue } from '@/store/types';
import { OrganWaitlistMedicalStatus, RhIndicator, BloodType, Ethnicity, Gender } from '@/store/lookups/types';
import { Format } from '@/store/utilities/types';
import { urlParams, isMasked } from '@/utils';
import LoadingListView from '@/components/shared/LoadingListView.vue';
import FullPageListLayout from "@/components/layouts/FullPageListLayout.vue";
import ColumnConfig from '@/components/shared/ColumnConfig.vue';
import { HospitalOption } from "@/store/hospitals/types";
import { titleCase } from "@/utils";

interface ColumnsFilterState {
  selectedColumns: string[];
}

interface RecipientListingRow {
  national_recipient_id: string,
  client_id: string,
  first_name: string,
  last_name: string,
  dob: string,
  organ: string,
  journey_status: string,
  medical_status: string,
  insurance_plan: string,
  insurance_number: string,
  registration_date: string,
  urgent: string,
  blood_type: string,
  rh: string,
  sex: string,
  ethnicity: string,
  program_abbreviation: string
}

interface MedicalStatusOption {
  id: number,
  medicalName: string,
  medicalCode: string,
  organName: string,
  organCode: string
}

@Component({
  components: {
    PageTop,
    SubSection,
    ColumnConfig,
    VueGoodTable,
    LoadingListView,
    FullPageListLayout
  }
})
export default class ListRecipients extends mixins(DateUtilsMixin) {
  @State(state => state.pageState.currentPage.recipientListSettings) private recipientListSettings!: any;
  @State(state => state.lookups.insurance_plan_codes) insurancePlanCodeLookup!: InsurancePlanCode[];
  @State(state => state.pageState.currentPage.columnsFilter) columnsFilter!: ColumnsFilterState;
  @State(state => state.lookups.organ) organLookup!: Organ[];
  @State(state => state.lookups.rh_indicator) private rhIndicatorLookup!: RhIndicator[];
  @State(state => state.lookups.blood_type) bloodTypeLookup!: BloodType[];

  //Getters
  @Getter('showList', { namespace: 'recipients' }) public recipients!: Recipient[];
  @Getter('organName', { namespace: 'lookups' }) organName!: (organCode?: number) => string;
  @Getter('describeMedicalStatus', { namespace: 'lookups' }) describeMedicalStatus!: (organLookup?: Organ[], organCode?: number, medicalStatusCode?: string|null, secondaryMedicalStatusCode?: string|null) => string|undefined;
  @Getter('medicalStatusLookup', { namespace: 'lookups' }) medicalStatusLookup!: (excludeHold: boolean, organLookup?: Organ[], organCode?: number) => any;
  @Getter('organOptions', { namespace: 'lookups' }) organOptions!: (type?: string) => NumericCodeValue[];
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('getJourneyStatusDisplayValue', { namespace: 'recipients' }) journeyStatusDisplayValue!: (journey: RecipientJourney) => string|undefined;
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;
  @Getter('canAccessOopRecipients', { namespace: 'users' }) private canAccessOopRecipients!: boolean;
  @Getter('getColumnPreferences', { namespace: 'users'}) getColumnPreferences!: (columnKey: string, optionDefaults: string[]) => string[];
  @Getter('ethnicity', { namespace: 'lookups' }) ethnicity!: Ethnicity[];
  @Getter('sexOptions', { namespace: 'lookups' }) sexOptions!: Gender[];
  @Getter('nonExpiredOrganOptions', { namespace: 'lookups' }) nonExpiredOrganOptions!: (type?: string) => NumericCodeValue[];
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;


  private lookupsToLoad = [ 'insurance_plan_codes', 'ethnicity', 'gender', 'blood_type', 'rh_indicator' ];

  public currentPage = 1;
  public perPage = 25;
  public isOverflowed = false;
  private searchParams: string|null = null;
  private sortParams: string|null = null;
  public isLoading = false;
  private organCode: string|null = null;

  get getListOop(): boolean {
    return this.recipientListSettings && this.recipientListSettings.listOOP || false;
  }

  get hiddenOptions(): undefined|string[] {
    return this.getListOop ? ['registration_date'] : undefined;
  }

  setListOOP(value = false): void {
    this.$store.commit('pageState/set', { pageKey: 'recipientListSettings', value: { listOOP: value }});
  }

  get getOOPToggleButtonText(): any {
    return this.getListOop ? this.$t('view_ontario_recipients') : this.$t('view_out_of_province_recipients');
  }

  private toggleTableByOOP(): void {
    this.setListOOP(!this.getListOop);
    this.loadData(this.searchParams, this.sortParams);
  }

  get canCreate(): boolean {
    return this.checkAllowed('/recipients', 'POST');
  }

  public checkwidth() {
    this.isOverflowed = window.innerWidth < 1200;
  }

  public mounted(): void {
    this.$store.commit('setPageTitle', `Patients`);
    // allow to use a permalink to view out of province recipients
    const listOOP = location.hash == '#oop-recipients' ? true : false;
    Promise.all([
      this.$store.commit('pageState/set', { pageKey: 'recipientListSettings', value: { listOOP: listOOP }}),
    ]).finally(() => {
      this.loadData(this.searchParams, this.sortParams);
      this.initializePageState();
      this.checkwidth();
      window.addEventListener('resize', this.checkwidth);
    });
  }

  private initializePageState(): void {
    // Initialize filter form state
    this.$store.commit('pageState/set', {
      pageKey: 'columnsFilter',
      value: this.buildColumnsFilter(),
    });
  }

  private buildColumnsFilter(): ColumnsFilterState {
    // get selected columns from user preferences or defaults
    const columns = this.getColumnPreferences('recipientListColumns', this.getColumnDefaults);

    return {
      selectedColumns: columns,
    };
  }

  get filteredColumns(): any[] {
    if (!this.columnsFilter || !this.columnsFilter.selectedColumns) {
      return this.recipientsColumns;
    }
    const selectedFields = this.columnsFilter.selectedColumns;
    const selectedColumns = this.recipientsColumns.filter((column: any) => {
      return selectedFields.includes(column.field);
    });
    return selectedColumns;
  }

  public updatePagination(event: any) {
    this.searchParams = urlParams(this.processSearchParams(event?.searchParams));
    this.sortParams = urlParams(this.processSortParams(event?.searchParams, event?.sortParams));
    this.currentPage = event.currentPage;
    this.perPage = event.currentPerPage;
    this.loadData(this.searchParams, this.sortParams);
  }

  /**
   * Sets the search terms and sorting options
   *
  */
  public filterList(event: any) {
    this.searchParams = urlParams(this.processSearchParams(event?.searchParams));
    this.sortParams = urlParams(this.processSortParams(event?.searchParams, event?.sortParams));
    this.currentPage = event.currentPage;
    this.loadData(this.searchParams, this.sortParams);
  }

  /**
   * takes search params and if medical_status included rebuilds data with correct medical_code & organ_code
   *
   * @param searchParams object
   * @returns {any} processed search params object with medical & organ code applied if needed
   */
  processSearchParams(searchParams: any): any {
    let newSearchParams = Object.assign({}, searchParams);
    if (searchParams.medical_status) {
      const found = this.medicalStatusOptions.find((item: any) => {
        return item.id == searchParams.medical_status;
      });
      if (found) {        
        newSearchParams.medical_status = found.medicalCode;
        newSearchParams.organ_code = found.organCode;
        return newSearchParams;
      } else {
        return searchParams;
      }
    } else {
      return searchParams;
    }
  }

  /**
   * if medical_status selected, clear organ_code sorting, otherwise return original data
   *
   * @param searchParams object
   * @param sortParams object
   * @returns {any} processed sort params object
   */
  processSortParams(searchParams: any, sortParams: any): any {
    if (searchParams.medical_status) {
      const newSortParams = Object.assign({}, sortParams);
      newSortParams.sort_options == 'organ_code' ? null : sortParams;
      return newSortParams;
    } else {
      return sortParams;
    }
  }

  /**
   * Loads recipients list with the custom search parameters and sort options
   *
   */
  public loadData(searchParams: any, sortParams: any) {
    this.isLoading = true;
    const search_params = [searchParams, sortParams].filter((p) => { return p && p.length >=0; });
    const dispatchMethod = this.getListOop ? 'recipients/getListOop' : 'recipients/getList';
    this.$store.dispatch(
      dispatchMethod,
      {
        pageNumber: this.currentPage,
        pageSize: this.perPage,
        search_params: `${search_params.length > 0 ? '&' : ''}${search_params.join('&')}`,
        oopResults: this.getListOop
      }
    ).then(() => {
      setTimeout(() => {
        this.isLoading = false;
      }, 600);
    });
  }

  public selectRecipient(event: any) {
    if (event.row.client_id !== undefined) {
      const clientId: string = event.row.client_id.toString();
      const routerUrl = this.getListOop ? 'edit-recipient-oop' : 'edit-recipient';
      this.$router.push({
        name: routerUrl,
        params: {
          id: clientId,
        }
      });
    } else {
      console.warn('Missing recipient clientId');
      this.$router.push({name: 'list-recipients'});
    }
  }

  /**
   * checks for medical_status property in searchParams querystring object
   *
   * @returns {boolean} true/false
   */
  get hasMedicalStatusFilter(): boolean {
    return this.searchParams ? this.searchParams.includes("medical_status") : false;
  }

  /**
   * generates lookup table with each record containing medical and organ details
   *
   * @returns {MedicalStatusOption[]} medical status lookup entries
   */
  get medicalStatusOptions(): MedicalStatusOption[] {    
    let index = 0;
    const filter: any = [];
    const organOptions = this.organOptions();
    organOptions.map((organ: { code: number; value: string }) => {
      const options: OrganWaitlistMedicalStatus[] = this.medicalStatusLookup(true, this.organLookup, organ.code);
      options.forEach((option: OrganWaitlistMedicalStatus) => {
        const values: any[] = filter.flat().map((f: any) => { return f.value; });

        if(values.indexOf(option.value) < 0 ){
          index = index + 1;
          filter.push({
            id: index,
            medicalName: option.value,
            medicalCode: option.code.toString(),
            organName: this.organName(organ.code), 
            organCode: organ.code.toString(),
          });
        }
      });
    });
    return filter;
  }

  get buildInsurancePlansOptions() {
    if ((this.insurancePlanCodeLookup || []).length <= 0) return [];
    return this.insurancePlanCodeLookup.map((plan) =>{
      return {
        value: plan.value,
        code: plan.value
      };
    });
  }

  get buildJourneyStatusFilter(): any {
    const filter: any[] = [];

    journeyStatus.journeyStatus.forEach((status) => {
      if (status.listPageStatus) {
        if(status.waitlistFactors) {
          filter.push(this.buildWaitlistFactorsStatusOption(status));
        } else if (status.decisionType) {
          filter.push(this.builDecisionTypeStatusOption(status));
        } else {
          filter.push(this.buildJourneyStatusOption(status));
        }
      }
    });
    // sort alphabetical order
    return filter.sort((a, b) => (a.value > b.value) ? 1 : -1);
  }

  private buildWaitlistFactorsStatusOption(status: journeyStatusOption): GenericCodeValue {
    const value = this.buildJourneyStatusWithStagePrefix(status);
    return {
      code: `{[stage]=${status.stage}&[state]=${status.state}&[completed]=${status.completed}&[waitlist_factors]=${status.waitlistFactors}}`,
      value,
    };
  }

  private builDecisionTypeStatusOption(status: journeyStatusOption): GenericCodeValue {
    const value = this.buildJourneyStatusWithStagePrefix(status);
    return {
      code: `{[stage]=${status.stage}&[completed]=${status.completed}&[decision]=${status.decisionType}&[decision_state]=${status.decisionState}}`,
      value,
    };
  }

  private buildJourneyStatusOption(status: journeyStatusOption): GenericCodeValue {
    let withState    = `{[stage]=${status.stage}&[state]=${status.state}&[completed]=${status.completed}}`;
    let withoutState = `{[stage]=${status.stage}&[completed]=${status.completed}}`;
    const value = this.buildJourneyStatusWithStagePrefix(status);
    return {
      code: status.state ? withState : withoutState,
      value,
    };
  }

  // Afflo Prototype
  private buildJourneyStatusWithStagePrefix(status: journeyStatusOption): string {
    const displayStage = titleCase(status.stage);
    const displayStatus = this.$t(`${status.listPageStatus} (${status.state})`).toString();
    const value = (displayStage == displayStatus) ? displayStatus : `${displayStage} - ${ displayStatus}`;
    return value;
  }
  
  get buildOrganFilter(): any {
    const allowedOrganCodes = [
      OrganCodeValue.Liver, 
      OrganCodeValue.Heart,  
      OrganCodeValue.Kidney,
      OrganCodeValue.Lung,
      OrganCodeValue.PancreasWhole,
      OrganCodeValue.PancreasIslets,
      OrganCodeValue.SmallBowel,
      OrganCodeValue.VCA];

    // Match options from query filters
    const filter: NumericCodeValue[] = [];

    // NOTE: here we assume that the Recipient Listing page should only support organs with non-expired entry in the organ lookup
    this.nonExpiredOrganOptions().forEach((organ) => {
      if (allowedOrganCodes.includes(organ.code)) {
        // Map lookup format (code/value) to filter option format (value/text)
        filter.push({
          code: organ.code,
          value: `${this.organName(organ.code)}`,
          expired_date: organ.expired_date
        });
      }
    });

    return filter;
  }

  get buildMedicalStatusFilter(): any {
    // Match options from query filters
    const filter: {code: number, value: string}[] = [];
    this.medicalStatusOptions.map((item: any) => {
      filter.push({
        code: item.id,
        value: `${item.organName} - ${item.medicalName}`,
      });
    });
    return filter;
  }

  get sexOptionsDropdownValues(): any[] {
    return this.sexOptions.map((option: any) => {
      return {
        value: option.value,
        code: option.code
      };
    });
  }

  get bloodTypeOptionsDropdownValues(): any[] {
    if(!this.bloodTypeLookup) {
      return [];
    }

    return this.bloodTypeLookup.filter((item: any) => !item.disabled).map((option: any) => {
      return {
        value: option.value,
        code: encodeURIComponent(option.code)
      };
    });
  }

  get rhIndicatorOptionsDropdownValues(): any[] {
    if(!this.rhIndicatorLookup) {
      return [];
    }

    return this.rhIndicatorLookup.filter((item: any) => !item.disabled).map((option: any) => {
      return {
        value: option.value,
        code: encodeURIComponent(option.code)
      };
    });
  }

  /**
   * Parse combined date/time field to be shown in table
   *
   * @param value the datetime property
   * @returns {string} string representation of date
   */
  formatDateFromDateTime(value: string|null): string {
    if (!value) return '-';
    if (isMasked(value)) return `${value}`;

    return this.parseDisplayDateUiFromDateTime(value) || '-';
  }

  formatEthnicity(value: number): string|undefined {
    const ethnicity = this.ethnicity.find((item: Ethnicity) => value === item.code);
    return ethnicity ? ethnicity.value : undefined;
  }

  /**
   * Parse date-only field to be shown in table
   *
   * @param value the date property
   * @returns {string} string representation of date
   */
  formatDate(value: string|null): string {
    if (!value) return '-';
    if (isMasked(value)) return `${value}`;

    return this.parseDisplayDateUi(value) || '-';
  }

  // Afflo Prototype
  formatDateArray(values: (string|null)[]): string {
    if (!values) return 'N/A';

    const formatted = values.map((value: string|null): string => {
      return this.formatDateFromDateTime(value);
    });
    const interpolated = formatted.join('<br />');
    return interpolated;
  }

  get getColumnDefaults(): string[] {
    return [
      'client_id',
      'first_name',
      'last_name',
      'dob',
      'organ_code',
      'journey_status',
      'medical_status',
      'insurance_plan',
      'insurance_number'
    ];
  }

  /**
   * Getter for the columns.
   *
   */
  get recipientsColumns(): any[] {
    return [
      {
          label: `${this.$t('ctr_id')}`,
          field: 'national_recipient_id',
          sortable: true,
          width: '115px',
          filterOptions: {
            enabled: true,
            custom: true,
            type: 'text'
          }
        },
        {
          label: `${this.$t('client_id')}`,
          field: 'client_id',
          sortable: true,
          width: '115px',
          filterOptions: {
            enabled: true,
            custom: true,
            type: 'text'
          }
        },
        {
          label: `${this.$t('first_name')}`,
          field: 'first_name',
          sortable: true,
          width: '200px',
          filterOptions: {
            enabled: true,
            custom: true,
            type: 'text'
          }
        },
        {
          label: `${this.$t('last_name')}`,
          field: 'last_name',
          sortable: true,
          width: '200px',
          filterOptions: {
            enabled: true,
            custom: true,
            type: 'text'
          }
        },
        {
          label: `${this.$t('dob')}`,
          field: 'dob',
          type: 'date',
          sortable: false,
          width: '120px',
          thClass: 'vgt-left-align',
          tdClass: 'vgt-left-align',
          formatFn: this.formatDate
        },
        {
          label: `${this.$t('organ_referral')}`,
          field: 'organ_code',
          html: true,
          sortable: !this.hasMedicalStatusFilter,
          width: '160px',
          filterOptions: {
            enabled: !this.hasMedicalStatusFilter,
            custom: true,
            placeholder: this.$t('all'),
            type: 'dropdown',
            filterDropdownItems: this.buildOrganFilter
          }
        },
        {
          label: `${this.$t('journey_status')}`,
          field: 'journey_status',
          sortable: true,
          html: true,
          width: '190px',
          filterOptions: {
            enabled: true,
            custom: true,
            type: 'dropdown',
            filterDropdownItems: this.buildJourneyStatusFilter
          },
        },
        {
          label: `${this.$t('medical_status')}`,
          field: 'medical_status',
          sortable: false,
          html: true,
          width: '250px',
          filterOptions: {
            enabled: true,
            custom: true,
            type: 'dropdown',
            placeholder: this.$t('all'),
            filterDropdownItems: this.buildMedicalStatusFilter
          }
        },
        {
          label: `${this.$t('insurance_plan')}`,
          field: 'insurance_plan',
          sortable: true,
          width: '140px',
          filterOptions: {
            enabled: true,
            custom: true,
            placeholder: this.$t('all'),
            type: 'dropdown',
            filterDropdownItems: this.buildInsurancePlansOptions
          }
        },
        {
          label: `${this.$t('insurance_number')}`,
          field: 'insurance_number',
          sortable: true,
          thClass: 'has-style',
          tdClass: 'has-style',
          width: '160px',
          filterOptions: {
            enabled: true,
            custom: true,
            type: 'text'
          }
        },
        {
          label: `${this.$t('recipient_registration_date')}`,
          field: 'registration_date',
          sortable: true,
          width: '160px',
          thClass: 'vgt-left-align',
          tdClass: 'vgt-left-align',
          formatFn: this.formatDateFromDateTime,
          hidden: this.getListOop //Hide registration_date for OOP Recipients (generated via ctr)
        },
        {
          label: `${this.$t('blood_type')}`,
          field: 'blood_type',
          width: "120px",
          sortable: true,
          filterOptions: {
            enabled: true,
            custom: true,
            type: 'dropdown',
            placeholder: this.$t('all'),
            filterDropdownItems: this.bloodTypeOptionsDropdownValues
          }
        },
        {
          label: `${this.$t('rh')}`,
          field: 'rh',
          width: "130px",
          sortable: false,
          filterOptions: {
            enabled: true,
            custom: true,
            type: 'dropdown',
            placeholder: this.$t('all'),
            filterDropdownItems: this.rhIndicatorOptionsDropdownValues
          }
        },
        {
          label: `${this.$t('sex')}`,
          field: 'sex',
          width: "130px",
          sortable: true,
          filterOptions: {
            enabled: true,
            custom: true,
            type: 'dropdown',
            placeholder: this.$t('all'),
            filterDropdownItems: this.sexOptionsDropdownValues
          }
        },
        {
          label: `${this.$t('ethnicity')}`,
          field: 'ethnicity',
          sortable: false,
          width: '160px',
          formatFn: this.formatEthnicity
        },
        {
          label: `${this.$t('listed_date')}`,
          field: 'listed_date',
          html: true,
          sortable: false,
          width: '160px',
        },
        {
          label: 'Wait Days',
          field: 'waitlist_report_calculated_wait_days',
          html: true,
          sortable: false,
        },
        {
          label: `${this.$t('CPRA')}`,
          field: 'CPRA',
          sortable: false,
          width: '160px',
          formatFn: this.formatEthnicity
        }
        
      ];
  }

  /**
   * Translates any of the columns that are just codes and unreadable to a more readable format and adds it into the object
   *
   * Affects the following columns: Organ Referrals, Journey Status, Medical Status, Insurance Type
   */
  get recipientList(): any[] {
    if (!this.recipients || this.isLoading) return [];

    const recipients: any = this.recipients.entries;
    const result = recipients.map((recipient: Recipient) => {
      let metaData: any = {}; // data to inject

      // Find the Insurance Name from the lookup
      const insuranceName = this.lookupValue(`${recipient.insurance_plan_code}`, 'insurance_plan_codes') || '-';

      if (!recipient.journeys || recipient.journeys.length === 0 ) {
        metaData = {
          organ: 'None',
          insurance_plan: insuranceName,
        };
      } else {
        const journeyOrgans = recipient.journeys.map((journey: RecipientJourney) => {
          const organCode = journey.organ_code;
          if (organCode == null) {
            return 'N/A';
          }
          const organName: string|undefined = this.organName(organCode) || undefined;
          return organName ? this.$t(organName) : 'Unknown';
        });
        const journeyStatus = recipient.journeys.map((journey: RecipientJourney) => {
          if (journey.stage == null) {
            return 'N/A';
          }
          //Display '--' for completed journey's
          // Completed post-transplant journey's will appear as specified by journeyStatus JSON file
          const isPostTransplant = journey.stage == JourneyStage.PostTransplant;

          if(journey.completed && !isPostTransplant) return '--';

          // Afflo Prototype
          const displayStage = titleCase(journey.stage);
          const displayStatus = this.journeyStatusDisplayValue(journey);
          const interpolated = (displayStage == displayStatus) ? displayStatus : `${displayStage} - ${ displayStatus}`;
          return interpolated;
        });
        const journeyMedicalStatus = recipient.journeys.map((journey: RecipientJourney) => {
          if (journey.medical_status === undefined || journey.medical_status === null) {
            return 'N/A';
          }
          const medicalStatus = this.describeMedicalStatus(this.organLookup, journey.organ_code, journey.medical_status);
          return medicalStatus ? medicalStatus : 'Unknown';
        });
        const journeyPrograms = recipient.journeys.map((journey: RecipientJourney) => {
          const program = journey.program_abbreviation;
          if (program == null) {
            return 'N/A';
          }
          return program ? program : 'Unknown';
        });
        const urgentValue = recipient.urgent ? 'Yes' : 'No';
        const journeyListingDates = recipient.journeys.map((journey: RecipientJourney) => {
          return journey.stage_attributes?.waitlist?.factors?.listing_date || null;
        });
        const journeyWaitDays = recipient.journeys.map((journey: RecipientJourney) => {
          return journey.stage_attributes?.waitlist?.waitlist_report_calculated_wait_days || '-';
        }).join("<br/>");
        metaData = {
          organ_code: journeyOrgans.join('<br /> '),
          journey_status: journeyStatus.filter(Boolean).join('<br /> '),
          medical_status: journeyMedicalStatus.filter(Boolean).join('<br /> '),
          insurance_plan: insuranceName,
          program_abbreviation: journeyPrograms.join('<br /> '),
          listed_date: this.formatDateArray(journeyListingDates),
          waitlist_report_calculated_wait_days: journeyWaitDays
        };
      }
      return Object.assign(recipient, metaData);
    });
    return result;
  }

  private recipientTableConfig(): TableConfig {
    return {
      data: this.recipientList,
      columns: this.filteredColumns, // switch to function to make sure options rebuilt each time
      empty: 'Cannot find any Recipients that match the selected filters',
      createButton: false,
      pagination: true,
      sortOptions: {
        enabled: true
      },
      paginationOptions: {
        enabled: true,
        perPage: this.perPage,
        mode: 'pages',
        perPageDropdown: [10, 25, 100],
        setCurrentPage: this.currentPage,
        dropdownAllowAll: false,
        nextLabel: '',
        prevLabel: '',
        rowsPerPageLabel: 'Results per page',
        position: 'bottom'
      }
    };
  }
}
</script>
