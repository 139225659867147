<template>
  <div>
    <template>
      <fieldset :id="subSectionId" :disabled="disabled">
        <legend v-if="subtitle && subtitle.length > 0" :aria-describedby="`${subSectionId}-note`">
          <collapsible-heading
            v-if="subSubSection"
            tag="h5"
            class="legend-title d-inline"
            :heading="title"
            :collapsible="collapsible"
            :guidingText="guidingText"
            v-model="collapsed"
          />
          <collapsible-heading
            v-else
            class="legend-title d-inline"
            :heading="title"
            :collapsible="collapsible"
            :guidingText="guidingText"
            v-model="collapsed"
          />
          <small :id="`${subSectionId}-note`" class="form-text text-muted d-inline">
            {{subtitle}}
          </small>
        </legend>
   
        <legend v-else-if="title.length > 0 && hideTitle == false">
          <collapsible-heading
            v-if="subSubSection"
            tag="h5"
            :heading="title"
            :collapsible="collapsible"
            :guidingText="guidingText"
            v-model="collapsed"
          />
          <collapsible-heading
            v-else
            :heading="title"
            :collapsible="collapsible"
            :guidingText="guidingText"
            v-model="collapsed"
          />
        </legend>
        <transition name="fade" mode="out-in">
          <div v-if="tableConfig" :class="tableWrapperClass" v-show="!collapsed">
            <div class="col-sm-12">
              <div class="table-responsive-md">
                <slot name="button-bar" />
                <!-- DIAG: If table data is empty at the start but is populated after, it's possible that the new data isn't loaded. -->
                <vue-good-table
                  :style-class="styleClass"
                  :row-style-class="rowStyle"
                  :mode="mode || ''"
                  :totalRows="totalRecords"
                  :columns="tableConfig.columns"
                  :rows="tableConfig.data || []"
                  :group-options="tableConfig.groupOptions"
                  :pagination-options="paginationOptions"
                  :sort-options="sortOptions"
                  :search-options="searchOptions"
                  :ref="tableRef"
                  @on-sort-change="onSortChange($event)"
                  @on-row-click="onRowClick($event)"
                  @on-page-change="onPageChange($event)"
                  @on-per-page-change="onPerPageChange($event)">
                  <template v-if="props.column.filterOptions && props.column.filterOptions.enabled && props.column.filterOptions.custom"
                            slot="column-filter"
                            slot-scope="props">
                    <text-input v-if="props.column.filterOptions.type === 'text'"
                      :inputId="`${props.column.field}_filter`"
                      :name="props.column.field"
                      :hideLabel="true"
                      :value="searchParams[props.column.field]"
                      className="vgt-input"
                      @input="updateFilters($event, props.column.field)" />
                    <select-input v-if="props.column.filterOptions.type === 'dropdown'"
                      :selectId="`${props.column.field}_select`"
                      :name="`${props.column.field}_select`"
                      :hideLabel="true"
                      :undefinedText="props.column.filterOptions.placeholder"
                      className="vgt-select"
                      :options="props.column.filterOptions.filterDropdownItems"
                      @change="updateFilters($event, props.column.field)" />
                  </template>
                  <template slot="emptystate" v-if="isLoading || isParentLoading">
                      <div v-for="index in 8"
                          :key="index"
                          class="loading_row_container"
                        >
                        <div class="loading_row"></div>
                      </div> 
                  </template>
                  <template slot="emptystate" v-else>
                    <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
                    {{ tableConfig.empty || $t('use_form_below_new_row').toString() }}
                  </template>
                  <template v-slot:table-actions>
                     <div class="filterSectionWrapper">
                    <div class="filter-section-action-row">
                      <slot name="actions" />
                    
            
                    </div>
                              <button @click="onCreateRow()" type="button" class="btn btn-primary btn-sm createButton" v-show="tableConfig.createButton">
                        {{ tableConfig.createText || $t('create_row').toString() }}
                      </button>
                     </div>
               
                  
                  </template>
                  <template v-slot:table-header-row="props">
                    <span v-if="props.column.field !== 'days'" class="summary-field">
                      {{props.formattedRow[props.column.field]}}
                    </span>
                    <template v-else>
                      {{props.formattedRow[props.column.field]}}
                    </template>
                  </template>
                  <template v-slot:table-column="props">
                    <span v-if="props.column.renderAsHtml" v-html="props.column.label" />
                    <template v-else>
                      {{props.column.label}}
                    </template>
                  </template>
                  <!-- Customize the table cell -->
                  <template v-slot:table-row="props">

                    <!-- If given tabbable value -->
                    <span v-if="labelMatchesTabbableValue(props.column.label, tabbableValue)">
                      <a href="#" class="table-link" v-on:click.stop.prevent="onRowClick(props)">{{props.formattedRow[props.column.field]}}</a>
                    </span>

                    <!-- Otherwise return normal table cell data -->
                    <slot
                      v-else
                      name="table-cell"
                      v-bind="props"
                    />

                  </template>
                </vue-good-table>
              </div>
            </div>
          </div>
        </transition>
      </fieldset>
      <transition name="fade" mode="out-in">
        <fieldset v-if="isLoading || isParentLoading" v-show="!collapsed">
          <div v-if="divider" class="card-footer action-row temp-saving" />
        </fieldset>
        <fieldset v-else :disabled="disabled" v-show="!collapsed">
          <save-toolbar-top
            :class="saveToolbarClass"
            v-if="showDeleteButton || showArchivedButton"
            :disabled="disabled"
            :archiveLabel="archivedButtonText"
            :showArchivedButton="showArchivedButton"
            :archiveButtonClass="archivedButtonText == 'Archive' ? 'btn btn-warning btn-wide' : 'btn btn-primary btn-wide'"
            :deleteLabel="deleteButtonText"
            :showDeleteButton="showDeleteButton"
            deleteButtonClass="btn btn-danger ml-2"
            :ref="saveToolbarRef()"
            @save="performSave"
            @archive="performArchive"
            @deleteItem="performDelete"
          />
          <hr  v-if="showDeleteButton || showArchivedButton"/>

          <slot name="contents" />  

          <save-toolbar
            :class="saveToolbarClass"
            v-if="saveButton"
            :buttonClass="buttonClass"
            :disabled="disabled"
            :cancelLabel="cancelButtonText"
            :showDisabledSave="showDisabledSave"
            :showCancelButton="showCancelButton"
            :label="saveButtonText"
            cancelButtonClass="btn btn-secondary ml-auto"
            :ref="saveToolbarRef()"
            @save="performSave"
            @cancel="performCancel"
          />


          <div v-else-if="divider" class="card-footer action-row temp-saving" :class="enforce_line ? 'line-important' : null"></div>
        </fieldset>
      </transition>
    </template>
  </div>
</template>

<i18n src="./_locales/common.json"></i18n>
<i18n src="./_locales/single.json"></i18n>

<script lang="ts">
import { TableConfig } from '@/types';
import { VueGoodTable } from 'vue-good-table';
import TextInput from "@/components/shared/TextInput.vue";
import SelectInput from "@/components/shared/SelectInput.vue";
import 'vue-good-table/dist/vue-good-table.css';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import SaveToolbarTop from '@/components/shared/SaveToolbarTop.vue';
import CollapsibleHeading from '@/components/shared/CollapsibleHeading.vue';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { SaveProvider, SaveResult, DismissableNotification } from '@/types';

export interface Options {
  [key: string]: string | undefined;
}

@Component({
  components: {
    VueGoodTable,
    SaveToolbar,
    SaveToolbarTop,
    TextInput,
    SelectInput,
    CollapsibleHeading,
  }
})
export default class SubSection extends Vue implements SaveProvider {
  @Prop({ required: true }) subSectionId!: string;
  @Prop({ required: false }) subtitle!: string;
  @Prop({ default: null }) tableConfig!: TableConfig|null;
  @Prop({ default: '' }) title!: string;
  @Prop({ default: false }) saveButton!: boolean;
  @Prop({ default: 'Save' }) saveButtonText!: string;
  @Prop({ default: false }) subSubSection!: boolean;
  @Prop({ required: false }) confirmation?: string;
  @Prop({ required: false }) totalRecords?: number|null;
  @Prop({ required: false }) tabbableValue?: string|null;
  @Prop({ required: false }) mode?: string;
  @Prop({ default: false }) divider!: boolean; // When true, sub-section divider is rendered even without a save button
  @Prop({ default: false }) highlightSelection!: boolean; // when true, highlight selected rows
  @Prop({ default: 'row' }) tableWrapperClass?: string;
  @Prop({ default: false }) disabled?: boolean;
  @Prop({ required: false }) preconfirmation?: string|null; // optional warning text to show before 'confirmation' warning
  @Prop({ required: false }) rowStyleClass?: string|((row: any) => string)
  @Prop({ default: 'card-footer action-row temp-saving row d-flex' }) saveToolbarClass?: string;
  @Prop({ default: false }) collapsible?: boolean; // button to collapse or reveal subsection (table and contents)
  @Prop({ default: false }) defaultCollapsed?: boolean; // when true, subsection will initially be rendered as already collapsed
  @Prop({ default: false }) isParentLoading?: boolean; // optional flag to override loading state
  @Prop({ default: false}) enforce_line?: boolean;
  @Prop({default: false}) hasCustomClasses?: string;
  @Prop({ default: false }) showArchivedButton!: boolean;
  @Prop({ default: 'Archive' }) archivedButtonText!: string;
  @Prop({ default: false }) showDeleteButton!: boolean;
  @Prop({ default: 'Delete' }) deleteButtonText!: string;
  @Prop({ default: false }) showCancelButton!: boolean;
  @Prop({ default: 'Delete' }) cancelButtonText!: string;
  @Prop({ default: 'btn btn-success sec-submit' }) buttonClass!: string;
  @Prop({ default: false }) hideTitle!: boolean;
  @Prop({ default: false }) showDisabledSave!: boolean;
  @Prop({ default: false }) scrollToID!: string;
  @Prop({ default: '' }) guidingText!: string;

  // Lookup properties
  @Prop({ default: () => { return []; } }) lookupsToLoad!: string[];
  @Prop({ default: () => { return []; } }) laboratoriesToLoad!: string[];
  @Prop({ default: () => { return []; } }) hospitalsToLoad!: string[];

  // Public attributes;
  public isLoading = false;
  public collapsed = false;

  //sorting and search
  public sortParams: Options = {};
  public searchParams: Options = {};

  // pagination
  public currentPage = 1;
  public currentPerPage = 25;

  // Private attributes
  private selectedIds: any[] = [];

  get numberOfItemsToLoad(): number {
    const lookupsCheck = this.lookupsToLoad || [];
    const labsCheck = this.laboratoriesToLoad || [];
    const hospitalCheck = this.hospitalsToLoad || [];
    return lookupsCheck.length + labsCheck.length + hospitalCheck.length;
  }

  public mounted(): void {
    // If sub-section is collapsible, and should default to collapsed, set initial state 
    if (this.collapsible && this.defaultCollapsed) this.collapsed = true;

    // Load any lookups if we have them
    if (this.numberOfItemsToLoad > 0) {
      this.getLookups(this.lookupsToLoad, this.laboratoriesToLoad, this.hospitalsToLoad);
    } else {
      this.$emit('loaded');
    }
  }

  labelMatchesTabbableValue(label: string, tabbable: string): boolean {
    if (!tabbable) { return false; }
    if (!label) { return false; }
    return label.replace(/\s/g, '') === tabbable.replace(/\s/g, '');
  }

  getLookups(lookupsToLoad: string[], laboratoriesToLoad: string[], hospitalsToLoad: string[]) {
    const itemsLoaded: string[] = [];
    this.isLoading = true;

    lookupsToLoad.forEach(item => {
      // load them
      this.$store.dispatch('lookups/queueLookup', {lookup: item})
      .then(() => {
        this.isLoading = false;
        this.$emit('loaded');
      })
      .catch(() => {
        this.isLoading = false;
        this.$emit('loaded');
      });
    });

    laboratoriesToLoad.forEach(item => {
      // load them
      this.$store.dispatch('laboratories/load', item)
      .then(() => {
        this.isLoading = false;
        this.$emit('loaded');
      })
      .catch(() => {
        this.isLoading = false;
        this.$emit('loaded');
      });
    });

    if (hospitalsToLoad.length > 0) {
      this.$store.dispatch('hospitals/load')
      .then(() => {
        this.isLoading = false;
        this.$emit('loaded');
      })
      .catch(() => {
        this.isLoading = false;
        this.$emit('loaded');
      });
    }

  }

  get styleClass() {
    if (this.hasCustomClasses && this.hasCustomClasses.length > 0) {
      return this.hasCustomClasses;
    }
    if (this.isParentLoading) {
      return 'vgt-table table table-bordered table-hover bordered table-loading';
    }
    // By default use constant table style
    if (!this.highlightSelection) {
      return 'vgt-table table table-bordered table-hover bordered';
    }
    // For selection highlighting, use detailed table design
    return 'vgt-table table table-bordered table-hover bordered table-with-detail';
  }

  get rowStyle(): any {
    // Permit customization of row style class using props
    if (this.rowStyleClass) return this.rowStyleClass;

    // By default use constant row style class
    if (!this.highlightSelection) {
      return 'tr-link';
    }

    // For selection highlighting, use function driven by _id field (styling purposes only)
    return (row: any): string => {
      const id: any = row._id?.$oid || this.$t('unknown_caps').toString();
      const selected = this.selectedIds.includes(id);
      const result = selected ? 'tr-active' : 'tr-link' ;
      return result;
    };
  }

  // Helper methods
  get paginationOptions(): any {
    if (!this.tableConfig || !this.tableConfig.pagination) {
      // No pagination
      return {};
    } else if (!this.tableConfig.paginationOptions) {
      // Default options if pagination: true
      return {
        enabled: true,
        perPage: 3,
        mode: 'records',
        perPageDropdown: [3, 10],
        setCurrentPage: this.currentPage,
        dropdownAllowAll: true,
        nextLabel: '',
        prevLabel: '',
        rowsPerPageLabel: this.$t('table.results').toString(),
        ofLabel: this.$t('table.of').toString(),
      };
    } else {
      return this.tableConfig.paginationOptions;
    }
  }

  /**
   * @returns {object} sorting properties for vue-good-table based on sub-section properties
   */
  get sortOptions(): any {
    if (!this.tableConfig || !this.tableConfig.sortOptions){
      // No sorting
      return { enabled: false };
    } else {
      // Custom options specified in table config
      return this.tableConfig.sortOptions;
    }
  }

  /**
   * @returns {object} searching properties for vue-good-table based on sub-section properties
   */
  get searchOptions(): any {
    if (!this.tableConfig || !this.tableConfig.searchOptions){
      // No searching
      return { enabled: false };
    } else {
      // Custom options specified in table config
      return this.tableConfig.searchOptions;
    }
  }

   /**
   * @returns {object} paging, filtering and sorting options whithin each event.
   */
  get tableParams() {
    return {
      currentPage: this.currentPage,
      currentPerPage: this.currentPerPage,
      searchParams: this.searchParams,
      sortParams: this.sortParams
    };
  }

  // Event handlers
  public onRowClick(event: any) {
    // Update internal sense of which rows are selected (only used for styling purposes)
    const row = event.row || {};
    const id = row._id ? row._id.$oid : this.$t('unknown_caps').toString();
    Vue.set(this, 'selectedIds', [id]);
    // Emit event to parent, which will handle functional logic separate from styling
    this.$emit('table-row-click', event);

     const location = document.getElementById(this.scrollToID);

     if (location) {
        const elementPosition = location.getBoundingClientRect().top;
        const offsetPosition = elementPosition - 105;


    window.scrollBy({
         top: offsetPosition,
         behavior: "smooth"
    }); 

     }
   

  }

  private searchDebounce: any;

  /**
   * Triggers the filtering search query per column
   * @param {any} event, the column to filter
   * @param {string} field, the text to query
  */
  public updateFilters(event: any, field: string){
    this.currentPage = 1;
    this.searchParams[field] = event;

    if (this.searchDebounce) clearTimeout(this.searchDebounce);

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _vm = this;
    this.searchDebounce = setTimeout(() => {
      _vm.$emit('on-column-filter', this.tableParams);
    }, 1000);

    this.$forceUpdate();
  }

  /**
   * Triggers the change of page
   * @param {any} event the event for paging
  */
  public onPageChange(event: any) {
    this.currentPage = event.currentPage;
    this.currentPerPage = event.currentPerPage;
    this.$emit('on-page-change', this.tableParams);
  }

  /**
   * Triggers the per page change
   * @param {any} event the event for per page change
  */
  public onPerPageChange(event: any) {
    this.currentPage = event.currentPage;
    this.currentPerPage = event.currentPerPage;
    this.$emit('on-per-page-change', this.tableParams);
  }

  /**
   * Triggers the sort change
   * @param {any} event the event for sorting
  */
  public onSortChange(event: any) {
    if (event.length === 0) {
      return;
    }

    let column = this.tableConfig?.columns.find((c) => {
      return c.field === event[0].field;
    });

    if (column.sortable){
      this.currentPage = 1;
      this.sortParams = { sort_by: event[0].field, sort_type: event[0].type };
      this.$emit('on-sort-change', this.tableParams);
    }
  }

  public onCreateRow(event: any) {
    this.selectedIds = [];
    this.$emit('table-create-row');
  }

  // Generate a unique reference for the save toolbar using the card section ID
  private saveToolbarRef(): string {
    return `save-${this.subSectionId}`;
  }

  // Generate a unique reference for the table component
  get tableRef(): string {
    return `${this.subSectionId}-table`;
  }

  // Use dynamic reference to refer to the save toolbar associated with this specific card section
  private saveToolbar(): SaveToolbar {
    return this.$refs[this.saveToolbarRef()] as SaveToolbar;
  }

  public performArchive(): void {
    this.$emit('archive');
  }

  // Handle saving triggered by local save button
  public performSave(): void {
    // Show pre-confirmation prompt if necessary
    if (!!this.preconfirmation) {
      const preconfirmed = confirm(this.preconfirmation);
      if (!preconfirmed) {
        // Cancel save if not pre-confirmed
        return;
      }
    }
    // Show confirmation prompt if necessary
    if (!!this.confirmation) {
      const confirmed = confirm(this.confirmation);
      if (!confirmed) {
        // Cancel save if not confirmed
        return;
      }
    }
    // Show saving notification
    this.saveToolbar().startSaving();
    // Report save event to parent
    this.$emit('save');
  }

  public performCancel(): void {
    this.$emit('cancel');
  }

  public performDelete(): void {
    this.$emit('delete');
  }

  // Handle result of save
  public registerSaveResult(result: SaveResult): void {
    // Show appropriate saving notification if the saveToolbar is still visible
    if (this.saveToolbar()) this.saveToolbar().stopSaving(result);
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Reset save toolbar if is visible
    if (this.saveToolbar()) {
      this.saveToolbar().reset();
    }
  }
}
</script>
