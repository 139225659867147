<template>
  <base-widget :title="$t('active_allocations')" :preferences="preferences.allocations" v-if="editState">
    <template v-slot:icon>
      <div class="circle dashboard-icon circle-active-allocations">
        <font-awesome-icon :icon="['fac', 'heart']" />
      </div>
    </template>
    <template v-slot:linkID>
      <a href="#idAllocations" class="nav-link card-header-btn py-0" data-toggle="collapse">
        <font-awesome-icon :icon="['far', 'cog']" data-toggle="tooltip" :title="$t('customize_panel')" />
      </a>
      <a href="#bodyAllocations" class="nav-link card-header-btn py-0" data-toggle="collapse">
        <font-awesome-icon :icon="['far', 'caret-down']" data-toggle="tooltip" :title="$t('collapse_panel')" />
      </a>
    </template>
    <template v-slot:options>
      <div class="card-body card-options p-0 collapse" id="idAllocations">
        <div class="customize-panel">
          <h4>{{$t('panel_options')}}</h4>
          <!-- Widget options-->
          <slot name="options">
            <form>
              <div class="form-row mb-3">
                <div class="col-sm-6">
                  <select-input
                    name="panel-style"
                    select-id='dashAllocationStyle'
                    v-model="editState.style"
                    :label="$t('panel_style')"
                    :options="getPanelStyles"
                    />
                </div>
              </div>
              <div class="sub-divider"></div>
              <div class="form-row mb-3">
                <div class="col-sm-6">
                  <checkbox-input
                    input-id='dashAllocationVisible'
                    v-model="editState.visible"
                    :label="$t('show_this_panel')"
                    :disabled="requiredWidget"
                  />
                </div>
              </div>
              <div class="sub-divider"></div>
              <div class="col-sm-12" v-if="errorMessage">
                <p>{{errorMessage}}</p>
              </div>
              <button
                type="button"
                class="btn btn-sm btn-wide btn-success"
                data-target="#idAllocations"
                @click="saveSettings()"
              >
                {{$t('save')}}
              </button>
            </form>
          </slot>
        </div>
      </div>
    </template>
    <template v-slot:widget-contents>
      <div class="card-body collapse show" id="bodyAllocations">
        <vue-good-table
          id="allocationsTable"
          style-class="vgt-table table table-bordered table-hover bordered"
          row-style-class="tr-link"
          :columns="tableConfig.columns"
          :rows="tableConfig.data"
          :pagination-options="tableConfig.paginationOptions"
          :sort-options="tableConfig.sortOptions"
          v-if="getAllocations"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'deceased_donor_id'">
              <router-link
                class="table-link"
                :to="{ name: 'edit-deceased-donor', params: { id: props.row.donor_client_id } }"
                :title="$t('link_to_donor_page')"
                :alt="props.row.deceased_donor_id"
                target="_blank"
              >
                {{ props.row.deceased_donor_id ? props.formattedRow[props.column.field] : this.$t('unknown').toString() }}
              </router-link>
            </span>
            <span v-else-if="props.column.field == 'organ'">
              <a
                :href="getDonorOrganUrl({ id: props.row.donor_client_id, organ_code: props.row.organ_code, allocation_type: props.row.allocation_type })"
                :title="$t('organ')"
              >
                {{props.row.organ}}
              </a>
            </span>
            <span v-else-if="props.column.field == 'state'">
              {{ $t(`allocation_widget_state.${props.row.state}`) }}
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <template slot="emptystate">
            {{$t('no_active_allocations')}}
          </template>
        </vue-good-table>
      </div>
    </template>
  </base-widget>
</template>

<i18n src="./_locales/common.json"></i18n>
<i18n src="@/components/dashboard/widgets/_locales/WidgetActiveAllocations.json"></i18n>

<script lang="ts">
import store from '@/store';
import { Getter, State } from 'vuex-class';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import BaseWidget from '@/components/dashboard/widgets/_BaseWidget.vue';
import { DashboardState, DashboardSettingState, WidgetSettings } from '@/store/dashboard/types';
import { TableConfig } from '@/types';
import { VueGoodTable } from 'vue-good-table';
import TextInput from '@/components/shared/TextInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import { router } from '@/router';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { AllocationOfferTypeValues } from '@/store/allocations/types';

@Component({
  components: {
    BaseWidget,
    VueGoodTable,
    TextInput,
    CheckboxInput,
    SelectInput
  }
})
export default class WidgetActiveAllocations extends Vue {
  @Prop({ default: false }) requiredWidget!: boolean;

  @State(state => state.users.user.preferences) private preferences!: any;
  @State(state => state.pageState.currentPage.dashAllocations) private editState!: any;

  @Getter('getPreferences', { namespace: 'users'}) getPreferences!: any;
  @Getter('getAllocations', { namespace: 'dashboard' }) getAllocations!: any[];
  @Getter('getPanelStyles', { namespace: 'dashboard' }) getPanelStyles!: any[];

  public errorMessage = null;

  getDonorOrganUrl(params: any) {
    if (params.allocation_type) {
      const location = router.resolve({ name: 'deceased-donor-organ-option', params: { id: params.id, organ_code: params.organ_code, option: params.allocation_type } });
      return location.href;
    } else {
      const location = router.resolve({ name: 'deceased-donor-organ', params: { id: params.id, organ_code: params.organ_code } });
      return location.href;
    }
  }

  mounted(): void {
    Promise.all([
      this.$store.dispatch('dashboard/loadAllocations')
    ]).finally(() => {
      this.initializeWidget();
    });
  }

  // Setup temporary edit state for unsaved widget settings form fields
  initializeWidget(): void {
    this.errorMessage = null; // clear error message
    const preferences = this.getPreferences;
    this.$store.commit('pageState/set', {
      pageKey: 'dashAllocations',
      value: this.buildAllocationsEditState(preferences),
    });
  }

  // Commit edit state field model values to vue-x store
  saveSettings(): void {
    const newState = this.extractDashboardSettings(this.preferences, this.editState);
    this.$store.dispatch('users/savePreferences', { preferences: newState }).then((success: SaveResult) => {
      // If successful dismiss dialog
      this.dismiss();
      this.initializeWidget();
      // Reinitialize if overall dashboard settings change
      this.$emit('reloadDashboard');
    }).catch((error: any) => {
      // Show error notification
      this.errorMessage = error.message;
    });
  }

  public dismiss(): void {
    $("#idAllocations").collapse('toggle');
  }

  // Build edit state based on overall dashboard settings
  buildAllocationsEditState(preferences: DashboardSettingState): WidgetSettings {
    return Object.assign({}, preferences.allocations);
  }

  // Build new overall dashboard settings with new settings for this specific widget
  extractDashboardSettings(currentSettings: DashboardSettingState, editState: WidgetSettings): DashboardSettingState {
    const newSettings = Object.assign({ allocations: {} }, currentSettings);
    newSettings.allocations = {
      style: editState.style,
      visible: editState.visible
    };
    return newSettings;
  }

  /**
   * Gets configuration for the table
   *
   * @returns {TableConfig} Configuration for the table
   */
  get tableConfig(): TableConfig {
    return {
      data: this.getAllocations,
      columns: [
        { label: this.$t('donor_id').toString(), field: 'deceased_donor_id', sortable: true },
        { label: this.$t('organ').toString(), field: 'organ' },
        { label: this.$t('allocation_status').toString(), field: 'state'}
      ],
      sortOptions: {
        enabled: true,
        initialSortBy: {field: 'deceased_donor_id', type: 'asc'}
      },
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPage: 5,
        mode: 'records',
        perPageDropdown: [5, 10, 25, 100],
        dropdownAllowAll: false,
        nextLabel: '',
        prevLabel: '',
        rowsPerPageLabel: this.$t('results_per_page').toString(),
      }
    };
  }
}
</script>
