import { render, staticRenderFns } from "./_ConsultationSection.vue?vue&type=template&id=e55e3d58"
import script from "./_ConsultationSection.vue?vue&type=script&lang=ts"
export * from "./_ConsultationSection.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/components/organs/shared/_locales/_ConsultationSection.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Forgans%2Fshared%2F_ConsultationSection.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports