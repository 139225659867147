<template>
  <modal-section
    :modal-id="`${id}-hla-antibody-modal`"
    :ref="modalSectionRef"
    class="modal-sticky-header"
    @switch="onHlaAntibodiesTestModalSwitched"
  >
    <template v-slot:title>
      {{hlaAntibodiesModalTitle}}
    </template>
    <template v-slot:body>
      <template v-if="!editState || !editState[modalStateComponentKey].form">
        {{$t('loading')}}
      </template>
      <template v-else>
        <fieldset>
          <div class="row">
            <div class="standard-form-group">
              <text-input
                :inputId="`${id}-modal-sample_code`"
                :name="$t('sample_code')"
                v-model="editState[modalStateComponentKey].form.sample_code"
                :readonly="true" />
            </div>
            <div class="standard-form-group">
              <text-input
                :inputId="`${id}-modal-sample_draw_date`"
                :name="$t('sample_draw_date')"
                v-model="editState[modalStateComponentKey].form.sample_draw_date"
                :readonly="true" />
            </div>
            <div class="standard-form-group">
              <select-input
                :selectId="`${id}-modal-hla_lab`"
                :name="$t('laboratory')"
                v-model="editState[modalStateComponentKey].form.hla_lab"
                :options="hlaLaboratoryLookup"
                :disabled="true"
                text-key="name"
                value-key="lab_code" />
            </div>
            <div class="standard-form-group">
              <select-input
                :selectId="`${id}-modal-testing_method_default`"
                :name="$t('testing_method_cap')"
                v-model="editState[modalStateComponentKey].form.testing_method_default"
                :options="hlaTestingMethodLookup"
                :disabled="true" />
            </div>
            <div class="standard-form-group-large">
              <text-area-input
                :inputId="`${id}-modal-comments`"
                :name="$t('comments')"
                v-model="editState[modalStateComponentKey].form.comments"
                :readonly="true" />
            </div>
          </div>
          <!-- Fields for PRA and cPRA, depending on which Testing Methods are selected for each class -->
          <div class="row">
            <div class="standard-form-group">
              <text-input
                :inputId="`${id}-modal-sample_tested_date`"
                :name="$t('sample_tested_date')"
                v-model="editState[modalStateComponentKey].form.sample_tested_date"
                :readonly="true" />
            </div>
            <div class="standard-form-group">
              <template v-if="showSabClass1">
                <text-input
                  :inputId="`${id}-modal-cpra_class1`"
                  :name="`${$t('cpras', { class: $t('class_i'), cpra: $t('cpra') } )}`"
                  v-model="editState[modalStateComponentKey].form.cpra_class1"
                  :calculated="true"
                  :readonly="true" />
              </template>
              <template v-else-if="showPraClass1">
                <text-input
                  :inputId="`${id}-modal-pra_class1`"
                  :name="`${$t('cpras', { class: $t('class_i'), cpra: $t('cpra') } )}`"
                  v-model="editState[modalStateComponentKey].form.cpra_class1"
                  :readonly="true" />
              </template>
              <template v-else>
                <text-input
                  :inputId="`${id}-modal-pra_class1`"
                  :name="`${$t('pras', { class: $t('class_i'), pra: $t('pra') } )}`"
                  v-model="editState[modalStateComponentKey].form.cpra_class1"
                  :readonly="true" />
              </template>
            </div>
            <div class="standard-form-group">
              <template v-if="showPraClass2">
                <text-input
                  :inputId="`${id}-modal-pra_class2`"
                  :name="`${$t('pras', { class: $t('class_ii'), pra: $t('pra') } )}`"
                  v-model="editState[modalStateComponentKey].form.cpra_class2"
                  :readonly="true" />
              </template>
              <template v-else-if="showSabClass2">
                <text-input
                  :inputId="`${id}-modal-cpra_class2`"
                  :name="`${$t('cpras', { class: $t('class_ii'), cpra: $t('cpra') } )}`"
                  v-model="editState[modalStateComponentKey].form.cpra_class2"
                  :calculated="true"
                  :readonly="true" />
              </template>
              <template v-else>
                <text-input
                  :inputId="`${id}-modal-pra_class2`"
                  :name="`${$t('pras', { class: $t('class_ii'), pra: $t('pra') } )}`"
                  v-model="editState[modalStateComponentKey].form.cpra_class2"
                  :readonly="true" />
              </template>
            </div>
            <div v-if="showCombinedCpra" class="standard-form-group">
              <text-input
                :inputId="`${id}-modal-combined_cpra`"
                :name="$t('combined_cpra')"
                v-model="editState[modalStateComponentKey].form.combined_cpra"
                :calculated="true"
                :readonly="true" />
            </div>
          </div>
          <!-- Fields grouped by HLA Antibody class -->
          <div class="row">
            <div class="col-sm-12">
              <!-- Note: using a div element with the fieldset class instead of an actual fieldset to allow fields to line up -->
              <div class="fieldset">
                <div class="row">
                  <div class="standard-form-group-large">
                    <!-- TODO: Need to connect titles to the relevant fields with aria-label -->
                    <h6 class="legend-title mb-3">
                      {{$t('class_1')}}
                    </h6>

                    <!-- Fields for selecting Testing Methods for class 1-->
                    <div class="form-group">
                      <select-input
                        :selectId="`${id}-modal-testing_method_class1`"
                        :name="`${$t('testing_methods', { class: $t('class_i'), method: $t('testing_method') } )}`"
                        v-model="editState[modalStateComponentKey].form.testing_method_class1"
                        :options="hlaTestingMethodLookup"
                        :disabled="true" />
                    </div>

                    <!-- Fields for selecting Testing Kits for class 1-->
                    <div v-if="showSabClass1" class="form-group">
                      <select-input
                        :selectId="`${id}-modal-testing_kit_class1`"
                        :name="`${$t('testing_kits', { class: $t('class_i'), kit: $t('testing_kit') } )}`"
                        v-model="editState[modalStateComponentKey].form.testing_kit_class1"
                        :options="hlaTestingKitClass1Options"
                        :disabled="true"
                        :hideExpired="false"
                      />
                    </div>

                     <!-- Fields for entering SAB tags for class 1 and category-->
                    <div v-if="showSabClass1" class="form-group">
                      <hla-input
                        :inputId="`${id}-modal-antibodies_class1_acceptable`"
                        :name="$t('acceptable')"
                        v-model="editState[modalStateComponentKey].form.antibodies.class1_acceptable"
                        :alleleSpecific="editState[modalStateComponentKey].form.antibodies.class1_allele_specific"
                        :alphaBetaSpecific="editState[modalStateComponentKey].form.antibodies.class1_alpha_beta_specific"
                        :readonly="true"
                        inputClass="hla-input hla-acceptable"
                        :disabledEmptyText="$t('no_acceptable_antibodies_kit_i')"  />
                    </div>
                    <div v-if="showSabClass1" class="form-group">
                      <hla-input-group
                        :inputId="`${id}-modal-antibodies_class1_unacceptable`"
                        :name="$t('unacceptable')"
                        v-model="editState[modalStateComponentKey].form.antibodies.class1_unacceptable"
                        :readonly="true"
                        inputClass="hla-input hla-unacceptable"
                        :alleleSpecific="editState[modalStateComponentKey].form.antibodies.class1_unacceptable_allele_specific"
                        :alphaBeta="editState[modalStateComponentKey].form.antibodies.class1_unacceptable_alpha_beta"
                        :enable-epitopes="true"
                        :epitopes="editState[modalStateComponentKey].form.antibodies.epitopes_unacceptable"
                      />
                    </div>
                    <div v-if="showSabClass1" class="form-group">
                      <hla-input-group
                        :inputId="`${id}-modal-antibodies_class1_indeterminate`"
                        :name="$t('indeterminate')"
                        v-model="editState[modalStateComponentKey].form.antibodies.class1_indeterminate"
                        :readonly="true"
                        inputClass="hla-input hla-indeterminate"
                        :alleleSpecific="editState[modalStateComponentKey].form.antibodies.class1_indeterminate_allele_specific"
                        :alphaBeta="editState[modalStateComponentKey].form.antibodies.class1_indeterminate_alpha_beta"
                        :enable-epitopes="true"
                        :epitopes="editState[modalStateComponentKey].form.antibodies.epitopes_indeterminate"
                      />
                    </div>
                    <!-- Possible Allele Specific (low-res legacy data only found in historical records) -->
                    <div v-if="showSabClass1 && showClass1PossibleAlleleSpecific" class="form-group">
                      <hla-input
                        :inputId="`${id}-modal-antibodies_class1_possible_allele_specific`"
                        :name="$t('possible_allele_specific_field')"
                        v-model="editState[modalStateComponentKey].form.antibodies.class1_possible_allele_specific"
                        :readonly="true"
                        inputClass="hla-input hla-possible-allele-specific"
                        :showToolTip="true"
                        :showLabel="true"                      
                        :calculatedText="$t('legacy_data_indicator')"
                        :toolTipText="$t('legacy_data_explanation')"
                      />
                    </div>
                  </div>
                  <div class="standard-form-group-large">
                    <!-- TODO: Need to connect titles to the relevant fields with aria-label -->
                    <h6 class="legend-title mb-3">
                      {{$t('class_2')}}
                    </h6>

                    <!-- Fields for selecting Testing Methods for class 2-->
                     <div class="form-group">
                      <select-input
                        :selectId="`${id}-modal-testing_method_class2`"
                        :name="`${$t('testing_methods', { class: $t('class_ii'), method: $t('testing_method') } )}`"
                        v-model="editState[modalStateComponentKey].form.testing_method_class2"
                        :options="hlaTestingMethodLookup"
                        :disabled="true" />
                    </div>

                    <!-- Fields for selecting Testing Kits for class 2-->
                     <div v-if="showSabClass2" class="form-group">
                      <select-input
                        :selectId="`${id}-modal-testing_kit_class2`"
                        :name="`${$t('testing_kits', { class: $t('class_ii'), kit: $t('testing_kit') } )}`"
                        v-model="editState[modalStateComponentKey].form.testing_kit_class2"
                        :options="hlaTestingKitClass2Options"
                        :disabled="true"
                        :hideExpired="false"
                      />
                    </div>

                    <!-- Fields for entering SAB tags for class 2 and category-->
                    <div v-if="showSabClass2" class="form-group">
                      <hla-input
                        :inputId="`${id}-modal-antibodies_class2_acceptable`"
                        :name="$t('acceptable')"
                        v-model="editState[modalStateComponentKey].form.antibodies.class2_acceptable"
                        :alleleSpecific="editState[modalStateComponentKey].form.antibodies.class2_allele_specific"
                        :alphaBetaSpecific="editState[modalStateComponentKey].form.antibodies.class2_alpha_beta_specific"
                        :readonly="true"
                        inputClass="hla-input hla-acceptable"
                        :disabledEmptyText="$t('no_acceptable_antibodies_kit_ii')"  />
                    </div>
                    <div v-if="showSabClass2" class="form-group">
                      <hla-input-group
                        :inputId="`${id}-modal-antibodies_class2_unacceptable`"
                        :name="$t('unacceptable')"
                        v-model="editState[modalStateComponentKey].form.antibodies.class2_unacceptable"
                        :readonly="true"
                        inputClass="hla-input hla-unacceptable"
                        :alleleSpecific="editState[modalStateComponentKey].form.antibodies.class2_unacceptable_allele_specific"
                        :alphaBeta="editState[modalStateComponentKey].form.antibodies.class2_unacceptable_alpha_beta"
                      />
                    </div>
                    <div v-if="showSabClass2" class="form-group">
                      <hla-input-group
                        :inputId="`${id}-modal-antibodies_class2_indeterminate`"
                        :name="$t('indeterminate')"
                        v-model="editState[modalStateComponentKey].form.antibodies.class2_indeterminate"
                        :readonly="true"
                        inputClass="hla-input hla-indeterminate"
                        :alleleSpecific="editState[modalStateComponentKey].form.antibodies.class2_indeterminate_allele_specific"
                        :alphaBeta="editState[modalStateComponentKey].form.antibodies.class2_indeterminate_alpha_beta"
                      />
                    </div>
                    <!-- Possible Allele Specific (low-res legacy data only found in historical records) -->
                    <div v-if="showSabClass2 && showClass2PossibleAlleleSpecific" class="form-group">
                      <hla-input
                        :inputId="`${id}-modal-antibodies_class2_possible_allele_specific`"
                        :name="$t('possible_allele_specific_field')"
                        v-model="editState[modalStateComponentKey].form.antibodies.class2_possible_allele_specific"
                        :readonly="true"
                        inputClass="hla-input hla-possible-allele-specific"
                        :showToolTip="true"
                        :showLabel="true"                      
                        :calculatedText="$t('legacy_data_indicator')"
                        :toolTipText="$t('legacy_data_explanation')"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </template>
    </template>
    <template v-slot:footer>
      <div class="modal-footer-body">
        <a
          type="button"
          class="btn btn-primary"
          href="#${id}-modal"
          @click.prevent="previousHlaAntibodiesTestModal()"
        >
          <font-awesome-icon :icon="['fas', 'caret-left']" fixed-width /> {{$t('previous_test_result')}}
        </a>
        <a
          type="button"
          class="btn btn-primary"
          href="#${id}-modal"
          @click.prevent="nextHlaAntibodiesTestModal()"
        >
          {{$t('next_test_result')}} <font-awesome-icon :icon="['fas', 'caret-right']" fixed-width />
        </a>
      </div>
    </template>
  </modal-section>
</template>

<i18n src="@/components/hla/_locales/common.json"></i18n>
<i18n src="@/components/hla/_locales/HlaAntibodyModal.json"></i18n>

<script lang="ts">
import { TableConfig } from '@/types';
import { Getter, State } from 'vuex-class';
import HlaInput from '@/components/shared/HlaInput.vue';
import { Laboratory } from '@/store/laboratories/types';
import TextInput from '@/components/shared/TextInput.vue';
import { HlaAntibodyTestKit } from '@/store/lookups/types';
import { idComparator } from '@/utils';
import SelectInput from '@/components/shared/SelectInput.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import NumberInput from '@/components/shared/NumberInput.vue';
import ModalSection from '@/components/shared/ModalSection.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import HlaInputGroup from '@/components/shared/HlaInputGroup.vue';
import { GenericCodeValue, NumericCodeValue } from '@/store/types';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { HlaAntibodiesFilterRow } from '@/components/hla/HlaAntibodyFilterTable.vue';
import { Recipient, RecipientProfileDetails, RecipientDiagnosticsHla, AntibodiesCumulative } from '@/store/recipients/types';
import { LabHLAAntibody, HlaAntibodyData, HlaAntibodyTestingMethod, HLAAntibodiesForm, HlaAntibodyDataForm } from '@/store/labs/types';

interface HlaAntibodyModalState {
  selectedId: string|null;
  form: HLAAntibodiesForm|null;
}

// Types of modal switching
enum ModalSwitch {
  Previous,
  Next
}

@Component({
  components: {
    SelectInput,
    TextInput,
    TextAreaInput,
    NumberInput,
    HlaInput,
    ModalSection,
    HlaInputGroup,
  }
})
export default class HlaAntibodyModal extends Vue {
  @Prop({ required: true }) id!: string;
  @Prop({ required: true }) filteredAntibodyLabs!: HlaAntibodiesFilterRow[];

  // State
  @State(state => state.labs.hlaAntibodies) private hlaAntibodies!: LabHLAAntibody[];
  @State(state => state.laboratories.hla) private hlaLaboratoryLookup!: Laboratory[];
  @State(state => state.pageState.currentPage.hlaAntibodyModal) editState!: { [key: string]: HlaAntibodyModalState|null };
  @State(state => state.lookups.laboratory_hla_antibody_test_kits) private hlaAntibodyTestKitLookup!: HlaAntibodyTestKit[];

  // Getters
  @Getter('hla_testing_method', { namespace: 'lookups' }) private hlaTestingMethodLookup: any;
  @Getter('hlaTestingKitClass1Options', { namespace: 'lookups' }) hlaTestingKitClass1Options!: GenericCodeValue[];
  @Getter('hlaTestingKitClass2Options', { namespace: 'lookups' }) hlaTestingKitClass2Options!: GenericCodeValue[];
  @Getter('lookupHlaAntibodyTestingKit', { namespace: 'lookups' }) lookupHlaAntibodyTestingKit!: (code: string|null) => HlaAntibodyTestKit|null;
  @Getter('buildHLAAntibodiesForm', { namespace: 'labs' }) buildHLAAntibodiesForm!: (hlaAntibodiesData: LabHLAAntibody) => HLAAntibodiesForm;

  get hlaAntibodiesModalTitle(): string {
    const form = (this.editState || {})[this.modalStateComponentKey]?.form;
    if (!form) return this.$t('serum_results').toString();

    return `${this.$t('serum_results').toString()} #${form.sample_code} - ${form.sample_draw_date}`;
  }

  get modalSectionRef(): string {
    return `${this.id}HlaAntibodyModal`;
  }

  get modalStateComponentKey(): string {
    return `${this.id}HlaAntibodyModalComponent`;
  }

  // Display Class 1 legacy data?
  get showClass1PossibleAlleleSpecific(): boolean {
    if (!this.formStateInModal) return false;

    const values = this.formStateInModal?.antibodies?.class1_possible_allele_specific || [];
    return values.length > 0;
  }

  // Display Class 2 legacy data?
  get showClass2PossibleAlleleSpecific(): boolean {
    if (!this.formStateInModal) return false;

    const values = this.formStateInModal?.antibodies?.class2_possible_allele_specific || [];
    return values.length > 0;
  }

  // Clear State and open modal
  public initialize(selectedHlaAntibodyLabId: string) {
    this.initializePageState(selectedHlaAntibodyLabId);
    this.toggleModal();
  }

  // Open a static modal
  private toggleModal(): void {
    const targetModal = this.$refs[this.modalSectionRef] as ModalSection;
    targetModal.toggleModal();
  }

  // Build modal page state
  private initializePageState(selectedHlaAntibodyLabId: string): void {
    // Initialize modal page-level state (there can be multiple on one page)
    if (!this.editState) {
      this.$store.commit('pageState/set', {
        pageKey: 'hlaAntibodyModal',
        value: {},
      });
    }

    // Initialize component-level state within overall page state
    this.$store.commit('pageState/set', {
      pageKey: 'hlaAntibodyModal',
      componentKey: this.modalStateComponentKey,
      value: this.buildHlaAntibodyModalState(selectedHlaAntibodyLabId)
    });
  }

  // Map to UI form interface
  private buildHlaAntibodyModalState(selectedHlaAntibodyLabId: string): HlaAntibodyModalState|null {
    if (!this.hlaAntibodies) return null;

    // Retrieve the HLA Antibodies Test object from the Vue-X Store
    const document: LabHLAAntibody|undefined = this.hlaAntibodies.find((each: LabHLAAntibody) => {
      return each._id && each._id.$oid === selectedHlaAntibodyLabId;
    });
    if (!document) return null;

    return {
      selectedId: selectedHlaAntibodyLabId,
      form: this.buildHLAAntibodiesForm(document),
    };
  }

  private previousHlaAntibodiesTestModal(): void {
    const modal = this.$refs[this.modalSectionRef] as ModalSection;
    modal.switchModal(ModalSwitch.Previous);
  }

  private nextHlaAntibodiesTestModal(): void {
    const modal = this.$refs[this.modalSectionRef] as ModalSection;
    modal.switchModal(ModalSwitch.Next);
  }

  private onHlaAntibodiesTestModalSwitched(options?: any): void {
    // Use filtered rows from table component so that modal switch order logically corresponds to row order
    const filterRowsFromTable: HlaAntibodiesFilterRow[] = this.filteredAntibodyLabs || [];
    // Determine index of selected test with respect to the filtered rows
    const indexOfCurrentTest = filterRowsFromTable.findIndex((test: any) => {
      return idComparator(test, { _id: { $oid: this.editState[this.modalStateComponentKey]?.selectedId } });
    });
    if (indexOfCurrentTest > -1) {
      if (options == ModalSwitch.Previous) {
        // Identify previous row with respect to filtering, sorting, etc.
        let previousFilterRow: HlaAntibodiesFilterRow;
        if (indexOfCurrentTest < 1) {
          previousFilterRow = filterRowsFromTable[filterRowsFromTable.length - 1];
        } else {
          previousFilterRow = filterRowsFromTable[indexOfCurrentTest - 1];
        }
        if (previousFilterRow) {
          // Fetch direct reference to source API document regardless of filtering
          const previousTest = this.hlaAntibodies.find((test: any) => {
            return idComparator(test, previousFilterRow);
          });
          // Select previous test
          const id = previousTest?._id?.$oid;
          if (id) {
            this.initialize(id);
          }
        }
      } else if (options == ModalSwitch.Next) {
        // Identify next row with respect to filtering, sorting, etc.
        let nextFilterRow: HlaAntibodiesFilterRow;
        if (indexOfCurrentTest > filterRowsFromTable.length - 2) {
          nextFilterRow = filterRowsFromTable[0];
        } else {
          nextFilterRow = filterRowsFromTable[indexOfCurrentTest + 1];
        }
        if (nextFilterRow) {
          // Fetch direct reference to source API document regardless of filtering
          const nextTest = this.hlaAntibodies.find((test: any) => {
            return idComparator(test, nextFilterRow);
          });
          // Select next test
          const id = nextTest?._id?.$oid;
          if (id) {
            this.initialize(id);
          }
        }
      }
    }
  }

  // Check form state shown in the popup modal form
  get formStateInModal(): HLAAntibodiesForm|null {
    if (!this.editState || !this.editState[this.modalStateComponentKey] || !this.editState[this.modalStateComponentKey]) return null;

    return this.editState[this.modalStateComponentKey]?.form || null;
  }

  // Check which Class I SAB Testing Kit has been selected if any
  get selectedTestKitClass1(): HlaAntibodyTestKit|null {
    if (!this.formStateInModal) return null;

    const testingKit = this.formStateInModal?.testing_kit_class1 || null;
    return this.lookupHlaAntibodyTestingKit(testingKit);
  }

  // Check which Class II SAB Testing Kit has been selected if any
  get selectedTestKitClass2(): HlaAntibodyTestKit|null {
    if (!this.formStateInModal) return null;

    const testingKit = this.formStateInModal?.testing_kit_class2 || null;
    return this.lookupHlaAntibodyTestingKit(testingKit);
  }

  // Show non-Storage fields if overall testing method is not Storage (i.e. it is ID/PRA or SAB)
  get showNonStorage(): boolean {
    if (!this.formStateInModal) return false;

    const testingMethod = this.formStateInModal?.testing_method_default || null;
    return testingMethod != null && testingMethod !== HlaAntibodyTestingMethod.Storage;
  }

  // Show editable PRA Class I field only if Class I Testing Method is set to ID/PRA
  get showPraClass1(): boolean {
    if (!this.formStateInModal) return false;

    const testingMethod = this.formStateInModal?.testing_method_class1 || null;
    return testingMethod != null && testingMethod === HlaAntibodyTestingMethod.Idpra;
  }

  // Show editable PRA Class II field only if Class II Testing Method is set to ID/PRA
  get showPraClass2(): boolean {
    if (!this.formStateInModal) return false;

    const testingMethod = this.formStateInModal?.testing_method_class2 || null;
    return testingMethod != null && testingMethod === HlaAntibodyTestingMethod.Idpra;
  }

  // Show Class I SAB tags and calculated cPRA Class I field only if Class I Testing Method is set to SAB
  get showSabClass1(): boolean {
    if (!this.formStateInModal) return false;

    const testingMethod = this.formStateInModal?.testing_method_class1 || null;
    return testingMethod != null && testingMethod === HlaAntibodyTestingMethod.Sab;
  }

  // Show Class II SAB tags and calculated cPRA Class II field only if Class II Testing Method is set to SAB
  get showSabClass2(): boolean {
    if (!this.formStateInModal) return false;

    const testingMethod = this.formStateInModal?.testing_method_class2 || null;
    return testingMethod != null && testingMethod === HlaAntibodyTestingMethod.Sab;
  }

  /**
   * Show calculated Combined cPRA field only if both Class I Testing Method and Class II Testing Method are
   * PRA or SAB. i.e. If either of the Testing Methods are storage or blank, there is no Combined cPRA.
   *
   * @returns {boolean} true if the form area should be shown, false otherwise
   */
  get showCombinedCpra(): boolean {
    return (this.showSabClass1 || this.showPraClass1) && (this.showSabClass2 || this.showPraClass2);
  }
}
</script>
