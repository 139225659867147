<template>
  <validation-provider 
    ref="provider"
    :rules="formRules" 
    :name="labelName || inputId" 
    :vid="validationId ? validationId : inputId" 
    v-slot="{ errors }">
    <p class="p-label" :class="{ 'sr-only': hideLabel }" v-if="labelName">
      {{labelName}}
      <validation-asterisk :rules=formRules :crossValues="crossValues" :ruleKey="ruleKey"/>
    </p>
    <template>
      <div class="form-check form-check-inline" :class="{ 'is-invalid': errors[0] }" v-for="option in options" :key="option.value">
        <input
          :id="`${inputId}-${option.value}`"
          :name="`${inputId}-${option.value}`"
          type="radio"
          class="form-check-input"
          v-model="value"
          :value="option.value"
          :aria-labelledby="`${inputId}-${option.value}`"
          :disabled="isReadOnly(disabled)"
          v-on="inputEvents()" />
       <label class="form-check-label" :for="`${inputId}-${option.value}`">{{$t(`${(option.label).toLowerCase()}`)}}</label>
      </div>
      <div class="invalid-feedback" :id="`${inputId}-error`" v-if="errors[0]">
        <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
        {{ translateError(errors, labelName) }}
      </div>
    </template>
  </validation-provider>
</template>

<i18n src="./_locales/RadioGroup.json"></i18n>

<script lang="ts">
import '@/vee-validate-rules.ts';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';
import { Rules } from '@/store/validations/types';
import ValidationAsterisk from '@/components/shared/ValidationAsterisk.vue';

@Component({
  components: {
    ValidationAsterisk
  }
})
export default class RadioGroup extends Vue {
  @Getter('getRuleSet', { namespace: 'validations' }) private ruleSet!: Rules;
  @Getter('getRules', { namespace: 'validations' }) private getRules!: (ruleSet: any, ruleKey: string, rules: string) => any;
  @Getter('isReadOnly', { namespace: 'validations' }) private isReadOnly!: (readonly?: any) => boolean;
  @Getter('translateError', { namespace: 'utilities' }) private translateError!: (error?: any, field?: string|null) => string;

  // V-model
  @Prop() value!: string;

  // Standard properties
  @Prop({ required: true }) inputId!: string; // MANDATORY actual HTML element ID, set indirectly using properties like 'inputId' and 'selectId'
  @Prop({ required: true }) options!: any; // array of radio buttons

  // Optional properties
  @Prop({ default: null }) validationId!: string; // OPTIONAL specify a 'vid' property for validation-provider, if it must be different than the element ID
                                                  // used by parent component after attempting to save to decide where server-side validation errors are shown
  @Prop({ default: null }) labelName!: string; // Optional label to prepend the radio buttons
  @Prop({ default: false }) hideLabel!: boolean; // Hide label visually, while still being readable for screen readers
  @Prop({ default: false }) disabled!: boolean; // Sets the input to disabled

  @Prop({ default: null }) rules!: string; // OPTIONAL lets us hard-code the client-side vee-validate rules in the front-end instead of using anything provided by the back-end
  @Prop({ default: null }) ruleKey!: string // OPTIONAL parameter path to load client-side validation e.g. new_validations, edit_validations
                                            // used by input components to set 'rules' properties in their validation providers based on the client-side validations loaded from the back-end
  @Prop({ default: null }) crossValues!: any; // valus needed for cross field validation for the asterix

  // Initialize local twin of model as null to ensure it exists during template render
  public localValue: string|null = null;

  get formRules(): any {
    return this.getRules(this.ruleSet, this.ruleKey, this.rules);
  }

  // Set local twin of model when mounting to DOM
  public mounted(): void {
    if (this.value != null) {
      this.localValue = this.value;
    } else {
      this.localValue = null;
    }
  }

  // Forward events to the parent component
  public inputEvents(): any {
    const _vm = this as RadioGroup;
    return Object.assign({},
      // parent listeners
      this.$listeners,
      {
        // custom listeners
        input(event: any) {
          // Emit updated value for v-model
          _vm.$emit('input', event.target.value);
        }
      }
    );
  }

  // Watch for changes to the prop value and update localValue to match
  @Watch('value')
  private onValueChange(newValue: boolean, oldValue: boolean) {
    if (this.value != null) {
      this.localValue = this.value;
    } else {
      this.localValue = null;
    }
  }
}
</script>
