<template>
<card-section
    section-id="medical_history"
    :isAccordion="prototypeFeatureEnabled('proto_ability_to_expand_collapse')"
  >
    <template v-slot:header>
      {{ title }}
    </template>
    <template v-slot:body>

     
      <sub-section
          sub-section-id="sub_medical_history"
      >
        <template v-slot:contents>
          <comorbidities />
          <medical-procedures />

        </template>
      </sub-section>
      <!-- Confirmation details -->
      <sub-section
        title="Past Surgical History"
        sub-section-id="medical_history_past"
        ref="confirmRecipientConsentConfirmationDetails"
      >
        <template v-slot:contents>
          <save-toolbar
            class="save-toolbar action-row temp-saving"
            buttonClass="btn btn-success sec-submit"
            label="Save Sugical History"
          />
        </template>
      </sub-section>  
    </template>
  </card-section>


</template>




<script lang="ts">
import { Getter, State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import CardSection from "@/components/shared/CardSection.vue";
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import SubSection from '@/components/shared/SubSection.vue';
import Comorbidities from './Comorbidities.vue';
import MedicalProcedures from './MedicalProcedures.vue';


@Component({
  components: {
    CardSection,
    SaveToolbar,
    SubSection,
    Comorbidities,
    MedicalProcedures
  }
})
export default class MedicalHistory extends Vue { 
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;
 
  title = 'Medical History';
  
}
</script>

<style>


</style>
