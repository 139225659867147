<template>
  <card-section
    section-id="hla-vxm"
    :lookups-to-load="lookupsToLoad"
    :isAccordion="prototypeFeatureEnabled('proto_ability_to_expand_collapse')"
    @loaded="loaded()"
  >
    <template v-slot:header>
      {{$t('virtual_crossmatch')}}
    </template>
    <template v-slot:body>
      <!-- Perform Specified Virtual Crossmatch -->
      <hla-perform-specified-virtual-crossmatch
        :recipient-id="recipientId"
      />
      <!-- View Virtual Crossmatch History -->
      <hla-view-virtual-crossmatch-history />
    </template>
  </card-section>
</template>

<i18n src="@/components/hla/_locales/_HlaVirtualCrossmatch.json"></i18n>

<script lang="ts">
import { Getter }  from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import CardSection from '@/components/shared/CardSection.vue';
import HlaViewVirtualCrossmatchHistory from '@/components/hla/HlaViewVirtualCrossmatchHistory.vue';
import HlaPerformSpecifiedVirtualCrossmatch from '@/components/hla/HlaPerformSpecifiedVirtualCrossmatch.vue';

@Component({
  components: {
    CardSection,
    HlaViewVirtualCrossmatchHistory,
    HlaPerformSpecifiedVirtualCrossmatch,
  }
})
export default class HlaVirtualCrossmatch extends Vue {
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;

  // List lookup data to be loaded by the card section component
  private lookupsToLoad = [
    'hla_testing_method',
  ];

  // Load recipient VXM history
  private loaded(): void {
    this.$emit('loaded', 'hlaVirtualCrossmatch');
    this.$store.dispatch('history/loadRecipientVxm', this.recipientId);
  }
}
</script>
