import { render, staticRenderFns } from "./HemodynamicInformation.vue?vue&type=template&id=4029559e"
import script from "./HemodynamicInformation.vue?vue&type=script&lang=ts"
export * from "./HemodynamicInformation.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "../../_locales/RecipientDocuments.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fprototypes%2Forgans%2Flung%2FHemodynamicInformation.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports