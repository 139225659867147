<template>
  <sub-section
    :title="$t('bridging_therapy')"
    sub-section-id="lsd-bridging-therapy"
    :tabbableValue="$t('bridging_therapy_date')"
    :table-config="bridgingTableConfig"
    :lookupsToLoad="lookupsToLoad"
    @table-row-click="selectLiverBridging($event)"
    @table-create-row="createBridgingTherapy()"
    :saveButton="canEdit"
    :disabled="!canSave || newJourney"
    :save-button-text="$t('save_bridging_therapy')"
    :divider="true"
    ref="saveBridgingTherapy"
    @save="saveBridgingTherapy()"
  >
    <template v-slot:contents>
      <fieldset v-if="editState.bridgingTherapy" :disabled="!canEdit">
        <legend>
          <h5 v-if="!selectedLiverBridging" class="legend-title">
            {{$t('new_bridging_therapy')}}
          </h5>
          <h5 v-else class="legend-title">
            {{$t('selected_bridging_therapy')}}
          </h5>
        </legend>
        <div class="row">
          <div class="standard-form-group">
            <date-input
              :name="$t('bridging_therapy_date')"
              input-id="lsd-bridging-date"
              v-model="editState.bridgingTherapy.bridgingTherapyDate"
              rules="required"
            />
          </div>
          <!-- <div class="form-group bridgeColumnExtend col-md-8 col-lg-6 col-xl-6"> -->
            <select-other-input
              selectId="lsd-bridging-type"
              :name="$t('bridging_therapy_type')"
              v-model="editState.bridgingTherapy.bridgingTherapyType"
              :options="bridgingTherapyLookup"
              @change="clearBridgingOther"
              rules="required"
              :otherTitle="$t('bridging_therapy_type_other')"
              colStyling="standard-form-group-with-other"
            >
              <template v-slot:other>
                <text-input
                  input-id="lsd-bridging-type-other"
                  :name="$t('bridging_therapy_type_other')"
                  v-model="editState.bridgingTherapy.bridgingTherapyTypeOther"
                  rules="required"
                />
              </template>
            </select-other-input>
          </div>
      </fieldset>
    </template>
  </sub-section>
</template>

<i18n src="@/components/organs/liver/_locales/BridgingTherapy.json"></i18n>

<script lang="ts">
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { Getter, State }  from 'vuex-class';
import { ObjectId, GenericCodeValue } from '@/store/types';
import { Recipient } from '@/store/recipients/types';
import DateInput from '@/components/shared/DateInput.vue';
import TextInput from '@/components/shared/TextInput.vue';
import SelectOtherInput from '@/components/shared/SelectOtherInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import { RecipientJourney } from '@/store/recipientJourney/types';
import { LiverDetails, ProcedureLiverBridging } from '@/store/organSpecificDetails/types';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { LiverSpecificForm } from '@/components/organs/liver/LiverSpecificDetails.vue';

export interface BridgingTherapyForm {
  bridgingTherapyDate?: string;
  bridgingTherapyType?: string;
  bridgingTherapyTypeOther?: string;
}

interface BridgingRow {
  _id?: ObjectId;
  bridgingTherapyDate?: string;
  bridgingTherapyType?: string;
  bridgingTherapyTypeOther?: string;
}

@Component({
  components: {
    DateInput,
    SubSection,
    TextInput,
    SelectOtherInput
  }
})

export default class BridgingTherapy extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.pageState.currentPage.liverDetails) editState!: LiverSpecificForm;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.organSpecificDetails.liverBridgings) private liverBridgings!: ProcedureLiverBridging[];
  @State(state => state.organSpecificDetails.selectedLiverBridging) private selectedLiverBridging!: ProcedureLiverBridging;
  @State(state => state.lookups.bridging_therapy_type_codes) bridgingTherapyLookup!: GenericCodeValue[];

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('isLastEntry', { namespace: 'utilities' }) private isLastEntry!: (id: ObjectId, entries: any[]) => boolean;

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  // Lookup tables to be loaded by the CardSection component
  public lookupsToLoad = ['bridging_therapy_type_codes'];
  /**
   * Return true if we can edit the selected measurement
   *
   * @returns {boolean} true if we can edit
   */
  get canEdit(): boolean{
    if (this.newJourney || this.journey.completed) {
      return false;
    }
    
    if (!!this.selectedLiverBridging && !!this.liverBridgings) {
      if (this.selectedLiverBridging._id) {
        return this.isLastEntry(this.selectedLiverBridging._id, this.liverBridgings);
      }
    }
    return true;
  }

  private mounted(): void {
    this.initializeBridgingTherapy();
  }

  // Clear bridging therapy type other when bridging therapy type changes
  public clearBridgingOther() {
    Vue.set(this.editState.bridgingTherapy!, "bridgingTherapyTypeOther", null);
  }

  /**
   * Gets text representation of a Bridging Therapy Type
   * 
   * @param bridgingCode string code from lookups representing a Bridging Therapy Type
   * @returns {string|undefined} Bridging Therapy Type as text
   */
  private bridgingTypeValue(bridgingCode?: string): string|undefined {
    const lookupTable = this.bridgingTherapyLookup;
    if (!lookupTable) {
      return undefined;
    }
    const bridgingValue = lookupTable.find((bridging: GenericCodeValue) => {
      return bridging.code == bridgingCode;
    });
    if (!bridgingValue) {
      return undefined;
    }
    return bridgingValue.value;
  }

  /**
   * Gets table data for recipient liver bridging procedures
   *
   * @returns {BridgingRow[]} bridging table data
   */
  get bridgingRows(): BridgingRow[] {
    if (!this.liverBridgings) {
      return [];
    }
    return this.liverBridgings.map((procedure: ProcedureLiverBridging) => {
      return {
        _id: procedure._id,
        bridgingTherapyDate: this.parseDisplayDateUi(procedure.date) || '-',
        bridgingTherapyType: this.bridgingTypeValue(procedure.type) || '-',
        bridgingTherapyTypeOther: procedure.other || '-',
      };
    });
  }

  /**
   * Loads a form edit state based on the procedure, or a new state if there is none
   *
   * @param procedure Liver Bridging procedure fetched from API, or undefined
   */
  public initializeBridgingTherapy(procedure?: ProcedureLiverBridging): void {
    // Clear table selections from subsection component
    this.$store.commit('organSpecificDetails/selectLiverBridging', procedure);
    // Initialize subsection component form edit states
    this.$store.commit('pageState/set', {
      pageKey: 'liverDetails',
      componentKey: 'bridgingTherapy',
      value: this.buildBridgingTherapyForm(procedure),
    });
  }

  /**
   * Returns Liver Bridging form edit state based on recipient liver bridging procedure document
   *
   * @param procedure Liver Bridging procedure document fetched from API
   * @returns {BridgingTherapyForm} editable bridging form state
   */
  public buildBridgingTherapyForm(procedure?: ProcedureLiverBridging): BridgingTherapyForm {
    if (!procedure) {
      return {
        bridgingTherapyDate: this.currentDateUi(),
        bridgingTherapyType: undefined,
        bridgingTherapyTypeOther: undefined,
      };
    }
    return {
      bridgingTherapyDate: this.parseDateUi(procedure.date),
      bridgingTherapyType: procedure.type,
      bridgingTherapyTypeOther: procedure.other
    };
  }

  /**
   * Gets table configuration for the liver bridging subsection table
   *
   * @returns {TableConfig} table configuration
   */
  get bridgingTableConfig(): TableConfig {
    return {
      data: this.bridgingRows,
      columns: [
        { label: this.$t('bridging_therapy_date').toString(), field: 'bridgingTherapyDate', width: '33%' },
        { label: this.$t('bridging_therapy_type').toString(), field: 'bridgingTherapyType', width: '33%' },
        { label: this.$t('bridging_therapy_type_other').toString(), field: 'bridgingTherapyTypeOther', width: '33%' },
      ],
      empty: this.$t('use_form_below').toString(),
      createButton: this.canEdit,
      createText: this.$t('create_therapy').toString(),
      pagination: true,
    };
  }

  // API response keys on the left, id for our UI on the right
  public idLookup: IdLookup = {
    'procedure_liver_bridging.date'  : 'lsd-bridging-date',
    'procedure_liver_bridging.type'  : 'lsd-bridging-type',
    'procedure_liver_bridging.other' : 'lsd-bridging-type-other',
  };

  // PRIVATE

  /**
   * Builds form edit state based on selected document
   *
   * @param event select event
   */
  private selectLiverBridging(event: any): void {
    // Get selected ID from the table row reference in the select event
    const selectedId = event.row._id && event.row._id.$oid ? event.row._id!.$oid : undefined;
    if (!selectedId || !this.liverBridgings) {
      return;
    }
    // Find the selected source document
    const found = this.liverBridgings.find((each: ProcedureLiverBridging) => {
      return each._id && each._id.$oid === selectedId;
    });
    if (!found) {
      return;
    }
    // Build form state based on selected document
    this.initializeBridgingTherapy(found);
  }

  /**
   * Build an empty new form edit state
   */
  private createBridgingTherapy(): void {
    // Build form state
    this.initializeBridgingTherapy();
  }

  /**
   * Saves current form state for liver bridging
   */
  private saveBridgingTherapy(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveBridgingTherapy as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'bridgingTherapy');
    // Generate payload based on current edit state
    const liverBridgingPayload = {
      id: !!this.selectedLiverBridging ? this.selectedLiverBridging._id : undefined,
      recipientId: this.recipient.client_id,
      liver_bridging: this.extractBridgingTherapyPatch(),
    };
    // Dispatch save action and register the response
    this.$store.dispatch('organSpecificDetails/saveLiverBridging', liverBridgingPayload).then((success: SaveResult) => {
      // If successful reload table data, clear form, and show success notification
      this.$store.dispatch('organSpecificDetails/loadLiverBridgings', this.recipientId);
      this.initializeBridgingTherapy();
      saveProvider.registerSaveResult(success);
      // Clear any errors
      this.$emit('clear');
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  /**
   * Returns a patch object containing changes for a Liver Bridging Procedure document
   *
   * For a document that does not yet exist, the object contains all data to be saved
   *
   * @returns {ProcedureLiverBridging} changes to save
   */
  private extractBridgingTherapyPatch(): ProcedureLiverBridging {
    if (!this.editState || !this.editState.bridgingTherapy) {
      return {};
    }
    const bridgingTherapy = this.editState.bridgingTherapy;
    return {
      date: this.sanitizeDateApi(bridgingTherapy.bridgingTherapyDate),
      type: bridgingTherapy.bridgingTherapyType,
      other: bridgingTherapy.bridgingTherapyTypeOther
    };
  }
}
</script>
