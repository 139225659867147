<template>
  <base-side-nav :class="{ closed: sideNavClosed }">
    <template v-slot:toolbar />
    <template v-slot:side-nav-content>
      <side-nav-toolbar @toggle="toggleSideNav">
        <template v-slot:return-link>
          <router-link class="nav-link no-scroll" title="Return to previous page" :aria-label="$t('control_that_returns_to_previous_page')" :to="{ name: 'edit-living-donor', params: { id: clientId } }">
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width />
            <span class="sr-only">
             {{$t('return_to_previous_page.span')}}
            </span>
          </router-link>
        </template>
      </side-nav-toolbar>
      <div class="side-nav-container">
        <template v-if="prototypeFeatureEnabled('demo_living_donor_inactive_liver_registration_example')">
          <selected-living-donor-organ-links @toggle="toggleSideNav" organ="Liver" :organId="1" :organCode="1" :organRoute="{ name: 'living-donor-organ', params: { organ_code: 1 } }" />
        </template>

        <template v-for="organ in selectedLivingDonorConsentedOrganList">      
          <selected-living-donor-organ-links @toggle="toggleSideNav" :organ="organ.display_name" :organId="organ.id" :organCode="organ.organ_code" :organRoute="organ.route" :key="organ.id" />
        </template>
        <hr>
        <selected-living-donor-profile-links @toggle="toggleSideNav">
          <template v-slot:profile-return-link>
            <router-link class="nav-link no-scroll open" :to="{ name: 'edit-living-donor', params: { id: clientId } }">
              <font-awesome-icon :icon="['fas', 'chevron-down']" class="nav-caret" fixed-width />
             {{$t('profile')}}
            </router-link>
          </template>
        </selected-living-donor-profile-links>
        <hr>
        <selected-living-donor-hla-links @toggle="toggleSideNav"/>
      </div>
    </template>      
  </base-side-nav>
</template>

<i18n src="@/components/_locales/common.json"></i18n>
<i18n src="@/components/deceasedDonors/_locales/SideNav.json"></i18n>

<script lang="ts">
import { State, Getter } from 'vuex-class';
import { Component, Vue, Prop } from 'vue-property-decorator';
import BaseSideNav from '@/components/shared/side-nav/BaseSideNav.vue';
import SideNavToolbar from '@/components/shared/side-nav/SideNavToolbar.vue';
import SelectedLivingDonorOrganLinks from "@/components/livingDonors/side-nav/SelectedLivingDonorOrganLinks.vue";
import SelectedLivingDonorProfileLinks from "@/components/livingDonors/side-nav/SelectedLivingDonorProfileLinks.vue";
import SelectedLivingDonorHlaLinks from "@/components/livingDonors/side-nav/SelectedLivingDonorHlaLinks.vue";
import { LivingDonor, LivingDonorAllocationSummary } from '@/store/livingDonors/types';

@Component({
  components: {
    BaseSideNav,
    SideNavToolbar,
    SelectedLivingDonorOrganLinks,
    SelectedLivingDonorProfileLinks,
    SelectedLivingDonorHlaLinks
  }
})
export default class SideNavLivingDonorOrgan extends Vue {
  @Getter('clientId', { namespace: 'livingDonors' }) private clientId!: string|undefined;
  @Getter('selectedLivingDonorConsentedOrganList', { namespace: 'livingDonors'}) private selectedLivingDonorConsentedOrganList!: LivingDonorAllocationSummary[];

  // Afflo Prototype
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;

  public sideNavClosed = false;

  /**
   * Boolean used to show the side menu, if true side-nav is open
   * Changes the 'closed' class on the nav
   *
   * @returns {void} changes the sideNavClosed boolean
   */
  public toggleSideNav(): void {
    this.sideNavClosed = !this.sideNavClosed;
  }
}
</script>
