<template>
  <base-widget title="Actions" :preferences="preferences.actions" v-if="editState">
    <template v-slot:icon>
      <div class="circle dashboard-icon">
        <font-awesome-icon :icon="['fas', 'rocket']" />
      </div>
    </template>
    <template v-slot:linkID>
      <a href="#idActions" class="nav-link card-header-btn py-0" data-toggle="collapse">
        <font-awesome-icon :icon="['far', 'cog']" data-toggle="tooltip" :title="$t('customize_panel')" />
      </a>
      <a href="#bodyActions" class="nav-link card-header-btn py-0" data-toggle="collapse">
        <font-awesome-icon :icon="['far', 'caret-down']" data-toggle="tooltip" :title="$t('collapse_panel')" />
      </a>
    </template>
    <template v-slot:options>
      <div class="card-body card-options p-0 collapse" id="idActions">
        <div class="customize-panel">
          <h4>{{$t('panel_options')}}</h4>
          <!-- Widget options-->
          <slot name="options">
            <form>
              <div class="form-row mb-3">
                <div class="col-sm-6">
                  <select-input name="panel-style" select-id='dashActionStyle' v-model="editState.style"
                    :label="$t('panel_style')" :options="getPanelStyles" />
                </div>
              </div>
              <div class="sub-divider"></div>
              <div class="form-row mb-3">
                <div class="col-sm-12 col-lg-8">
                  <checkbox-input input-id='dashActionVisible' v-model="editState.visible"
                    :label="$t('show_this_panel')" :disabled="requiredWidget" />
                </div>
              </div>
              <div class="sub-divider"></div>

              <div class="sub-divider"></div>
              <div class="col-sm-12" v-if="errorMessage">
                <p>{{errorMessage}}</p>
              </div>
              <button type="button" class="btn btn-sm btn-wide btn-success" data-target="#idActions"
                @click="saveSettings()">
                {{$t('save')}}
              </button>
            </form>
          </slot>
        </div>
      </div>
    </template>
    <template v-slot:widget-contents>
      <div class="card-body collapse show" id="bodyActions">
        <fieldset id="virology-results">
          <div class="row">
            <div class="col-sm-12">
              <div class="table-responsive-md">
                <div class="vgt-wrap">
                  <div class="vgt-inner-wrap">

                    <div class="vgt-fixed-header"></div>
                    <div class="vgt-responsive">
                      <table id="vgt-table" class="vgt-table table table-bordered table-hover bordered">
                        <colgroup>
                          <col id="col-0">
                          <col id="col-1">
                          <col id="col-2">
                          <col id="col-3">
                          <col id="col-4">
                          <col id="col-5">
                        </colgroup>
                        <thead>
                          <tr>
                            <th scope="col" aria-sort="descending" aria-controls="col-0" class="vgt-left-align"
                              style="min-width: 12%; max-width: 12%; width: 12%;">
                              Living Donor ID</th>
                            <th scope="col" aria-sort="descending" aria-controls="col-0" class="vgt-left-align"
                              style="min-width: 12%; max-width: 12%; width: 12%;">
                              Living Donor Last Name</th>
                            <th scope="col" aria-sort="descending" aria-controls="col-0" class="vgt-left-align"
                              style="min-width: 12%; max-width: 12%; width: 12%;">
                              Organ Registration</th>
                            <th scope="col" aria-sort="descending" aria-controls="col-0" class="vgt-left-align"
                              style="min-width: 12%; max-width: 12%; width: 12%;">
                              Subject</th>
                            <th scope="col" aria-sort="descending" aria-controls="col-0" class="vgt-left-align"
                              style="min-width: 9%; max-width: 10%; width: 9%;">
                              Follow-up Date</th>
                            <th scope="col" aria-sort="descending" aria-controls="col-2" class="vgt-left-align"
                              style="min-width: 25%; max-width: 25%; width: 25%;">
                              Description</th>
                            <th scope="col" aria-sort="descending" aria-controls="col-4" class="vgt-left-align"
                              style="min-width: 6%; max-width: 8%; width: 8%;">
                              Assigned to</th>
                            <th scope="col" aria-sort="descending" aria-controls="col-5" class="vgt-left-align"
                              style="min-width: 10%; max-width: 10%; width: 10%;">
                              Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="vgt-left-align"><span><a href="/living-donors/134738/">126600</a></span></td>
                            <td class="vgt-left-align"><span>Wyman</span></td>
                            <td class="vgt-left-align"><span>Kidney</span></td>
                            <td class="vgt-left-align"><span>Donor evaluation Labs</span></td>
                            <td class="vgt-left-align"><span>29-12-2023</span></td>

                            <td class="vgt-left-align"><span>Follow up to request results</span></td>
                            <td class="vgt-left-align"><span>S.Wood</span></td>
                            <td class="vgt-left-align"><span>Pending</span></td>
                          </tr>
                          <tr>
                            <td class="vgt-left-align"><span><a href="/living-donors/134738/">126600</a></span></td>
                            <td class="vgt-left-align"><span>Wyman</span></td>
                            <td class="vgt-left-align"><span>Kidney</span></td>
                            <td class="vgt-left-align"><span>Psychology Consult Follow Up </span></td>
                            <td class="vgt-left-align"><span>03-01-2024</span></td>

                            <td class="vgt-left-align"><span>Reminder Call for Patients follow up Clinic Appointment on 20-12-2023</span></td>
                            <td class="vgt-left-align"><span>S.Wood</span></td>
                            <td class="vgt-left-align"><span>Pending</span></td>
                          </tr>
                          <tr>
                            <td class="vgt-left-align"><span><a href="/living-donors/134738/">126600</a></span></td>
                            <td class="vgt-left-align"><span>Wyman</span></td>
                            <td class="vgt-left-align"><span>Kidney</span></td>
                            <td class="vgt-left-align"><span>Living Donor Clinic appt </span></td>
                            <td class="vgt-left-align"><span>08-01-2024</span></td>

                            <td class="vgt-left-align"><span>Patient given waitlist education materials to review and agree to</span></td>
                            <td class="vgt-left-align"><span>S.Wood</span></td>
                            <td class="vgt-left-align"><span>Pending</span></td>
                          </tr>
                          <tr>
                            <td class="vgt-left-align"><span><a href="/living-donors/134738/">126600</a></span></td>
                            <td class="vgt-left-align"><span>Wyman</span></td>
                            <td class="vgt-left-align"><span>Kidney</span></td>
                            <td class="vgt-left-align"><span>Transplant Surgeon Apt </span></td>
                            <td class="vgt-left-align"><span>22-01-2024</span></td>

                            <td class="vgt-left-align"><span>Transplant Surgery Appt Reminder</span></td>
                            <td class="vgt-left-align"><span>S.Wood</span></td>
                            <td class="vgt-left-align"><span>Pending</span></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="vgt-wrap__footer vgt-clearfix">
                      <div class="footer__row-count vgt-pull-left">
                        <form><label for="vgt-select-rpp-1021699291527" class="footer__row-count__label">Results
                            per
                            page:</label><select id="vgt-select-rpp-1021699291527" autocomplete="off"
                            name="perPageSelect" aria-controls="vgt-table" class="footer__row-count__select">
                            <option value="3"> 3 </option>
                            <option value="10" selected> 10 </option>
                            <option value="-1">All</option>
                          </select></form>
                      </div>
                      <div class="footer__navigation vgt-pull-right">
                        <div data-v-347cbcfa="" class="footer__navigation__page-info">
                          <div data-v-347cbcfa=""> 1 - 1 of 1 </div>
                        </div> <button type="button" aria-controls="vgt-table"
                          class="footer__navigation__page-btn disabled"><span aria-hidden="true"
                            class="chevron left"></span><span>Newer</span></button>
                        <button type="button" aria-controls="vgt-table"
                          class="footer__navigation__page-btn disabled"><span>Older</span><span aria-hidden="true"
                            class="chevron right"></span></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </template>
    <template v-slot:widget-modal-link>
      <a href="#" data-toggle="modal" data-target="#notificationHistoryModal">View Actions History</a>
    </template>
    <!-- Temp generic history modal -->
    <template v-slot:widget-modal-body>
      <div class="modal fade" id="notificationHistoryModal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{$t('action_history')}}</h5>
              <button type="button" class="close" data-dismiss="modal" :aria-label="$t('close')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <!-- <vue-good-table
                id="notificationsTable"
                style-class="vgt-table table table-bordered table-hover bordered"
                :columns="tableConfigHistory.columns"
                :rows="tableConfigHistory.data"
                :sort-options="tableConfigHistory.sortOptions"
                :pagination-options="tableConfigHistory.paginationOptions"
                :row-style-class="rowStyleForReadUnread"
                v-if="tableConfigHistory.data"
              >
                <template slot="emptystate">
                  {{$t('no_notifications')}}
                </template>
              </vue-good-table> -->
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">{{$t('close')}}</button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </base-widget>
</template>

<i18n src="./_locales/common.json"></i18n>
<i18n src="@/components/dashboard/widgets/_locales/WidgetActions.json"></i18n>

<script lang="ts">
  import store from '@/store';
  import {
    Getter,
    State
  } from 'vuex-class';
  import {
    Component,
    Vue,
    Prop,
    Watch
  } from 'vue-property-decorator';
  import BaseWidget from '@/components/dashboard/widgets/_BaseWidget.vue';
  import {
    DashboardState,
    DashboardSettingState,
    WidgetSettings
  } from '@/store/dashboard/types';
  import {
    TableConfig
  } from '@/types';
  import {
    VueGoodTable
  } from 'vue-good-table';
  import TextInput from '@/components/shared/TextInput.vue';
  import CheckboxInput from '@/components/shared/CheckboxInput.vue';
  import SelectInput from '@/components/shared/SelectInput.vue';
  import {
    SaveableSection,
    SaveProvider,
    SaveResult
  } from '@/types';
  import {
    Format
  } from '@/store/utilities/types';

  @Component({
    components: {
      BaseWidget,
      VueGoodTable,
      TextInput,
      CheckboxInput,
      SelectInput
    }
  })
  export default class WidgetActions extends Vue {
    @Prop({
      default: false
    }) requiredWidget!: boolean;

    @State(state => state.users.user.preferences) private preferences!: any;
    @State(state => state.pageState.currentPage.dashActions) private editState!: any;

    @Getter('getPreferences', {
      namespace: 'users'
    }) getPreferences!: any;
    @Getter('getPanelStyles', {
      namespace: 'dashboard'
    }) getPanelStyles!: any[];
    @Getter('getDateFormat', {
      namespace: 'features'
    }) private getDateFormat!: string;

    public errorMessage = null;

    mounted(): void {
      Promise.all([
        this.$store.dispatch('dashboard/loadActions')
      ]).finally(() => {
        this.initializeWidget();
      });
    }

    // Setup temporary edit state for unsaved widget preferences form fields
    public initializeWidget(): void {
      this.errorMessage = null; // clear error message
      const preferences = this.getPreferences;
      this.$store.commit('pageState/set', {
        pageKey: 'dashActions',
        value: this.buildEditState(preferences),
      });
    }

    // Commit edit state field model values to vue-x store
    saveSettings(): void {
      const newState = this.extractDashboardSettings(this.preferences, this.editState);
      this.$store.dispatch('users/savePreferences', {
        preferences: newState
      }).then((success: SaveResult) => {
        // If successful dismiss dialog
        this.dismiss();
        this.initializeWidget();
        // Reinitialize if overall dashboard settings change
        this.$emit('reloadDashboard');
      }).catch((error: any) => {
        // Show error notification
        this.errorMessage = error.message;
      });
    }

    public dismiss(): void {
      $("#idActions").collapse('toggle');
    }

    // Build new overall dashboard settings with new settings for this specific widget
    extractDashboardSettings(currentSettings: DashboardSettingState, editState: WidgetSettings): DashboardSettingState {
      const newSettings = Object.assign({
        actions: {}
      }, currentSettings);
      newSettings.actions = {
        style: editState.style,
        visible: editState.visible
      };
      return newSettings;
    }

    // Build edit state based on overall dashboard preferences
    buildEditState(preferences: any): WidgetSettings {
      return Object.assign({}, preferences.actions);
    }


  }

</script>
