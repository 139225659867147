<template>
  <div class="row">
    <div class="col-sm-12">
      <sub-section sub-section-id="careGiver_section" :table-config="tableConfig" :title='title'
        hasCustomClasses="vgt-table table table-bordered table-hover table-has-actions" tabbableValue="Last Updated"
        @table-row-click="editSelected" @table-create-row="clearForm" :row-style-class="rowStyleClass"
        @on-row-click="editSelected" scrollToID="recipient-care-giver">
        <template v-slot:actions>
          <filter-component :displayFilter="displayFilter" @updateFilter="updateFilter" :setFilters='setFilters' fieldID='CareGivers' :archivedFilter="true"
           />

        </template>
        <template v-slot:table-cell="props">
          <template v-if="props.column.field == 'contactInformation'">
            <span v-html="props.row.contactInformation" />
          </template>
          <template v-else-if="props.column.field == 'responsibility'">
            <span v-html="props.row.responsibility" />
          </template>
        </template>

      </sub-section>
      <modal-section modalId="careGiversArchiveModal" ref="careGiversArchiveModal" class="modal-sticky-header"
        :centered="true" :closeButton="false" size="md" :wide="false">
        <template v-slot:body>
          {{`${modalMode} this item`}}

        </template>
        <template v-slot:footer>
          <div class="modal-footer-body">
            <button type="button" data-dismiss="modal" class="btn btn-secondary">
              {{ $t('Cancel') }}
            </button>
            <a class="btn btn-success" data-dismiss="modal" @click="archiveDocument(modalMode)">
              {{ $t('OK') }}
            </a>
          </div>
        </template>
      </modal-section>
      <sub-section ref="recipientCareGiver"
        sub-section-id="recipient-care-giver" :saveButton="true"   :showDisabledSave="status == 'archived'"
        :save-button-text="editstate ? `${$t('Update Care Giver Information')}` : `${$t('Save Care Giver Information')}` "
        @save="uploadDocument()" showCancelButton="true" cancelButtonText="Cancel" @cancel="clearForm()"
        @archive="confirmPopup()" :showArchivedButton="editstate ? true : false"
        :archivedButtonText="status == 'active'? 'Archive' : 'Restore' ">
        <template v-slot:contents>
          <fieldset>
            <legend>
              <h5 v-if="!editstate" class="legend-title">
                New Care Giver
              </h5>
              <h5 v-else class="legend-title">
                Edit Care Giver
              </h5>
            </legend>
            <div class="row">

              <div class="standard-form-group">
                <text-input rules="required" inputId="careGiver-name" name="Name" v-model="name" :disabled="status == 'archived'"/>
              </div>
              <div class="standard-form-group">
                <text-input rules="required" inputId="careGiver-relationship" name="Relationship"
                  v-model="relationship" :disabled="status == 'archived'"/>
              </div>
              <div class="standard-form-group">
                <checkbox-input name="Primary Care Giver?" labelName="Primary Care Giver?"
                  inputId="careGiver-primary-care" v-model="isPrimary" label='Yes' :disabled="status == 'archived'" />
              </div>
              <div class="standard-form-group">
                <text-input rules="required" inputId="careGiver-location" name="Location" v-model="location" :disabled="status == 'archived'" />
              </div>
            </div>
            <div class="row">
 
              <div class="standard-form-group form-group-checkbox">
                <label>Consented For</label>
                <div class="row">
                  <div class="col-12">
                    <checkbox-input name="consent-appointments" hideLabel="true" labelName="Appointments"
                      inputId="consent-appointments" v-model="consentAppointments" label='Appointments' :disabled="status == 'archived'" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <checkbox-input name="consent-phi" hideLabel="true" labelName="PHI" inputId="consent-phi"
                      v-model="consentPHI" label='PHI' :disabled="status == 'archived'"/>
                  </div>
                </div>
              </div>
              <div class="standard-form-group form-group-checkbox">
                <label>Responsibility</label>
                <div class="row">
                  <div class="col-12">
                    <checkbox-input name="responsibility-finances" hideLabel="true" labelName="Budgeting / Finances"
                      inputId="responsibility-finances" v-model="responsibilityFinances" label='Budgeting / Finances' :disabled="status == 'archived'" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <checkbox-input name="responsibility-reminders" hideLabel="true" labelName="Calendaring / Reminders"
                      inputId="responsibility-reminders" v-model="responsibilityReminders"
                      label='Calendaring / Reminders' :disabled="status == 'archived'" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <checkbox-input name="responsibility-living-donor" hideLabel="true" labelName="Living Donor"
                      inputId="responsibility-living-donor" v-model="responsibilityLivingDonor" label='Living Donor' :disabled="status == 'archived'" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <checkbox-input name="responsibility-medication" hideLabel="true" labelName="Medication"
                      inputId="responsibility-medication" v-model="responsibilityMedication" label='Medication' :disabled="status == 'archived'"/>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <checkbox-input name="responsibility-transportation" hideLabel="true" labelName="Transportation"
                      inputId="responsibility-transportation" v-model="responsibilityTransportation"
                      label='Transportation' :disabled="status == 'archived'"/>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <checkbox-input name="responsibility-decision-making" hideLabel="true"
                      labelName="Understanding / Decision Making" inputId="responsibility-decision-making"
                      v-model="responsibilityDecision" label='Understanding / Decision Making' :disabled="status == 'archived'"/>
                  </div>
                </div>
              </div>
              <div class="standard-form-group-large">
                <text-area-input input-id="careGiver-comments" name="Comments" rows="4" v-model="comments" :disabled="status == 'archived'" />
              </div>
            </div>
          </fieldset>
          <template>
            <contact-section :columns="getColumns('contact')" fieldID="contact-methods" :showCancelButton="true" :parentDisabled="status == 'archived'"
              customEmptyMessage="There are no contact methods added" :tableData="contactMethods"
              title="Contact Methods" buttonClass="btn btn-primary sec-submit" :categoryOptions="categoryOptions"
              :portalOptions="portalOptions" :responsivenessOptions="responsivenessOptions" @saveToGiver="applyMethod"
              label="Contact Method" localStorageRef="RecipientContactMethods" />
          </template>
        </template>
      </sub-section>
    </div>
  </div>
</template>


<i18n src="./_locales/AddressComponent.json"></i18n>

<script lang="ts">
  import {
    Getter,
    State,
  } from 'vuex-class';
  import {
    mixins
  } from "vue-class-component";
  import {
    Component,
    Vue,
    Prop
  } from 'vue-property-decorator';
  import CardSection from "@/components/shared/CardSection.vue";
  import SaveToolbar from '@/components/shared/SaveToolbar.vue';
  import SelectInput from '@/components/shared/SelectInput.vue';
  import CheckboxInput from '@/components/shared/CheckboxInput.vue';
  import {
    GenericCodeValue
  } from '@/store/types';
  import TextInput from '@/components/shared/TextInput.vue';
  import TextAreaInput from '@/components/shared/TextAreaInput.vue';
  import SubSection from '@/components/shared/SubSection.vue';
  import DateInput from '@/components/shared/DateInput.vue';
  import SelectOtherInput from '@/components/shared/SelectOtherInput.vue';
  import FilterComponent from '@/components/prototypes/shared/_FilterComponent.vue';
  import ModalSection from '@/components/shared/ModalSection.vue';

  import {
    SaveProvider,
    TableConfig
  } from '@/types';

  import {
    Recipient
  } from "@/store/recipients/types";
  import {
    UserDetails
  } from '@/store/userAccounts/types';
  import {
    DateUtilsMixin
  } from "@/mixins/date-utils-mixin";
  import NumberInput from '@/components/shared/NumberInput.vue';

  import BooleanRadioInput from '@/components/shared/BooleanRadioInput.vue';
  import contactSection from '@/components/prototypes/_contactSection.vue';
  import RecipientContactInformationColumnsJson from '@/components/prototypes/json/Recipients/RecipientContactInformationColumns.json';
  import RecipientContactInformationRowsJson from '@/components/prototypes/json/Recipients/RecipientContactInformationData.json';

  interface TableRow {
    id: string;
    type ? : string;

    date ? : string;
    fileName ? : string;

    fileType ? : string;
    description ? : string;
    uploadedBy ? : string;
  }

  export interface FollowUpTypeOption extends GenericCodeValue {
    hasChecklist: boolean;
    checklistReference ? : string;
  }

  @Component({
    components: {
      CardSection,
      SaveToolbar,
      SubSection,
      DateInput,
      SelectInput,
      TextInput,
      CheckboxInput,
      TextAreaInput,
      SelectOtherInput,
      BooleanRadioInput,
      contactSection,
      FilterComponent,
      ModalSection
    }
  })
  export default class CareGiversSection extends mixins(DateUtilsMixin) {

    @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
    @State(state => state.userAccounts.selected) private selected!: UserDetails;
    @State(state => state.pageState.currentPage.journeyStatus) editState!: any;

    @Getter('getUser', {
      namespace: 'users'
    }) private getUser!: any;

    @Prop({
      default: null
    }) columns!: any;
    @Prop({
      default: null
    }) tableData!: any;
    @Prop({
      default: null
    }) title!: string;
    @Prop({
      default: null
    }) localStorageRef!: string;

    loaded() {
      // Parse tasks from local storage if found, otherwise default to JSON file contents
      let formState;
      const defaultState = {
        careGivers: this.tableData.rows
      };
      const items: any = localStorage.getItem(this.localStorageRef);
      if (items) {
        try {
          formState = JSON.parse(items);
        } catch (error) {
          console.warn('Warning: Cannot parse tasklist from local storage, setting default tasks.', error);
          formState = defaultState;
        }
      } else {
        formState = defaultState;
      }
      this.$store.commit("pageState/set", {
        pageKey: "journeyStatus",
        value: formState,
      });
      this.careGivers = formState.careGivers || [];
    }

    getTableData(type: any) {
      switch (type) {
        case 'contact':
          return RecipientContactInformationRowsJson;
      }
    }

    public applyMethod(contacts: any): any {
      this.contactMethods = contacts;
    }

    getColumns(type: any) {
      switch (type) {
        case 'contact':
          return RecipientContactInformationColumnsJson;
      }
    }

    private updateFilter(filterArray: any) {
      if (filterArray.length == 0) {
        return false;
      }

      this.setFilters = filterArray;
    }

    private confirmPopup(): any {
      this.modalMode = this.status == 'active' ? 'Archive' : 'Restore';
      this.toggleModal('careGiversArchiveModal');
    }

    // Toggle a modal based on a ref
    private toggleModal(ref: string): void {
      const targetModal = this.$refs[ref] as ModalSection;
      targetModal.toggleModal();
    }


    private mounted(): void {
      Promise.all([
        this.$store.dispatch('hospitals/load'),
      ]).finally(() => {
        this.loaded();
      });
    }

    private id = null;
    private name = '';
    private relationship = '';
    private isPrimary = false;
    private location = '';
    private consentAppointments = '';
    private consentPHI = "";
    private responsibilityFinances = '';
    private responsibilityReminders = '';
    private responsibilityLivingDonor = '';
    private responsibilityMedication = '';
    private responsibilityTransportation = '';
    private responsibilityDecision = '';
    private comments = '';
    private contactMethods = [];
    private careGivers: any = [];
    private editstate = false;
    private isTableUpdating = false;
    private taskInProgress = false;
    public perPage = 10;
    public currentPageTable = 1;
    private isLoading = true;
    private isSubSectionLoading = true;
    private selectedItem = '';
    private emptyContactMethodForm = '';
    private displayFilter = true;
    private setFilters: any = {};
    private status = 'active';
    private modalMode = ""

    private careGiverOptions = [{
      code: 0,
      value: 'Medicare'
    }, {
      code: 1,
      value: 'Commercial'
    }, {
      code: 2,
      value: 'Medicare Advantage'
    }, {
      code: 3,
      value: 'Medicaid'
    }];

    private portalOptions = [{
      code: 0,
      value: 'MyChart (Epic)'
    }, {
      code: 1,
      value: 'Other',
      other_selected: true,
    }]


    public categoryOptions = [{
      code: 0,
      value: 'Mobile Phone',
      type: 'phone'
    }, {
      code: 1,
      value: 'Home Phone',
      type: 'phone'
    }, {
      code: 2,
      value: 'Work Phone',
      type: 'phone'
    }, {
      code: 3,
      value: 'Email',
      type: 'email'
    }];

    private responsivenessOptions = [{
      code: 0,
      value: 'Good'
    }, {
      code: 1,
      value: 'Fair'
    }, {
      code: 2,
      value: 'Poor'
    }]


    public getTypeValue(item: any, data: any) {
      return data[item as any].value;
    }

    getArchivedStatus(row: any) {
      if (this.setFilters && this.setFilters.includeArchived) {
        return row.status === 'archived' ? 'highlight-yellow' : '';
      } else if (row.status === 'archived') {
        return 'd-none';
      }
    }

    getprimaryStyle(row: any) {
      return row.primary === true ? 'tr-primary-row' : '';
    }

    rowStyleClass(row: any) {
      return `${this.getArchivedStatus(row)} ${this.getprimaryStyle(row)} tr-link`;
    }

    private resetArchive(): any {
      setTimeout(() => {
        this.scrollToMethod('recipient-care-giver');
      }, 500);
    }

    private scrollToMethod(id: any): any {


      const location = document.getElementById(id);

      if (location) {
        const elementPosition = location.getBoundingClientRect().top;
        const offsetPosition = elementPosition - 105;

        window.scrollBy({
          top: offsetPosition,
          behavior: "smooth"
        });
      }
    }

    private archiveDocument(): any {
      this.isTableUpdating = true;
      let careGivers = this.careGivers || [];
      let message = "";

      careGivers.map((item: any) => {
        if (item.id === this.id) {
          if (item.status == 'active') {
            item.status = 'archived';
          } else {
            item.status = "active";
          }
        }
      });

      this.careGivers = careGivers;

      const defaultState = {
        careGivers: careGivers
      };

      localStorage.setItem(this.localStorageRef, JSON.stringify(defaultState));

      this.isTableUpdating = false;
      this.editstate = false;
      this.clearForm();
       setTimeout(() => {
        this.scrollToMethod('care-givers-section');
      }, 500);
    }


    get tableRows() {
      const careGivers = this.careGivers || [];
      return careGivers.map((careGiver: any) => {
        return {
          date: this.parseDisplayDateUi(careGiver.date) || '-',
          id: careGiver.id,
          name: careGiver.name,
          primary: careGiver.isPrimary,
          relationship: careGiver.relationship,
          contactInformation: this.getContactInformation(careGiver.contactMethods),
          contactMethods: careGiver.contactMethods,
          responsibility: this.getResponsibility(careGiver),
          location: careGiver.location,
          consentAppointments: careGiver.consentAppointments,
          appointmentsName: careGiver.consentAppointments ? 'Yes' : 'No',        
          consentPHI: careGiver.consentPHI,
          phiName: careGiver.consentPHI ? 'Yes' : 'No',
          responsibilityFinances: careGiver.responsibilityFinances,
          responsibilityReminders: careGiver.responsibilityReminders,
          responsibilityLivingDonor: careGiver.responsibilityLivingDonor,
          responsibilityMedication: careGiver.responsibilityMedication,
          responsibilityTransportation: careGiver.responsibilityTransportation,
          responsibilityDecision: careGiver.responsibilityDecision,
          comments: careGiver.comments,
          status: careGiver.status,
          statusName: careGiver.status.charAt(0).toUpperCase() + careGiver.status.slice(1),
        };
      });
    }

    get tableConfig(): TableConfig {
      return {
        data: this.tableRows,
        columns: this.columns,
        createButton: true,
        createText: `${this.$t('Add New Care Giver')}`,
        sortOptions: {
          enabled: false,
        },
        paginationOptions: {
          enabled: true,
          mode: 'records',
          perPage: this.perPage,
          perPageDropdown: [10, 25, 50],
          setCurrentPage: this.currentPageTable,
          dropdownAllowAll: true,
          nextLabel: '',
          prevLabel: '',
          rowsPerPageLabel: this.$t('table.results'),
        }
      };
    }

    private editSelected(row: any) {
      if (row) {
        this.editstate = true;
        this.id = row.row.id;
        this.name = row.row.name;
        this.relationship = row.row.relationship;
        this.isPrimary = row.row.isPrimary;
        this.status = row.row.status;
        this.location = row.row.location;
        this.consentAppointments = row.row.consentAppointments;
        this.consentPHI = row.row.consentPHI;
        this.responsibilityFinances = row.row.responsibilityFinances;
        this.responsibilityReminders = row.row.responsibilityReminders;
        this.responsibilityLivingDonor = row.row.responsibilityLivingDonor;
        this.responsibilityMedication = row.row.responsibilityMedication;
        this.responsibilityTransportation = row.row.responsibilityTransportation;
        this.responsibilityDecision = row.row.responsibilityDecision;
        this.comments = row.row.comments;
        this.contactMethods = row.row.contactMethods;
        this.$emit('clear');
      }

    }

    public getCategoryValue(item: any, data: any) {
      return data[item as any].value;
    }

    public getContactInformation(contact: any) {

      let methods = [];

      contact.map((method: any) => {
        methods.push(
          `${method.detailsInput} - ${this.getCategoryValue(method.category, this.categoryOptions)}`);
      });


      if (methods.length == 0) {
        methods.push('-');
      }

      const codeHTML = '<ul class="no-table-list-style">' + methods.reduce((html, item) => {
        return html + "<li>" + item + "</li>";
      }, "") + '</ul>';

      return codeHTML;

    }

    public getResponsibility(careGiver: any) {

      let result = [];

      for (const [key, value] of Object.entries(careGiver)) {
        if (key == "responsibilityFinances" || key == "responsibilityReminders" || key ==
          "responsibilityLivingDonor" || key == "responsibilityMedication" || key ==
          "responsibilityTransportation" || key == "responsibilityDecision") {
          if (key == "responsibilityFinances" && value == true) {
            result.push('Budgeting / Finances');
          } else if (key == "responsibilityReminders" && value == true) {
            result.push('Calendaring / Reminders');
          } else if (key == "responsibilityLivingDonor" && value == true) {
            result.push('Living Donor');
          } else if (key == "responsibilityMedication" && value == true) {
            result.push('Medication');
          } else if (key == "responsibilityTransportation" && value == true) {
            result.push('Transportation');
          } else if (key == "responsibilityDecision" && value == true) {
            result.push('Understanding / Decision Making');
          }
        }
      }

      if (result.length == 0) {
        result.push('-');
      }


      const codeHTML = `<ul class="${result[0] == '-'? "no-table-list-style" : 'table-list-style'}">` + result
        .reduce((html, item) => {
          return html + "<li>" + item + "</li>";
        }, "") + '</ul>';

      return codeHTML;
    }

    get emptyMessage(): string {
      if (!this.careGivers) {
        return this.$t('loading').toString();
      } else {
        return this.$t('use_form_below_follow_up').toString();
      }
    }


    public resetSaveToolbar(): void {
      // Refer to the save toolbar that handles this page
      const saveToolbar = this.$refs.recipientCareGiver as unknown as SaveProvider;

      //const saveToolbar = (this.$refs.recipientCareGiver as SubSection).$refs[
      ///'save-recipient-careGiver'] as SaveToolbar;
      saveToolbar.resetSaveToolbar();
    }


    get getUserFullName(): string | undefined {
      let user = this.getUser;
      return user.name;
    }

    public getOptionValue(item: any, options: any, other: any) {
      if (other) {
        return other;
      } else {


        return options[item as any].value;
      }
    }

    public clearForm(): any {
      this.name = '';
      this.relationship = '';
      this.location = '';
      this.isPrimary = false;
      this.status = 'active';
      this.consentAppointments = '';
      this.consentPHI = '';
      this.responsibilityFinances = '';
      this.responsibilityReminders = '';
      this.responsibilityLivingDonor = '';
      this.responsibilityMedication = '';
      this.responsibilityTransportation = '';
      this.responsibilityDecision = '';
      this.contactMethods = [];
      this.comments = '';
      this.$emit('clear');
      this.resetSaveToolbar();
      this.editstate = false;
    }


    public uploadDocument(): any {
      this.isTableUpdating = true;
      let careGivers = this.careGivers || [];
      if (!this.editstate) {
        const payload: any = {
          id: Math.random().toString(16).slice(2),
          date: new Date().toLocaleDateString('en-CA'),
          name: this.name,
          relationship: this.relationship,
          isPrimary: this.isPrimary,
          status: this.status,
          location: this.location,
          consentAppointments: this.consentAppointments,
          consentPHI: this.consentPHI,
          responsibilityFinances: this.responsibilityFinances,
          responsibilityReminders: this.responsibilityReminders,
          responsibilityLivingDonor: this.responsibilityLivingDonor,
          responsibilityMedication: this.responsibilityMedication,
          responsibilityTransportation: this.responsibilityTransportation,
          responsibilityDecision: this.responsibilityDecision,
          contactMethods: this.contactMethods,
          comments: this.comments
        };
        careGivers.push(payload);

      } else {
        careGivers.map((item: any) => {
          if (item.id === this.id) {
            item.date = new Date().toLocaleDateString('en-CA'),
              item.name = this.name;
            item.relationship = this.relationship;
            item.isPrimary = this.isPrimary,
              item.status = this.status,
              item.location = this.location;
            item.consentAppointments = this.consentAppointments;
            item.consentPHI = this.consentPHI;
            item.responsibilityFinances = this.responsibilityFinances;
            item.responsibilityReminders = this.responsibilityReminders;
            item.responsibilityLivingDonor = this.responsibilityLivingDonor;
            item.responsibilityMedication = this.responsibilityMedication;
            item.responsibilityTransportation = this.responsibilityTransportation;
            item.responsibilityDecision = this.responsibilityDecision;
            item.contactMethods = this.contactMethods;
            item.comments = this.comments;
          }
        });


      }

      this.careGivers = careGivers;

      const defaultState = {
        careGivers: careGivers
      };

      localStorage.setItem(this.localStorageRef, JSON.stringify(defaultState));

      this.isTableUpdating = false;
      this.editstate = false;
      this.clearForm();
    }
  }

</script>

<style>


</style>
