<template>
  <sub-section
    sub-section-id="new-policy-exemption"
    :tabbableValue="$t('request_date_time')"
    :table-config="decisionTableConfig"
    @table-row-click="selectDecision($event)"
    @table-create-row="createDecision()"
    :saveButton="canSave"
    :disabled="!canSave || newJourney"
    :save-button-text="$t('save_policy_request')"
    ref="saveExemptionsSection"
    :divider="true"
    @save="saveDecision()"
  >
    <template v-slot:contents>
      <fieldset v-if="editState" :disabled="!canSave">
        <legend>
          <h5 v-if="!editState._id" class="legend-title">
            {{$t('new_policy_exemption')}}
          </h5>
          <h5 v-else class="legend-title">
            {{$t('selected_policy_exemption')}}
          </h5>
        </legend>
        <div class="row">
          <div class="standard-form-group-6column-xlarge-only">
            <select-input
              select-id="exemption_type"
              ruleKey="type"
              :name="$t('request_type_form')"
              :options="decisionTypes"
              v-model="editState.type"
              :readonly="selectedDecisionId"
              :disabled="selectedDecisionId"
            />
          </div>
          <div class="standard-form-group">
            <date-input
              input-id="exemption_request_date"
              ruleKey="request_date"
              :name="$t('request_date')"
              v-model="editState.request_date"
            />
          </div>
          <div class="standard-form-group">
            <time-input
              input-id="exemption_request_time"
              :name="$t('request_time')"
              rules="required"
              v-model="editState.request_time"
            />
          </div>
        </div>
        <div class="row">
          <div class="standard-form-group-6column-xlarge-only">
            <select-other-input
              selectId="exemption_requesting_hospital_id"
              ruleKey="requesting_hospital_id"
              :name="$t('requesting_hospital_id')"
              v-model="editState.requesting_hospital_id"
              :options="requestingTransplantPrograms"
              @change="selectedRequestingHospitalCode($event)"
              :crossValues="{ requesting_hospital_other_id: editState.requesting_hospital_id }"
            >
            <template v-slot:other>
              <text-input
                input-id="exemption_requesting_hospital_other"
                :name="$t('requesting_hospital_other')"
                ruleKey="requesting_hospital_other"
                v-model="editState.requesting_hospital_other"
                :crossValues="{ requesting_hospital_other_id: editState.requesting_hospital_id }"
              />
            </template>
            </select-other-input>
          </div>
          <div class="standard-form-group-6column-xlarge-only">
            <text-input
              input-id="exemption_requesting_physician"
              ruleKey="requesting_physician"
              :name="$t('requesting_doctor')"
              v-model="editState.requesting_physician"
            />
          </div>
        </div>
        <div class="row">
          <div class="standard-form-group-6column-xlarge-only">
            <text-area-input
              input-id="exemption_comments"
              :name="$t('comments')"
              ruleKey="comments"
              v-model="editState.comments"
            />
          </div>
        </div>
      </fieldset>
    </template>
  </sub-section>
</template>

<i18n src="./_locales/common.json"></i18n>
<i18n src="@/components/organs/shared/_locales/PolicyExemptions.json"></i18n>

<script lang="ts">
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { IdLookup } from '@/store/validations/types';
import { TableConfig } from '@/types';
import { Getter, State }  from 'vuex-class';
import { RootState, ObjectId, GenericCodeValue } from '@/store/types';
import { Recipient } from '@/store/recipients/types';
import TextInput from '@/components/shared/TextInput.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import TimeInput from '@/components/shared/TimeInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { RecipientJourney } from '@/store/recipientJourney/types';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { DecisionsState, ListDecisions, Decision, ListAttachments, Attachment, ListResponses, Response } from '@/store/decisions/types';
import { CoordinatorOptions } from '@/store/coordinators/types';
import SelectInput from '@/components/shared/SelectInput.vue';
import SelectOtherInput from '@/components/shared/SelectOtherInput.vue';
import {ACTIVE_REGION_TRANSPLANT_PROGRAM} from '@/store/hospitals/types';
import { HOSPITAL_CODE_OTHER } from "@/store/hospitals/types";

export interface DecisionResultForm {
  _id?: { $oid: string };
  category?: string;
  type?: string;  
  request_date?: string;
  request_time?: string;
  requesting_hospital_id?: string;
  requesting_hospital_other?: string;
  requesting_physician?: string;
  comments?: string;
}

interface DecisionRow {
  _id?: { $oid: string };
  category?: string;
  type?: string;  
  displayType?: string;
  request_date?: string;
  sub_decisions?: string;
  effective_final_decision_type?: string;
  attachments?: any;
  final_decision?: any;
}

@Component({
  components: {
    TextInput,
    TextAreaInput,
    DateInput,
    TimeInput,
    SubSection,
    SelectInput,
    SelectOtherInput
  }
})
export default class PolicyExemptions extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.decisions) editState!: DecisionResultForm;
  @State(state => state.decisions.listDecisions) private decisions!: Decision[];

  // Getters
  @Getter('selectedDecisionId', { namespace: 'decisions' }) selectedDecisionId!: string;
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;
  @Getter('isLastEntry', { namespace: 'utilities' }) private isLastEntry!: (id: ObjectId, entries: any[]) => boolean;
  @Getter('decisionTypes', { namespace: 'lookups' }) private decisionTypes!: any;
  @Getter('regionTransplantOptionsByOrgan', { namespace: 'hospitals' }) hospitalOptions!: (organCode?: number | string) => GenericCodeValue[];
  @Getter('coordinatorOptions', { namespace: 'coordinators' }) coordinatorOptions!: CoordinatorOptions[]; 
  @Getter('decisionFinalDecisionTypes', { namespace: 'lookups' }) private decisionFinalDecisionTypes!: any;

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  /**
   * Get a string representation the organ_code
   * 
   * @returns {string} organ_code as a string
   */
  get organCode(): string {
    if (this.newJourney) {
      return this.$route.params.organ_code.toString();
    }
    return this.journey.organ_code ? this.journey.organ_code.toString() : '';
  }

  private translateFinalDecisionType(code: string | undefined): string {
    if (!code) return '-';
    const type = this.decisionFinalDecisionTypes.find((item: any) => {
      return item.code == code;
    });
    return type ? type.value : '-';
  }

  get requestingTransplantPrograms(): any {
    const options = this.hospitalOptions(this.organCode);
    return [
      ...options,
      ...[{ code: HOSPITAL_CODE_OTHER, value: HOSPITAL_CODE_OTHER, other_selected: true }]
    ];
  }

  public selectedRequestingHospitalCode(hospitalCode: string) {
    if (hospitalCode !== HOSPITAL_CODE_OTHER) {
      Vue.set(this.editState, 'requesting_hospital_other', null);
    }
 }

  // load validation rules
  public mounted() {
    Promise.all([
      this.$store.dispatch('hospitals/load', ACTIVE_REGION_TRANSPLANT_PROGRAM),
      this.$store.dispatch('decisions/indexDecisions', { recipientId: this.recipientId, journeyId: this.journeyId }),
      this.$store.dispatch('validations/loadNew', { view: `recipients/${this.recipientId}/journeys/${this.journeyId}/decisions`, action: 'new' }),
    ]).finally(() => {
      this.initializeDecisionForm();
    }).catch(() => {
      this.initializeDecisionForm();
    });
  }

  /**
   * Reload list of Policy Exemptions.
   *
   */
  public loadDecisions(): void {
    this.$store.dispatch('decisions/indexDecisions', { recipientId: this.recipientId, journeyId: this.journeyId }).then(() => {
      this.initializeDecisionForm();
    }).catch(() => {
      this.initializeDecisionForm();
    });
  }

  // Gets table data for policy exemptions.
  get decisionRows(): Decision[] {
    if (!this.decisions || this.decisions.length < 0) {
      return [];
    }
    const result: Decision[] = [];
    this.decisions.forEach((record: Decision) => {
      const attachments = record.attachments ? record.attachments : [];
      const number_of_attachments = attachments.filter((item: Attachment) => {
        return !item.deleted;
      });
      const sub_decisions = record.responses ? record.responses : [];
      const final_decision = record.effective_final_decision_type ? this.translateFinalDecisionType(record.effective_final_decision_type) : '-';
      const row: DecisionRow = {
        _id: record._id,
        type: record.type,
        displayType: this.getExemptionType(record.type),
        request_date: this.parseDisplayDateTimeUiFromDateTime(record.request_date),
        attachments: number_of_attachments.length,
        sub_decisions: sub_decisions.length,
        final_decision: final_decision,
      };

      result.push(row);
    });
    return result;
  }

  private getExemptionType(type: string|undefined): string {
    const types = this.decisionTypes;
    if (!type) return '-';
    const result = types.find((record: any) => {
      return record.code == type;
    });
    return result ? result.value : '-';
  }

  // Loads a form edit state based on the lab, or a new state if there is none
  private initializeDecisionForm(record?: Decision): void {
    // Initialize subsection component form edit states
    this.$store.commit('pageState/set', {
      pageKey: 'decisions',
      value: this.buildDecisionForm(record)
    });
    this.$emit('clear');
  }

  /**
   * Returns policy exemption form edit state based on policy exemption document
   *
   * @param record policy exemption document fetched from API
   * @returns {HccResultForm} editable form state
   */
  public buildDecisionForm(record?: Decision): any {
    if (!record) {
      return {};
    }

    let hospitalId = null;
    if (record.requesting_hospital_other) {
      hospitalId = HOSPITAL_CODE_OTHER;
    } else {
      hospitalId = record.requesting_hospital_id && record.requesting_hospital_id.$oid ? record.requesting_hospital_id.$oid : null;
    }

    return {
      _id: record._id,
      category: record.category,
      type: record.type,
      request_date: this.parseDateUiFromDateTime(record.request_date),
      request_time: this.parseTimeUiFromDateTime(record.request_date),
      requesting_hospital_id: hospitalId,
      requesting_hospital_other: record.requesting_hospital_other,
      requesting_physician: record.requesting_physician,
      comments: record.comments,
    };
  }

  get decisionTableConfig(): TableConfig {
    return {
      data: this.decisionRows,
      columns: [
        { label: this.$t('request_type').toString(), field: 'displayType'},
        { label: this.$t('request_date_time').toString(), field: 'request_date'},
        { label: this.$t('attachments').toString(), field: 'attachments'},
        { label: this.$t('responses').toString(), field: 'sub_decisions'},
        { label: this.$t('final_decision').toString(), field: 'final_decision'},
      ],
      empty: this.$t('use_form_below_decision').toString(),
      createButton: this.canSave,
      createText: this.$t('create_policy_exemption').toString(),
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPage: 3,
        mode: 'records',
        perPageDropdown: [3, 10],
        dropdownAllowAll: true,
        nextLabel: '',
        prevLabel: '',
        rowsPerPageLabel: this.$t('results_per_page').toString(),
      }
    };
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    return {   
      'type'                       : 'exemption_type',
      'request_date'               : ['exemption_request_date', 'exemption_request_time'],
      'requesting_hospital_id'     : 'exemption_requesting_hospital_id',
      'requesting_hospital_other'  : 'exemption_requesting_hospital_other',
      'requesting_physician'       : 'exemption_requesting_physician',
      'comments'                   : 'exemption_comments',
    };
  }

  //  Builds form edit state based on selected document
  private selectDecision(event: any): void {
    // Get selected ID from the table row reference in the select event
    const selectedId = event.row._id && event.row._id.$oid ? event.row._id!.$oid : undefined;

    if (!selectedId || !this.decisions) {
      return;
    }
    // Find the selected source document
    const found = this.decisions.find((each: Decision) => {
      return each._id && each._id.$oid === selectedId;
    });
    if (!found) {
      this.$emit('selected', null);
      return;
    }

    this.$emit('selected', found);

    this.$store.dispatch('validations/loadEdit', { view: `recipients/${this.recipientId}/journeys/${this.journeyId}/decisions`, action: 'edit', clientId: found._id?.$oid });

    // Build form state based on selected document
    this.initializeDecisionForm(found);
  }

  // Build an empty new form edit state
  private createDecision(): void {
    // Build form state
    this.$emit('selected', null);
    this.initializeDecisionForm();
  }

  // Saves current form state for policy exemption
  private saveDecision(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveExemptionsSection as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'decision');
    // Generate payload based on current edit state
    const payload: any = {
      recipientId: this.recipient.client_id,
      journeyId: this.journey._id?.$oid,
      decision: this.extractPatch(),
    };
    // check for decisionId
    if (this.editState._id && this.editState._id?.$oid) { payload.decisionId = this.editState._id?.$oid; }

    // Dispatch save action and register the response
    this.$store.dispatch('decisions/saveDecision', payload).then((success: SaveResult) => {
      // Clear any errors
      this.$emit('clear');
      // Show success notification
      saveProvider.registerSaveResult(success);
      this.createDecision();
      // Reload index
      this.loadDecisions();
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  // Returns a patch object containing changes for policy exemption document
  private extractPatch(): any {
    if (!this.editState) {
      return {};
    }
    const form = this.editState;

    const patch: any = {
      category: 'policy-exemption',
      type: form.type || null,
      request_date: this.sanitizeDateTimeApi(form.request_date, form.request_time),
      requesting_physician: form.requesting_physician || null,
      comments: form.comments || null,
    };

    if (form.requesting_hospital_id == HOSPITAL_CODE_OTHER) {
      patch.requesting_hospital_other = form.requesting_hospital_other || null;
      patch.requesting_hospital_other_id = HOSPITAL_CODE_OTHER;
    } else {
      // if not other
      patch.requesting_hospital_id = form.requesting_hospital_id;
      patch.requesting_hospital_other = null;
      patch.requesting_hospital_other_id = null;
    }

    return patch;
  }
}
</script>
