<template>
  <div class="nav-block-wrap">
    <ul class="nav flex-column nav-block"
       :class="{ active: isActive }">
      <li class="nav-item">
          <router-link class="nav-link no-scroll"
                     :to="{ name: 'living-donor-organ', params: { organ_code: organCode } }">
          <font-awesome-icon class="nav-caret"
                             :icon="['fas', 'chevron-right']"
                             fixed-width />
          {{ $t(`${organ}`) }}
        </router-link>
        <template v-if="show">
          <ul class="nav flex-column">
            <template v-if="!prototypeFeatureEnabled('demo_living_donor_hide_allocations')">
              <li class="nav-item" v-if="!isTransplantCoordinator" @click="toggleSideNav">
                <a class="nav-link" :href="getUrl('donor-checklist')">{{ $t(`checklists`) }}</a>
              </li>
              <li class="nav-item" @click="toggleSideNav">
                <a class="nav-link text-flat" :href="getUrl(isTransplantCoordinator ? 'allocation-offer-responses' : 'allocation-recommendation-listing')">{{ $t(`allocation_eoffer`) }}</a>
              </li>
            </template>

            <template v-if="organ == 'Kidney'">

            <li class="nav-item" @toggle="toggleSideNav" v-if="prototypeFeatureEnabled('demo_phase_state')">
              <a class="nav-link" :href="getUrl('living_donor_phase_status')">Registration Information</a>
            </li>
            <li class="nav-item" @toggle="toggleSideNav" v-if="prototypeFeatureEnabled('demo_living_donor_organ_registration_link_to_recipient')">
              <a class="nav-link" :href="getUrl('link-to-recipient')">Link to Recipient</a>
            </li>
            <li class="nav-item" @toggle="toggleSideNav" v-if="prototypeFeatureEnabled('demo_tasklists')">
              <a class="nav-link" :href="getUrl('checklists')">Checklists</a>
            </li>
            <li class="nav-item" @toggle="toggleSideNav" v-if="prototypeFeatureEnabled('demo_actions')">
              <a class="nav-link" :href="getUrl('proto_actions')">Actions</a>
            </li>
            <li class="nav-item" @toggle="toggleSideNav" v-if="prototypeFeatureEnabled('demo_notes')">
              <a class="nav-link" :href="getUrl('proto_notes')">Notes</a>
            </li>
            <li class="nav-item" @toggle="toggleSideNav" v-if="prototypeFeatureEnabled('demo_kidney_specific_living_donors')">
              <a class="nav-link" :href="getUrl('living_donor_kidney_specific_details')">Kidney Specific Assessment</a>
            </li>
            <li class="nav-item" @toggle="toggleSideNav" v-if="prototypeFeatureEnabled('demo_living_donor_organ_registration_recovery_details')">
              <a class="nav-link" :href="getUrl('recovery-information')">Donation Surgery</a>
            </li>
            <li class="nav-item" @toggle="toggleSideNav" v-if="prototypeFeatureEnabled('demo_post_tranplant_living_donor')">
              <a class="nav-link" :href="getUrl('living_donor_post_transplant')">Post-Donation Follow-Up</a>
            </li>
            </template>

            
          </ul>
        </template>
      </li>
    </ul>
  </div>
</template>

<i18n src="@/components/_locales/Organs.json"></i18n>
<i18n src="@/components/livingAllocations/side-nav/_locales/SelectedAllocationLinks.json"></i18n>

<script lang="ts">
import { Getter, State } from 'vuex-class';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class SelectedLivingDonorOrganLinks extends Vue {
  @Getter('isTransplantCoordinator', { namespace: 'users' }) private isTransplantCoordinator!: boolean;
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;


  @Prop() organId!: string;
  @Prop() organ!: string;
  @Prop() organCode!: string;
  @Prop() organRoute!: any;

  show = false;

  public selectedJourney = '';

  // Hide organ specific deatils from side nav
  public ORGAN_CODES_TO_EXCLUDE = ['7'];

  public toggleNav() {
    this.show = !this.show;
  }

  public mounted() {
    this.show = this.isActive;
  }

  @Watch('$route', { immediate: true, deep: true })
  public onUrlChange() {
    this.show = this.isActive;
  }

  get isActive(): boolean {
    return this.organCode == this.$route.params.organ_code
      && this.organRoute.params.option == this.$route.params.option;
  }

  get showOrganSpecificDetails(): boolean {
   return true;
  }

  public toggleSideNav(): void {
    if (window.innerWidth < 992) {
      this.$emit("toggle");
    }
  }

  public getUrl(hash: string): string {
    hash = `#${hash}`;
    if(this.isActive) {
      return hash;
    } else {
      // We are fine not checking for the existence of client_id as for new recipients we only ever show this in active mode
      const location = this.$router.resolve({ name: 'living-donor-organ', params: { organ_code: this.organCode }, hash: hash });
      return location.href;
    }
  }
}
</script>

<style scoped>
.page-nav .side-nav-container .nav-block-wrap .nav-link {
  text-transform: none;
}
</style>
