<template>
  <nav id="side-nav" class="page-nav">
    <ul aria-label="Toolbar" class="menu-header-nav hidden-sm">
      <li class="nav-item skeleton-padding-icon">
        <span class="skeleton-box" style="width: 23px; height: 23px;" />
      </li>
      <li class="nav-item skeleton-padding-icon">
        <span class="skeleton-box" style="width: 23px; height: 23px;" />
      </li>
      <li class="nav-item skeleton-padding-icon">
        <span class="skeleton-box" style="width: 23px; height: 23px;" />
      </li>
    </ul>
    <div class="side-nav-container">
      <div class="skeleton-padding">
        <span class="skeleton-box w-50" />
        <span class="skeleton-spacer" />
        <span class="skeleton-box w-25" />
        <span class="skeleton-spacer" />
        <span class="skeleton-box w-75" />
      </div>
      <hr />
      <div class="skeleton-padding">
        <span class="skeleton-box w-25" />
        <ul>
          <li class="skeleton-spacer" />
          <li class="skeleton-box w-75" />      
          <li class="skeleton-spacer" />
          <li class="skeleton-box w-75" />
          <li class="skeleton-spacer" />
          <li class="skeleton-box w-100" />      
          <li class="skeleton-spacer" />
          <li class="skeleton-box w-75" />      
          <li class="skeleton-spacer" />
          <li class="skeleton-box w-75" />      
          <li class="skeleton-spacer" />
          <li class="skeleton-box w-100" /> 
          <li class="skeleton-spacer" />
          <li class="skeleton-box w-75" />     
        </ul>
      </div>
      <hr />
      <div class="skeleton-padding">
        <span class="skeleton-box w-25" />
        <ul>
          <li class="skeleton-spacer" />
          <li class="skeleton-box w-75" />      
          <li class="skeleton-spacer" />
          <li class="skeleton-box w-75" />
          <li class="skeleton-spacer" />
          <li class="skeleton-box w-100" />      
        </ul>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class LoadingSideNav extends Vue {}
</script>
