<template>
  <card-section
    section-id="donor-allocation-eoffer"
    :lookupsToLoad="lookupsToLoad"
    @loaded="loaded()"
  >
    <template v-slot:header>
      <span class='text-flat'>{{ $t('allocation_e_offer') }}</span>
    </template>
    <template v-slot:body>
      <allocation-controls ref="allocationControls"
        @reloadAllocations="reloadAllocations()"
      />
      <template v-if="!isLoadingAllocations">
        <allocation-details ref="allocationDetails" v-if="allocation" />
        <donor-checklist
          v-if="allocation && canReadChecklists"
          :canSave="canWriteChecklists"
        />
        <allocation-recommendation-listing
          ref="allocationRecommendationListings"
          v-if="allocation"
        />
      </template>
      <br/>
      <allocation-offer-response ref="allocationOffers" v-if="allocation && allPrimaryBackupOffers.length > 0" />
    </template>
  </card-section>
</template>

<i18n src="@/components/_locales/common.json"></i18n>
<i18n src="@/components/_locales/Organs.json"></i18n>
<i18n src="@/components/allocations/_locales/common.json"></i18n>

<script lang="ts">
import { Getter, State } from 'vuex-class';
import SubSection from '@/components/shared/SubSection.vue';
import { LivingDonor } from '@/store/livingDonors/types';
import CardSection from '@/components/shared/CardSection.vue';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import AllocationDetails from '@/components/livingAllocations/_AllocationDetails.vue';
import DonorChecklist from '@/components/livingAllocations/_DonorChecklist.vue';
import AllocationOfferResponse from '@/components/livingAllocations/_AllocationOfferResponse.vue';
import AllocationControls, { AllocationControlState } from '@/components/livingAllocations/_AllocationControls.vue';
import { LivingAllocation, LivingAllocations, LivingAllocationRecipient } from '@/store/livingAllocations/types';
import AllocationRecommendationListing from '@/components/livingAllocations/_AllocationRecommendationListing.vue';
import { EP } from '@/api-endpoints';

@Component({
  components: {
    SubSection,
    CardSection,
    AllocationDetails,
    AllocationControls,
    AllocationOfferResponse,
    AllocationRecommendationListing,
    DonorChecklist,
  }
})
export default class LivingDonorOffers extends Vue {
  @State(state => state.livingDonors.selectedLivingDonor) private livingDonor!: LivingDonor;
  @State(state => state.livingAllocations.selected) private allocation!: LivingAllocation;
  @State(state => state.livingAllocations.isLoadingAllocations) private isLoadingAllocations!: boolean;
  @State(state => state.pageState.currentPage.livingAllocations) editState!: AllocationControlState;
  @State(state => state.livingAllocations.allAllocations) allAllocations!: any;

  @Getter('clientId', { namespace: 'livingDonors' }) private clientId!: string|undefined;
  @Getter('activeAllocations', { namespace: 'livingAllocations' }) private activeAllocations!: LivingAllocations[];
  @Getter('findAllocations', { namespace: 'livingAllocations' }) private findAllocations!: (activeAllocations: LivingAllocations[], organCode: string) => any;
  @Getter('findAllocationForPage', { namespace: 'livingAllocations' }) private findAllocationForPage!: (activeAllocations: LivingAllocations[], organCode: string) => any;
  @Getter('allPrimaryBackupOffers', { namespace: 'livingAllocations' }) private allPrimaryBackupOffers!: LivingAllocationRecipient[];
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;

  @Prop({ default: false }) canSave!: boolean;

  public lookupsToLoad = ['offer_types', 'offer_responses', 'gender'];
  public isInitializing = false;

  // Emit a loaded event to the parent
  public loaded(): void {
    this.$emit('loaded', 'allocations');
  }

  /**
   * checks for read permission to checklists
   * Note: hla_tech are not allowed to see the checklists section, only view the list of recommendations
   *
   * @returns {boolean} if is allowed to see checklists
   */
  get canReadChecklists(): boolean {
    return this.checkAllowed(EP.living_donors.allocations.checklist.show, "GET");
  }

  /**
   * checks for write permissions to checklists
   *
   * @returns {boolean} if is allowed to see checklists
   */
  get canWriteChecklists(): boolean {
    return this.checkAllowed(EP.living_donors.allocations.checklist.create, "POST");
  }

  /**
   * Return the organ code
   *
   * Get the organ_code param from the url.
   *
   * @returns {string} organ code
   */
  get organCode(): string {
    return (this.$route.params.organ_code || '').toString() || '';
  }

  // Initialize page on mount
  public mounted(): void {
    this.$store.dispatch('validations/loadEdit', { view: 'living_donors', action: 'edit', clientId: this.clientId });
    this.initializeAllocation();
  }

  public reloadAllocations(): void {
    this.initializeAllocation();
  }

  /**
   * We re-initialize the allocation if the isLoadingAllocations changes and we specifically look for when the change
   * makes it false. This makes sure that we only reload after all the allocations are properly loaded instead of
   * looking for specific type of allocation as that means we see flashes.
   */
  @Watch('isLoadingAllocations')
  // TODO: TECH_DEBT: remove watch isLoadingAllocations
  public initializeAllocation() {
    if(this.isInitializing) return; // We don't need to double load
    // Ref to our AllocationControls
    const allocationControls = this.$refs.allocationControls as AllocationControls;
    // Clear our allocation control state when loading
    if (allocationControls) allocationControls.buildAllocationPageState();
    // If we're currently loading an allocation
    if(this.isLoadingAllocations) {
      return;
    }
    this.isInitializing = true;
    this.getAllocation();
  }

  // PRIVATE

  // Get active Allocation based on organ_code
  private getAllocation() {
    // In order to show concluded allocation details, we have to get allAllocations
    this.$store.commit('livingAllocations/clearAllocation');
    // TODO: TECH_DEBT: Currently donor details is called everytime a checklist is called, so instead of that we need to get
    // TODO: rh_indicator,height,weight from allocation service, so that we can avoid calling donordetails
    // TODO: Remove donorDetails once we have above fields from allocation service
    this.$store.commit('livingAllocations/clearDonorDetails');

    const allocations = this.findAllocations(this.allAllocations, this.organCode);
    const allocationForPage = this.findAllocationForPage(allocations, this.organCode);
    // Let's clear the selected allocation as we are about to reset everything
    // if we have an allocation id get the allocation details from api
    if (allocationForPage) {
      const allocationPageId = allocationForPage._id;
      this.$store.dispatch('livingAllocations/getAllocation', { clientId: this.clientId, organCode: this.organCode, allocationId: allocationPageId }).then(() => {
        this.isInitializing = false;
      });
    } else {
      this.isInitializing = false;
    }
  }
}
</script>
