<template>
  <ul class="nav nav-block flex-column" :class="{ active: active }">
    <li class="nav-item">
      <span class="nav-link">
        <font-awesome-icon :icon="['fas', (show ? 'chevron-down' : 'chevron-right')]" class="nav-caret" fixed-width @click.prevent="toggleNav" />
        <router-link class="no-scroll open" style="color: #2e2e2e !important" :to="getUrl('')">
          {{$t('recipient_offers')}}
        </router-link>
      </span>
    </li>
    <ul class="nav flex-column" v-if="show">
      <li class="nav-item" @click="toggleSideNav">
        <a class="nav-link" :href="getUrl('offer-stats')">{{$t('offer_statistics')}}</a>
      </li>
      <li class="nav-item" @click="toggleSideNav">
        <a class="nav-link" :href="getUrl('current-offers')">{{$t('current_offers')}}</a>
      </li>
      <li class="nav-item" @click="toggleSideNav">
        <a class="nav-link" :href="getUrl('offer-history')">{{$t('offer_history')}}</a>
      </li>
      <!-- <li class="nav-item" @click="toggleSideNav">
        <a class="nav-link" :href="getUrl('hla-vxm')">{{$t('virtual_crossmatch')}}</a>
      </li> -->
      <li class="nav-item" @click="toggleSideNav">
        <a class="nav-link" :href="getUrl('serum-crossmatch')">{{$t('serum_crossmatch')}}</a>
      </li>
    </ul>
  </ul>
</template>

<i18n src="@/components/prototypes/offers/_locales/SelectedRecipientOfferLinks.json"></i18n>

<script lang="ts">
import { State, Getter } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Recipient } from "@/store/recipients/types";

@Component
export default class SelectedRecipientOfferLinks extends Vue {
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;

  @Prop({default: false}) active!: boolean;
  @Prop() journeyId!: string;

  public show = true;

  public toggleNav() {
    this.show = !this.show;
  }

  public toggleSideNav(): void {
    if (window.innerWidth < 992) { 
       this.$emit("toggle");
    }
  }
  /**
   * Gets the recipient client_id.
   *
   * Converts the recipient client_id to a string
   *
   * @returns {string} client_id as a string
   */
  get clientId(): string {
    return this.recipient.client_id == null ? '' : this.recipient.client_id.toString();
  }

  get isActive(): boolean {
    const organId = this.$route.params.organ_id;
    if (!!organId) {
      return this.journeyId == organId;
    }
    return false;
  }

  public getUrl(hash: string): string {
    hash = `#${hash}`;
    
    const currentRoute = this.$router.currentRoute;
    if (currentRoute.name == 'recipient-offers') {
      return hash;
    }

    const location = this.$router.resolve({ name: 'recipient-offers', params: { organ_id: this.journeyId }, hash: hash });
    return location.href;
  }
}
</script>
