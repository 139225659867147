<template>
  <div>
    <template>
      <page-top>
        {{ $t("user_profile") }}
      </page-top>
      <div class="content-wrap">
        <div class="container-fluid">
          <div class="nav-wrapper" id="administration-area">
            <side-nav-profile />
            <div class="page-content">
              <validation-observer ref="validations">
                <user-profile
                  ref="userProfile"
                />
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<i18n src="@/components/_locales/common.json"></i18n>
<i18n src="@/components/_locales/ManageAccount.json"></i18n>

<script lang="ts">
import { Getter, State } from 'vuex-class';
import PageTop from '@/components/shared/PageTop.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import { Component, Vue } from 'vue-property-decorator';
import { SaveProvider, SaveResult } from '@/types';
import SelectInput from '@/components/shared/SelectInput.vue';
import UserProfile from '@/components/administration/UserProfile.vue';
import SideNavProfile from '@/components/administration/side-nav/SideNavProfile.vue';
import { UserDetails } from '@/store/userAccounts/types';

@Component({
  components: {
    PageTop,
    SaveToolbar,
    UserProfile,
    SideNavProfile
  }
})
export default class ProfilePage extends Vue {

}
</script>
