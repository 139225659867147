<template>
  <sub-section
    subSectionId="crossmatch-report"
    :lookupsToLoad="lookupsToLoad"
  >
    <template v-slot:contents v-if="editState">
      <div class="card tip-card">
        <div class="card-header">
          <div class="media align-items-center">
            <div class="media-left">
              <font-awesome-icon :icon="['far', 'file-chart-line']" fixed-width />
            </div>
            <div class="media-body flex-row align-items-center">
              <h3 class="tip-report-name">
                CrossMatch Worksheet Report
              </h3>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="content-wrap">
            <div class="container-fluid">
              <validation-observer id="crossmatch-filters" ref="CrossmatchReportFilters" autocomplete="off" tag="form" v-slot="{ handleSubmit }">

              <div class="row">
                <div class="form-group col-md-8 col-lg-4 col-xl-3">
                  <select-input
                    selectId="crossmatch-region"
                    name="Region"
                    v-model="editState.region"
                    :options="filteredLaboratories"
                    :multiple="true"
                  />
                </div>
                <div class="form-group col-md-8 col-lg-4 col-xl-3 infoColumn">
                  <select-input
                    selectId="crossmatch-hospital"
                    name="Hospital"
                    v-model="editState.hospital"
                    rules='required'
                    :options="allowedHospitals"
                    :multiple="true"
                  />
                </div>
                <div class="standard-form-group">
                  <select-input
                    selectId="crossmatch-blood_type"
                    name="Blood Type"
                    v-model="editState.bloodType"
                    :options="allowedBloodTypes"
                    :multiple="true"
                  />
                </div>
                <div class="standard-form-group">
                  <text-input
                    inputId="trayNumber"
                    name="Tray No"
                    v-model="editState.trayNumber"
                  />
                </div>
              </div>

              <div class="form-row">
                <save-toolbar
                  class="form-inline action-row"
                  buttonClass="btn btn-success"
                  ref="generateReport"
                  savingText="Generating"
                  successText="Report generated"
                  defaultErrorText="There was a problem generating the report"
                  @save="handleSubmit(generateReport)"
                >
                  <template v-slot:label>
                    <font-awesome-icon :icon="['far', 'file-chart-line']" class="mr-2" /> Generate CrossMatch Report
                  </template>
                </save-toolbar>
              </div>
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </template>
  </sub-section>
</template>

<script lang="ts">
import { Getter, State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { SaveResult } from '@/types';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { CrossmatchReportState } from '@/store/reports/types';
import TextInput from '@/components/shared/TextInput.vue';
import { BloodType } from '@/store/lookups/types';
import { Hospital } from '@/store/hospitals/types';
import { Region } from '@/store/lookups/types';
import { LaboratoriesState, Laboratory } from '@/store/laboratories/types';

interface CrossmatchOptions {
  code?: string,
  value?: string
}

@Component({
  components: {
    TextInput,
    SaveToolbar,
    SelectInput,
    SubSection
  }
})

export default class CrossmatchReport extends Vue {
  // State
  @State(state => state.reports.CrossmatchReportState) editState!: CrossmatchReportState;
  @State(state => state.lookups.blood_type) bloodTypeLookup!: BloodType[];
  @State(state => state.laboratories.hla) laboratories!: Laboratory[];

  @Getter('organTransplantHospitals', { namespace: 'hospitals' }) hospitalOptions!: any[];

  private bloodTypesIncluded = ["A", "B", "AB", "O"];

  public lookupsToLoad = ['blood_type'];

  get filteredLaboratories(): any[]{
    if(!this.laboratories) {
      return [];
    }

    return this.laboratories.map((lab) => {
      return {
        code: lab.lab_code,
        value: lab.name
      };
    });
  }

  get allowedHospitals(): CrossmatchOptions[] {
    let hospitals: CrossmatchOptions[] = [{code: 'All', value: 'All' }];
    this.hospitalOptions.forEach((hospital: CrossmatchOptions) =>{
      hospitals.push(hospital);
    });
    return hospitals;
  }

  get allowedBloodTypes(): BloodType[]{
    return this.bloodTypeLookup.filter((bloodType) => {
      return this.bloodTypesIncluded.includes(bloodType.code);
    });
  }

  public mounted(): void {
    this.$store.dispatch('hospitals/load').then(() => {
      this.$store.dispatch('laboratories/load', 'hla').then(() => {
        this.$store.commit('reports/setCrossmatchReportState', {});
      });
    });
  }

  public buildCrossmatchPayload(): CrossmatchReportState {
    if (!this.editState) {
      return {};
    }

    const hospitalValues = this.hospitalOptions.map((hospital: CrossmatchOptions) => {
      return hospital.code || '';
    });

    const bloodTypeValues = this.allowedBloodTypes.map((bloodType: BloodType) => {
      return bloodType.code || '';
    });

    const regionValues = this.filteredLaboratories.map((laboratory: CrossmatchOptions) => {
      return laboratory.code || '';
    });

    const result = {
      region: this.editState.region ? this.editState.region : regionValues,
      hospital: !this.editState.hospital?.includes('All') ? this.editState.hospital : hospitalValues,
      blood_type: this.editState.bloodType ? this.editState.bloodType : bloodTypeValues,
      tray_number: this.editState.trayNumber
    };

    return result;
  }

  public generateReport(): void {
    const saveToolbar = this.$refs.generateReport as SaveToolbar;
    saveToolbar.startSaving();
    const payload = this.buildCrossmatchPayload();
    // Submit query
    this.$store.dispatch('reports/printCrossmatchReport', payload).then((result: SaveResult) => {

      // Get the filename
      const fileName = result.responseData.report;

      // Is there actually a filename
      if (!!fileName) {
        // Create a link
        const link = document.createElement('a');
        link.href = fileName;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        // Then click it forcing a save/open dialogue
        link.click();
        // Show success notification
      }
      saveToolbar.stopSaving(result);

    }).catch((result: SaveResult) => {
      saveToolbar.stopSaving(result);
    });
  }
}
</script>
