<template>
  <card-section
    sectionId="clinical-transaction"
    ref="saveClinicalTransaction"
    :saveButtonText="$t('save_clinical_transaction')"
    :saveButton="!newRecipient"
    :disabled="!canSave"
    @save="savePatch"
    @loaded="loaded"
  >
    <template v-slot:header>
      {{$t('clinical_transaction_section')}}
    </template>
    <template v-slot:body v-if="editState">
      <sub-section
        :title="$t('call_information_subsection')"
        subSectionId="clinical-transaction-call-information"
      >
        <template v-slot:contents>
          <div class="row">
            <div class="standard-form-group">
              <date-input
                inputId="clinical-transaction-call-information-date"
                ruleKey="clinical_transactions.call_datetime"
                :name="$t('clinical_transaction_call_date')"
                v-model="editState.callDate"
              />
            </div>
            <div class="standard-form-group">
              <time-input
                inputId="clinical-transaction-call-information-time"
                rules="required"
                :name="$t('clinical_transaction_call_time')"
                v-model="editState.callTime"
              />
            </div>
          </div>
        </template>
      </sub-section>
    </template>
  </card-section>
</template>

<i18n src="@/components/recipients/_locales/ClinicalTransaction.json"></i18n>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Getter } from "vuex-class";
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Recipient, RecipientClinicalTransaction } from "@/store/recipients/types";
import { SaveProvider, SaveResult } from "@/types";
import CardSection from "@/components/shared/CardSection.vue";
import SubSection from "@/components/shared/SubSection.vue";
import DateInput from "@/components/shared/DateInput.vue";
import TimeInput from "@/components/shared/TimeInput.vue";
import SaveToolbar from "@/components/shared/SaveToolbar.vue";

interface ClinicalTransactionForm {
  callDate?: string|null;
  callTime?: string|null;
}

@Component({
  components: {
    CardSection,
    SubSection,
    DateInput,
    TimeInput,
    SaveToolbar,
  }
})
export default class ClinicalTransaction extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.pageState.currentPage.clinicalTransaction) editState!: ClinicalTransactionForm;

  // Getters
  @Getter("show", { namespace: "recipients" }) private recipient!: Recipient;

  // Properties
  @Prop({ default: false }) newRecipient!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  // Initialize the form
  public initializeForm(): void {
    this.$store.commit("pageState/set", {
      pageKey: "clinicalTransaction",
      value: this.buildClinicalTransactionForm()
    });
  }

  // Load the form
  public loaded(): void {
    this.initializeForm();
  }

  // Build form state
  public buildClinicalTransactionForm(): ClinicalTransactionForm {
    const recipient = this.recipient;
    const result: ClinicalTransactionForm = {
      callDate: this.parseDateUiFromDateTime(recipient?.clinical_transaction?.call_datetime),
      callTime: this.parseTimeUiFromDateTime(recipient?.clinical_transaction?.call_datetime),
    };
    return result;
  }

  // Derive request payload
  public extractPatch(): Recipient {
    const form = this.editState;
    const clinicaltransactions: RecipientClinicalTransaction[] = [
      {
        call_datetime: this.sanitizeDateTimeApi(form?.callDate, form?.callTime),
      }
    ];
    const payload: Recipient = {
      clinical_transactions: clinicaltransactions,
    };
    return payload;
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    const saveProvider = (this.$refs.saveClinicalTransaction as unknown) as SaveProvider;
    if (saveProvider) saveProvider.resetSaveToolbar();
  }

  // Handle saving triggered by local save button
  public savePatch(): void {
    const saveProvider = (this.$refs.saveClinicalTransaction as unknown) as SaveProvider;
    const payload = this.extractPatch();
    this.$store.dispatch("recipients/saveRecipientPatch", payload ).then((success: SaveResult) => {
        if (saveProvider) saveProvider.registerSaveResult(success);
        this.$store.commit("recipients/set", success.responseData.recipient);
        this.initializeForm();
      }).catch((error: SaveResult) => {
        this.$emit("handleErrors", error);
        if (saveProvider) saveProvider.registerSaveResult(error);
      });
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): { [key: string]: string|string[] } {
    const result: { [key: string]: string|string[] } = {
      'clinical_transactions.call_datetime' : ['clinical-transaction-call-information-date', 'clinical-transaction-call-information-time'],
    };
    return result;
  }
}
</script>
