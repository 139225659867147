<template>
  <card-section section-id="offer-stats" :isAccordion="prototypeFeatureEnabled('proto_ability_to_expand_collapse')">
    <template v-slot:header>
      Offer Statistics - {{organDescription}}
    </template>
    <template v-slot:body>


      <sub-section sub-section-id="sub_medical_history">
        <template v-slot:contents>
          <div class="row">
            <div class="w-auto mr-5 col-12">
              <fieldset id="record-recipient-death">
                <legend>
                  <h4 class="legend-title">
                    <span class="">Offers</span>
                  </h4>
                </legend>
              </fieldset>
              <div class="summary-list">
                <dl class="pl-0">
                  <dt>Offer Rate</dt>
                  <dd>6 / month</dd>
                </dl>
                <dl>
                  <dt>Totals</dt>
                  <dd>33 offers (11 primary, 22 backup)</dd>
                </dl>
              </div>
            </div>
          </div>

        </template>
      </sub-section>
      <!-- Confirmation details -->

    </template>
  </card-section>


</template>


<script lang="ts">
  import {
    State,
    Getter
  } from 'vuex-class';
  import {
    Component,
    Vue
  } from 'vue-property-decorator';
  import {
    organCodeLookup
  } from '@/types';
  import {
    OrganCodeValue
  } from '@/store/lookups/types';
  import {
    RecipientJourney
  } from '@/store/recipientJourney/types';
  import CardSection from "@/components/shared/CardSection.vue";
  import SubSection from '@/components/shared/SubSection.vue';



  @Component({
    components: {
      CardSection,
      SubSection
    }
  })
  export default class OfferStatistics extends Vue {
    @Getter('prototypeFeatureEnabled', {
      namespace: 'features'
    }) private prototypeFeatureEnabled!: (featureName: string) => boolean;

    @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;

    @Getter("includeStomach", {
      namespace: "recipients"
    }) includeStomach!: (journeyId ? : string) => boolean;
    @Getter('journeyId', {
      namespace: 'journeyState',
    }) journeyId!: string | undefined;

    /**
     * Return titlized organ name
     *
     * @returns {string} organ name titlized
     */
    get organDescription(): string {
      let organName = this.organComponent;
      if (this.includeStomach(this.journeyId) && this.journey.organ_code == OrganCodeValue.SmallBowel) {
        return organName = this.$t(`${organName} + Stomach`).toString();
      } else {
        return this.$t(organName).toString();
      }
    }

    /**
     * Gets the current journey as lower case
     *
     * Using the organ code, return the lower case organ name
     *
     * @returns {string} organ as lower case
     */
    get organComponent(): string {
      return organCodeLookup[`${this.journey ? this.journey.organ_code : ''}`] || '';
    }
  }

</script>
