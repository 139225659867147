<template>
  <div>
    <template v-if="!livingDonor">
      <page-top>
        <span class="skeleton-box" style="width: 200px" />
      </page-top>
      <div class="content-wrap">
        <div class="container-fluid">
          <div class="nav-wrapper">
            <loading-side-nav />
            <div class="page-content">
              <loading-donor-page />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <!-- Page Top -->
      <page-top>
        <router-link :to="{ name: 'list-living-donors' }">
          {{ $t("living_donors")}}
        </router-link>
        /
        <router-link :to="{ name: 'edit-living-donor', params: { id: clientId } }">
          {{livingDonorDisplayName}}
        </router-link>
        / {{livingDonorOrganName}}
      </page-top>
      <living-donor-sticky-summary />
      <!-- Page wrap -->
      <div class="content-wrap">
        <!-- Page Container  -->
        <div class="container-fluid">
          <!-- Nav wrapper -->
          <div class="nav-wrapper">
            <!-- Sidebar Navigation -->
            <side-nav-living-donor-organ />
            <!-- Donor Content -->
            <div class="page-content">
              <living-donor-summary ref="livingDonorSummary" @loaded="(ref) => loaded(ref)" />

              <living-donor-offer
                ref="allocations"
                @reload="reloadSummary()"
                @loaded="(ref) => loaded(ref)"
                v-if="!prototypeFeatureEnabled('demo_living_donor_hide_allocations')"
              />

              <!-- Organ Specific Details -->
              <template
                v-if="livingDonorOrganName == 'Kidney' && prototypeFeatureEnabled('demo_kidney_specific_living_donors')">
                
              <validation-observer ref="validations" tag="form" @submit.prevent>

              <living-donor-phase-status
                organ="Kidney"
                v-if="prototypeFeatureEnabled('demo_phase_state')" ref="journeySection"
                title="Registration Information"
                @clear="clear"
                @handleErrors="handleErrors" 
                localStorageRef="KidneyLivingDonorJourneyStatus"
                :columns="getColumns('status')"
                :tableData="getTableData('status')" />

              <!-- Afflo Prototype -->
              <link-to-recipient
                v-if="prototypeFeatureEnabled('demo_living_donor_organ_registration_link_to_recipient')"
                ref="linkToRecipient"
                @loaded="(ref) => loaded(ref)"
                @save="(details) => handleSectionSave(details)"
                @handleErrors="(errors) => handleErrors(errors)"
                :canSave="true"
                @reload="reload"
              />

              <tasklists
                organ="Kidney"
                v-if="prototypeFeatureEnabled('demo_tasklists')"
                ref="checklists"
                title="Checklists"
                localStorageRef="KidneyLivingDonortTasklists"
                :tableData="getTableData('tasks')"/>

              <actions
                organ="Kidney"
                v-if="prototypeFeatureEnabled('demo_actions')"
                @clear="clear"
                @handleErrors="handleErrors"
                ref="actions"
                title="Actions"
                localStorageRef="kidneyLivingDonorActions"
                :columns="getColumns('actions')"
                :tableData="getTableData('actions')" />

              <notes
                organ="Kidney"
                v-if="prototypeFeatureEnabled('demo_notes')"
                @clear="clear"
                @handleErrors="handleErrors"
                ref="notes"
                title="Notes"
                localStorageRef="kidneyLivingDonorNotes"
                localStorageActionsRef="kidneyLivingDonorActions"
                :columns="getColumns('notes')"
                :tableData="getTableData('notes')"
                :actionData="getTableData('actions')"/>


                <living-donor-kidney-specific-details 
                  title="Kidney Specific Assessment"
                   ref="organSpecificDetailsSection" @loaded="(ref) => loaded(ref)"
                  @saving="saving" @handleErrors="handleErrors" @clear="clear"
                  v-if="prototypeFeatureEnabled('demo_kidney_specific_living_donors')"
                  localStorageRef="LivingDonorKidneySpecificDetails"/>

                <!-- NOTE: re-using existing Recovery Information component as organ-level Donation Surgery -->
                <recovery-information
                  ref="recoveryInformation"
                  v-if="prototypeFeatureEnabled('demo_living_donor_organ_registration_recovery_details')"
                  :canSave="true"
                  @handleErrors="(errors) => handleErrors(errors)"
                  @clear="resetValidationErrors"
                />

                <!-- Post Transplant Follow Up -->
                <post-donation
                 title="Post-Donation Follow-Up"
                 v-if="prototypeFeatureEnabled('demo_post_tranplant_living_donor')"
                 :currentHospital="currentHospital"
                @clear="clear"
                @handleErrors="handleErrors"
                />



              </validation-observer>

              </template>

            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<i18n src="../_locales/common.json"></i18n>
<i18n src="../_locales/listLivingDonors.json"></i18n>

<script lang="ts">
  import {
    State,
    Getter
  } from 'vuex-class';
  import PageTop from '@/components/shared/PageTop.vue';
  import {
    LivingAllocation
  } from '@/store/livingAllocations/types';
  import {
    OrganCodeValue
  } from '@/store/lookups/types';
  import {
    Component,
    Vue,
    Watch,
    Prop,
  } from 'vue-property-decorator';
  import {
    LivingDonor
  } from '@/store/livingDonors/types';
  import LivingDonorSummary from '@/components/livingDonors/LivingDonorSummary.vue';
  import LivingDonorOffers from '@/components/livingAllocations/LivingDonorOffers.vue';
  import LivingDonorStickySummary from '@/components/livingDonors/LivingDonorStickySummary.vue';
  import LoadingSideNav from '@/components/shared/side-nav/LoadingSideNav.vue';
  import LoadingDonorPage from '@/components/shared/LoadingDonorPage.vue';
  import SideNavLivingDonorOrgan from '@/components/livingDonors/side-nav/SideNavLivingDonorOrgan.vue';
  import LivingDonorPhaseStatus from '@/components/prototypes/living_donor/organs/LivingDonorPhaseStatus.vue';
  import Notes from '@/components/prototypes/organs/_Notes.vue';
  import Actions from '@/components/prototypes/organs/_Actions.vue';
  import Tasklists from '@/components/prototypes/organs/_Tasklists.vue';
  import PostDonation from '@/components/prototypes/living_donor/organs/_PostDonation.vue';
  import JourneyStatusRowsJson from '@/components/prototypes/json/LivingDonors/LivingDonorJourneyStatusData.json';
  import JourneyStatusColumnsJson from '@/components/prototypes/json/LivingDonors/LivingDonorJourneyStatusColumns.json';
  import ActionRowsJson from '@/components/prototypes/json/LivingDonors/LivingDonorActionsData.json';
  import ActionColumnsJson from '@/components/prototypes/json/LivingDonors/LivingDonorActionsColumns.json';
  import NotesRowsJson from '@/components/prototypes/json/LivingDonors/LivingDonorNotesData.json';
  import NotesColumnsJson from '@/components/prototypes/json/LivingDonors/LivingDonorNotesColumns.json';
  import TasklistDataJson from '@/components/prototypes/json/LivingDonors/LivingDonorTaskListData.json';
  import LivingDonorKidneySpecificDetails from '@/components/prototypes/living_donor/organs/LivingDonorKidneySpecificDetails.vue';
  import RecoveryInformation from '@/components/livingDonors/RecoveryInformation.vue';
  import { mixins } from "vue-class-component";
  import { ValidationUtilsMixin } from "@/mixins/validation-utils-mixin";
  import { IdLookup } from '@/store/validations/types';
  import { SaveResult } from '@/types';
  import LinkToRecipient from '@/components/livingDonors/LinkToRecipient.vue';

  @Component({
    components: {
      PageTop,
      SideNavLivingDonorOrgan,
      LoadingSideNav,
      LoadingDonorPage,
      LivingDonorStickySummary,
      LivingDonorSummary,
      LivingDonorOffers,
      LivingDonorPhaseStatus,
      LinkToRecipient,
      Tasklists,
      Actions,
      Notes,
      RecoveryInformation,
      PostDonation,
      LivingDonorKidneySpecificDetails
    },
  })
  export default class EditDeceasedDonorAllocations extends mixins(ValidationUtilsMixin) {
    @State(state => state.livingAllocations.selected) private allocation!: LivingAllocation;
    @State(state => state.livingDonors.selectedLivingDonor) private livingDonor!: LivingDonor;

    @Getter('clientId', {
      namespace: 'livingDonors'
    }) private clientId!: string | undefined;
    @Getter('organName', {
      namespace: 'lookups'
    }) organNameLookup!: (organCode ? : number) => string;
    @Getter('livingDonorDisplayName', {
      namespace: 'livingDonors'
    }) private livingDonorDisplayName!: string;
    @Getter('prototypeFeatureEnabled', {
      namespace: 'features'
    }) private prototypeFeatureEnabled!: (featureName: string) => boolean;


    // Set page title based on organ and allocation region
    private setPageTitle(): void {
      this.$store.commit('setPageTitle',
        `Living Donors / ${this.livingDonorDisplayName} / ${this.livingDonorOrganName}`);
    }

    private sectionsLoaded = new Set();
    private allSectionsLoaded = false; 
    private currentHospital: any = null;

    // Return true if all sections and their associated data has been loaded
    get isLoaded(): boolean {
      return this.allSectionsLoaded;
    }

    // Return true is the section reference supplied has been loaded
    public isSectionLoaded(ref: string): boolean {
      if (!ref) return false;
      return this.sectionsLoaded.has(ref);
    }

    getTableData(type: any) {
      switch (type) {
        case 'status':
          return JourneyStatusRowsJson;
        case 'actions':
          return ActionRowsJson;
        case 'notes':
          return NotesRowsJson;
        case 'tasks':
          return TasklistDataJson;
        default:
          // code block
      }
    }

    getColumns(type: any) {
      switch (type) {
        case 'status':
          return JourneyStatusColumnsJson;
        case 'actions':
          return ActionColumnsJson;
        case 'notes':
          return NotesColumnsJson;
        default:
          // code block
      }
    }

    public loaded(ref: string): void {
      if (!ref) return;
      // Create a set of all the sections to load filtering out validations and the save button
      const sectionsToLoad = new Set(Object.keys(this.$refs).filter((ref: string) => !ref.match(
        /validations|saveDonor/)));
      // Add the ref we just loaded
      this.sectionsLoaded.add(ref);
      if (this.sectionsLoaded.size === sectionsToLoad.size) {
        this.$store.dispatch('utilities/scrollBehavior');
        this.allSectionsLoaded = true;
      }
    }

    // Emit event to parent so it can handle clearing validations when saving
    private saving(formReference: string) {
      this.$emit('saving', formReference);
    }

    // Parse and highlight errors from api response
    private handleErrors(errors: SaveResult[]|SaveResult, mainSave?: boolean): void {
      mainSave = mainSave ? true : false;

      const idLookup: IdLookup = {};
      const recoveryInformation = this.$refs.recoveryInformation as RecoveryInformation;
      if (recoveryInformation) {
        Object.assign(idLookup, {
          ...recoveryInformation.idLookup,
        });
      }
      const linkToRecipient = this.$refs.linkToRecipient as LinkToRecipient;
      if (linkToRecipient) {
        Object.assign(idLookup, {
          ...linkToRecipient.idLookup(),
        });
      }

      // Standardize errors to an array of SaveResult objects to faciliate duplicate check
      errors = Array.isArray(errors) ? errors : [errors];

      // Derive errors for UI input fields based on API error results
      const formErrors = this.parseFormErrors(errors, idLookup);

      (this.$refs.validations as any).setErrors(formErrors);

    }

    private resetValidationErrors(): void {
      (this.$refs.validations as any).reset();
    }

    /**
     * Vue lifecyle hook, for when the reactivity system has taken control of the Document Object Model.
     *
     * @listens #mounted
     */
    public mounted(): void {
      this.setPageTitle();
      this.$store.dispatch('hospitals/load');
      this.getAllocations();
      this.currentHospital = this.livingDonor.living_donor_info && this.livingDonor.living_donor_info.journeys && this.livingDonor.living_donor_info.journeys[0].transplant_program ? this.livingDonor.living_donor_info.journeys[0].transplant_program.transplant_hospital_id?.$oid : null;
    }

    // Update title if organ or allocation region changes
    @Watch('$route.params.organ_code')
    onOrganCodeChange() {
      if (!this.$route.hash) {
        this.setPageTitle();
        this.getAllocations();
      }
    }

    @Watch('$route.params.option')
    onAllocationRegionChange() {
      if (!this.$route.hash) {
        this.setPageTitle();
        this.getAllocations();
      }
    }

    /**
     * Return the organ name as a string
     *
     * Using the organ_code param, find the matching consented organ for the selected donor.
     *
     * @returns {string} organ name
     */
    get livingDonorOrganName(): string {
      // Prepare organ name for display
      const rawOrganCode = this.$route.params.organ_code || '';
      const numericOrganCode = rawOrganCode != null ? parseFloat(rawOrganCode) : undefined;
      const rawOrganName = this.organNameLookup(numericOrganCode);
      const prettyOrganName = numericOrganCode ? rawOrganName : 'Unknown';
      const donorIndicators = this.livingDonor.indicators || {};
      const organCount = '';
      return `${organCount}${prettyOrganName}`;
    }

    public reloadSummary(): void {
      const livingDonorSummary = this.$refs.livingDonorSummary as LivingDonorSummary;
      livingDonorSummary.initializeForm();
    }

    // Emit event to parent so it can handle clearing validations when saving
    private clear() {
      const validationObserver = this.$refs.validations;
      if (!validationObserver) return;

      (validationObserver as any).reset();
    }

    private getAllocations() {
      this.$store.dispatch('livingAllocations/getAllAllocations', {
        clientId: this.clientId,
        clearSelectedAllocation: true
      });
    }

    // Handle save events generated by descendent components
    private handleSectionSave(sectionSaved: string): void {
      (this.$refs.validations as any).reset();
    }

    // Reload living donor after successful subsection save
    private reload(): void {
      this.$store.dispatch('livingDonors/get', this.clientId).then(() => {
        this.reinitialize();
      });
    }

  // Re-initialize card sections that rely on Living Donor document
  public reinitialize(): void {
    const recoveryInformation = this.$refs.recoveryInformation as RecoveryInformation;
    if (recoveryInformation) recoveryInformation.reinitialize();
  }
  }

</script>
