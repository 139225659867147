<template>
  <base-side-nav :class="{ closed: sideNavClosed }">
    <template v-slot:side-nav-content>
      <side-nav-toolbar @toggle="toggleSideNav">
        <template v-slot:return-link>
          <router-link class="nav-link no-scroll" :title="$t('return_to_prev')" :aria-label="$t('control_that_returns_to_previous_page')" :to="{ name: 'list-recipients' }">
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width />
            <span class="sr-only">
              {{$t('return_to_prev')}}
            </span>
          </router-link>
        </template>
      </side-nav-toolbar>
      <div class="side-nav-container">
        <template>
          <template v-for="journey in selectedRecipientJourneysList">
            <selected-recipient-journey-links-oop 
              @toggle="toggleSideNav" 
              :journeyName="journey.name" 
              :journeyId="journey.id" 
              :organCode="journey.organCode" 
              :key="journey.id" />
          </template>
          <hr>
          <selected-recipient-profile-links-oop @toggle="toggleSideNav" :active="true">
            <template v-slot:profile-return-link>
              <router-link class="no-scroll open" style="color: #2e2e2e !important" :to="{ name: 'edit-recipient-oop', params: { id: recipient.client_id } }">
                {{$t('profile')}}
              </router-link>
            </template>
          </selected-recipient-profile-links-oop>
          <hr>
      </template>
      </div>
    </template>
  </base-side-nav>
</template>

<i18n src="@/components/_locales/common.json"></i18n>
<i18n src="@/components/recipientsOop/_locales/side-nav.json"></i18n>

<script lang="ts">
import { State, Getter } from 'vuex-class';
import { Recipient } from '@/store/recipients/types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import BaseSideNav from '@/components/shared/side-nav/BaseSideNav.vue';
import SelectedRecipientProfileLinksOop from '@/components/recipientsOop/side-nav/SelectedRecipientProfileLinksOop.vue';
import SelectedRecipientJourneyLinksOop from '@/components/recipientsOop/side-nav/SelectedRecipientJourneyLinksOop.vue';
import SideNavToolbar from '@/components/shared/side-nav/SideNavToolbar.vue';

@Component({
  components: {
    BaseSideNav,
    SideNavToolbar,
    SelectedRecipientProfileLinksOop,
    SelectedRecipientJourneyLinksOop
  }
})
export default class SideNavRecipientProfileOop extends Vue {
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;

  @Getter('selectedRecipientJourneysList', { namespace: 'recipients' }) private selectedRecipientJourneysList!: { id: string; name: string, organCode: number }[];

  @Prop({ default: false }) newRecipient!: boolean;

  public sideNavClosed = false;

  /**
   * Boolean used to show the side menu, if true side-nav is open
   * Changes the 'closed' class on the nav
   *
   * @returns {void} changes the sideNavClosed boolean
   */
  public toggleSideNav(): void {
    this.sideNavClosed = !this.sideNavClosed;
  }
}
</script>
