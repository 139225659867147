<template>
  <login-container>
    <template v-slot:content>
      <div class="form-group">
        <template v-if="showUserSelect">
          <template v-if="bypassFusionAuth">
            <user-list :fusionAuth="!bypassFusionAuth"/>
          </template>
          <template v-else>
            <form :action="fusionAuthURL" v-on:submit="(event) => prepareRequest('fusionauth')" ref="fusionRequest" method="post" class="oneIdRequest">
              <input type="submit" :value="$t('login_fusionauth')" class="btn btn-info login float-left"/>
            </form>
          </template>
        </template>
        <login-one-id/>
      </div>
    </template>
  </login-container>
</template>

<i18n src="@/components/login/_locales/common.json"></i18n>

<script lang="ts">
import { State, Getter } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { APIRoute, APIBaseRoute, APINakedRoute, EP } from '@/api-endpoints';
import LoginContainer from '@/components/shared/LoginContainer.vue';
import UserList from '@/components/login/UserList.vue';
import LoginOneId from '@/components/shared/LoginOneId.vue';

interface LoginState {
  user: string|null;
}

@Component({
  components: {
    LoginContainer,
    UserList,
    LoginOneId
  }
})
export default class Login extends Vue {
  @State(state => state.pageState.currentPage.login) private editState!: any;

  @Getter('showUserSelect', { namespace: 'features' }) private showUserSelect!: boolean;
  @Getter('bypassFusionAuth', { namespace: 'features' }) private bypassFusionAuth!: boolean;

  private mounted(): void {
    this.$store.commit('setPageTitle', 'Log in');
    localStorage.removeItem('user_token');
  }

  /**
   * set access_type (either fusionauth or oneid)
   * used to determine what the next stage of authentication will be
   *
   * @param type string
   * @param replace parameters to replace in path
   * @returns {string}
   */
  prepareRequest(type: string) {
    localStorage.removeItem('access_type');
    localStorage.setItem('access_type', type);
  }

  /**
   * get fusionauth url
   *
   * @returns string
   */
  get fusionAuthURL(): string { return APINakedRoute(EP.fusionAuth.request); }
}
</script>
