<template>

  <div class="row">
    <div class="col-sm-12 contact-address-section">
      <sub-section title='Address' sub-section-id="address_section" :table-config="tableConfig"
        hasCustomClasses="vgt-table table table-bordered table-hover table-has-actions" tabbableValue="categoryName"
        @table-row-click="editSelected" @table-create-row="clearForm" :row-style-class="rowStyleClass"
        @on-row-click="editSelected" scrollToID="recipient-address-form">
        <template v-slot:actions>
          <filter-component :displayFilter="displayFilter" @updateFilter="updateFilter" fieldID='Address' :setFilters='setFilters' :archivedFilter="true"/>
        </template>
        <template v-slot:table-cell="props">
          <template v-if="props.column.field == 'actions'">
            <button class="btn btn-light btn-action mr-2" @click.prevent="editSelected(props.row)"
              :title="$t('Edit this address')">
              <font-awesome-icon class='text-grey' :icon="['fas', 'edit']" size='sm' aria-hidden='true' fixed-width />
            </button>
          </template>
        </template>

      </sub-section>

      <sub-section 
        ref="contactAddressSection"
        sub-section-id="recipient-address-form" saveButton="true"
        :save-button-text="editstate ? `${$t('Update Address')}` : `${$t('Save Address')}` " @save="uploadDocument()"
        showCancelButton="true" cancelButtonText="Cancel" @cancel="clearForm()" 
        @archive="archiveDocument()"
        :showDisabledSave="status == 'archived'"
        :showArchivedButton="editstate ? true : false" 
        :archivedButtonText="status == 'active'? 'Archive' : 'Restore' "
        :showDeleteButton="false" deleteButtonText="Delete" @deleteItem="deleteItem()">
        <template v-slot:contents>
          <fieldset>
            <legend>
              <h5 v-if="!editstate" class="legend-title">
                New Address
              </h5>
              <h5 v-else class="legend-title">
                Edit Address
              </h5>
            </legend>
            <div class="row">
              <div class="standard-form-group">
                <select-input rules="required" select-id="addressCategory" name="Category"
                  validation-id="addressCategory" v-model="category" :options="categoryOptions" />
              </div>

            </div>

            <div class="row">
              <div class="standard-form-group-with-other">
                <country-input ruleKey="patient_profile.addresses.country_code"
                  select-id="contactinfo-permanent-country" name="Country" v-model="countryDropdown"
                  :options="countryLookup" @change="onCountryChange">
                  <template v-slot:province>
                    <select-input rules="required_unless:@urgent" ruleKey="patient_profile.addresses.province_code"
                      select-id="contactinfo-permanent-province" name="Province" validation-id="province-state"
                      v-model="province" :options="provinceLookup" />
                  </template>
                  <template v-slot:state>
                    <select-input rules="required_unless:@urgent" select-id="contactinfo-permanent-state" name="State"
                      validation-id="province-state" v-model="state" :options="usStateLookup" />
                  </template>
                  <template v-slot:other>
                    <text-input ruleKey="patient_profile.addresses.country_other"
                      input-id="contactinfo-permanent-othercountry" rules="required" name="Other country"
                      v-model="countryOther" />
                  </template>
                </country-input>

              </div>
              <div class="standard-form-group-large">
                <text-input rules="required" inputId="contactinfo-ontario-street"
                  name="Street Address / P.O. Box / Rural Route" v-model="street"
                  :showToolTip="true"
                  toolTipText="Include apartment or suite numbers" />
              </div>
              <div class="standard-form-group">
                <text-input rules="required" inputId="contactinfo-ontario-city" name="City" v-model="city" />
              </div>
              <div class="standard-form-group">
                <text-input :rules="{required: true , regex: getRegex()}" :disabled="!countryDropdown"
                  inputId="contactinfo-ontario-postalcode" :name="getPostalLabelText" v-model="postalCode"
                  :mask="getMask" />
              </div>
            </div>
          </fieldset>
        </template>

      </sub-section>
    <confirm-dialog key="archiveAddress"/>
    </div>
  </div>
</template>


<i18n src="./_locales/AddressComponent.json"></i18n>

<script lang="ts">
  import {
    Getter,
    State,
  } from 'vuex-class';
  import {
    mixins
  } from "vue-class-component";
  import {
    Component,
    Vue,
    Prop
  } from 'vue-property-decorator';
  import CardSection from "@/components/shared/CardSection.vue";
  import SaveToolbar from '@/components/shared/SaveToolbar.vue';
  import {
    VueGoodTable
  } from 'vue-good-table';
  import SelectInput from '@/components/shared/SelectInput.vue';
  import CheckboxInput from '@/components/shared/CheckboxInput.vue';
  import {
    GenericCodeValue
  } from '@/store/types';
  import TextInput from '@/components/shared/TextInput.vue';
  import TextAreaInput from '@/components/shared/TextAreaInput.vue';
  import SubSection from '@/components/shared/SubSection.vue';
  import DateInput from '@/components/shared/DateInput.vue';
  import TimeInput from '@/components/shared/TimeInput.vue';
  import SelectOtherInput from '@/components/shared/SelectOtherInput.vue';
  import FilterComponent from '@/components/prototypes/shared/_FilterComponent.vue';
  import ConfirmDialog from 'primevue/confirmdialog';
  import {
    TableConfig,
    SaveProvider,
  } from '@/types';

  import {
    Recipient
  } from "@/store/recipients/types";
  import {
    UserDetails
  } from '@/store/userAccounts/types';
  import {
    DateUtilsMixin
  } from "@/mixins/date-utils-mixin";
  import NumberInput from '@/components/shared/NumberInput.vue';
  import CountryInput from '@/components/shared/CountryInput.vue';
  import {
    CountryValue,
    Country,
    Province
  } from '@/store/lookups/types';

  import BooleanRadioInput from '@/components/shared/BooleanRadioInput.vue';


  interface TableRow {
    id: string;
    category ? : string;

    date ? : string;
    fileName ? : string;

    fileType ? : string;
    description ? : string;
    uploadedBy ? : string;
  }

  export interface FollowUpTypeOption extends GenericCodeValue {
    hasChecklist: boolean;
    checklistReference ? : string;
  }

  @Component({
    components: {
      CardSection,
      SaveToolbar,
      SubSection,
      DateInput,
      TimeInput,
      SelectInput,
      TextInput,
      CheckboxInput,
      TextAreaInput,
      VueGoodTable,
      SelectOtherInput,
      BooleanRadioInput,
      CountryInput,
      FilterComponent,
      ConfirmDialog      
    }
  })
  export default class RecipientDocuments extends mixins(DateUtilsMixin) {

    @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
    @State(state => state.userAccounts.selected) private selected!: UserDetails;
    @State(state => state.pageState.currentPage.journeyStatus) editState!: any;

    @Getter('getUser', {
      namespace: 'users'
    }) private getUser!: any;
    @Prop({
      default: null
    }) countryLookup!: any;
    @Prop({
      default: null
    }) provinceLookup!: any;
    @Prop({
      default: null
    }) usStateLookup!: any;


    @Prop({
      default: null
    }) columns!: any;
    @Prop({
      default: null
    }) tableData!: any;
    @Prop({
      default: null
    }) title!: string;
    @Prop({
      default: null
    }) localStorageRef!: string;

    loaded() {
      // Parse tasks from local storage if found, otherwise default to JSON file contents
      let formState;
      const defaultState = {
        addresses: this.tableData.rows
      };
      const items: any = localStorage.getItem(this.localStorageRef);
      if (items) {
        try {
          formState = JSON.parse(items);
        } catch (error) {
          console.warn('Warning: Cannot parse tasklist from local storage, setting default tasks.', error);
          formState = defaultState;
        }
      } else {
        formState = defaultState;
      }
      this.$store.commit("pageState/set", {
        pageKey: "journeyStatus",
        value: formState,
      });
      this.addresses = formState.addresses || [];
    }

    private mounted(): void {
      Promise.all([
        this.$store.dispatch('hospitals/load'),
      ]).finally(() => {
        this.loaded();
      });
    }

    private id = null;
    private category = '';
    private countryOther = '';
    private province = '';
    private countryDropdown = '';
    private state = '';
    private street = '';
    private city = '';
    private addressActive = null;
    private postalCode = '';
    private addresses: any = [];
    private editstate = false;
    private status = 'active';
    private isTableUpdating = false;
    private taskInProgress = false;
    public perPage = 10;
    public currentPageTable = 1;
    private isLoading = true;
    private isSubSectionLoading = true;
    public hospitalsToLoad = ['all'];
    private setFilters: any = {};
    private displayFilter = true;

    private onCountryChange() {
      this.province = '';
      this.state = '';
      this.postalCode = '';
      this.countryOther = '';
      this.$emit('clear');
    }



    private categoryOptions = [{
      code: 0,
      value: 'Home'
    }, {
      code: 1,
      value: 'Business'
    }, {
      code: 2,
      value: 'Permanent'
    }, {
      code: 3,
      value: 'Temporary'
    }];

    public getCategoryValue(item: any) {
      return this.categoryOptions[item as any].value;
    }

    public prepareAddress(item: any) {
      if (this.countryLookup.length === 0) return null;
      const countryCode = item.countryDropdown;
      const provinceCode = item.province;
      const stateCode = item.state;

      if (!countryCode) return null;

      const matchingCountry = this.countryLookup.find((val: Country) => {
        return val.code === item.countryDropdown;
      });
      if (!matchingCountry) return null;

      const provinceOptions = matchingCountry.sub_tables.province || [];
      if (provinceOptions.length === 0) return null;

      const stateOptions = matchingCountry.sub_tables.province || [];
      if (provinceOptions.length === 0) return null;


      let matchingProvince = null;

      if (stateCode) {
        matchingProvince = stateOptions.find((provinceOption: Province) => {
          return provinceOption.code === item.state;
        });

      } else {
        // canada
        matchingProvince = provinceOptions.find((provinceOption: Province) => {
          return provinceOption.code === item.province;
        });
      }

      if (!matchingProvince) return null;
      return `${item.street}, ${item.city}, ${matchingProvince.value}, ${item.postalCode}, ${matchingCountry.value}`;

    }

    get getMask(): string {
      const countryCode = this.countryDropdown ? this.countryDropdown : null;

      if (countryCode == CountryValue.USA) {
        return "XXXXXX";
      } else if (countryCode == "CAN") {
        return 'XXX XXX';
      }
      return '';
    }

    getRegex(): any {
      const countryCode = this.countryDropdown ? this.countryDropdown : null;
      if (countryCode == CountryValue.USA) {
        return "zip_code";
      } else if (countryCode == "CAN") {
        return 'postal_code';
      }
    }


    get getPostalLabelText(): string {
      const countryCode = this.countryDropdown ? this.countryDropdown : null;
      if (countryCode == CountryValue.USA) {
        return "Zip Code";
      }
      return 'Postal Code';
    }

    get tableRows() {
      const addresses = this.addresses || [];
      return addresses.map((address: any) => {
        return {
          id: address.id,
          date: this.parseDisplayDateUi(address.date) || '-',
          category: address.category,
          categoryName: address.category ? this.getCategoryValue(address.category) : null,
          street: address.street,
          city: address.city,
          postalCode: address.postalCode,
          countryDropdown: address.countryDropdown,
          province: address.province,
          state: address.state,
          countryOther: address.countryOther,
          address: this.prepareAddress(address),
          status: address.status,
          statusName: address.status.charAt(0).toUpperCase() + address.status.slice(1)
        };
      });
    }

    get tableConfig(): TableConfig {
      return {
        data: this.tableRows,
        columns: this.columns,
        createButton: true,
        createText: `${this.$t('Create Address')}`,
        sortOptions: {
          enabled: false,
        },
        paginationOptions: {
          enabled: true,
          mode: 'records',
          perPage: this.perPage,
          perPageDropdown: [10, 25, 50],
          setCurrentPage: this.currentPageTable,
          dropdownAllowAll: true,
          nextLabel: '',
          prevLabel: '',
          rowsPerPageLabel: this.$t('table.results'),
        },
      };
    }

    private editSelected(row: any) {
      if (row) {
        this.editstate = true;
        this.category = row.row.category;
        this.id = row.row.id;
        this.street = row.row.street;
        this.city = row.row.city;
        this.postalCode = row.row.postalCode;
        this.countryDropdown = row.row.countryDropdown;
        this.province = row.row.province;
        this.state = row.row.state;
        this.countryOther = row.row.countryOther;
        this.status = row.row.status;
        this.$emit('clear');
      }
    }

    private updateFilter(filterArray: any) {
      if (filterArray.length == 0) {
        return false;
      }

      this.setFilters = filterArray;
    }

    get emptyMessage(): string {
      if (!this.addresses) {
        return this.$t('loading').toString();
      } else {
        return this.$t('use_form_below_follow_up').toString();
      }
    }

    public resetSaveToolbar(): void {
      // Refer to the save toolbar that handles this page
      const saveToolbar = this.$refs.contactAddressSection as unknown as SaveProvider;

      //const saveToolbar = (this.$refs.recipientCareGiver as SubSection).$refs[
      ///'save-recipient-careGiver'] as SaveToolbar;
      saveToolbar.resetSaveToolbar();
    }


    get getUserFullName(): string | undefined {
      let user = this.getUser;
      return user.name;
    }

    public getOptionValue(item: any, options: any, other: any) {
      if (other) {
        return other;
      } else {


        return options[item as any].value;
      }
    }

    public deleteItem(): any {
      return null;
    }

    public clearForm(): any {
      this.category = '';
      this.street = '';
      this.city = '';
      this.postalCode = '';
      this.countryDropdown = '';
      this.province = '';
      this.state = '';
      this.editstate = false;
      this.countryOther = '';
      this.status = 'active';
      this.$emit('clear');
      this.resetSaveToolbar();
      this.editstate = false;
    }

    private archiveDocument(): any {
      this.isTableUpdating = true;
      let addresses = this.addresses || [];
      let message= "";

      addresses.map((item: any) => {
        if (item.id === this.id) {
          if (item.status == 'active') {
            message = 'Archive';
          } else {
            message = 'Restore';
          }
        }
      });

      this.$confirm.require({
        message: `${message} this item`,
        header: '',
        acceptLabel: "Ok",
        key: 'archiveAddress',
        rejectLabel: "Cancel",
        acceptClass: 'button btn btn-sm btn-success',
        rejectClass: 'button btn btn-sm btn-secondary',
        accept: () => {
          addresses.map((item: any) => {
            if (item.id === this.id) {
              if (item.status == 'active') {
                item.status = 'archived';
              } else {
                item.status = "active";
              }
            }
          });
          this.$confirm.close();
        },
        reject: () => {
          this.$confirm.close();
          //callback to execute when user rejects the action
        }
      });





      this.addresses = addresses;

      const defaultState = {
        addresses: addresses
      };

      localStorage.setItem(this.localStorageRef, JSON.stringify(defaultState));

      this.isTableUpdating = false;
      this.editstate = false;
      this.clearForm();
    }

    getArchivedStatus(row: any) {
      if (this.setFilters && this.setFilters.includeArchived) {
        return row.status === 'archived' ? 'highlight-yellow' : '';
      } else if (row.status === 'archived') {
        return  'd-none';
      }
    }


    rowStyleClass(row: any) {


      return `${this.getArchivedStatus(row)} tr-link`;

    }


    public uploadDocument(): any {
      this.isTableUpdating = true;
      let addresses = this.addresses || [];
      if (this.category && this.street && this.city && (this.province || this.state) && this.postalCode || this
        .countryDropdown) {



        if (!this.editstate) {

          const payload: any = {
            id: Math.random().toString(16).slice(2),
            date: new Date().toLocaleDateString('en-CA'),
            category: this.category,
            street: this.street,
            city: this.city,
            postalCode: this.postalCode,
            countryDropdown: this.countryDropdown,
            province: this.province,
            state: this.state,
            status: "active",
            countryOther: this.countryOther,
          };
          addresses.push(payload);
        } else {
          addresses.map((item: any) => {
            if (item.id === this.id) {
              item.date = new Date().toLocaleDateString('en-CA'),
                item.category = this.category;
              item.street = this.street;
              item.city = this.city;
              item.postalCode = this.postalCode;
              item.countryDropdown = this.countryDropdown;
              item.province = this.province;
              item.state = this.state;
              item.status = this.status;
              item.countryOther = this.countryOther;
            }
          });

        }

        this.addresses = addresses;

        const defaultState = {
          addresses: addresses
        };

        localStorage.setItem(this.localStorageRef, JSON.stringify(defaultState));

        this.isTableUpdating = false;
        this.editstate = false;
        this.clearForm();
      } else {
        this.isTableUpdating = false;
        this.resetSaveToolbar();
        return false;
      }
    }
  }

</script>

<style>


</style>
