<template>
  <modal-section
    modal-id="allocation-audit-messages-modal"
    ref="allocationAuditMessagesModal"
    class="modal-sticky-header"
    :centered="true"
  >
    <template v-slot:title>
      {{ $t('allocation_audit_messages_heading') }}
    </template>
    <template v-slot:body>
      <div class="row mb-3">
        <div class="col-12">
          {{ $t('allocation_audit_messages_explanation') }}
        </div>
      </div>
      <!-- Allocation Warnings -->
      <sub-section
        sub-section-id="allocation-audit-messages-modal-warning-section"
        :title="$tc('allocation_warning_messages_heading', filteredWarningMessages.length, { count: filteredWarningMessages.length })"
        :collapsible="true"
      >
        <template v-slot:contents>
          <span v-if="filteredWarningMessages.length === 0">{{ $t('no_allocation_audit_warning_messages') }}</span>
          <div v-else v-for="(warningMessage, warningIndex) in filteredWarningMessages" :key="`warning-message-${warningIndex}`" class="alert alert-warning">
            {{ warningMessage }}
          </div>
        </template>
      </sub-section>
      <!-- Allocation Errors -->
      <sub-section
        sub-section-id="allocation-audit-messages-modal-warning-section"
        :title="$tc('allocation_error_messages_heading', filteredWarningMessages.length, { count: filteredErrorMessages.length })"
        :collapsible="true"
      >
        <template v-slot:contents>
          <span v-if="filteredErrorMessages.length === 0">{{ $t('no_allocation_audit_error_messages') }}</span>
          <div v-else v-for="(errorMessage, errorIndex) in filteredErrorMessages" :key="`error-message-${errorIndex}`" class="alert alert-danger">
            {{ errorMessage }}
          </div>
        </template>
      </sub-section>
    </template>
    <template v-slot:footer>
      <div class="modal-footer-body">
        <button
          type="button"
          data-dismiss="modal"
          class="btn btn-secondary"
        >
          {{ $t('cancel') }}
        </button>
      </div>
    </template>
  </modal-section>
</template>

<i18n src="@/components/allocations/_locales/common.json"></i18n>
<i18n src="@/components/allocations/_locales/_AllocationAuditMessages.json"></i18n>

<script lang="ts">
import { Getter } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import SubSection from '@/components/shared/SubSection.vue';
import ModalSection from '@/components/shared/ModalSection.vue';
import { Allocation } from '@/store/allocations/types';


@Component({
  components: {
    SubSection,
    ModalSection,
  }
})
export default class AllocationAuditMessages extends Vue {
  @Getter('selectedAllocation', { namespace: 'allocations' }) private allocation!: Allocation;

  // Show modal
  public showModal(): void {
    const targetModal = this.$refs.allocationAuditMessagesModal as ModalSection;
    if (!targetModal) return;

    targetModal.toggleModal();
  }

  // Which audit warnings to show
  get filteredWarningMessages(): string[] {
    if (!this.allocation) return [];

    // NOTE: here is where to do any translation, pattern-matching, or filtering
    const warnings = this.allocation.messages.warnings || [];
    return warnings;
  }

  // Which audit errors to show
  get filteredErrorMessages(): string[] {
    if (!this.allocation) return [];

    // NOTE: here is where to do any translation, pattern-matching, or filtering
    const errors = this.allocation.messages.errors || [];
    return errors;
  }

}
</script>
