<template>
  <div>
    <template>
      <page-top>
        {{$t('administration')}}
      </page-top>
      <div class="content-wrap">
        <div class="container-fluid">
          <div class="nav-wrapper" id='administration-area'>
            <side-nav-admin></side-nav-admin>
            <div class="page-content" >
              <div class="col-sm-12">
                {{$t('administration')}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<i18n src="@/views/_locales/Administration.json"></i18n>

<script lang="ts">
import { Getter, State } from 'vuex-class';
import PageTop from '@/components/shared/PageTop.vue';
import { Component, Vue } from 'vue-property-decorator';
import SelectInput from '@/components/shared/SelectInput.vue';
import SideNavAdmin from '@/components/administration/side-nav/SideNavAdmin.vue';

@Component({
  components: {
    PageTop,
    SideNavAdmin
  }
})
export default class Administration extends Vue {
}
</script>
