<template>
  <card-section
    section-id="notes_and_encounters"
     :isAccordion="prototypeFeatureEnabled('proto_ability_to_expand_collapse')"
  >
    <template v-slot:header>
      {{ title }}
    </template>
    <template v-slot:body>
      <encounters-details-section
        ref="NotesAndEncouters"
      />
    </template>
  </card-section>
</template>




<script lang="ts">
import { Getter, State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import CardSection from "@/components/shared/CardSection.vue";
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import EncountersDetailsSection from '@/components/prototypes/organs/EncountersDetailsSection.vue';

@Component({
  components: {
    CardSection,
    SaveToolbar,
    EncountersDetailsSection
  }
})
export default class NotesAndEncounters extends Vue {  
  title = 'Notes / Encounters';
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;
}
</script>

<style>


</style>
