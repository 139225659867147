<template>
  <div :title="getOfferAttributes.name" :class="getOfferAttributes.style">
    <template v-if="getOfferAttributes.faIcon">
      <font-awesome-icon :icon="['fas', getOfferAttributes.faIcon]" fixed-width />
    </template>
    <template v-if="!getOfferAttributes.faIcon">
      {{getOfferAttributes.text}}
    </template>
  </div>
</template>



<script lang="ts">
import { Getter, State } from 'vuex-class';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { AllocationOfferTypeValues, AllocationOfferResponseCodeValues } from '@/store/allocations/types';

@Component
export default class OfferIcon extends Vue {
  @Prop({ default: null }) offer!: string; // offer code from table row
  @Prop({ default: null }) response!: string; // response code from table row

  get getOfferAttributes(): Record<string, unknown> {
    const { offer, response } = this.$props;
    let style = undefined;
    switch(offer) {
      case AllocationOfferTypeValues.Primary:
        if (response === AllocationOfferResponseCodeValues.Withdraw || response === AllocationOfferResponseCodeValues.Cancel) {
          style = { name: "Offer withdrawn or discontinued", style: "circle circle-offer-widthdrawn", text: undefined, faIcon: "times" };
        } else {
          style = { name: "Primary Offer", style: "circle circle-primary-offer", text: "P", faIcon: undefined };
        }
        break;
      case AllocationOfferTypeValues.Backup:
        if (response === AllocationOfferResponseCodeValues.Withdraw || response === AllocationOfferResponseCodeValues.Cancel) {
          style = { name: "Offer withdrawn or discontinued", style: "circle circle-offer-widthdrawn", text: undefined, faIcon: "times" };
        } else {
          style = { name: "Backup Offer", style: "circle circle-backup-offer", text: "B", faIcon: undefined };
        }
        break;
      case AllocationOfferTypeValues.NoOffer:
        style = { name: "No Offer Made", style: 'circle circle-no-offer', text: undefined, faIcon: "ban" };
        break;
      default:
        style = { name: "Pending Offer", style: "circle circle-pending-offer", text: undefined, faIcon: undefined };
        break;
    }
    return style;
  }

}
</script>
