<template>
  <card-section section-id="offer-history" 
  :isAccordion="prototypeFeatureEnabled('proto_ability_to_expand_collapse')">
    <template v-slot:header>
      Offer History - {{organDescription}}
    </template>
    <template v-slot:body>


      <sub-section sub-section-id="sub_offer_history">
        <template v-slot:contents>
          <div class="row">
            <div class="w-auto mr-5 col-12">
              
            <legend>
              <h4 class="legend-title"><span class="">Offer Responses</span></h4>
            </legend>
            <fieldset>
              <div class="table-responsive-md allocationOffers">
                <table class="table table table-bordered table-hover  table-allocation table-demo-offers">

                  <thead>
                    <tr class="fixed-head">
                      <th scope="col" style="width: 11%;max-width: 11%;min-width: 11%;"><span>Offer Date Time</span></th>
                      <th scope="col" class="">Organ</th>
                      <th scope="col">Laterality</th>
                      <th scope="col">Offer Type</th>
                      <th scope="col">Response Date Time</th>
                      <th scope="col">Coordinator</th>
                      <th scope="col">Surgeon</th>
                      <th scope="col">Offer Response</th>
                      <th scope="col">Response Category</th>
                      <th scope="col">Outcome / Dispostion</th>
                      <th scope="col">Notes</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr id="offer_0" class="offer-row-no-offer">
                      <td><a href="#" @click.prevent="openCompareModal(552288)"><span>05-12-2023 15:00</span></a></td>
                      <td class="text-center">
                        <span>Liver</span>
                      </td>
                      <td class="text-center">
                        <span>Whole</span>
                      </td>
                      <td class="text-center">
                        <span>Primary</span>
                      </td>
                      <td>05-12-2023 15:42</td>
                      <td>M. Couch</td>
                      <td>C. Bratton</td>
                      <td>Declined</td>
                      <td>-</td>
                      <td>Declined</td>
                      <td>Declined due to size</td>
                    </tr>
                    <tr id="offer_0" class="offer-row-no-offer">
                      <td><a href="#" @click.prevent="openCompareModal(552288)"><span>03-12-2023 18:00</span></a></td>
                      <td class="text-center">
                        <span>Liver</span>
                      </td>
                      <td class="text-center">
                        <span>Whole</span>
                      </td>
                      <td class="text-center">
                        <span>Backup</span>
                      </td>
                      <td>03-12-2023 19:00</td>
                      <td>S. Woods</td>
                      <td>B. Jones</td>
                      <td>Accepted</td>
                      <td>-</td>
                      <td>Never became primary</td>
                      <td>-</td>
                    </tr>
                    <tr id="offer_0" class="offer-row-no-offer">
                      <td><a href="#" @click.prevent="openCompareModal(552288)"><span>25-11-2023 23:00</span></a></td>
                      <td class="text-center">
                        <span>Liver</span>
                      </td>
                      <td class="text-center">
                        <span>Whole</span>
                      </td>
                      <td class="text-center">
                        <span>Primary</span>
                      </td>
                      <td>25-11-2023 23:48</td>
                      <td>S. Woods</td>
                      <td>C. Bratton</td>
                      <td>Accepted</td>
                      <td>-</td>
                      <td>Not Transplanted (donor reasons)</td>
                      <td>-</td>
                    </tr>
                    <tr id="offer_0" class="offer-row-no-offer">
                      <td><a href="#" @click.prevent="openCompareModal(552288)"><span>14-11-2023 11:00</span></a></td>
                      <td class="text-center">
                        <span>Liver</span>
                      </td>
                      <td class="text-center">
                        <span>Whole</span>
                      </td>
                      <td class="text-center">
                        <span>Backup</span>
                      </td>
                      <td>14-11-2023 11:45</td>
                      <td>M. Couch</td>
                      <td>C. Bratton</td>
                      <td>Accepted</td>
                      <td>-</td>
                      <td>Never became primary</td>
                      <td>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </fieldset>
            
              </div>
            </div>
        

        </template>
      </sub-section>
      <!-- Confirmation details -->

    </template>
  </card-section>


</template>


<script lang="ts">
import { State, Getter } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { organCodeLookup } from '@/types';
import { OrganCodeValue } from '@/store/lookups/types';
import { RecipientJourney } from '@/store/recipientJourney/types';

import CardSection from "@/components/shared/CardSection.vue";
import SubSection from '@/components/shared/SubSection.vue';

  @Component({
    components: {
      CardSection,
      SubSection
    }
  })
export default class OfferHistory extends Vue {
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;

  @Getter("includeStomach", { namespace: "recipients" }) includeStomach!: (journeyId?: string) => boolean;
  @Getter('journeyId', { namespace: 'journeyState', }) journeyId!: string|undefined;
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;


  /**
   * Return titlized organ name
   *
   * @returns {string} organ name titlized
   */
  get organDescription(): string {
    let organName = this.organComponent;
    if (this.includeStomach(this.journeyId) && this.journey.organ_code == OrganCodeValue.SmallBowel) {
      return organName = this.$t(`${organName} + Stomach`).toString();
    } else {
      return this.$t(organName).toString();
    }
  }

  /**
   * Gets the current journey as lower case
   *
   * Using the organ code, return the lower case organ name
   *
   * @returns {string} organ as lower case
   */
  get organComponent(): string {
    return organCodeLookup[`${this.journey ? this.journey.organ_code : ''}`] || '';
  }

  private openCompareModal(donorId: any) {
    this.$emit('openCompareModal', donorId);
  }
}
</script>

<style scoped>
.table-bordered.table-demo-offers th, .table-demo-offers td, .table-demo-offers td input, .table-demo-offers td select {
  font-size: .8rem !important;
  font-weight: 600 !important;
}

.table-allocation:not(.table-offers) tbody > tr:first-child td, .table-offers:not(.table-offers) tbody > tr:first-child td {
	border-bottom: 1px solid #dee2e6;;
}
.temp-form-subheader {
  font-weight: 600;
}
.offer-form-wrapper {
  padding-top: .5rem;
  border-bottom: 1px solid #dee2e6;
}
</style>
