import { render, staticRenderFns } from "./_OrganJourneyOverview.vue?vue&type=template&id=ad156fc6"
import script from "./_OrganJourneyOverview.vue?vue&type=script&lang=ts"
export * from "./_OrganJourneyOverview.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/components/_locales/Organs.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fprototypes%2Forgans%2F_shared%2F_OrganJourneyOverview.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/components/organs/shared/_locales/OrganDetailsSection.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fprototypes%2Forgans%2F_shared%2F_OrganJourneyOverview.vue&external"
if (typeof block1 === 'function') block1(component)

export default component.exports