<template>
  <card-section section-id="living_donor_kidney_specific_details"
    :isAccordion="prototypeFeatureEnabled('proto_ability_to_expand_collapse')">
    <template v-slot:header>
      {{ title }}
    </template>
    <template v-slot:body>
      <div class="row">
        <div class="col-sm-12">
          <sub-section 
            title="General Kidney Assessment"
            sub-section-id="ksd-general"
            :saveButton="true"
            saveButtonText="Save Kidney Specific Assessment"
            ref="saveKidneySpecificDetails"
            @save="uploadDocument()">
            <template v-slot:contents>
              <fieldset>
                <div class="row">
                  <div class="standard-form-group">
                    <number-input
                      min="0"
                      step="1"
                      input-id="ksd-serum_creatine"
                      :disabled="true"
                      name="Serum Creatinine (µmol/L) "
                      v-model="serum_creatine"
                    />
                  </div>
                  <div class="standard-form-group">
                    <date-input
                      rules="required"
                      input-id="ksd-serum_creatine_date"
                      name="Serum Creatinine Date"
                      :disabled="true"
                      v-model="serum_creatine_date"
                    />
                  </div>
                  <div class="standard-form-group">
                    <text-input
                      inputId="ksd-eGFR"
                      name="eGFR (mL/min/1.73m2)"
                      :disabled="true"
                      v-model="eGFR"
                      />
                  </div>
                  <div class="standard-form-group">
                    <date-input
                      rules="required"
                      input-id="ksd-eGFR_date"
                      name="eGFR Date"
                      :disabled="true"
                      v-model="eGFR_date"
                    />
                  </div>
                  <div class="standard-form-group">
                    <text-input
                      inputId="ksd-A1C"
                      name="A1C (%)"
                      :disabled="true"
                      v-model="A1C"
                      />
                  </div>
                  <div class="standard-form-group">
                    <date-input
                      rules="required"
                      input-id="ksd-A1C_date"
                      name="A1C Date"
                      :disabled="true"
                      v-model="A1C_date"
                    />
                  </div>
                  <div class="row-break d-none d-xxl-block d-xxxl-none"></div>
                  <div class="standard-form-group-large">
                    <text-area-input
                      input-id="ksd-general_comments"
                      name="General Comments"
                      :disabled="true"
                      v-model="comments"
                    />
                  </div>
                </div>
                <!--
                <div class="hr-break" />
                <div class="row">
                  <div class="standard-form-group">
                    <checkbox-input
                      input-id="ksd-kidney-paired"
                      labelName="Kidney Paired Donation"
                      v-model="kidneyPaired"
                      label="Yes"
                    />
                  </div>
                  <div class="row-break d-none d-sm-block d-lg-none"></div>
                  <div class="standard-form-group">
                    <checkbox-input
                      input-id="ksd-list-exchange"
                      labelName="List Exchange?"
                      v-model="listExchange"
                      label="Yes"
                    />
                  </div>
                  <div class="standard-form-group">
                    <date-input
                      :rules="'required_if:@listExchange'"
                      name="List Exchange Date"
                      input-id="ksd-list-exchange-date"
                      :disabled="listExchange"
                      v-model="listExchangeDate"
                    />
                  </div>
                  <div class="row-break d-none d-xxl-block d-xxxl-none"></div>
                  <div class="standard-form-group">
                    <checkbox-input
                      input-id="ksd-previous-living-donor"
                      labelName="Previous Living Donor?"
                      v-model="previousLivingDonor"
                       label="Yes"
                      @change="clearDonationDate()"
                    />
                  </div>
                  <div class="standard-form-group">
                    <date-input
                      :rules="'required'"
                      name="Donation Date"
                      input-id="ksd-donation-date"
                      :disabled="!previousLivingDonor"
                      v-model="donationDate"
                    />
                  </div>
                </div>
                -->

              </fieldset>
            </template>

          </sub-section>

        </div>
      </div>

    </template>
  </card-section>
</template>


<script lang="ts">
  import {
    Getter,
    State,
  } from 'vuex-class';
  import {
    mixins
  } from "vue-class-component";
  import {
    Component,
    Vue, Prop
  } from 'vue-property-decorator';
  import CardSection from "@/components/shared/CardSection.vue";
  import SaveToolbar from '@/components/shared/SaveToolbar.vue';
  import {
    VueGoodTable
  } from 'vue-good-table';
  import SelectInput from '@/components/shared/SelectInput.vue';
  import NumberInput from '@/components/shared/NumberInput.vue';
  import CheckboxInput from '@/components/shared/CheckboxInput.vue';
  import DateInput from '@/components/shared/DateInput.vue';
  import TextInput from '@/components/shared/TextInput.vue';
  import TextAreaInput from '@/components/shared/TextAreaInput.vue';
  import SubSection from '@/components/shared/SubSection.vue';
  import {
    Recipient
  } from "@/store/recipients/types";
  import {
    UserDetails
  } from '@/store/userAccounts/types';
  import {
    DateUtilsMixin
  } from "@/mixins/date-utils-mixin";

  interface TableRow {
    id: string;
    category ? : string;

    date ? : string;
    fileName ? : string;

    fileType ? : string;
    description ? : string;
    uploadedBy ? : string;
  }

  @Component({
    components: {
      CardSection,
      SaveToolbar,
      SubSection,
      SelectInput,
      TextInput,
      CheckboxInput,
      TextAreaInput,
      DateInput,
      NumberInput,
      VueGoodTable
    }
  })
  export default class RecipientDocuments extends mixins(DateUtilsMixin) {

    @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
    @State(state => state.userAccounts.selected) private selected!: UserDetails;
    @State(state => state.pageState.currentPage.journeyStatus) editState!: any;

    @Prop({ default: null }) columns!: any;
    @Prop({ default: null }) tableData!: any;
    @Prop({ default: null }) title!: string;
    @Prop({ default: null }) localStorageRef!: string;

    @Getter('prototypeFeatureEnabled', {
      namespace: 'features'
    }) private prototypeFeatureEnabled!: (featureName: string) => boolean;

    private serum_creatine = '';
    private serum_creatine_date = '';
    private eGFR = '';
    private eGFR_date = '';
    private A1C = '';
    private A1C_date = '';
    private comments = '';
    private kidneyPaired = '';
    private listExchange = '';
    private listExchangeDate = '';
    private previousLivingDonor = '';
    private donationDate = '';

    loaded() {
      // Parse data from local storage if found, otherwise default to JSON file contents
      const items: any = localStorage.getItem(this.localStorageRef);
      if (items) {
         try {
          let parsed = JSON.parse(items);
          if (parsed) {
              this.serum_creatine = parsed.serum_creatine;
              this.serum_creatine_date = parsed.serum_creatine_date;
              this.eGFR = parsed.eGFR;
              this.eGFR_date = parsed.eGFR_date;
              this.A1C = parsed.A1C;        
              this.A1C_date = parsed.A1C_date;
              this.comments = parsed.comments;        
              this.kidneyPaired = parsed.kidneyPaired;
              this.listExchange = parsed.listExchange;        
              this.listExchangeDate = parsed.listExchangeDate;
              this.previousLivingDonor = parsed.previousLivingDonor;
              this.donationDate = parsed.donationDate;      
          
          }
        } catch (error) {
          console.warn('Warning: Cannot parse data from local storage, setting default data.', error);
        }
      }
    }

    mounted() {
      this.loaded();
    }

    // clear Donation Date when Previous Living Donor is changed
    public clearDonationDate() {
      Vue.set(this, "donationDate", undefined);
    }

    public resetSaveToolbar(): void {
      // Refer to the save toolbar that handles this page
      const saveToolbar = (this.$refs.uploadDocument as SubSection).$refs[
        'save-kidney-specific-details'] as SaveToolbar;
      saveToolbar.reset();
    }

    public uploadDocument(): any {
        const payload: any = {
          serum_creatine: this.serum_creatine,
          serum_creatine_date: this.serum_creatine_date,
          eGFR:  this.eGFR,
          eGFR_date: this.eGFR_date,
          A1C: this.A1C,     
          A1C_date: this.A1C_date,
          comments: this.comments,      
          kidneyPaired: this.kidneyPaired,
          listExchange: this.listExchange,       
          listExchangeDate: this.listExchangeDate,
          previousLivingDonor: this.previousLivingDonor,
          donationDate: this.donationDate
        };

      localStorage.setItem(this.localStorageRef, JSON.stringify(payload));
      this.resetSaveToolbar();
    }


  }

</script>

<style>


</style>
