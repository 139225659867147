<template>
 <sub-section
   title="Journey State"
    sub-section-id="journey-state"
    ref="journeyState"
    >
        <template v-slot:contents>
          <fieldset>
              <p class="mt-2">
                  <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
                  Keep track of this journey's current state, including Phase, Status and Reason, along with
                  the workflow step it's currently in. Select any row to see additional decision details and
                  comments.
              </p>
              <div class="row">
                  <div class="col-sm-12">
                      <div class="table-responsive-md">
                          <div>
                              <div class="vgt-wrap " id="donorDocuments-uploadedFiles-table"
                                  aria-labelledby="donorDocuments-uploadedFiles-label"><!---->
                                  <div class="vgt-inner-wrap"><!---->
                                      <div class="vgt-global-search vgt-clearfix">
                                          <div class="vgt-global-search__input vgt-pull-left"><!----></div>
                                          <div class="vgt-global-search__actions vgt-pull-right"><button
                                                  type="button" class="btn btn-primary btn-sm"> Create New
                                                  Entry </button></div>
                                      </div> <!---->
                                      <div class="vgt-fixed-header"><!----></div>
                                      <div class="vgt-responsive">
                                          <table id="vgt-table"
                                              class="vgt-table table table-bordered table-hover ">
                                              <colgroup>
                                                  <col id="col-0">
                                                  <col id="col-1">
                                                  <col id="col-2">
                                                  <col id="col-3">
                                                  <col id="col-4">
                                                  <col id="col-5">
                                                  <col id="col-6">
                                                  <col id="col-7">
                                                  <col id="col-8">
                                                  <col id="col-9">
                                              </colgroup>
                                              <thead>
                                                  <tr><!----> <!---->
                                                      <th scope="col" aria-sort="descending"
                                                          aria-controls="col-0"
                                                          class="vgt-left-align sortable sorting sorting-desc"
                                                          style="min-width: 12%; max-width: 12%; width: 12%;">
                                                          <span>Effective Date/Time (ET)</span> <button><span
                                                                  class="sr-only">
                                                                  Sort table by Date in descending order
                                                              </span></button>
                                                      </th>
                                                      <th scope="col" aria-sort="descending"
                                                          aria-controls="col-1"
                                                          class="vgt-left-align sortable"
                                                          style="min-width: 10%; max-width: 10%; width: 10%;">
                                                          <span>Phase</span> <button><span class="sr-only">
                                                                  Sort table by phase in descending order
                                                              </span></button>
                                                      </th><!---->
                                                      <th scope="col" aria-sort="descending"
                                                          aria-controls="col-3"
                                                          class="vgt-left-align sortable"
                                                          style="min-width: 10%; max-width: 10%; width: 10%;">
                                                          <span>Status</span> <button><span class="sr-only">
                                                                  Sort table by Status in descending order
                                                              </span></button>
                                                      </th><!---->
                                                      <th scope="col" aria-sort="descending"
                                                          aria-controls="col-5"
                                                          class="vgt-left-align sortable"
                                                          style="min-width: 15%; max-width: 15%; width: 15%;">
                                                          <span>Reason</span> <button><span class="sr-only">
                                                                  Sort table by Reason in descending order
                                                              </span></button>
                                                      </th><!---->
                                                      <th scope="col" aria-sort="descending"
                                                          aria-controls="col-9"
                                                          class="vgt-left-align sortable"
                                                          style="min-width: 20%; max-width: 20%; width: 20%;">
                                                          <span>Step</span> <button><span class="sr-only">
                                                                  Sort table by Comments in descending order
                                                              </span></button>
                                                      </th>
                                                      <th scope="col" aria-sort="descending"
                                                          aria-controls="col-7"
                                                          class="vgt-left-align sortable"
                                                          style="min-width: 15%; max-width: 15%; width: 15%;">
                                                          <span>Entered By</span> <button><span
                                                                  class="sr-only">
                                                                  Sort table by Entered By in descending order
                                                              </span></button>
                                                      </th><!---->
                                                      <th scope="col" aria-sort="descending"
                                                          aria-controls="col-9"
                                                          class="vgt-left-align sortable"
                                                          style="min-width: 15%; max-width: 15%; width: 15%;">
                                                          <span>Number of days in this step</span>
                                                          <button><span class="sr-only">
                                                                  Sort table by Comments in descending order
                                                              </span></button>
                                                      </th>
                                                  </tr> <!---->
                                              </thead>
                                              <tbody><!---->
                                                  <tr class="clickable"
                                                      style="background-color: #e6f3ed !important;"><!---->
                                                      <!---->
                                                      <td class="vgt-left-align"><a href="#"
                                                              class="table-link">14-02-2024 09:33</a></td>
                                                      <td class="vgt-left-align">Referral</td><!---->
                                                      <td class="vgt-left-align">Active</td><!---->
                                                      <td class="vgt-left-align">Ready for Scheduling</td>
                                                      <!---->
                                                      <td class="vgt-left-align">Ready for Day 1 Scheduling
                                                      </td><!---->
                                                      <td class="vgt-left-align">Stephanie Wood</td>
                                                      <td class="vgt-left-align">3</td>
                                                  </tr>
                                                  <tr class="clickable"><!----> <!---->
                                                      <td class="vgt-left-align"><a href="#"
                                                              class="table-link">14-01-2024 12:01</a></td>
                                                      <td class="vgt-left-align">Referral</td><!---->
                                                      <td class="vgt-left-align">Active</td><!---->
                                                      <td class="vgt-left-align">Scheduled for Evaluation</td>
                                                      <!---->
                                                      <td class="vgt-left-align">Scheduled for Day 1</td>
                                                      <!---->
                                                      <td class="vgt-left-align">Stephanie Wood</td>
                                                      <td class="vgt-left-align">30</td>
                                                  </tr>
                                                  <tr class="clickable"><!----> <!---->
                                                      <td class="vgt-left-align"><a href="#"
                                                              class="table-link">07-01-2024 10:55</a></td>
                                                      <td class="vgt-left-align">Referral</td><!---->
                                                      <td class="vgt-left-align">Active</td><!---->
                                                      <td class="vgt-left-align">Ready for Scheduling</td>
                                                      <!---->
                                                      <td class="vgt-left-align">Ready for Day 1 Scheduling
                                                      </td><!---->
                                                      <td class="vgt-left-align">Stephanie Wood</td>
                                                      <td class="vgt-left-align">7</td>
                                                  </tr>
                                                  <tr class="clickable"><!----> <!---->
                                                      <td class="vgt-left-align"><a href="#"
                                                              class="table-link">28-12-2023 17:00</a></td>
                                                      <td class="vgt-left-align">Referral</td><!---->
                                                      <td class="vgt-left-align">Active</td>
                                                      <td class="vgt-left-align">Referral received</td>

                                                      <td class="vgt-left-align">Pending financial coordinator
                                                          review</td><!---->
                                                      <td class="vgt-left-align">Scott McPhee</td>
                                                      <td class="vgt-left-align">10</td>
                                                  </tr>
                                                  <tr class="clickable"><!----> <!---->
                                                      <td class="vgt-left-align"><a href="#"
                                                              class="table-link">27-12-2023 19:00</a></td>
                                                      <td class="vgt-left-align">Referral</td><!---->
                                                      <td class="vgt-left-align">Active</td><!---->
                                                      <td class="vgt-left-align">Referral received</td>
                                                      <!---->
                                                      <td class="vgt-left-align">Clinical Review</td><!---->
                                                      <td class="vgt-left-align">Scott McPhee</td>
                                                      <td class="vgt-left-align">1</td>
                                                  </tr>
                                                  <tr class="clickable"><!----> <!---->
                                                      <td class="vgt-left-align"><a href="#"
                                                              class="table-link">10-12-2023 07:25</a></td>
                                                      <td class="vgt-left-align">Referral</td><!---->
                                                      <td class="vgt-left-align">Active</td><!---->
                                                      <td class="vgt-left-align">Referral received</td>
                                                      <!---->
                                                      <td class="vgt-left-align">Pending Tx Nephrologist
                                                          review (new referral / result)</td><!---->
                                                      <td class="vgt-left-align">Scott McPhee</td>
                                                      <td class="vgt-left-align">17</td>
                                                  </tr>
                                                  <tr class="clickable"><!----> <!---->
                                                      <td class="vgt-left-align"><a href="#"
                                                              class="table-link">30-11-2023 13:22</a></td>
                                                      <td class="vgt-left-align">Referral</td><!---->
                                                      <td class="vgt-left-align">Active</td><!---->
                                                      <td class="vgt-left-align">Referral received</td>
                                                      <!---->
                                                      <td class="vgt-left-align">Clinical Review</td><!---->
                                                      <td class="vgt-left-align">Stephanie Wood</td>
                                                      <td class="vgt-left-align">10</td>
                                                  </tr>
                                                  <tr class="clickable">
                                                      <td class="vgt-left-align"><a href="#"
                                                              class="table-link">25-11-2023 11:05</a></td>
                                                      <td class="vgt-left-align">Referral</td>
                                                      <td class="vgt-left-align">Active</td>
                                                      <td class="vgt-left-align">Referral received</td>

                                                      <td class="vgt-left-align">Referral intake &amp;
                                                          screening</td>
                                                      <td class="vgt-left-align">Stephanie Wood</td>
                                                      <td class="vgt-left-align">5</td>
                                                  </tr>
                                                  <tr class="clickable"><!----> <!---->
                                                      <td class="vgt-left-align"><a href="#"
                                                              class="table-link">25-11-2023 09:00</a></td>
                                                      <td class="vgt-left-align">Referral</td><!---->
                                                      <td class="vgt-left-align">Active</td><!---->
                                                      <td class="vgt-left-align">-</td>
                                                      <!---->
                                                      <td class="vgt-left-align">New journey</td><!---->
                                                      <td class="vgt-left-align">Stephanie Wood</td>
                                                      <td class="vgt-left-align">1</td>
                                                  </tr> <!---->
                                              </tbody> <!---->
                                          </table>
                                      </div> <!---->
                                      <div class="vgt-wrap__footer vgt-clearfix">
                                          <div class="footer__row-count vgt-pull-left">
                                              <form><label for="vgt-select-rpp-1650216685342"
                                                      class="footer__row-count__label">Results per
                                                      page::</label> <select id="vgt-select-rpp-1650216685342"
                                                      autocomplete="off" name="perPageSelect"
                                                      aria-controls="vgt-table"
                                                      class="footer__row-count__select">
                                                      <option value="10">
                                                          10
                                                      </option>
                                                      <option value="25">
                                                          25
                                                      </option>
                                                      <option value="50">
                                                          50
                                                      </option>
                                                      <option value="-1">All</option>
                                                  </select></form>
                                          </div>
                                          <div class="footer__navigation vgt-pull-right">
                                              <div data-v-347cbcfa="" class="footer__navigation__page-info">
                                                  <div data-v-347cbcfa="">
                                                      1 - 10 of 10
                                                  </div>
                                              </div> <!----> <button type="button" aria-controls="vgt-table"
                                                  class="footer__navigation__page-btn disabled"><span
                                                      aria-hidden="true" class="chevron left"></span>
                                                 </button> <button type="button"
                                                  aria-controls="vgt-table"
                                                  class="footer__navigation__page-btn disabled">
                                                  <span aria-hidden="true"
                                                      class="chevron right"></span></button> <!---->
                                          </div>
                                      </div>
                                  </div>
                              </div><!---->
                          </div>
                      </div>
                  </div>
              </div><!---->
          </fieldset>
          <fieldset>
              <fieldset>
                  <legend>
                      <h5 class="legend-title">NEW ENTRY / SELECTED ENTRY</h5>
                  </legend>
                  <p class="mt-2 mb-4">
                      <svg aria-hidden="true" focusable="false" data-prefix="far"
                          data-icon="exclamation-circle" role="img" xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512" class="svg-inline--fa fa-exclamation-circle fa-fw">
                          <path fill="currentColor"
                              d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm42-104c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42zm-81.37-211.401l6.8 136c.319 6.387 5.591 11.401 11.985 11.401h41.17c6.394 0 11.666-5.014 11.985-11.401l6.8-136c.343-6.854-5.122-12.599-11.985-12.599h-54.77c-6.863 0-12.328 5.745-11.985 12.599z"
                              class=""></path>
                      </svg> New: You are adding a new row to the table above.
                      Update: You are editing this entry.
                  </p>
                  <div class="row">
                      <div class="standard-form-group"><span><label for="ksd-dialysis-type"
                                  class="">Phase</label><select id="ksd-dialysis-type" class="form-control">
                                  <option value=""> Select... </option>
                                  <option value="1"> Frequent Nocturnal Dialysis </option>
                                  <option value="2"> Conventional Hemodialysis </option>
                                  <option value="3"> Home Intermittent Dialysis </option>
                                  <option value="4"> Home Short Hour Daily Hemodialysis </option>
                                  <option value="5"> Continuous Ambulatory Peritoneal Dialysis (CAPD)
                                  </option>
                                  <option value="6"> Automated Peritoneal Dialysis (APD) </option>
                                  <option value="7"> Other/Unknown </option>
                              </select><!----><!----></span></div>
                      <div class="standard-form-group"><span><label for="ksd-dialysis-type"
                                  class="">Status</label><select id="ksd-dialysis-type" class="form-control">
                                  <option value=""> Select... </option>
                                  <option value="1"> Frequent Nocturnal Dialysis </option>
                                  <option value="2"> Conventional Hemodialysis </option>
                                  <option value="3"> Home Intermittent Dialysis </option>
                                  <option value="4"> Home Short Hour Daily Hemodialysis </option>
                                  <option value="5"> Continuous Ambulatory Peritoneal Dialysis (CAPD)
                                  </option>
                                  <option value="6"> Automated Peritoneal Dialysis (APD) </option>
                                  <option value="7"> Other/Unknown </option>
                              </select><!----><!----></span></div>
                      <div class="standard-form-group"><span><label for="ksd-dialysis-type"
                                  class="">Reason</label><select id="ksd-dialysis-type" class="form-control">
                                  <option value=""> Select... </option>
                                  <option value="1"> Frequent Nocturnal Dialysis </option>
                                  <option value="2"> Conventional Hemodialysis </option>
                                  <option value="3"> Home Intermittent Dialysis </option>
                                  <option value="4"> Home Short Hour Daily Hemodialysis </option>
                                  <option value="5"> Continuous Ambulatory Peritoneal Dialysis (CAPD)
                                  </option>
                                  <option value="6"> Automated Peritoneal Dialysis (APD) </option>
                                  <option value="7"> Other/Unknown </option>
                              </select><!----><!----></span></div>
                      <div class="standard-form-group"><span><label for="ksd-dialysis-access-mode"
                                  class="">Step</label><select id="ksd-dialysis-access-mode"
                                  class="form-control">
                                  <option value=""> Select... </option>
                                  <option value="1"> Arteriovenous Fistula </option>
                                  <option value="2"> Arteriovenous Graft </option>
                                  <option value="3"> Central Venous Catheter </option>
                                  <option value="4"> Body Access for Peritoneal Dialysis </option>
                                  <option value="5"> Other </option>
                              </select><!----><!----></span></div>
                      <div class="row-break d-none d-xxxl-block"></div>
                      <div class="standard-form-group"><span><label for="state-effective-date"
                                  class="">Effective Date</label><!----><input id="state-effective-date"
                                  class="form-control" type="date"><!----></span></div>
                      <div class="standard-form-group"><span><label for="state-effective-time"
                                  class="">Effective Time (ET)</label><!----><input class="form-control"
                                  type="time" id="state-effective-time"><!----></span></div>
                  </div>
              </fieldset>
              <div class="save-toolbar card-footer action-row temp-saving row"><button type="button"
                      class="btn btn-success sec-submit"> Save Entry</button><!----></div>
          </fieldset>
        </template>
    </sub-section>
</template>




<script lang="ts">
import { Getter, State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import SubSection from "@/components/shared/SubSection.vue";
import SaveToolbar from '@/components/shared/SaveToolbar.vue';

@Component({
  components: {
    SubSection,
    SaveToolbar
  }
})
export default class JourneyStatus extends Vue {  
  title = 'Phase and Status';
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;
}
</script>

<style>


</style>
