<template>
  <form autocomplete="off">
    <!-- Recipient Clinical Transaction -->
    <clinical-transaction
      ref="clinicalTransaction"
      v-if="isClinicalTransactionEnabled"
      @loaded="(ref) => loaded(ref)"
      @handleErrors="handleErrors"
      @clear="clear"
      :canSave="true"
    />

    <!-- Organ Details -->
    <organ-details-section
      ref="organDetailsSection"
      :new-journey="newJourney"
      @loaded="(ref) => loaded(ref)"
      @handleErrors="handleErrors"
      @clear="clear"
      :canSave="isGroupWriteable('journey_referral')"
      @reloadLiverExceptionPoints="reloadLiverExceptionPoints"
      v-if="prototypeFeatureEnabled('proto_organ_details_section')"
    />

    <organ-journey-overview
      ref="organJourneyOverview"
      :new-journey="newJourney"
      @loaded="(ref) => loaded(ref)"
      @handleErrors="handleErrors"
      @clear="clear"
      :canSave="isGroupWriteable('journey_referral')"
      @reloadReferralSection="reloadReferralSection"
      v-if="prototypeFeatureEnabled('proto_show_organ_journey_overview')"
    />

    <notes-and-encounters 
      organ="Liver"
      v-if="prototypeFeatureEnabled('proto_notes_encounters')"
      ref="notesAndEncounters"/>

    <worksheet-and-tasklists
      organ="Liver"
      v-if="prototypeFeatureEnabled('proto_worksheets_tasklists')"
      ref="worksheetAndTasklists"/>

    <phase-status
      organ="Liver"
      v-if="prototypeFeatureEnabled('demo_phase_state')"
      title="Journey Status"
      localStorageRef ="LiverRecipientJourneyStatus"
      :columns="getColumns()"
      :tableData="getTableData()"/>
      ref="journeySection"/>

     <living-donation
      organ="Liver"
      v-if="prototypeFeatureEnabled('proto_living_donation')"
      ref="livingdonation"/>


    <tasklists
      organ="Liver"
      v-if="prototypeFeatureEnabled('demo_tasklists')"
      ref="checklists"
      title="Checklists"
      localStorageRef="liverRecipientChecklists"
      :tableData="getTableData('tasks')"/>

    <actions 
      organ="Liver" 
      v-if="prototypeFeatureEnabled('demo_actions')" 
      @clear="clear" 
      @handleErrors="handleErrors"
      ref="actions" 
      title="Actions" 
      localStorageRef="LiverRecipientActions" 
      :columns="getColumns('actions')"
      :tableData="getTableData('actions')" />

    <notes 
      organ="Liver" 
      v-if="prototypeFeatureEnabled('demo_notes')" 
      @clear="clear" 
      @handleErrors="handleErrors"
      ref="notes" 
      title="Patient Notes" 
      localStorageRef="LiverRecipientNotes" 
      localStorageDismissed="LiverRecipientDismissedNotes"
      :columns="getColumns('notes')"
      :tableData="getTableData('notes')" 
      :actionData="getTableData('actions')"/>
    
    <referral-section
      ref="referralSection"
      :new-journey="newJourney"
      @loaded="(ref) => loaded(ref)"
      @saving="saving"
      @handleErrors="handleErrors"
      @clear="clear"
      :canSave="isGroupWriteable('journey_referral')"
    />

    <!-- Consultation -->
    <consultation-section
      ref="consultationSection"
      :new-journey="newJourney"
      @loaded="(ref) => loaded(ref)"
      @handleErrors="handleErrors"
      @clear="clear"
      :canSave="isGroupWriteable('journey_decision')"
      v-if="groupExists('journey_decision') && isConsultationSectionEnabled && prototypeFeatureEnabled('demo_consultations')"
    />

    <!-- Medical Assessment -->
    <medical-assessment-section
      ref="medicalAssessmentSection"
      :new-journey="newJourney"
      @loaded="(ref) => loaded(ref)"
      @handleErrors="handleErrors"
      @clear="clear"
      :canSave="isGroupWriteable('journey_decision')"
      v-if="groupExists('journey_decision') && isMedicalAssessmentSectionEnabled && prototypeFeatureEnabled('demo_medical_assessment')"
    />

    <!-- Organ Specific Details -->
    <liver-specific-details
      ref="organSpecificDetailsSection"
      :new-journey="newJourney"
      @loaded="(ref) => loaded(ref)"
      @saving="saving"
      @handleErrors="handleErrors"
      @clear="clear"
      :canSave="isGroupWriteable('journey_medical')"
    />

    <!-- Donor Acceptability Criteria -->
    <donor-acceptability
      ref="donorAcceptabilitySection"
      :new-journey="newJourney"
      @loaded="(ref) => loaded(ref)"
      @handleErrors="handleErrors"
      @clear="clear"
      :canSave="isGroupWriteable('journey_medical')"
    />

    <!-- Waitlist -->
    <waitlist-section
      ref="waitlistSection"
      :new-journey="newJourney"
      @loaded="(ref) => loaded(ref)"
      @handleErrors="handleErrors"
      @clear="clear"
      @saving="saving"
      @reloadDonorAcceptabilityCriteria="reloadDonorAcceptabilityCriteria"
      @reloadOrganSpecificDetails="reloadOrganSpecificDetails"
      :canSave="isGroupWriteable('journey_waitlist')"
      @reloadLiverExceptionPoints="reloadLiverExceptionPoints"
    />

    <!-- Transplant Details -->
    <transplant-details-section
      ref="transplantDetailsSection"
      :new-journey="newJourney"
      @loaded="(ref) => loaded(ref)"
      @handleErrors="handleErrors"
      @clear="clear"
    />
    
    <!-- Post Transplant Follow Up -->
    <post-transplant-section
      ref="postTransplantSection"
      :new-journey="newJourney"
      @loaded="(ref) => loaded(ref)"
      @handleErrors="handleErrors"
      :canSave="isGroupWriteable('journey_post_transplant')"
    />
  </form>
</template>

<script lang="ts">

import { State, Getter } from 'vuex-class';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import { RecipientJourney } from '@/store/recipientJourney/types';
import ClinicalTransaction from '@/components/recipients/ClinicalTransaction.vue';
import DonorAcceptability from '@/components/organs/shared/DonorAcceptability.vue';
import LiverSpecificDetails from '@/components/organs/liver/LiverSpecificDetails.vue';
import OrganDetailsSection from '@/components/organs/shared/_OrganDetailsSection.vue';
import ReferralSection from '@/components/organs/shared/_ReferralSection.vue';
import ConsultationSection from '@/components/organs/shared/_ConsultationSection.vue';
import MedicalAssessmentSection from '@/components/organs/shared/_MedicalAssessmentSection.vue';
import WaitlistSection from '@/components/organs/shared/_WaitlistSection.vue';
import TransplantDetailsSection from '@/components/organs/shared/_TransplantDetailsSection.vue';
import PostTransplantSection from '@/components/organs/shared/_PostTransplantSection.vue';
import LivingDonation from '@/components/prototypes/organs/_LivingDonation.vue';
import NotesAndEncounters from '@/components/prototypes/organs/_NotesAndEncounters.vue';
import WorksheetAndTasklists from '@/components/prototypes/organs/_WorksheetAndTasklists.vue';
import { SystemModules } from '@/store/features/types';
import PhaseStatus from '@/components/prototypes/organs/_PhaseStatus.vue';
import Notes from '@/components/prototypes/organs/_Notes.vue';
import Actions from '@/components/prototypes/organs/_Actions.vue';
import Tasklists from '@/components/prototypes/organs/_Tasklists.vue';
import JourneyStatusRowsJson from '@/components/prototypes/json/Recipients/RecipientJourneyStatusData.json';
import JourneyStatusColumnsJson from '@/components/prototypes/json/Recipients/RecipientJourneyStatusColumns.json';
import ActionRowsJson from '@/components/prototypes/json/Recipients/RecipientActionsData.json';
import ActionColumnsJson from '@/components/prototypes/json/Recipients/RecipientActionsColumns.json';
import NotesRowsJson from '@/components/prototypes/json/Recipients/RecipientNotesData.json';
import NotesColumnsJson from '@/components/prototypes/json/Recipients/RecipientNotesColumns.json';
import TasklistDataJson from '@/components/prototypes/json/Recipients/RecipientTaskListData.json';
import OrganJourneyOverview from '@/components/prototypes/organs/_shared/_OrganJourneyOverview.vue';
@Component({
  components: {
    ClinicalTransaction,
    OrganDetailsSection,
    ReferralSection,
    ConsultationSection,
    MedicalAssessmentSection,
    WaitlistSection,
    TransplantDetailsSection,
    PostTransplantSection,
    DonorAcceptability,
    LiverSpecificDetails,
    NotesAndEncounters,
    WorksheetAndTasklists,
    LivingDonation, 
    PhaseStatus,
    Tasklists, 
    Actions,
    Notes,
    OrganJourneyOverview
  }
})
export default class Liver extends Vue {
  // State
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney|undefined;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;
  @Getter('groupExists', { namespace: 'validations' }) private groupExists!: (groupName: string) => boolean;
  @Getter("moduleEnabled", { namespace: "features" }) private moduleEnabled!: (module: string) => boolean;
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;

  // Props
  @Prop({ default: false }) newJourney!: boolean;

  getTableData(type: any) {
    switch (type) {
      case 'status':
        return JourneyStatusRowsJson;
      case 'actions':
        return ActionRowsJson;
      case 'notes':
        return NotesRowsJson;
      case 'tasks':
        return TasklistDataJson;
      default:
        // code block
    }
  }

  getColumns(type: any) {
    switch (type) {
      case 'status':
        return JourneyStatusColumnsJson;
      case 'actions':
        return ActionColumnsJson;
      case 'notes':
        return NotesColumnsJson;
      default:
        // code block
    }
  }


  // Is the Consultation Section module enabled?
  get isConsultationSectionEnabled(): boolean {
    return this.moduleEnabled(SystemModules.CONSULTATION_SECTION);
  }

  // Is the Medical Assessment Section module enabled?
  get isMedicalAssessmentSectionEnabled(): boolean {
    return this.moduleEnabled(SystemModules.MEDICAL_ASSESSMENT_SECTION);
  }

  // Is the Clinical Transaction module enabled?
  get isClinicalTransactionEnabled(): boolean {
    return this.moduleEnabled(SystemModules.CLINICAL_TRANSACTIONS);
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    const result = {};

    // Organ Details Section
    const organDetailsSection = this.$refs.organDetailsSection as OrganDetailsSection;
    if (organDetailsSection) {
      Object.assign(result, { ...organDetailsSection.idLookup });
    }

    // Referral Section
    const referralSection = this.$refs.referralSection as ReferralSection;
    if (referralSection) {
      Object.assign(result, { ...referralSection.idLookup() });
    }

    // Consultation Section
    const consultationSection = this.$refs.consultationSection as ConsultationSection;
    if (consultationSection) {
      Object.assign(result, { ...consultationSection.idLookup() });
    }

    // Medical Assessment Section
    const medicalAssessmentSection = this.$refs.medicalAssessmentSection as MedicalAssessmentSection;
    if (medicalAssessmentSection) {
      Object.assign(result, { ...medicalAssessmentSection.idLookup() });
    }

    // Organ Specific Details Section
    const organSpecificDetailsSection = this.$refs.organSpecificDetailsSection as LiverSpecificDetails;
    if (organSpecificDetailsSection) {
      Object.assign(result, { ...organSpecificDetailsSection.idLookup() });
    }

    // Donor Acceptability Section
    const donorAcceptabilitySection = this.$refs.donorAcceptabilitySection as DonorAcceptability;
    if (donorAcceptabilitySection) {
      Object.assign(result, { ...donorAcceptabilitySection.idLookup });
    }

    // Waitlist Section
    const waitlistSection = this.$refs.waitlistSection as WaitlistSection;
    if (waitlistSection) {
      Object.assign(result, { ...waitlistSection.idLookup() });
    }

    // Transplant Details Section
    const transplantDetailsSection = this.$refs.transplantDetailsSection as TransplantDetailsSection;
    if (transplantDetailsSection) {
      Object.assign(result, { ...transplantDetailsSection.idLookup() });
    }

    // Post Transplant Section
    const postTransplantSection = this.$refs.postTransplantSection as PostTransplantSection;
    if (postTransplantSection) {
      Object.assign(result, { ...postTransplantSection.idLookup() });
    }

    return result;
  }

  // PRIVATE

  // Loading processes unique to this component
  private mounted(): void {
    if (!this.newJourney) {
      // Fetch all recipient decision events
      this.$store.dispatch('recipients/loadDecisionEvents', this.recipientId);
    }
    // Collect all sections we're loading
    const sectionsToLoad: string[] = Array.from(Object.keys(this.$refs));
    // Emit to parent all sections we're loading
    this.$emit('sectionsToLoad', sectionsToLoad);
  }
  
  // Emit a loaded event with the component name
  private loaded(ref: string): void {
    this.$emit('loaded', ref);
  }
  
  // Emit event to parent so it can handle validations
  private handleErrors(errors: any) {
    this.$emit('handleErrors', errors);
  }

  // Emit event to parent so it can handle clearing validations when saving
  private saving(formReference: string) {
    this.$emit('saving', formReference);
  }

  // Emit event to parent so it can handle clearing validations when saving
  private clear() {
    this.$emit('clear');
  }

  private reloadLiverExceptionPoints() {
    const liverSpecificDetails = this.$refs.organSpecificDetailsSection as unknown as LiverSpecificDetails;
    liverSpecificDetails.reloadLiverExceptionPoints();
  }

  // initialize the donor acceptability criteria section
  private reloadDonorAcceptabilityCriteria() {
    const donorAcceptabilitySection = this.$refs.donorAcceptabilitySection as unknown as DonorAcceptability;
    if (donorAcceptabilitySection) donorAcceptabilitySection.initializeForm();
  }

  // initialize the organ Specific Details Section
  private reloadOrganSpecificDetails() {
    const organSpecificDetailsSection = this.$refs.organSpecificDetailsSection as LiverSpecificDetails;
    if (organSpecificDetailsSection) organSpecificDetailsSection.initializeForm();
  }

  // Reload the Referral Section (Details and Decision) if related form has saved
  private reloadReferralSection(): void {
    const referralSection = this.$refs.referralSection as ReferralSection;
    if (referralSection) referralSection.initializeForm();
  }
}
</script>
