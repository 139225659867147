<template>
  <div class="row">
    <div class="col-sm-12 contact-information-section">
      <sub-section
        :ref="`${fieldID}-contact-information-section`"
        :showDisabledSave="status == 'archived' ||parentDisabled"
        :sub-section-id="`${fieldID}-contact-information-form`"
        saveButton="true"
        :class="enforce_line"
        :divider="divider"
        :save-button-text="saveButtonText"
        @save="uploadDocument()"
        :hideTitle="hideTitle"
        :showArchivedButton="showArchivedButton"
        :archivedButtonText="status == 'active' ? 'Archive' : 'Restore'"
        :buttonClass="buttonClass"
        @archive="archive"
        :showCancelButton="showCancelButton"
        cancelButtonText="Cancel"
        @cancel="clearForm"
      >
        <template v-slot:contents>
          <fieldset>
            <legend>
              <h5 class="legend-title">
                {{title}}
              </h5>
            </legend>
            <div class="row">
              <div class="standard-form-group">
                <select-input
                  rules="required"
                  select-id="addressCategory"
                  name="Category"
                  validation-id="addressCategory"
                  v-model="category"
                  :disabled="status == 'archived' || parentDisabled"
                  :options="categoryOptions"
                  @change="setContactType($event)"
                />
              </div>
            </div>
            <div class="row">
              <div
                class="standard-form-group"
                v-if="contactType !== 'patient_portal'"
              >
                <text-input
                  inputId="contactinfo-phonenumber"
                  :rules="{
                    required: getDetailsLabelNameRule,
                    regex: getRegex(),
                  }"
                  :disabled="!detailsLabelName || status == 'archived' || parentDisabled"
                  :name="detailsLabelName"
                  :mask="contactType == 'phone' ? getTelephoneMask : ''"
                  v-model="detailsInput"
                />
              </div>

              <template v-else>
                <select-other-input
                  select-id="contactInfo_portal_Patient"
                  ruleKey="contactInfo.portal_Patient"
                  :name="$t('Patient Portal Platform')"
                  validationId="portal_Patient"
                  v-model="portalValue"
                  :rules="`required_if_filled:${category == '5'}`"
                  :options="portalOptions"
                  @change="clearPortalOther"
                  :disabled="status == 'archived' || parentDisabled"
                  :otherTitle="$t('Patient Portal Platform - Other')"
                  colStyling="standard-form-group-with-other"
                >
                  <template v-slot:other>
                    <text-input
                      input-id="contactInfo_portal_Patient_other"
                      :name="$t('Patient Portal Platform - Other')"
                      ruleKey="contactInfo_portal_Patient_other"
                      v-model="portalOther"
                      rules="required"
                    />
                  </template>
                </select-other-input>
              </template>

              <div class="standard-form-group-small-checkbox">
                <checkbox-input
                  name="Preferred?"
                  :disabled="status == 'archived' || parentDisabled"
                  labelName="Preferred?"
                  :inputId="`${fieldID}-contact-preferred`"
                  v-model="isPreferred"
                  label="Yes"
                />
              </div>
            </div>
            <div class="row">
              <div
                class="standard-form-group form-group-checkbox"
                v-if="contactType === 'phone'"
              >
                <label>Okay to</label>
                <div class="row">
                  <div class="col-12">
                    <checkbox-input
                      name="okay-call"
                      hideLabel="true"
                      labelName="Call (Voice)"
                      inputId="okay-call"
                      v-model="okayCall"
                      label="Call (Voice)"
                      :disabled="status == 'archived' || parentDisabled"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <checkbox-input
                      name="okay-voicemail"
                      hideLabel="true"
                      labelName="Leave Message (Voicemail)"
                      inputId="okay-voicemail"
                      v-model="okayVoicemail"
                      label="Leave Message (Voicemail)"
                      :disabled="status == 'archived' || parentDisabled"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <checkbox-input
                      name="okay-text"
                      hideLabel="true"
                      labelName="Text (SMS)"
                      inputId="okay-text"
                      v-model="okayText"
                      label="Text (SMS)"
                      :disabled="status == 'archived' || parentDisabled"
                    />
                  </div>
                </div>
              </div>
              <div class="standard-form-group">
                <select-input
                  rules="required"
                  select-id="responsiveness"
                  name="Responsiveness"
                  validation-id="responsiveness"
                  v-model="responsiveness"
                  :disabled="status == 'archived' || parentDisabled "
                  :options="responsivenessOptions"
                />
              </div>
              <div class="standard-form-group-large">
                <text-area-input
                  input-id="additional-information"
                  name="Comments"
                  rows="2"
                  :disabled="status == 'archived' || parentDisabled"
                  :showToolTip="true"
                  :toolTipText="getCommentGuidingText()"
                  v-model="comments"
                />
              </div>
            </div>
          </fieldset>
        </template>
      </sub-section>
    </div>
  </div>
</template>


<i18n src="./_locales/AddressComponent.json"></i18n>

<script lang="ts">
import { Getter, State } from "vuex-class";
import { mixins } from "vue-class-component";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import CardSection from "@/components/shared/CardSection.vue";
import SaveToolbar from "@/components/shared/SaveToolbar.vue";
import { VueGoodTable } from "vue-good-table";
import SelectInput from "@/components/shared/SelectInput.vue";
import CheckboxInput from "@/components/shared/CheckboxInput.vue";
import { GenericCodeValue } from "@/store/types";
import TextInput from "@/components/shared/TextInput.vue";
import TextAreaInput from "@/components/shared/TextAreaInput.vue";
import SubSection from "@/components/shared/SubSection.vue";
import SelectOtherInput from "@/components/shared/SelectOtherInput.vue";

import { Recipient } from "@/store/recipients/types";
import { UserDetails } from "@/store/userAccounts/types";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import NumberInput from "@/components/shared/NumberInput.vue";

import BooleanRadioInput from "@/components/shared/BooleanRadioInput.vue";

  import {
    SaveProvider,
    TableConfig
  } from '@/types';

interface TableRow {
  id: string;
  category?: string;

  date?: string;
  fileName?: string;

  fileType?: string;
  description?: string;
  uploadedBy?: string;
}

export interface FollowUpTypeOption extends GenericCodeValue {
  hasChecklist: boolean;
  checklistReference?: string;
}

@Component({
  components: {
    CardSection,
    SaveToolbar,
    SubSection,
    SelectInput,
    TextInput,
    CheckboxInput,
    TextAreaInput,
    VueGoodTable,
    SelectOtherInput,
    BooleanRadioInput,
  },
})
export default class RecipientDocuments extends mixins(DateUtilsMixin) {
  @State((state) => state.recipients.selectedRecipient) recipient!: Recipient;
  @State((state) => state.userAccounts.selected) private selected!: UserDetails;
  @State((state) => state.pageState.currentPage.journeyStatus) editState!: any;

  @Getter("getUser", {
    namespace: "users",
  })
  private getUser!: any;
  @Getter("getTelephoneMask", { namespace: "utilities" })
  getTelephoneMask!: string;

  @Prop({
    default: null,
  })
  title!: string;

  @Prop({ default: null }) emptyForm!: any;
  @Prop({ default: null }) selectedItem!: any;
  @Prop({ default: null }) saveButtonText!: any;
  @Prop({ default: null }) showCancelButton!: any;

  @Prop({ default: null }) categoryOptions!: any;
  @Prop({ default: null }) portalOptions!: any;
  @Prop({ default: null }) responsivenessOptions!: any;

  @Prop({ default: null }) enforce_line!: any;
  @Prop({ default: null }) divider!: any;
  @Prop({ default: null }) buttonClass!: any;
  @Prop({ default: null }) hideTitle!: any;
  @Prop({ default: null }) fieldID!: any;

  @Prop({ default: null }) showArchivedButton!: any;

  @Prop({default: null}) parentDisabled!: any;

  @Watch("emptyForm")
  private onClearForm(): void {
    this.$emit("cancel");
    this.status = "active";
    this.editstate = false;
    this.clearForm();
  }

  

  @Watch("selectedItem")
  private onSelectedItem(): void {
    this.editSelected();
  }

  get getDetailsLabelNameRule(): any {
    return this.detailsLabelName ? "required" : null;
  }

  private id = "";
  private status = "active";
  private isPreferred = false;
  private category = "";
  private comments = "";
  private responsiveness = "";
  private portalOther = "";
  private portalValue = "";
  private okayCall = "";
  private okayVoicemail = "";
  private okayText = "";
  private detailsInput = null;
  private contactType = "";
  private editstate = false;
  private detailsLabelName = null;

  getRegex(): any {
    const type = this.contactType;
    if (!type) {
      return false;
    }
    if (type == "phone") {
      return "phone_number";
    } else if (type == "email") {
      return "email";
    }
  }

  public getCommentGuidingText(): any {
    const type = this.contactType;

    if (!type) {
      return 'Enter additional detail about the patient';
    }

    let result;
    switch (type) {
      case "telehealth":
        result =
          "Enter additional detail about the patient's device access (own or borrowed, device type), internet access, and proficiency using telehealth.";
        break;
      case "patient_portal":
        result =
          "Enter additional detail about the patient's use of a patient portal.";
        break;
      }
    return result;
  }

  public getCategoryValue(item: any, data: any) {
    return data[item as any].value;
  }

  public getLabelName(type: any): any {
    let result;

    if (!type) {
      return false;
    }

    switch (type) {
      case "phone":
        result = "Phone Number";
        break;
      case "email":
        result = "Email Address";
        break;
      case "telehealth":
        result = "Telehealth Platform";
        break;
    }
    return result;
  }

  public setContactType(item: any): any {
    const option = this.categoryOptions[item as any];

    if (!option) {
      this.detailsLabelName = null;
      return false;
    }

    this.contactType = option.type;
    this.detailsLabelName = this.getLabelName(option.type);
  }

  public clearPortalOther() {
    this.portalOther = "";
  }

  private editSelected() {
    let row = this.selectedItem;
    if (row) {
      this.category = row.category;
      this.setContactType(row.category);
      this.id = row.id;
      this.detailsInput = row.detailsInput;
      this.okayCall = row.okayCall;
      this.okayVoicemail = row.okayVoicemail;
      this.okayText = row.okayText;
      this.responsiveness = row.responsiveness;
      this.comments = row.comments;
      this.portalValue = row.portalValue;
      this.portalOther = row.portalOther;
      this.isPreferred = row.preferred;
      this.status = row.status;
    }
  }

  public getOther(selectValue: any, selectOther: any) {
    if (selectValue == "1") {
      return selectOther;
    } else {
      return this.portalOptions[selectValue as any].value;
    }
  }

  public getOkayTo(contact: any, contactType: any) {
    const type = this.categoryOptions[contact.category as any];

    if (!type) {
      return false;
    }

    let result;

    if (type.type == "phone") {
      if (contactType == "call") {
        result = contact.okayCall ? "Yes" : "No";
      } else if (contactType == "voicemail") {
        result = contact.okayVoicemail ? "Yes" : "No";
      } else if (contactType == "text") {
        result = contact.okayText ? "Yes" : "No";
      }
    } else {
      result = "-";
    }

    return result;
  }

  public getContactDetails(contact: any) {
    const type = this.categoryOptions[contact.category as any];

    if (!type) {
      return false;
    }

    let result;

    switch (type.type) {
      case "phone":
        result = contact.detailsInput;
        break;
      case "email":
        result = contact.detailsInput;
        break;
      case "telehealth":
        result = contact.detailsInput;
        break;
      case "patient_portal":
        result = this.getOther(contact.portalValue, contact.portalOther);
    }

    return result;

        
  }

  public resetSaveToolbar(): void {
    // Refer to the save toolbar that handles this page
    const saveToolbar = this.$refs[`${this.fieldID}-contact-information-section`] as unknown as SaveProvider;

    //const saveToolbar = (this.$refs.recipientCareGiver as SubSection).$refs[
    ///'save-recipient-careGiver'] as SaveToolbar;
    saveToolbar.resetSaveToolbar();
  }

  get getUserFullName(): string | undefined {
    let user = this.getUser;
    return user.name;
  }

  public getOptionValue(item: any, options: any, other: any) {
    if (other) {
      return other;
    } else {
      return options[item as any].value;
    }
  }

  public clearForm(): any {
    this.contactType = "";
    this.category = "";
    this.detailsInput = null;
    this.okayCall = "";
    this.okayVoicemail = "";
    this.okayText = "";
    this.responsiveness = "";
    this.comments = "";
    this.portalValue = "";
    this.portalOther = "";
    this.isPreferred = false;
    this.status = 'active';
    this.editstate = false;
    this.$emit("cancel");
    this.resetSaveToolbar();
  }

  rowStyleClass(row: any) {
    return row.preferred === true ? "tr-primary-row tr-link" : "tr-link";
  }

  public archive(): any {
    let message = "";
    if (this.selectedItem.status == "active") {
      // this.selectedItem.status = 'archived';
      message = "Archive";
    } else {
      // this.selectedItem.status = 'active';
      message = "Restore";
    }
    this.$emit("archive", this.selectedItem, message);
  }

  public uploadDocument(): any {
    if (this.selectedItem.length == 0) {
      const payload: any = {
        id: Math.random().toString(16).slice(2),
        date: new Date().toLocaleDateString("en-CA"),
        status: this.status,
        category: this.category,
        detailsInput: this.detailsInput,
        okayCall: this.okayCall,
        okayVoicemail: this.okayVoicemail,
        okayText: this.okayText,
        responsiveness: this.responsiveness,
        comments: this.comments,
        portalValue: this.portalValue,
        portalOther: this.portalOther,
        preferred: this.isPreferred,
      };

      this.$emit("save", "isNew", payload);
    } else {
      this.selectedItem.date = new Date().toLocaleDateString("en-CA");
      this.selectedItem.category = this.category;
      this.selectedItem.detailsInput = this.detailsInput;
      this.selectedItem.okayCall = this.okayCall;
      this.selectedItem.okayVoicemail = this.okayVoicemail;
      this.selectedItem.okayText = this.okayText;
      this.selectedItem.responsiveness = this.responsiveness;
      this.selectedItem.comments = this.comments;
      this.selectedItem.portalValue = this.portalValue;
      this.selectedItem.portalOther = this.portalOther;
      this.selectedItem.preferred = this.isPreferred;
      this.selectedItem.status = this.status;

      this.$emit("save", "isEdit", this.selectedItem);
    }

    this.clearForm();
    this.$emit("clear");
  }
}
</script>

<style>
</style>
