<template>
  <div class="nav-block-wrap">
    <ul class="nav nav-block flex-column" :class="{ active: active }">
      <li class="nav-item" >
        <router-link class="nav-link no-scroll open" :to="{ name: 'edit-deceased-donor', params: { id: clientId }, hash: '#summary' }" v-if="clientId">
          <font-awesome-icon :icon="['fas', (show ? 'chevron-down' : 'chevron-right')]" class="nav-caret" fixed-width @click.prevent="toggleNav" />
          {{ $t('profile') }}
        </router-link>
        <a href="#" class="nav-link no-scroll open" :to="{ name: getDonorsUrl, hash: '#summary'}" v-else>
          <font-awesome-icon :icon="['fas', (show ? 'chevron-down' : 'chevron-right')]" class="nav-caret" fixed-width @click.prevent="toggleNav" />
          {{ $t('profile') }}
        </a>
      </li>
      <ul class="nav flex-column" v-if="show">
        <li class="nav-item" @click="toggleSideNav" v-if="showDonorDataHistory">
          <a class="nav-link" :href="getUrl('donor-data-history')">
            {{ $t(`donor_data_history`) }}
          </a>
        </li>
        <li class="nav-item" @click="toggleSideNav" v-if="!prototypeFeatureEnabled('demo_hide_referral_information')">
          <a class="nav-link" 
            :href="getUrl('referral-information')">
            {{ $t(`referral_information`) }}
          </a>
        </li>
        <li class="nav-item" @click="toggleSideNav">
          <a class="nav-link" 
            :href="getUrl('donation-information')">
            {{ $t(`donor_information`) }}
          </a>
        </li>
        <li class="nav-item" @click="toggleSideNav">
          <a class="nav-link" 
            :href="getUrl('clinical-information')">
            {{ $t(`clinical_information`) }}
          </a>
        </li>
        <li class="nav-item" @click="toggleSideNav" v-if="prototypeFeatureEnabled('demo_offers')">
          <a class="nav-link" 
            :href="getUrl('offers-made')">
            {{ $t('Offers')}}
          </a>
        </li>
        <li class="nav-item" @click="toggleSideNav" v-if="(groupExists('donor_attachments') && !newDonor) && !prototypeFeatureEnabled('demo_donor_documents')">
          <a class="nav-link" 
            :href="getUrl('donor-documents')">
            {{ $t(`donor_documents`) }}
          </a>
        </li>
        <li class="nav-item" @click="toggleSideNav">
          <a class="nav-link" 
            :href="getUrl('virology')">
            {{ $t('serology_results')}}
          </a>
        </li>
        <li class="nav-item" v-if="showOrganPackagingForms" @click="toggleSideNav">
          <a class="nav-link" 
            :href="getUrl('organ-packaging-forms')">
            {{ $t('organ-packaging-forms')}}
          </a>
        </li>
        <li class="nav-item" v-if="(groupExists('donor_recovery') && !newDonor || groupExists('donor_recovery_date') && !newDonor) && !prototypeFeatureEnabled('demo_hide_recovery_details')"  @click="toggleSideNav">
          <a class="nav-link" 
            :href="getUrl('recovery-details')">
            {{ $t('recovery-details')}}
          </a>
        </li>
        <li class="nav-item" v-if="showMonitorRecoveryOutcomes && !newDonor" @click="toggleSideNav">
          <a class="nav-link" 
            :href="getUrl('monitor-recovery-outcomes')">
            {{ $t('monitor-recovery-outcomes')}}
          </a>
        </li>
        <li class="nav-item" v-if="showExdDisposition  && !newDonor" @click="toggleSideNav">
          <a class="nav-link" 
            :href="getUrl('exceptional-distribution-disposition')">
            {{ $t('exceptional-distribution-disposition')}}
          </a>
        </li>
      </ul>
    </ul>
  </div>
</template>

<i18n src="../_locales/SideNav.json"></i18n>
<i18n src="../_locales/commonPatientShared.json"></i18n>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';
import { DeceasedDonor, DeceasedDonorAllocationSummary } from '@/store/deceasedDonors/types';
import { DONOR_DATA_HISTORY_ENABLED } from "@/store/administration/types";

@Component
export default class SelectedDeceasedDonorProfileLinks extends Vue {
  @State(state => state.deceasedDonors.selected) private donor!: DeceasedDonor;

  @Getter('isTransplantCoordinator', { namespace: 'users' }) private isTransplantCoordinator!: boolean;
  @Getter('clientId', { namespace: 'deceasedDonors' }) clientId!: string|undefined;
  @Getter('getDonorsUrl', { namespace: 'users' }) private getDonorsUrl!: string;
  @Getter('groupExists', { namespace: 'validations' }) private groupExists!: (groupName: string) => boolean;
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;
  @Getter('donorOrganPackagingForms', { namespace: 'deceasedDonors'}) private donorOrganPackagingForms!: any[];
  @Getter('selectedDonorConsentedOrganList', { namespace: 'deceasedDonors'}) private selectedDonorConsentedOrganList!: DeceasedDonorAllocationSummary[];
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;

  // @Prop({default: true}) open!: boolean;
  @Prop({default: false}) active!: boolean;
  @Prop({default: false}) newDonor!: boolean;

  
  /**
   * Create a return to profile link
   *
   * @returns {string} client_id as a string
   */

  public show = true;

  public toggleNav() {
    this.show = !this.show;
  }

  get profileReturnLink(): string {
    return this.clientId === '' ? `/donors` : `/donors/${this.clientId}`;
  }

  // Determine whether or not to show the Donor Data History link
  get showDonorDataHistory(): boolean {
    return DONOR_DATA_HISTORY_ENABLED && !this.newDonor && !this.isTransplantCoordinator;
  }

  // Determine whether or not to show the Exceptional Distribution Disposition link
  get showExdDisposition(): boolean {
    return !!this.donor?.indicators?.exceptional_distribution;
  }

  // Determine whether or not to show the Monitor Recovery Outcomes section
  // - by having the group monitor_recovery_outcomes & using a consented organ
  get showMonitorRecoveryOutcomes(): boolean {
    if (this.prototypeFeatureEnabled('demo_hide_donor_summary_table')) return false;

    const hasRecoveryOutcomes = this.groupExists('donor_recovery_outcomes');
    const hasConsentedOrgan = (this.selectedDonorConsentedOrganList || []).length > 0;
    return hasConsentedOrgan && hasRecoveryOutcomes;
  }

  public toggleSideNav(): void {
    if (window.innerWidth < 992) { 
       this.$emit("toggle");
    }
  }

  public getUrl(hash: string): string {
    hash = `#${hash}`;
    if(this.active) {
      return hash;
    } else {
      // We are fine not checking for the existence of client_id as for new recipients we only ever show this in active mode
      const location = this.$router.resolve({ name: 'edit-deceased-donor', params: { id: (this.clientId || '') }, hash: hash });
      return location.href;
      
    }
  }

  get showOrganPackagingForms(): boolean {
    if (this.prototypeFeatureEnabled('demo_hide_organ_packaging_forms')) return false;

    return (this.donorOrganPackagingForms.length > 0 && (this.groupExists('organ_packaging') || this.groupExists('organ_packaging_odsif')) && !this.newDonor);
  }
}
</script>
