import { render, staticRenderFns } from "./DialysisReport.vue?vue&type=template&id=c45d5f4c"
import script from "./DialysisReport.vue?vue&type=script&lang=ts"
export * from "./DialysisReport.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./_locales/common.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2Freports%2FDialysisReport.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "./_locales/DialysisReport.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2Freports%2FDialysisReport.vue&external"
if (typeof block1 === 'function') block1(component)

export default component.exports