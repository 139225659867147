<template>
    <sub-section
    :title='title'
    sub-section-id="recipients-uploaded-files"
  >
    <template v-slot:contents>
    <div class="row">
      <!-- Uploaded Files -->
      <div class="col-sm-12">
        <fieldset>
          <div class="row" v-for="(medicalCode) in Object.values(medicalList)" :key="medicalCode.md_code">
            <div class="col-sm-12 col-md-6 col-lg-4 viralogy-section" v-for="(item, index)  in medicalCode" :key="index" >
              <div class="check-markers">
                <div class="form-check form-check-inline" @click="addMedicalHistory(item.md_code)">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    :checked="checkIndex(item.md_code)"
                  />
                  <label class="form-check-label form-check-label-main">{{ item.md_label }}</label>
                </div>

                <div class="additional_info pb-2" v-if="checkIndex(item.md_code)" >
                  
                  <div v-for="(option, index) in (item.additional_info)" :key="index" >
  
                    <div v-if="option.type == 'date'" class="mt-1">
                      <date-input
                       :input-id="`${item.md_name}_${option.value}`"
                       :name="option.name"
                       rules="required"
                       v-model="option.value" />
                    </div>
                 
                    <div v-else class="mt-1">
                      <text-area-input
                        :input-id="`${item.md_name}_${option.value}`"
                        :name="option.name"
                        v-model="option.value"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <save-toolbar
            class="save-toolbar action-row temp-saving"
            buttonClass="btn btn-success sec-submit"
            label="Save Medical History"
          />
        </fieldset>

      </div>
    </div>
    </template>

  </sub-section>
</template>


<script lang="ts">

import { Component, Vue } from 'vue-property-decorator';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import LivingDonorMedicalHistoryJson from './json/LivingDonorMedicalHistory.json';
import DateInput from '@/components/shared/DateInput.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';

@Component({
  components: {
    SubSection,
    SaveToolbar,
    SelectInput,
    DateInput,
    TextAreaInput
  }
})
export default class LivingDonorMedicalHistory extends Vue {  
  title = 'Medical History';
  private previous_discussion = '';
  private medicalList = LivingDonorMedicalHistoryJson;
  private selectedMedicalHistory :any = []
  private getOptions() {
    let options :any  = [];
    Object.values(this.medicalList).map((items: any) => {
      items.map((item: any) => {
        options.push({code: item.md_code, value: item.md_label });
      });
    
    });
    return options;
  }

  private addMedicalHistory(value: any) {
    if (!this.selectedMedicalHistory.some((e : any) => e.id === value)) {
      
      this.selectedMedicalHistory.push({id: value});
    } else {
    const pos = this.selectedMedicalHistory.findIndex((e : any) => e.id === value);
    if (pos >= 0) {
      this.selectedMedicalHistory.splice(pos, 1);
      }
    }
  }

  private checkIndex(value: any) {
    return this.selectedMedicalHistory.some((e : any) => e.id === value);
  }


  
}
</script>

<style>


</style>
