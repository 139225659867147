<template>
  <card-section
    section-id="living_donation"
    :isAccordion="prototypeFeatureEnabled('proto_ability_to_expand_collapse')"
  >
    <template v-slot:header>
      {{ title }}
    </template>
    <template v-slot:body>
      <div>
        <fieldset id="living_donation">
          <legend >
            <h4 class="legend-title">
              <span>{{ title }}</span>
            </h4>
          </legend>
        </fieldset>
      </div>
      <div class="row">
        <div class="col-sm-12">
        </div>
      </div>         
      <fieldset>
        <div class="row">
           <div class="standard-form-group-large">
            <select-input
              select-id="recipientAttachment-category"
              name='Previous discussion about Living Donor transplantation'
              :options="categoryOptions"
              v-model="previous_discussion"
            >
            </select-input>
          </div>

        </div>
      </fieldset>
      <div class="row">
        <div class="col-sm-12">

          <save-toolbar
            class="save-toolbar action-row temp-saving"
            buttonClass="btn btn-success sec-submit"
            label="Save Living Donation"
            @save="performSave"
          />
        </div>
      </div>
      
    </template>
  </card-section>
</template>




<script lang="ts">
import { Getter, State } from 'vuex-class';
import { Component, Vue, Prop } from 'vue-property-decorator';
import CardSection from "@/components/shared/CardSection.vue";
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import SelectInput from '@/components/shared/SelectInput.vue';

@Component({
  components: {
    CardSection,
    SaveToolbar,
    SelectInput
  }
})
export default class LivingDonation extends Vue {  
  @Prop({ default: null }) organ!: string|null;
  title = 'Living Donation';
  private previous_discussion = '';
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;
  private categoryOptions = [{code: 0, value: 'Yes'}, {code: 1, value: 'No'},{code: 2, value: 'Unknown'}];
  

  private mounted(): void {
    let items = localStorage.getItem(`${this.organ}livingDonationStorage`);

    if (items) {
      let parsed = JSON.parse(items);
      this.previous_discussion = parsed.previous_discussion;
    }
  
  }

  public performSave(): void {
    const livingDonationStorage = {
    "previous_discussion":  this.previous_discussion,
  };

    localStorage.setItem(`${this.organ}livingDonationStorage`, JSON.stringify(livingDonationStorage));
  }
}
</script>

<style>


</style>
