import { render, staticRenderFns } from "./StickySummary.vue?vue&type=template&id=22d36570"
import script from "./StickySummary.vue?vue&type=script&lang=ts"
export * from "./StickySummary.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./_locales/MainMenu.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fshared%2FStickySummary.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports