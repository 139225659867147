<template>
  <sticky-summary v-if="recipient">
    <template v-slot:summaryContent>
      <ul class="donor-summary">
        <li class="list-inline-item">
          <span>{{$t('name')}}: <b>{{ recipientDisplayName }}</b></span>
        </li>
        <li class="list-inline-item">
          <span>{{$t('client_id')}}: <b>{{ recipient.client_id }}</b></span>
        </li>
        <li class="list-inline-item">
          <span>{{$t('ctr_id')}}: <b>{{ ctrIdDisplayValue }}</b></span>
        </li>
        <li class="list-inline-item">
          <span>{{$t('age')}}: <b>{{ calculatedAge }}</b></span>
        </li>
        <li class="list-inline-item">
          <span>{{$t('sex')}}: <b>{{ recipient.patient_profile.sex || '-' }}</b></span>
        </li>
        <li class="list-inline-item">
          <span>{{$t('abo')}}: <b>{{ recipient.diagnostics.blood.type }}</b></span>
       </li>
      </ul>
      <ul class="organ-summary">
        <li v-for="(journey, idx) in activeJourneys" :key="idx" class="list-inline-item " :class="$route.params.organ_id == journey._id.$oid ? 'active' : ''">
          <router-link :to="{ name: getUrlForEditOrgan, params: { organ_id: journey._id.$oid } }" class="nav-link">
            <organ-icon :organ-code="journey.organ_code" :summary-bar-icon="true" />
            {{ $t(journeyName(journey.organ_code,journey._id.$oid)) }}
          </router-link>
          ({{ hospitalAbbreviation(journey) }})
        </li>
     </ul>
    </template>
  </sticky-summary>
</template>

<i18n src="../_locales/Organs.json"></i18n>
<i18n src="@/components/recipients/_locales/RecipientStickySummary.json"></i18n>

<script lang="ts">
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Component, Vue, Prop } from 'vue-property-decorator';
import StickySummary from '@/components/shared/StickySummary.vue';
import { Getter, State } from 'vuex-class';
import { Recipient } from '@/store/recipients/types';
import { Hospital } from '@/store/hospitals/types';
import { calculateAge } from '@/utils';
import { RecipientJourney } from '@/store/recipientJourney/types';
import OrganIcon from '@/components/shared/OrganIcon.vue';

@Component({
  components: {
    StickySummary,
    OrganIcon
  }
})
export default class RecipientStickySummary extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;

  // Getters
  @Getter('recipientDisplayName', { namespace: 'recipients' }) recipientDisplayName!: string;
  @Getter('organName', { namespace: 'lookups'}) organName!: (organCode?: number) => string;
  @Getter('getJourneysByStatus', { namespace: 'recipients' }) filterJourneys!: (status: string) => RecipientJourney[];
  @Getter('getHospitalAbbreviation', { namespace: 'hospitals' }) getHospitalAbbreviation!: (hospitalCode?: string|null) => string|null;
  @Getter("isOopRecipient", { namespace: 'recipients' }) isOopRecipient!: boolean;
  @Getter("journeyName", { namespace: "recipients" }) journeyName!: (organCode?: number, journeyId?: string) => string;

  /**
   * Return all active journeys
   *
   * @returns {RecipientJourney[]} all active journeys
   */
  get activeJourneys(): any {
    return this.filterJourneys('active');
  }

  get getUrlForEditOrgan(): any {
    return this.isOopRecipient ? 'edit-organ-oop' : 'edit-organ';
  }

  get ctrIdDisplayValue(){
    return this.recipient?.patient_profile?.ctr?.national_recipient_id || '-';
  }

  /**
   * Return hospital abbreviation by journey.
   *
   * @returns {string} hospital abbreviation
   */
  public hospitalAbbreviation(journey: RecipientJourney): any {
    if(!journey.transplant_program?.transplant_hospital_id) {
      return '-';
    }
    return this.getHospitalAbbreviation(journey.transplant_program?.transplant_hospital_id?.$oid);
  }

  public mounted(){
    const dispatchMethod = this.isOopRecipient ? 'recipients/getOop' : 'recipients/get';
    this.$store.dispatch(dispatchMethod, this.recipient.client_id);
    this.$store.dispatch('hospitals/load');
  }
  
  get calculatedAge() {
    const recipientDoB = this.recipient.patient_profile?.birth?.date || null;
    const recipientDoD =
      this.recipient.patient_profile && this.recipient.death
        ? this.recipient.death.death_date
        : null;
    // I have a birth and death date
    if (recipientDoB && recipientDoD) {
      return calculateAge(recipientDoB, this.parseDateUi(recipientDoD));
    }
    // I have a birth date only
    if (recipientDoB && !recipientDoD) {
      return calculateAge(recipientDoB);
    }
    // I have age only
    if (this.recipient.patient_profile && this.recipient.patient_profile.age) {
      return this.recipient.patient_profile.age;
    }
    return null;
  }
}
</script>
