<template>

      <div class="row">
        <div class="col-sm-12">
          <sub-section 
            sub-section-id="insurance_section"
            :table-config="tableConfig"
            hasCustomClasses="vgt-table table table-bordered table-hover table-has-actions"
            tabbableValue="Last Updated"
            scrollToID="recipient-insurance"
            @table-row-click="editSelected"
            @table-create-row="clearForm"
          
            :row-style-class="rowStyleClass"
            @on-row-click="editSelected"
            >
            <template v-slot:actions>
              <filter-component :displayFilter="displayFilter" @updateFilter="updateFilter" fieldID='Insurance' :setFilters='setFilters' :archivedFilter="true"/>
            </template>
          </sub-section>
                <modal-section modalId="insuranceArchiveModal" ref="insuranceArchiveModal" class="modal-sticky-header"
        :centered="true" :closeButton="false" size="md" :wide="false">
        <template v-slot:body>
          {{`${modalMode} this item`}}

        </template>
        <template v-slot:footer>
          <div class="modal-footer-body">
            <button type="button" data-dismiss="modal" @click="resetArchive()" class="btn btn-secondary">
              {{ $t('Cancel') }}
            </button>
            <a class="btn btn-success" data-dismiss="modal" @click="archiveDocument(modalMode)">
              {{ $t('OK') }}
            </a>
          </div>
        </template>
      </modal-section>
      <sub-section 
        ref="recipientInsurance"
        sub-section-id="recipient-insurance" 
        :saveButton="true"  :showDisabledSave="status == 'archived'"
        :save-button-text="editstate ? `${$t('Update insurance')}` : `${$t('Save insurance')}`"
        @save="uploadDocument()" showCancelButton="true" cancelButtonText="Cancel" @cancel="clearForm()"
        @archive="confirmPopup()" :showArchivedButton="editstate ? true : false"
        :archivedButtonText="status == 'active'? 'Archive' : 'Restore' "
        >
        <template v-slot:contents>
          <fieldset>
            <legend>
              <h5 v-if="!editstate" class="legend-title">
                New Insurance
              </h5>
              <h5 v-else class="legend-title">
                Edit Insurance
              </h5>
            </legend>
            <div class="row">
              <div class="standard-form-group">
                <select-input
                  rules="required"
                  select-id="insurance-type"
                  name="Insurance Type"
                  validation-id="insuranceType"
                  v-model="type"
                  :options="insuranceOptions"
                />
              </div>   
              <div class="standard-form-group-small-checkbox">

                <checkbox-input
                  :disabled="idOfPrimary.length > 0"
                  name="Primary"
                  labelName="Primary"
                  inputId="insurance-primary"
                  v-model="primary"
                  label='Yes'
                />
              </div>  
              <div class="standard-form-group">             
                <text-input
                  rules="required"
                  inputId="insurance-payer"
                  name="Payer"
                  v-model="payer" />
              </div>
              <div class="standard-form-group">
                <text-input
                  rules="required"
                  inputId="insurance-plan"
                  name="Plan"
                  v-model="plan" />
              </div>

              <div class="standard-form-group">
                <text-input
                  rules="required"
                  inputId="insurance-policy"
                  name="Policy"
                  v-model="policy" />
              </div>
            
              <div class="standard-form-group">
                <text-input
                  rules="required"
                  inputId="insurance-status"
                  name="Guarantor / Subscriber"
                  v-model="subscriber" />
              </div>
                
            </div>
          </fieldset>
        </template>

      </sub-section>

    </div>
  </div>
</template>


<i18n src="./_locales/AddressComponent.json"></i18n>

<script lang="ts">
  import {
    Getter,
    State,
  } from 'vuex-class';
  import {
    mixins
  } from "vue-class-component";
  import {
    Component,
    Vue,
    Prop
  } from 'vue-property-decorator';
  import CardSection from "@/components/shared/CardSection.vue";
  import SaveToolbar from '@/components/shared/SaveToolbar.vue';
  import {
    VueGoodTable
  } from 'vue-good-table';
  import SelectInput from '@/components/shared/SelectInput.vue';
  import CheckboxInput from '@/components/shared/CheckboxInput.vue';
  import {
    GenericCodeValue
  } from '@/store/types';
  import TextInput from '@/components/shared/TextInput.vue';
  import TextAreaInput from '@/components/shared/TextAreaInput.vue';
  import SubSection from '@/components/shared/SubSection.vue';
  import DateInput from '@/components/shared/DateInput.vue';
  import SelectOtherInput from '@/components/shared/SelectOtherInput.vue';

  import { TableConfig } from '@/types';

  import { Recipient } from "@/store/recipients/types";
  import { UserDetails } from '@/store/userAccounts/types';
  import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
  import NumberInput from '@/components/shared/NumberInput.vue';

  import BooleanRadioInput from '@/components/shared/BooleanRadioInput.vue';
  import FilterComponent from '@/components/prototypes/shared/_FilterComponent.vue';
  import ModalSection from '@/components/shared/ModalSection.vue';


  interface TableRow {
    id: string;
    type ? : string;

    date ? : string;
    fileName ? : string;

    fileType ? : string;
    description ? : string;
    uploadedBy ? : string;
  }

  export interface FollowUpTypeOption extends GenericCodeValue {
    hasChecklist: boolean;
    checklistReference?: string;
  }

  

  @Component({
    components: {
      CardSection,
      SaveToolbar,
      SubSection,
      DateInput,
      SelectInput,
      TextInput,
      CheckboxInput,
      TextAreaInput,
      VueGoodTable,
      SelectOtherInput,
      BooleanRadioInput,
      FilterComponent,
      ModalSection
    }
  })
  export default class RecipienInsurance extends mixins(DateUtilsMixin) {

    @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
    @State(state => state.userAccounts.selected) private selected!: UserDetails;
    @State(state => state.pageState.currentPage.journeyStatus) editState!: any;

    @Getter('getUser', { namespace: 'users' }) private getUser!: any;
    @Prop({ default: null }) countryLookup!: any; 
    @Prop({ default: null }) provinceLookup!: any; 
    @Prop({ default: null }) usStateLookup!: any; 


    @Prop({
      default: null
    }) columns!: any;
    @Prop({
      default: null
    }) tableData!: any;
    @Prop({
      default: null
    }) title!: string;
    @Prop({
      default: null
    }) localStorageRef!: string;

    loaded() {
      // Parse tasks from local storage if found, otherwise default to JSON file contents
      let formState;
      const defaultState = {
        insurances: this.tableData.rows
      };
      const items: any = localStorage.getItem(this.localStorageRef);
      if (items) {
        try {
          formState = JSON.parse(items);
        } catch (error) {
          console.warn('Warning: Cannot parse tasklist from local storage, setting default tasks.', error);
          formState = defaultState;
        }
      } else {
        formState = defaultState;
      }
      this.$store.commit("pageState/set", {
        pageKey: "journeyStatus",
        value: formState,
      });
      this.insurances = formState.insurances || [];

      this.checkInsurances();
    }

    private mounted(): void {
      Promise.all([
        this.$store.dispatch('hospitals/load'),
      ]).finally(() => {
        this.loaded();
      });
    }

  private id = null;
  private type = '';
  private payer = '';
  private plan = '';
  private policy = '';
  private status = 'active';
  private subscriber = ''; 
  private primary = null;
  private idOfPrimary = '';
  private insurances: any = [];
  private editstate = false;
  private isTableUpdating = false;
  private taskInProgress = false;
  public perPage = 10;
  public currentPageTable = 1;
  private isLoading = true;
  private isSubSectionLoading = true;
  private displayFilter = true;
  private setFilters: any = {};
  private modalMode = ""

  private insuranceOptions = [{code: 0, value: 'Medicare'}, {code: 1, value: 'Commercial'},{code: 2, value: 'Medicare Advantage'},{code: 3, value: 'Medicaid'}];

  private statusOptions = [{code: 0, value: 'Active'}, {code: 1, value: 'Expired'}];


  public getTypeValue(item: any, data: any) {
    return data[item as any].value;
  }

  checkInsurances() {
    return this.insurances.map((item: any) => {
      if (item.primary == true) {
        this.idOfPrimary = item.id;
      }
    });
  }

  private updateFilter(filterArray: any) {
    if (filterArray.length == 0) {
      return false;
    }

    this.setFilters = filterArray;
  }

  private confirmPopup(): any {
    this.modalMode = this.status == 'active' ? 'Archive' : 'Restore';
    this.toggleModal('insuranceArchiveModal');
  }


  private resetArchive(): any {
    setTimeout(() => {
      this.scrollToMethod('recipient-insurance');
    }, 500);
  }

  private scrollToMethod(id: any): any {


    const location = document.getElementById(id);

    if (location) {
      const elementPosition = location.getBoundingClientRect().top;
      const offsetPosition = elementPosition - 105;

      window.scrollBy({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  }

  private archiveDocument(): any {
    this.isTableUpdating = true;
    let insurances = this.insurances || [];
    let message = "";

    insurances.map((item: any) => {
      if (item.id === this.id) {
        if (item.status == 'active') {
          item.status = 'archived';
        } else {
          item.status = "active";
        }
      }
    });

    this.insurances = insurances;

    const defaultState = {
      insurances: insurances
    };

    localStorage.setItem(this.localStorageRef, JSON.stringify(defaultState));

    this.isTableUpdating = false;
    this.editstate = false;
    this.clearForm();
      setTimeout(() => {
      this.scrollToMethod('insurance_section');
    }, 500);
  }


    // Toggle a modal based on a ref
  private toggleModal(ref: string): void {
    const targetModal = this.$refs[ref] as ModalSection;
    targetModal.toggleModal();
  }


  getArchivedStatus(row: any) {
    if (this.setFilters && this.setFilters.includeArchived) {
      return row.status === 'archived' ? 'highlight-yellow' : '';
    } else if (row.status === 'archived') {
      return 'd-none';
    }
  }

  getprimaryStyle(row: any) {
    return row.primary === true ? 'tr-primary-row' : '';
  }

  rowStyleClass(row: any) {
    return `${this.getArchivedStatus(row)} ${this.getprimaryStyle(row)} tr-link`;
  }


  private deleteRow(row :any) {
    const updatedAddresses = this.insurances.filter((item: any) => item.id !== row.id);
    this.insurances = updatedAddresses;
    localStorage.setItem(this.localStorageRef, JSON.stringify(updatedAddresses));
  }

  

  get tableRows() {
    const insurances = this.insurances || [];
    return insurances.map((insurance: any) => {
      return {
        date: this.parseDisplayDateUi(insurance.date) || '-',
        id: insurance.id,
        type: insurance.type,
        insuranceName: insurance.type ? this.getTypeValue(insurance.type, this.insuranceOptions) : '-',
        payer: insurance.payer,
        plan: insurance.plan,
        primary: insurance.primary,
        policy: insurance.policy,
        status: insurance.status, 
        statusName: insurance.status ? insurance.status.charAt(0).toUpperCase() + insurance.status.slice(1) : '',
        subscriber: insurance.subscriber
      };
    });
  }

    get tableConfig(): TableConfig {
      return {
        data: this.tableRows,
        columns: this.columns,
        createButton: true,
        createText: `${this.$t('Add New insurance')}`,
        sortOptions: {
          enabled: false,
        },
        paginationOptions: {
          enabled: true,
          mode: 'records',
          perPage: this.perPage,
          perPageDropdown: [10, 25, 50],
          setCurrentPage: this.currentPageTable,
          dropdownAllowAll: true,
          nextLabel: '',
          prevLabel: '',
          rowsPerPageLabel: this.$t('table.results'),
        },
      };
    }

  private editSelected(row :any) {    
    if (row) {
      this.checkInsurances();

      if (this.idOfPrimary == row.row.id) {
        this.idOfPrimary = "";
      }

      this.editstate = true;
      this.type = row.row.type;
      this.id = row.row.id;
      this.payer = row.row.payer;
      this.plan = row.row.plan;
      this.policy = row.row.policy;
      this.status = row.row.status;
      this.subscriber = row.row.subscriber;
      this.primary = row.row.primary;
      
      this.$emit('clear');
    }
    
  }

    get emptyMessage(): string {
      if (!this.insurances) {
        return this.$t('loading').toString();
      } else {
        return this.$t('use_form_below_follow_up').toString();
      }
    }


    public resetSaveToolbar(): void {
      // Refer to the save toolbar that handles this page
      const saveToolbar = (this.$refs.recipientInsurance as SubSection).$refs['save-recipient-insurance'] as SaveToolbar;
      saveToolbar.reset();
    }


    get getUserFullName(): string | undefined {
      let user = this.getUser;
      return user.name;
    }

    public getOptionValue(item: any, options: any, other: any) {
      if (other) {
        return other;
      } else {

      
      return options[item as any].value;
      }
    }

    public clearForm(): any {
      this.checkInsurances();
      this.type = '';
      this.payer = '';
      this.plan = '';
      this.policy = '';
      this.status = "active";
      this.subscriber = '';
      this.primary = null;
      this.$emit('clear');
      this.resetSaveToolbar();
      this.editstate = false;
    }


    public uploadDocument(): any {
      this.isTableUpdating = true;
      let insurances = this.insurances || [];
      let getId;

      if (!this.editstate) {


        const payload: any = {
          id: Math.random().toString(16).slice(2),
          date: new Date().toLocaleDateString('en-CA'),
          type: this.type,
          payer: this.payer,
          plan: this.plan,
          policy: this.policy,
          status: this.status,
          subscriber: this.subscriber,
          primary: this.primary
        };
        insurances.push(payload);
        getId = payload.id;
      } else {
        insurances.map((item: any) => {
          if (item.id === this.id) {
          item.date = new Date().toLocaleDateString('en-CA'),
          item.type = this.type;
          item.payer = this.payer;
          item.plan = this.plan;
          item.policy = this.policy;
          item.status = this.status;
          item.subscriber = this.subscriber;
          item.primary = this.primary;
          getId =  this.id;
        }
        });
      

      }

      this.insurances = insurances;

      if (this.primary == true) {
         this.idOfPrimary = getId;
      }

      const defaultState = {
        insurances: insurances
      };

      localStorage.setItem(this.localStorageRef, JSON.stringify(defaultState));

      this.isTableUpdating = false;
      this.editstate = false;
      this.clearForm();
    }
  }


</script>

<style>


</style>
