import { render, staticRenderFns } from "./HlaInput.vue?vue&type=template&id=64c667a1"
import script from "./HlaInput.vue?vue&type=script&lang=ts"
export * from "./HlaInput.vue?vue&type=script&lang=ts"
import style0 from "./HlaInput.vue?vue&type=style&index=0&id=64c667a1&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/components/hla/_locales/HlaVirtualCrossmatchResult.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fshared%2FHlaInput.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/components/shared/_locales/HlaInputGroup.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fshared%2FHlaInput.vue&external"
if (typeof block1 === 'function') block1(component)

export default component.exports