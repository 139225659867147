<template>
  <div class="nav-block-wrap">
    <ul class="nav flex-column nav-block" 
      :class="{ active: isActive }">
      <li class="nav-item" >
        <span class="nav-link">
          <router-link class="no-scroll" style="color: #2e2e2e !important" :to="{ name: 'edit-organ-oop', params: { organ_id: journeyId } }">
             <font-awesome-icon class="nav-caret" :icon="['fas', (show ? 'chevron-down' : 'chevron-right')]" fixed-width @click.prevent="toggleNav"/>
            {{ $t(journeyName) }}
          </router-link>
        </span>
        <template v-if="show">
          <ul class="nav flex-column">
            <li class="nav-item" @click="toggleSideNav">
              <a class="nav-link" :href="getUrl('transplant-details-section')">{{$t('transplant_details')}}</a>
            </li>
          </ul>
        </template>
      </li>
    </ul>
  </div>
</template>

<i18n src="@/components/_locales/Organs.json"></i18n>
<i18n src="@/components/recipientsOop/_locales/side-nav.json"></i18n>

<script lang="ts">
import { State, Getter } from 'vuex-class';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { organCodeLookup } from '@/types';
import { Recipient } from '@/store/recipients/types';
import { RecipientJourney } from '@/store/recipientJourney/types';

@Component
export default class SelectedRecipientJourneyLinksOop extends Vue {
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) private journey!: RecipientJourney;

  @Prop() journeyId!: string;
  @Prop() journeyName!: string;
  @Prop() organCode!: number;

  show = false;

  public toggleNav() {
    this.show = !this.show;
  }

  public mounted() {
    this.show = this.isActive;
  }

  public toggleSideNav(): void {
    if (window.innerWidth < 992) { 
       this.$emit("toggle");
    }
  }

  @Watch('$route', { immediate: true, deep: true })
  public onUrlChange() {
    this.show = this.isActive;
  }

  // Hide organ specific deatils from side nav
  public ORGAN_CODES_TO_EXCLUDE = ['7'];

  get showOrganSpecificDetails(): boolean {
    // Check if the journey organ code should be excluded
    const isExcluded = this.ORGAN_CODES_TO_EXCLUDE.includes(this.organCode!.toString());
    // Hide field if organ is excluded
    return !isExcluded;
  }

  get isActive(): boolean {
    const organId = this.$route.params.organ_id;
    if (!!organId) {
      return this.journeyId == organId;
    }
    return false;
  }
  
  /**
   * Return organ name from the organ code using organCodeLookup
   * 
   * @returns {string} organ name 
   */
  get organName(): string {
    if (!this.organCode) {
      return '';
    }
    return organCodeLookup[this.organCode] || '';
  }

  public getUrl(hash: string): string {
    hash = `#${hash}`;
    if(this.isActive) {
      return hash;
    } else {
      // We are fine not checking for the existence of client_id as for new recipients we only ever show this in active mode
      const location = this.$router.resolve({ name: 'edit-organ-oop', params: { organ_id: this.journeyId }, hash: hash });
      return location.href;
    }
  }
}
</script>
