<template>
  <card-section
    section-id="lung-specific-details"
    :lookups-to-load="lookupsToLoad"
    :saveButtonText="$t('save_details')"
    ref="saveLungSpecificDetails"
    @loaded="loaded()"
    :saveButton="canEdit"
    :disabled="!canSave || newJourney"
     :isAccordion="prototypeFeatureEnabled('proto_ability_to_expand_collapse')"
    @save="savePatch()">
    <template v-slot:header>
      {{$t('lung_specific_details')}}
    </template>
    <template v-slot:body v-if="editState">
      <hemodynamic-information

        ref="hemodynamicInformation"

      />
      <thoracic-measurements v-if="isThoracicMeasurementsEnabled"
        ref="thoracicMeasurement"
        :new-journey="newJourney"
        @handleErrors="handleErrors"
        @saving="saving"
        @clear="clear"
        :canSave="canSave"
      />
      <sub-section :title="$t('general_info')" sub-section-id="lungsd-transplant">
        <template v-slot:contents>
          <template v-if="editState.generalInfo">
            <fieldset :disabled="!canSave || newJourney" >
              <div class="row">
                <div class="standard-form-group">
                  <select-input
                    select-id="lungsd-secondary-specification"
                    :name="$t('secondary_specification')"
                    ruleKey="organ_specific_details.organ_specification_preference_code"
                    v-model="editState.generalInfo.secondarySpecification" 
                    :options="secondarySpecificationOptions"
                  />
                </div>
                <div class="standard-form-group">
                  <number-input
                    inputId="lungsd-transplant-perfusion-right"
                    :name="$t('perfusion_right')"
                    ruleKey="organ_specific_details.perfusion_right"
                    v-model="editState.generalInfo.perfusionRight"
                    step="1"
                    min="0" />
                </div>
                <div class="standard-form-group">
                  <number-input
                    inputId="lungsd-transplant-perfusion-left"
                    :name="$t('perfusion_left')"
                    ruleKey="organ_specific_details.perfusion_left"
                    v-model="editState.generalInfo.perfusionLeft"
                    step="1"
                    min="0" />
                </div>
                <div class="standard-form-group">
                  <number-input
                    inputId="lungsd-transplant-predicted-tlc"
                    :name="$t('predicted_tlc')"
                    ruleKey="organ_specific_details.predicted_total_lung_capacity"
                    v-model="editState.generalInfo.predictedTotalLungCapacity"
                    step="1"
                    min="0" />
                </div>
                <div class="standard-form-group">
                  <number-input
                    inputId="lungsd-transplant-actual-tlc"
                    :name="$t('actual_tlc')"
                    ruleKey="organ_specific_details.actual_total_lung_capacity"
                    v-model="editState.generalInfo.actualTotalLungCapacity"
                    step="1"
                    min="0" />
                </div>
                <div class="standard-form-group-large">
                  <number-input
                    inputId="lungsd-transplant-rv"
                    :name="$t('estimated_rv_systolic_pressure')"
                    ruleKey="organ_specific_details.estimated_right_ventricular_systolic_pressure"
                    v-model="editState.generalInfo.estimatedRightVentricularSystolicPressure"
                    step="1"
                    min="0" />
                </div>
                <div class="standard-form-group">
                  <number-input
                    inputId="lungsd-transplant-systolic-pa"
                    :name="$t('systolic_pa_pressure')"
                    ruleKey="organ_specific_details.pulmonary_artery_systolic_pressure"
                    v-model="editState.generalInfo.pulmonaryArterySystolicPressure"
                    step="1"
                    min="0" />
                </div>
                <div class="standard-form-group">
                  <number-input
                    inputId="lungsd-transplant-diastolic-pa"
                    :name="$t('diastolic_pa_pressure')"
                    ruleKey="organ_specific_details.pulmonary_artery_diastolic_pressure"
                    v-model="editState.generalInfo.pulmonaryArteryDiastolicPressure"
                    step="1"
                    min="0" />
                </div>
                <div class="standard-form-group">
                  <number-input
                    inputId="lungsd-transplant-mean-pa"
                    :name="$t('mean_pa_pressure')"
                    ruleKey="organ_specific_details.pulmonary_mean_arterial_pressure"
                    v-model="editState.generalInfo.meanPulmonaryAterialPressure"
                    step="1"
                    min="0" />
                </div>
                <div class="standard-form-group">
                  <number-input
                    inputId="lungsd-transplant-las"
                    :name="$t('las')"
                    ruleKey="organ_specific_details.pulmonary_mean_arterial_pressure"
                    v-model="editState.generalInfo.lungAllocationScore"
                    step="1"
                    min="0" />
                </div>
              </div>
            </fieldset>
            <fieldset :disabled="!canSave || newJourney" v-if="checkAtLeastOnePropExists(['organ_specific_details.echocardiogram_date', 'organ_specific_details.echocardiogram_comment'])">
              <div class="row">
                <div class="standard-form-group" v-if="checkPropExists('organ_specific_details.echocardiogram_date')">
                  <date-input
                    inputId="lungsd-transplant-echo-date"
                    :name="$t('echo_date')"
                    ruleKey="organ_specific_details.echocardiogram_date"
                    :cross-values="{ echocardiogram_comment: editState.generalInfo.echocardiogramComment }"
                    v-model="editState.generalInfo.echocardiogramDate" />
                </div>
                <div class="row-break" v-if="checkAllPropsExist(['organ_specific_details.echocardiogram_date', 'organ_specific_details.echocardiogram_comment'])"></div>
                <div class="standard-comment-group" v-if="checkPropExists('organ_specific_details.echocardiogram_comment')">
                  <text-area-input
                    inputId="lungsd-transplant-echo-comment"
                    :name="$t('echo_comment')"
                    ruleKey="organ_specific_details.echocardiogram_comment"
                    :cross-values="{ echocardiogram_date: editState.generalInfo.echocardiogramDate }"
                    v-model="editState.generalInfo.echocardiogramComment" />
                </div>
              </div>
            </fieldset>
            <fieldset :disabled="!canSave || newJourney">
              <div class="row">
                <div class="standard-form-group" v-if="checkPropExists('organ_specific_details.chest_ct_scan_date')">
                  <date-input
                    inputId="lungsd-transplant-ct-date"
                    :name="$t('chest_ct_date')"
                    ruleKey="organ_specific_details.chest_ct_scan_date"
                    :cross-values="{ chest_ct_scan_result: editState.generalInfo.chestCtScanResult }"
                    v-model="editState.generalInfo.chestCtScanDate" />
                </div>
                <div class="standard-form-group" v-if="checkPropExists('organ_specific_details.chest_ct_scan_result')">
                  <text-input
                    inputId="lungsd-transplant-ct-results"
                    :name="$t('chest_ct_results')"
                    ruleKey="organ_specific_details.chest_ct_scan_result"
                    :cross-values="{ chest_ct_scan_date: editState.generalInfo.chestCtScanDate }"
                    v-model="editState.generalInfo.chestCtScanResult" />
                </div>
                <div class="standard-form-group-large">
                  <date-input
                    input-id="lungsd-transplant-hla-date"
                    :name="$t('hla_antibodies_date')"
                    :disabled="true"
                    v-model="editState.generalInfo.hlaAntibodiesDate">
                    <template v-slot:link>
                        <span class="nobr">
                          (<router-link :to="{ name: 'recipient-hla', params: { id: selectedRecipient.client_id } }">{{$t('link_hla')}}</router-link>)
                        </span>
                    </template>
                  </date-input>
                </div>
                <div class="standard-form-group">
                  <text-input
                    input-id="lungsd-transplant-dsa"
                    :name="$t('unacceptable_dsa')"
                    :disabled="true"
                    v-model="editState.generalInfo.unacceptableDsa"
                  />
                </div>
                <div class="standard-form-group" v-if="checkPropExists('organ_specific_details.extra_corporeal_life_support')">
                  <radio-group
                    input-id="lungsd-transplant-ecls"
                    :labelName="$t('ecls')"
                    ruleKey="organ_specific_details.extra_corporeal_life_support"
                    :options="yesNoRadioGroupOptions"
                    v-model="editState.generalInfo.extraCorporealLifeSupport"
                  />
                </div>
                <div class="standard-form-group" v-if="checkPropExists('organ_specific_details.extra_corporeal_life_support_comment')">
                  <text-input
                    inputId="lungsd-transplant-ecls-comment"
                    :name="$t('ecls_comment')"
                    ruleKey="organ_specific_details.extra_corporeal_life_support_comment"
                    :cross-values="{ extra_corporeal_life_support: editState.generalInfo.extraCorporealLifeSupport }"
                    v-model="editState.generalInfo.extraCorporealLifeSupportComment" />
                </div>
                <div class="standard-form-group" v-if="checkPropExists('organ_specific_details.ventilator')">
                  <radio-group
                    input-id="lungsd-transplant-vent"
                    :labelName="$t('vent')"
                    ruleKey="organ_specific_details.ventilator"
                    :options="yesNoRadioGroupOptions"
                    v-model="editState.generalInfo.ventilator"
                  />
                </div>
                <div class="standard-form-group" v-if="checkPropExists('organ_specific_details.ventilator_comment')">
                  <text-input
                    inputId="lungsd-transplant-vent-comment"
                    :name="$t('vent_comment')"
                    ruleKey="organ_specific_details.ventilator_comment"
                    :cross-values="{ ventilator: editState.generalInfo.ventilator }"
                    v-model="editState.generalInfo.ventilatorComment" />
                </div>
                <div class="standard-form-group" v-if="checkPropExists('organ_specific_details.predicted_forced_vital_capacity')">
                  <number-input
                    inputId="lungsd-transplant-fvc"
                    :name="$t('predicted_fvc')"
                    ruleKey="organ_specific_details.predicted_forced_vital_capacity"
                    v-model="editState.generalInfo.predictedForcedVitalCapacity"
                    step="1"
                    min="0" />
                </div>
                <div class="standard-form-group" v-if="checkPropExists('organ_specific_details.actual_forced_expiratory_volume')">
                  <number-input
                    inputId="lungsd-transplant-fev1"
                    :name="$t('fev1_actual')"
                    ruleKey="organ_specific_details.actual_forced_expiratory_volume"
                    v-model="editState.generalInfo.actualForcedExpiratoryVolume"
                    step="1"
                    min="0" />
                </div>
                <div class="standard-comment-group">
                  <text-area-input
                    inputId="lungsd-comments"
                    :name="$t('general_comments')"
                    ruleKey="comments"
                    v-model="editState.comments" />
                </div>
                <div class="standard-form-group">
                  <number-input
                    inputId="six_min_walk_distance"
                    rules="min_value: 0 |max_value: 999.9"
                    name="6-Minute Walk Distance"
                    v-model="six_min_walk_distance"
                  />
                </div>
                <div class="standard-form-group-large">
                  <number-input
                    inputId="forced_vital_capacity"
                    rules="min_value: 0 |max_value: 100"
                    name="Forced Vital Capacity Percentage Predicted"
                    v-model="forced_vital_capacity"
                  />
                </div>
                <div class="standard-form-group-large">
                  <number-input
                    inputId="forced_expiratory_capacity"
                    rules="min_value: 0 |max_value: 100"
                    name="Forced Expiratory Volume in 1 Second Percentage Predicted"
                    v-model="forced_expiratory_volume"
                  />
                </div>
              </div>
            </fieldset>
          </template>
        </template>
      </sub-section>
    </template>
  </card-section>
</template>

<i18n src="@/components/_locales/Organs.json"></i18n>
<i18n src="@/components/organs/lung/_locales/LungSpecificDetails.json"></i18n>

<script lang="ts">
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-class';
import { GenericCodeValue } from '@/store/types';
import { LabHLAAntibody } from '@/store/labs/types';
import { Recipient } from '@/store/recipients/types';
import { YesNoNoSelection } from '@/store/lookups/types';
import { SystemModules } from '@/store/features/types';
import HlaInput from '@/components/shared/HlaInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import TextInput from '@/components/shared/TextInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import CardSection from '@/components/shared/CardSection.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import NumberInput from '@/components/shared/NumberInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { LungDetails } from '@/store/organSpecificDetails/types';
import { RecipientJourney } from '@/store/recipientJourney/types';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import BooleanRadioInput from '@/components/shared/BooleanRadioInput.vue';
import RadioGroup from '@/components/shared/RadioGroup.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import ThoracicMeasurements, { ThoracicMeasurementForm } from '@/components/organs/shared/ThoracicMeasurements.vue';
import HemodynamicInformation from '@/components/prototypes/organs/lung/HemodynamicInformation.vue';

export interface LungSpecificPageState {
  generalInfo: LungSpecificForm;
  thoracicMeasurement?: ThoracicMeasurementForm;
  comments?: string;
}

interface LungSpecificForm {
    secondarySpecification?: string;
    transplantType?: string;
    perfusionRight?: number;
    perfusionLeft?: number;
    predictedTotalLungCapacity?: number;
    actualTotalLungCapacity?: number;
    estimatedRightVentricularSystolicPressure?: number;
    pulmonaryArterySystolicPressure?: number;
    pulmonaryArteryDiastolicPressure?: number;
    meanPulmonaryAterialPressure?: number;
    lungAllocationScore?: number;
    echocardiogramDate?: string|null;
    echocardiogramComment?: string;
    chestCtScanDate?: string|null;
    chestCtScanResult?: string;
    predictedForcedVitalCapacity?: number;
    actualForcedExpiratoryVolume?: number;
    hlaAntibodiesDate?: string;
    unacceptableDsa?: string;
    extraCorporealLifeSupport?: string; // string as 'Y' and 'N' while 'U' is nil
    extraCorporealLifeSupportComment?: string;
    ventilator?: string;  // string as 'Y' and 'N' while 'U' is nil
    ventilatorComment?: string;
}

@Component({
  components: {
    DateInput,
    TextInput,
    HlaInput,
    SubSection,
    CardSection,
    SelectInput,
    NumberInput,
    TextAreaInput,
    CheckboxInput,
    BooleanRadioInput,
    ThoracicMeasurements,
    RadioGroup,
    HemodynamicInformation
  }
})
export default class LungSpecificDetails extends mixins(DateUtilsMixin) implements SaveableSection {
  // State
  @State(state => state.labs.hlaAntibodies) hlaAntibodies!: LabHLAAntibody[];
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.recipients.selectedRecipient) selectedRecipient!: Recipient;
  @State(state => state.pageState.currentPage.lungDetails) editState!: LungSpecificPageState;
  @State(state => state.lookups.yes_no_noselection) private yesNoNoSelectionLookup!: YesNoNoSelection[];
  @Getter("moduleEnabled", { namespace: "features" }) private moduleEnabled!: (module: string) => boolean;

  // Getters
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('lungSecondarySpecOptions', { namespace: 'lookups' }) private lungSecondarySpecOptions!: GenericCodeValue[];
  @Getter('checkPropExists', { namespace: 'validations' }) checkPropExists!: (ruleKey: string) => boolean;
  @Getter('checkAllPropsExist', { namespace: 'validations' }) checkAllPropsExist!: (ruleKeys: string[]) => boolean;
  @Getter('checkAtLeastOnePropExists', { namespace: 'validations' }) checkAtLeastOnePropExists!: (ruleKeys: string[]) => boolean;

  // Lookup tables to be loaded by the CardSection component
  public lookupsToLoad = ['yes_no_noselection'];
  private six_min_walk_distance = '';
  private forced_vital_capacity = '';
  private forced_expiratory_volume = '';

  // Properties
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;


  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;

  /**
   * Return true if lung specific details section can be edited
   * 
   * cannot be edited if new journey
   * cannot be edited if journey is completed
   *
   * @returns {boolean} true if we can edit
   */
  get canEdit(): boolean{
    if (this.newJourney || this.journey.completed) {
      return false;
    }
    return true;
  }

  // Restructure yesNoSelectionLookup to options that RadioGroup can handle
  get yesNoRadioGroupOptions(): any[]{
    return [
      {
        value: this.yesNoNoSelectionLookup[0].code,
        label: this.yesNoNoSelectionLookup[0].value,
      },
      {
        value: this.yesNoNoSelectionLookup[1].code,
        label: this.yesNoNoSelectionLookup[1].value,
      },
    ];
  }
  
  get secondarySpecificationOptions(): GenericCodeValue[] {
    if (!this.lungSecondarySpecOptions || this.lungSecondarySpecOptions.length === 0) return [];

    const translated = this.lungSecondarySpecOptions.map((option: GenericCodeValue): GenericCodeValue => {
      return {
        code: option.code,
        value: this.$t(option.value).toString(),
      };
    });
    return translated;
  }

  // Is the thoracic measurements system module enabled?
  get isThoracicMeasurementsEnabled(): boolean {
    return this.moduleEnabled(SystemModules.THORACIC_MEASUREMENTS);
  }

  // Comes from card-section after all the lookups are loaded
  public loaded(): void {
    this.$emit('loaded', 'organSpecificDetailsSection');
    this.initializeForm();
  }

  // Copy relevant data from journey into editState or initialize as blank
  public initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'lungDetails',
      value: this.extractLungSpecificDetailsForm(this.journey)
    });
    this.loadHlaAntibodies();

    // Load the 'edit' validation rules
    this.loadValidationRules();
  }

  // Load the 'edit' validation rules based on current selection
  private loadValidationRules(): void {
    const journeyId =  this.journey?._id?.$oid;
    if (!journeyId) return;

    // NOTE: this is using 'loadNew' action because organ specific details 'edit' has no ID in the route
    this.$store.dispatch(
      'validations/loadNew',
      {
        view: `recipients/${this.recipientId}/journeys/${journeyId}/organ_specific_details`,
        action: 'edit',
        prefix: 'organ_specific_details'
      }
    );
  }


  private mounted(): void {
    let items = localStorage.getItem("lungSpecificDetailsStorage");

    if (items) {
      let parsed = JSON.parse(items);
      this.six_min_walk_distance = parsed.six_min_walk_distance;
      this.forced_vital_capacity = parsed.forced_vital_capacity;
      this.forced_expiratory_volume = parsed.forced_expiratory_volume;
    }

  }

  // Update HLA Antibodies Date field with most recent sample_date available
  public loadHlaAntibodies(): void {
    const recipientId = this.selectedRecipient.client_id;
    this.$store.dispatch('labs/loadHlaAntibodies', recipientId).then(() => {
      let hlaAntibodiesDate: string|undefined;
      let unacceptableDsa: string[]|undefined;
      if (this.hlaAntibodies && this.hlaAntibodies.length > 0) {
        // API already sorted values based on most recent date, first one in the array will be most recent value
        hlaAntibodiesDate = this.parseDateUi(this.hlaAntibodies[0].sample_date);
        const hlaUnacceptable = this.hlaAntibodies.filter((item: LabHLAAntibody) => {
          return this.parseDateUi(item.sample_date) == hlaAntibodiesDate;
        });
        const class1 = hlaUnacceptable[0].antibodies!.class1 || {};
        const class2 = hlaUnacceptable[0].antibodies!.class2 || {};
        const class1_unacceptable = (class1.antibodies.unacceptable_allele_group || []).concat(class1.antibodies.unacceptable_allele_specific || []).concat(class1.antibodies.unacceptable_alpha_beta || []);
        const class2_unacceptable = (class2.antibodies.unacceptable_allele_group || []).concat(class2.antibodies.unacceptable_allele_specific || []).concat(class2.antibodies.unacceptable_alpha_beta || []);
        unacceptableDsa = class1_unacceptable!.concat(class2_unacceptable);
      } else {
        hlaAntibodiesDate = 'N/A';
      }
      // Must use Vue.set or the UI will not be updated
      Vue.set(this.$store.state.pageState.currentPage.lungDetails.generalInfo, 'hlaAntibodiesDate', hlaAntibodiesDate);
      Vue.set(this.$store.state.pageState.currentPage.lungDetails.generalInfo, 'unacceptableDsa', unacceptableDsa);
    }).catch(() => {
      console.warn(this.$t('could_not_load_hla_labs').toString());
    });
  }

  // Extract relevant parts from Recipient to match the form layout
  public extractLungSpecificDetailsForm(journey?: RecipientJourney): LungSpecificPageState {
    if (!journey) {
      const result: LungSpecificPageState = { generalInfo: {} };
      return result;
    }
    const organ_specific_details = journey.organ_specific_details as LungDetails;
    const ecls = organ_specific_details.extra_corporeal_life_support;
    const tlc = organ_specific_details.actual_total_lung_capacity ? parseFloat(organ_specific_details.actual_total_lung_capacity.toFixed(2)) : undefined;
    const vent = organ_specific_details.ventilator;
    const result: LungSpecificPageState = {
      comments: journey.comments,
      generalInfo: {
        secondarySpecification: organ_specific_details.organ_specification_preference_code?.toString(),
        transplantType: organ_specific_details.transplant_type,
        perfusionRight: organ_specific_details.perfusion_right,
        perfusionLeft: organ_specific_details.perfusion_left,
        predictedTotalLungCapacity: organ_specific_details.predicted_total_lung_capacity,
        actualTotalLungCapacity: tlc,
        estimatedRightVentricularSystolicPressure: organ_specific_details.estimated_right_ventricular_systolic_pressure,
        pulmonaryArterySystolicPressure: organ_specific_details.pulmonary_artery_systolic_pressure,
        pulmonaryArteryDiastolicPressure: organ_specific_details.pulmonary_artery_diastolic_pressure,
        meanPulmonaryAterialPressure: organ_specific_details.pulmonary_mean_arterial_pressure,
        lungAllocationScore: organ_specific_details.lung_allocation_score,
        echocardiogramDate: this.parseDateUi(organ_specific_details.echocardiogram_date),
        echocardiogramComment: organ_specific_details.echocardiogram_comment,
        chestCtScanDate: this.parseDateUi(organ_specific_details.chest_ct_scan_date),
        chestCtScanResult: organ_specific_details.chest_ct_scan_result,
        extraCorporealLifeSupport: ecls,
        extraCorporealLifeSupportComment: organ_specific_details.extra_corporeal_life_support_comment,
        ventilator: vent,
        ventilatorComment: organ_specific_details.ventilator_comment,
        predictedForcedVitalCapacity: organ_specific_details.predicted_forced_vital_capacity,
        actualForcedExpiratoryVolume: organ_specific_details.actual_forced_expiratory_volume,
      }
    };
    return result;
  }

  // Translate the form structure into the Recipient data structure
  public extractPatch(): RecipientJourney {
    if (this.editState) {
      const lungDetails = this.editState.generalInfo;
      return {
        comments: this.editState.comments,
        organ_specific_details: {
          organ_specification_preference_code: lungDetails.secondarySpecification ? Number(lungDetails.secondarySpecification) : null,
          transplant_type: lungDetails.transplantType,
          perfusion_right: lungDetails.perfusionRight,
          perfusion_left: lungDetails.perfusionLeft,
          predicted_total_lung_capacity: lungDetails.predictedTotalLungCapacity,
          actual_total_lung_capacity: lungDetails.actualTotalLungCapacity,
          estimated_right_ventricular_systolic_pressure: lungDetails.estimatedRightVentricularSystolicPressure,
          pulmonary_artery_systolic_pressure: lungDetails.pulmonaryArterySystolicPressure,
          pulmonary_artery_diastolic_pressure: lungDetails.pulmonaryArteryDiastolicPressure,
          pulmonary_mean_arterial_pressure: lungDetails.meanPulmonaryAterialPressure,
          lung_allocation_score: lungDetails.lungAllocationScore,
          echocardiogram_date: this.sanitizeDateApi(lungDetails.echocardiogramDate),
          echocardiogram_comment: lungDetails.echocardiogramComment,
          chest_ct_scan_date: this.sanitizeDateApi(lungDetails.chestCtScanDate),
          chest_ct_scan_result: lungDetails.chestCtScanResult,
          extra_corporeal_life_support: lungDetails.extraCorporealLifeSupport == null ? 'U' : lungDetails.extraCorporealLifeSupport,
          extra_corporeal_life_support_comment: lungDetails.extraCorporealLifeSupportComment,
          ventilator: lungDetails.ventilator == null ? 'U' : lungDetails.ventilator,
          ventilator_comment: lungDetails.ventilatorComment,
          predicted_forced_vital_capacity: lungDetails.predictedForcedVitalCapacity,
          actual_forced_expiratory_volume: lungDetails.actualForcedExpiratoryVolume,
        }
      };
    } else {
      return {};
    }
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    const saveProvider = this.$refs.saveLungSpecificDetails as unknown as SaveProvider;
    // Reset the save provider's save toolbar
    saveProvider.resetSaveToolbar();
  }

  // Handle saving triggered by local save button
  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveLungSpecificDetails as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'saveLungSpecificDetails');
    // Generate payload based on current edit state
    const organDetailsPatch = {
      recipientId: this.selectedRecipient.client_id,
      journeyId: this.journey ? this.journey._id!.$oid : null,
      journey: this.extractPatch()
    };
    // Dispatch save action and register the response
    this.$store.dispatch('journeyState/saveJourney', organDetailsPatch).then((success: SaveResult) => {
      // If successful, reload the current recipient
      this.$store.dispatch('recipients/get', this.selectedRecipient.client_id);
      // If successful, update the current recipient and show success notification
      this.$store.commit('journeyState/setJourney', success.responseData.journey);
      this.initializeForm();
      saveProvider.registerSaveResult(success);
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });

    const LungSpecificDetailsStorage = {
      "six_min_walk_distance": this.six_min_walk_distance,
      "forced_vital_capacity" : this.forced_vital_capacity,
      "forced_expiratory_volume": this.forced_expiratory_volume
    };

    localStorage.setItem("lungSpecificDetailsStorage", JSON.stringify(LungSpecificDetailsStorage));

  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    const result = {
      'comments'                                                             : 'lungsd-comments',
      'organ_specific_details.organ_specification_preference_code'           : 'lungsd-secondary-specification',
      'organ_specific_details.perfusion_right'                               : 'lungsd-transplant-perfusion-right',
      'organ_specific_details.perfusion_left'                                : 'lungsd-transplant-perfusion-left',
      'organ_specific_details.predicted_total_lung_capacity'                 : 'lungsd-transplant-predicted-tlc',
      'organ_specific_details.actual_total_lung_capacity'                    : 'lungsd-transplant-actual-tlc',
      'organ_specific_details.estimated_right_ventricular_systolic_pressure' : 'lungsd-transplant-rv',
      'organ_specific_details.pulmonary_artery_systolic_pressure'            : 'lungsd-transplant-systolic-pa',
      'organ_specific_details.pulmonary_artery_diastolic_pressure'           : 'lungsd-transplant-diastolic-pa',
      'organ_specific_details.pulmonary_mean_arterial_pressure'              : 'lungsd-transplant-mean-pa',
      'organ_specific_details.lung_allocation_score'                         : 'lungsd-transplant-las',
      'organ_specific_details.echocardiogram_date'                           : 'lungsd-transplant-echo-date',
      'organ_specific_details.echocardiogram_comment'                        : 'lungsd-transplant-echo-comment',
      'organ_specific_details.chest_ct_scan_date'                            : 'lungsd-transplant-ct-date',
      'organ_specific_details.chest_ct_scan_result'                          : 'lungsd-transplant-ct-results',
      'organ_specific_details.extra_corporeal_life_support'                  : 'lungsd-transplant-ecls',
      'organ_specific_details.extra_corporeal_life_support_comment'          : 'lungsd-transplant-ecls-comment',
      'organ_specific_details.ventilator'                                    : 'lungsd-transplant-vent',
      'organ_specific_details.ventilator_comment'                            : 'lungsd-transplant-vent-comment',
      'organ_specific_details.predicted_forced_vital_capacity'               : 'lungsd-transplant-fvc',
      'organ_specific_details.actual_forced_expiratory_volume'               : 'lungsd-transplant-fev1',
    };

    // Thoracic Measurements
    const thoracicMeasurement = this.$refs.thoracicMeasurement as ThoracicMeasurements;
    if (thoracicMeasurement) {
      Object.assign(result, { ...thoracicMeasurement.idLookup });
    }

    return result;
  }

    // Emit event to parent so it can handle validations
  private handleErrors(errors: any): void {
    this.$emit('handleErrors', errors);
  }

  // Emit event to parent so it can handle clearing validations when saving
  private saving(formReference: string) {
    this.$emit('saving', formReference);
  }

  // Emit event to parent only for clear validations
  private clear() {
    this.$emit('clear');
  }
}
</script>
