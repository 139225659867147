<template>
  <card-section
    section-id="offers-made"
    :save-button="true"
    save-button-text="Save New Offer"
    :isAccordion="prototypeFeatureEnabled('proto_ability_to_expand_collapse')"
    @save="saveNewOffer()"
    ref="saveNewOffer"
  >
    <template v-slot:header>
      Offers
    </template>
    <template v-slot:body>
      <fieldset>
        <legend>
          <h4 class="legend-title"><span class="">Current Offers</span></h4>
        </legend>
        <fieldset>
          <div class="table-responsive-md allocationOffers">
            <table class="table table table-bordered table-hover  table-allocation table-demo-offers">
              <caption>
                <nav class="nav action-row flex-align-end" style="margin: .5rem 0;">
                  <div class="offer-button-wrap ml-auto"><button type="button"
                      class="btn btn-wide btn-sm btn-primary w-auto">Add New Offer</button>
                  </div>
                </nav>
              </caption>
              <thead>
                <tr class="fixed-head">
                  <th scope="col" style="width: 11%;max-width: 11%;min-width: 11%;"><span>Offer Date</span></th>
                  <th scope="col" class="">Organ</th>
                  <th scope="col">Laterality</th>
                  <th scope="col">Offer Type</th>
                  <th scope="col">Patient</th>
                  <th scope="col">Response Date Time</th>
                  <th scope="col">Coordinator</th>
                  <th scope="col">Surgeon</th>
                  <th scope="col">Offer Response</th>
                  <th scope="col">Response Category</th>
                  <th scope="col">Outcome / Dispostion</th>
                  <th scope="col">Notes</th>
                </tr>
              </thead>
              <!-- <tfoot>
                <tr class="no-hover action-table-row sticky-row">
                  <td colspan="100%">
                    <nav class="nav action-row flex-align-end">
                      <div class="offer-button-wrap"><button type="button"
                          class="btn btn-wide btn-sm btn-success w-auto">Save
                          Responses</button>
                      </div>
                      <div class="ml-auto offer-timers">
                        <div></div>
                      </div>
                    </nav>
                  </td>
                </tr>
              </tfoot> -->
              <tbody>
                <template v-for="offerRow in offerRows">
                  <tr :id="offerRow.id" :class="offerRow.class" :key="offerRow.id">
                    <td><a href="#" @click.prevent="openCompareModal(offerRow.deceased_donor_id)"><span>{{ offerRow.offer_date }}</span></a>
                    </td>
                    <td class="text-center">
                      <span>{{ offerRow.organ }}</span>
                    </td>
                    <td class="text-center">
                      <span>{{ offerRow.laterality }}</span>
                    </td>
                    <td class="text-center font-weight-bold">
                      <span>{{ offerRow.offer_type }}</span>
                    </td>
                    <td><span>{{ offerRow.patient }}</span></td>
                    <td><span>{{ offerRow.response_date }}</span></td>
                    <td>
                      <span>{{ offerRow.coordinator }}</span>
                    </td>
                    <td>
                      <span>{{ offerRow.surgeon }}</span>
                    </td>
                    <td>
                      <span>{{ offerRow.offer_response }}</span>
                    </td>
                    <td>
                      <span>{{ offerRow.response_category }}</span>
                    </td>
                    <td>
                      <span>{{ offerRow.outcome_disposition }}</span>
                    </td>
                    <td>
                      <span>{{ offerRow.notes }}</span>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </fieldset>
        <fieldset v-if="editState">
          <legend>
            <h5 v-if="editState.newRow" class="legend-title">Selected Offer</h5>
            <h5 v-else class="legend-title">New Offer</h5>
          </legend>
          <div class="offer-form-wrapper">
            <div class="row">
              <div class="col-12">
                <h6 class="temp-form-subheader">Offer</h6>
              </div>
              <div class="standard-form-group">
                <date-input
                  input-id="offersMade-offerDate"
                  name="Offer Date"
                  v-model="editState.newOfferForm.offerDate"
                />
              </div>
              <div class="standard-form-group">
                <time-input
                  input-id="offersMade-offerTime"
                  name="Offer Time"
                  v-model="editState.newOfferForm.offerTime"
                />
              </div>
              <div class="standard-form-group">
                <select-input
                  select-id="offersMade-organ"
                  name="Organ"
                  :options="organOptions"
                  v-model="editState.newOfferForm.organ"
                />
              </div>
              <div class="standard-form-group">
                <select-input
                  select-id="offersMade-laterality"
                  name="Laterality"
                  :options="lateralityOptions"
                  v-model="editState.newOfferForm.laterality"
                />
              </div>
              <div class="standard-form-group">
                <select-input
                  select-id="offersMade-offerType"
                  name="Offer Type"
                  :options="offerTypeOptions"
                  v-model="editState.newOfferForm.offerType"
                />
              </div>
              <!-- Patient field -->
              <div class="standard-form-group" v-if="editState.recipientSearch">
                <validation-provider
                  vid="offers-made-search"
                  class="generic-tags"
                  v-slot="{ errors }"
                >
                  <label for="offers-made-search">
                    Patient
                  </label>
                  <vue-tags-input
                    id="offers-made-search"
                    :class="{ 'select-multi': true, 'is-invalid': errors[0] }"
                    v-model="editState.recipientSearch.searchTerm"
                    :tags="editState.recipientSearch.tags"
                    :add-on-key="[13]"
                    :autocomplete-items="recipientSearchOptions"
                    :max-tags="1"
                    :autocomplete-min-length="1"
                    :placeholder="recipientSearchPlaceholder"
                    @tags-changed="onTagsChanged"
                    @before-adding-tag="onBeforeAddingTag"
                    :add-only-from-autocomplete="true"
                  >
                    <template v-slot:autocomplete-item="props">
                      <div @click="props.performAdd(props.item)">
                        <span class="text">{{ props.item.text }}</span><span v-if="props.item.hint" class="hint">{{ props.item.hint }}</span>
                      </div>
                    </template>
                  </vue-tags-input>
                  <template v-if="isLoadingRecipientList">
                    <font-awesome-icon style="float: right;top: -26px;position: relative;right: 24px;margin-bottom: -26px;" :icon="['far', 'spinner-third']" fixed-width spin aria-hidden="true" />
                  </template>
                  <div class="invalid-feedback" v-if="errors[0]">
                    <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
                    {{ translateError(errors, null) }}
                  </div>
                </validation-provider>
              </div>

            </div>
          </div>
          <div class="offer-form-wrapper">
            <div class="row">
              <div class="col-12">
                <h6 class="temp-form-subheader">Response</h6>
              </div>
              <div class="standard-form-group">
                <date-input
                  input-id="offersMade-responseDate"
                  name="Response Date"
                  v-model="editState.newOfferForm.responseDate"
                />
              </div>
              <div class="standard-form-group">
                <time-input
                  input-id="offersMade-responseTime"
                  name="Response Time"
                  v-model="editState.newOfferForm.responseTime"
                />
              </div>
              <div class="standard-form-group">
                <select-input
                  select-id="offersMade-coordinator"
                  name="Coordinator"
                  :options="coordinatorOptions"
                  v-model="editState.newOfferForm.coordinator"
                />
              </div>
              <div class="standard-form-group">
                <select-input
                  select-id="offersMade-surgeon"
                  name="Coordinator"
                  :options="surgeonOptions"
                  v-model="editState.newOfferForm.surgeon"
                />
              </div>
              <div class="standard-form-group">
                <select-input
                  select-id="offersMade-offerResponse"
                  name="Offer Response"
                  :options="offerResponseOptions"
                  v-model="editState.newOfferForm.offerResponse"
                />
              </div>
              <div class="row-break d-none d-xxl-block"></div>
            </div>
          </div>
          <div class="offer-form-wrapper">
            <div class="row">
              <div class="col-12">
                <h6 class="temp-form-subheader">Disposition</h6>
              </div>
              <div class="standard-form-group">
                <select-input
                  select-id="offersMade-outcomeDisposition"
                  name="Offer Response"
                  :options="outcomeDispositionOptions"
                  v-model="editState.newOfferForm.outcomeDisposition"
                />
              </div>
              <div class="standard-form-group-large">
                <text-area-input
                  input-id="offersMade-notes"
                  name="Notes"
                  v-model="editState.newOfferForm.notes"
                />
              </div>
            </div>
          </div>
        </fieldset>
      </fieldset>
    </template>
  </card-section>
</template>

<script lang="ts">
import { Getter, State } from 'vuex-class';
import { mixins } from "vue-class-component";
import { GenericCodeValue } from '@/store/types';
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TagObject } from '@/store/utilities/types';
import { Recipient } from '@/store/recipients/types';
import { Component, Vue, Watch } from 'vue-property-decorator';
import DateInput from "@/components/shared/DateInput.vue";
import TimeInput from "@/components/shared/TimeInput.vue";
import SelectInput from "@/components/shared/SelectInput.vue";
import TextAreaInput from "@/components/shared/TextAreaInput.vue";
import { VueTagsInput, createTag, createTags } from '@johmun/vue-tags-input';
import tablejson from '@/components/prototypes/json/OffersMade.json';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import { SaveResult, SaveProvider } from '@/types';
import CardSection from '@/components/shared/CardSection.vue';

interface OffersMadeForm {
  recipientSearch: RecipientSearchForm;
  recipientDetails: RecipientDetailsForm;
  newOfferForm: NewOfferForm;
  newRow: OfferRow|null;
}

interface RecipientSearchForm {
  searchTerm: string;
  tags: string[];
}

interface RecipientDetailsForm {
  recipientId: string|null;
  lastName: string|null;
}

interface NewOfferForm {
  offerDate: string|null;
  offerTime: string|null;
  organ: string|null;
  laterality: string|null;
  offerType: string|null;
  responseDate: string|null;
  responseTime: string|null;
  coordinator: string|null;
  surgeon: string|null;
  offerResponse: string|null;
  responseCategory: string|null;
  outcomeDisposition: string|null;
  notes: string|null;
}

interface OfferRow {
  id: string;
  class: string;
  deceased_donor_id: number;
  offer_date: string;
  organ: string;
  laterality: string;
  offer_type: string;
  patient: string;
  response_date: string;
  coordinator: string;
  surgeon: string;
  offer_response: string;
  response_category: string;
  outcome_disposition: string;
  notes: string;
}

// Constants related to recipient search index query
const INDEX_SEARCH_FIELDS = [
  'client_id',
  'last_name',
];

const INDEX_SEARCH_STRATEGY = 'or';
const INDEX_PAGE_SIZE = 25;
const INDEX_PAGE = 1;

@Component({
  components: {
    CardSection,
    DateInput,
    TimeInput,
    SelectInput,
    TextAreaInput,
    VueTagsInput,
    SaveToolbar,
  }
})
export default class OffersMade extends mixins(DateUtilsMixin) {
  @State(state => state.recipients.selectedRecipient) selectedRecipient!: Recipient;
  @State(state => state.pageState.currentPage.offersMade) editState!: OffersMadeForm;

  @Getter('showList', { namespace: 'recipients' }) recipientList!: { entries: any[] };
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;
  @Getter('optionsFor', { namespace: 'lookups' }) optionsFor!: (items: string[]) => GenericCodeValue[];
  
  // Local component state
  private isLoadingRecipientList = false;
  private isLoadingSelectedRecipient = false;

  private openCompareModal(donorId: any) {
    this.$emit('openCompareModal', donorId);
  }

  // Initialize the form before the page mounts
  private mounted(): void {
    // Clear previously loaded data
    this.clearRecipientList();
    this.clearSelectedRecipient();

    // Initialize the form edit state
    this.initializeForm();
  }

  // Clears out the recipient list
  private clearRecipientList(): void {
    this.$store.commit('recipients/setList', undefined);
  }

  // Clear any previously loaded recipient info
  private clearSelectedRecipient(): void {
    this.$store.commit('recipients/clearRecipient');
  }

  // Build the page component's editable form state 
  private initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'offersMade',
      value: this.buildFormState(),
    });
  }

  // Define form state entire form
  public buildFormState(): OffersMadeForm {
    const result = {
      recipientSearch: {
        searchTerm: '',
        tags: [],
      },
      recipientDetails: this.buildRecipientDetailsFormState(null),
      newOfferForm: {
        offerDate: null,
        offerTime: null,
        organ: null,
        laterality: null,
        offerType: null,
        responseDate: null,
        responseTime: null,
        coordinator: null,
        surgeon: null,
        offerResponse: null,
        responseCategory: null,
        outcomeDisposition: null,
        notes: null,
      },
      newRow: null,
    };
    return result;
  }

  // Define form state for just the Recipient Details form
  private buildRecipientDetailsFormState(recipient: Recipient|null): RecipientDetailsForm {
    // Link date
    const result: RecipientDetailsForm = {
      recipientId: null,
      lastName: null,
    };
    if (!recipient) return result;

    Object.assign(result, {
      recipientId: recipient?.client_id,
      lastName: recipient?.patient_profile?.last_name,
    });
    return result;
  }

  get offerRows(): OfferRow[] {
    const mockOfferData = tablejson.offers || [];

    const result = [...mockOfferData];

    const newRow = this.editState?.newRow;
    if (newRow) result.push(newRow);

    return result;
  }

  // List of recipients matching search criteria with applicable journey
  get recipientSearchOptions(): TagObject[] {
    if (!this.recipientList) return [];

    const entries = this.recipientList?.entries || [];
    const options: TagObject[] = entries.map((recipient: any): TagObject => {
      const hints: string[] = [];
      const text = `${recipient.client_id} - ${recipient.last_name}`;
      hints.push(recipient.first_name);
      hints.push(recipient.last_name);
      return {
        text,
        code: recipient.client_id,
        hint: hints.join(' '),
      };
    });

    return options;
  }

  /**
   * Placeholder text for Recipient Search tag entry
   *
   * @returns {string} placeholder text if empty, or empty string when a tag has been entered
   */
  get recipientSearchPlaceholder(): string {
    if ((this.editState?.recipientSearch?.tags || []).length > 0) return '';

    return 'Enter Afflo ID or Last Name';
  }

  // Handle changes to the search term tags
  private onTagsChanged(tags: TagObject[]): void {
    // Set tag in search area
    const newTags = createTags(tags);
    Vue.set(this.editState.recipientSearch, 'tags', newTags);

    // Update results area
    if (newTags.length > 0) {
      // Use tag code as recipient ID
      const firstTag = newTags[0];
      const recipientId = firstTag.code;
      this.updateRecipientDetails(recipientId);
    } else {
      // Clear any previously loaded recipient info
      this.updateRecipientDetails(null);
    }
  }

  // Sanitize entry of a search term tag
  private onBeforeAddingTag(event: { tag: TagObject, addTag: () => void; }): void {
    event.addTag();
  }

  // Update Recipient Details form area based on ID
  private updateRecipientDetails(recipientClientId: number|null): void {
    Vue.set(this.editState.recipientDetails, 'recipientId', recipientClientId);

    // Reload recipient info based on Recipient TGLN ID
    this.reloadSelectedRecipient(recipientClientId);
  }

  // Get the Recipient Details to show
  private reloadSelectedRecipient(recipientClientId?: number|null): void {
    // Clear any previously loaded recipient info
    this.clearSelectedRecipient();
    if (!recipientClientId) {
      this.syncRecipientDetailsArea();
      return;
    }

    // Load recipient info based on Recipient ID
    this.isLoadingSelectedRecipient = true;
    this.$store.dispatch('recipients/get', recipientClientId).then(() => {
      // Loaded successfully
      this.syncRecipientDetailsArea();
      this.isLoadingSelectedRecipient = false;
    }).catch((error: any) => {
      // Could not load due to unexpected error
      console.warn(error);
      this.isLoadingSelectedRecipient = false;
    });
  }

  // Reset just the recipient details portion of the form state
  private syncRecipientDetailsArea(): void {
    const recipient: Recipient|null = this.selectedRecipient || null;

    this.$store.commit('pageState/set', {
      pageKey: 'offersMade',
      componentKey: 'recipientDetails',
      value: this.buildRecipientDetailsFormState(recipient)
    });
  }

  // Watch for changes to recipient search term
  @Watch('editState.recipientSearch.searchTerm')
  private onSearchTermChanged(): void {
    this.reloadRecipientList();
  }

  /**
   * Load recipients based on search term, using the 'OR' search strategy to Search the same search term duplicated in all three searched fields
   *
   * Note: clear out the list and resolves immediately if there is no search term
   *
   * @returns {Promise<void>} promise that resolves after recipients are loaded, or rejects if an error is caught
   */
  private reloadRecipientList(): Promise<void> {
    const searchTerm = this.editState?.recipientSearch?.searchTerm;
    if (!searchTerm) return new Promise<void>((resolve) => {
      this.clearRecipientList();
      resolve();
    });

    // Configure search to query the search term in every search field simultaneously
    const searchCriteria: { [key: string]: string } = {
      search_strategy: INDEX_SEARCH_STRATEGY
    };
    INDEX_SEARCH_FIELDS.forEach((key: string) => {
      searchCriteria[key] = searchTerm;
    });

    // Define recipients index action options based on search configuration
    const mappedParams: string[] = [];
    Object.keys(searchCriteria).forEach((key: any) => {
      mappedParams.push(`${key}=${searchCriteria[key]}`);
    });
    const opts = {
      pageNumber: INDEX_PAGE,
      pageSize: INDEX_PAGE_SIZE,
      search_params: `&${mappedParams.join('&')}`,
      oopResults: false
    };

    // Start loading and dispatch the recipients index action
    this.isLoadingRecipientList = true;
    return new Promise<void>((resolve, reject) => {
      this.$store.dispatch('recipients/getList', opts).then(() => {
        // Loaded successfully
        this.isLoadingRecipientList = false;
        resolve();
      }).catch((error: any) => {
        // Could not load due to unexpected error
        console.warn(error);
        this.isLoadingRecipientList = false;
        reject();
      });
    });
  }

  private saveNewOffer(): void {
    const saveProvider = this.$refs.saveNewOffer as unknown as SaveProvider;
    if (!saveProvider) return;

    this.$emit('saving', 'saveNewOffer');

    const numOffers = this.offerRows.length;
    const newOfferForm = this.editState.newOfferForm || {};
    const recipientDetails = this.editState.recipientDetails || {};
    const newRow = {
      id: `offer_${numOffers}`,
      class: 'offer-row-no-offer',
      deceased_donor_id: 552288,
      offer_date: [this.parseDisplayDateUi(newOfferForm.offerDate), newOfferForm.offerTime].join(' ').trim() || '-',
      organ: newOfferForm.organ || '-',
      laterality: newOfferForm.laterality || '-',
      offer_type: newOfferForm.offerType || '-',
      patient: recipientDetails.recipientId ? `${recipientDetails.recipientId} - ${recipientDetails.lastName}` : '-',
      response_date: [this.parseDisplayDateUi(newOfferForm.responseDate), newOfferForm.responseTime].join(' ').trim() || '-',
      coordinator: newOfferForm.coordinator || '-',
      surgeon: newOfferForm.surgeon || '-',
      offer_response: newOfferForm.offerResponse || '-',
      response_category: newOfferForm.responseCategory || '-',
      outcome_disposition: newOfferForm.outcomeDisposition || '-',
      notes: newOfferForm.notes || '-',
    };

    setTimeout(() => {
      Vue.set(this.editState, 'newRow', newRow);
      saveProvider.registerSaveResult({ success: true });
    }, 500);

  }

  get organOptions(): GenericCodeValue[] {
    return this.optionsFor([
      'Kidney',
      'Liver',
      'Heart',
      'Lung',
      'Pancreas',
      'Small Bowel / Intestine',
      'Islets',
      'VCA',
    ]);
  }

  get lateralityOptions(): GenericCodeValue[] {
    return this.optionsFor([
      'Left',
      'Right',
      'Both',
    ]);
  }

  get offerTypeOptions(): GenericCodeValue[] {
    return this.optionsFor([
      'Primary',
      'Backup',
      'Offer',
    ]);
  }

  get coordinatorOptions(): GenericCodeValue[] {
    return this.optionsFor([
      'S. Wood',
      'M. Couch',
      'S. McPhee',
      'A. Johnson',
    ]);
  }

  get surgeonOptions(): GenericCodeValue[] {
    return this.optionsFor([
      'B. Jones',
      'A. Smith',
      'H. Payne',
      'M. Bridger',
      'W. Johnson',
    ]);
  }

  get offerResponseOptions(): GenericCodeValue[] {
    return this.optionsFor([
      'Accepted',
      'Accepted with conditions',
      'Declined',
      'Extension Required',
    ]);
  }

  get outcomeDispositionOptions(): GenericCodeValue[] {
    return this.optionsFor([
      'Transplanted',
      'Offer did not become primary',
      'Accepted - not transplanted',
    ]);
  }
}
</script>

<style scoped>
.table-bordered.table-demo-offers th, .table-demo-offers td, .table-demo-offers td input, .table-demo-offers td select {
  font-size: .8rem !important;
  font-weight: 600 !important;
}

.table-allocation:not(.table-offers) tbody > tr:first-child td, .table-offers:not(.table-offers) tbody > tr:first-child td {
	border-bottom: 1px solid #dee2e6;;
}
.temp-form-subheader {
  font-weight: 600;
}
.offer-form-wrapper {
  padding-top: .5rem;
  border-bottom: 1px solid #dee2e6;
}
</style>
