<i18n src='./_locales/MoreLess.yml'></i18n>

<template>
  <div aria-hidden="true">
   <truncate action-class="btn btn-sm btn-white" :clamp="$t('view_more')" :length="Number(maxDisplay)" :less="$t('view_less')" type="html" :text="descriptionText"></truncate>
  </div>
</template>
<script>
  import truncate from 'vue-truncate-collapsed';

  export default {
    components: {
      truncate  
    },
    props: {
      descriptionText: {type: String},
    },
    data() {
      return {
        maxDisplay: {type: Number, default: 400},
      };
    },
    mounted() {
      this.windowResize();
    },
    created() {
      window.addEventListener("resize", this.windowResize);
    },
    methods: {
      windowResize(e) {
        var w = window.innerWidth;
        if (w <= 550) {
          this.maxDisplay = 220;
        } else if (w >= 551 && w <= 768){
          this.maxDisplay = 280;
        } else if (w >= 769 && w <= 990){
          this.maxDisplay = 380;
        } else if (w >= 991 && w <= 1200){
          this.maxDisplay = 320;
        } else if (w >= 1201 && w <= 1400){
          this.maxDisplay = 400;
        } else if (w >= 1401) { 
          this.maxDisplay = 515;
        }
      }
    }
  };
</script>
