<template>
  <card-section section-id="donor-assessment-section"
    :isAccordion="prototypeFeatureEnabled('proto_ability_to_expand_collapse')">
    <template v-slot:header>
      Donor Assessment
    </template>
    <template v-slot:body>


      <sub-section sub-section-id="journey-assessment">
        <template v-slot:contents>
          <div class="row">
            <div class="col-12">
              <fieldset>
                <legend>
                  <h4 class="legend-title"><span class=""> Donor Assessment Decision </span>
                    <!---->
                  </h4>
                </legend>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="table-responsive-md">
                      <div class="vgt-wrap ">
                        <!---->
                        <div class="vgt-inner-wrap">
                          <!---->
                          <div class="vgt-global-search vgt-clearfix">
                            <div class="vgt-global-search__input vgt-pull-left">
                              <!---->
                            </div>
                            <div class="vgt-global-search__actions vgt-pull-right"><button type="button"
                                class="btn btn-primary btn-sm"> Create Donor Assessment Decision
                              </button></div>
                          </div>
                          <!---->
                          <div class="vgt-fixed-header">
                            <!---->
                          </div>
                          <div class="vgt-responsive">
                            <table id="vgt-table" class="vgt-table table table-bordered table-hover bordered ">
                              <colgroup>
                                <col id="col-0">
                                <col id="col-1">
                                <col id="col-2">
                                <col id="col-3">
                              </colgroup>
                              <thead>
                                <tr>
                                  <!---->
                                  <!---->
                                  <th scope="col" aria-sort="descending" aria-controls="col-0" class="vgt-left-align"
                                    style="min-width: 20%; max-width: 20%; width: 20%;">
                                    Date
                                    <!---->
                                  </th>
                                  <th scope="col" aria-sort="descending" aria-controls="col-2" class="vgt-left-align"
                                    style="min-width: 30%; max-width: 30%; width: 30%;">
                                    Decision to Conduct Assessment
                                    <!---->
                                  </th>
                                  <th scope="col" aria-sort="descending" aria-controls="col-3" class="vgt-left-align">
                                    Reason
                                    <!---->
                                  </th>
                                </tr>
                                <!---->
                              </thead>
                              <tbody>
                                <!---->
                                <tr class="clickable tr-link">
                                  <!---->
                                  <!---->
                                  <td colspan="3">
                                    <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width /> use the form
                                    below to add new Donor Assessment Decision </td>
                                </tr>
                                <tr>
                                  <!---->
                                  <!---->
                                  <!---->
                                  <th class="vgt-row-header vgt-left-align">
                                    <!----> <span class="summary-field"> </span></th>
                                  <th class="vgt-row-header vgt-left-align">
                                    <!----> <span class="summary-field"> Total Wait
                                      for Assessment </span>
                                  </th>
                                  <th class="vgt-row-header vgt-left-align summary-column">
                                    <!---->
                                    <span class="summary-field"> 0 days </span>
                                  </th>
                                </tr>
                              </tbody>
                              <!---->
                            </table>
                          </div>
                          <!---->
                          <!---->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset v-if="editState">
                <fieldset>
                  <legend>
                    <h5 class="legend-title"> New Donor Assessment </h5>
                  </legend>
                  <div class="row">
                    <div class="standard-form-group">
                      <date-input input-id="date-of-decision-to-conduct-organ-donation-assessment"
                        name="Date of Decision to Conduct Organ Donation Assessment"
                        v-model="editState.dateOfDecisionToConductOrganDonationAssessment" />
                    </div>
                    <div class="standard-form-group">
                      <boolean-radio-input input-id="status-of-decision-to-conduct-organ-donation-assessment"
                        label-name="Status of Decision to Conduct Organ Donation Assessment" :accept-id="true"
                        :decline-id="false" accept-label="Yes" decline-label="No"
                        v-model="editState.statusOfDecisionToConductOrganDonationAssessment" />
                    </div>
                    <div class="standard-form-group" v-if="showReasonForNotProceeding">
                      <span><label for="journey-assessment-coordinator" class="">Reason for Not
                          Proceeding to Organ Donation Assessment
                          <span><i>*</i></span></label>
                        <select id="journey-assessment-coordinator" class="form-control">
                          <option value=""> Select... </option>
                          <option value="1">Age</option>
                          <option value="1">ABO incompatible</option>
                          <option value="1">Size mismatch</option>
                          <option value="1">Crossmatch positive</option>
                          <option value="1">Living donor decision not to proceed</option>
                          <option value="1">Living donor reluctant</option>
                          <option value="1">Living donor died</option>
                          <option value="1">Unable to contact living donor</option>
                          <option value="1">Living donor transferred to another centre</option>
                          <option value="1">Another donor chosen</option>
                          <option value="1">Early referral (recipient has not been referred yet or is not
                            a candidate for transplant yet)</option>
                          <option value="1">Financial reasons</option>
                          <option value="1">Unsuitable for donation — Psychosocial</option>
                          <option value="1">Medical non-compliance</option>
                          <option value="1">Substance abuse</option>
                          <option value="1">Tobacco use</option>
                          <option value="1">BMI</option>
                          <option value="1">Medically unsuitable for donation — Other reasons</option>
                          <option value="1">Recipient-related reasons — Other</option>
                          <option value="1">Chain collapsed</option>
                          <option value="1">Other</option>
                          <option value="1">Unknown</option>
                          <!---->
                          <!---->
                        </select></span></div>
                  </div>
                  <div class="row">
                    <div class="standard-form-group">
                      <boolean-radio-input input-id="donor-cleared" label-name="Donor Cleared" :accept-id="true"
                        :decline-id="false" accept-label="Yes" decline-label="No" v-model="editState.donorCleared" />
                    </div>
                    <div class="standard-form-group">
                      <date-input input-id="date-of-decision-to-donate" name="Date of Decision to Donate"
                        v-model="editState.dateOfDecisionToDonate" />
                    </div>
                    <div class="standard-form-group" v-if="showReasonForNotDonating">
                      <span><label for="Reason-for-Not-Donating" class="">Reason
                          for Not Donating<span><i></i></span></label>
                        <select class="form-control" id="Reason-for-Not-Donating">
                          <option value=""> Select... </option>
                          <option value="1">Age</option>
                          <option value="1">ABO incompatible</option>
                          <option value="1">Size mismatch</option>
                          <option value="1">Crossmatch positive</option>
                          <option value="1">Living donor decision not to proceed</option>
                          <option value="1">Living donor reluctant</option>
                          <option value="1">Living donor died</option>
                          <option value="1">Unable to contact living donor</option>
                          <option value="1">Living donor transferred to another centre</option>
                          <option value="1">Another donor chosen</option>
                          <option value="1">Early referral (recipient has not been referred yet or is not
                            a candidate for transplant yet)</option>
                          <option value="1">Financial reasons</option>
                          <option value="1">Unsuitable for donation — Psychosocial</option>
                          <option value="1">Medical non-compliance</option>
                          <option value="1">Substance abuse</option>
                          <option value="1">Tobacco use</option>
                          <option value="1">BMI</option>
                          <option value="1">Medically unsuitable for donation — Other reasons</option>
                          <option value="1">Recipient-related reasons — Other</option>
                          <option value="1">Chain collapsed</option>
                          <option value="1">Other</option>
                          <option value="1">Unknown</option>
                          <!---->
                          <!---->
                        </select></span></div>
                  </div>
                  <div class="row">
                    <div class="standard-form-group">
                      <select-input select-id="living-donor-status" name="Living Donor Status" style="width: 100%"
                        :options="livingDonorStatusOptions" v-model="editState.livingDonorStatus" />
                    </div>

                    <template v-if="showStatusReason">
                      <div class="standard-form-group">
                        <select-input select-id="living-donor-status-reason-category" name="Reason Category"
                          style="width: 100%" :options="reasonCategoryOptions" v-model="editState.reasonCategory" />
                      </div>
                      <div class="standard-form-group"><span>
                          <label for="Reason-for-On-hold-Donor-reason" class="">Reason<span><i></i></span></label>
                          <select class="form-control" id="Reason-for-On-Hold-Donor-reason ">
                            <option value=""> Select... </option>
                            <option value="1">Medically unsuitable — Temporary</option>
                            <option value="1">Psychosocial issue(s) — Temporary</option>
                            <option value="1">Pending investigations</option>
                            <option value="1">Potential living donor paired exchange transplant</option>
                            <option value="1">Potential living donor — Desensitization (ABO or HLA)</option>
                            <option value="1">Not available (away)</option>
                            <option value="1">Other</option>
                            <option value="1">Unknown</option>
                          </select>
                        </span></div>
                    </template>
                  </div>
                </fieldset>
                <div class="save-toolbar card-footer action-row temp-saving row"><button type="button"
                    class="btn btn-success sec-submit"> Save Donor Assessment Decision </button>
                  <!---->
                </div>
              </fieldset>
            </div>
          </div>

        </template>
      </sub-section>
      <!-- Confirmation details -->

    </template>
  </card-section>


</template>


<script lang="ts">
  import {
    State,
    Getter
  } from 'vuex-class';
  import {
    GenericCodeValue
  } from "@/store/types";
  import {
    Component,
    Vue
  } from 'vue-property-decorator';
  import DateInput from "@/components/shared/DateInput.vue";
  import SelectInput from "@/components/shared/SelectInput.vue";
  import BooleanRadioInput from "@/components/shared/BooleanRadioInput.vue";
  import CountryInput from '@/components/shared/CountryInput.vue';
  import SubSection from '@/components/shared/SubSection.vue';
  import CardSection from '@/components/shared/CardSection.vue';
  import {
    Country,
    CountryValue,
    Province
  } from '@/store/lookups/types';

  @Component({
    components: {
      DateInput,
      SelectInput,
      BooleanRadioInput,
      CountryInput,
      SubSection,
      CardSection
    },
  })
  export default class DonorAssessment extends Vue {
    @State(state => state.pageState.currentPage.livingDonorAssessment) editState!: any;
    @State(state => state.lookups.country) countryLookup!: Country[];
    @State(state => state.lookups.us_state) usStateLookup!: Province[];
    @State(state => state.lookups.province) provinceLookup!: Province[];

    @Getter('optionsFor', {
      namespace: 'lookups'
    }) optionsFor!: (items: string[]) => GenericCodeValue[];
    @Getter('prototypeFeatureEnabled', {
      namespace: 'features'
    }) private prototypeFeatureEnabled!: (featureName: string) => boolean;

    private mounted(): void {
      this.initializeEditState();
    }

    private initializeEditState(): void {
      this.$store.commit('pageState/set', {
        pageKey: 'livingDonorAssessment',
        value: {},
      });
    }

    get livingDonorStatusOptions(): GenericCodeValue[] {
      return this.optionsFor([
        'New',
        'Active',
        'On Hold',
        'Inactive',
      ]);
    }

    get reasonCategoryOptions(): GenericCodeValue[] {
      return this.optionsFor([
        'Recipient',
        'Living Donor',
      ]);
    }

    get showReasonForNotProceeding(): boolean {
      if (!this.editState) return false;

      return this.editState.statusOfDecisionToConductOrganDonationAssessment ===
      false; // Only show if explicitly 'No'
    }

    get showReasonForNotDonating(): boolean {
      if (!this.editState) return false;

      return this.editState.donorCleared === false; // Only show if explicitly 'No'
    }

    get showStatusReason(): boolean {
      if (!this.editState) return false;

      const selectedStatus = this.editState.livingDonorStatus;
      if (selectedStatus == 'On Hold') return true;
      if (selectedStatus == 'Inactive') return true;

      return false;
    }
  }

</script>

<style scoped>
  .standard-form-group {
    display: flex;
    align-items: flex-end;
  }

</style>

<style>
  .p-label {
    white-space: normal !important;
  }

</style>
