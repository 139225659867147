<template>
  <div class="checklist border-0">
    <div class="form-check">
      <input
        type="checkbox"
        class="form-check-input d-none"
        :id="inputId"
        :aria-labelledby="inputId"
        :v-model="isChecked"
        v-on="inputEvents()"
      />
      <label
        class="form-check-label selected"
        :for="inputId"
      >
        <font-awesome-icon v-if="isChecked" :icon="['far', 'check-square']" />
        <font-awesome-icon v-else :icon="['far', 'square']" />
        <span>
          <slot name="label">
            {{ label }}
          </slot>
        </span>
      </label>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Model } from 'vue-property-decorator';
@Component
export default class ColumnConfigCheckboxInput extends Vue {
  // V-model
  @Model('change') value!: string;
  // Required props
  @Prop({ required: true }) inputId!: string; // HTML ID
  @Prop({ required: true }) label!: string; // Label text (what the user clicks on)
  // Initialize local copy of the value so it's available at template render
  get isChecked(): boolean {
    return !!this.value;
  }
  // Forward events to the parent component
  public inputEvents(): any {
    const _vm = this as ColumnConfigCheckboxInput;
    return Object.assign({},
      // parent listeners
      this.$listeners,
      {
        // custom listeners
        change(event: any) {
          // Emit updated value for v-model
          const newValue = !_vm.isChecked;
          _vm.$emit('change', newValue);
        }
      }
    );
  }
}
</script>
