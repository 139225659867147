import { render, staticRenderFns } from "./Dashboard.vue?vue&type=template&id=5ede7d3c"
import script from "./Dashboard.vue?vue&type=script&lang=ts"
export * from "./Dashboard.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/components/dashboard/_locales/Dashboard.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fdashboard%2FDashboard.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports