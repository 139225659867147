<template>
  <sub-section 
    sub-section-id="post-operative-care"
    ref="savePostOperativeCare"
    :title="$t('post_operative_care')" 
    :save-button-text="$t('save_post_operative_care')"
    :save-button="true"
    :is-parent-loading="!editState"
    :lookups-to-load="lookupsToLoad"
    :confirmation="confirmationText"
    @save="handleSave"
  >
    <template v-slot:contents>
      <fieldset>
        <template v-if="editState">
          <!-- Discharge and Comments -->
          <div class="row">
            <div class="standard-form-group">
              <date-input
                input-id="post-operative-care-date-of-hospital-discharge"
                :name="$t('date_of_hospital_discharge')"
                rules="required_if_filled:@timeOfHospitalDischarge"
                :cross-values="{ timeOfHospitalDischarge: editState.timeOfHospitalDischarge }"
                v-model="editState.dateOfHospitalDischarge"
              />
            </div>
            <div class="standard-form-group">
              <time-input
                input-id="post-operative-care-time-of-hospital-discharge"
                :name="$t('time_of_hospital_discharge')"
                rules="required_if_filled:@dateOfHospitalDischarge"
                :cross-values="{ dateOfHospitalDischarge: editState.dateOfHospitalDischarge }"
                v-model="editState.timeOfHospitalDischarge"
              />
            </div>
          </div>
          <div class="row">
            <div class="standard-form-group-large">
              <text-area-input
                input-id="post-operative-care-transplant-comments"
                :name="$t('transplant_comments')"
                v-model="editState.transplantComments"
              />
            </div>
          </div>

          <!-- Primary Graft Dysfunction (Heart, Lung) -->
          <template v-if="showPrimaryGraftDysfunction">
            <div class="hr-break" />
            <div class="row">
              <div class="standard-form-group">
                <boolean-radio-input
                  input-id="post-operative-care-primary-graft-dysfunction"
                  :label-name="$t('primary_graft_dysfunction')"
                  :accept-id="true"
                  :decline-id="false"
                  :accept-label="$t('yes')"
                  :decline-label="$t('no')"
                  v-model="editState.primaryGraftDysfunction"
                  @change="handlePrimaryGraftDysfunctionChanged"
                />
              </div>
              <div class="standard-form-group">
                <select-input
                  select-id="post-operative-care-primary-graft-dysfunction-grade"
                  :name="$t('primary_graft_dysfunction_grade')"
                  rules="required_if_filled:@primaryGraftDysfunction"
                  :cross-values="{ primaryGraftDysfunction: editState.primaryGraftDysfunction }"
                  :options="primaryGraftDysfunctionGradeOptions"
                  :disabled="!editState.primaryGraftDysfunction"
                  v-model="editState.primaryGraftDysfunctionGrade"
                />
              </div>
            </div>
          </template>

          <!-- Mechanical Circulatory Support -->
          <template v-if="showMechanicalCirculatorySupport">
            <div class="row">
              <div class="standard-form-group">
                <boolean-radio-input
                  input-id="post-operative-care-mechanical-circulatory-support"
                  :label-name="$t('post_operative_mechanical_circulatory_support')"
                  :accept-id="true"
                  :decline-id="false"
                  :accept-label="$t('yes')"
                  :decline-label="$t('no')"
                  v-model="editState.mechanicalCirculatorySupport"
                  @change="handleMechanicalCirculatorySupportChanged"
                />
              </div>
              <div class="standard-form-group">
                <select-input
                  select-id="post-operative-care-mechanical-circulatory-support-type"
                  :name="$t('mechanical_circulatory_support_type')"
                  rules="required_if_filled:@mechanicalCirculatorySupport"
                  :cross-values="{ mechanicalCirculatorySupport: editState.mechanicalCirculatorySupport }"
                  :options="mechanicalCirculatorySupportTypeOptions"
                  :disabled="!editState.mechanicalCirculatorySupport"
                  v-model="editState.mechanicalCirculatorySupportType"
                />
              </div>
              <div class="standard-form-group">
                <date-input
                  input-id="post-operative-care-mechanical-circulatory-support-separation-date"
                  :name="$t('mechanical_circulatory_support_separation_date')"
                  rules="required_if_filled:@mechanicalCirculatorySupport"
                  :disabled="!editState.mechanicalCirculatorySupport"
                  v-model="editState.mechanicalCirculatorySupportSeparationDate"
                />
              </div>
            </div>
          </template>

          <!-- Complications and Death -->
          <div class="hr-break" />
          <div class="row">
            <div class="standard-form-group">
              <checkbox-input
                input-id="post-operative-care-surgical-complications"
                :label-name="$t('surgical_complications')"
                :label="$t('yes')"
                v-model="editState.surgicalComplications"
                @change="handleSurgicalComplcationsChanged"
              />
            </div>
            <div class="standard-form-group">
              <select-input
                select-id="post-operative-care-clavien-dindo-classification"
                :name="$t('clavien_dindo_classification')"
                rules="required_if_filled:@surgicalComplications"
                :cross-values="{ surgicalComplications: editState.surgicalComplications }"
                :options="clavientDindoClassificationOptions"
                :disabled="!editState.surgicalComplications"
                v-model="editState.clavientDindoClassification"
              />
            </div>
            <div class="standard-form-group">
              <select-input
                select-id="post-operative-care-surgical-complication-category"
                :name="$t('surgical_complication_category')"
                :options="surgicalComplicationCategoryOptions"
                :disabled="!editState.surgicalComplications"
                v-model="editState.surgicalComplicationCategory"
                @change="handleSurgicalComplicationCategoryChanged"
              />
            </div>
            <div class="standard-form-group">
              <select-input
                select-id="post-operative-care-surgical-complication-type"
                :name="$t('surgical_complication_type')"
                :numeric="true"
                :options="surgicalComplicationTypeOptions"
                :disabled="!editState.surgicalComplications"
                v-model="editState.surgicalComplicationType"
              />
            </div>
          </div>
          <div class="row">
            <div class="standard-form-group">
              <checkbox-input
                input-id="post-operative-care-death-related-to-transplant-surgery"
                :label-name="$t('death_related_to_transplant_surgery')"
                :label="$t('yes')"
                v-model="editState.deathRelatedToTransplantSurgery"
              />
            </div>
            <div class="standard-form-group" v-if="editState.deathRelatedToTransplantSurgery">
              <boolean-radio-input
                input-id="post-operative-care-intra-operative-death"
                :label-name="$t('intra_operative_death')"
                :accept-id="true"
                :decline-id="false"
                :accept-label="$t('yes')"
                :decline-label="$t('no')"
                rules="required"
                v-model="editState.intraOperativeDeath"
              />
            </div>
            <div class="standard-form-group" v-if="editState.deathRelatedToTransplantSurgery">
              <boolean-radio-input
                input-id="post-operative-care-death-in-hospital"
                :label-name="$t('death_in_hospital')"
                :accept-id="true"
                :decline-id="false"
                :accept-label="$t('yes')"
                :decline-label="$t('no')"
                rules="required"
                v-model="editState.deathInHospital"
              />
            </div>
          </div>
          <fieldset v-if="editState.deathRelatedToTransplantSurgery">
            <div class="row">
              <div class="standard-form-group">
                <select-input
                  select-id="post-operative-care-death-category"
                  :name="$t('cause_of_death_category')"
                  rules="required"
                  :options="causeOfDeathCategoryOptions"
                  v-model="editState.causeOfDeathCategory"
                />
              </div>
              <div class="standard-form-group">
                <select-input
                  select-id="post-operative-care-death-type"
                  :name="$t('cause_of_death_type')"
                  rules="required"
                  :numeric="true"
                  :options="causeOfDeathTypeOptions"
                  v-model="editState.causeOfDeathType"
                />
              </div>
              <div class="standard-form-group">
                <date-input
                  input-id="post-operative-care-death-date"
                  :name="$t('death_date')"
                  rules="required"
                  v-model="editState.deathDate"
                />
              </div>
              <div class="standard-form-group">
                <checkbox-input
                  input-id="journey-post-transplant-follow-up-estimated"
                  :label-name="$t('estimated')"
                  :label="$t('yes')"
                  v-model="editState.estimated"
                />
              </div>
            </div>
          </fieldset>
        </template>
      </fieldset>
    </template>
  </sub-section>
</template>

<i18n src="./_locales/PostOperativeCare.json"></i18n>

<script lang="ts">
import { Organ, OrganCodeValue } from '@/store/lookups/types';
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { State, Getter }  from 'vuex-class';
import { SaveResult, SaveProvider } from '@/types';
import { Component, Vue } from "vue-property-decorator";
import { GenericCodeValue, NumericCodeValue } from "@/store/types";
import SubSection from "@/components/shared/SubSection.vue";
import DateInput from "@/components/shared/DateInput.vue";
import TimeInput from "@/components/shared/TimeInput.vue";
import TextAreaInput from "@/components/shared/TextAreaInput.vue";
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import SelectInput from "@/components/shared/SelectInput.vue";
import BooleanRadioInput from '@/components/shared/BooleanRadioInput.vue';
import { RecipientJourney } from '@/store/recipientJourney/types';

export interface PostOperativeCareForm {
  dateOfHospitalDischarge:                    string|null;
  timeOfHospitalDischarge:                    string|null;
  transplantComments:                         string|null;

  primaryGraftDysfunction:                    boolean|null;
  primaryGraftDysfunctionGrade:               string|null;
  mechanicalCirculatorySupport:               boolean|null;
  mechanicalCirculatorySupportType:           string|null;
  mechanicalCirculatorySupportSeparationDate: string|null;

  surgicalComplications:                      boolean;
  surgicalComplicationCategory:               number|null;
  surgicalComplicationType:                   number|null;
  clavientDindoClassification:                string|null;

  deathRelatedToTransplantSurgery:            boolean;
  intraOperativeDeath:                        boolean|null;
  deathInHospital:                            boolean|null;
  causeOfDeathCategory:                       string|null;
  causeOfDeathType:                           number|null;
  deathDate:                                  string|null;
  estimated:                                  boolean|null;
}

const PRIMARY_GRAFT_DYSFUNCTION_ORGANS = [
  OrganCodeValue.Heart,
  OrganCodeValue.Lung,
];

const MECHANICAL_CIRCULATORY_SUPPORT_ORGANS = [
  OrganCodeValue.Heart,
  OrganCodeValue.Lung,
];

@Component({
  components: {
    SubSection,
    DateInput,
    TimeInput,
    TextAreaInput,
    CheckboxInput,
    SelectInput,
    BooleanRadioInput,
  }
})
export default class PostOperativeCare extends mixins(DateUtilsMixin) {
  @State(state => state.pageState.currentPage.postOperativeCare) editState!: PostOperativeCareForm;
  @State(state => state.lookups.complication_categories) private surgicalComplicationCategoryOptions!: NumericCodeValue[];
  @State(state => state.lookups.cause_of_death_category) private causeOfDeathCategoryOptions!: GenericCodeValue[];
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;

  @Getter("causeOfDeathTypeLookup", { namespace: "lookups" }) causeOfDeathTypeByCategory!: (deathCode: string) => NumericCodeValue[];
  @Getter('optionsFor', { namespace: 'lookups' }) optionsFor!: (items: string[]) => GenericCodeValue[];

  lookupsToLoad = [
    'complication_categories',
    'cause_of_death_category',
  ];

  get primaryGraftDysfunctionGradeOptions(): GenericCodeValue[] {
    return this.optionsFor([
      "Heart moderate PGD — Left ventricle",
      "Heart severe PGD — Left ventricle",
      "Heart PGD — Right ventricle",
      "Lung PGD Grade 2",
      "Lung PGD Grade 3 ",
    ]);
  }

  get mechanicalCirculatorySupportTypeOptions(): GenericCodeValue[] {
    return this.optionsFor([
      "IABP - Intra-Aortic Balloon Pump",
      "LVAD - Left Ventricular Assist Device",
      "RVAD - Right Ventricular Assist Device",
      "Artificial heart",
      "VA ECMO - Venoarterial Extracorporeal Membrane Oxygenation",
      "VV ECMO - Venovenous Extracorporeal Membrane Oxygenation",
      "Other",
      "Unknown",
    ]);
  }

  get clavientDindoClassificationOptions(): GenericCodeValue[] {
    return this.optionsFor([
      "Grade I",
      "Grade II",
      "Grade IIIa",
      "Grade IIIb",
      "Grade IVa",
      "Grade IVb",
      "Grade V", 
    ]);
  }

  get causeOfDeathTypeOptions(): NumericCodeValue[] {
    const causeOfDeathCategory = this.editState?.causeOfDeathCategory;
    if (causeOfDeathCategory == null) return [];

    return this.causeOfDeathTypeByCategory(causeOfDeathCategory);
  }

  get surgicalComplicationTypeOptions(): NumericCodeValue[] {
    const surgicalComplicationCategory = this.editState?.surgicalComplicationCategory;
    if (surgicalComplicationCategory == null) return [];

    const surgicalComplicationCategoryLookup = this.surgicalComplicationCategoryOptions.find((lookup: NumericCodeValue) => {
      return lookup.code == surgicalComplicationCategory;
    });
    if (!surgicalComplicationCategoryLookup || !surgicalComplicationCategoryLookup.sub_tables) return [];

    return surgicalComplicationCategoryLookup.sub_tables?.complication_types || [];
  }

  get confirmationText(): string|undefined {
    if (!this.editState || !this.editState.deathRelatedToTransplantSurgery) return undefined;

    const deathDate = this.editState.deathDate;
    return this.$t('death_confirmation', { death_date: this.parseDisplayDateUi(deathDate) || 'TODAY' }).toString();
  }

  get showPrimaryGraftDysfunction(): boolean {
    if (!this.journey) return false;

    const organCode = this.journey?.organ_code || null;
    return PRIMARY_GRAFT_DYSFUNCTION_ORGANS.includes(organCode as OrganCodeValue);
  }

  get showMechanicalCirculatorySupport(): boolean {
    if (!this.journey) return false;

    const organCode = this.journey?.organ_code || null;
    return MECHANICAL_CIRCULATORY_SUPPORT_ORGANS.includes(organCode as OrganCodeValue);
  }

  private mounted(): void {
    this.initializeEditState();
  }

  private initializeEditState(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'postOperativeCare',
      value: this.buildEditState(),
    });
  }

  private buildEditState(): PostOperativeCareForm {
    return {
      dateOfHospitalDischarge:                    null,
      timeOfHospitalDischarge:                    null,
      transplantComments:                         null,

      primaryGraftDysfunction:                    null,
      primaryGraftDysfunctionGrade:               null,
      mechanicalCirculatorySupport:               null,
      mechanicalCirculatorySupportType:           null,
      mechanicalCirculatorySupportSeparationDate: null,

      surgicalComplications:                      false,
      clavientDindoClassification:                null,
      surgicalComplicationCategory:               null,
      surgicalComplicationType:                   null,

      deathRelatedToTransplantSurgery:            false,
      intraOperativeDeath:                        null,
      deathInHospital:                            null,
      causeOfDeathCategory:                       null,
      causeOfDeathType:                           null,
      deathDate:                                  null,
      estimated:                                  null,
    };
  }

  private handlePrimaryGraftDysfunctionChanged(): void {
    if (!this.editState) return;

    Vue.set(this.editState, 'primaryGraftDysfunctionGrade', null);
  }

  private handleMechanicalCirculatorySupportChanged(): void {
    if (!this.editState) return;

    Vue.set(this.editState, 'mechanicalCirculatorySupportType', null);
    Vue.set(this.editState, 'mechanicalCirculatorySupportSeparationDate', null);
  }
  
  private handleSurgicalComplcationsChanged(): void {
    if (!this.editState) return;

    Vue.set(this.editState, 'clavientDindoClassification', null);
    Vue.set(this.editState, 'surgicalComplicationCategory', null);
    Vue.set(this.editState, 'surgicalComplicationType', null);
  }

  private handleSurgicalComplicationCategoryChanged(): void {
    if (!this.editState) return;

    Vue.set(this.editState, 'surgicalComplicationType', null);
  }

  private handleSave(): void {
    const saveProvider = this.$refs.savePostOperativeCare as unknown as SaveProvider;
    if (!saveProvider) return;

    this.$emit('saving', 'postOperativeCare');
    setTimeout(() => {
      saveProvider.registerSaveResult({ success: true });
    }, 500);
  }

}
</script>
