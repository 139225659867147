<template>
  <card-section
    section-id="social_history"   
    :isAccordion="prototypeFeatureEnabled('proto_ability_to_expand_collapse')"
  >
    <template v-slot:header>
      {{ title }}
    </template>
    <template v-slot:body>
      <div>
        <fieldset id="social_history">
          <legend >
            <h4 class="legend-title">
              <span>{{ title }}</span>
            </h4>
          </legend>
        </fieldset>
      </div>
      <div class="row">
        <div class="col-sm-12">
      
          <fieldset>
            <div class="row">
              <div class="standard-form-group">
                <select-input
                  select-id="alcohol_use"
                  name='Alcohol Use'
                  :options="Options"
                  v-model="alcohol_use"
                >
                </select-input>
              </div>
              <div class="standard-form-group">
                <select-input
                  select-id="smoking_history"
                  name='Smoking History'
                  :options="Options"
                  v-model="smoking_use"
                >
                </select-input>
              </div>
              <div class="standard-form-group">
                <number-input
                  inputId="smoking_rate"
                  rules="min_value: 0 |max_value: 99"
                  name="Smoking Rate"
                  v-model="smoking_rate"
                />
              </div>
              <div class="standard-form-group">
                <number-input
                  inputId="smoking_duration"
                  rules="min_value: 0 |max_value: 130"
                  name="Smoking Duration"
                  v-model="smoking_duration"
                />
              </div>
              <div class="standard-form-group">
                <select-input
                  select-id="marijuana_use"
                  name='History of Marijuana Use'
                  :options="Options"
                  v-model="marijuana_use"
                >
                </select-input>
              </div>
              <div class="standard-form-group">
                <select-input
                  select-id="drug_use"
                  name='Drug Use'
                  :options="Options"
                  v-model="drug_use"
                >
                </select-input>
              </div>
            </div>
            
            
          </fieldset>
        </div>
      
      </div>
      <div class="row">
        <div class="col-sm-12">
          <save-toolbar
            class="save-toolbar action-row temp-saving"
            buttonClass="btn btn-success sec-submit"
            label="Save Social History"
            @save="performSave"
          />
        </div>
      </div>
      
    </template>
  </card-section>
</template>

<script lang="ts">
import { Getter, State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import CardSection from "@/components/shared/CardSection.vue";
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import NumberInput from '@/components/shared/NumberInput.vue';

@Component({
  components: {
    CardSection,
    SaveToolbar,
    SelectInput,
    NumberInput
  }
})
export default class SocialHistory extends Vue {  
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;

  title = 'Social History';
  private alcohol_use = '';
  private smoking_use = '';
  private smoking_rate = '';
  private smoking_duration = '';
  private marijuana_use = '';
  private drug_use = '';

  private Options = [{code: 0, value: 'Yes'}, {code: 1, value: 'No'},{code: 2, value: 'Unknown'}];


  private mounted(): void {
    let items = localStorage.getItem("socialHistoryStorage");

    if (items) {
      let parsed = JSON.parse(items);
      this.alcohol_use = parsed.alcohol_use;
      this.smoking_use = parsed.smoking_use;
      this.smoking_rate = parsed.smoking_rate;
      this.smoking_duration = parsed.smoking_duration;
      this.marijuana_use = parsed.marijuana_use;
      this.drug_use = parsed.drug_use;
    }
  
  }

  public performSave(): void {
    const socialHistoryStorage = {
    "alcohol_use":  this.alcohol_use,
    "smoking_use": this.smoking_use,
    "smoking_rate": this.smoking_rate,
    "smoking_duration": this.smoking_duration,
    "marijuana_use": this.marijuana_use,
    "drug_use": this.drug_use
  };

    localStorage.setItem("socialHistoryStorage", JSON.stringify(socialHistoryStorage));
  }


  

}
</script>

<style>


</style>
