<template>
  <form class="form-inline form-search" @submit.stop.prevent="performSearch()" method="get">
    <input type="hidden" name="type" :value="selectedSearchOption">
    <label for="header-search" class="sr-only">{{$t('search_recipient_and_records')}}}</label>
    <div class="input-group" v-if="canSearchAnything">
      <div class="input-group-prepend">
        <button
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          class="btn btn-outline dropdown-toggle dropdown-toggle-split shadow-none"
        >
          {{ availableSearchCategories[selectedSearchOption] }}
          <font-awesome-icon :icon="['fas', 'caret-down']" />
          <span class="sr-only">{{$t('toggle_search_options_dropdown')}}</span>
        </button>
        <div
          class="dropdown-menu dropdown-menu-right"
          x-placement="bottom-end"
        >
          <a v-for="k in Object.keys(availableSearchCategories)"
             :key="k"
             href="#"
             @click.prevent="selectedSearchOption = k"
             class="dropdown-item">
            {{ availableSearchCategories[k] }}
          </a>
        </div>
      </div>
      <input id="header-search"
             name="term"
             type="text"
             v-model="searchTerm"
             :placeholder="$t('search')"
             class="form-control border-0">
      <div class="input-group-append">
        <button type="submit" class="btn btn-outline-primary shadow-none">
          <font-awesome-icon :icon="['fas', 'search']" />
          <span class="sr-only">{{$t('search')}}</span>
        </button>
      </div>
    </div>
  </form>
</template>

<i18n src="./_locales/SearchBar.json"></i18n>

<script lang="ts">
import { mixins } from 'vue-class-component';
import { Component, Vue } from 'vue-property-decorator';
import {UrlUtilsMixin} from "@/mixins/url-utils-mixin";
import {Getter} from "vuex-class";
import {EP} from "@/api-endpoints";

@Component
export default class NavSearch extends mixins(UrlUtilsMixin) {

  @Getter('checkAllowed', { namespace: 'users' } ) private checkAllowed!: (url: string, method?: string) => boolean;
  selectedSearchOption = 'recipients';
  searchTerm = '';

  get availableSearchCategories() {
    const availableCategories: any = {};
    if(this.checkAllowed(EP.recipients.create)) {
      availableCategories.recipients = this.$t('Patients');
    }
    if(this.checkAllowed(EP.deceasedDonors.create)) {
      availableCategories.donors = this.$t('donor_records');
    }

    return availableCategories;
  }

  get canSearchAnything(): boolean {
    return Object.keys(this.availableSearchCategories).length > 0;
  }
  public mounted() {
    this.searchTerm = this.getUrlQueryValue('term') || '';
    this.selectedSearchOption = this.getUrlQueryValue('type') || Object.keys(this.availableSearchCategories)[0];
  }

  public performSearch() {
    this.$router.push({
      name: 'global-search',
      query: {
        type: this.selectedSearchOption,
        term: this.searchTerm
      }
    });
  }
}
</script>
