<template>
  <div class="dropdown user-dropdown">
    <a
      id="user-drop-down"
      class="nav-link dropdown-toggle media"
      href="#"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <font-awesome-icon :icon="['fad', 'user-circle']" class="headerUserIcon" />
    </a>
    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="user-drop-down">
      <router-link class="dropdown-item" :to="{ name: 'profilePage' }">
        <font-awesome-icon :icon="['far', 'user']" class="headerMenuIcon pr-2" /> {{getUserName}}
      </router-link>
      <template v-if="getHelpUrl">
        <a class="dropdown-item" :href="getHelpUrl" target="_blank">
          <font-awesome-icon :icon="['far', 'question-circle']" class="headerMenuIcon pr-2" /> {{$t('support')}}
        </a>
      </template>
      <div class="dropdown-divider"></div>
      <router-link class="dropdown-item" :to="{ name: 'administration' }">
        <font-awesome-icon :icon="['far', 'hammer']" class="headerMenuIcon pr-2" /> {{$t('administration')}}
      </router-link>
      <div class="dropdown-divider"></div>
      <template v-if="localeOptions.length > 1">
        <div class="dropdown-item col-12">
          <select-input
            :name="`${getCurrentLocale}`"
            select-id="lang-selector"
            v-model="$i18n.locale"
            @change="setLocale($event)"
            :options="localeOptions"
            @click="preventClose($event)"
          />
        </div>
        <div class="dropdown-divider"></div>
      </template>
      <a href="#" class="dropdown-item" @click="logout">
        <font-awesome-icon :icon="['far', 'sign-out']" class="headerMenuIcon pr-2" />
        <span class="d-sm-inline">{{$t('log_out')}}</span>
      </a>
    </div>
  </div>
</template>

<i18n src="@/components/_locales/common.json"></i18n>
<i18n src="./_locales/UserMenu.json"></i18n>

<script lang="ts">
import { Getter } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import ModalSection from '@/components/shared/ModalSection.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import store from '@/store';
import i18n from '@/i18n';
import { mixins } from "vue-class-component";
import { TranslationUtilsMixin } from "@/mixins/translation-utils-mixin";
import { APIBaseRoute, EP } from '@/api-endpoints';

interface RoleChoice {
  code?: string,
  value?: string
}

@Component({
  components: {
    ModalSection,
    SelectInput
  }
})
export default class UserMenu extends mixins(TranslationUtilsMixin) {
  @Getter('getUserName', { namespace: 'users' }) private getUserName!: string;
  @Getter('getHelpUrl', { namespace: 'features' }) private getHelpUrl!: string;
  @Getter('bypassFusionAuth', { namespace: 'features' }) private bypassFusionAuth!: boolean;

  public preventClose(event: any): void {
    event.stopPropagation();
  }

  setLocale(locale :string): void {
    if (locale) {
      this.$i18n.locale = locale;
      localStorage.setItem('locale', locale);
      // force page refresh to rebuild lookup store
      window.location.reload();
    }
  }

  get localeOptions(): any {
    return this.getAvailableLocales.map((lang: string) => {
      return { code: lang, value: this.$t(lang) };
    });
  }

  get getCurrentLocale(): string|undefined {
    if (!this.$i18n.locale) { return undefined; }
    const options = this.localeOptions || [];
    const current = options.find((t: any) => t.code === this.$i18n.locale).value;
    return `${this.$t('language')} ${current}`;
  }

  logout(): void {
    store.dispatch('users/logout').then(() => {
      // if fusionauth enabled and access token exists, redirect to user list page
      if (!this.bypassFusionAuth && localStorage.getItem('access_token')) {
        const access_token = localStorage.getItem('access_token') || '';
        const url = APIBaseRoute(EP.fusionAuth.access_token);      
        window.location.href = url;
      // otherwise login page
      } else {
        window.location.href = "/login";
      }
    });
  }
}
</script>
