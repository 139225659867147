import { render, staticRenderFns } from "./_DonorChecklist.vue?vue&type=template&id=daf6f192"
import script from "./_DonorChecklist.vue?vue&type=script&lang=ts"
export * from "./_DonorChecklist.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/components/allocations/_locales/common.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FlivingAllocations%2F_DonorChecklist.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/components/allocations/_locales/_AllocationDetails.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FlivingAllocations%2F_DonorChecklist.vue&external"
if (typeof block1 === 'function') block1(component)
import block2 from "@/components/allocations/_locales/_DonorChecklist.json?vue&type=custom&index=2&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FlivingAllocations%2F_DonorChecklist.vue&external"
if (typeof block2 === 'function') block2(component)

export default component.exports