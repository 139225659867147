import { render, staticRenderFns } from "./LivingDonorStickySummary.vue?vue&type=template&id=01007d2c"
import script from "./LivingDonorStickySummary.vue?vue&type=script&lang=ts"
export * from "./LivingDonorStickySummary.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/views/_locales/common.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FlivingDonors%2FLivingDonorStickySummary.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/components/_locales/Organs.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FlivingDonors%2FLivingDonorStickySummary.vue&external"
if (typeof block1 === 'function') block1(component)
import block2 from "@/components/deceasedDonors/_locales/commonPatientShared.json?vue&type=custom&index=2&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FlivingDonors%2FLivingDonorStickySummary.vue&external"
if (typeof block2 === 'function') block2(component)
import block3 from "@/components/livingDonors/_locales/LivingDonorStickySummary.json?vue&type=custom&index=3&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FlivingDonors%2FLivingDonorStickySummary.vue&external"
if (typeof block3 === 'function') block3(component)

export default component.exports