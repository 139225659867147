<template>

  <div>
    <template v-if="serologyFilter">
      <div class="filteritem">
        <date-input
          inputId="startDate"
          name="Start Date"
          v-model="startDate"
          @input="$parent.$emit('moveScroll', startDate)"
        />
      </div>
      <div class="filteritem">
         <date-input
          inputId="endDate"
          name="End Date (Optional)"
          v-model="endDate"
        />
      </div>
    </template>

    <template v-if="notesFilter">
      <div class="filteritem">
        <date-input input-id="added_date_from" :name="$t('Added Date from')" v-model="addedDateFrom" />
      </div>
      <div class="filteritem">
        <date-input input-id="added_date_to" :name="$t('Added Date to')" v-model="addedDateTo" />
      </div>
      <div class="filteritem">
        <select-input selectId="users" :name="$t('Author')" v-model="author" :options="notesAuthorOptions" />
      </div>
  
      <div class="filteritem">
         <multi-select-input 
          select-id="tags" 
          aria-label="Options"
          name="Notes Tags" 
          placeholder="Select Tags" 
          :showToolTip="false" 
          :toolTipText="`Select tags to help find this note or capture important elements.`"
          v-model="filterTag" 
          :options="notesTagOptions"
          displayType="comma"
          showToggleAll="false" />
     
      </div>
      <div class="filteritem">
        <text-input inputId="search" :name="$t('Keyword')" v-model="search" placeHolder="search" />
      </div>
    </template>
    <template v-if="checklistFilter">
      <div class="filteritem">
        <select-input selectId="tags" :name="$t('Status')" v-model="filterStatus" :options="checklistStatusOptions" />
      </div>
    </template>
    <div class="filteritem last" v-if="!serologyFilter">
      <div class="form-check form-check-inline" v-if="archivedFilter">
        <input :id="`${fieldID}-archive`" v-model="includeArchived" :aria-labelledby="`${fieldID}-archive`"
          type="checkbox" class="form-check-input">
        <label :for="`${fieldID}-archive`" class="form-check-label">
          <span> Include Archived </span>
        </label>
      </div>
      
      <div class="form-check form-check-inline" v-if="completedFilter">
        <input :id="`${fieldID}-completed`" v-model="includeCompleted" :aria-labelledby="`${fieldID}-archive`"
          type="checkbox" class="form-check-input">
        <label :for="`${fieldID}-completed`" class="form-check-label">
          <span> Include Completed </span>
        </label>
      </div>
    </div>
    <div class="btn-group">
      <button type="button" class="btn btn-primary-outline btn-sm" @click.prevent="saveFilters()">
        {{"Filter"}}
      </button>
      <button type="button" class="btn btn-primary-outline btn-sm ml-2" v-if="appliedFilters.length > 0" @click.prevent="clearFilters()">
        {{"Clear"}}
      </button>
      <div class="filter-spacer filterShowLarge" v-if="!hideSpacer" />
    </div>
  </div>
</template>

<script lang="ts">
  import {
    Component,
    Vue,
    Prop,
    Watch
  } from 'vue-property-decorator';
  import CheckboxInput from '@/components/shared/CheckboxInput.vue';
  import TextInput from '@/components/shared/TextInput.vue';
  import SelectInput from '@/components/shared/SelectInput.vue';
  import DateInput from '@/components/shared/DateInput.vue';
  import MultiSelectInput from '@/components/shared/MultiSelectInput.vue';

  @Component({
    components: {
      TextInput,
      SelectInput,
      DateInput,
      MultiSelectInput
    }
  })
  export default class FilterComponent extends Vue {

    @Prop({
      default: null
    }) displayFilter!: any;
    @Prop({
      default: null
    }) serologyFilter!: any;
    @Prop({
      default: null
    }) fieldID!: any;
    @Prop({
      default: null
    }) notesFilter!: any;
    @Prop({
      default: null
    }) notesTagOptions!: any;
    @Prop({
      default: null
    }) notesAuthorOptions!: any;
    @Prop({
      default: null
    }) checklistFilter!: any;
    @Prop({
      default: null
    }) checklistStatusOptions!: any;
    @Prop({
      default: null
    }) appliedFilters!: any;
    @Prop({
      default: null
    }) hideSpacer!: any;
      @Prop({
      default: null
    }) completedFilter!: any;
      @Prop({
      default: null
    }) archivedFilter!: any;

    

    @Watch('appliedFilters', { immediate: true, deep: true })
    public checkFilters(): void {
     this.appliedFilters.map((filter:any) => {
      if (filter.name == 'includeArchived') {
         this.includeArchived = filter.value.value;
      }
      if (filter.name == 'includeCompleted') {
        this.includeCompleted = filter.value.value;
      }
      if (filter.name == 'filterTag') {
        let tags: any = [];
        filter.value.data.map((f: any) => {
          tags.push((f.code + 1).toString());
        });
        this.filterTag = tags;
      }
     });
    }
    //  
    private includeArchived = false;
    private includeCompleted = false;
    private search = '';
    private filterTag = [];
    private author = '';
    private addedDateTo = '';
    private addedDateFrom = '';
    private filterStatus = null;
    private startDate = false;
    private endDate = false;

    isMobile() {
      return true;
    }

    public getCategoryValue(item: any, data: any) {
      return data[item as any].value;
    }

    private getFilterTags() {
      let tags = this.filterTag;
      let filterArray: any = [];

      tags.map((item: any) => {
        filterArray.push({code: item-1, name: this.getCategoryValue(item-1, this.notesTagOptions)});
      });
      return filterArray;
    }

    private saveFilters(): void {
      let FilterObject = {
        filterStatus: { value: this.filterStatus !== null ? true: false, data: this.filterStatus !== null ? this.checklistStatusOptions[this.filterStatus as any].value : null},
        includeArchived: { value: this.includeArchived},
        includeCompleted: { value :this.includeCompleted},
        filterTag: { value: this.filterTag.length > 0 ? true: false, data: this.getFilterTags()}
      };

      this.$emit('prepareFilter', FilterObject);
    }

    private clearFilters(): void {
      this.filterStatus = null;
      this.includeArchived = false;
      this.includeCompleted = false;
      this.filterTag = [];

      let FilterObject = {
        filterStatus: this.filterStatus,
        includeArchived: this.includeArchived,
        includeCompleted: this.includeCompleted,
        filterTag: this.filterTag

      };


      this.$emit('prepareFilter', {});
    }

  }

</script>

<style>


</style>
