<template>
  <div class="nav-block-wrap">
    <ul class="nav nav-block flex-column" :class="{ active: active }">
      <li class="nav-item">
        <span class="nav-link">
          <font-awesome-icon :icon="['fas', (show ? 'chevron-down' : 'chevron-right')]" class="nav-caret" fixed-width @click.prevent="toggleNav" />
          <slot name="profile-return-link" />
        </span>
      </li>
      <ul class="nav flex-column" v-if="show">
        <li class="nav-item" @click="toggleSideNav" v-if="isClinicalTransactionEnabled">
          <a class="nav-link" 
            :href="getUrl('clinical-transaction')">
            {{$t('clinical_transaction')}}
          </a>
        </li>
        <li class="nav-item" @click="toggleSideNav" v-if="newRecipient && showOrganDetailsSection">
          <a class="nav-link"
            :href="getUrl('organ-details-section')">
            {{$t('journey_details')}}
          </a>
        </li>
        <li class="nav-item" @click="toggleSideNav">
          <a class="nav-link" 
            :href="getUrl('demographics')">
            {{$t('demographics')}}
          </a>
        </li>
        <li class="nav-item" @click="toggleSideNav" v-if="prototypeFeatureEnabled('proto_health_insurance')">
          <a class="nav-link" 
            :href="getUrl('health_insurance')">
            Insurance
          </a>
        </li>
        <li class="nav-item" @click="toggleSideNav" v-if="prototypeFeatureEnabled('proto_contact_information')">
          <a class="nav-link" 
            :href="getUrl('contactinfo')">
            {{$t('contact_information')}}
          </a>
        </li>

        
        <li class="nav-item" @click="toggleSideNav" v-if="prototypeFeatureEnabled('proto_address_component_contact_information')">
          <a class="nav-link" 
            :href="getUrl('protoContactInfo')">
            {{$t('contact_information')}}
          </a>
        </li>
        <li class="nav-item" @click="toggleSideNav" v-if="prototypeFeatureEnabled('proto_care_givers_section')">
          <a class="nav-link" 
            :href="getUrl('care-givers-section')">
            {{"Care Givers"}}
          </a>
        </li>
        <li class="nav-item" @click="toggleSideNav" v-if="prototypeFeatureEnabled('proto_communication_information')">
          <a class="nav-link" 
            :href="getUrl('communication-information')">
            {{"Communication Information"}}
          </a>
        </li>
        <li class="nav-item" @click="toggleSideNav" v-if="prototypeFeatureEnabled('proto_transportation_and_mobility')">
          <a class="nav-link" 
            :href="getUrl('transportation-and-mobility')">
            {{"Transportation and Mobility"}}
          </a>
        </li>
        
        <li class="nav-item" @click="toggleSideNav" v-if="prototypeFeatureEnabled('proto_pace_and_engagement')">
          <a class="nav-link" 
            :href="getUrl('pace-and-engagement')">
            {{"Pace and Engagement"}}
          </a>
        </li>
        
          <li class="nav-item" @click="toggleSideNav" v-if="prototypeFeatureEnabled('proto_scheduling_preferences')">
          <a class="nav-link" 
            :href="getUrl('scheduling-preferences')">
            {{"Scheduling Preferences"}}
          </a>
        </li>
        
      
        <li class="nav-item" @click="toggleSideNav" v-if="prototypeFeatureEnabled('proto_recipient_documents')">
          <a class="nav-link" 
            :href="getUrl('recipient_documents')">
            {{"Patient Documents"}}
          </a>
        </li>
         <li class="nav-item" @click="toggleSideNav">
          <a class="nav-link text-flat"
            :href="getUrl('link-to-donor')">
            {{$t('link_to_donor')}}
          </a>
        </li>

        <li class="nav-item" @click="toggleSideNav">
          <a class="nav-link" 
            :href="getUrl('organ-referral')">
            {{$t('referrals_and_tranplantations')}}
          </a>
        </li>
        <li class="nav-item" @click="toggleSideNav">
          <a class="nav-link" 
            :href="getUrl('gci')">
            {{$t('general_clinical_information')}}
          </a>
        </li>
        <li class="nav-item" @click="toggleSideNav" v-if="prototypeFeatureEnabled('proto_social_history')">
          <a class="nav-link" 
            :href="getUrl('social_history')">
            {{"Social History"}}
          </a>
        </li>
        <li class="nav-item" @click="toggleSideNav" v-if="prototypeFeatureEnabled('proto_medical_history')">
          <a class="nav-link" 
            :href="getUrl('medical_history')">
            {{"Medical History"}}
          </a>
        </li>
        <li class="nav-item" @click="toggleSideNav" v-if="prototypeFeatureEnabled('proto_chemistries')">
          <a class="nav-link" 
            :href="getUrl('serology')">
            {{$t('serology')}}
          </a>
        </li>
        <li class="nav-item" @click="toggleSideNav">
          <a class="nav-link" 
            :href="getUrl('recipient-death')">
            {{$t('recipient_death')}}
          </a>
        </li>
      </ul>
    </ul>
  </div>
</template>

<i18n src="@/components/organs/shared/_locales/OrganDetailsSection.json"></i18n>
<i18n src="@/components/recipients/_locales/SelectedRecipientProfileLinks.json"></i18n>

<script lang="ts">
import { State, Getter } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Recipient } from "@/store/recipients/types";
import { SystemModules } from '@/store/features/types';

@Component
export default class SelectedRecipientProfileLinks extends Vue {
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;

  @Getter("moduleEnabled", { namespace: "features" }) private moduleEnabled!: (module: string) => boolean;
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;


  @Prop({ default: false }) active!: boolean;
  @Prop({ default: false }) newRecipient!: boolean;

  public show = true;

  public toggleNav() {
    this.show = !this.show;
  }

  get showOrganDetailsSection(): boolean {
    return this.moduleEnabled('recipient_create_includes_journey');
  }

  /**
   * Gets the recipient client_id.
   *
   * Converts the recipient client_id to a string
   *
   * @returns {string} client_id as a string
   */
  get clientId(): string {
    return this.recipient.client_id == null ? '' : this.recipient.client_id.toString();
  }

  /**
   * Create a return to profile link
   *
   * @returns {string} client_id as a string
   */
  get profileReturnLink(): string {
    return this.clientId === '' ? `/recipients` : `/recipients/${this.clientId}`;
  }

  // Is the Clinical Transaction module enabled?
  get isClinicalTransactionEnabled(): boolean {
    return this.moduleEnabled(SystemModules.CLINICAL_TRANSACTIONS);
  }

  public toggleSideNav(): void {
    if (window.innerWidth < 992) { 
       this.$emit("toggle");
    }
  }

  public getUrl(hash: string): string {
    hash = `#${hash}`;
    if(this.active) {
      return hash;
    } else {
      // We are fine not checking for the existence of client_id as for new recipients we only ever show this in active mode
      const location = this.$router.resolve({ name: 'edit-recipient', params: { id: this.clientId }, hash: hash });
      return location.href;
    }
  }
}
</script>
