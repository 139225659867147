<template>
  <sub-section
    :title='title'
    sub-section-id="link_here"
  >
    <template v-slot:contents>
    <div class="row">
      <!-- Uploaded Files -->
      <div class="col-sm-12">
        <fieldset>

        </fieldset>

      </div>
    </div>
    <save-toolbar
      class="save-toolbar action-row temp-saving"

      buttonClass="btn btn-success sec-submit"

      :label="`Save ${title}`"
    />
    </template>

  </sub-section>
</template>


<script lang="ts">

import { Component, Vue } from 'vue-property-decorator';

import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import SubSection from '@/components/shared/SubSection.vue';

@Component({
  components: {
    SubSection,
    SaveToolbar,
  }
})
export default class Comorbidities extends Vue {  
  title = 'Medical Procedures';
  
}
</script>

<style>


</style>
