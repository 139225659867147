<template>
<span class="tooltip-box">
  <button @click.prevent class="tooltip-toggle">
     <font-awesome-icon class="text-grey" :icon="['far', 'exclamation-circle']" fixed-width />
    <span class="ml-1" v-if="showLabel">{{labelText}}</span>
  </button>
  <span class="tooltip" :id="`${inputID}-tooltip-tip`" aira-role="tooltip" >
    <span :id="`${inputID}-tooltip`"
      class="tooltip-text"
    >{{  getText  }}</span>
    </span>
  </span>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {
  }
})
export default class VueTooltip extends Vue {
  @Prop({default: null}) toolTipText!: string|null;
  @Prop({default: null}) inputID!: string|null;
  @Prop({default: null}) showLabel!: string|null;
  @Prop({default: null}) labelText!: string|null;
  @Prop({default: 'exclamation-circle'}) icon!: string|null;
  

  get getText(): string|undefined {
    return this.toolTipText || '';
  }
}
</script>

<style>
.tooltip-box { 
  float: right;
  padding-top: 1rem;
  margin-top: -2rem;
  position: relative;
}

.test-result .tooltip-box { 
  float: none;
}
.tooltip-toggle {

  border: 0;
  padding-right: 0;
  background-color: transparent;
  padding: 1rem 1rem 0.3rem;
  /* margin-top: -1rem; */
  margin-right: -0.9rem;
  vertical-align: middle;
  z-index: 999;
}

.tooltip-toggle svg {
  margin-top: -1em;
}


.tooltip-box .tooltip-toggle:hover + .tooltip, 
.tooltip-box .tooltip-toggle:focus + .tooltip {
  opacity:1;
  display: block;
  z-index: 1;
}

.tooltip-toggle:focus-visible {
  outline: none;

}


.tooltip-toggle:focus-visible svg {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    border-radius: 50%;
}

.tooltip { 
    background: rgb(76, 80, 87);
    border-radius: 6px;
    padding: 10px;
    opacity:0;
    transition:visibility 0.3s linear, opacity 0.3s linear;
    line-height: normal;
    text-decoration: none;
    position: absolute;
    right: -1em;
    color: #ffffff;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-width: 250px;
    top: 1.5rem;
    -webkit-transform: translatey(-100%);
    transform: translatey(-100%);
    outline: none;
    z-index: -9;
}

.tooltip-text:after {
  content: " ";
  position: absolute;
  bottom: -18px;
  right: 13.5px;
  border-width: 10px;
  border-style: solid;
  border-color: rgb(76, 80, 87) transparent transparent transparent;
}


</style>
