<template>

  <div class="row">
    <div class="col-sm-12 contact-information-section">
      <sub-section :title='title' sub-section-id="contact_info_section" :table-config="tableConfig" tabbableValue="date"
        hasCustomClasses="vgt-table table table-bordered table-hover vue-good-table-bordered"
        @table-row-click="editSelected" @table-create-row="clearForm" :row-style-class="rowStyleClass"
        @on-row-click="editSelected" :scrollToID="`${fieldID}-contact-information-section`" :id="`${fieldID}-contact-information-section`">
        <template v-slot:actions>
          <filter-component :displayFilter="displayFilter" @updateFilter="updateFilter" :archivedFilter="true" :fieldID="fieldID" :setFilters='setFilters'/>
        </template>
        <template v-slot:table-cell="props">
          <template v-if="props.column.isListType">
            <span v-html="props.row.okayTo" />
          </template>
        </template>

      </sub-section>
      <modal-section :modalId="`${fieldID}-contact-information-modal`" :ref="`${fieldID}-contact-information-modal`"
        class="modal-sticky-header" :centered="true" :closeButton="false" size="md" :wide="false">
        <template v-slot:body>
          {{`${modalMode} this item`}}

        </template>
        <template v-slot:footer>
          <div class="modal-footer-body">
            <button type="button" data-dismiss="modal" @click="resetArchive()" class="btn btn-secondary">
              {{ $t('Cancel') }}
            </button>
            <a class="btn btn-success" data-dismiss="modal" @click="archiveDocument(modalMode)">
              {{ $t('OK') }}
            </a>
          </div>
        </template>
      </modal-section>

      <contact-form ref="recipientContactFormSection" :fieldID="fieldID"
        :title="editstate ? `Edit ${label}` : `New ${label}`" sub-section-id="recipient-contact-information-section"
        saveButton="true" enforce_line="enforce_line" :divider="true"
        :save-button-text="editstate ? `${$t(`Update ${label}`)}` : `${$t(`Save New ${label}`)}` "
        @save="uploadDocument" :selectedItem="selectedItem" :showCancelButton="showCancelButton"
        cancelButtonText="Cancel" :emptyForm="emptyForm" @cancel="clearForm()" :categoryOptions="categoryOptions"
        :portalOptions="portalOptions" :responsivenessOptions="responsivenessOptions" @archive="confirmPopup"
        :showArchivedButton="editstate ? true : false" :buttonClass="buttonClass" :hideTitle="false"
        :parentDisabled="parentDisabled" />

    </div>
  </div>
</template>


<i18n src="./_locales/AddressComponent.json"></i18n>

<script lang="ts">
  import {
    Getter,
    State,
  } from 'vuex-class';
  import {
    mixins
  } from "vue-class-component";
  import {
    Component,
    Vue,
    Prop,
    Watch
  } from 'vue-property-decorator';
  import CardSection from "@/components/shared/CardSection.vue";
  import SaveToolbar from '@/components/shared/SaveToolbar.vue';
  import {
    VueGoodTable
  } from 'vue-good-table';
  import SelectInput from '@/components/shared/SelectInput.vue';
  import CheckboxInput from '@/components/shared/CheckboxInput.vue';
  import {
    GenericCodeValue
  } from '@/store/types';
  import TextInput from '@/components/shared/TextInput.vue';
  import TextAreaInput from '@/components/shared/TextAreaInput.vue';
  import SubSection from '@/components/shared/SubSection.vue';
  import SelectOtherInput from '@/components/shared/SelectOtherInput.vue';
  import ContactForm from '@/components/prototypes/_contactForm.vue';
  import FilterComponent from '@/components/prototypes/shared/_FilterComponent.vue';
  import ModalSection from '@/components/shared/ModalSection.vue';

  import {
    TableConfig
  } from '@/types';

  import {
    Recipient
  } from "@/store/recipients/types";
  import {
    UserDetails
  } from '@/store/userAccounts/types';
  import {
    DateUtilsMixin
  } from "@/mixins/date-utils-mixin";

  import BooleanRadioInput from '@/components/shared/BooleanRadioInput.vue';

  interface TableRow {
    id: string;
    category ? : string;

    date ? : string;
    fileName ? : string;

    fileType ? : string;
    description ? : string;
    uploadedBy ? : string;
  }

  export interface FollowUpTypeOption extends GenericCodeValue {
    hasChecklist: boolean;
    checklistReference ? : string;
  }

  @Component({
    components: {
      CardSection,
      SaveToolbar,
      SubSection,
      SelectInput,
      TextInput,
      CheckboxInput,
      TextAreaInput,
      VueGoodTable,
      SelectOtherInput,
      BooleanRadioInput,
      ContactForm,
      FilterComponent,
      ModalSection
    }
  })
  export default class RecipientDocuments extends mixins(DateUtilsMixin) {

    @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
    @State(state => state.userAccounts.selected) private selected!: UserDetails;
    @State(state => state.pageState.currentPage.journeyStatus) editState!: any;

    @Getter('getUser', {
      namespace: 'users'
    }) private getUser!: any;
    @Getter('getTelephoneMask', {
      namespace: 'utilities'
    }) getTelephoneMask!: string;

    @Prop({
      default: null
    }) columns!: any;
    @Prop({
      default: null
    }) tableData!: any;
    @Prop({
      default: null
    }) title!: string;
    @Prop({
      default: null
    }) label!: string;
    @Prop({
      default: null
    }) fieldID!: string;
    @Prop({
      default: null
    }) showCancelButton!: string;
    @Prop({
      default: null
    }) customEmptyMessage!: string;
    @Prop({
      default: null
    }) localStorageRef!: string;
    @Prop({
      default: null
    }) categoryOptions!: any;
    @Prop({
      default: null
    }) portalOptions!: any;
    @Prop({
      default: null
    }) responsivenessOptions!: any;
    @Prop({
      default: null
    }) buttonClass!: any;
    @Prop({
      default: null
    }) parentDisabled!: any;


    get getDetailsLabelNameRule(): any {
      return this.detailsLabelName ? 'required' : null;
    }
    @Watch('tableData')
    private reloadLoaded(): void {
      this.loaded();
    }

    private confirmPopup(): any {
      this.modalMode = this.selectedItem.status == 'active' ? 'Archive' : 'Restore';
      this.toggleModal(`${this.fieldID}-contact-information-modal`);
    }

    // Toggle a modal based on a ref
    private toggleModal(ref: string): void {
      const targetModal = this.$refs[ref] as ModalSection;
      targetModal.toggleModal();
    }


    loaded() {
      // Parse tasks from local storage if found, otherwise default to JSON file contents
      let formState;

      const defaultState = {
        contacts: this.tableData.rows ? this.tableData.rows : this.tableData
      };
      const items: any = localStorage.getItem(this.localStorageRef);
      if (items) {
        try {
          formState = JSON.parse(items);
        } catch (error) {
          console.warn('Warning: Cannot parse tasklist from local storage, setting default tasks.', error);
          formState = defaultState;
        }
      } else {
        formState = defaultState;
      }
      this.$store.commit("pageState/set", {
        pageKey: "journeyStatus",
        value: formState,
      });
      this.contacts = formState.contacts || [];
    }

    private mounted(): void {
      Promise.all([
        this.$store.dispatch('hospitals/load'),
      ]).finally(() => {
        this.loaded();
      });
    }

    private contacts: any = [];
    private selectedItem: any = [];
    private editstate = false;
    private isTableUpdating = false;
    private taskInProgress = false;
    public perPage = 10;
    public currentPageTable = 1;
    private isLoading = true;
    private isSubSectionLoading = true;
    private emptyForm = false;
    private setFilters: any = {};
    private displayFilter = true;
    private detailsLabelName = null;
    private modalMode = ""

    public getCategoryValue(item: any, data: any) {
      return data[item as any].value;
    }

    private resetArchive(): any {
      setTimeout(() => {
         this.scrollToMethod(`${this.fieldID}-contact-information-form`);
      }, 500);
    }

    private scrollToMethod(id: any): any {


      const location = document.getElementById(id);

      if (location) {
        const elementPosition = location.getBoundingClientRect().top;
        const offsetPosition = elementPosition - 105;

        window.scrollBy({
          top: offsetPosition,
          behavior: "smooth"
        });
      }
    }

     private archiveDocument(type: any): any {
      this.isTableUpdating = true;
      let contacts = this.contacts || [];

      contacts.map((item: any) => {
  
        if (item.id === this.selectedItem.id) {
          item.status = item.status == 'active' ? 'archived' : 'active';
        }
      });

      this.contacts = contacts;

      const defaultState = {
        contacts: contacts
      };

      if (this.localStorageRef === "RecipientContactInformation") {

        localStorage.setItem(this.localStorageRef, JSON.stringify(defaultState));
      } else {
        this.$emit('saveToGiver', contacts);


      }

      this.isTableUpdating = false;
      this.editstate = false;
      this.clearForm();
      setTimeout(() => {
        this.scrollToMethod(`${this.fieldID}-contact-information-section`);
      }, 500);
    }

    private updateFilter(filterArray: any) {
      if (filterArray.length == 0) {
        return false;
      }

      this.setFilters = filterArray;
    }

    private clearForm() {
      this.emptyForm = !this.emptyForm;
      this.editstate = false;
      this.selectedItem = [];
    }

    get tableRows() {
      const contacts = this.contacts || [];
      return contacts.map((contact: any) => {
        return {
          id: contact.id,
          date: this.parseDisplayDateUi(contact.date) || '-',
          category: contact.category,
          categoryName: contact.category ? this.getCategoryValue(contact.category, this.categoryOptions) : null,
          detailsInput: contact.detailsInput,
          details: this.getContactDetails(contact),
          okayTo: this.getOkayTo(contact),
          okayCall: contact.okayCall,
          okayVoicemail: contact.okayVoicemail,
          okayText: contact.okayText,
          responsiveness: contact.responsiveness,
          responsivenessName: contact.responsiveness ? this.getCategoryValue(contact.responsiveness, this
            .responsivenessOptions) : null,
          comments: contact.comments,
          status: contact.status,
          statusName: contact.status.charAt(0).toUpperCase() + contact.status.slice(1),
          preferred: contact.preferred,
          portalValue: contact.portalValue,
          portalOther: contact.portalOther
        };
      });
    }

    get tableConfig(): TableConfig {
      return {
        data: this.tableRows,
        columns: this.columns,
        createButton: true,
        empty: this.emptyMessage,
        createText: `${this.$t(`New ${this.label}`)}`,
        sortOptions: {
          enabled: false,
        },
        paginationOptions: {
          enabled: true,
          mode: 'records',
          perPage: this.perPage,
          perPageDropdown: [10, 25, 50],
          setCurrentPage: this.currentPageTable,
          dropdownAllowAll: true,
          nextLabel: '',
          prevLabel: '',
          rowsPerPageLabel: this.$t('table.results'),
        },
      };
    }

    private editSelected(row: any) {
      if (row) {
        this.editstate = true;
        this.selectedItem = row.row;
      }
    }

    get emptyMessage(): string {
      if (!this.contacts) {
        return this.$t('loading').toString();
      } else if (this.customEmptyMessage) {
        return this.customEmptyMessage;
      } else {
        return this.$t('use_form_below_follow_up').toString();
      }
    }


    public getOther(selectValue: any, selectOther: any) {
      if (selectValue == '1') {
        return selectOther;
      } else {
        return this.portalOptions[selectValue as any].value;
      }
    }

    public getOkayTo(contact: any) {
      let result = [];
      for (const [key, value] of Object.entries(contact)) {

        if (key == "okayCall" || key == "okayVoicemail" || key == "okayText") {
          if (key == "okayCall" && value == true) {
            result.push('Call (Voice)');
          } else if (key == "okayVoicemail" && value == true) {
            result.push('Leave Message (Voicemail)');
          } else if (key == "okayText" && value == true) {
            result.push('Text (SMS)');
          }
        }
      }

      if (result.length == 0) {
        result.push('-');
      }


      const codeHTML = '<ul class="no-table-list-style">' + result.reduce((html, item) => {
        return html + "<li>" + item + "</li>";
      }, "") + '</ul>';

      return codeHTML;

    }

    public getContactDetails(contact: any) {

      const type = this.categoryOptions[contact.category as any];

      if (!type) {
        return false;
      }

      let result;

      switch (type.type) {
        case 'phone':
          result = contact.detailsInput;
          break;
        case 'email':
          result = contact.detailsInput;
          break;
        case 'telehealth':
          result = contact.detailsInput;
          break;
        case 'patient_portal':
          result = this.getOther(contact.portalValue, contact.portalOther);
      }

      return result;

    }


    public resetSaveToolbar(): void {
      // Refer to the save toolbar that handles this page
      const saveToolbar = (this.$refs.uploadDocument as SubSection).$refs[
        'save-recipient-upload-document'] as SaveToolbar;
      saveToolbar.reset();
    }

    getArchivedStatus(row: any) {
      if (this.setFilters && this.setFilters.includeArchived) {
        return row.status === 'archived' ? 'highlight-yellow' : '';
      } else if (row.status === 'archived') {
        return 'd-none';
      }
    }

    getprimaryStyle(row: any) {
      return row.preferred === true ? 'tr-primary-row' : '';
    }

    rowStyleClass(row: any) {
      return `${this.getArchivedStatus(row)} ${this.getprimaryStyle(row)} tr-link`;
    }


    public uploadDocument(type: any, contact: any): any {
      this.isTableUpdating = true;


      let contacts = this.contacts || [];

      if (type == 'isEdit') {
        let id = contact.id;
        contacts.map((item: any) => {
          if (item.id == id) {
            item.date = item.date;
            item.category = contact.category;
            item.detailsInput = contact.detailsInput;
            item.okayCall = contact.okayCall;
            item.okayVoicemail = contact.okayVoicemail;
            item.okayText = contact.okayText;
            item.responsiveness = contact.responsiveness;
            item.comments = contact.comments;
            item.portalValue = contact.portalValue;
            item.portalOther = contact.portalOther;
            item.status = contact.status;
            item.preferred = contact.isPreferred;
          }
        });

      } else {
        contacts.push(contact);
      }
      this.contacts = contacts;

      const defaultState = {
        contacts: contacts
      };

      if (this.localStorageRef === "RecipientContactInformation") {

        localStorage.setItem(this.localStorageRef, JSON.stringify(defaultState));
      } else {
        this.$emit('saveToGiver', contacts);


      }
      this.isTableUpdating = false;

      this.clearForm();
      this.selectedItem = [];
      setTimeout(() => {
        this.scrollToMethod(`${this.fieldID}-contact-information-section`);
      }, 500);



    }
  }

</script>

<style>


</style>
