<template>
  <card-section
    section-id="waitlist-section"
    :key="journeyId"
    :lookups-to-load="lookupsToLoad"
    @loaded="loaded()"
    :disabled="!canSave || newJourney"
     :isAccordion="prototypeFeatureEnabled('proto_ability_to_expand_collapse')"
  >
    <template v-slot:header>
      {{$t('waitlist')}}
    </template>
    <template v-slot:body v-if="editState">
      <!-- Warning banner for clustered journeys in Waitlist phase with a journey in Assessment phase -->
          <warning-banner v-if="showClusterIncompleteHoldWarningBanner">
            {{ $t('incomplete_cluster_hold_warning') }}
            <ul>
              <li v-for="(journey, idx) in clusteredJourneysInAssessement" :key="idx">
                <router-link :to="{ name: 'edit-organ', params: { organ_id: journey.journeyId } }" >
                  {{ $t(journeyName(journey.organCode,journeyId)) }}
                </router-link>
              </li> 
            </ul>
          </warning-banner>
          <warning-banner v-if="showClusterAssessmentWarningBanner">    
            {{ $t('assessment_cluster_warning') }}
          </warning-banner>
      <!-- Display waitlist outcome notifications with a popup modal -->
      <waitlist-outcome-modal
        ref="waitlistOutcome"
      />

      <!-- Waitlist Summary shows latest waitlist factors from Recipient Journey data -->
      <waitlist-summary 
        :new-journey="newJourney" 
        ref="waitlistSummary"
        :isWaitTimeLoaded="isWaitTimeLoaded"
      />

      <!-- Cluster -->
      <cluster-section 
        v-if="!newJourney && recipient.journeys.length > 1" 
        ref="clusterSection"
        @handleErrors="handleErrors"
        @clear="clear"
        @reloadDonorAcceptabilityCriteria="reloadDonorAcceptabilityCriteria"
        @reloadOrganSpecificDetails="reloadOrganSpecificDetails"
        @reloadLiverExceptionPoints="reloadLiverExceptionPoints"
        @reloadWaitlistSummary="reloadWaitlistSummary"
      />

      <!-- Waitlist History table shows 'decision rows' mapped from Recipient Significant Event data -->
      <waitlist-history-table
        :new-journey="newJourney"
        :isWaitTimeLoaded="isWaitTimeLoaded"
      />

      <!-- Add to Waitlist or Waitlist Status Update -->
      <template v-if="showAddToWaitlist">
        <add-to-waitlist
          ref="addToWaitlist"
          :new-journey="newJourney"
          @saving="saving"
          @handleErrors="handleErrors"
          @display-outcome-notification="displayOutcomeNotification"
          @clear="clear"
          @saved="saved"
        />
      </template>
      <template v-else>
        <waitlist-status
          ref="waitlistStatus"
          @saving="saving"
          @handleErrors="handleErrors"
          @display-outcome-notification="displayOutcomeNotification"
          @clear="clear"
          @saved="saved"
        />
      </template>

      <template v-if="showOverrideSection">
        <!-- Override Wait Time -->
        <override-wait-time
          ref="overrideWaitTime"
          :new-journey="newJourney"
          @saving="saving"
          @handleErrors="handleErrors"
          @display-outcome-notification="displayOutcomeNotification"
          @clear="clear"
          @saved="saved"
          :divider="showRemoveSection || showTransferListing"
        />
      </template>
      <template v-if="showRemoveSection">
        <!-- Remove from Waitlist -->
        <remove-from-waitlist
          ref="removeFromWaitlist"
          :new-journey="newJourney"
          @saving="saving"
          @handleErrors="handleErrors"
          @clear="clear"
          @saved="saved"
          :divider="showTransferListing"
        />
      </template>
      <template v-if="showTransferListing">
        <!-- Transfer Listing -->
        <transfer-listing
          ref="transferListing"
          :new-journey="newJourney"
          @saving="saving"
          @handleErrors="handleErrors"
          @clear="clear"
          @saved="saved"
          v-if="isActiveTransferEnabled"
        />
      </template>
    </template>
  </card-section>
</template>

<i18n src="./_locales/_WaitlistSection.json"></i18n>
<i18n src="@/components/_locales/Organs.json"></i18n>

<script lang="ts">
import { EP } from '@/api-endpoints';
import { isMasked } from '@/utils';
import { ModalContent } from '@/types';
import { State, Getter } from 'vuex-class';
import { OrganCodeValue } from '@/store/lookups/types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import { Recipient } from '@/store/recipients/types';
import WaitlistSummary from '@/components/organs/shared/WaitlistSummary.vue';
import OverrideWaitTime from '@/components/organs/shared/OverrideWaitTime.vue';
import RemoveFromWaitlist from '@/components/organs/shared/RemoveFromWaitlist.vue';
import WaitlistOutcomeModal from '@/components/organs/shared/WaitlistOutcomeModal.vue';
import WaitlistHistoryTable from '@/components/organs/shared/WaitlistHistoryTable.vue';
import { RecipientJourney, RecipientStageAttributes, JourneyStage, JourneyStatus } from '@/store/recipientJourney/types';
import CardSection from '@/components/shared/CardSection.vue';
import AddToWaitlist, { AddToWaitlistState } from '@/components/organs/shared/AddToWaitlist.vue';
import ClusterSection from '@/components/organs/shared/ClusterSection.vue';
import WaitlistStatus, { WaitlistStatusUpdateState } from '@/components/organs/shared/WaitlistStatus.vue';
import TransferListing, { TransferListingPageState } from '@/components/organs/shared/TransferListing.vue';
import WarningBanner from '@/components/shared/WarningBanner.vue';
import { ObjectId } from '@/store/types';
import LoadingTableView from '@/components/shared/LoadingTableView.vue';
import { SystemModules } from '@/store/features/types';

export interface WaitlistSectionPageState {
  addToWaitlist?: AddToWaitlistState;
  statusUpdate?: WaitlistStatusUpdateState;
  transferListing?: TransferListingPageState;
  // Note: Override Wait Time sub-section manages its own state
}

@Component({
  components: {
    CardSection,
    WarningBanner,
    AddToWaitlist,
    WaitlistStatus,
    ClusterSection,
    TransferListing,
    WaitlistSummary,
    OverrideWaitTime,
    RemoveFromWaitlist,
    WaitlistOutcomeModal,
    WaitlistHistoryTable,
    LoadingTableView,
  }
})
export default class WaitlistSection extends Vue {
  // State
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.waitlistSection) editState!: WaitlistSectionPageState;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('isWaitlisted', { namespace: 'journeyState' }) isWaitlisted!: boolean;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;
  @Getter('wasRemovedFromWaitlist', { namespace: 'journeyState' }) wasRemovedFromWaitlist!: boolean;
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('isClustered', { namespace: 'journeyState', }) isClustered!: boolean;
  @Getter("journeyName", { namespace: "recipients" }) journeyName!: (organCode?: number, journeyId?: string) => string;
  @Getter("moduleEnabled", { namespace: "features" }) private moduleEnabled!: (module: string) => boolean;

  // Properties
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  private isWaitTimeLoaded = false;
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;


  private lookupsToLoad = [
    'medical_hold_reasons',
    'wait_days_adjustment_reasons',
    'waitlist_removal_reason_codes',
    'recipient_significant_event_factor_codes'
  ];

  /**
   * Get a string representation the organ_code
   * 
   * @returns {string} organ_code as a string
   */
  get organCode(): string {
    if (this.newJourney) {
      return this.$route.params.organ_code.toString();
    }
    return this.journey.organ_code ? this.journey.organ_code.toString() : '';
  }

  /**
   * If a user clusters a journey or journeys in Waitlist phase with a journey in Assessment phase, 
   * the journey(s) in Waitlist phase will be placed on an Incomplete Cluster Hold. System displays 
   * persistent warning message at the top of the journey Waitlist area of the journey(s) on 
   * Incomplete Cluster Hold
   *
   * @returns {boolean} true only if the warning banner should be shown
   */
  get showClusterIncompleteHoldWarningBanner(): boolean {
    if (!this.isClustered) return false;
    // Get related journey's in assessment stage
    const relatedJourneys = this.getRelatedJourneysByState(JourneyStage.Assessment);
    
    return this.journey.stage != JourneyStage.Assessment && relatedJourneys.length > 0;
  }
  /**
   * If a user clusters a journey or journeys in Waitlist phase with a journey in Assessment phase, 
   * the journey(s) in Waitlist phase will be placed on an Incomplete Cluster Hold. System displays 
   * persistent warning message at the top of the journey Waitlist area of clustered journey(s) 
   * in Assessment phase
   *
   * @returns {boolean} true only if the warning banner should be shown
   */
  get showClusterAssessmentWarningBanner(): boolean {
    if (!this.isClustered) return false;
    // Get related journey's in waitlist stage and incomplete hold
    const relatedJourneys = this.getRelatedJourneysByState(JourneyStage.Waitlist,JourneyStatus.IncompleteClusterHold);
    return this.journey.stage == JourneyStage.Assessment && relatedJourneys.length > 0 ;
  }

  get clusteredJourneysInAssessement() {
    // Get related journey's in assessment stage
    const relatedJourneys = this.getRelatedJourneysByState(JourneyStage.Assessment);
    const filteredJourney: { journeyId?: string, organCode?: number }[] =[];
    relatedJourneys.map((journey: RecipientJourney) => {
      filteredJourney.push({ journeyId: journey._id?.$oid.toString(), organCode: journey.organ_code });
    });
    return filteredJourney;
  }

  get isOverrideAuthorized(): boolean {
    return this.checkAllowed(EP.recipients.journeys.waitlist.overrideWaitTime, 'PATCH');
  }

  get hasOverrideAdjustment(): boolean {
    if (this.newJourney || !this.journey) {
      return false;
    }
    const overrideAdjustmentDays = this.journey.stage_attributes?.waitlist?.factors?.wait_days_adjustment || 0;
    const overrideAdjustmentReason = this.journey.stage_attributes?.waitlist?.factors?.wait_days_adjustment_reason_code;
    const masked = isMasked(`${overrideAdjustmentDays}`) || isMasked(`${overrideAdjustmentReason}`);
    return !masked && (overrideAdjustmentDays != 0 || overrideAdjustmentReason != null);
  }

  get showOverrideSection(): boolean {
    if (this.newJourney || !this.journey) {
      return false;
    }
    return this.isOverrideAuthorized || (this.hasOverrideAdjustment && this.isWaitlisted);
  }

  get showRemoveSection(): boolean {
    if (this.newJourney || !this.journey) {
      return false;
    }
    return this.isWaitlisted || this.wasRemovedFromWaitlist;
  }
  
  /**
   * Gets a boolean value for if we can show the transfer listing section 
   * 
   * @returns {boolean} true if we can show the transfer listing section
   */
  get showTransferListing(): boolean {
    if (this.hasExistingTransferStatus) return true;
    if (this.newJourney || !this.journey || !this.isWaitlisted) return false;
    return this.checkAllowed(EP.recipients.journeys.transferStatuses.activeTransfer.singleToSingle, 'PUT');
  }
  
  // Does the selected Journey have an existing transfer_status entry
  get hasExistingTransferStatus(): boolean {
    if (this.newJourney) return false;
    const transferStatuses = this.journey.transfer_statuses || [];
    return Array.isArray(transferStatuses) && (transferStatuses || []).length > 0;
  }

  // Is the Active Transfer system module enabled?
  get isActiveTransferEnabled(): boolean {
    return this.moduleEnabled(SystemModules.TRANSFER_ACTIVE);
  }
  
  /**
   * Populates form state with default values for the Waitlist Section
   */
  public initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'waitlistSection',
      value: {},
    });
  }

  public reloadWaitlistSummary() {
    const waitlistSummary = this.$refs.waitlistSummary as WaitlistSummary;
    if (waitlistSummary) waitlistSummary.reload();
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    const result = {};

    // Nested mapping for inputs defined in sub-section children components

    // Add to Waitlist
    const addToWaitlist = this.$refs.addToWaitlist as AddToWaitlist;
    if (addToWaitlist) {
      Object.assign(result, { ...addToWaitlist.idLookup() });
    }
    
    // Cluster Section
    const clusterSection = this.$refs.clusterSection as ClusterSection;
    if (clusterSection) {
      Object.assign(result, { ...clusterSection.idLookup() });
    }

    // Update Waitlist Status
    const waitlistStatus = this.$refs.waitlistStatus as WaitlistStatus;
    if (waitlistStatus) {
      Object.assign(result, { ...waitlistStatus.idLookup() });
    }

    // Override Wait Time
    const overrideWaitTime = this.$refs.overrideWaitTime as OverrideWaitTime;
    if (overrideWaitTime) {
      Object.assign(result, { ...overrideWaitTime.idLookup() });
    }

    // Remove from Waitlist
    const removeFromWaitlist = this.$refs.removeFromWaitlist as RemoveFromWaitlist;
    if (removeFromWaitlist) {
      Object.assign(result, { ...removeFromWaitlist.idLookup() });
    }

    return result;
  }

  // PRIVATE

  private mounted(): void {
    // Check what data we need to load for this component
    this.initializeForm();
    this.isWaitTimeLoaded = false;
    if (!this.newJourney) {
      // Edit Journey - need to load both wait time and waitlist decisions
      const payload = { recipientId: this.recipientId, journeyId: this.journeyId };
      this.$store.dispatch('journeyState/loadJourneyDurations', payload).then(() => {
        this.isWaitTimeLoaded = true;
      });
    } else {
      // New Journey - simply reset wait time and decisions
      this.$store.commit('journeyState/clearJourneyDurations');
      this.isWaitTimeLoaded = true;
    }
  }

  // Reload the Wait Time result by requesting Journey Durations calculations again
  private reloadWaitTime(): void {
    if (this.newJourney) return;

    this.isWaitTimeLoaded = false;

    const payload = { recipientId: this.recipientId, journeyId: this.journeyId };
    this.$store.dispatch('journeyState/loadJourneyDurations', payload).then(() => {
      this.isWaitTimeLoaded = true;
    });
  }

  /**
   * Gets a boolean value representing whether or not to show the Add to Waitlist form area
   * 
   * @returns {boolean} true if Add to Waitlist should be shown, false otherwise
   */
  get showAddToWaitlist(): boolean {
    return !this.isWaitlisted && !this.wasRemovedFromWaitlist;
  }

  // Called after all lookups are loaded
  private loaded(): void {
    this.$emit('loaded', 'waitlistSection');
  }

  // Emit event to parent so it can handle clearing validations when saving
  private saving(formReference: string) {
    this.$emit('saving', formReference);
  }

  // After a nested form finishes saving, top-level component should reload waitlist decisions
  // Note: this must happen after saving completes, because this can affect which components are rendered
  private saved(formReference: string) {
    // Clear validations
    this.$emit('clear');
    // Load the resulting waitlist decisions and select the most recent one
    const opts = {
      journeyId: this.journeyId,
      recipientId: this.recipientId,
    };
    this.$store.dispatch('journeyState/loadWaitlistDecisions', opts);

    // Reload wait time, because medical status can affect the result (see TPGLI-5576)
    this.reloadWaitTime();

    // Reload wait time override events
    this.$store.dispatch('journeyState/loadWaitTimeOverrideEvents', opts);

    // Reload recipient and journey for latest Waitlist Factors
      this.$store.dispatch('recipients/get', this.recipientId).then(() => {
        this.$store.dispatch('journeyState/getJourney', this.journeyId);
        this.$emit('reloadDonorAcceptabilityCriteria');
        // Reload Wait Time
        this.$store.dispatch('journeyState/loadJourneyDurations', opts).then(() => {
          this.reloadWaitlistSummary();
        });
    });
    /**
     * If organ is Liver then we need to reload SMC Score history, exception diseases, etc.
     * 
     * Here we simply bubble an event up to the Liver component, which will then delegate the
     * reloading of sub-sections related to Liver Scores to Liver Specific Details card section
     *
     * This is necessary because most of the Liver Scores are based on Listing Date, and can
     * even be affecting by waitlist suspensions, etc.
     */
    if (this.organCode == OrganCodeValue.Liver.toString()) {
      this.$emit('reloadLiverExceptionPoints');
    }
  }

  // Emit event to parent so it can handle clearing validations when saving
  private clear() {
    this.$emit('clear');
  }

  // Emit event to parent so it can handle validations
  private handleErrors(errors: any) {
    this.$emit('handleErrors', errors);
  }

  // Check if we need to display an outcome notification
  private displayOutcomeNotification(modalContent: ModalContent) {
    const modal = this.$refs.waitlistOutcome as WaitlistOutcomeModal;
    modal.initialize(modalContent);
  }

  /**
   * This will filter related journey's based on state and stage
   *
   * @returns { RecipientJourney}
   */
  private getRelatedJourneysByState(stage: string, state?: string): RecipientJourney[] {
    let clusteredJourneys: RecipientJourney[] = [];
    const relatedJourneys = this.journey.related_journeys;

    this.recipient.journeys?.find((journey: RecipientJourney) => {
      relatedJourneys?.forEach((id: ObjectId|string) => {
        const journeyId = (typeof id === 'string') ? id : id.$oid;
        if(journey.stage == stage &&  journey._id?.$oid == journeyId || (!state && state == journey.state)) {
          clusteredJourneys.push(journey);
        }
      });
    }); 
    return clusteredJourneys;
  }

  // Emit event to parent to reload donor acceptability criteria section
  private reloadDonorAcceptabilityCriteria() {
    this.$emit('reloadDonorAcceptabilityCriteria');
  }

  // Emit event to parent to reload organ specific details section
  private reloadOrganSpecificDetails() {
    this.$emit('reloadOrganSpecificDetails');
  }

  // Emit event to parent to reload LiverExceptionPoints
  private reloadLiverExceptionPoints() {
    this.$emit('reloadLiverExceptionPoints');
  }
}
</script>
