<template>
  <ul class="nav nav-block flex-column" :class="{ 'active': active }" v-if="canAccessUsers">
    <li class="nav-item">
      <router-link class="nav-link no-scroll open" :to="{ name: 'Users' }">
        <font-awesome-icon :icon="['fas', 'chevron-right']" class="nav-caret" fixed-width />
        Users
      </router-link>
    </li>
  </ul>
</template>

<script lang="ts">
import { State, Getter } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class UserLinks extends Vue {
  @Getter('getUserName', { namespace: 'users' }) private getUserName!: string;
  @Getter('canAccessManageUsers', { namespace: 'users' }) private canAccessManageUsers!: boolean;

  @Prop({ default: false }) active!: boolean; // HTML ID

  get canAccessUsers(): boolean {
    return this.canAccessManageUsers;
  }
}
</script>
