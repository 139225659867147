<template>
  <base-widget :title="$t('recent_living_donor_registrations')" :preferences="preferences.recentLivingDonors" v-if="editState">
    <template v-slot:icon>
      <div class="circle dashboard-icon circle-recent-living">
        <font-awesome-icon :icon="['fas', 'user-plus']" />
      </div>
    </template>
    <template v-slot:linkID>
      <a href="#idRecentLivingDonors" class="nav-link card-header-btn py-0" data-toggle="collapse">
        <font-awesome-icon :icon="['far', 'cog']" data-toggle="tooltip" :title="$t('customize_panel')" />
      </a>
      <a href="#bodyRecentLivingDonors" class="nav-link card-header-btn py-0" data-toggle="collapse">
        <font-awesome-icon :icon="['far', 'caret-down']" data-toggle="tooltip" :title="$t('collapse_panel')" />
      </a>
    </template>
    <template v-slot:options>
      <div class="card-body card-options p-0 collapse" id="idRecentLivingDonors">
        <div class="customize-panel">
          <h4>{{$t('panel_options')}}</h4>
          <!-- Widget options-->
          <slot name="options">
            <form>
              <div class="form-row mb-3">
                <div class="col-sm-6">
                  <select-input
                    name="panel-style"
                    select-id='dashRecentLivingDonorsStyle'
                    v-model="editState.style"
                    :label="$t('panel_style')"
                    :options="getPanelStyles"
                    />
                </div>
              </div>
              <div class="sub-divider"></div>
              <div class="form-row mb-3">
                <div class="col-sm-12 col-lg-8">
                  <checkbox-input
                    input-id='dashRecentLivingDonorsVisible'
                    v-model="editState.visible"
                    :label="$t('show_this_panel')"
                    :disabled="requiredWidget"
                  />
                </div>
              </div>
              <div class="sub-divider"></div>
              <div class="col-sm-12" v-if="errorMessage">
                <p>{{errorMessage}}</p>
              </div>
              <button
                type="button"
                class="btn btn-sm btn-wide btn-success"
                data-target="#idRecentLivingDonorsOffers"
                @click="saveSettings()"
              >
                {{$t('save')}}
              </button>
            </form>
          </slot>
        </div>
      </div>
    </template>
    <template v-slot:widget-contents>
      <div class="card-body collapse show" id="bodyRecentLivingDonors">
        <sub-section
          sub-section-id="RecentLivingDonorsTable"
          title=""
          style-class="vgt-table table table-bordered table-hover bordered"
          :total-records="perPage"
          :table-config="tableConfig"
          :pagination-options="tableConfig.paginationOptions"
          v-if="getRecentLivingDonors"
          >
          <template v-slot:table-cell="props">
            <template v-if="props.column.field == 'living_donor_id'">
              <router-link
                class="table-link"
                :to="{ name: 'edit-living-donor', params: { id: props.row.client_id } }"
                :title="$t('link_to_living_donor_page')"
                :alt="props.row.living_donor_id"
                target="_blank"
              >
                {{ props.row.living_donor_id ? props.formattedRow[props.column.field] : 'Unknown' }}
              </router-link>
            </template>
          </template>
        </sub-section>
      </div>
    </template>
  </base-widget>
</template>

<i18n src="./_locales/common.json"></i18n>
<i18n src="@/components/dashboard/widgets/_locales/WidgetRecentLivingDonors.json"></i18n>

<script lang="ts">
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import store from '@/store';
import { Getter, State } from 'vuex-class';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import BaseWidget from '@/components/dashboard/widgets/_BaseWidget.vue';
import { DashboardState, DashboardSettingState, WidgetSettings } from '@/store/dashboard/types';
import { TableConfig } from '@/types';
import { VueGoodTable } from 'vue-good-table';
import { urlParams } from "@/utils";
import TextInput from '@/components/shared/TextInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { Format } from "@/store/utilities/types";
import { GenericCodeValue } from '@/store/types';

@Component({
  components: {
    BaseWidget,
    VueGoodTable,
    TextInput,
    CheckboxInput,
    SelectInput,
    SubSection
  }
})
export default class WidgetRecentLivingDonors extends mixins(DateUtilsMixin) {
  @Prop({ default: false }) requiredWidget!: boolean;

  @State(state => state.users.user.preferences) private preferences!: any;
  @State(state => state.pageState.currentPage.dashRecentLivingDonors) private editState!: any;

  @Getter('getPreferences', { namespace: 'users'}) getPreferences!: any;
  @Getter('getRecentLivingDonors', { namespace: 'dashboard' }) getRecentLivingDonors!: any[];
  @Getter('getPanelStyles', { namespace: 'dashboard' }) getPanelStyles!: any[];
  @Getter('getDefaultPaginationOptions', { namespace: 'utilities' }) getDefaultPaginationOptions!: any;
  @Getter('organName', { namespace: 'lookups' }) organName!: (organCode?: number) => string|undefined;
  @Getter('getHospitalAbbreviation', { namespace: 'hospitals' }) getHospitalAbbreviation!: (hospitalCode?: string|null) => string|null;

  // Afflo Prototype
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;
  @Getter('optionsFor', { namespace: 'lookups' }) optionsFor!: (items: string[]) => GenericCodeValue[];

  public currentPage = 1;
  public perPage = 10;
  public errorMessage = null;

  mounted(): void {
    Promise.all([
      this.loadData()
    ]).finally(() => {
      this.initializeWidget();
    });
  }

  public loadData(search='', sort='') {
    const search_params = [search, sort].filter((p) => { return p && p.length >= 0; });
    this.$store.dispatch('hospitals/load');
    this.$store.dispatch(
      'dashboard/loadRecentLivingDonors', {
      pageNumber: this.currentPage,
      pageSize: this.perPage,
      search_params: `${search_params.length > 0 ? '&' : ''}${search_params.join('&')}`
    });
  }

  public updatePagination(event: any) {
    let search_params = urlParams(event?.searchParams);
    let sort_params = urlParams(event?.sortParams);
    this.currentPage = event.currentPage;
    this.perPage = event.currentPerPage;
    this.loadData(search_params, sort_params);
  }

  /**
   * Sets the search terms and sorting options
   *
  */
  public filterList(event: any) {
    let search_params = urlParams(event?.searchParams);
    let sort_params = urlParams(event?.sortParams);
    this.currentPage = event.currentPage;
    this.loadData(search_params, sort_params);
  }

  // Setup temporary edit state for unsaved widget settings form fields
  initializeWidget(): void {
    this.errorMessage = null; // clear error message
    const preferences = this.getPreferences;
    this.$store.commit('pageState/set', {
      pageKey: 'dashRecentLivingDonors',
      value: this.buildRecentLivingDonorsEditState(preferences),
    });
  }

  // Commit edit state field model values to vue-x store
  saveSettings(): void {
    const newState = this.extractDashboardSettings(this.preferences, this.editState);
    this.$store.dispatch('users/savePreferences', { preferences: newState }).then((success: SaveResult) => {
      // If successful dismiss dialog
      this.dismiss();
      this.initializeWidget();
      // Reinitialize if overall dashboard settings change
      this.$emit('reloadDashboard');
    }).catch((error: any) => {
      // Show error notification
      this.errorMessage = error.message;
    });
  }

  public dismiss(): void {
    $("#idRecentLivingDonors").collapse('toggle');
  }

  // Build edit state based on overall dashboard settings
  buildRecentLivingDonorsEditState(preferences: DashboardSettingState): WidgetSettings {
    return Object.assign({}, preferences.recentLivingDonors);
  }

  // Build new overall dashboard settings with new settings for this specific widget
  extractDashboardSettings(currentSettings: DashboardSettingState, editState: WidgetSettings): DashboardSettingState {
    const newSettings = Object.assign({ recentLivingDonors: {} }, currentSettings);
    newSettings.recentLivingDonors = {
      style: editState.style,
      visible: editState.visible
    };
    return newSettings;
  }

  get tableData() {
    const recentLivingDonors: any = this.getRecentLivingDonors.entries || [];
    const results: any[] = [];
    recentLivingDonors.map((record: any) => {
      const journeys = record.living_donor_info.journeys;
      const transplantProgramId = journeys ? journeys[0].transplant_program?.transplant_hospital_id?.$oid : null;
      const transplantProgram = transplantProgramId ? this.getHospitalAbbreviation(transplantProgramId) : '--';
      const result = {
        client_id: record.client_id,
        living_donor_id: record.living_donor_id,
        first_name: record.first_name,
        last_name: record.last_name,
        transplant_program: transplantProgram,
        registration_date: record.registration_date ? this.parseDisplayDateUiFromDateTime(record.registration_date) : '--',
      };

      if (this.prototypeFeatureEnabled('demo_phase_state')) {
        // Afflo Prototype
        Object.assign(result, {
          demo_phase: this.buildDemoPhase(record),
          demo_status: this.buildDemoStatus(record),
        });
      }

      results.push(result);
    });
    return results;
  }

  // Afflo Prototype
  buildDemoPhase(record: any): string {
    // NOTE: this special case should match what is in LivingDonorSummary
    if (record.client_id == 137344) return 'Recipient Match Assessment';

    const phaseIndex = (record.client_id || 0) % (this.phaseOptions?.length || 1);
    const pseudorandomPhase = this.phaseOptions[phaseIndex]?.value;
    return pseudorandomPhase;
  }

  // Afflo Prototype
  buildDemoStatus(record: any): string {
    // NOTE: this special case should match what is in LivingDonorSummary
    if (record.client_id == 137344) return 'Active';

    const statusIndex = (record.client_id || 0) % (this.statusOptions?.length || 1);
    const pseudorandomStatus = this.statusOptions[statusIndex]?.value;
    return pseudorandomStatus;
  }

  // Afflo Prototype
  get phaseOptions(): GenericCodeValue[] {
    return this.optionsFor([
      'Submission',
      'Eligibility Assessment',
      'Recipient Match Assessment',
      'Donation Surgery',
      'Post-Donation',
    ]);
  }

  // Afflo Prototype
  get statusOptions(): GenericCodeValue[] {
    return this.optionsFor([
      'New',
      'Active',
      'On Hold',
      'Inactive',
      'Donated',
    ]);
  }

  /**
   * Gets configuration for the table
   *
   * @returns {TableConfig} Configuration for the table
   */
  get tableConfig(): TableConfig {
    return {
      data: this.tableData,
      columns: this.columns,
      empty: this.$t('no_living_donors').toString(),
      sortOptions: {
        enabled: true,
        initialSortBy: {field: 'registration_date', type: 'desc'}
      },
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPage: 10,
        mode: 'records',
        perPageDropdown: [5, 10],
        dropdownAllowAll: false,
        nextLabel: '',
        prevLabel: '',
        rowsPerPageLabel: this.$t('results_per_page').toString(),
        position: 'bottom'
      }
    };
  }

  get columns(): any[] {
    const result = [
      { label: this.$t('donor_id').toString(), field: 'living_donor_id', sortable: true, type: 'number', thClass: 'vgt-left-align', tdClass: 'vgt-left-align' },
      { label: this.$t('first_name').toString(), field: 'first_name', sortable: true },
      { label: this.$t('last_name').toString(), field: 'last_name', sortable: true },
      // { label: this.$t('transplant_program').toString(), field: 'transplant_program', sortable: true },
      { label: this.$t('registration_date').toString(), field: 'registration_date', sortable: true, type: 'date', dateInputFormat: Format(this.getDateFormat).DISPLAY_DATE, dateOutputFormat: Format(this.getDateFormat).DISPLAY_DATE, },
    ];

    if (this.prototypeFeatureEnabled('demo_phase_state')) {
      result.push({ label: 'Phase', field: 'demo_phase', sortable: true });
      result.push({ label: 'Status', field: 'demo_status', sortable: true });
    }

    return result;
  }
}
</script>
