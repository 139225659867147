<template>
  <sub-section
    :title="$t('allocation_recommendation_listing')"
    sub-section-id="allocation-recommendation-listing"
    ref="allocationRecommendationListing"
    :save-button="false"
    :lookupsToLoad="lookupsToLoad"
    @loaded="loaded()"
  >
    <template v-slot:contents v-if="allocation">
      <a v-if="allPrimaryBackupOffers.length > 0" href="#allocation-offer-responses" class="allocation-jump-link" >
        {{`${$t('offer_responses')}`}}
        <font-awesome-icon :icon="['fas', 'arrow-down']" fixed-width />
      </a>

      <template v-if="showControls">
        <offer-modal
          ref="offerModal"
          @closeModal="closeModal"
          @display-outcome-notification="displayOutcomeNotification"
        />
        <offer-history-modal ref="offerHistoryModal" />
        <discontinue-modal
          ref="discontinueModal"
          @closeModal="closeModal"
          @display-outcome-notification="displayOutcomeNotification"
        />

        <!-- Transplant Details popup for manually added Out-of-Province Program entry -->
        <opo-transplant-details-modal
          ref="opoTransplantDetailsModal"
          @reload="reload"
          :allocationRecipientEntry="acceptedPrimaryOfferToOPOProgram"
        />

        <modal-section
          modal-id="excluded-recipients-view-modal"
          :wide="true"
          ref="excludedRecipientsListModal"
          class="modal-sticky-header">
          <template v-slot:title>
            {{ $t('excluded_recipients') }}
          </template>
          <template v-slot:body>
            <p class="text-center" v-if="loadingExcludedRecipients">
              {{ $t('loading_excluded_recipients') }}
            </p>
            <template v-else>
              <vue-good-table :columns="excludedRecipientsTableConfig.columns"
                              :rows="excludedRecipientsTableConfig.data"
                              :sortOptions="excludedRecipientsTableConfig.sortOptions">
                <template slot="table-row" slot-scope="props">
                  <template v-if="props.column.field === 'client_id'">
                    <!-- Molecular locus -->
                    <router-link :to="{name: 'edit-recipient', params: {id: props.row.client_id}}" target="_blank">
                      {{props.row.client_id}}
                    </router-link>
                  </template>
                  <template v-else>
                    {{props.row[props.column.field]}}
                  </template>
                </template>
              </vue-good-table>
            </template>
          </template>
        </modal-section>
      </template>
      <compare-modal ref="compareModal" />
      <div id="allocation-listing">

        <div class="mb-2" v-if="showControls">
          <nav class="nav action-row mb-0">
            <a href="#" @click="openPrimaryOfferHistory" data-toggle="modal" data-target="#primaryOfferHistory" :class="`btn btn-sm btn-light ${(primaryOffers.length) == 0 ? 'disabled' : ''}`">
              <font-awesome-icon :icon="['fas', 'history']" fixed-width /> {{$t('primary_offer_history')}}
            </a>
            <a href="#" @click="openBackupOfferHistory" data-toggle="modal" data-target="#backupOfferHistory" :class="`btn btn-sm btn-light ml-2 ${!backupOffers.length > 0 ? 'disabled' : ''}`">
              <font-awesome-icon :icon="['fas', 'history']" fixed-width /> {{$t('backup_offer_history')}}
            </a>
            <a href="#" @click="openNoOfferHistory" data-toggle="modal" data-target="#noOfferHistory" :class="`btn btn-sm btn-light ml-2 ${!noOffers.length > 0 ? 'disabled' : ''}`">
              <font-awesome-icon :icon="['fas', 'history']" fixed-width />  {{$t('no_offer_history')}}
            </a>

            <button
              type="button"
              @click="showExcludedRecipients()"
              class="btn btn-sm btn-light ml-auto"
              v-if="canViewExcludedRecipients"
            >
              {{ $t('show_excluded_recipients')}}
            </button>
          </nav>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="table-responsive-md allocationTable" v-if="editState">

              <!-- table -->
              <sorted-table
                :values="editState.records || []"
                ascIcon='<span class="sorting-asc" />'
                descIcon='<span class="sorting-desc" />'
                class="table table-bordered table-hover table-allocation"
                sort="effective_rank"
              >
                <!-- table header -->
                <thead>
                  <tr class="fixed-head">
                    <th scope="col" v-if="showControls">
                      <span>{{ $t('select') }}</span>
                    </th>
                    <template v-for="rowName in columnsByOrgan">
                      <th v-if="rowName === 'response'" v-bind:key="rowName" scope="col" class="text-center" style="min-width:110px;">
                        {{ $t('response') }}
                      </th>
                      <th v-else v-bind:key="rowName" scope="col" class="text-center">
                        {{ $t(rowName) }}
                      </th>
                    </template>
                  </tr>
                </thead>

                <!-- table body -->
                <tbody slot="body" slot-scope="sort">
                  <!-- table row : search -->
                  <tr>
                    <td v-if="showControls">
                      <checkbox-input
                        input-id='offer-response-select-all-matching-rows-recommended'
                        :label="$t('select_all')"
                        v-model="editState.selectAllMatchingRows"
                        @change="selectAllMatchingRows"
                        :disabled="isLoadingAllocation"
                      />
                    </td>
                    <template v-for="rowName in columnsByOrgan">
                      <td v-if="rowName === 'offer'" v-bind:key="rowName">
                        <text-input
                          class="form-group"
                          :hideLabel="true"
                          :inputId="`${rowName}_filter`"
                          name="offer"
                          type="text"
                          :value="searchParams[rowName] ? searchParams[rowName].value : ''"
                          @input="updateFilters($event, rowName)"
                          :disabled="isLoadingAllocation"
                        />
                      </td>
                      <td v-if="rowName === 'organ_spec_offered'" v-bind:key="rowName">&nbsp;</td>
                      <td v-if="rowName === 'response'" v-bind:key="rowName">
                        <text-input
                          class="form-group"
                          :inputId="`${rowName}_filter`"
                          :hideLabel="true"
                          :name="$t('response')"
                          type="text"
                          :value="searchParams['response_value'] ? searchParams['response_value'].value : ''"
                          @input="updateFilters($event, 'response_value')"
                          :disabled="isLoadingAllocation"
                        />
                      </td>
                      <td v-if="rowName === 'last_name'" v-bind:key="rowName">
                        <text-input
                          class="form-group"
                          :inputId="`${rowName}_filter`"
                          :hideLabel="true"
                          :name="$t('last_name')"
                          type="text"
                          :value="searchParams[rowName] ? searchParams[rowName].value : ''"
                          @input="updateFilters($event, rowName)"
                          :disabled="isLoadingAllocation"
                        />
                      </td>
                      <td v-if="rowName === 'hospital_abbreviation'" v-bind:key="rowName">
                        <select-input
                          select-id="offer-program-search-by"
                          class="form-group"
                          :name="$t('program')"
                          :hideLabel="true"
                          :undefinedText="$t('all')"
                          v-model="editState.programFilterValue"
                          :options="editState.programOptions"
                          @change="updateFilters($event, rowName, true)"
                          :disabled="isLoadingAllocation"
                        />
                      </td>
                      <td v-if="rowName === 'province_code'" v-bind:key="rowName">&nbsp;</td>
                      <td v-if="rowName === 'medical_status'" v-bind:key="rowName">
                        <text-input
                          class="form-group"
                          :inputId="`${rowName}_filter`"
                          :hideLabel="true"
                          :name="$t('medical_status')"
                          type="text"
                          :value="searchParams[rowName] ? searchParams[rowName].value : ''"
                          @input="updateFilters($event, rowName)"
                          :disabled="isLoadingAllocation"
                        />
                      </td>
                      <template v-if="showIposForAllocation">
                        <!-- new ipos heart fields -->
                        <td v-if="rowName === 'hsh'" v-bind:key="rowName" class="text-center">&nbsp;</td>
                        <td v-if="rowName === 'on_wait_time'" v-bind:key="rowName" class="text-center">&nbsp;</td>
                        <td v-if="rowName === 'on_wait_time_date'" v-bind:key="rowName" class="text-center">&nbsp;</td>
                        <td v-if="rowName === 'initial_list_date'" v-bind:key="rowName" class="text-center">&nbsp;</td>
                        <td v-if="rowName === 'ctr_wait_time'" v-bind:key="rowName" class="text-center">&nbsp;</td>
                        <td v-if="rowName === 'ctr_wait_time_date'" v-bind:key="rowName" class="text-center">&nbsp;</td>
                      </template>
                      <td v-if="rowName === 'effective_rank'" v-bind:key="rowName">&nbsp;</td>
                      <td v-if="rowName === 'client_id'" v-bind:key="rowName">&nbsp;</td>
                      <td v-if="rowName === 'sec_status'" v-bind:key="rowName">&nbsp;</td>
                      <td v-if="rowName === 'hsp'" v-bind:key="rowName">&nbsp;</td>
                      <td v-if="rowName === 'allocation_points'" v-bind:key="rowName">&nbsp;</td>
                      <td v-if="rowName === 'mpe_score'" v-bind:key="rowName">&nbsp;</td>
                      <td v-if="rowName === 'abo'" v-bind:key="rowName">&nbsp;</td>
                      <td v-if="rowName === 'organ'" v-bind:key="rowName">&nbsp;</td>
                      <td v-if="rowName === 'listed_for'" v-bind:key="rowName">
                        <select-input
                          select-id="listedfor-search-by"
                          class="form-group"
                          :name="$t('listed_for')"
                          :hideLabel="true"
                          :undefinedText="$t('all')"
                          v-model="editState.listedForFilterValue"
                          :options="getListedForOptions"
                          @change="updateFilters($event, rowName)"
                          :disabled="isLoadingAllocation"
                        />
                      </td>
                      <td v-if="rowName === 'registration_type'" v-bind:key="rowName">
                        <select-input
                          select-id="registration_type_for-search-by"
                          class="form-group"
                          :name="$t('registration_type')"
                          :hideLabel="true"
                          :undefinedText="$t('all')"
                          v-model="editState.registrationTypeFilterValue"
                          :options="getRegistrationTypeOptions"
                          @change="updateFilters($event, rowName)"
                          :disabled="isLoadingAllocation"
                        />
                      </td>
                      <td v-if="rowName === 'sex'" v-bind:key="rowName">&nbsp;</td>
                      <td v-if="rowName === 'age'" v-bind:key="rowName">&nbsp;</td>
                      <td v-if="rowName === 'height'" v-bind:key="rowName">&nbsp;</td>
                      <td v-if="rowName === 'weight'" v-bind:key="rowName">&nbsp;</td>
                      <td v-if="rowName === 'cpra'" v-bind:key="rowName">&nbsp;</td>
                      <td v-if="rowName === 'rec_status'" v-bind:key="rowName">&nbsp;</td>
                    </template>
                  </tr>

                  <!-- table row : data -->
                  <tr v-if="sort.values.length === 0">
                    <td class="text-center" :colspan="numColumns">
                      <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
                      {{ $t('allocation_recipients.no_recipients_found') }}
                    </td>
                  </tr>
                  <tr
                    v-else
                    :id="`recommendation_${row.rank}`"
                    :class="getAllocationRowStyle(row)"
                    v-for="(row, idx) in sort.values"
                    :key="idx"
                  >
                    <td class="td-check" v-if="showControls">
                      <input
                        role="checkbox"
                        :aria-checked="row.selected"
                        type="checkbox"
                        :id="`recommendation_select-${idx}`"
                        class="table-check-input"
                        :value="row.selected"
                        :checked="row.selected ? 'checked' : ''"
                        @change="checkRow($event, row)"
                        :disabled="isLoadingAllocation || row.disableOfferActions"
                      />
                    </td>
                    <template v-for="rowName in columnsByOrgan">
                      <td v-if="rowName === 'offer'" v-bind:key="rowName" class="text-center">
                        <offer-icon :offer="row.offer" :response="row.response"></offer-icon>
                      </td>
                      <td v-if="rowName === 'organ_spec_offered'" v-bind:key="rowName" class="text-center">
                        {{ $t(row.organ_spec_offered) }}
                      </td>
                      <td v-if="rowName === 'response'" v-bind:key="rowName" :class="getAllocationCellStyle(row)" class="text-center font-weight-bold">
                        {{offerResponseCodeValue(row)}}
                      </td>
                      <td v-if="rowName === 'effective_rank'" v-bind:key="rowName" class="text-center nobr">
                        <!-- Ranking in OATS -->
                        <span
                          class="font-weight-bold"
                          :title="$t('effective_rank_explanation')"
                        >
                          {{ row.display_rank }}
                        </span>
                        <!-- Ranking in CTR (if applicable) -->
                        <span
                          v-if="row.ctr_rank != null"
                          :title="$t('ctr_rank_explanation')"
                        >
                          ({{ row.ctr_rank }})
                        </span>
                      </td>
                      <!-- Disable table navigation link while allocation is loading -->
                      <td v-if="rowName === 'client_id' && isLoadingAllocation" v-bind:key="rowName">
                        {{row.client_id}}
                      </td>
                      <td v-else-if="rowName === 'client_id'" v-bind:key="rowName">
                        <a
                          :href="row.isOffered ? `#offer_${row.client_id}` : '#offer'"
                          :title="$t('link_recipient_responses_table')"
                          class="table-link"
                        >
                          {{row.client_id}}
                        </a>
                      </td>
                      <!-- Disable popup link while allocation is loading -->
                      <!-- In some situations there is no recipient data so we prevent user from opening compare modal -->
                      <td v-if="rowName === 'last_name' && (isLoadingAllocation || row.disableCompareModal)" v-bind:key="rowName">
                        {{row.last_name}}
                      </td>
                      <td v-else-if="rowName === 'last_name'" v-bind:key="rowName" class="set-link font-weight-bold">
                        <a
                          href="#"
                          data-toggle="modal"
                          data-target="#recipientDetailDemo"
                          @click="openCompareModal(row)"
                          :disabled="isLoadingAllocation"
                        >
                          {{row.last_name}}
                        </a>
                      </td>
                      <td v-if="rowName === 'hospital_abbreviation'" v-bind:key="rowName">{{row.hospital_abbreviation}}</td>
                      <td v-if="rowName === 'province_code'" v-bind:key="rowName">{{row.province_code}}</td>
                      <td v-if="rowName === 'medical_status'" v-bind:key="rowName">{{row.medical_status}}</td>
                      <template v-if="showIposForAllocation">
                        <!-- new ipos heart fields -->
                        <td v-if="rowName === 'hsh'" v-bind:key="rowName" class="text-center font-weight-bold">
                          <em>{{row.hsh}}</em>
                        </td>
                        <td v-if="rowName === 'on_wait_time'" v-bind:key="rowName" class="text-center" :title="$t('on_wait_time_hover_text')">{{row.on_wait_time}}</td>
                        <td v-if="rowName === 'on_wait_time_date'" v-bind:key="rowName" class="text-center">{{row.on_wait_time_date}}</td>
                        <td v-if="rowName === 'initial_list_date'" v-bind:key="rowName" class="text-center">{{row.initial_list_date}}</td>
                        <td v-if="rowName === 'ctr_wait_time'" v-bind:key="rowName" class="text-center" :title="$t('ctr_wait_time_hover_text')">{{row.ctr_wait_time}}</td>
                        <td v-if="rowName === 'ctr_wait_time_date'" v-bind:key="rowName" class="text-center">{{row.ctr_wait_time_date}}</td>
                      </template>
                      <td v-if="rowName === 'sec_status'" v-bind:key="rowName">{{row.sec_status}}</td>
                      <td v-if="rowName === 'hsp'" v-bind:key="rowName" class="text-center font-weight-bold">
                        <em>{{row.hsp}}</em>
                      </td>
                      <td v-if="rowName === 'allocation_points'" v-bind:key="rowName">
                        {{row.allocation_points}}
                      </td>
                      <td v-if="rowName === 'mpe_score'" v-bind:key="rowName">
                        {{row.mpe_score}}
                      </td>
                      <td v-if="rowName === 'abo'" v-bind:key="rowName">{{row.abo}}</td>
                      <td v-if="rowName === 'organ'" v-bind:key="rowName">{{row.organ}}</td>
                      <td v-if="rowName === 'listed_for'" v-bind:key="rowName">
                        <div v-for="(organ, idx) in row.listed_for" v-bind:key="idx">
                          <strong v-if="highlightOrgan(row.organ_code, row.cluster_organ_code, row.listed_for_codes[idx], row.listed_for_codes)">{{organ}}</strong>
                          <span v-else>{{organ}}</span>
                        </div>
                      </td>
                      <td v-if="rowName === 'registration_type'" v-bind:key="rowName">{{row.registration_type}}</td>
                      <td v-if="rowName === 'sex'" v-bind:key="rowName" class="text-center">
                        {{row.sex}}
                      </td>
                      <td v-if="rowName === 'age'" v-bind:key="rowName">{{row.age}}</td>
                      <td v-if="rowName === 'height'" v-bind:key="rowName">{{!isNaN(row.height) ? row.height.toFixed(1) : '-'}}</td>
                      <td v-if="rowName === 'weight'" v-bind:key="rowName">{{!isNaN(row.weight) ? row.weight.toFixed(1) : '-'}}</td>
                      <td v-if="rowName === 'cpra'" v-bind:key="rowName" class="text-center">
                        {{row.cpra}}
                      </td>
                      <td v-if="rowName === 'rec_status'" v-bind:key="rowName" class="font-weight-bold">
                        <template v-if="row.tip_donor_client_id">
                          <div class="text-center">
                            <mark class="highlight-tip"> {{$t('transplant_in_progress')}} </mark>
                            <!-- Disable TIP link while allocation is loading -->
                            <template v-if="isLoadingAllocation">
                              #{{row.tip_deceased_donor_id}}
                            </template>
                            <router-link v-else :to="{ name: 'edit-deceased-donor', params: { id: row.tip_donor_client_id} }">
                              #{{row.tip_deceased_donor_id}}
                            </router-link>
                          </div>
                        </template>
                        <template v-else>
                          {{row.rec_status}}
                        </template>
                      </td>
                    </template>
                  </tr>

                  <!-- Transplant Details popup row only needed for Out-of-Province Program-level accepted offer -->
                  <template v-if="isTransplantDetailsPopupApplicable">
                    <tr
                      :class="{ 'offer-row-primary': hasAcceptedPrimaryOfferToOPOProgram }"
                    >
                      <td class="opo-transplant-details text-right p-2" :colspan="numColumns">
                        <button
                          type=button
                          class="btn btn-sm btn-primary w-auto"
                          @click.prevent="openOPOTransplantDetailsPopup"
                          :disabled="!isOPOTransplantDetailsEnabled"
                          :title="isOPOTransplantDetailsEnabled ? $t('opo-button-enabled-explanation') : $t('opo-button-disabled-explanation')"
                        >
                          {{$t('opo_transplant_details_button')}}
                          <font-awesome-icon class="fa-1x" fixed-width :icon="['fas', 'external-link-alt']" />
                        </button>
                      </td>
                    </tr>
                  </template>
                </tbody>

                <!-- table footer -->
                <tfoot slot="foot" v-if="showControls">
                  <tr class="no-hover action-table-row sticky-row">
                    <td colspan="100%">

                      <nav class="nav action-row flex-align-end">
                        <div class="ml-1">
                          <p class="p-label w-100">
                            {{ $t('with_selected') }}
                          </p>
                          <div class="offer-button-wrap">

                            <button
                              type="button"
                              data-toggle="modal"
                              :disabled="!isAllocationOfferable || isLoadingAllocation || isMakingOffer"
                              @click="makeOffer()"
                              class="btn btn-wide btn-sm btn-success w-auto"
                            >
                              {{ $t('make_offer') }}
                              <span class="pl-2" v-if="isMakingOffer">
                                <font-awesome-icon class="fa-spin" :icon="['far', 'spinner-third']" />
                              </span>
                            </button>

                            <button
                              type="button"
                              data-toggle="modal"
                              :disabled="!discontinueAble || isLoadingAllocation || isDiscontinuingOneOffer"
                              @click="discontinueOffer()"
                              class="btn btn-wide btn-sm btn-dark w-auto"
                              :title="$t('discontinue_selected_offers')"
                            >
                              {{ $t('discontinue_offer') }}
                              <span class="pl-2" v-if="isDiscontinuingOneOffer">
                                <font-awesome-icon class="fa-spin" :icon="['far', 'spinner-third']" />
                              </span>
                            </button>
                          </div>
                        </div>
                        <div class="ml-auto d-flex align-items-end offer-timers">
                          <offer-timers></offer-timers>
                        </div>
                      </nav>
                    </td>
                  </tr>
                </tfoot>

              </sorted-table>

              </div>
            </div>
          </div>
        </div>
    </template>
  </sub-section>
</template>

<i18n src="./_locales/common.json"></i18n>
<i18n src="./_locales/_AllocationRecommendationListing.json"></i18n>
<i18n src="@/components/_locales/Organs.json"></i18n>
<i18n src="@/components/_locales/iposFields.json"></i18n>

<script lang="ts">

import { TableConfig } from '@/types';
import { Getter, State } from 'vuex-class';
import { SortedTable, SortLink } from 'vue-sorted-table';
import { mixins } from "vue-class-component";
import { AllocationUtilsMixin } from "@/mixins/allocation-utils-mixin";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import ModalSection from '@/components/shared/ModalSection.vue';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { DeceasedDonor } from '@/store/deceasedDonors/types';
import OpoTransplantDetailsModal from '@/components/allocations/OpoTransplantDetailsModal.vue';
import { Allocation, AllocationRecipient, AllocationOfferTypeValues, AllocationOfferResponseCodeValues, AllocationOfferRecipient, RegistrationType, OfferOutcomeContext } from '@/store/allocations/types';
import SubSection from '@/components/shared/SubSection.vue';
import OfferModal from '@/components/allocations/offers/OfferModal.vue';
import OfferHistoryModal from '@/components/allocations/offers/OfferHistoryModal.vue';
import OfferIcon from '@/components/allocations/offers/OfferIcon.vue';
import OfferTimers from '@/components/allocations/offers/OfferTimers.vue';
import DiscontinueModal from '@/components/allocations/_DiscontinueModal.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import CompareModal from '@/components/deceasedDonors/CompareModal.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import { OrganCodeValue } from '@/store/lookups/types';
import { GenericCodeValue } from '@/store/types';
import { organCodeLookup } from '@/types';
import { recipients } from '@/store/recipients';
import {EP} from "@/api-endpoints";
import {VueGoodTable} from "vue-good-table";
import TextInput from "@/components/shared/TextInput.vue";


interface AllocationRecipientCell {
  _id: string;
  selected?: boolean;
  client_id?: number;
  offer?: RecipientOfferCell;
  response?: string;
  rank?: number;
  effective_rank?: number;
  ctr_rank?: number|null;
  last_name?: string;
  program?: string|null;
  province_code?: string|null;
  medical_status?: string;
  secondary_medical_status?: string;
  blood_type?: string|null;
  hospital_abbreviation?: string|null;
  hsp?: string;
  allocation_points?: number|null;
  mpe_score?: number|null;
  listed_for?: string[];
  sex?: string|null;
  age?: number|null;
  height?: number|null;
  weight?: number|null;
  cpra?: number|null;
  status?: string;
  disableCompareModal?: boolean;
  disableOfferActions?: boolean;
  registration_type?: string|null;
  organ_code: number;
  isOffered: boolean;
  hsh?: boolean;
  on_wait_time?: string;
  on_wait_time_date?: string;
  initial_list_date: string;
  ctr_wait_time?: string;
  ctr_wait_time_date?: string;
}

interface RecipientOfferCell {
  offer_type_code?: string|null;
  response_code?: string|null;
  organ_specification_code?: string|null;
  offered_by?: string|null;
  response_reason_category_code?: string|null;
  response_by?: string|null;
  responsible_physician_id?: string|null;
}

const ALLOCATION_STATES_TRANSPLANT_DETAILS_ENABLED = [
  'offer-confirmed',
  'concluded',
];

@Component({
  components: {
    OfferModal,
    OfferHistoryModal,
    OfferIcon,
    OfferTimers,
    DiscontinueModal,
    SubSection,
    CheckboxInput,
    SortedTable,
    SortLink,
    ModalSection,
    CompareModal,
    SelectInput,
    VueGoodTable,
    TextInput,
    OpoTransplantDetailsModal,
  }
})

export default class AllocationRecommendationListing extends mixins(AllocationUtilsMixin, DateUtilsMixin) {
  @State(state => state.deceasedDonors.selected) private donor!: DeceasedDonor;
  @State(state => state.pageState.currentPage.allocationRecommendations) editState!: any;
  @State(state => state.allocations.isLoadingAllocation) private isLoadingAllocation!: boolean;
  @State(state => state.allocations.isMakingOffer) private isMakingOffer!: boolean;
  @State(state => state.allocations.isDiscontinuingOneOffer) private isDiscontinuingOneOffer!: boolean;

  @Getter('selectedAllocation', { namespace: 'allocations' }) private allocation!: Allocation;
  @Getter('recipients', { namespace: 'allocations' }) private recipients!: AllocationRecipient[];
  @Getter('primaryOffers', { namespace: 'allocations' }) private primaryOffers!: AllocationRecipient[];
  @Getter('backupOffers', { namespace: 'allocations' }) private backupOffers!: AllocationRecipient[];
  @Getter('noOffers', { namespace: 'allocations' }) private noOffers!: AllocationRecipient[];
  @Getter('isAllocationOfferable', { namespace: 'allocations' }) private isAllocationOfferable!: boolean;
  @Getter('offerResponseCode', { namespace: 'lookups' }) private offerResponseCode!: (code: string) => string;
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('isTransplantCoordinator', { namespace: 'users' }) private isTransplantCoordinator!: boolean;
  @Getter('manuallyAddedOutOfProvinceEntries', { namespace: 'allocations' }) private manuallyAddedOutOfProvinceEntries!: AllocationRecipient[];
  @Getter('clusterOrganCodeDisplayValue', { namespace: 'utilities' }) private clusterOrganCodeDisplayValue!: (organCode: number|null, clusterOrganCode?: string|null) => string;
  @Getter('organName', { namespace: 'lookups' }) organNameLookup!: (organCode?: number) => string;
  @Getter('getOrganSpecificationName', { namespace: 'lookups' }) getOrganSpecificationName!: (organCode?: number|null, organSpecificationCode?: number|null) => string;
  @Getter('getRecipientsByEffectiveRank', { namespace: 'allocations' }) getRecipientsByEffectiveRank!: (recipientRanks?: number[]) => AllocationOfferRecipient[];
  @Getter('determineHspValue', { namespace: 'allocations' }) determineHspValue!: (recipient?: AllocationRecipient) => string;
  @Getter('determineHshValue', { namespace: 'allocations' }) determineHshValue!: (recipient?: AllocationRecipient) => string;
  @Getter('allPrimaryBackupOffers', { namespace: 'allocations' }) private allPrimaryBackupOffers!: AllocationRecipient[];
  @Getter('showIposForAllocation', { namespace: 'allocations' }) private showIposForAllocation!: boolean;

  private loadingExcludedRecipients = false;
  private excludedRecipients: any[] = [];
  public searchParams: { [key: string]: { value: string; exact: boolean; }} = {};

  // Lookup tables to be loaded by the CardSection component
  public lookupsToLoad = [
    'offer_responses',
    'out_of_sequence_offer_reasons',
    'donor_exceptional_distribution',
    'oop_recipient_notification_email'
  ];

  /**
   * Checks to see if listed for contains kidney and pancreas
   *
   * @param listed_for_codes  all the listed_for organ codes
   * @returns {boolean} true / false
   */
  listedForIncludesKidneyAndPancreasCombination(listed_for_codes: string[]|undefined): boolean {
    // if no listed_for codes, return false
    if (!listed_for_codes || Array.isArray(listed_for_codes) && listed_for_codes.length <= 1) return false;
    // catch strange case where listed_for_codes returns string
    if (typeof listed_for_codes == 'string') return false;
    // if kidney & pancreas whole return true
    const organs: string[] = [];
    listed_for_codes.map((item: string) => {
      // separate organs, if we find one clustered split it so we have one array containing separate organs
      if (item.includes('/')) {
        const separated_organs = item.split('/');
        separated_organs.map((single_organ: string) => {
          organs.push(single_organ);
        });
      } else {
        organs.push(item);
      }
    });
    // deduplicate the array
    const unique_organs = [...new Set(organs)];

    // use that to see if we have a kidney & pancreas
    // ...regardless of whether it's made up of [kidney/lung, pancreas] or [kidney/pancreas, lung] or [kidney, pancreas, lung/liver]
    return unique_organs.includes(OrganCodeValue.Kidney.toString()) && unique_organs.includes(OrganCodeValue.PancreasWhole.toString());
  }

  /**
   * Checks to see if organ listed should be highlighted
   *
   * @param organ_code         the 'organ' row's organ code when it's a single organ
   * @param cluster_organ_code the 'organ' row's organ code when it's a cluster
   * @param listed_for_code    this particular listed_for organ code
   * @param listed_for_codes   all the listed_for organ codes
   * @returns {boolean} true if should be highlighted
   */
  highlightOrgan(organ_code: string, cluster_organ_code: string, listed_for_code: string, listed_for_codes: string[]): boolean {
    const real_organ_code = cluster_organ_code ? cluster_organ_code : organ_code;
    if (!listed_for_code) { return false; }
    if (this.allocation.organ_code != OrganCodeValue.Kidney) { return false; }
    if (real_organ_code == '3/6') { return false; }
    const listedForKidneyPancreas = this.listedForIncludesKidneyAndPancreasCombination(listed_for_codes);
    return real_organ_code == listed_for_code && listedForKidneyPancreas;
  }

  get showControls(): boolean {
    if (this.isTransplantCoordinator) { return false; }
    return this.checkAllowed("/donors/:donor_id/organs/:organ_id/allocations/:allocation_id/offers", "POST");
  }

  /**
   * Emits a loaded event after all subcomponents have finished loading.
   *
   * @listens allocationRecommendationListing#loaded
   * @emits loaded
   */
  public loaded(): void {
    this.$emit('loaded', 'allocationRecommendationListing');
  }

  /**
   * Return if we're allowed to discontinue offers
   *
   * Allocation state is offering, offer-accepted or offer-confirmed and there are selected rows
   *
   * @returns {boolean} true if we're allowed to discontinue offers
   */
  get discontinueAble(): boolean {
    if (!this.allocation.state) return false;
    return (this.allocation.state === 'offering' || this.allocation.state === 'offer-accepted' || this.allocation.state === 'offer-confirmed') && this.hasSelectedIds;
  }

  // Generate column list by Organ Code
  get columnsByOrgan(): any[] {
    let columns = ['offer', 'organ_spec_offered', 'response', 'effective_rank', 'client_id', 'last_name', 'hospital_abbreviation', 'province_code', 'medical_status', 'hsh', 'on_wait_time', 'on_wait_time_date', 'initial_list_date', 'ctr_wait_time', 'ctr_wait_time_date', 'sec_status', 'hsp', 'allocation_points', 'mpe_score', 'abo', 'organ', 'listed_for', 'registration_type', 'sex', 'age', 'height', 'weight', 'cpra', 'rec_status'];

    // TODO: Clean up this case or replace it with something better
    switch(this.allocationOrganCode) {
      case OrganCodeValue.SmallBowel:
      case OrganCodeValue.VCA:
        columns = columns.filter((item: string) => item !== "allocation_points");
        columns = columns.filter((item: string) => item !== "sec_status");
        columns = columns.filter((item: string) => item !== "mpe_score");
        columns = columns.filter((item: string) => item !== "organ");
        columns = columns.filter((item: string) => item !== "hsp");
        columns = columns.filter((item: string) => item !== "organ_spec_offered");
        columns = columns.filter((item: string) => item !== "hsh");
        columns = columns.filter((item: string) => item !== "on_wait_time");
        columns = columns.filter((item: string) => item !== "on_wait_time_date");
        columns = columns.filter((item: string) => item !== "initial_list_date");
        columns = columns.filter((item: string) => item !== "ctr_wait_time");
        columns = columns.filter((item: string) => item !== "ctr_wait_time_date");
        break;
      case OrganCodeValue.Liver:
        columns = columns.filter((item: string) => item !== "allocation_points");
        columns = columns.filter((item: string) => item !== "sec_status");
        columns = columns.filter((item: string) => item !== "organ");
        columns = columns.filter((item: string) => item !== "cpra");
        columns = columns.filter((item: string) => item !== "hsp");
        columns = columns.filter((item: string) => item !== "hsh");
        columns = columns.filter((item: string) => item !== "on_wait_time");
        columns = columns.filter((item: string) => item !== "on_wait_time_date");
        columns = columns.filter((item: string) => item !== "initial_list_date");
        columns = columns.filter((item: string) => item !== "ctr_wait_time");
        columns = columns.filter((item: string) => item !== "ctr_wait_time_date");
        break;
      case OrganCodeValue.PancreasWhole:
        columns = columns.filter((item: string) => item !== "sec_status");
        columns = columns.filter((item: string) => item !== "mpe_score");
        columns = columns.filter((item: string) => item !== "organ");
        columns = columns.filter((item: string) => item !== "hsp");
        columns = columns.filter((item: string) => item !== "organ_spec_offered");
        columns = columns.filter((item: string) => item !== "hsh");
        columns = columns.filter((item: string) => item !== "on_wait_time");
        columns = columns.filter((item: string) => item !== "on_wait_time_date");
        columns = columns.filter((item: string) => item !== "initial_list_date");
        columns = columns.filter((item: string) => item !== "ctr_wait_time");
        columns = columns.filter((item: string) => item !== "ctr_wait_time_date");
        break;
      case OrganCodeValue.PancreasIslets:
        columns = columns.filter((item: string) => item !== "allocation_points");
        columns = columns.filter((item: string) => item !== "sec_status");
        columns = columns.filter((item: string) => item !== "mpe_score");
        columns = columns.filter((item: string) => item !== "organ");
        columns = columns.filter((item: string) => item !== "hsp");
        columns = columns.filter((item: string) => item !== "organ_spec_offered");
        columns = columns.filter((item: string) => item !== "hsh");
        columns = columns.filter((item: string) => item !== "on_wait_time");
        columns = columns.filter((item: string) => item !== "on_wait_time_date");
        columns = columns.filter((item: string) => item !== "initial_list_date");
        columns = columns.filter((item: string) => item !== "ctr_wait_time");
        columns = columns.filter((item: string) => item !== "ctr_wait_time_date");
        break;
      case OrganCodeValue.Kidney:
        columns = columns.filter((item: string) => item !== "sec_status");
        columns = columns.filter((item: string) => item !== "mpe_score");
        columns = columns.filter((item: string) => item !== "organ");
        columns = columns.filter((item: string) => item !== "hsh");
        columns = columns.filter((item: string) => item !== "on_wait_time");
        columns = columns.filter((item: string) => item !== "on_wait_time_date");
        columns = columns.filter((item: string) => item !== "initial_list_date");
        columns = columns.filter((item: string) => item !== "ctr_wait_time");
        columns = columns.filter((item: string) => item !== "ctr_wait_time_date");
        break;
      case OrganCodeValue.Lung:
        columns = columns.filter((item: string) => item !== "allocation_points");
        columns = columns.filter((item: string) => item !== "sec_status");
        columns = columns.filter((item: string) => item !== "mpe_score");
        columns = columns.filter((item: string) => item !== "organ");
        columns = columns.filter((item: string) => item !== "hsp");
        columns = columns.filter((item: string) => item !== "hsh");
        columns = columns.filter((item: string) => item !== "on_wait_time");
        columns = columns.filter((item: string) => item !== "on_wait_time_date");
        columns = columns.filter((item: string) => item !== "initial_list_date");
        columns = columns.filter((item: string) => item !== "ctr_wait_time");
        columns = columns.filter((item: string) => item !== "ctr_wait_time_date");
        break;
      case OrganCodeValue.Heart:
        columns = columns.filter((item: string) => item !== "allocation_points");
        columns = columns.filter((item: string) => item !== "mpe_score");
        columns = columns.filter((item: string) => item !== "organ");
        columns = columns.filter((item: string) => item !== "hsp");
        columns = columns.filter((item: string) => item !== "organ_spec_offered");
        if (this.showIposForAllocation) {
          // hide for ipos heart
          columns = columns.filter((item: string) => item !== "sec_status");
        } else {
          // hide for non-ipos heart
          columns = columns.filter((item: string) => item !== "hsh");
          columns = columns.filter((item: string) => item !== "on_wait_time");
          columns = columns.filter((item: string) => item !== "on_wait_time_date");
          columns = columns.filter((item: string) => item !== "initial_list_date");
          columns = columns.filter((item: string) => item !== "ctr_wait_time");
          columns = columns.filter((item: string) => item !== "ctr_wait_time_date");
        }
        break;
      default:
        columns = columns.filter((item: string) => item !== "allocation_points");
        columns = columns.filter((item: string) => item !== "sec_status");
        columns = columns.filter((item: string) => item !== "mpe_score");
        columns = columns.filter((item: string) => item !== "organ");
        columns = columns.filter((item: string) => item !== "hsp");
        columns = columns.filter((item: string) => item !== "hsh");
        columns = columns.filter((item: string) => item !== "on_wait_time");
        columns = columns.filter((item: string) => item !== "on_wait_time_date");
        columns = columns.filter((item: string) => item !== "initial_list_date");
        columns = columns.filter((item: string) => item !== "ctr_wait_time");
        columns = columns.filter((item: string) => item !== "ctr_wait_time_date");
        break;
    }
    return columns;
  }

  /** Filter offers by search params
   *
   * @param event input event object
   * @param field field name
   * @param exact does the value need to match exactly (no lowerCase match)
   */
  public updateFilters(event: any, field: string, exact: boolean) {
    this.searchParams[field] = { value: event, exact: exact };

    let records = this.editState.recordsAll;
    Object.keys(this.searchParams).map((key:string) => {
      records = this.searchAllocationsBy(records, key, this.searchParams[key].value, this.searchParams[key].exact);
    });
  }

  /**
   * Search Allocation Recipients and filter the listing
   *
   * This will filter the Allocation Recipients
   * based on the the search params and respective values.
   *
   * @param records records to filter by
   * @param column column we intend to search
   * @param value value we intend to search by
   * @param exact does the value need to match exactly (no lowerCase match)
   */
  private searchAllocationsBy(records:any, column: string|undefined, value: string, exact: boolean) {
    const results = records.filter((item: any) => {
      let props = (value && column) ? [item[column]] : Object.values(item);

      return props.some((prop: any) => {
        // If no value we're defaulting back to showing all values
        if (!value) return true;
        // Flag to determine if we should return this record
        let returnValue = false;
        // Lower case all items if they're an array (this would only be the listed_for column)
        const filteredProp: string[] = Array.isArray(prop) ? (prop.map((item: string) => item.toLowerCase())) : [prop];

        // Are we looking for an exact match?
        if (exact) {
          returnValue = filteredProp[0] === value;
        } else {
          // If we're looking through the listed_for column we need to look at
          // the array, otherwise join all the items and search that.
          if (column === 'listed_for') {
            returnValue = filteredProp.includes(value.toLowerCase());
          } else {
            returnValue = filteredProp.join('').toLowerCase().includes(value.toLowerCase());
          }
        }
        // Return true if we found a matching value
        return returnValue || false;
      });
    });

    Vue.set(this.editState, 'records', results);
    Vue.set(this.editState, 'selectAllMatchingRows', false); // uncheck 'select all'

    return results;
  }

  get getListedForOptions(): any[] {
    if (this.recipients.length <= 0) return [];
    const options: any[] = [];
    this.recipients.filter((recipient: AllocationRecipient) => {
      if (recipient.offer !== null) {
        // Fallback to showing single organ based on organ_code for any rows missing waitlisted_for_organs
        const listed_for = recipient.waitlisted_for_organs && recipient.waitlisted_for_organs.length > 0 ? recipient.waitlisted_for_organs : [this.clusterOrganCodeDisplayValue(recipient.organ_code, recipient.cluster_organ_code)];
        (listed_for || []).forEach((item: string) => options.push(item));
      }
    });
    const filteredOptions = [...new Set(options)];
    const selectOptions: GenericCodeValue[] = [];
    filteredOptions.map((item: string) => {
      selectOptions.push({code: item, value: item});
    });
    return selectOptions;
  }

  get getRegistrationTypeOptions(): any[] {
    if (this.editState.recordsAll.length <= 0) return [];
    const options: any[] = [];
    // get all current types
    this.editState.recordsAll.filter((recipient: AllocationRecipient) => {
      options.push(recipient.registration_type);
    });
    // de-duplicate list
    const filteredOptions = [...new Set(options)];
    // return options
    const selectOptions: GenericCodeValue[] = [];
    filteredOptions.map((item: string) => {
      selectOptions.push({code: item, value: item});
    });
    return selectOptions;
  }

  public checkRow(event: any, row: any): void {
    if (row && row.effective_rank) {
      if (event.target.checked) {
        this.editState.records.map((item: any) => {
          if (item.effective_rank === row.effective_rank) {
            item.selected = true;
          }
        });
      } else {
        this.editState.records.map((item: any) => {
          if (item.effective_rank === row.effective_rank) {
            item.selected = false;
          }
        });
      }
    }
  }

  /**
   * Show the Recipient/Donor comparison pop-up for the specified row
   *
   * @param row recipient entry from allocation list
   */
  private openCompareModal(row: AllocationRecipientCell): void {
    const recipientId = row._id;
    const journeyOrganCode = row.organ_code;
    (this.$refs.compareModal as CompareModal).initializeAllocationCompare(recipientId, journeyOrganCode);
  }

  public mounted(): void {
    this.initializeForm();
  }

  /**
   * Watch for changes to the recipients
   *
   * @listens recipients#changed
   */
  @Watch('recipients', { immediate: true, deep: true })
  private initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'allocationRecommendations',
      value: {
        recordsAll: this.buildAllocationRecommendations(this.recipients), // used for searching
        records: this.buildAllocationRecommendations(this.recipients), // used for selection, altered
        selectAllMatchingRows: false,
        programFilterValue: null,
        listedForFilterValue: null,
        programOptions: this.getHospitalPrograms(this.recipients)
      }
    });
  }

  // Sanitize 'Listed For' information
  private listedFor(record: AllocationRecipient): string[] {
    let result: string[] = record.waitlisted_for_organs && record.waitlisted_for_organs.length > 0 ? record.waitlisted_for_organs : [this.clusterOrganCodeDisplayValue(record.organ_code, record.cluster_organ_code)];

    // Combine the organ listing information for Out-of-Province cluster entry
    if (record.out_of_province && record.registration_type === RegistrationType.Cluster) {
      result = [result.join('/')];
    }

    return result;
  }

  // Sanitize 'Listed For Codes' information
  private listedForCodes(record: AllocationRecipient): string[] {
    let result: string[] = record?.waitlisted_for_organ_codes || [];

    // Combine the organ listing information for Out-of-Province cluster entry
    if (record.out_of_province && record.registration_type === RegistrationType.Cluster) {
      result = [result.join('/')];
    }

    return result;
  }

  public buildAllocationRecommendations(recipients: AllocationRecipient[]): AllocationRecipientCell[] {
    if (!recipients) {
      return [];
    }
    const results: any[] = [];
    recipients.forEach((record: AllocationRecipient) => {
      const hspValue = this.determineHspValue(record);
      const waitlistedFor = this.listedFor(record);
      const registrationType = this.parseRegistrationType(record.registration_type, waitlistedFor);
      const row: any = {
        selected: false,
        _id: record._id,
        offer: record.offer?.offer_type_code || undefined,
        organ_spec_offered: record.offer && record.offer?.organ_specification_code ? this.getOrganSpecificationName(this.allocation.organ_code, record.offer?.organ_specification_code) : null,
        response: record.offer?.response_code || undefined,
        // response_value is used for searching inside the response column
        response_value: `${this.offerResponseCodeValue({ response: record.offer?.response_code, offer: record.offer?.offer_type_code })} ${record.offer?.response_code}`,
        // response_for_sorting is only used for sorting code. 'zzz' is in place of blank values for sorting a,b,c,blank,blank,blank
        response_for_sorting: record.offer?.response_code || 'zzz',
        rank: record.rank || undefined,
        effective_rank: record.effective_rank || undefined,
        display_rank: record.rank && !record.added_manually ? record.effective_rank || undefined : null,
        ctr_rank: record.ctr_rank,
        client_id: record.client_id || undefined,
        isOffered: (record.offer) ? true : false,
        last_name: record.last_name || '-',
        program: record.program || '-',
        hospital_abbreviation: record.hospital_abbreviation || record.program || '-',
        province_code: record.province_code || '-',
        medical_status: record.medical_status || '-',
        sec_status: record.secondary_medical_status || '-',
        abo: record.blood_type || '-',
        hsp: hspValue,
        allocation_points: record.allocation_points == null ? '-' : record.allocation_points,
        mpe_score: record.mpe_score || '-',
        organ: this.clusterOrganCodeDisplayValue(record.organ_code, record.cluster_organ_code),
        cluster_organ_code: record.cluster_organ_code,
        organ_code: record.organ_code,
        listed_for: waitlistedFor,
        listed_for_codes: this.listedForCodes(record),
        sex: record.sex || '-',
        age: record.age === 0 ? 0 : (record.age || '-'),
        height:  record.out_of_province ? '-' :record.height || '-',
        weight:  record.out_of_province ? '-' :record.weight || '-',
        cpra: record.cpra === 0 ? 0 : (record.cpra || '-'),
        rec_status: record.status || '-',
        tip_donor_client_id: record.transplant_in_progress ? record.transplant_in_progress.donor_client_id : null,
        tip_deceased_donor_id: record.transplant_in_progress ? record.transplant_in_progress.deceased_donor_id : null,
        /**
         * Oct 18, 2021 - Overridden to never disable the compare modal as we will need to to enter transplant details
         * for manually added out of province recipients.
         */
        disableCompareModal: false,
        // Prevent interaction with rows where Allocation Service has 'entry_offer_actions_enabled: false'
        disableOfferActions: record.entry_offer_actions_enabled === false,
        registration_type: registrationType || '-',
        // IPOS Heart Data
        hsh: this.determineHshValue(record),
        on_wait_time: record.wait_days === 0 ? 0 : (record.wait_days || '-'),
        on_wait_time_date: this.parseDisplayDateUiFromDateTime(record.wait_days_date) || '-',
        initial_list_date: this.parseDisplayDateUiFromDateTime(record.listing_date) || '-',
        ctr_wait_time: record.ctr_wait_days === 0 ? 0 : (record.ctr_wait_days || '-'),
        ctr_wait_time_date: this.parseDisplayDateUiFromDateTime(record.ctr_wait_days_date) || '-',
      };

      results.push(row);
    });
    return results;
  }

  public openPrimaryOfferHistory(): void {
    const offerHistoryModal = this.$refs.offerHistoryModal as OfferHistoryModal;
    offerHistoryModal.initializeModal(AllocationOfferTypeValues.Primary);
  }

  public openBackupOfferHistory(): void {
    const offerHistoryModal = this.$refs.offerHistoryModal as OfferHistoryModal;
    offerHistoryModal.initializeModal(AllocationOfferTypeValues.Backup);
  }

  public openNoOfferHistory(): void {
    const offerHistoryModal = this.$refs.offerHistoryModal as OfferHistoryModal;
    offerHistoryModal.initializeModal(AllocationOfferTypeValues.NoOffer);
  }

  get getSelectedIds(): number[] {
    const rows = this.editState.records || [];
    const selectedIds: Set<number> = new Set();

    // These rows are now using effective_rank an an identifier
    rows.forEach((item: AllocationRecipientCell) => {
      if (item.selected && typeof item.effective_rank === 'number') {
        selectedIds.add(item.effective_rank);
      }
    });
    return Array.from(selectedIds);
  }

  get hasSelectedIds(): boolean {
    return this.getSelectedIds.length > 0;
  }

  get allocationOrganCode(): number|null {
    return this.allocation.organ_code || null;
  }

  public makeOffer(): void {
    if (this.hasSelectedIds) {
      if (this.checkForMixOfHSPTypes(this.getSelectedIds)) {
        alert(this.$t('cannot_mix_hsp_with_non_hsp'));
      } else {
        const offerModal = this.$refs.offerModal as OfferModal;
        offerModal.initializeAllocationOffer(this.getSelectedIds);
      }
    }
  }

  /**
   * Check for mixture of hsp and non-hsp recipients
   *
   * @param recipientRanks selected ids
   * @returns {boolean} if mixture of hsp and non-hsp recipients detected
   */
  public checkForMixOfHSPTypes(recipientRanks: number[]): boolean {
    const recipients = this.getRecipientsByEffectiveRank(recipientRanks);

    // find hsp and non-hsp recipients
    const hspRecipients = recipients.filter((record: AllocationOfferRecipient) => { return record.hsp == 'HSP'; });
    const nonHspRecipients = recipients.filter((record: AllocationOfferRecipient) => { return record.hsp != 'HSP'; });

    return hspRecipients.length > 0 && nonHspRecipients.length > 0;
  }

  public discontinueOffer(): void {
    if (this.hasSelectedIds) {
      const discontinueModal = this.$refs.discontinueModal as DiscontinueModal;
      discontinueModal.initializeDiscontinueModal(this.getSelectedIds);
    }
  }

  private closeModal(): void {
    this.editState.records.map((item: any) => {
      item.selected = false;
    });
  }

  public selectAllMatchingRows(checked: boolean): void {
    if (checked) {
      this.editState.records.map((item: AllocationRecipientCell) => {
        // make offer: can do multiple offers regardless of sequence for backup and no-offer
        // also, don't select recipient entries that have offer actions disabled for any reason
        item.selected = (!item.offer && !item.response && !item.disableOfferActions) ? true : false;
      });
    } else {
      this.editState.records.map((item: AllocationRecipientCell) => {
        item.selected = false;
      });
    }
  }

  getAllocationRowStyle(row: any): string {
    let style = [];
    // if recipient status is transplant in progress then push style
    if (row.tip_donor_client_id) {
      style.push("highlight-tip");
    }

    // offer circle style
    switch(row.offer) {
      case AllocationOfferTypeValues.Primary:
        // primary
        style.push("offer-row-primary");
        break;
      case AllocationOfferTypeValues.Backup:
        // backup
        style.push("offer-row-backup");
        break;
      case AllocationOfferTypeValues.NoOffer:
        // no offer (will also have to provide reason_category & reason_code)
        style.push("offer-row-no-offer");
        break;
      default:
        // null = no offer
        style.push("offer-row-unoffered");
        break;
    }
    // offer response style
    switch(row.response) {
      case AllocationOfferResponseCodeValues.Accept:
        style.push("row-response-accepted");
        break;
      case AllocationOfferResponseCodeValues.AcceptWithCondition:
        style.push("row-response-accepted-condition");
        break;
      case AllocationOfferResponseCodeValues.Withdraw:
        style.push("row-response-declined");
        break;
      case AllocationOfferResponseCodeValues.Cancel:
        style.push("row-response-declined");
        break;
      case AllocationOfferResponseCodeValues.Decline:
        style.push("row-response-declined");
        break;
      default:
        // do nothing
        break;
    }
    if (row.hsp === "HSP") style.push("hsp-row");

    // add style to indicate that offer actions are disabled for certain rows
    if (row.disableOfferActions) style.push("row-response-declined");

    return style.join(" ");
  }

  /**
   * Return response column text
   *
   * Check for a response code and return that, otherwise check if
   * this is a primary or backup offer without a response.
   *
   * @param row table row
   * @returns {string} text for the response code
   */
  private offerResponseCodeValue(row: any): string {
    // get the response value from the code
    const response: string = this.offerResponseCode(row.response);
    if (!!response) {
      return response;
    }
    if (row.offer === AllocationOfferTypeValues.Backup || row.offer === AllocationOfferTypeValues.Primary) {
      return "No Response";
    }
    return "";
  }

  /**
   * Return table cell css class
   *
   * Check for an offer, if there is one check the response to
   * determine which css class should be applied.
   *
   * @param row table row
   * @returns {string} css class
   */
  private getAllocationCellStyle(row: any): string {
    const style = [];
    // do we have an offer?
    if (!!row.offer && row.offer != AllocationOfferTypeValues.NoOffer) {
      // accepted or accepted with condition
      if (row.response === AllocationOfferResponseCodeValues.Accept || row.response === AllocationOfferResponseCodeValues.AcceptWithCondition) {
        style.push("response-accepted");
      }
      // declined
      if (row.response === AllocationOfferResponseCodeValues.Decline) {
        style.push("response-declined");
      }
      // no response
      if (!row.response) {
        style.push("response-none");
      }
    }
    return style.join(" ");
  }

  // Offer outcome notification events bubble up to the view
  private displayOutcomeNotification(context: OfferOutcomeContext) {
    this.$emit('display-outcome-notification', context);
  }

  private toggleModal(ref: string): void {
    const targetModal = this.$refs[ref] as ModalSection;
    targetModal.toggleModal();
  }

  /**
   * Check if the current user is allowed to view the excluded recipients
   *
   * @return boolean
   */
  get canViewExcludedRecipients(): boolean {
    return this.checkAllowed(EP.deceasedDonors.allocations.excluded_recipients);
  }

  /**
   * Method to trigger the API call to get the excluded recipients and show the modal
   */
  public showExcludedRecipients() {
    if(!this.allocation || !this.allocation._id) {
      return;
    }
    this.loadingExcludedRecipients = true;
    this.toggleModal('excludedRecipientsListModal');

    this.$store.dispatch('allocations/getExcludedRecipients', {
      donorId: this.allocation.client_id, organCode: this.allocation.organ_code, allocationId: this.allocation._id
    }).then((response) => {
      this.excludedRecipients = response.data.excluded_recipients.excluded_recipients;
      this.loadingExcludedRecipients = false;
    }).catch((_error) => {
      alert(this.$t('excluded_recipients_modal.loading_error'));
    });
  }

  public get excludedRecipientsTableConfig(): TableConfig {
    return {
      data: this.excludedRecipients,
      columns: [
        { label: this.$t('excluded_recipients_modal.table_columns.client_id'), field: 'client_id'},
        { label: this.$t('excluded_recipients_modal.table_columns.ctr_id'), field: 'national_recipient_id'},
        { label: this.$t('excluded_recipients_modal.table_columns.first_name'), field: 'first_name'},
        { label: this.$t('excluded_recipients_modal.table_columns.last_name'), field: 'last_name'},
        { label: this.$t('excluded_recipients_modal.table_columns.blood_type'), field: 'blood_type'},
        { label: this.$t('excluded_recipients_modal.table_columns.excluded_reason_code'), field: 'excluded_reason_code'},
        { label: this.$t('excluded_recipients_modal.table_columns.excluded_reason'), field: 'excluded_reason'},
      ],
      sortOptions: {
        enabled: false,
      },
    };
  }

  // Show the inline OPO Transplant Details button?
  get isTransplantDetailsPopupApplicable(): boolean {
    // Do we have any manually-added Out-of-Province Programs in the list?
    return this.manuallyAddedOutOfProvinceEntries.length > 0;
  }

  // Do we have an applicable OPO Program offer?
  get acceptedPrimaryOfferToOPOProgram(): AllocationRecipient|null {
    if (!this.isTransplantDetailsPopupApplicable) return null;

    // Check for Primary / Accepted
    const opoProgramEntries = this.manuallyAddedOutOfProvinceEntries || [];
    const filtered = opoProgramEntries.filter((entry: AllocationRecipient) => {
      return entry.offer && entry.offer.offer_type_code == AllocationOfferTypeValues.Primary && entry.offer.response_code == AllocationOfferResponseCodeValues.Accept;
    });
    if (filtered.length === 0) return null;

    // Here we assume there will only be one
    return filtered[0] || null;
  }

  // Does OPO Program have accepted primary offer?
  get hasAcceptedPrimaryOfferToOPOProgram(): boolean {
    return !!this.acceptedPrimaryOfferToOPOProgram;
  }

  // Are we ready to use the OPO Transplant Details feature?
  get isOPOTransplantDetailsEnabled(): boolean {
    if (!this.allocation || !this.hasAcceptedPrimaryOfferToOPOProgram) return false;

    return ALLOCATION_STATES_TRANSPLANT_DETAILS_ENABLED.includes(this.allocation.state);
  }

  // How many columns are visible in the Allocation List?
  get numColumns(): number {
    if (!this.columnsByOrgan) return 1;

    return this.columnsByOrgan.length + 1;
  }

  // Open the OPO Transplant Details popup
  private openOPOTransplantDetailsPopup(): void {
    const opoTransplantDetailsModal = this.$refs.opoTransplantDetailsModal as OpoTransplantDetailsModal;
    if (opoTransplantDetailsModal) opoTransplantDetailsModal.openModal();
  }

  // Bubble up a request to the view that the Donor data needs reloaded
  private reload(): void {
    this.$emit('reload');
  }
}
</script>
