<template>
  <base-widget :title="$t('title_widget_lab_results')" :preferences="preferences.labResults" v-if="editState">
    <template v-slot:icon>
      <div class="circle dashboard-icon circle-active-allocations">
        <font-awesome-icon :icon="['fac', 'heart']" />
      </div>
    </template>
    <template v-slot:linkID>
      <a href="#id-lab-results" class="nav-link card-header-btn py-0" data-toggle="collapse">
        <font-awesome-icon :icon="['far', 'cog']" data-toggle="tooltip" :title="$t('customize_panel')" />
      </a>
      <a href="#body-lab-results" class="nav-link card-header-btn py-0" data-toggle="collapse">
        <font-awesome-icon :icon="['far', 'caret-down']" data-toggle="tooltip" :title="$t('collapse_panel')" />
      </a>
    </template>
    <template v-slot:options>
      <div class="card-body card-options p-0 collapse" id="id-lab-results">
        <div class="customize-panel">
          <h4>{{$t('panel_options')}}</h4>
          <!-- Widget options-->
          <slot name="options">
            <form>
              <div class="form-row mb-3">
                <div class="col-sm-6">
                  <select-input
                    name="panel-style"
                    select-id='dash-lab-results-style'
                    v-model="editState.style"
                    :label="$t('panel_style')"
                    :options="getPanelStyles"
                    />
                </div>
              </div>
              <div class="sub-divider"></div>
              <div class="form-row mb-3">
                <div class="col-sm-6">
                  <checkbox-input
                    input-id='dash-lab-results-visible'
                    v-model="editState.visible"
                    :label="$t('show_this_panel')"
                    :disabled="requiredWidget"
                  />
                </div>
              </div>
              <div class="sub-divider"></div>
              <div class="col-sm-12" v-if="errorMessage">
                <p>{{errorMessage}}</p>
              </div>
              <button
                type="button"
                class="btn btn-sm btn-wide btn-success"
                data-target="#id-lab-results"
                @click="saveSettings()"
              >
                {{$t('save')}}
              </button>
            </form>
          </slot>
        </div>
      </div>
    </template>
    <template v-slot:widget-contents v-if="isWidgetLoaded">
      <div class="card-body collapse show" id="body-lab-results">
        <sub-section
          sub-section-id="LabResultsTable"
          title=""
          style-class="vgt-table table table-bordered table-hover bordered"
          :total-records="getLabResults.count"
          :table-config="tableConfig"
          v-if="getLabResults"
        >
          <template v-slot:table-cell="props">
            <template v-if="props.column.field == 'deceased_donor_id'">
              <router-link
                class="table-link"
                :to="{ name: 'edit-deceased-donor', params: { id: props.row.donor_client_id } }"
                :title="$t('link_to_donor_page')"
                :alt="props.row.deceased_donor_id"
                target="_blank"
              >
                {{ props.row.deceased_donor_id ? props.formattedRow[props.column.field] : 'Unknown' }}
              </router-link>
            </template>
          </template>
          <template slot="emptystate">
            {{$t('no_new_lab_results')}}
          </template>
        </sub-section>
      </div>
    </template>
  </base-widget>
</template>

<i18n src="./_locales/common.json"></i18n>
<i18n src="./_locales/WidgetLabResults.json"></i18n>

<script lang="ts">
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-class';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import BaseWidget from '@/components/dashboard/widgets/_BaseWidget.vue';
import { DashboardSettingState, LabDonor, LabResult, WidgetSettings } from '@/store/dashboard/types';
import { TableConfig } from '@/types';
import SubSection from '@/components/shared/SubSection.vue';
import { urlParams } from "@/utils";
import TextInput from '@/components/shared/TextInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';

import { SaveResult } from '@/types';
import { Format } from "@/store/utilities/types";

@Component({
  components: {
    BaseWidget,
    SubSection,
    TextInput,
    CheckboxInput,
    SelectInput
  }
})
export default class WidgetLabResults extends mixins(DateUtilsMixin) {
  @Prop({ default: false }) requiredWidget!: boolean;

  @State(state => state.users.user.preferences) private preferences!: any;
  @State(state => state.pageState.currentPage.dashLabResults) private editState!: WidgetSettings;

  @Getter('getLabResults', { namespace: 'dashboard' }) getLabResults!: any;
  @Getter('getPreferences', { namespace: 'users'}) getPreferences!: any;
  @Getter('getPanelStyles', { namespace: 'dashboard' }) getPanelStyles!: any[];
  @Getter('getDefaultPaginationOptions', { namespace: 'utilities' }) getDefaultPaginationOptions!: any;

  public currentPage = 1;
  public perPage = 5;
  public errorMessage = null;

  private isWidgetLoaded = false;

  mounted(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _vm = this;
    setTimeout(() => {
      _vm.loadIfStillViewingDashboard();
    }, 5000);
  }

  /**
   * NOTE: we are relying on checking route 'name' to handle a special case for widget loading,
   * so we need to ensure this route name check matches dashboard route name in router.ts
   */
  loadIfStillViewingDashboard(): void {
    const viewingDashboard = this.$route.name === 'index';
    if (!viewingDashboard) return;

    Promise.all([
      this.loadData()
    ]).finally(() => {
      this.initializeWidget();
    });
  }

  // Setup temporary edit state for unsaved widget settings form fields
  initializeWidget(): void {
    this.errorMessage = null; // clear error message
    const preferences = this.getPreferences;

    this.$store.commit('pageState/set', {
      pageKey: 'dashLabResults',
      value: this.buildLabResultsEditState(preferences),
    });
    this.isWidgetLoaded = true;
  }

  public selectDonor(event: any) {
    if (event.row.donor_client_id) {
      const clientId: string = event.row.donor_client_id.toString();
      this.$router.push({
        name: 'edit-deceased-donor',
        params: {
          id: clientId,
        },
        hash: event.row.route_hash
      });
    } else {
      console.warn('Missing donor clientId');
      this.$router.push({name: 'list-deceased-donors'});
    }
  }

  public loadData(search='', sort='') {
    const search_params = [search, sort].filter((p) => { return p && p.length >= 0; });

    this.$store.dispatch(
      'dashboard/loadLabResults', {
      pageNumber: this.currentPage,
      pageSize: this.perPage,
      search_params: `${search_params.length > 0 ? '&' : ''}${search_params.join('&')}`
    });
  }

  // Commit edit state field model values to vue-x store
  saveSettings(): void {
    const newState = this.extractDashboardSettings(this.preferences, this.editState);
    this.$store.dispatch('users/savePreferences', { preferences: newState }).then((success: SaveResult) => {
      // If successful dismiss dialog
      this.dismiss();
      this.initializeWidget();
      // Reinitialize if overall dashboard settings change
      this.$emit('reloadDashboard');
    }).catch((error: any) => {
      // Show error notification
      this.errorMessage = error.message;
    });
  }

  public dismiss(): void {
    $("#id-lab-results").collapse('toggle');
  }

  // Build edit state based on overall dashboard settings
  buildLabResultsEditState(preferences: DashboardSettingState): WidgetSettings {
    return Object.assign({}, preferences.labResults);
  }

  // Build new overall dashboard settings with new settings for this specific widget
  extractDashboardSettings(currentSettings: DashboardSettingState, editState: WidgetSettings): DashboardSettingState {
    const newSettings = Object.assign({ labResults: {} }, currentSettings);
    newSettings.labResults = {
      style: editState.style,
      visible: editState.visible
    };
    return newSettings;
  }

  get labResultsData(): LabResult[] {
    if(!this.getLabResults) {
      return [];
    }

    return this.getLabResults.map((lab: LabDonor) => {
      return {
        deceased_donor_id: lab.deceased_donor_id || '',
        donor_client_id: lab.donor_client_id || '',
        result_type: lab.result_type ? this.$t(lab.result_type) : '',
        route_hash: this.routeHash(lab?.result_type),
        date_time_received: this.parseDisplayDateTimeUiFromDateTime(lab.date_time_received || '')
      };
    });
  }

  private routeHash(result_type: string|undefined): string {
    if(result_type === 'virology_donor') {
      return '#virology';
    }

    else if(result_type === 'hla_typing_donor'){
      return '#hla-typing';
    }

    return '';
  }

  /**
   * Gets configuration for the table
   *
   * @returns {TableConfig} Configuration for the table
   */
  get tableConfig(): TableConfig {
    return {
      data: this.labResultsData,
      columns: [
        { label: this.$t('donor_id'), field: 'deceased_donor_id', sortable: true },
        { label: this.$t('result_type'), field: 'result_type', sortable: true },
        { label: this.$t('date_time_received'), field: 'date_time_received', sortable: true, type: 'date', dateInputFormat: Format(this.getDateFormat).DATE_TIME_FORMAT, dateOutputFormat: Format(this.getDateFormat).DATE_TIME_FORMAT}
      ],
      sortOptions: {
        enabled: true,
        multipleColumns: true,
        initialSortBy: [{ field: 'deceased_donor_id', type: 'asc'}, { field: 'date_time_received', type: 'desc' }]
      },
      empty: this.$t('empty_widget_lab_results').toString(),
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPage: 5,
        mode: 'records',
        perPageDropdown: [5, 10, 25, 100],
        dropdownAllowAll: false,
        nextLabel: '',
        prevLabel: '',
        rowsPerPageLabel: this.$t('results_per_page').toString(),
      }
    };
  }
}
</script>

