<template>
  <div>
    <page-top>
      <router-link :to="{ name: 'list-recipients' }">
        {{$t('recipients')}}
      </router-link>
      /
      <router-link :to="{ name: 'edit-recipient', params: { id: recipientId} }">
        {{recipientDisplayName}}
      </router-link>
      / {{$t('hla_information')}}
    </page-top>
    <recipient-sticky-summary></recipient-sticky-summary>
    <div class="content-wrap">
      <div class="container-fluid">
        <div class="nav-wrapper">
          <side-nav-hla></side-nav-hla>
          <div class="page-content">
            <template>
              <validation-observer ref="validations" autocomplete="off" tag="form">
                <!-- Card Sections -->
                <recipient-summary />
                <hla-typing 
                  ref="hlaTyping"
                  @loaded="(ref) => loaded(ref)"
                  @save="(details) => handleSectionSave(details)"
                  @handleErrors="(errors) => handleErrors(errors)"
                  :canSave="checkAllowed('/recipients/:recipient_id/hla_typing', 'POST')"
                />
                <hla-antibodies 
                  ref="hlaAntibodies"
                  @loaded="(ref) => loaded(ref)"
                  @save="(details) => handleSectionSave(details)"
                  @saved="reloadRecipientDiagnosticsHla"
                  @clear="resetValidationErrors"
                  @handleErrors="(errors) => handleErrors(errors)"
                  :canSave="checkAllowed('/recipients/:recipient_id/hla_antibodies', 'POST')"
                />
                <!-- Serum Summary & Review and Stem Cell Therapy -->
                <hla-serum-summary-review
                  ref="hlaSerumSummaryReview"
                  @loaded="(ref) => loaded(ref)"
                  @save="handleSectionSave"
                  @handleErrors="handleErrors"
                />
                <!-- Virtual Crossmatch -->
                <hla-virtual-crossmatch
                  ref="hlaVirtualCrossmatch"
                  @loaded="(ref) => loaded(ref)"
                />
              </validation-observer>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n src="@/views/_locales/RecipientHla.json"></i18n>

<script lang="ts">
import { Getter, State } from 'vuex-class';
import { Recipient } from '@/store/recipients/types';
import PageTop from '@/components/shared/PageTop.vue';
import HlaTyping from '@/components/hla/HlaTyping.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mixins } from "vue-class-component";
import { ValidationUtilsMixin } from "@/mixins/validation-utils-mixin";
import { IdLookup } from '@/store/validations/types';
import HlaAntibodies from '@/components/hla/_HlaAntibodies.vue';
import HlaVirtualCrossmatch from '@/components/hla/_HlaVirtualCrossmatch.vue';
import HlaSerumSummaryReview from '@/components/hla/HlaSerumSummaryReview.vue';
import RecipientSummary from '@/components/recipients/RecipientSummary.vue';
import SideNavHla from "@/components/hla/side-nav/SideNavHla.vue";
import { SaveResult } from '@/types';
import RecipientStickySummary from '@/components/recipients/RecipientStickySummary.vue';

@Component({
  components: {
    PageTop,
    HlaTyping,
    SideNavHla,
    SaveToolbar,
    HlaAntibodies,
    HlaVirtualCrossmatch,
    HlaSerumSummaryReview,
    RecipientSummary,
    RecipientStickySummary,
  },
})
export default class RecipientHla extends mixins(ValidationUtilsMixin) {
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;

  // Getters
  @Getter('recipientDisplayName', { namespace: 'recipients' } ) private recipientDisplayName!: string;
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;

  private sectionsLoaded = new Set();
  private allSectionsLoaded = false;

  get isLoaded(): boolean {
    return this.allSectionsLoaded;
  }

  public loaded(ref: string): void {
    if (!ref) return;
    // Create a set of all the sections to load filtering out validations and the save button
    const sectionsToLoad = new Set(Object.keys(this.$refs).filter((ref: string) => !ref.match(/validations/)));
    // Add the ref we just loaded
    this.sectionsLoaded.add(ref);
    if (this.sectionsLoaded.size === sectionsToLoad.size) {
      this.$store.dispatch('utilities/scrollBehavior');
      this.allSectionsLoaded = true;
    }
  }
  
  /**
   * Gets the recipients client_id.
   *
   * Converts the recipients client_id to a string
   *
   * @returns {String} organ as lowercase string or empty string
   */
  get recipientId(): string {
    return String(this.recipient.client_id);
  }

  // run scrollbehaviour on child component being fully loaded

  // Vue lifecycle hooks
  private mounted(): void {
    this.$store.commit('setPageTitle', `${this.$t('recipients')} / ${this.recipientDisplayName} / ${this.$t('hla_information')}`);
  }

  /**
   * Reload recipient diagnostics HLA information
   *
   * @listens hla-antibodies#saved
   */
  private reloadRecipientDiagnosticsHla(): void {
    // Reload recipient document to fetch updated HLA diagnostics e.g. cumulative antibodies
    const recipientId = this.recipient.client_id;
    this.$store.dispatch('recipients/get', recipientId);
  }

  // Parse and highlight errors from api response
  private handleErrors(errors: SaveResult[]|SaveResult): void {
    const idLookup: IdLookup = {
      ...(this.$refs.hlaTyping as HlaTyping).idLookup(),
      ...(this.$refs.hlaAntibodies as HlaAntibodies).idLookup(),
      ...(this.$refs.hlaSerumSummaryReview as HlaSerumSummaryReview).idLookup(),
    };

    // Derive errors for UI input fields based on API error results
    const formErrors = this.parseFormErrors(errors, idLookup);

    (this.$refs.validations as any).setErrors(formErrors);
  }

  // Tell the top-level form validation observer to reset all errors
  private resetValidationErrors() {
    (this.$refs.validations as any).reset();
  }

  // Handle save events generated by descendent components
  private handleSectionSave(sectionSaved: string): void {
    this.resetValidationErrors();
  }
}
</script>
