<template>
  <card-section
    section-id="lab-section-living-donor"
    :isAccordion="prototypeFeatureEnabled('proto_ability_to_expand_collapse')"
  >
    <template v-slot:header>
      {{ title }}
    </template>
    <template v-slot:body>
      <div data-v-b078911c="">
        <fieldset id="labs">
          <legend style="margin-bottom: 16px;">
            <h4 class="legend-title"><span>Laboratory results</span></h4>
          </legend>
        </fieldset>
      </div>
      <div class="row">
        <div class="standard-form-group">             
          <date-input
            inputId="startDate"
            name="Start Date"
            v-model="startDate"
            @input="moveScroll"
          />
        </div>
        <div class="standard-form-group">             
          <date-input
            inputId="endDate"
            name="End Date (Optional)"
            v-model="endDate"
          />
        </div>
        <div  class="mb-0 ml-auto mr-2 align-self-end">
          <button type="button" class="btn btn-success btn-sm btn-wide">Add Result</button>
        </div>
        <div class="form-group mb-0 mr-2 align-self-end">
          <button type="button" class="btn btn-primary btn-sm btn-wide">Left</button>
        </div>
        <div class="form-group mb-0 align-self-end">
          <button type="button" class="btn btn-primary btn-sm btn-wide">Right</button>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 mb-4">
          <div id="wrapper1" class="mt-4">
            <div id="fake-div"></div>
          </div>         
          <scroll-shadow>
            <div id="wrapper2">
              
              <div class="table-wrapper">
  
                <table class="table table-sm table-bordered scroll-table">
                  <tbody id="first">
                    <tr style="background: rgba( 33, 37, 41, 0.04);">
                      <th scope="column" class="th-row-heading th-row">Date</th>
                      <template v-for="item in tableData.date_row">
                        <td :class="item.sample_id" :id="item.sample_id" :key="item.sample_id">{{item.sample_date}}</td>
                      </template>
                    </tr>
                
                    <tr style="background: rgba( 33, 37, 41, 0.04);">
                      <th scope="row" class="th-row-heading th-row">Time</th>
                      <template v-for="item in tableData.date_row">
                        <td :class="item.sample_id" :key="item.sample_id">{{item.sample_time}}</td>
                      </template>
                    </tr>

                    <template v-for="group in tableData.test_groups">
                      <tr style="text-align: center;" class="accordion" :key="group.group_name">
                        <th scope="row" class="th-row-heading th-row" id="table-sidebar" @click="closeSection(group)" >
                          <font-awesome-icon :icon="['fas', (group.active ? 'chevron-down' : 'chevron-right')]" class="nav-caret" fixed-width  />  
                          <span>{{ group.group_name}}</span>
                        </th>
                        <td :colspan="tableData.date_row.length">&nbsp;</td>
                      </tr>
                      <tbody class="table-section" :class="{ show: group.active }" :key="group.group_code">
                        <template v-for="(row, index) in group.test_rows">
                          <tr style="text-align: center;" :key="index">
                            <th class="th-row" scope="row">{{row.test_name}}</th>
                            <template v-for="(result, index) in row.test_results">
                              <td :class="result.sample_id"  :key="index">{{result.sample_result}}</td>
                            </template>
                          </tr>
                        </template>
                      </tbody>
                    </template>

                  </tbody>
                </table>
    
              </div>
                          
            </div>
          </scroll-shadow>
    
        </div>
      </div>
      
    </template>
  </card-section>
</template>




<script lang="ts">
import { Getter, State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import CardSection from "@/components/shared/CardSection.vue";
import DateInput from '@/components/shared/DateInput.vue';
import tablejson from './json/LivingDonorLabsjson.json';
import 'scroll-shadow-element';

@Component({
  components: {
    CardSection,
    DateInput
  }
})
export default class LabSection extends Vue {  
  title = 'Labs';
  startDate = '';
  endDate= '';
  tableData = tablejson;
  originalData = tablejson;
  offsetWidth = 0;
  displayJson = {};

  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;

  mounted() {
    this.resizeScrollBar();
    this.displayJson = tablejson;
  }

  closeSection(item: any) {
    let newJson = tablejson;
    for (const [key, value] of Object.entries( newJson.test_groups)) {
      if (value.group_code == item.group_code) {
        value.active = !value.active;
      }
    }
  }

  resizeScrollBar() {
    let wrapper1 = document.getElementById('wrapper1');
    let wrapper2 = document.getElementById('wrapper2');
    let first = document.getElementById('first');
    let collection = document.getElementsByClassName("th-row");
    let inputList = Array.prototype.slice.call(collection);
  
    this.offsetWidth = first!.offsetWidth;
    let fakediv = document.getElementById('fake-div');
 
    fakediv!.style.width = this.offsetWidth + 'px';
      
    wrapper1!.onscroll = function() {
      wrapper2!.scrollLeft = wrapper1!.scrollLeft;  
      if (wrapper2!.scrollLeft > 5) {

        inputList.map((col,i) => {
          col.classList.add("addBoxShadow");
        });

      } else {
        inputList.map((col,i) => {
          col.classList.remove("addBoxShadow");
        });
          }
    };

    wrapper2!.onscroll = function() {
        wrapper1!.scrollLeft = wrapper2!.scrollLeft;  
    };
    
  }

  replaceDashes(value : any) {
    return value.sample_date.replace(/\//g, "-");
  }

  
  private moveScroll(): void {
    let toDateID = '';
    let selectedDate = new Date(this.startDate).setUTCHours(0,0,0,0);
    for (const [key, value] of Object.entries(this.tableData.date_row)) {
      if ( new Date(this.replaceDashes(value)).setUTCHours(0,0,0,0) >= selectedDate && toDateID == '') {
        toDateID = value.sample_id;    
      } 
    }

    if (toDateID == '') {
      
      let first = Object.entries(tablejson.date_row)[0][1];
      let last = Object.entries(tablejson.date_row)[Object.entries(tablejson.date_row).length - 1] [1];
      
      if (selectedDate > new Date(last.sample_date).setUTCHours(0,0,0,0) ) {
        toDateID = last.sample_id;
      } else {
        toDateID = first.sample_id;
      }
    }
    
    let pos = $('#' + toDateID);

    let offset = pos.offset();
    let wrapper2 = document.getElementById('wrapper2');
    
    let sidebar = (document.querySelector('.menu-header-nav') as HTMLElement).offsetWidth;

    if (offset && wrapper2 && sidebar) {
      wrapper2.scrollLeft +=  offset.left - sidebar - 305 - 100;

      toDateID = '';
    } 
  
     
  }
  
}
</script>

<style>
 /* For the "lab panels" table */

.scroll-table {

  font-size: .875rem;
  margin-bottom: 0 !important;
}
/* .scroll-table tr th {
  background-color: #f5f5f5 !important;
} */


.accordion.closed svg {
    transform: rotate(-90deg);
  }


.scroll-table tr td:not(:first-child) {
  text-align: center;
}
.table-bordered th:not(.has-style), .table-bordered th:not(.vgt-small-column), .vue-good-table-bordered th:not(.has-style), .vue-good-table-bordered th:not(.vgt-small-column) {
  font-size: .875rem !important;
}

.scroll-table th, .scroll-table td {
  border-bottom: none !important;
}
.scroll-table  td {
  border-right: none !important;
  text-align: center;
  min-width: 20px;
}
.scroll-table td:last-child {
  border-right: 1px solid #dee2e6 !important;
}
.scroll-table  tr:last-child th, .scroll-table  tr:last-child td{
  border-bottom: 1px solid #dee2e6 !important;
}
.btn-xs {
  padding: 0 .5rem;
  font-size: .7rem;
  letter-spacing: 0.02rem;
}
.scroll-table th {
  text-indent: 1.5rem;
}
.scroll-table th.th-row-heading {
  text-indent: 0;

}
.scroll-table th {
  font-weight: 600 !important;
  background: rgb(245, 245, 245);
  border-left: none !important;
  align-items: center;
}

.scroll-table th.th-row-heading {
  text-wrap: nowrap;
  font-weight: 700 !important;

}
.scroll-table th.th-row-heading span {
  padding-left: .5rem;
}


#wrapper1 {height: 20px; }

.fake-div {
width: 100%;
  height: 20px;
}

#wrapper1, #wrapper2{ 
overflow-x: auto; overflow-y:hidden;}
#wrapper1{height: 20px; }


.historical-transplants ul {
  padding-left:0
}
.list-inline-item:not(:last-child) {
  margin-right:.5rem!important
}
.historical-transplants ul li:after {
  padding-left:1rem;
  font-weight:700;
  content:"\2215";
  margin-right:.25rem!important
}
.historical-transplants ul li:last-child:after {
  content:""
}
.scroll-table table tbody {
  border-top: none !important;
}

.table-section {
  display: none;
}

.table-section.show {
  display: contents;
}

.vgt-right-align {
  text-align: right !important;
}


scroll-shadow {
  overflow: auto;
  width: 100%;
	display: inline-block;
	--scroll-shadow-size: 14;
	--scroll-shadow-left: linear-gradient(to left, transparent 0%, #0003 100%);
	--scroll-shadow-right:  linear-gradient(to right, transparent 0%, #0003 100%);
}

@media (max-width: 1500px) { 
  .scroll-table.table-bordered th:not(.vgt-small-column){
    min-width: 383px !important;
  }

}

</style>

