import { render, staticRenderFns } from "./LungSpecificDetails.vue?vue&type=template&id=26da6868"
import script from "./LungSpecificDetails.vue?vue&type=script&lang=ts"
export * from "./LungSpecificDetails.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/components/_locales/Organs.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Forgans%2Flung%2FLungSpecificDetails.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/components/organs/lung/_locales/LungSpecificDetails.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Forgans%2Flung%2FLungSpecificDetails.vue&external"
if (typeof block1 === 'function') block1(component)

export default component.exports