
<template>
  <card-section
    section-id="recipient_documents"
    :isAccordion="prototypeFeatureEnabled('proto_ability_to_expand_collapse')"
  >
    <template v-slot:header>
      {{ title }}
    </template>
    <template v-slot:body>
      <div class="row">
        <div class="col-sm-12">
          <sub-section
          :title='$t("uploaded_documents")'
          sub-section-id="recipients-uploaded-files"
        >
          <template v-slot:contents>
          <div class="row">
            <!-- Uploaded Files -->
            <div class="col-sm-12">
              <div class="table-responsive-md">
                <div>
                  <vue-good-table
                    id='donorDocuments-uploadedFiles-table'
                    aria-labelledby='donorDocuments-uploadedFiles-label'
                    style-class='vgt-table table table-bordered table-hover'
                    :columns="tableConfig.columns"
                    :rows="tableConfig.data"
                    :sortOptions="tableConfig.sortOptions"
                    :pagination-options="tableConfig.paginationOptions"
                    @on-row-click="editSelected"
                  >
                    <template v-slot:table-actions>
                      <button
                        @click="clearForm()"
                        type="button"
                        class="btn btn-primary btn-sm">
                        {{ tableConfig.createText || 'Create Row' }}
                      </button>
                    </template>
                    <template slot="table-row" slot-scope="props">

                      
                      <template v-if="props.column.field == 'fileName'">
                        <!-- Download one document / File can only be downloaded if there is uuid value -->
                        <template>
                         {{props.row.fileName || '-'}}
                        </template>
                      </template>
                    
                      <template>
                        {{props.formattedRow[props.column.field] || '-' }}
                      </template>
                    </template>
                    <template slot="emptystate">
                      <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
                      {{ emptyMessage }}
                    </template>
                  </vue-good-table>
                  <p v-if="isTableUpdating"> {{ $t('loading') }} <font-awesome-icon class="fa-1x fa-spin" :icon="['far', 'spinner-third']" /></p>
                </div>
              </div>
            </div>
          </div>

          </template>

        </sub-section>
        <sub-section
          ref="uploadDocument"
          sub-section-id="recipient-upload-document"
          saveButton="true"
          :save-button-text="editstate ? `${$t('save_description')}` : `${$t('save_document')}` "
          @save="uploadDocument()"
        >
          <template v-slot:contents>
            <fieldset>
              <legend>
                <h5 v-if="!editstate" class="legend-title">
                  New Document
                </h5>
                <h5 v-else class="legend-title">
                  Document Details
                </h5>
              </legend>
              <div class="row">
                <!-- Clinical Attachments -->
                <div class="standard-form-group">
                  <label for="recipientAttachment-clinicalAttachment">{{$t('clinical_attachment')}} <i>*</i></label>
                  <input
                    input-id="recipientAttachment-clinicalAttachment"
                    ref="fileUploader"
                    type="file"
                    class="form-control"
                    :disabled="id"
                    @change="onClinicalAttachmentsChanged($event)"
                    />
                </div>
                <div class="standard-form-group">
                  <select-input
                    select-id="recipientAttachment-category"
                    rules="required"
                    :name='$t("category")'
                    :options="categoryOptions"
                    v-model="category"
                  >
                  </select-input>
                </div>
              </div>
              <div class="row">
                <div class="standard-full-width-group">
                  <text-input
                    :name='$t("fileName")'
                    input-id="recipientAttachment-fileName"
                    rules="required"
                    v-model="fileName"
                  />
                </div>
                <div class="row-break d-none d-xl-block"></div>
                <div class="standard-full-width-group">
                  <text-area-input
                    input-id="recipientAttachment-description"
                    :name='$t("description")'
                    rules="required"
                    rows="4"
                    v-model="description">
                  </text-area-input>
                </div>
              </div>
             
            </fieldset>
          </template>

        </sub-section>

        </div>
      </div>
      
    </template>
  </card-section>
</template>

<i18n src="./_locales/RecipientDocuments.json"></i18n>

<script lang="ts">
  import { Getter, State } from 'vuex-class';
  import { mixins } from "vue-class-component";
  import { Component, Vue } from 'vue-property-decorator';
  import CardSection from "@/components/shared/CardSection.vue";
  import SaveToolbar from '@/components/shared/SaveToolbar.vue';  
  import { VueGoodTable } from 'vue-good-table';
  import SelectInput from '@/components/shared/SelectInput.vue';
  import CheckboxInput from '@/components/shared/CheckboxInput.vue';
  import TextInput from '@/components/shared/TextInput.vue';
  import TextAreaInput from '@/components/shared/TextAreaInput.vue';
  import SubSection from '@/components/shared/SubSection.vue';
  import { TableConfig } from '@/types';
  import { Recipient } from "@/store/recipients/types";
  import { UserDetails } from '@/store/userAccounts/types';
  import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
  
    
    interface TableRow {
    id: string;
    category?: string;

    date?: string;
    fileName?: string;

    fileType?: string;
    description?: string;
    uploadedBy?: string;

  }

@Component({
  components: {
    CardSection,
    SaveToolbar,
    SubSection,
    SelectInput,
    TextInput,
    CheckboxInput,
    TextAreaInput,
    VueGoodTable
  }
})
export default class RecipientDocuments extends mixins(DateUtilsMixin) {  

  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.userAccounts.selected) private selected!: UserDetails;
  @Getter('getUser', { namespace: 'users' }) private getUser!: any;
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;


  title = 'Patient Documents';
  private id = null;
  private category = '';
  private description = '';
  private fileName = '';
  private isTableUpdating = false;
  private taskInProgress = false;
  public perPage = 10;
  public currentPageTable = 1;
  private attachments: any = [];
  private clinicalAttachments = [];
  private editstate = false

  private categoryOptions = [{code: 0, value: 'Referral'}, {code: 1, value: 'Result'},{code: 2, value: 'Order'},{code: 3, value: 'Letter'}];

  get tableRows() {
    const attachments = this.attachments || [];
    return attachments.map((item: any) => {

      return {
        id: item.id,
        date: this.formatDateObject(item.date),
        category: item.category,
        categoryValue : this.getCategoryValue(item.category),
        description : item.description,
        fileName : item.fileName,
        fileType: item.fileType,
        uploadedBy: item.uploadedBy
      };
    });
  }
  get tableConfig(): TableConfig {
  const tableConfig = [
    { label: `${this.$t('attachment_date')}`,
      field: 'date',
      width: '12%',
      sortable: true
    },
    { label: `${this.$t('fileName')}`,
      field: 'fileName',
      sortable: true
    },
    { 
      field: 'category',
      hidden: 'true'
    },
    { label: `${this.$t('category')}`,
      field: 'categoryValue',
      width: '10%',
      sortable: true
    },
    { label: `${this.$t('fileType')}`,
      field: 'fileType',
      width: '10%',
      sortable: true
    },
    { label: `${this.$t('description')}`,
      field: 'description',
      sortable: true
    },
    { label: `${this.$t('uploadedBy')}`,
      field: 'uploadedBy',
      width: '10%',
      sortable: true
    }
  ];

  return {
    data: this.tableRows,
    columns: tableConfig,
    createButton: true,
    createText: `${this.$t('attach_new_document')}`,
    sortOptions: {
      enabled: true,
      initialSortBy: {field: 'date', type: 'desc'}
    },
    paginationOptions: {
    enabled: true,
    mode: 'records',
    perPage: this.perPage,
    perPageDropdown: [10, 25, 50],
    setCurrentPage: this.currentPageTable,
    dropdownAllowAll: true,
    nextLabel: '',
    prevLabel: '',
    rowsPerPageLabel: this.$t('table.results'),
    },
  };
  }

    private editSelected(row :any) {
      if (row) {
        this.editstate = true;
        this.id = row.row.id;
        this.category = row.row.category;
        this.description = row.row.description;
        this.fileName = row.row.fileName;
      }
    }

  get emptyMessage(): string {
    if (!this.attachments) {
      return this.$t('loading').toString();
    } else {
      return this.$t('use_form_below').toString();
    }
  }

  private onClinicalAttachmentsChanged(event: any) {
    if (!this.id && event.target) {{
      this.clinicalAttachments = event.target.files;

      if (this.clinicalAttachments && this.clinicalAttachments[0] && this.clinicalAttachments[0]['name'] ) {
        Vue.set(this, 'fileName', this.clinicalAttachments[0]['name']);
      }
    }}
  }

  public resetSaveToolbar(): void {
    // Refer to the save toolbar that handles this page
    const saveToolbar = (this.$refs.uploadDocument as SubSection).$refs['save-recipient-upload-document'] as SaveToolbar;
    saveToolbar.reset();
  }


  get getUserFullName(): string|undefined {
    let user = this.getUser;

    return user.name;
  }

  public getCategoryValue(item: any) {
    return this.categoryOptions[item as any].value;
  }

  public clearForm(): any {
    Vue.set(this, 'clinicalAttachments', []);
    this.id = null;
    this.category = '';
    this.description = '';
    this.fileName = '';
    this.$emit('clear');
    this.resetSaveToolbar();
    this.editstate = false;
  }

   private mounted(): void {
    let attachments = localStorage.getItem("recipientDocumentAttachment");

    if (attachments) {
      this.attachments = JSON.parse(attachments);
    }
  
  }
  

  public uploadDocument(): any {
  
    this.isTableUpdating = true;
    let attachments = this.attachments || [];

    if (!this.editstate) {
      const payload: any = {
        id: Math.random().toString(16).slice(2),
        date: Date.now(),
        fileList: this.clinicalAttachments,
        fileName: this.fileName,
        fileType: this.clinicalAttachments[0]['type'],
        uploadedBy : this.getUserFullName,
        description: this.description || '',
        category: this.category
      };

      attachments.push( payload );
    } else {
      attachments.map((item: any) => {
        if (item.id === this.id) {
          item.fileName = this.fileName;
          item.description = this.description;
          item.category = this.category;
        }
      });

    }

    this.attachments = attachments;
    localStorage.setItem("recipientDocumentAttachment", JSON.stringify(attachments));

    this.isTableUpdating = false;
    this.clearForm();
  }
}
</script>

<style>


</style>
