<template>
  <card-section
    ref="donorAcceptability"
    :key="journeyId"
    section-id="donor-acceptability-section"
    :saveButtonText="$t('save_button')"
    :lookupsToLoad="[]"
    @loaded="loaded()"
    :saveButton="canEdit"
    :disabled="!canSave || newJourney"
     :isAccordion="prototypeFeatureEnabled('proto_ability_to_expand_collapse')"
    @save="savePatch()"
  >
    <template v-slot:header>
      {{$t('donor_accep_criteria')}}
    </template>
    <template v-slot:body>
      <template v-if="!editState">
        {{ $t('loading') }}
      </template>
      <template v-else>
        <sub-section :title="$t('donor_accep_criteria')" sub-section-id="dac-donation-constraints">
          <template v-slot:contents>
            <fieldset :disabled="!canSave || newJourney">
              <div class="row">
                <div class="standard-form-group" v-if="showDonorType">
                  <select-input
                    :name="$t('donor_type')"
                    select-id="donorTypeCode"
                    v-model="donorTypeCode"
                    :options="donorTypesLookup"
                    @change="donorTypeUpdate"
                    :ruleKey="['donor_acceptability.deceased_donor', 'donor_acceptability.living_donor']"
                    :rules="{ required: isRequired(['deceased_donor', 'living_donor']) }"
                  />
                </div>
                <div class="standard-form-group" v-for="(display_in_ui,criteria) in displayDonorCriteria" v-bind:key="criteria">
                  <boolean-radio-input
                    v-if="showEcdforAdultOnly(`${criteria}`) && display_in_ui == true"
                    :labelName="$t(`${criteria}`)"
                    :input-id="`dac-${criteria}`"
                    v-model="editState.acceptabilityCriteria[criteria]"
                    :ruleKey="`donor_acceptability.${criteria}`"
                    :rules="{ required: isRequired(`${criteria}`) }"
                    @change="hcvUpdate(`${criteria}`)"
                  />
                </div>
              </div>
        
              <div class="d-flex measureUnits">
                <div class="inner col-sm-12">
                  <p class="feature-title">{{ $t('min_requirements') }}</p>
                  <br/>
                  <div class="row d-flex">   
                    <div class="measureItem measurements-form-group">
                      <p>{{ $t('metric') }}</p>
                      <div class="form-group">
                        <number-input
                          input-id="dac-minimum-weight-kg"
                          :name="$t('min_donor_weight')"
                          :append="true"
                          append-text="kg"
                          step="0.1"
                          :calculated="true"
                          @change="updateWeight($event)"
                          v-model="editState.acceptabilityCriteria.minWeight.weightKg"
                          ruleKey="donor_acceptability.min_weight"
                          :warningParams="{ min: 1, max: 225, message: $t('min_weight_warning') }"
                        />
                      </div>
                      <div class="form-group">
                        <number-input
                          input-id="dac-minimum-height-cm"
                          :name="$t('min_donor_height')"
                          :append="true"
                          append-text="cm"
                          step="0.1"
                          :calculated="true"
                          @change="updateHeight($event)"
                          v-model="editState.acceptabilityCriteria.minHeight.heightCm"
                          ruleKey="donor_acceptability.min_height"
                          :warningParams="{ min: 30, max: 215, message: $t('min_height_warning') }"
                        />
                      </div>
                    </div>
                    <div class="measureItem measurements-form-group">      
                      <p>{{ $t('imperial') }}</p>
                      <div class="form-group">
                        <number-input
                          inputId="dac-minimum-weight-lb"
                          :name="$t('min_weight_lb')"
                          :append="true"
                          append-text="lb"
                          step="0.1"
                          :calculated="true"
                          @change="updateWeight($event)"
                          v-model="editState.acceptabilityCriteria.minWeight.weightLb"
                          rules="float"
                        />
                      </div>
                      <div class="form-group">
                        <number-input
                          input-id="dac-minimum-height-in"
                          :name="$t('min_height_in')"
                          :append="true"
                          append-text="in"
                          step="0.1"
                          :calculated="true"
                          @change="updateHeight($event)"
                          v-model="editState.acceptabilityCriteria.minHeight.heightIn"
                          rules="float"
                        />
                      </div>
                    </div> 
                    <div class="measureItem measurements-form-group">
                      <p class="spacer d-none d-xl-block"></p>   
                      <div class="form-group">
                       <number-input
                        input-id="dac-min-age"
                        :name="$t('min_age')"
                        step="1"
                        v-model="editState.acceptabilityCriteria.minAge"
                        ruleKey="donor_acceptability.min_age"
                      />
                      </div>
                    </div>    
                  </div>
                </div>
              </div>
              <div class="d-flex measureUnits">
                <div class="inner col-sm-12">
                  <p class="feature-title">{{ $t('max_requirements') }}</p>
                  <div class="row d-flex">   
                    <div class="measureItem measurements-form-group">
                      <p>{{ $t('metric') }}</p>
                      <div class="form-group">
                        <number-input
                          input-id="dac-maximum-weight-kg"
                          :name="$t('max_donor_weight')"
                          :append="true"
                          append-text="kg"
                          step="0.1"
                          :calculated="true"
                          @change="updateWeight($event)"
                          v-model="editState.acceptabilityCriteria.maxWeight.weightKg"
                          ruleKey="donor_acceptability.max_weight"
                          :warningParams="{ min: 1, max: 225, message: $t('max_weight_warning') }"
                        />
                      </div>
                      <div class="form-group">
                        <number-input
                          input-id="dac-maximum-height-cm"
                          :name="$t('max_donor_height')"
                          :append="true"
                          append-text="cm"
                          step="0.1"
                          :calculated="true"
                          @change="updateHeight($event)"
                          v-model="editState.acceptabilityCriteria.maxHeight.heightCm"
                          ruleKey="donor_acceptability.max_height"
                          :warningParams="{ min: 30, max: 215, message: $t('max_height_warning') }"
                        />
                      </div>
                    </div>
                    <div class="measureItem measurements-form-group">      
                      <p>{{ $t('imperial') }}</p>
                      <div class="form-group">
                        <number-input
                          input-id="dac-maximum-weight-lb"
                          :name="$t('max_weight_lb')"
                          :append="true"
                          append-text="lb"
                          step="0.1"
                          :calculated="true"
                          @change="updateWeight($event)"
                          v-model="editState.acceptabilityCriteria.maxWeight.weightLb"
                          rules="float"
                        />
                      </div>
                      <div class="form-group">
                        <number-input
                          input-id="dac-maximum-height-in"
                          :name="$t('max_height_in')"
                          :append="true"
                          append-text="in"
                          step="0.1"
                          :calculated="true"
                          @change="updateHeight($event)"
                          v-model="editState.acceptabilityCriteria.maxHeight.heightIn"
                          rules="float"
                        />
                      </div>
                    </div>  
                    <div class="measureItem measurements-form-group">
                      <p class="spacer d-none d-xl-block"></p>   
                      <div class="form-group">
                        <number-input
                          input-id="dac-max-age"
                          :name="$t('max_age')"
                          step="1"
                          v-model="editState.acceptabilityCriteria.maxAge"
                          ruleKey="donor_acceptability.max_age"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row d-flex">
                <div class="measurements-comment-group">
                  <text-area-input
                    inputId="dac-comments"
                    :name="$t('comments')"
                    v-model="editState.acceptabilityCriteria.comments"
                    validationId="comments"
                    ruleKey="donor_acceptability.comments"
                  />
                </div>
              </div>
         
            </fieldset>

            <modal-section 
              modalId="hcv-update-modal" 
              ref="hcvUpdateModal"
              class="modal-sticky-header"
              size="md"
              :wide="false"
              :centered="true"
              :closeButton="false"
            >
              <template v-slot:title>
                <template v-if="isClustered">{{ $t('hcv_ab_change_cluster_confirm') }}</template>
                <template v-else>{{ $t('hcv_ab_change_confirm') }}</template>
              </template>
              <template v-slot:body>
                <div class="form-group col-sm text-center">
                  <boolean-radio-input
                    :acceptLabel="$t('accept')"
                    :declineLabel="$t('decline')"
                    input-id="dac-hcv-update"
                    v-on:click.stop.prevent="updateHcvAb($event.target.id)"
                  />
                </div>
              </template>
            </modal-section>

          </template>
        </sub-section>
      </template>
    </template>
  </card-section>
</template>

<i18n src="./_locales/common.json"></i18n>
<i18n src="./_locales/DonorAcceptability.json"></i18n>

<script lang="ts">
import {organCodeLookup, SaveProvider, SaveResult} from '@/types';
import {Getter, State} from 'vuex-class';
import {Recipient} from '@/store/recipients/types';
import TextInput from '@/components/shared/TextInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import {Component, Prop, Vue} from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import CardSection from '@/components/shared/CardSection.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import NumberInput from '@/components/shared/NumberInput.vue';
import ModalSection from '@/components/shared/ModalSection.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import { AllDonorTypes, BloodTypeValue, OrganCodeValue } from '@/store/lookups/types';
import BooleanRadioInput from '@/components/shared/BooleanRadioInput.vue';
import {RecipientDonorAcceptability, RecipientJourney} from '@/store/recipientJourney/types';
import { DonorAcceptabilityCriteria } from '@/store/hospitals/types';

interface DonorAcceptabilityForm {
  acceptabilityCriteria: {
    a2_or_a2b_donor?: boolean|null;
    abo_incompatible?: boolean|null;
    hcv_ab_positive?: boolean|null;
    hcv_nat_positive?: boolean|null;
    hep_ab_core_positive?: boolean|null;
    ecd_donor?: boolean|null;
    minWeight: DonorAcceptabilityFormWeight;
    maxWeight: DonorAcceptabilityFormWeight;
    minHeight: DonorAcceptabilityFormHeight;
    maxHeight: DonorAcceptabilityFormHeight;
    comments?: string;
    minAge?: number;
    maxAge?: number;
    deceased_donor?: boolean|null;
    living_donor?: boolean|null;
  };
}

interface DonorAcceptabilityFormWeight {
  weightKg?: number;
  weightLb?: number;
}

interface DonorAcceptabilityFormHeight {
  heightCm?: number;
  heightIn?: number;
}

@Component({
  components: {
    TextInput,
    SubSection,
    CardSection,
    SelectInput,
    NumberInput,
    ModalSection,
    TextAreaInput,
    BooleanRadioInput,
  }
})
export default class DonorAcceptability extends Vue {
  // State
  @State(state => state.pageState.currentPage.donorAcceptability) editState!: DonorAcceptabilityForm;
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;

  // Properties
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) clientId!: string;
  @Getter('allDonorTypes', { namespace: 'lookups' }) donorTypes!: any;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('isClustered', { namespace: 'journeyState', }) isClustered!: boolean;
  @Getter('isPediatricKidney', { namespace: 'journeyState' }) isPediatric!: boolean;
  @Getter('getDefaultDonorCriteria', { namespace: 'hospitals' }) defaultDonorCriteria!: (hospitalId: string|undefined, organCode: string) => RecipientDonorAcceptability|undefined;

  private donorType: any = {};
  private donorTypeCode: string|null = null;

  private ORGAN_CODES_TO_INCLUDE = [ OrganCodeValue.Liver, OrganCodeValue.Kidney, OrganCodeValue.Lung ];
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;

 
 /**
   * Return true if donor acceptability section can be edited
   * 
   * cannot be edited if new journey
   * cannot be edited if journey is completed
   *
   * @returns {boolean} true if we can edit
   */
  get canEdit(): boolean{
    if (this.newJourney || this.journey.completed) {
      return false;
    }
    return true;
  }

  /**
   * Return organ code as a number or false if none
   *
   * @returns {number|false} organ code or false if none
   */
  get organCode(): number|false {
    if (this.newJourney) {
      return Number(this.$route.params.organ_code);
    }
    return this.journey.organ_code ? this.journey.organ_code : false;
  }
  
  /**
   * Return if the new/existing organ is a Kidney
   *
   * @returns {boolean} true if the organ is a Kidney
   */
  get isKidney(): boolean {
    return this.organCode === OrganCodeValue.Kidney;
  }

  /**
   * Return criteria for displaying in ui
   *
   * @returns {DisplayCriteria} 
   */
  get displayDonorCriteria(): DonorAcceptabilityCriteria {
    if (this.newJourney) return {
      a2_or_a2b_donor: true,
      abo_incompatible: true,
      hcv_nat_positive: true,
      hcv_ab_positive: true,
      hep_ab_core_positive: true,
      ecd_donor:  true,
    };

    const defaultDonorCriteria = this.organCode ? this.defaultDonorCriteria(this.journey.transplant_program?.transplant_hospital_id?.$oid, this.organCode.toString()) : undefined;
    return {
      a2_or_a2b_donor:  defaultDonorCriteria?.display_in_ui?.a2_or_a2b_donor,
      abo_incompatible: defaultDonorCriteria?.display_in_ui?.abo_incompatible,
      hcv_nat_positive: defaultDonorCriteria?.display_in_ui?.hcv_nat_positive,
      hcv_ab_positive: defaultDonorCriteria?.display_in_ui?.hcv_ab_positive,
      hep_ab_core_positive: defaultDonorCriteria?.display_in_ui?.hep_ab_core_positive,
      ecd_donor:  defaultDonorCriteria?.display_in_ui?.ecd_donor,
    };
  }

  /**
   * Check whether pediatric
   *
   * @returns {boolean} true if not ecd and not pediatric
   */
  get showEcdforAdultOnly() {
    return(criteria: string): boolean => {
      if(criteria == 'ecd_donor' && this.isPediatric) return false;
      return true;
    };
  }

 /**
   * Return if the new/existing organ is a Kidney|PancreasWhole|PancreasIslets
   *
   * @returns {boolean} true if the organ is a Kidney|PancreasWhole|PancreasIslets
   */
  get showAcceptHepB(): boolean {
    const allowed_organs = [ OrganCodeValue.Kidney, OrganCodeValue.PancreasWhole, OrganCodeValue.PancreasIslets ];
    return !this.organCode ? false : allowed_organs.includes(this.organCode);
  }
  /**
   *  As analogy with
   *  API module Concern::Recipient::Journey::DonorAcceptability
   *  on_waitlist -> !self.journey&.waitlist_factors&.listing_date.nil?
   */
  get requireDonorType(): boolean {
    return (this.journey?.stage_attributes?.waitlist?.factors?.listing_date || null) !== null;
  }

  // Presented only for Kidney=3 and Liver=1 journeys
  get showDonorType(): boolean {
    return !this.organCode ? false : this.ORGAN_CODES_TO_INCLUDE.includes(this.organCode);
  }
  
  /**
   * Gets a string representation of the organ using the organ code.
   *
   * Using the organ code from the journey against a lookup table
   * this will return a lowercase string of the organ.
   *
   * @returns {string} organ as lowercase string or empty string
   */
  get organComponent(): string {
    if (!this.journey) {
      const organCode: string = this.$route.params.organ_code;
      return organCode === null ? "" : organCodeLookup[organCode];
    } else {
      return this.journey.organ_code === null ? "" : organCodeLookup[this.journey.organ_code!.toString()];
    }
  }

  /**
   * Gets a AllDonorTypes representation the types of donors
   *
   * @returns {AllDonorTypes}
   */
  get donorTypesLookup(): AllDonorTypes {
    return this.donorTypes;
  }

  /**
   * Determines whether the field is required based on the passed API key param
   *
   * @param key the api key
   * @returns {boolean} true if field required
   */
  public isRequired(keys: string|Array<string>): boolean {
    const prefix = 'addToWaitlist.journey.donor_acceptability.';
    // append the prefix key 
    if (typeof keys == 'string') {
      keys = [keys];
    }

    if (keys) {
      for (const key in keys) {
        const fullKey = prefix + key;

        // return required true if on waitlist and key is living_donor or deceased_donor
        if (this.requireDonorType && (key == 'living_donor' || key == 'deceased_donor')) {
          return true;
        }

        // return required true if on waitlist and key is part of the fields that are required unless urgent
        if (this.requireDonorType && !(this.newJourney ? false : !!this.journey.urgent)) {
          if(keys[key] == "a2_or_a2b_donor") {
            // return required true if on waitlist, organ is kidney, blood type is B and key is a2_or_a2b_donor
            return this.organCode == OrganCodeValue.Kidney && this.recipient.diagnostics?.blood?.type == BloodTypeValue.B;
          }
          return true;
        }
        
      }
    }
    return false;
  }

  public donorTypeUpdate():any{
    const donorSelectedType = this.donorTypes.find((e: AllDonorTypes) => e.code === this.donorTypeCode);
    if(donorSelectedType) {
      this.donorType = {
        deceased_donor : donorSelectedType.deceased_donor,
        living_donor : donorSelectedType.living_donor,
        code : donorSelectedType.code,
        value : donorSelectedType.code
      };
    } else {
      this.donorType = {};
    }
  }

  /**
   * Emits a loaded event after all subcomponents have finished loading.
   *
   * The Donor Acceptability card section emits a loaded event when
   * it finishes loading lookup tables (if any).
   *
   * @listens donorAcceptability#loaded
   * @emits loaded
   */
  public loaded(): void {
    this.initializeForm();
    this.$emit('loaded', 'donorAcceptabilitySection');
  }

  /**
   * Populates the Donor Acceptability form state with data from
   * the selected Journey.
   */
  public initializeForm(): void {
    this.$store.commit("pageState/set", {
      pageKey: "donorAcceptability",
      value: this.buildDonorAcceptability(this.journey)
    });
  }

  /**
   * Generates Donor Acceptability form state based on the selected journey
   *
   * @param journey current selected jounrey
   * @returns {DonorAcceptabilityForm} Donor Acceptability form state
   */
  public buildDonorAcceptability(journey: RecipientJourney): DonorAcceptabilityForm {
    if (!journey) {
      return {
        acceptabilityCriteria: {
          a2_or_a2b_donor: null,
          abo_incompatible: null,
          hcv_ab_positive: null,
          hcv_nat_positive: null,
          hep_ab_core_positive: null,
          ecd_donor: null,
          minWeight: {},
          maxWeight: {},
          minHeight: {},
          maxHeight: {},
          deceased_donor: null,
          living_donor: null,
        }
      };
    }
    const donor_acceptability = journey.donor_acceptability as RecipientDonorAcceptability;
    const result: DonorAcceptabilityForm = {
      acceptabilityCriteria: {
        a2_or_a2b_donor: donor_acceptability.a2_or_a2b_donor,
        abo_incompatible: donor_acceptability.abo_incompatible,
        hcv_ab_positive: donor_acceptability.hcv_ab_positive,
        hcv_nat_positive: donor_acceptability.hcv_nat_positive,
        hep_ab_core_positive: donor_acceptability.hep_ab_core_positive,
        ecd_donor: donor_acceptability.ecd_donor,
        minWeight: {
          weightKg: donor_acceptability.min_weight,
          weightLb: this.convertKgLb('lb', donor_acceptability.min_weight)
        },
        maxWeight: {
          weightKg: donor_acceptability.max_weight,
          weightLb: this.convertKgLb('lb', donor_acceptability.max_weight)
        },
        minHeight: {
          heightCm: donor_acceptability.min_height,
          heightIn: this.convertCmIn('in', donor_acceptability.min_height)
        },
        maxHeight: {
          heightCm: donor_acceptability.max_height,
          heightIn: this.convertCmIn('in', donor_acceptability.max_height)
        },
        minAge: donor_acceptability.min_age,
        maxAge: donor_acceptability.max_age,
        comments: donor_acceptability.comments,
        deceased_donor: donor_acceptability.deceased_donor,
        living_donor: donor_acceptability.living_donor,

      }
    };

    const donorSelectedType = this.donorTypes.find((e: any) => (
      (e.living_donor === result.acceptabilityCriteria.living_donor) &&
      (e.deceased_donor === result.acceptabilityCriteria.deceased_donor)));


    this.donorTypeCode = !donorSelectedType ? null : donorSelectedType.code;
    this.donorTypeUpdate();

    return result;
  }

  /**
   * Gets changes from the edit state as a patch for the journey's Donor Acceptability
   *
   * If the edit state doesn't exist return null
   *
   * @returns {any} object containing field changes
   */
  public extractPatch(): any {
    if (!this.editState || !this.editState.acceptabilityCriteria) {
      return {};
    } else {
      return this.extractDonorAcceptabilityPatch(this.editState);
    }
  }

  /**
   * Saves the form edit state.
   *
   * Prepares an update payload for Donor Acceptability Criteria,
   * dispatches a save action, and registers the save result.
   */
  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.donorAcceptability as unknown as SaveProvider;
    this.$emit('clear');
    // Generate payload based on current edit state
    const payload = {
      recipientId: this.clientId,
      journeyId: this.journey._id ? this.journey._id.$oid : undefined,
      journey: this.extractPatch()
    };
    // Dispatch save action and register the response
    this.$store.dispatch('journeyState/saveJourney', payload).then((success: SaveResult) => {
      // If successful, update the current recipient and show success notification
      const clientId = this.clientId;
      const journeyId = String(this.journey._id!.$oid);
      // Clear the recipient
      this.$store.dispatch('recipients/get', clientId);
      // Register success result
      saveProvider.registerSaveResult(success);
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  // Private methods

  /**
   * Gets changes from the edit state as a patch for the journey's Donor Acceptability
   *
   * Changes to Donor Acceptability nested within 'donor_acceptability' key
   *
   * @returns {RecipientJourney} patch object containing field changes
   */
  private extractDonorAcceptabilityPatch(donorAcceptability: DonorAcceptabilityForm): RecipientJourney {
    const acceptCriteria = donorAcceptability.acceptabilityCriteria;
    return {
      donor_acceptability: {
        a2_or_a2b_donor: acceptCriteria.a2_or_a2b_donor  === undefined ? null : acceptCriteria.a2_or_a2b_donor,
        abo_incompatible: acceptCriteria.abo_incompatible === undefined ? null : acceptCriteria.abo_incompatible,
        hcv_ab_positive: acceptCriteria.hcv_ab_positive === undefined ? null : acceptCriteria.hcv_ab_positive,
        hcv_nat_positive: acceptCriteria.hcv_nat_positive === undefined ? null : acceptCriteria.hcv_nat_positive,
        hep_ab_core_positive: acceptCriteria.hep_ab_core_positive === undefined ? null : acceptCriteria.hep_ab_core_positive,
        ecd_donor: acceptCriteria.ecd_donor === undefined ? null : acceptCriteria.ecd_donor,
        min_weight: acceptCriteria.minWeight!.weightKg,
        max_weight: acceptCriteria.maxWeight!.weightKg,
        min_height: acceptCriteria.minHeight!.heightCm,
        max_height: acceptCriteria.maxHeight!.heightCm,
        min_age: acceptCriteria.minAge,
        max_age: acceptCriteria.maxAge,
        comments: acceptCriteria.comments,
        deceased_donor: this.donorType.deceased_donor === undefined ? null : this.donorType.deceased_donor,
        living_donor: this.donorType.living_donor === undefined ? null : this.donorType.living_donor,
      }
    };
  }

  /**
   * Updates a height from an input $event
   *
   * Using specific id's capture the value needing to change
   * and update the corresponding field to match.
   *
   * @param $event input field
   * @returns {number} directly modifies the editState model
   */
  private updateHeight(event: any) {
    if (event.target.id.includes("minimum-height-in")) {
      const measurement = this.editState.acceptabilityCriteria.minHeight!.heightIn;
      Vue.set(this.editState.acceptabilityCriteria.minHeight, 'heightCm', this.convertCmIn('cm', measurement));
    }
    if (event.target.id.includes("minimum-height-cm")) {
      const measurement = this.editState.acceptabilityCriteria.minHeight!.heightCm;
      Vue.set(this.editState.acceptabilityCriteria.minHeight, 'heightIn', this.convertCmIn('in', measurement));
    }
    if (event.target.id.includes("maximum-height-in")) {
      const measurement = this.editState.acceptabilityCriteria.maxHeight!.heightIn;
      Vue.set(this.editState.acceptabilityCriteria.maxHeight, 'heightCm', this.convertCmIn('cm', measurement));
    }
    if (event.target.id.includes("maximum-height-cm")) {
      const measurement = this.editState.acceptabilityCriteria.maxHeight!.heightCm;
      Vue.set(this.editState.acceptabilityCriteria.maxHeight, 'heightIn', this.convertCmIn('in', measurement));
    }
  }

  /**
   * Converts Height Cm <-> In
   *
   * Using the unit param, convert cm to in
   * or in to cm.
   *
   * @param unit 'in' or 'cm'
   * @param measurement the measurement to convert
   * @returns {number|undefined} return the converted value or undefined
   */
  private convertCmIn(unitTo: string, measurement?: number): number|undefined {
    if (!measurement || isNaN(measurement)) {
      return undefined;
    }
    if (unitTo === 'in') {
      return parseFloat((measurement * 0.39370).toFixed(1));
    } else {
      return parseFloat((measurement / 0.39370).toFixed(1));
    }
  }

  /**
   * Updates a weight from an input $event
   *
   * Using specific id's capture the value needing to change
   * and update the corresponding field to match.
   *
   * @param $event input field
   * @returns {number} directly modifies the editState model
   */
  private updateWeight(event: any) {
    if (event.target.id.includes("minimum-weight-kg")) {
      const measurement = this.editState.acceptabilityCriteria.minWeight!.weightKg;
      Vue.set(this.editState.acceptabilityCriteria.minWeight, 'weightLb', this.convertKgLb('lb', measurement));
    }
    if (event.target.id.includes("minimum-weight-lb")) {
      const measurement = this.editState.acceptabilityCriteria.minWeight!.weightLb;
      Vue.set(this.editState.acceptabilityCriteria.minWeight, 'weightKg', this.convertKgLb('kg', measurement));
    }
    if (event.target.id.includes("maximum-weight-kg")) {
      const measurement = this.editState.acceptabilityCriteria.maxWeight!.weightKg;
      Vue.set(this.editState.acceptabilityCriteria.maxWeight, 'weightLb', this.convertKgLb('lb', measurement));
    }
    if (event.target.id.includes("maximum-weight-lb")) {
      const measurement = this.editState.acceptabilityCriteria.maxWeight!.weightLb;
      Vue.set(this.editState.acceptabilityCriteria.maxWeight, 'weightKg', this.convertKgLb('kg', measurement));
    }
  }

  /**
   * Converts weight Kg <-> Lb
   *
   * Using the unit param, convert kb to lb
   * or lb to kb.
   *
   * @param unit 'kg' or 'lb'
   * @param measurement the measurement to convert
   * @returns {number|undefined} return the converted value or undefined
   */
  private convertKgLb(unitTo: string, measurement?: number): number|undefined {
    if (!measurement || isNaN(measurement)) {
      return undefined;
    }
    if (unitTo === 'lb') {
      return parseFloat((measurement * 2.2046).toFixed(1));
    } else {
      return parseFloat((measurement / 2.2046).toFixed(1));
    }
  }

  /**
   * Update HCV Nat+ or Ab+ value
   *
   * Change the corresponding radio input for HCV Nat+ or Ab+
   *
   * @param value string from the radio button
   */
  private hcvUpdate(value: string): void {
    // Ensure we have a value and it's a string
    if (!value || typeof value != 'string') {
      return;
    }

    switch(value) {
    case 'hcv_nat_positive': // Accept HCV NAT+ Donor
      // 1. Nat+ true then Ab+ goes to true
      if (this.editState.acceptabilityCriteria.hcv_nat_positive === true) {
        // Clusters require an alert message on Nat+ Accept
        if (this.isClustered) {
          alert(this.$t('hcv_nat_accept_cluster_confirm'));
        }
        Vue.set(this.editState.acceptabilityCriteria, 'hcv_ab_positive', true);
      } else {
        // 2. Nat+ goes false give user modal choice for Ab+ value
        if (this.editState.acceptabilityCriteria.hcv_nat_positive === false) {
          // Toggle the modal
          (this.$refs.hcvUpdateModal as ModalSection).toggleModal();
        }
      }
      break;
    case 'hcv_ab_positive': // Accept HCV Ab+ Donor
       // 3. AB+ change alert user
       const acceptHCVAb = this.editState.acceptabilityCriteria.hcv_ab_positive;
       if (this.isClustered) {
         // Clusters require an alert message on Nat+ changes
         alert(acceptHCVAb ? this.$t('hcv_ab_accept_cluster_confirm') : this.$t('hcv_nat_hcv_ab_decline_cluster_confirm'));
       } else if (acceptHCVAb === false){
          // 4. Ab+ goes false alert and change Nat+ to false and alert the user
          alert(this.$t('hcv_ab_decline_confirm'));
          Vue.set(this.editState.acceptabilityCriteria, 'hcv_nat_positive', false);
       }
      break;
    case 'abo_incompatible': //Accept ABO Incompatible Donor
      // 5. ABO Incompatible Donor change alert user
      const acceptABO = this.editState.acceptabilityCriteria.abo_incompatible;
      if (this.isClustered) {     
        // Clusters require an alert message on Nat+ change
        alert(acceptABO ? this.$t('abo_accept_cluster_confirm') : this.$t('abo_decline_cluster_confirm'));
      }
      break;
    case 'hep_ab_core_positive': // Accept Hep B Core Ab+ Donor
      // 6. hepB AB+ Donor change alert user
      const acceptHepB = this.editState.acceptabilityCriteria.hep_ab_core_positive;
      if (this.isClustered) {     
        // Clusters require an alert message on hepB AB+ chnage
        alert(acceptHepB ? this.$t('hepb_ab_accept_cluster_confirm') : this.$t('hepb_ab_decline_cluster_confirm'));
      }
      break;
    default:
      break;
    }
  }

  /**
   * Update HCV Ab+ value
   *
   * Change the HCV Ab+ value based on a yes/no choice in a modal
   *
   * @param id value of the input id
   */
  private updateHcvAb(id: string): void {
    // Check for accept in the id
    const result = /accept/.test(id);
    // Set the Ab+ according to the choice from the modal
    Vue.set(this.editState.acceptabilityCriteria, 'hcv_ab_positive', result);
    // Toggle the modal
    (this.$refs.hcvUpdateModal as ModalSection).toggleModal();
    // Hcv Ab+ value as 'Yes' or 'No' 
    const hcvAbValue = this.editState.acceptabilityCriteria.hcv_ab_positive;
    // Toggle cluster alert on modal close
    if (this.isClustered) {
      alert(hcvAbValue ? this.$t('hcv_ab_accept_cluster_confirm') : this.$t('hcv_ab_decline_cluster_confirm'));
    }
  }

  // API response keys on the left, id for our UI on the right
  public idLookup: IdLookup = {
    // Validation keys when saving locally
    'donor_acceptability.a2_or_a2b_donor'     : 'dac-a2_or_a2b_donor',
    'donor_acceptability.abo_incompatible'    : 'dac-abo_incompatible',
    'donor_acceptability.hcv_ab_positive'     : 'dac-hcv_ab_positive',
    'donor_acceptability.hcv_nat_positive'    : 'dac-hcv_nat_positive',
    'donor_acceptability.hep_ab_core_positive': 'dac-hep_ab_core_positive',
    'donor_acceptability.ecd_donor'           : 'dac-ecd_donor',
    'donor_acceptability.min_weight'          : 'dac-minimum-weight-kg',
    'donor_acceptability.max_weight'          : 'dac-maximum-weight-kg',
    'donor_acceptability.min_height'          : 'dac-minimum-height-cm',
    'donor_acceptability.max_height'          : 'dac-maximum-height-cm',
    'donor_acceptability.min_age'             : 'dac-min-age',
    'donor_acceptability.max_age'             : 'dac-max-age',
    'donor_acceptability.comments'            : 'dac-comments',
    'donor_acceptability.deceased_donor'      : 'donorTypeCode',
    'donor_acceptability.living_donor'        : 'donorTypeCode',

    // Validation keys when attempting to add a recipient to the waitlist
    'addToWaitlist.journey.donor_acceptability.a2_or_a2b_donor'     : 'dac-a2_or_a2b_donor',
    'addToWaitlist.journey.donor_acceptability.abo_incompatible'    : 'dac-abo_incompatible',
    'addToWaitlist.journey.donor_acceptability.hcv_ab_positive'     : 'dac-hcv_ab_positive',
    'addToWaitlist.journey.donor_acceptability.hcv_nat_positive'    : 'dac-hcv_nat_positive',
    'addToWaitlist.journey.donor_acceptability.hep_ab_core_positive': 'dac-hep_ab_core_positive',
    'addToWaitlist.journey.donor_acceptability.ecd_donor'           : 'dac-ecd_donor',
    'addToWaitlist.journey.donor_acceptability.deceased_donor'      : 'donorTypeCode',
    'addToWaitlist.journey.donor_acceptability.living_donor'        : 'donorTypeCode',

  };
}
</script>
