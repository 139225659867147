<template>
  <div>
    <div v-if="!organCode">
      {{$t('loading')}}
    </div>
    <div v-else>
      <!-- Page Top -->
      <page-top>
        <router-link :to="{ name: 'list-recipients' }">
          {{$t('recipients')}}
        </router-link>
        /
        <router-link :to="{ name: 'edit-recipient', params: { id: recipientId } }">
          {{recipientDisplayName}}
        </router-link>
        / {{$t(organName)}}
      </page-top>
      <recipient-sticky-summary />
      <!-- Page wrap -->
      <div class="content-wrap">
        <!-- Page Container  -->
        <div class="container-fluid">
          <!-- Nav wrapper -->
          <div class="nav-wrapper">
            <!-- Sidebar Navigation -->
            <side-nav-journey :newOrgan="organName" :organCode="organCode"/>
            <!-- Organ Content -->
            <div class="page-content">
              <recipient-summary />
              <validation-observer ref="validations">
                <component
                  v-bind:is="organComponentName"
                  :new-journey="true"
                  ref="organComponent"
                  @saving="saving"
                  @handleErrors="handleErrors"
                  @clear="resetValidationErrors"
                />
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n src="@/views/_locales/organs.json"></i18n>
<i18n src="@/components/_locales/Organs.json"></i18n>

<script lang="ts">
import { Getter, State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { mixins } from "vue-class-component";
import { ValidationUtilsMixin } from "@/mixins/validation-utils-mixin";
import { IdLookup } from '@/store/validations/types';
import { SaveResult } from '@/types';
import { organCodeLookup } from '@/types';
import { Recipient } from '@/store/recipients/types';
import { RecipientJourney, RecipientReferralAttributes } from '@/store/recipientJourney/types';

import Vca from '@/components/organs/vca/Vca.vue';
import Lung from '@/components/organs/lung/Lung.vue';
import Heart from '@/components/organs/heart/Heart.vue';
import Liver from '@/components/organs/liver/Liver.vue';
import Kidney from '@/components/organs/kidney/Kidney.vue';
import SmallBowel from '@/components/organs/bowel/SmallBowel.vue';
import PancreasWhole from '@/components/organs/pancreas/PancreasWhole.vue';
import PancreasIslets from '@/components/organs/pancreas/PancreasIslets.vue';
import PageTop from '@/components/shared/PageTop.vue';
import SideNavJourney from "@/components/organs/shared/side-nav/SideNavJourney.vue";
import RecipientSummary from '@/components/recipients/RecipientSummary.vue';
import RecipientStickySummary from '@/components/recipients/RecipientStickySummary.vue';
import { OrganCodeValue } from '@/store/lookups/types';

@Component({
  components: {
    Vca,
    Lung,
    Heart,
    Liver,
    Kidney,
    PageTop,
    SmallBowel,
    PancreasWhole,
    PancreasIslets,
    SideNavJourney,
    RecipientSummary,
    RecipientStickySummary
  }
})
export default class NewOrgan extends mixins(ValidationUtilsMixin) {
  // State
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('recipientDisplayName', { namespace: 'recipients' }) recipientDisplayName!: string;
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;
  @Getter("recipientAge", { namespace: "recipients" }) recipientAge!: number;

  public mounted(): void {
    Promise.all([
      this.$store.commit('setPageTitle', `${this.$t('recipients')} / ${this.recipientDisplayName} / ${this.$t(this.organName)}`),
      this.$store.dispatch('validations/loadNew', { view: `recipients/${this.recipientId}/journeys/`, action: 'new' })
    ]);
  }

  /**
   * Return organ_code param from url
   *
   * @returns {string} organ code
   */
  get organCode(): string {
    return this.$route.params.organ_code;
  }

  /**
   * Return titlized organ name from lookup value
   *
   * @returns {string} organ name
   */
  get organName(): string {
    const organ = this.lookupValue(this.organCode, 'organ');
    return organ ? organ : '';
  }

  /**
   * Gets the current journey as lower case
   *
   * Using the organ code, return the lower case organ name
   *
   * @returns {string} organ as lower case
   */
  get organComponentName(): string {
    const name=this.organCode;
    return organCodeLookup[this.organCode];
  }


  // Parse and highlight errors from api response
  private handleErrors(errors: SaveResult|SaveResult[]): void {
    // Refer to top level organ component
    const organComponent = this.$refs.organComponent as Liver|Lung|Kidney|Heart|PancreasWhole|PancreasIslets|Vca|SmallBowel;
    // Gather validation IDs used in forms on this page
    const idLookup: IdLookup = {
      // until all organs are updated this will cause a linter error
      ...organComponent.idLookup(),
    };
    
    // Derive errors for UI input fields based on API error results
    const formErrors = this.parseFormErrors(errors, idLookup);

    // Set all validation errors using the validation observer wrapping entire form
    (this.$refs.validations as any).setErrors(formErrors);
  }

  // Clearing validations when saving
  private saving(formReference: string) {
    this.resetValidationErrors();
  }

  // Tell the top-level form validation observer to reset all errors
  private resetValidationErrors() {
    (this.$refs.validations as any).reset();
  }
}
</script>
