import { render, staticRenderFns } from "./_Notes.vue?vue&type=template&id=4ff079f6"
import script from "./_Notes.vue?vue&type=script&lang=ts"
export * from "./_Notes.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "../_locales/Notes.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fprototypes%2Forgans%2F_Notes.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports