<template>
  <sub-section
    :title="$t('exception_diseases')"
    sub-section-id="lsd-new-diseases"
    ref="liverExceptionDiseases"
    :lookups-to-load="lookupsToLoad"
    @loaded="loaded()"
    :save-button="canEdit"
    :disabled="!canSave || newJourney"
    :divider="true"
    :save-button-text="$t('save_diseases')"
    @save="saveDisease()"
  >
    <template v-slot:contents>
      <fieldset :disabled="!canEdit">

        <div class="row">
          <div class="standard-form-group">
            <label for="lsd-exception-disease-points">{{$t('exception_disease_points')}}</label>
            <input id="lsd-exception-disease-points"  v-model="editState.liverExceptionDiseases.points" type="text" class="form-control" readonly>
          </div>
        </div>

        <div class="row" v-if="editState.liverExceptionDiseases.exceptionDiseaseCodes">
          <template v-for="(schema,_inx) in populateExceptionDisease">
            <div :key="_inx" class="disease-group-form-group viralogy-section">
              <checkbox-input  v-model="editState.liverExceptionDiseases.exceptionDiseaseCodes[schema.code]"
                                :ruleKey="`lsd-exception-disease-${schema.code}`"
                                :input-id="`lsd-exception-disease-${schema.code}`"
                                :label="schema.value"
                                :disabled="schema.disabled || isLiverKidney(schema.code) || isOtherExceptionDisease(schema.code)"
              />
            </div>
          </template>

        </div>

        <div class="row" v-if="showExceptionDiseaseCodeOther">
          <div class="standard-form-group-large">
            <text-input
              inputId="lsd-exception-disease-other-comments"
              :name="$t('other_specify')"
              v-model="editState.liverExceptionDiseases.exceptionDiseaseOther"
              rules="required"
              :disabled="!(isCSCManager || isAdmin)"
              />
          </div>
        </div>

        <br/>

        <sub-section
          :title="$t('paediatric_conditions')"
          sub-section-id="lsd-paediatric"
          ref="liverPediatricCondition"
          :save-button="false"
          v-if="isPediatricConditions || !showExceptionDisease"
        >
          <template v-slot:contents>
            <fieldset>
              <div class="row" v-if="editState.liverPediatricCondition.pediatricConditionCodes && (isPediatricConditions || !showExceptionDisease)">
                <template v-for="(schema,_inx) in populatePediatricCondition">
                  <div :key="_inx" class="disease-group-form-group">
                    <checkbox-input  v-model="editState.liverPediatricCondition.pediatricConditionCodes[schema.code]"
                                      :ruleKey="`lsd-pediatric-condition-${schema.code}`"
                                      :input-id="`lsd-pediatric-condition-${schema.code}`"
                                      :label="schema.value"
                                      :disabled="schema.disabled || showExceptionDisease"
                    />
                  </div>
                </template>
              </div>
            </fieldset>
          </template>
        </sub-section>

      </fieldset>
    </template>
  </sub-section>

</template>

<i18n src="@/components/organs/liver/_locales/DiseasesSection.json"></i18n>

<script lang="ts">

import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import {Getter, State} from 'vuex-class';
import {SaveProvider, SaveResult} from '@/types';
import {Component, Prop, Vue} from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import SubSection from '@/components/shared/SubSection.vue';
import { RecipientJourney } from '@/store/recipientJourney/types';
import {Recipient} from "@/store/recipients/types";
import {LiverDetails,DiseasesSectionCode,PediatricSectionCode} from '@/store/organSpecificDetails/types';
import CheckboxInput from "@/components/shared/CheckboxInput.vue";
import CardSection from "@/components/shared/CardSection.vue";
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import TextInput from "@/components/shared/TextInput.vue";

export interface DiseasesSectionRow {
  points?: number | null;
  exceptionDiseaseCodes?: any;
  exceptionDiseaseOther?: string;
}

export interface PediatricSectionRow {
  pediatricConditionCodes?: (boolean|null)[];
}

export interface DiseasesSectionForm {
  liverExceptionDiseases: DiseasesSectionRow;
  liverPediatricCondition: PediatricSectionRow;
}

export const EXCEPTION_DISEASE_CODES = {
  LiverKidney : 8,
  Other: 10,
};

@Component({
  components: {
    SubSection,
    CheckboxInput,
    CardSection,
    TextAreaInput,
    TextInput,
  }
})

export default class DiseasesSection extends mixins(DateUtilsMixin) {

  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.liverDetails) editState!: DiseasesSectionForm;// LiverSpecificForm;
  @State(state => state.lookups.liver_exception_diseases) private  liverExceptionDiseases!: any;
  @State(state => state.lookups.liver_pediatric_condition) private  liverPediatricCondition!: any;

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;
  @Getter('showExceptionDisease', { namespace: 'labs' }) showExceptionDisease!: boolean;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('isAdmin', { namespace: 'users' }) private isAdmin!: boolean;
  @Getter('isCSCManager', { namespace: 'users' }) private isCSCManager!: boolean;

  // Lookup tables to be loaded by the CardSection component
  private lookupsToLoad = ['liver_exception_diseases','liver_pediatric_condition'];

  /**
   * Return true if exception diseases section can be edited
   * 
   * cannot be edited if new journey
   * cannot be edited if journey is completed
   *
   * @returns {boolean} true if we can edit
   */
  get canEdit(): boolean{
    if (this.newJourney || this.journey.completed) {
      return false;
    }
    return true;
  }

  get populateExceptionDisease(){
    return this.liverExceptionDiseases || [] ;
  }

  get populatePediatricCondition(){
    return this.liverPediatricCondition || [] ;
  }

  /**
   * This section should be editable only by CSC Manager and TGLN Administrator roles.
   */
  public isOtherExceptionDisease(exception_disease_code: number): boolean {
    return exception_disease_code == EXCEPTION_DISEASE_CODES.Other && !(this.isCSCManager || this.isAdmin);
  }

  public loaded(): void {
    this.initializeForm();
    this.$emit("loaded", "liverException");
  }

  get isPediatricConditions(): boolean {
    return this.editState.liverPediatricCondition.pediatricConditionCodes?.length !== 0;
  }

  public isLiverKidney(exception_disease_code: number): boolean {
     return (exception_disease_code === EXCEPTION_DISEASE_CODES.LiverKidney);
  }

  get showExceptionDiseaseCodeOther(): boolean {
     if(!this.editState.liverExceptionDiseases.exceptionDiseaseCodes)
       return false;
     return this.editState.liverExceptionDiseases.exceptionDiseaseCodes[EXCEPTION_DISEASE_CODES.Other] == true;
  }

  // Loads a form edit state based on the lab, or a new state if there is none
  private initializeForm(): void {

    this.$store.commit("pageState/set", {
      pageKey: "liverDetails",
      componentKey: "liverExceptionDiseases",
      value: this.buildDiseasesForm()
    });

    this.$store.commit("pageState/set", {
      pageKey: "liverDetails",
      componentKey: "liverPediatricCondition",
      value: this.buildPediatricForm()
    });
  }


  public buildDiseasesForm(): DiseasesSectionRow {
    if (!this.journey){
      return {
        exceptionDiseaseCodes: [],
      };
    }

    const liverDetails = this.journey.organ_specific_details as LiverDetails;
    const latestSmc = liverDetails.latest_smc;
    const exceptionDiseases = liverDetails.exception_diseases || [];

    const other = exceptionDiseases.find((each:any) => { return each.comments !== null; });
    let exceptionDiseaseCodes:any = [];
    Object(exceptionDiseases).map((each:any) => {
       exceptionDiseaseCodes[each.exception_disease_code] = true;
    });

    return {
        exceptionDiseaseCodes: exceptionDiseaseCodes,
        exceptionDiseaseOther: other !== undefined ? other.comments : undefined,
        points: latestSmc ? latestSmc.exception_points_scores?.exception_diseases : null
    };
  }


  public buildPediatricForm(): PediatricSectionRow {
    if (!this.journey) {
      return {
        pediatricConditionCodes: []
      };
    }

    const liverDetails = this.journey.organ_specific_details as LiverDetails;
    const pediatricCondition = liverDetails.pediatric_conditions || [];

    let pediatricConditionCodes:any = [];
    Object(pediatricCondition).map((each:any) => {
      pediatricConditionCodes[each.pediatric_condition_code] = true;
    });

    return {
      pediatricConditionCodes: pediatricConditionCodes
    };
  }


  private saveDisease():void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.liverExceptionDiseases as unknown as SaveProvider;

    // Report to parent that saving has began
    this.$emit('save', 'liverException');

    // Generate payload based on current edit state
    const organDetail = this.extractPatch()?.organ_specific_details || {};
    const payload = {
      recipientId: this.recipientId,
      journeyId: this.journey?._id ? this.journey._id.$oid : undefined,
      organDetail,
    };
    // Dispatch save action and register the response
    this.$store.dispatch('organSpecificDetails/saveOrganDetail', payload).then((success: SaveResult) => {
      // If successful, update the current recipient and show success notification
      // Register success result
      saveProvider.registerSaveResult(success);
      // Request card-section to reload sub-sections related to Liver Scores simultaneously
      // Note: this will cause the card-section to invoke this component's public 'reinitialize' method
      this.$emit('reloadLiverExceptionPoints');
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  // Re-initialize the form edit state
  public reinitialize(): void {
    this.initializeForm();
  }

  public extractPatch(): RecipientJourney {
    if (!this.editState || !this.editState.liverExceptionDiseases) {
      return {};
    }
    let exceptionDiseaseCodes: DiseasesSectionCode[] = [];
    let pediatricConditionCodes: PediatricSectionCode[] = [];

      Object(this.editState.liverExceptionDiseases.exceptionDiseaseCodes).map((selected: any, key: any) => {
        if (selected === true) {
          if(key !== EXCEPTION_DISEASE_CODES.Other) {
            exceptionDiseaseCodes.push({exception_disease_code: key, exception_disease_date: this.currentDateTimeApi() });
          }else{
            exceptionDiseaseCodes.push({
              exception_disease_code: key,
              comments:  this.editState.liverExceptionDiseases.exceptionDiseaseOther,
              exception_disease_date: this.currentDateTimeApi(),
            });
          }
        }
      });

    if(!this.showExceptionDisease) {
      Object(this.editState.liverPediatricCondition.pediatricConditionCodes).map((selected: any, key: any) => {
        if (selected === true) {
          pediatricConditionCodes.push({pediatric_condition_code: key});
        }
      });
    }

    return {
      organ_specific_details: {
        exception_diseases: exceptionDiseaseCodes,
        pediatric_conditions: pediatricConditionCodes
      }
    };
  }

  // API response keys on the left, id for our UI on the right
  public idLookup: IdLookup = {
    'organ_specific_details.exception_diseases.exception_disease_other' : 'lsd-exception-disease-other',
    'organ_specific_details.exception_diseases.comments'                : 'lsd-exception-disease-other-comments',
  };
}
</script>
