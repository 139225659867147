<template>
  <sub-section
    sub-section-id="liver-transplant-details"
    :save-button="canEdit"
    :save-button-text="$t('save_transplant_details')"
    @save="savePatch()"
    @loaded="loaded()"
    ref="saveTransplantDetails"
    :disabled="!isGroupWriteable('journey_transplant_details')"
    :confirmation="confirmationText"
  >
    <template v-slot:contents>
      <div class="hr-break" />
      <fieldset :disabled="newJourney">
        <legend>
          <h5 class="legend-title">
            {{$t('or_details')}}
          </h5>
        </legend>
        <template v-if="!editState || !editState.transplantDetails || !editState.transplantDetails.organTransplantDetails">
          {{$t('loading')}}
        </template>
        <template v-else>
          <div class="row">
            <div class="standard-form-group">
              <select-input
                ruleKey="organ_specification_code"
                select-id="transplantType"
                :name="$t('transplant_type')"
                v-model="editState.transplantDetails.organTransplantDetails.transplantType"
                :options="transplantTypeLookup"
              />
            </div>
            <div class="standard-form-group">
              <date-input
                ruleKey="transplant_start_date"
                input-id="transplantStartDate"
                :name="$t('transplant_start_date')"
                rules="required_if_filled:@transplantStartTime"
                :cross-values="{ transplantStartTime: editState.transplantDetails.organTransplantDetails.transplantStartTime }"
                v-model="editState.transplantDetails.organTransplantDetails.transplantStartDate"
              />
            </div>
           <div class="standard-form-group">
              <time-input
                ruleKey="transplant_start_date"
                inputId="transplantStartTime"
                :name="$t('transplant_start_time')"
                rules="required_if_filled:@transplantStartDate"
                :cross-values="{ transplantStartDate: editState.transplantDetails.organTransplantDetails.transplantStartDate }"
                v-model="editState.transplantDetails.organTransplantDetails.transplantStartTime"
              />
            </div>
            <div class="standard-form-group">
              <date-input
                input-id="transplantEndDate"
                name="Transplant End Date"
                v-model="transplant_end_date"
              />
            </div>
            <div class="standard-form-group">
               <time-input
                inputId="transplantEndTime"
                name="Transplant End Time"
                v-model="transplant_end_time"        
              />
            </div>
            <div class="row-break d-none d-xl-block"></div>
            <div class="standard-form-group">
              <date-input
                ruleKey="portal_vein_cross_clamp_date"
                input-id="pv_CrossClampDate"
                :name="$t('portal_vein_cross_clamp_date')"
                rules="required_if_filled:@pv_CrossClampTime"
                :cross-values="{ pv_CrossClampTime: editState.transplantDetails.organTransplantDetails.pv_CrossClampTime }"
                v-model="editState.transplantDetails.organTransplantDetails.pv_CrossClampDate"
              />
            </div>
            <div class="standard-form-group-6column-xlarge-only">
              <time-input
                ruleKey="portal_vein_cross_clamp_date"
                inputId="pv_CrossClampTime"
                :name="$t('portal_vein_cross_clamp_time')"
                rules="required_if_filled:@pv_CrossClampDate"
                :cross-values="{ pv_CrossClampDate: editState.transplantDetails.organTransplantDetails.pv_CrossClampDate }"
                v-model="editState.transplantDetails.organTransplantDetails.pv_CrossClampTime"
              />
            </div>
            <div class="standard-form-group">
              <date-input
                ruleKey="end_cold_storage_date"
                input-id="removedFromColdDate"
                :name="$t('removed_from_cold_date')"
                rules="required_if_filled:@removedFromColdTime"
                :cross-values="{ removedFromColdTime: editState.transplantDetails.organTransplantDetails.removedFromColdTime }"
                v-model="editState.transplantDetails.organTransplantDetails.removedFromColdDate"
              />
            </div>
            <div class="standard-form-group">
              <time-input
                ruleKey="end_cold_storage_date"
                inputId="removedFromColdTime"
                :name="$t('removed_from_cold_time')"
                rules="required_if_filled:@removedFromColdDate"
                :cross-values="{ removedFromColdDate: editState.transplantDetails.organTransplantDetails.removedFromColdDate }"
                v-model="editState.transplantDetails.organTransplantDetails.removedFromColdTime"
              />
            </div>
            <div class="standard-form-group-large">
              <date-input
                ruleKey="removed_from_normothermic_perf_pump_date"
                input-id="normothermicDate"
                :name="$t('removed_from_normothermic_perfusion_pump_date')"
                rules="required_if_filled:@normothermicTime"
                :cross-values="{ normothermicTime: editState.transplantDetails.organTransplantDetails.normothermicTime }"
                v-model="editState.transplantDetails.organTransplantDetails.normothermicDate"
              />
            </div>
            <div class="standard-form-group-large">
              <time-input
                ruleKey="removed_from_normothermic_perf_pump_date"
                inputId="normothermicTime"
                :name="$t('removed_from_normothermic_perfusion_pump_time')"
                rules="required_if_filled:@normothermicDate"
                :cross-values="{ normothermicDate: editState.transplantDetails.organTransplantDetails.normothermicDate }"
                v-model="editState.transplantDetails.organTransplantDetails.normothermicTime"
              />
            </div>
           <div class="standard-form-group-6column-xlarge-only">
              <date-input
                ruleKey="pv_clamp_off_date"
                input-id="pv_ClampOffDate"
                :name="$t('portal_vein_clamp_removal_date')"
                rules="required_if_filled:@pv_ClampOffTime"
                :cross-values="{ pv_ClampOffTime: editState.transplantDetails.organTransplantDetails.pv_ClampOffTime }"
                v-model="editState.transplantDetails.organTransplantDetails.pv_ClampOffDate"
              />
            </div>
            <div class="standard-form-group-6column-xlarge-only">
              <time-input
                ruleKey="pv_clamp_off_date"
                inputId="pv_ClampOffTime"
                :name="$t('portal_vein_clamp_removal_time')"
                rules="required_if_filled:@pv_ClampOffDate"
                :cross-values="{ pv_ClampOffDate: editState.transplantDetails.organTransplantDetails.pv_ClampOffDate }"
                v-model="editState.transplantDetails.organTransplantDetails.pv_ClampOffTime"
              />
            </div>
            <div class="standard-form-group-6column-xlarge-only">
              <date-input
                ruleKey="ha_clamp_off_date"
                input-id="ha_ClampOffDate"
                :name="$t('hepatic_artery_clamp_removal_date')"
                rules="required_if_filled:@ha_ClampOffTime"
                :cross-values="{ ha_ClampOffTime: editState.transplantDetails.organTransplantDetails.ha_ClampOffTime }"
                v-model="editState.transplantDetails.organTransplantDetails.ha_ClampOffDate"
              />
            </div>
            <div class="standard-form-group-large">
              <time-input
                ruleKey="ha_clamp_off_date"
                inputId="ha_ClampOffTime"
                :name="$t('hepatic_artery_clamp_removal_time')"
                rules="required_if_filled:@ha_ClampOffDate"
                :cross-values="{ ha_ClampOffDate: editState.transplantDetails.organTransplantDetails.ha_ClampOffDate }"
                v-model="editState.transplantDetails.organTransplantDetails.ha_ClampOffTime"
              />
            </div>
            <div class="standard-form-group">
              <text-input
                ruleKey="rewarm_time"
                inputId="rewarmTime"
                :name="$t('rewarm_time')"
                v-model="editState.transplantDetails.organTransplantDetails.rewarmTime"
                :append="true"
                appendText="hrs"
                :calculated="true"
                :disabled="true"
              />
            </div>
            <div class="standard-form-group-6column-xlarge-only">
              <text-input
                ruleKey="cold_isch_time"
                inputId="coldIschTime"
                :name="$t('cold_ischemic_time')"
                :append="true"
                appendText="hrs"
                :calculated="true"
                :disabled="true"
                v-model="editState.transplantDetails.organTransplantDetails.coldIschTime"
              />
            </div>
          </div>
          <div class="row">
            <div class="standard-form-group">
              <boolean-radio-input
                ruleKey="vessels_used"
                input-id="vesselsUsed"
                :labelName="$t('vessels_used')"
                :acceptId="true"
                :declineId="false"
                :acceptLabel="$t('yes')"
                :declineLabel="$t('no')"
                v-model="editState.transplantDetails.organTransplantDetails.vesselsUsed"
              />
            </div>
            <div class="standard-form-group-large"
              v-if="editState.transplantDetails.organTransplantDetails.vesselsUsed" 
            >
              <text-area-input
                ruleKey="list_of_vessels_used"
                input-id="listOfVessels"
                :name="$t('list_of_vessels')"
                v-model="editState.transplantDetails.organTransplantDetails.listOfVessels"
              />
            </div>
          </div>
        </template>
      </fieldset>
    </template>
  </sub-section>
</template>

<i18n src="./../shared/_locales/common.json"></i18n>
<i18n src="@/components/_locales/Organs.json"></i18n>
<i18n src="@/components/organs/liver/_locales/LiverTransplantDetails.json"></i18n>

<script lang="ts">
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TransplantMixin } from "@/mixins/transplant-mixin";
import { Getter, State } from "vuex-class";
import { Component, Vue, Prop } from "vue-property-decorator";
import { IdLookup } from '@/store/validations/types';
import SubSection from "@/components/shared/SubSection.vue";
import { LiverTransplantAttributes, RecipientJourney, TransplantFactors } from "@/store/recipientJourney/types";
import DateInput from "@/components/shared/DateInput.vue";
import { RecipientTransplantAttributes } from "@/store/recipientJourney/types";
import TimeInput from "@/components/shared/TimeInput.vue";
import NumberInput from '@/components/shared/NumberInput.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { Recipient } from '@/store/recipients/types';
import BooleanRadioInput from '@/components/shared/BooleanRadioInput.vue';
import { Organ, OrganSpecification } from '@/store/lookups/types';
import SelectInput from '@/components/shared/SelectInput.vue';
import { TransplantSectionPageState } from '@/mixins/transplant-mixin';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import TextInput from "@/components/shared/TextInput.vue";

export interface LiverTransplantDetailsPageState {
  transplantType?:number;
  transplantStartDate?: string|null;
  transplantStartTime?: string|null;
  pv_CrossClampDate?: string|null;
  pv_CrossClampTime?: string|null;
  removedFromColdDate?: string|null;
  removedFromColdTime?: string|null;
  normothermicDate?: string|null;
  normothermicTime?: string|null;
  pv_ClampOffDate?: string|null;
  pv_ClampOffTime?: string|null;
  ha_ClampOffDate?: string|null;
  ha_ClampOffTime?: string|null;
  vesselsUsed?: boolean;
  listOfVessels?: string;
  rewarmTime?: string;
  coldIschTime?: string;
}

@Component({
  components: {
    SubSection,
    DateInput,
    TimeInput,
    BooleanRadioInput,
    SelectInput,
    TextAreaInput,
    TextInput
  }
})
export default class LiverTransplantDetails extends mixins(DateUtilsMixin, TransplantMixin) implements SaveableSection {
  // State
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  
  // Getters
  @Getter('clientId', { namespace: 'recipients' }) private recipientId!: string;
  @Getter("transplantTypeLookup", { namespace: "lookups" }) organSpecification!: (organCode: number | string | undefined) => OrganSpecification[] | undefined;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('convertTimeInSeconds', { namespace: 'utilities' }) convertTimeInSeconds!: (time?: number) => string;
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  private transplant_end_date = '';
  private transplant_end_time = '';


  public loaded(): void {
    this.$emit('loaded', 'liverDetails');
  }

    public mounted(): void {

    let items = localStorage.getItem(`${this.journey.organ}transplantDetailsOR`);

    if (items) {
      let parsed = JSON.parse(items);
      this.transplant_end_date = parsed.transplant_end_date;
      this.transplant_end_time = parsed.transplant_end_time;
    }
  }


  /**
   * Return true if we can edit the transplant details
   *
   * @returns {boolean} true if we can edit
   */
  get canEdit(): boolean{
    if (this.newJourney || this.journey.completed) {
      return false;
    }
    return true;
  }

  /**
   * Returns an array of options for Transplant Type
   * 
   * 
   * @returns {OrganSpecification[]|[]} organ-specific options 
   */
  get transplantTypeLookup(): OrganSpecification[] {
   const organCode = this.journey?.organ_code;
    if (!organCode) {
      return [];
    }
    // Fetch appropriate options
    const options = this.organSpecification(organCode) || [];

    const translated = options.map((option: OrganSpecification): OrganSpecification => {
      return {
        ...option,
        value: this.$t(option.value).toString(),
      };
    });
    return translated;
  }

  /**
   * Build state for the LiverTransplantDetails form area
   *
   * @param LiverTransplantAttributes from RecipientTransplantAttributes
   * @returns {LiverTransplantDetailsPageState} form state for LiverTransplant
   */
  public buildTransplantDetailsState(transplantDetails?: RecipientTransplantAttributes): LiverTransplantDetailsPageState {
    const liverTransplantAttributes = transplantDetails as LiverTransplantAttributes || {};
    const result: LiverTransplantDetailsPageState = {
      transplantType: liverTransplantAttributes.organ_specification_code || undefined,
      transplantStartDate: this.parseDateUiFromDateTime(liverTransplantAttributes.transplant_start_date || undefined),
      transplantStartTime: this.parseTimeUiFromDateTime(liverTransplantAttributes.transplant_start_date || undefined),
      pv_CrossClampDate: this.parseDateUiFromDateTime(liverTransplantAttributes.portal_vein_cross_clamp_date || undefined),
      pv_CrossClampTime: this.parseTimeUiFromDateTime(liverTransplantAttributes.portal_vein_cross_clamp_date || undefined),
      removedFromColdDate: this.parseDateUiFromDateTime(liverTransplantAttributes.end_cold_storage_date || undefined),
      removedFromColdTime: this.parseTimeUiFromDateTime(liverTransplantAttributes.end_cold_storage_date || undefined),
      pv_ClampOffDate: this.parseDateUiFromDateTime(liverTransplantAttributes.pv_clamp_off_date || undefined),
      pv_ClampOffTime: this.parseTimeUiFromDateTime(liverTransplantAttributes.pv_clamp_off_date || undefined),
      ha_ClampOffDate: this.parseDateUiFromDateTime(liverTransplantAttributes.ha_clamp_off_date || undefined),
      ha_ClampOffTime: this.parseTimeUiFromDateTime(liverTransplantAttributes.ha_clamp_off_date || undefined),
      vesselsUsed: liverTransplantAttributes.vessels_used,
      normothermicDate: this.parseDateUiFromDateTime(liverTransplantAttributes.removed_from_normothermic_perf_pump_date || undefined),
      normothermicTime: this.parseTimeUiFromDateTime(liverTransplantAttributes.removed_from_normothermic_perf_pump_date || undefined),
      listOfVessels: liverTransplantAttributes.list_of_vessels_used,
      rewarmTime: this.convertTimeInSeconds(liverTransplantAttributes.rewarm_time || 0), 
      coldIschTime: this.convertTimeInSeconds(liverTransplantAttributes.cold_isch_time || 0)
    };
    return result;
  }

  /**
   * Gets a patch object representing form edit state changes for this form
   *
   * Delegates the logic of building the patch to a local private method
   *
   * @returns {any} patch object containing field changes
   */
  public extractPatch(): any {
    if (!this.editState || !this.editState.transplantDetails || !this.editState.transplantDetails.organTransplantDetails) {
      return {};
    } else {
      return this.extractLiverTransplantDetailsPatch(this.editState.transplantDetails.organTransplantDetails);
    }
  }

  // String for confirmation dialog for partial cluster transplant hold
  get confirmationText(): string {
    if (!this.isConfirmationRequired()) return '';

    return this.$t('confirm_partial_cluster_transplant_hold').toString();
  }

  /**
   * Saves the form edit state.
   *
   * Prepares a payload for Transplant Atributes, dispatches a save action, and registers the save result.
   * @emits clear clear validation errors because saving has begun
   * @emits saved saving has completed successful
   */
  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveTransplantDetails as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('clear');
    // Generate payload based on current edit state
    const transplantAttributesPatch = this.extractPatch();
    // Setup saving payload
    const payload = {
      journeyId: this.journey._id ? this.journey._id.$oid : undefined,
      recipientId: this.recipientId,
      transplantAttributes: transplantAttributesPatch,
    };
    // Dispatch save action and register the response
    this.$store.dispatch('journeyState/saveTransplantDetails', payload).then((success: SaveResult) => {
      // Report to parent that saving has completed
      this.$emit('saved', 'liverTransplantDetails');
      saveProvider.registerSaveResult(success);
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });

    const transplantDetailsStorage = {
      "transplant_end_date":  this.transplant_end_date,
      "transplant_end_time": this.transplant_end_time,
    };

    localStorage.setItem(`${this.journey.organ}transplantDetailsOR`, JSON.stringify(transplantDetailsStorage));
 }

   /**
   * Clears all save notifications shown by the form.
   *
   * Gets the Save Provider associated with the form, and requests that it reset its own Save Toolbar
   */
  public resetSaveToolbar(): void {
    const saveProvider = this.$refs.saveTransplantDetails as unknown as SaveProvider;
    saveProvider.resetSaveToolbar();
  }


  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    return {
      'organ_specification_code' : 'transplantType',
      'transplant_start_date' : 'transplantStartDate',
      'end_cold_storage_date' : 'removedFromColdDate',
      'pv_clamp_off_date' : 'pv_ClampOffDate',
      'ha_clamp_off_date'  : 'ha_ClampOffDate',
      'transplant_start_time' : 'transplantStartTime',
      'end_cold_storage_time' : 'removedFromColdTime',
      'pv_clamp_off_time' : 'pv_ClampOffTime',
      'ha_clamp_off_time'  : 'ha_ClampOffTime',
      'vessels_used' : 'vesselsUsed',
      'portal_vein_cross_clamp_date' : 'pv_CrossClampDate',
      'portal_vein_cross_clamp_time' : 'pv_CrossClampTime',
      'removed_from_normothermic_perf_pump_date' : 'normothermicDate',
      'removed_from_normothermic_perf_pump_time' : 'normothermicTime',
      'list_of_vessels_used' : 'listOfVessels',
    };
  }

  /**
   * Returns a journey patch object containing changes from a Transplant Details form
   *
   * @returns {RecipientTransplantAttributes}
   */
  private extractLiverTransplantDetailsPatch(transplantDetails: LiverTransplantDetailsPageState): LiverTransplantAttributes {
    const transplant = this.editState.transplantDetails || {};
    const liverTransplantDetails = transplant.organTransplantDetails as LiverTransplantDetailsPageState || {};

    // Fetch Transplant Date from the top-level of the form
    const transplantDate = this.editState.transplantDetails?.transplantDate;

    // Build nested 'factors' object to include in the Transplant Attributes patch
    const factors: TransplantFactors = {
      transplant_date: transplantDate ? this.sanitizeDateApi(transplantDate) : null,
    };

    // Build patch for Transplant Attributes object
    const result: LiverTransplantAttributes = {
      organ_specification_code: liverTransplantDetails.transplantType,
      transplant_start_date: this.sanitizeDateTimeApi(liverTransplantDetails.transplantStartDate, liverTransplantDetails.transplantStartTime),
      end_cold_storage_date: this.sanitizeDateTimeApi(liverTransplantDetails.removedFromColdDate, liverTransplantDetails.removedFromColdTime),
      pv_clamp_off_date: this.sanitizeDateTimeApi(liverTransplantDetails.pv_ClampOffDate, liverTransplantDetails.pv_ClampOffTime),
      ha_clamp_off_date: this.sanitizeDateTimeApi(liverTransplantDetails.ha_ClampOffDate, liverTransplantDetails.ha_ClampOffTime),
      vessels_used: liverTransplantDetails.vesselsUsed,
      portal_vein_cross_clamp_date: this.sanitizeDateTimeApi(liverTransplantDetails.pv_CrossClampDate, liverTransplantDetails.pv_CrossClampTime),
      removed_from_normothermic_perf_pump_date: this.sanitizeDateTimeApi(liverTransplantDetails.normothermicDate, liverTransplantDetails.normothermicTime),
      list_of_vessels_used: liverTransplantDetails.listOfVessels,
      factors,
      donor_id: transplant.donorId ? { $oid: transplant.donorId } : null,
    };

    // Copy Details to Clustered Organs for details see https://shore.tpondemand.com/entity/7541-722-v42-transplant-details
    if (this.isClustered) {
      result.copy_to_cluster = transplant.copyToCluster;
    }

    return result;
  }
}
</script>
