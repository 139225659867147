<template>
  <sub-section
    :title="$t('sodium_meld')"
    sub-section-id="lsd-new-meld"
    :tabbableValue="$t('date_of_nameld_data')"
    :table-config="meldTableConfig"
    @table-row-click="selectMeld($event)"
    @table-create-row="createMeld()"
    :saveButton="canEdit"
    :disabled="!canSave || newJourney"
    :save-button-text="$t('save_nameld')"
    :divider="true"
    ref="saveSodiumMeld"
    @save="saveSodiumMeld()"
  >
    <template v-slot:contents>
      <fieldset v-if="editState.sodiumMeld">
        <legend>
          <h5 v-if="!selectedMeld" class="legend-title">
            {{$t('new_nameld')}}
          </h5>
          <h5 v-else class="legend-title">
            {{$t('selected_nameld')}}
          </h5>
        </legend>
        <fieldset :disabled="!canEdit">
          <div class="row">
            <div class="standard-form-group">
              <date-input
                :name="$t('date_of_nameld_data')"
                input-id="lsd-meld-date"
                v-model="editState.sodiumMeld.dateOfNaMeldData"
                rules="required"
              />
            </div>
            <div class="standard-form-group">
              <!--
                TODO: TECH_DEBT: Refactor initialization of Liver Specific Details and its sub-section components.
                The underlying issue is that LiverSpecificDetails is mutating this form's editState without using the
                buildSodiumMeldForm method defined in this MeldSection component.
              -->
              <date-input
                :name="$t('nameld_expiry_date')"
                input-id="lsd-meld-expiry-date"
                v-model="editState.sodiumMeld.naMeldExpiryDate"
                :calculated="true"
                :disabled="true"
              />
            </div>
            <div class="standard-form-group">
              <date-input
                :name="$t('nameld_entry_date')"
                input-id="lsd-meld-created-at"
                v-model="editState.sodiumMeld.naMeldCreatedAt"
                :disabled="true"
              />
            </div>
            <div class="standard-form-group-6column-xlarge-only">
              <number-input
                :name="$t('total_bilirubin_spec')"
                input-id="lsd-meld-bilirubin"
                v-model="editState.sodiumMeld.totalBilirubin"
                step="0.1"
                rules="required"
              />
            </div>
            <div class="standard-form-group">
              <number-input
                :name="$t('inr_spec')"
                input-id="lsd-meld-inr"
                v-model="editState.sodiumMeld.inr"
                step="0.1"
                rules="required"
                :warningParams="{ min: 0.9, max: 7.5, message: $t('warning_0.9_7.5').toString()}"
              />
            </div>
            <div class="standard-form-group-6column-xlarge-only">
              <number-input
                :name="$t('serum_sodium_spec')"
                input-id="lsd-meld-sodium"
                v-model="editState.sodiumMeld.serumSodium"
                step="0.1"
                rules="required"
                :warningParams="{ min: 125.0, max: 140.0, message: $t('warning_125.0_140.0').toString()}"
              />
            </div>
            <div class="standard-form-group-large">
              <number-input
                :name="$t('sodium_creatine_spec')"
                input-id="lsd-meld-creatine"
                v-model="editState.sodiumMeld.serumCreatinine"
                step="0.1"
                rules="required"
                :warningParams="{ min: 1.0, max: 884.0, message: $t('warning_1.0_884.0').toString()}"
              />
            </div>
            <div class="standard-form-group">
              <checkbox-input
                input-id="lsd-meld-dialysis"
                :labelName="$t('on_dialysis')"
                v-model="editState.sodiumMeld.onDialysis"
                :label="$t('yes')"
              />
            </div>
            <div class="standard-form-group-large">
              <text-input
                :name="$t('calculated_nameld_score')"
                input-id="lsd-meld-nameld"
                v-model="editState.sodiumMeld.naMeldScore"
                :calculated="true"
                :disabled="true"
              />
            </div>
          </div>
          <div class="row d-flex" v-if="checkPropExists('liver_meld_labs.comments')">
            <div class="measurements-comment-group">
              <text-area-input
                input-id="lsd-meld-comments"
                rule-key="liver_meld_labs.comments"
                :name="$t('comments')"
                v-model="editState.sodiumMeld.comments"
              />
            </div>
          </div>
        </fieldset>
      </fieldset>
    </template>
  </sub-section>
</template>

<i18n src="@/components/organs/liver/_locales/MeldSection.json"></i18n>

<script lang="ts">
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { Getter, State }  from 'vuex-class';
import { RootState, ObjectId } from '@/store/types';
import { Recipient } from '@/store/recipients/types';
import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import CardSection from '@/components/shared/CardSection.vue';
import NumberInput from '@/components/shared/NumberInput.vue';
import { LiverMeldLab } from '@/store/organSpecificDetails/types';
import { RecipientJourney } from '@/store/recipientJourney/types';
import { LiverDetails } from '@/store/organSpecificDetails/types';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import DiseasesSection from '@/components/organs/liver/DiseasesSection.vue';
import TherapiesSection from '@/components/organs/liver/TherapiesSection.vue';
import { LiverSpecificForm } from '@/components/organs/liver/LiverSpecificDetails.vue';

export interface SodiumMeldForm {
  dateOfNaMeldData?: string;
  naMeldCreatedAt?: string;
  naMeldExpiryDate?: string;
  totalBilirubin?: number;
  inr?: number;
  serumSodium?: number;
  serumCreatinine?: number;
  onDialysis?: boolean;
  naMeldScore?: number;
  comments?: string;
}

interface LiverMeldRow {
  _id?: ObjectId;
  dateOfNaMeldData?: string;
  naMeldCreatedAt?: string;
  naMeldExpiryDate?: string;
  totalBilirubin?: string;
  inr?: string;
  serumSodium?: string;
  serumCreatinine?: string;
  onDialysis?: string;
  naMeldScore?: string;
}

@Component({
  components: {
    TextInput,
    DateInput,
    SubSection,
    NumberInput,
    TextAreaInput,
    CheckboxInput,
  }
})

export default class MeldSection extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.pageState.currentPage.liverDetails) editState!: LiverSpecificForm;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.organSpecificDetails.liverMelds) private liverMelds!: LiverMeldLab[];
  @State(state => state.organSpecificDetails.selectedLiverMeld) private selectedMeld!: LiverMeldLab;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('isLastEntry', { namespace: 'utilities' }) private isLastEntry!: (id: ObjectId, entries: any[]) => boolean;
  @Getter('checkPropExists', { namespace: 'validations' }) checkPropExists!: (ruleKey: string) => boolean;

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  /**
   * Return true if we can edit the selected measurement
   *
   * @returns {boolean} true if we can edit
   */
  get canEdit(): boolean{
    if (this.newJourney || this.journey.completed) {
      return false;
    }

    if (!!this.selectedMeld && !!this.liverMelds) {
      if (this.selectedMeld._id) {
        return this.isLastEntry(this.selectedMeld._id, this.liverMelds);
      }
    }
    return true;
  }

  /**
   * Emits a loaded event when the component has finished mounting
   *
   * @emits loaded
   */
  private mounted(): void {
    this.initializeSodiumMeldForm();
    this.$emit('loaded', 'meldSection');
  }

  /**
   * Gets table data for recipient liver NaMELD labs
   *
   * @returns {LiverMeldRow[]} NaMELD table data
   */
  get meldRows(): LiverMeldRow[] {
    if (!this.liverMelds) {
      return [];
    }  
    return this.liverMelds.map((lab: LiverMeldLab) => {
      return {
        _id: lab._id,
        dateOfNaMeldData: this.parseDisplayDateUi(lab.test_date) || '-',
        naMeldCreatedAt: this.parseDisplayDateUiFromDateTime(lab.created_at) || '-',
        naMeldExpiryDate: this.parseDisplayDateUi(lab.expiry_date) || '-',
        totalBilirubin: lab.serum_bilirubin == undefined ? '-' : lab.serum_bilirubin.toFixed(1).toString(),
        inr: lab.inr == undefined ? '-' : lab.inr.toFixed(1).toString(),
        serumSodium: lab.serum_sodium == undefined ? '-' : lab.serum_sodium.toFixed(1).toString(),
        serumCreatinine: lab.serum_creatinine == undefined ? '-' : lab.serum_creatinine.toFixed(1).toString(),
        onDialysis:  lab.on_dialysis ? `${this.$t('yes')}` : `${this.$t('no')}`,
        naMeldScore: lab.na_meld == undefined ? '-' : lab.na_meld.toString(),
      };
    });
  }

  /**
   * Loads a form edit state based on the lab, or a new state if there is none
   *
   * @param lab Liver MELD lab fetched from API, or undefined
   */
  public initializeSodiumMeldForm(lab?: LiverMeldLab): void {

    // Clear table selections from subsection component
    this.$store.commit('organSpecificDetails/selectLiverMeld', lab);
    // Initialize subsection component form edit states
    this.$store.commit('pageState/set', {
      pageKey: 'liverDetails',
      componentKey: 'sodiumMeld',
      value: this.buildSodiumMeldForm(lab),
    });

    // Load the 'new' or 'edit' validation rules
    this.loadValidationRules();
  }

  /**
   * Returns Sodium Meld form edit state based on recipient Sodium Meld lab document
   *
   * @param lab NaMELD lab document fetched from API
   * @returns {SodiumMeldForm} editable NaMELD form state
   */
  public buildSodiumMeldForm(lab?: LiverMeldLab): SodiumMeldForm {
    if (!lab) {
      return {
        dateOfNaMeldData: this.currentDateUi(),
        onDialysis: false,
      };
    }

    return {
      dateOfNaMeldData: this.parseDateUi(lab.test_date),
      naMeldCreatedAt: this.parseDateUiFromDateTime(lab.created_at),
      naMeldExpiryDate: this.parseDateUi(lab.expiry_date),
      totalBilirubin: lab.serum_bilirubin === undefined ? undefined : Number( lab.serum_bilirubin.toFixed(1) ),
      inr: lab.inr === undefined ? undefined : Number( lab.inr.toFixed(1) ),
      serumSodium: lab.serum_sodium === undefined ? undefined : Number( lab.serum_sodium.toFixed(1) ),
      serumCreatinine: lab.serum_creatinine === undefined ? undefined : Number( lab.serum_creatinine.toFixed(1) ),
      onDialysis: lab.on_dialysis,
      naMeldScore: lab.na_meld === undefined ? undefined :  Number( lab.na_meld.toFixed(1) ),
      comments: lab.comments,
    };
  }

  /**
   * Gets table configuration for the Sodium MELD subsection table
   *
   * @returns {TableConfig} table configuration
   */
  get meldTableConfig(): TableConfig {
    return {
      data: this.meldRows,
      columns: [
        { label: `${this.$t('date_of_nameld_data')}`, field: 'dateOfNaMeldData', width: '20%' },
        { label: `${this.$t('nameld_expiry_date')}`, field: 'naMeldExpiryDate', width: '10%' },
        { label: `${this.$t('nameld_entry_date')}`, field: 'naMeldCreatedAt', width: '10%' },
        { label: `${this.$t('total_bilirubin')}`, field: 'totalBilirubin', width: '10%' },
        { label: `${this.$t('inr')}`, field: 'inr', width: '10%' },
        { label: `${this.$t('serum_sodium')}`, field: 'serumSodium', width: '10%' },
        { label: `${this.$t('sodium_creatine')}`, field: 'serumCreatinine', width: '10%' },
        { label: `${this.$t('on_dialysis')}`, field: 'onDialysis', width: '10%' },
        { label: `${this.$t('calculated_nameld_score')}`, field: 'naMeldScore', width: '10%' },
      ],
      empty: `${this.$t('use_the_form_below_to_add_a_new_nameld')}`,
      createButton: this.canEdit,
      createText: `${this.$t('create_nameld')}`,
      pagination: true
    };
  }

  // API response keys on the left, id for our UI on the right
  public idLookup: IdLookup = {
    'liver_meld_lab.test_date'        : 'lsd-meld-date',
    'liver_meld_lab.created_at'       : 'lsd-meld-created-at',
    'liver_meld_lab.expiry_date'      : 'lsd-meld-expiry-date',
    'liver_meld_lab.serum_bilirubin'  : 'lsd-meld-bilirubin',
    'liver_meld_lab.inr'              : 'lsd-meld-inr',
    'liver_meld_lab.serum_sodium'     : 'lsd-meld-sodium',
    'liver_meld_lab.serum_creatinine' : 'lsd-meld-creatine',
    'liver_meld_lab.on_dialysis'      : 'lsd-meld-dialysis',
    'liver_meld_lab.comments'         : 'lsd-meld-comments',
  };

  // PRIVATE

  /**
   * Builds form edit state based on selected document
   *
   * @param event select event
   */
  private selectMeld(event: any): void {
    // Get selected ID from the table row reference in the select event
    const selectedId = event.row._id && event.row._id.$oid ? event.row._id!.$oid : undefined;
    if (!selectedId || !this.liverMelds) {
      return;
    }
    // Find the selected source document
    const found = this.liverMelds.find((each: LiverMeldLab) => {
      return each._id && each._id.$oid === selectedId;
    });
    if (!found) {
      return;
    }
    // Build form state based on selected document
    this.initializeSodiumMeldForm(found);
  }

  /**
   * Build an empty new form edit state
   */
  private createMeld(): void {
    // Build form state
    this.initializeSodiumMeldForm();
  }

  /**
   * Saves current form state for Sodium MELD
   */
  private saveSodiumMeld(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveSodiumMeld as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'sodiumMeld');
    // Generate payload based on current edit state
    const sodiumMeldPayload = {
      id: !!this.selectedMeld ? this.selectedMeld._id : undefined,
      recipientId: this.recipientId,
      sodiumMeld: this.extractSodiumMeldPatch(),
    };
    // Dispatch save action and register the response
    this.$store.dispatch('organSpecificDetails/saveSodiumMeld', sodiumMeldPayload).then((success: SaveResult) => {
      // If successful reload table data, clear form, and show success notification
      this.reload();
      saveProvider.registerSaveResult(success);
      // Clear any errors
      this.$emit('clear');
      // Request card-section to reload sub-sections related to Liver Scores simultaneously
      this.$emit('reloadLiverExceptionPoints');
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  /**
   * Reload list of Liver MELDs, the Recipient and Liver Journey, as well as any new Waitlist Decisions.
   *
   * This is needed because saving a Liver MELD Labs can affect the Journey's Waitlist Factors.
   */
  private reload(): void {
    this.$store.dispatch('organSpecificDetails/loadLiverMelds', this.recipientId).then(() => {
      this.initializeSodiumMeldForm();
    });
    // Reload recipient and journey for latest Waitlist Factors
    this.$store.dispatch('recipients/get', this.recipientId).then(() => {
      this.$store.dispatch('journeyState/getJourney', this.journeyId).then(() => {
        // Reload all waitlist decisions for the recipient's journey
        const opts = {
          journeyId: this.journeyId,
          recipientId: this.recipientId,
        };
        this.$store.dispatch('journeyState/loadWaitlistDecisions', opts);
        // Reload Wait Time
        this.$store.dispatch('journeyState/loadJourneyDurations', opts);
      });
    });
  }

  // Load the 'new' or 'edit' validation rules based on current selection
  private loadValidationRules(): void {
    const selectedId = this.selectedMeld?._id?.$oid;
    if (selectedId) {
      // Load 'edit' validation rules
      this.$store.dispatch(
        'validations/loadEdit',
        {
          view: `recipients/${this.recipientId}/liver_meld_labs`,
          clientId: selectedId,
          action: 'edit',
          prefix: 'liver_meld_labs'
        }
      );
    } else {
      // Load 'new' validation rules
      this.$store.dispatch(
        'validations/loadNew',
        {
          view: `recipients/${this.recipientId}/liver_meld_labs`,
          action: 'new',
          prefix: 'liver_meld_labs'
        }
      );
    }
  }

  /**
   * Returns a patch object containing changes for a Liver Meld Lab document
   *
   * For a document that does not yet exist, the object contains all data to be saved
   *
   * @returns {LiverMeldLab} changes to save
   */
  private extractSodiumMeldPatch(): LiverMeldLab {
    if (!this.editState || !this.editState.sodiumMeld) {
      return {};
    }
    const sodiumMeld = this.editState.sodiumMeld;
    return {
      test_date: this.sanitizeDateApi(sodiumMeld.dateOfNaMeldData),
      expiry_date: this.sanitizeDateApi(sodiumMeld.naMeldExpiryDate),
      inr: sodiumMeld.inr,
      on_dialysis: sodiumMeld.onDialysis ? true : false,
      serum_bilirubin: sodiumMeld.totalBilirubin,
      serum_creatinine: sodiumMeld.serumCreatinine,
      serum_sodium: sodiumMeld.serumSodium,
      comments: sodiumMeld.comments,
    };
  }
}
</script>
