<template>
  <modal-section
    modalId="checklist-history-modal"
    ref="checklistHistoryModal"
    :centered="true">
    <template v-slot:title v-if="records">
      {{$t('checklist_history_title')}}
    </template>
    <template v-slot:body>
      <p>{{$t('checklist_history_title_table')}}</p>
      <div class="hr-break"></div>
      <sub-section
        sub-section-id="list-donors"
        title=""
        mode="remote"
        :total-records="checklistHistoryTableConfig.data.count"
        :table-config="checklistHistoryTableConfig"
        >
      </sub-section>
    </template>
    <template v-slot:footer>
      <div class="modal-footer-body">
        <button type="button" data-dismiss="modal" :aria-label="$t('close')" class="btn btn-secondary" @click="closeModal()">
          {{ $t('close') }}
        </button>
      </div>
    </template>
  </modal-section>
</template>

<i18n src="@/components/_locales/common.json"></i18n>
<i18n src="@/components/allocations/_locales/common.json"></i18n>
<i18n src="@/components/allocations/offers/_locales/common.json"></i18n>
<i18n src="@/components/allocations/offers/_locales/ChecklistHistoryModal.json"></i18n>

<script lang="ts">
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { Getter, State } from 'vuex-class';
import SubSection from '@/components/shared/SubSection.vue';
import ModalSection from '@/components/shared/ModalSection.vue';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { LivingDonor } from '@/store/livingDonors/types';
import { GenericCodeValue } from '@/store/types';
import { LivingAllocationRecipient, LivingAllocationOfferTypeValues, LivingAllocationResponse } from '@/store/livingAllocations/types';

interface LivingAllocationOfferHistoryPageState {
  title: string,
  allocationId: string,
  records: LivingAllocationRecipient[]
}

@Component({
  components: {
    ModalSection,
    SubSection
  }
})
export default class ChecklistHistoryModal extends mixins(DateUtilsMixin) {
  @State(state => state.livingDonors.selectedLivingDonor) private livingDonor!: LivingDonor;
  @State(state => state.currentUser) private currentUser!: any;

  @Getter('selectedAllocation', { namespace: 'livingAllocations' }) private allocation!: any;
  @Getter('offerResponses', { namespace: 'lookups' }) private offerResponses!: GenericCodeValue[];
  @Getter('offerResponseCode', { namespace: 'lookups' }) private offerResponseCode!: (code: string|null) => string;
  @Getter('noOfferReasonCategory', { namespace: 'lookups' }) private noOfferReasonCategory!: (code: string) => string;
  @Getter('noOfferReasonCode', { namespace: 'lookups' }) private noOfferReasonCode!: (category: string, code: string) => string;
  @Getter('offerResponseReason', { namespace: 'livingAllocations' }) private offerResponseReason!: (responseCode: string|null, responseCategoryCode: number|null, reasonCode: number|null, offerResponses: any[], organCode: number) => string|undefined;
  @Getter('getChecklistHistory', { namespace: 'livingAllocations' }) private getChecklistHistory!: any;

  records: any[] = [];

  /**
   * Gets configuration for the vue good table configuation
   *
   * @returns {TableConfig} Offer History Table configuration
   */
  get checklistHistoryTableConfig(): TableConfig {
    return {
      data: this.checklistHistoryRows || [],
      columns: [
        { label: 'Checklist Type', field: 'checklist_type' },
        { label: 'Checklist Status', field: 'confirmed' },
        { label: 'CSC1', field: 'csc1_confirmed_userid' },
        { label: 'CSC1 date stamp signoff', field: 'csc1_confirmed_date' },
        { label: 'CSC2', field: 'csc2_confirmed_userid' },
        { label: 'CSC2 date stamp signoff', field: 'csc2_confirmed_date' },
      ],
      empty: 'There is no checklist history',
    };
  }

  /**
   * Get all rows for table data
   *
   * @returns {any[]} Offer History Table configuration
   */
  get checklistHistoryRows(): any[] {
    // if not an array OR there are no records
    if (!Array.isArray(this.records) || this.records.length <= 0) {
      return [];
    }
    const results: any[] = [];
    const recordsCount = this.records.length;

    this.records.forEach((record: any, index: number) => {
      const row: any = {
        checklist_type: this.$t(index < recordsCount - 2 ? 're' : record.checklist_type),
        confirmed: record.confirmed ? this.$t('confirmed') : this.$t('not confirmed'),

        csc1_confirmed_userid: record.details.csc1_confirmed_userid,
        csc1_confirmed_date: record.details.csc1_confirmed_date ? this.parseDisplayDateTimeUiFromDateTime(record.details.csc1_confirmed_date) : '-',

        csc2_confirmed_userid: record.details.csc2_confirmed_userid,
        csc2_confirmed_date: record.details.csc2_confirmed_date ? this.parseDisplayDateTimeUiFromDateTime(record.details.csc2_confirmed_date) : '-',
      };
      results.push(row);
    });
    return results || [];
  }

  public initializeModal(): void {
    this.records = this.getChecklistHistory;

    const targetModal = this.$refs.checklistHistoryModal as ModalSection;
    targetModal.toggleModal();
  }

  // Toggle modal
  private closeModal(): void {
    (this.$refs.checklistHistoryModal as ModalSection).hideModal();
  }
}
</script>
