<template>
  <base-widget :title="$t('active_primary_offers')" :preferences="preferences.primary" v-if="editState">
    <template v-slot:icon>
      <div class="circle dashboard-icon circle-primary-offer">P</div>
    </template>
    <template v-slot:linkID>
      <a href="#idPrimaryOffers" class="nav-link card-header-btn py-0" data-toggle="collapse">
        <font-awesome-icon :icon="['far', 'cog']" data-toggle="tooltip" :title="$t('customize_panel')" />
      </a>
      <a href="#bodyPrimaryOffers" class="nav-link card-header-btn py-0" data-toggle="collapse">
        <font-awesome-icon :icon="['far', 'caret-down']" data-toggle="tooltip" :title="$t('collapse_panel')" />
      </a>
    </template>
    <template v-slot:options>
      <div class="card-body card-options p-0 collapse" id="idPrimaryOffers">
        <div class="customize-panel">
          <h4>{{$t('panel_options')}}</h4>
          <!-- Widget options-->
          <slot name="options">
            <form>
              <div class="form-row mb-3">
                <div class="col-sm-6">
                  <select-input
                    name="panel-style"
                    select-id='dashPrimaryStyle'
                    v-model="editState.style"
                    :label="$t('panel_style')"
                    :options="getPanelStyles"
                    />
                </div>
              </div>
              <div class="sub-divider"></div>
              <div class="form-row mb-3">
                <div class="col-sm-12 col-lg-8">
                  <checkbox-input
                    input-id='dashPrimaryVisible'
                    v-model="editState.visible"
                    :label="$t('show_this_panel')"
                    :disabled="requiredWidget"
                  />
                </div>
              </div>
              <div class="sub-divider"></div>
              <div class="col-sm-12" v-if="errorMessage">
                <p>{{errorMessage}}</p>
              </div>
              <button
                type="button"
                class="btn btn-sm btn-wide btn-success"
                data-target="#idPrimaryOffers"
                @click="saveSettings()"
              >
                {{$t('save')}}
              </button>
            </form>
          </slot>
        </div>
      </div>
    </template>
    <template v-slot:widget-contents v-if="isWidgetLoaded">
      <div class="card-body collapse show" id="bodyPrimaryOffers">
        <sub-section
          sub-section-id="primaryOffersTable"
          :lookupsToLoad="lookupsToLoad"
          title=""
          :total-records="getPrimaryOffers.count"
          :table-config="tableConfig"
          :pagination-options="tableConfig.paginationOptions"
          v-if="getPrimaryOffers"
        >
          <template v-slot:table-cell="props">
            <template v-if="props.column.field == 'deceased_donor_id'">
              <router-link
                class="table-link"
                :to="{ name: 'edit-deceased-donor', params: { id: props.row.donor_client_id } }"
                :title="$t('link_to_donor_page')"
                :alt="props.row.deceased_donor_id"
                target="_blank"
              >
                {{ props.row.deceased_donor_id ? props.formattedRow[props.column.field] : $t('unknown').toString() }}
              </router-link>
            </template>
            <template v-else-if="props.column.field == 'recipient_client_id'">
              <router-link
                class="table-link"
                :to="{ name: 'edit-recipient', params: { id: props.row.recipient_client_id } }"
                :title="$t('link_to_recipient_page')"
                :alt="props.row.recipient_client_id"
                target="_blank"
                v-if="props.row.recipient_client_id"
              >
                {{props.formattedRow[props.column.field] }}
              </router-link>
            </template>
            <template v-else-if="props.column.field == 'client_id'">
              <a
                :href="getDonorOrganUrl({ id: props.row.donor_client_id, organ_code: props.row.organ_code, allocation_type: props.row.allocation_type })"
                :title="$t('allocation_id')"
                target="_blank"
              >
                {{props.row.client_id}}
              </a>
            </template>
            <template v-else-if="props.column.field == 'offer_response_code'">
              {{parseOfferResponse(props.row.offer_response_code)}}
            </template>
          </template>
          <template slot="emptystate">
            {{$t('no_active_primary_offer')}}s
          </template>
        </sub-section>
      </div>
    </template>
  </base-widget>
</template>

<i18n src="@/components/dashboard/widgets/_locales/WidgetPrimaryOffers.json"></i18n>

<script lang="ts">
import store from '@/store';
import { Getter, State } from 'vuex-class';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import BaseWidget from '@/components/dashboard/widgets/_BaseWidget.vue';
import { DashboardState, DashboardSettingState, WidgetSettings } from '@/store/dashboard/types';
import { TableConfig } from '@/types';
import { VueGoodTable } from 'vue-good-table';
import TextInput from '@/components/shared/TextInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import { GenericCodeValue } from '@/store/types';
import SubSection from '@/components/shared/SubSection.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { Format } from '@/store/utilities/types';

@Component({
  components: {
    BaseWidget,
    VueGoodTable,
    SubSection,
    TextInput,
    CheckboxInput,
    SelectInput
  }
})
export default class WidgetPrimaryOffers extends Vue {
  @Prop({ default: false }) requiredWidget!: boolean;

  @State(state => state.users.user.preferences) private preferences!: any;
  @State(state => state.pageState.currentPage.dashPrimary) private editState!: any;

  @Getter('getPreferences', { namespace: 'users'}) getPreferences!: any;
  @Getter('getPrimaryOffers', { namespace: 'dashboard' }) getPrimaryOffers!: any[];
  @Getter('getPanelStyles', { namespace: 'dashboard' }) getPanelStyles!: any[];
  @Getter('getDateFormat', { namespace: 'features' }) private getDateFormat!: string;
  @Getter('offerResponses', { namespace: 'lookups' }) private offerResponses!: GenericCodeValue[];

  public errorMessage = null;

  public lookupsToLoad = ['offer_responses'];

  private isWidgetLoaded = false;

  getDonorOrganUrl(params: any) {
    if (params.allocation_type) {
      const location = this.$router.resolve({ name: 'deceased-donor-organ-option', params: { id: params.id, organ_code: params.organ_code, option: params.allocation_type } });
      return location.href;
    } else {
      const location = this.$router.resolve({ name: 'deceased-donor-organ', params: { id: params.id, organ_code: params.organ_code } });
      return location.href;
    }
  }

  mounted(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _vm = this;
    setTimeout(() => {
      _vm.loadIfStillViewingDashboard();
    }, 5000);
  }

  /**
   * NOTE: we are relying on checking route 'name' to handle a special case for widget loading,
   * so we need to ensure this route name check matches dashboard route name in router.ts
   */
  loadIfStillViewingDashboard(): void {
    const viewingDashboard = this.$route.name === 'index';
    if (!viewingDashboard) return;

    Promise.all([
      this.$store.dispatch('dashboard/loadPrimaryOffers')
    ]).finally(() => {
      this.initializeWidget();
    });
  }

  // Setup temporary edit state for unsaved widget settings form fields
  initializeWidget(): void {
    this.errorMessage = null; // clear error message
    const preferences = this.getPreferences;
    this.$store.commit('pageState/set', {
      pageKey: 'dashPrimary',
      value: this.buildPrimaryEditState(preferences),
    });
    this.isWidgetLoaded = true;
  }

  // Commit edit state field model values to vue-x store
  saveSettings(): void {
    const newState = this.extractDashboardSettings(this.preferences, this.editState);
    this.$store.dispatch('users/savePreferences', { preferences: newState }).then((success: SaveResult) => {
      // If successful dismiss dialog
      this.dismiss();
      this.initializeWidget();
      // Reinitialize if overall dashboard settings change
      this.$emit('reloadDashboard');
    }).catch((error: any) => {
      // Show error notification
      this.errorMessage = error.message;
    });
  }

  public dismiss(): void {
    $("#idPrimaryOffers").collapse('toggle');
  }

  // Build edit state based on overall dashboard settings
  buildPrimaryEditState(preferences: DashboardSettingState): WidgetSettings {
    return Object.assign({}, preferences.primary);
  }

  // Build new overall dashboard settings with new settings for this specific widget
  extractDashboardSettings(currentSettings: DashboardSettingState, editState: WidgetSettings): DashboardSettingState {
    const newSettings = Object.assign({ primary: {} }, currentSettings);
    newSettings.primary = {
      style: editState.style,
      visible: editState.visible
    };
    return newSettings;
  }

  // String representation of the Offer Response using the Offer Response Lookup
  private parseOfferResponse(offerResponseCode: any): string {
    const responseOffer = this.offerResponses.find((offerResponse: GenericCodeValue) => offerResponse.code == offerResponseCode);
    return responseOffer?.value || 'Pending';
  }

  /**
   * Gets configuration for the table
   *
   * @returns {TableConfig} Configuration for the table
   */
  get tableConfig(): TableConfig {
    const primaryOffersData: any[] = [
      {
        allocation_type: null,
        client_id: 552288,
        datetime_expires: "11-12-2023 19:00",
        datetime_offered: "11-12-2023 14:00",
        deceased_donor_id: 552288,
        donor_client_id: 137331,
        offer_response_code: "A",
        offer_type_code: "P",
        recipient_client_id: 62154,
        offered_organ: "Liver",
        offered_organ_code: 1,
        offered_organ_specification_code: 101,
        program_identifier: "TGH",
        organ: "Liver",
        organ_code: 1
      },
      ...this.getPrimaryOffers
    ];

    return {
      data: primaryOffersData,
      columns: [
        { label: this.$t('donor_id').toString(), field: 'deceased_donor_id', sortable: true, type: 'number', thClass: 'vgt-left-align', tdClass: 'vgt-left-align' },
        // { label: this.$t('transplant_program').toString(), field: 'program_identifier', sortable: false },
        { label: this.$t('allocation_id').toString(), field: 'client_id', type: 'number', sortable: false, thClass: 'vgt-left-align', tdClass: 'vgt-left-align' },
        { label: this.$t('organ').toString(), field: 'offered_organ' },
        { label: this.$t('recipient_id').toString(), field: 'recipient_client_id', type: 'number', sortable: false, thClass: 'vgt-left-align', tdClass: 'vgt-left-align' },
        { label: this.$t('offer_time').toString(), field: 'datetime_offered', sortable: true, type: 'date', dateInputFormat: Format(this.getDateFormat).DATE_TIME_FORMAT, dateOutputFormat: Format(this.getDateFormat).DATE_TIME_FORMAT},
        // { label: this.$t('offer_expiry_time').toString(), field: 'datetime_expires', sortable: true, type: 'date', dateInputFormat: Format(this.getDateFormat).DATE_TIME_FORMAT, dateOutputFormat: Format(this.getDateFormat).DATE_TIME_FORMAT },
        { label: this.$t('offer_response').toString(), field: 'offer_response_code', sortable: false }
      ],
      sortOptions: {
        enabled: true,
        initialSortBy: {field: 'datetime_expires', type: 'desc'}
      },
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPage: 5,
        mode: 'records',
        perPageDropdown: [5, 10, 25, 100],
        dropdownAllowAll: false,
        nextLabel: '',
        prevLabel: '',
        rowsPerPageLabel: this.$t('results_per_page').toString(),
      }
    };
  }
}
</script>
