import { render, staticRenderFns } from "./HlaSerumSummaryReview.vue?vue&type=template&id=fbdf8c8a"
import script from "./HlaSerumSummaryReview.vue?vue&type=script&lang=ts"
export * from "./HlaSerumSummaryReview.vue?vue&type=script&lang=ts"
import style0 from "./HlaSerumSummaryReview.vue?vue&type=style&index=0&id=fbdf8c8a&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./_locales/common.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fhla%2FHlaSerumSummaryReview.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "./_locales/HlaSerumSummaryReview.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fhla%2FHlaSerumSummaryReview.vue&external"
if (typeof block1 === 'function') block1(component)

export default component.exports