<template>
  <sub-section
    sub-section-id="kidney-transplant-details"
    :save-button="canEdit"
    :save-button-text="$t('save_transplant_details')"
    @save="savePatch()"
    @loaded="loaded()"
    ref="saveTransplantDetails"
    :disabled="!isGroupWriteable('journey_transplant_details')"
    :confirmation="confirmationText"
  >
    <template v-slot:contents>
      <div class="hr-break" />
      <fieldset :disabled="newJourney">
        <legend>
          <h5 class="legend-title">
            {{$t('or_details')}}
          </h5>
        </legend>
        <template v-if="!editState || !editState.transplantDetails || !editState.transplantDetails.organTransplantDetails">
          {{$t('loading')}}
        </template>
        <template v-else>
          <div class="row">
            <div class="standard-form-group">
              <select-input
                ruleKey="organ_specification_code"
                select-id="transplantType"
                :name="$t('transplant_type')"
                v-model="editState.transplantDetails.organTransplantDetails.transplantType"
                :options="transplantTypeLookup"
              />
            </div>
            <div class="standard-form-group">
              <date-input
                ruleKey="transplant_start_date"
                input-id="transplantStartDate"
                :name="$t('transplant_start_date')"
                rules="required_if_filled:@transplantStartTime"
                :cross-values="{ transplantStartTime: editState.transplantDetails.organTransplantDetails.transplantStartTime }"
                v-model="editState.transplantDetails.organTransplantDetails.transplantStartDate"
              />
            </div>
            <div class="standard-form-group">
              <time-input
                ruleKey="transplant_start_date"
                inputId="transplantStartTime"
                :name="$t('transplant_start_time')"
                rules="required_if_filled:@transplantStartDate"
                :cross-values="{ transplantStartDate: editState.transplantDetails.organTransplantDetails.transplantStartDate }"
                v-model="editState.transplantDetails.organTransplantDetails.transplantStartTime"
              />
            </div>
            <div class="standard-form-group">
              <date-input
                input-id="transplantEndDate"
                name="Transplant End Date"
                v-model="transplant_end_date"
              />
            </div>
            <div class="standard-form-group">
               <time-input
                inputId="transplantEndTime"
                name="Transplant End Time"
                v-model="transplant_end_time"        
              />
            </div>
            <div class="row-break d-none d-xl-block"></div>
            <div class="standard-form-group-large">
              <date-input
                ruleKey="end_cold_storage_date_left_kid"
                input-id="removedFromColdDateLeft"
                :name="$t('removed_from_cold_date_left_kidney')"
                rules="required_if_filled:@removedFromColdTimeLeft"
                :cross-values="{ removedFromColdTimeLeft: editState.transplantDetails.organTransplantDetails.removedFromColdTimeLeft }"
                v-model="editState.transplantDetails.organTransplantDetails.removedFromColdDateLeft"
              />
            </div>
            <div class="standard-form-group-large">
              <time-input
                ruleKey="end_cold_storage_date_left_kid"
                inputId="removedFromColdTimeLeft"
                :name="$t('removed_from_cold_time_left_kidney')"
                rules="required_if_filled:@removedFromColdDateLeft"
                :cross-values="{ removedFromColdDateLeft: editState.transplantDetails.organTransplantDetails.removedFromColdDateLeft }"
                v-model="editState.transplantDetails.organTransplantDetails.removedFromColdTimeLeft"
              />
            </div>
            <div class="standard-form-group-large">
              <date-input
                ruleKey="end_cold_storage_date_right_kid"
                input-id="removedFromColdDateRight"
                :name="$t('removed_from_cold_date_right_kidney')"
                rules="required_if_filled:@removedFromColdTimeRight"
                :cross-values="{ removedFromColdTimeRight: editState.transplantDetails.organTransplantDetails.removedFromColdTimeRight }"
                v-model="editState.transplantDetails.organTransplantDetails.removedFromColdDateRight"
              />
            </div>
            <div class="standard-form-group-large">
              <time-input
                ruleKey="end_cold_storage_date_right_kid"
                inputId="removedFromColdTimeRight"
                :name="$t('removed_from_cold_time_right_kidney')"
                rules="required_if_filled:@removedFromColdDateRight"
                :cross-values="{ removedFromColdDateRight: editState.transplantDetails.organTransplantDetails.removedFromColdDateRight }"
                v-model="editState.transplantDetails.organTransplantDetails.removedFromColdTimeRight"
              />
            </div>
            <div class="standard-form-group-6column-xlarge-only">
              <date-input
                ruleKey="clamp_removal_date_left_kid"
                input-id="clampOffDateLeft"
                :name="$t('clamp_removal_date_left_kidney')"
                rules="required_if_filled:@clampOffTimeLeft"
                :cross-values="{ clampOffTimeLeft: editState.transplantDetails.organTransplantDetails.clampOffTimeLeft }"
                v-model="editState.transplantDetails.organTransplantDetails.clampOffDateLeft"
              />
            </div>
            <div class="standard-form-group-large">
              <time-input
                ruleKey="clamp_removal_date_left_kid"
                inputId="clampOffTimeLeft"
                :name="$t('clamp_removal_time_left_kidney')"
                rules="required_if_filled:@clampOffDateLeft"
                :cross-values="{ clampOffDateLeft: editState.transplantDetails.organTransplantDetails.clampOffDateLeft }"
                v-model="editState.transplantDetails.organTransplantDetails.clampOffTimeLeft"
              />
            </div>
            <div class="standard-form-group-6column-xlarge-only">
              <date-input
                ruleKey="clamp_removal_date_right_kid"
                input-id="clampOffDateRight"
                :name="$t('clamp_removal_date_right_kidney')"
                rules="required_if_filled:@clampOffTimeRight"
                :cross-values="{ clampOffTimeRight: editState.transplantDetails.organTransplantDetails.clampOffTimeRight }"
                v-model="editState.transplantDetails.organTransplantDetails.clampOffDateRight"
              />
            </div>
            <div class="standard-form-group-large">
              <time-input
                ruleKey="clamp_removal_date_right_kid"
                inputId="clampOffTimeRight"
                :name="$t('clamp_removal_time_right_kidney')"
                rules="required_if_filled:@clampOffDateRight"
                :cross-values="{ clampOffDateRight: editState.transplantDetails.organTransplantDetails.clampOffDateRight }"
                v-model="editState.transplantDetails.organTransplantDetails.clampOffTimeRight"
              />
            </div>
            <div class="standard-form-group-large">
              <text-input
                ruleKey="rewarm_time_left_kid"
                inputId="rewarmTimeLeft"
                :name="$t('rewarm_time_left_kidney')"
                v-model="editState.transplantDetails.organTransplantDetails.rewarmTimeLeft"
                :calculated="true"
                :disabled="true"
                :append="true"
                appendText="hrs"
              />
            </div>
            <div class="standard-form-group-large">
              <text-input
                ruleKey="rewarm_time_right_kid"
                inputId="rewarmTimeRight"
                :name="$t('rewarm_time_right_kidney')"
                v-model="editState.transplantDetails.organTransplantDetails.rewarmTimeRight"
                :calculated="true"
                :disabled="true"
                :append="true"
                appendText="hrs"
              />
            </div>
            <div class="standard-form-group-large">
              <text-input
                ruleKey="cold_isch_time_left_kid"
                inputId="coldIschTimeLeft"
                :name="$t('cold_ischemic_time_left_kidney')"
                :calculated="true"
                :disabled="true"
                :append="true"
                appendText="hrs"
                v-model="editState.transplantDetails.organTransplantDetails.coldIschTimeLeft"
              />
            </div>
            <div class="standard-form-group-large">
              <text-input
                ruleKey="cold_isch_time_right_kid"
                inputId="coldIschTimeRight"
                :name="$t('cold_ischemic_time_right_kidney')"
                :calculated="true"
                :disabled="true"
                :append="true"
                appendText="hrs"
                v-model="editState.transplantDetails.organTransplantDetails.coldIschTimeRight"
              />
            </div>
          </div>
          <div class="row">
            <div class="standard-form-group">
              <boolean-radio-input
                  ruleKey="vessels_used"
                  input-id="vesselsUsed"
                  :labelName="$t('vessels_used')"
                  :acceptId="true"
                  :declineId="false"
                  :acceptLabel="$t('yes')"
                  :declineLabel="$t('no')"
                  v-model="editState.transplantDetails.organTransplantDetails.vesselsUsed"
              />
            </div>
            <div class="standard-form-group-large"
              v-if="editState.transplantDetails.organTransplantDetails.vesselsUsed" 
            >
              <text-area-input
                ruleKey="list_of_vessels_used"
                input-id="listOfVessels"
                :name="$t('list_of_vessels')"
                v-model="editState.transplantDetails.organTransplantDetails.listOfVessels"
              />
            </div>
          </div>
        </template>
      </fieldset>
    </template>
  </sub-section>
</template>

<i18n src="./../shared/_locales/common.json"></i18n>
<i18n src="@/components/_locales/Organs.json"></i18n>
<i18n src="@/components/organs/kidney/_locales/KidneyTransplantDetails.json"></i18n>

<script lang="ts">
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TransplantMixin } from "@/mixins/transplant-mixin";
import { Getter, State } from "vuex-class";
import { Component, Vue, Prop } from "vue-property-decorator";
import { IdLookup } from '@/store/validations/types';
import SubSection from "@/components/shared/SubSection.vue";
import { KidneyTransplantAttributes, RecipientJourney, TransplantFactors } from "@/store/recipientJourney/types";
import DateInput from "@/components/shared/DateInput.vue";
import { RecipientTransplantAttributes } from "@/store/recipientJourney/types";
import TimeInput from "@/components/shared/TimeInput.vue";
import NumberInput from '@/components/shared/NumberInput.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { Recipient } from '@/store/recipients/types';
import { Organ, OrganSpecification } from '@/store/lookups/types';
import BooleanRadioInput from '@/components/shared/BooleanRadioInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import { TransplantSectionPageState } from '@/mixins/transplant-mixin';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import TextInput from "@/components/shared/TextInput.vue";

export interface KidneyTransplantDetailsPageState {
  transplantType?:number;
  transplantStartDate?: string;
  transplantStartTime?: string;
  removedFromColdDateLeft?: string;
  removedFromColdTimeLeft?: string;
  removedFromColdDateRight?: string;
  removedFromColdTimeRight?: string;
  clampOffDateLeft?: string;
  clampOffTimeLeft?: string;
  clampOffDateRight?: string;
  clampOffTimeRight?: string;
  vesselsUsed?: boolean;
  listOfVessels?: string;
  rewarmTimeLeft?: string;
  rewarmTimeRight?: string;
  coldIschTimeLeft?: string;
  coldIschTimeRight?: string;
}

@Component({
  components: {
    SubSection,
    DateInput,
    TimeInput,
    BooleanRadioInput,
    SelectInput,
    TextAreaInput,
    TextInput,
  }
})
export default class KidneyTransplantDetails extends mixins(DateUtilsMixin, TransplantMixin) implements SaveableSection {
  // State
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) private recipientId!: string;
  @Getter("transplantTypeLookup", { namespace: "lookups" }) organSpecification!: (organCode: number | string | undefined) => OrganSpecification[] | undefined;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('convertTimeInSeconds', { namespace: 'utilities' }) convertTimeInSeconds!: (time?: number) => string;
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  private transplant_end_date = '';
  private transplant_end_time = '';

  public loaded(): void {
    this.$emit('loaded', 'kidneyDetails');
  }

  public mounted(): void {

    let items = localStorage.getItem(`${this.journey.organ}transplantDetailsOR`);

    if (items) {
      let parsed = JSON.parse(items);
      this.transplant_end_date = parsed.transplant_end_date;
      this.transplant_end_time = parsed.transplant_end_time;
    }
  }

  /**
   * Return true if we can edit the transplant details
   *
   * @returns {boolean} true if we can edit
   */
  get canEdit(): boolean{
    if (this.newJourney || this.journey.completed) {
      return false;
    }
    return true;
  }

   /**
   * Returns an array of options for Transplant Type
   * 
   * 
   * @returns {OrganSpecification[]|[]} organ-specific options 
   */
  get transplantTypeLookup(): OrganSpecification[] {
    const organCode = this.journey?.organ_code;
    if (!organCode) {
      return [];
    }
    // Fetch appropriate options
    const options = this.organSpecification(organCode) || [];

    const translated = options.map((option: OrganSpecification): OrganSpecification => {
      return {
        ...option,
        value: this.$t(option.value).toString(),
      };
    });
    return translated;
  }

  /**
   * Get organ code from the params
   *
   * @returns {number} organ_code
   */
  get organCode(): number {
    return !isNaN(Number(this.$route.params.organ_code)) ? Number(this.$route.params.organ_code) : 0;
  }

  /**
   * Build state for the KidneyTransplantDetails form area
   *
   * @param KidneyTransplantAttributes from RecipientTransplantAttributes
   * @returns {KidneyTransplantDetailsPageState} form state for KidneyTransplant
   */
  public buildTransplantDetailsState(transplantDetails?: RecipientTransplantAttributes): KidneyTransplantDetailsPageState {
    const kidneyTransplantAttributes = transplantDetails as KidneyTransplantAttributes || {};
    const result: KidneyTransplantDetailsPageState = {
      transplantType: kidneyTransplantAttributes.organ_specification_code || undefined,
      transplantStartDate: this.parseDateUiFromDateTime(kidneyTransplantAttributes.transplant_start_date || undefined),
      transplantStartTime: this.parseTimeUiFromDateTime(kidneyTransplantAttributes.transplant_start_date || undefined),
      removedFromColdDateLeft: this.parseDateUiFromDateTime(kidneyTransplantAttributes.end_cold_storage_date_left_kid || undefined),
      removedFromColdTimeLeft: this.parseTimeUiFromDateTime(kidneyTransplantAttributes.end_cold_storage_date_left_kid || undefined),     
      removedFromColdDateRight: this.parseDateUiFromDateTime(kidneyTransplantAttributes.end_cold_storage_date_right_kid || undefined),
      removedFromColdTimeRight: this.parseTimeUiFromDateTime(kidneyTransplantAttributes.end_cold_storage_date_right_kid || undefined),
      clampOffDateLeft: this.parseDateUiFromDateTime(kidneyTransplantAttributes.clamp_removal_date_left_kid || undefined),
      clampOffTimeLeft: this.parseTimeUiFromDateTime(kidneyTransplantAttributes.clamp_removal_date_left_kid || undefined),
      clampOffDateRight: this.parseDateUiFromDateTime(kidneyTransplantAttributes.clamp_removal_date_right_kid || undefined),
      clampOffTimeRight: this.parseTimeUiFromDateTime(kidneyTransplantAttributes.clamp_removal_date_right_kid || undefined),
      vesselsUsed: kidneyTransplantAttributes.vessels_used,
      listOfVessels: kidneyTransplantAttributes.list_of_vessels_used,
      rewarmTimeLeft: this.convertTimeInSeconds(kidneyTransplantAttributes.rewarm_time_left_kid || 0), 
      rewarmTimeRight: this.convertTimeInSeconds(kidneyTransplantAttributes.rewarm_time_right_kid || 0), 
      coldIschTimeRight: this.convertTimeInSeconds(kidneyTransplantAttributes.cold_isch_time_right_kid || 0),
      coldIschTimeLeft: this.convertTimeInSeconds(kidneyTransplantAttributes.cold_isch_time_left_kid || 0)
    };
    return result;
  }

  /**
   * Gets a patch object representing form edit state changes for this form
   *
   * Delegates the logic of building the patch to a local private method
   *
   * @returns {any} patch object containing field changes
   */
  public extractPatch(): any {
    if (!this.editState || !this.editState.transplantDetails || !this.editState.transplantDetails.organTransplantDetails) {
      return {};
    } else {
      return this.extractKidneyTransplantDetailsPatch(this.editState.transplantDetails.organTransplantDetails);
    }
  }

  // String for confirmation dialog for partial cluster transplant hold
  get confirmationText(): string {
    if (!this.isConfirmationRequired()) return '';

    return this.$t('confirm_partial_cluster_transplant_hold').toString();
  }


  /**
   * Saves the form edit state.
   *
   * Prepares a payload for Transplant Atributes, dispatches a save action, and registers the save result.
   * @emits clear clear validation errors because saving has begun
   * @emits saved saving has completed successful
   */
  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveTransplantDetails as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('clear');
    // Generate payload based on current edit state
    const transplantAttributesPatch = this.extractPatch();
    // Setup saving payload
    const payload = {
      journeyId: this.journey._id ? this.journey._id.$oid : undefined,
      recipientId: this.recipientId,
      transplantAttributes: transplantAttributesPatch,
    };
    // Dispatch save action and register the response
    this.$store.dispatch('journeyState/saveTransplantDetails', payload).then((success: SaveResult) => {
      // Report to parent that saving has completed
      this.$emit('saved', 'kidneyTransplantDetails');
      saveProvider.registerSaveResult(success);
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });

    const transplantDetailsStorage = {
      "transplant_end_date":  this.transplant_end_date,
      "transplant_end_time": this.transplant_end_time,
    };

     localStorage.setItem(`${this.journey.organ}transplantDetailsOR`, JSON.stringify(transplantDetailsStorage));

  }

   /**
   * Clears all save notifications shown by the form.
   *
   * Gets the Save Provider associated with the form, and requests that it reset its own Save Toolbar
   */
  public resetSaveToolbar(): void {
    const saveProvider = this.$refs.saveTransplantDetails as unknown as SaveProvider;
    saveProvider.resetSaveToolbar();
  }


  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    return{
      'organ_specification_code' : 'transplantType',
      'transplant_start_date' : 'transplantStartDate',
      'end_cold_storage_date_left_kid' : 'removedFromColdDateLeft',
      'end_cold_storage_date_right_kid' : 'removedFromColdDateRight',
      'transplant_start_time' : 'transplantStartTime',
      'end_cold_storage_time_left_kid' : 'removedFromColdTimeLeft',
      'end_cold_storage_time_right_kid' : 'removedFromColdTimeRight',
      'clamp_removal_date_left_kid':  'clampOffDateLeft',
      'clamp_removal_time_left_kid':  'clampOffTimeLeft',
      'clamp_removal_date_right_kid':  'clampOffDateRight',
      'clamp_removal_time_right_kid':  'clampOffTimeRight',
      'vessels_used':  'vesselsUsed',
      'list_of_vessels_used':  'listOfVessels'
    };
  }

  /**
   * Returns a journey patch object containing changes from a Transplant Details form
   *
   * @returns {RecipientTransplantAttributes}
   */
  private extractKidneyTransplantDetailsPatch(transplantDetails: KidneyTransplantDetailsPageState): KidneyTransplantAttributes {
    const transplant = this.editState.transplantDetails || {};
    const kidneyTransplantDetails = transplant.organTransplantDetails as KidneyTransplantDetailsPageState || {};

    // Fetch Transplant Date from the top-level of the form
    const transplantDate = this.editState.transplantDetails?.transplantDate;

    // Build nested 'factors' object to include in the Transplant Attributes patch
    const factors: TransplantFactors = {
      transplant_date: transplantDate ? this.sanitizeDateApi(transplantDate) : null,
    };

    // Build patch for Transplant Attributes object
    const result: KidneyTransplantAttributes = {
      organ_specification_code: kidneyTransplantDetails.transplantType,
      transplant_start_date: this.sanitizeDateTimeApi(kidneyTransplantDetails.transplantStartDate, kidneyTransplantDetails.transplantStartTime),
      end_cold_storage_date_left_kid: this.sanitizeDateTimeApi(kidneyTransplantDetails.removedFromColdDateLeft, kidneyTransplantDetails.removedFromColdTimeLeft),
      end_cold_storage_date_right_kid: this.sanitizeDateTimeApi(kidneyTransplantDetails.removedFromColdDateRight, kidneyTransplantDetails.removedFromColdTimeRight),
      clamp_removal_date_left_kid: this.sanitizeDateTimeApi(kidneyTransplantDetails.clampOffDateLeft, kidneyTransplantDetails.clampOffTimeLeft),
      clamp_removal_date_right_kid: this.sanitizeDateTimeApi(kidneyTransplantDetails.clampOffDateRight, kidneyTransplantDetails.clampOffTimeRight),
      vessels_used: kidneyTransplantDetails.vesselsUsed,
      list_of_vessels_used: kidneyTransplantDetails.listOfVessels,
      factors,
      donor_id: transplant.donorId ? { $oid: transplant.donorId } : null,
    };

    // Copy Details to Clustered Organs for details see https://shore.tpondemand.com/entity/7541-722-v42-transplant-details
    if (this.isClustered) {
      result.copy_to_cluster = transplant.copyToCluster;
    }

    return result;
  }
}
</script>
