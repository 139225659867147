<template>
  <sub-section
    :title="$t('allocation_details')"
    sub-section-id="allocation-details"
    :save-button="false"
  >
    <template v-slot:contents v-if="livingDonor && allocation">
      <fieldset>
        <div class="row">
          <dl class="col-md-4 col-lg-3 col-xl-2">
            <dt id="allocation-id-label">{{ $t('allocation_id') }}</dt>
            <dd :title="$t('allocation_id')" aria-labelledby="allocation-id-label">{{allocation.client_id}}</dd>
          </dl>
          <dl class="col-md-4 col-lg-3 col-xl-2">
            <dt>{{ $t('allocation_stage_field') }}</dt>
            <dd>{{ $t(allocationStage) }}</dd>
          </dl>
          <dl class="col-md-4 col-lg-3 col-xl-2">
            <dt>{{ $t('allocation_run_datetime') }}</dt>
            <dd>{{allocationTime}}</dd>
          </dl>
          <dl class="col-md-4 col-lg-3 col-xl-2">
            <dt>{{ $t('organ') }}</dt>
            <dd>{{ $t(`${organName}`) }}</dd>
          </dl>
          <dl class="col-md-4 col-lg-3 col-xl-2" v-if="allocation.disabled_program_rules.length > 0">
            <dt>{{ $t('disabled_program_rules') }}</dt>
            <dd>{{allocation.disabled_program_rules.join(', ')}}</dd>
          </dl>
          <dl class="col-md-4" v-if="specialConsiderations.length > 0">
            <dt>{{ $t('special_considerations') }}</dt>
            <dd v-for="consideration in specialConsiderations" :key="consideration.index">
              <span v-html="translateContext(consideration)" />
            </dd>
          </dl>
        </div>
      </fieldset>
    </template>
  </sub-section>
</template>

<i18n src="@/components/_locales/common.json"></i18n>
<i18n src="@/components/_locales/Organs.json"></i18n>
<i18n src="@/components/allocations/_locales/common.json"></i18n>
<i18n src="@/components/allocations/_locales/_AllocationDetails.json"></i18n>

<script lang="ts">
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, namespace, State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { Hospital } from '@/store/hospitals/types';
import { OrganCodeValue } from '@/store/lookups/types';
import { LivingDonor } from '@/store/livingDonors/types';
import { organCodeLookup, TranslationContext } from '@/types';
import { TranslationUtilsMixin } from "@/mixins/translation-utils-mixin";
import { LivingAllocation, LivingAllocationRecipient } from '@/store/livingAllocations/types';
import SubSection from '@/components/shared/SubSection.vue';

@Component({
  components: {
    SubSection,
  }
})
export default class AllocationDetails extends mixins(TranslationUtilsMixin, DateUtilsMixin) {
  @State(state => state.hospitals.all) hospitals!: Hospital[];
  @State(state => state.livingDonors.selectedLivingDonor) private livingDonor!: LivingDonor;

  @Getter('specialConsiderations', { namespace: 'livingAllocations' }) private specialConsiderations!: TranslationContext[];
  @Getter('oopHospitalsByOrgan', { namespace: 'hospitals' }) private oopHospitalsByOrgan!: (organCode: string) => Hospital[];
  @Getter('clientId', { namespace: 'livingDonors' }) private clientId!: string|undefined;
  @Getter('selectedAllocation', { namespace: 'livingAllocations' }) private allocation!: LivingAllocation;
  @Getter('isProvincialAllocation', { namespace: 'livingAllocations' }) private isProvincialAllocation!: boolean;
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;
  @Getter('describeAllocationStage', { namespace: 'livingDonors' }) private describeAllocationStage!: (allocation: LivingAllocation) => string;
  
  /**
   * Get the organ_code param from the url.
   *
   * @returns {string} the organ code
   */
  get organCode(): string {
    return this.$route.params.organ_code.toString() || '';
  }

  /**
   * Return the organ value which will get translated in the template
   *
   * @returns {string} organ value from the lookup
   */
  get organName(): string {
    if (!this.allocation.organ_code) return '-';
    return this.lookupValue((this.allocation.organ_code.toString() || ''), 'organ');
  }

  /**
   * Returns the allocation state from i18n
   *
   * @returns {string} the allocation state
   */
  get allocationStage() {
    return this.describeAllocationStage(this.allocation);
  }

  /**
   * Returns the allocation timestamp in the consistent UI displayed format.
   *
   * @returns {string} Formatted allocation created at datetime
   */
  get allocationTime() {
    return this.parseDisplayDateTimeUiFromDateTime(this.allocation.start_date);
  }

  /**
   * Return the option param from the url
   *
   * @returns {string} option param from the url
   */
  get organAllocationOption(): string {
    return (this.$route.params.option || '').toString().toLowerCase();
  }
}
</script>
