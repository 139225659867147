import { render, staticRenderFns } from "./_Actions.vue?vue&type=template&id=6662319c"
import script from "./_Actions.vue?vue&type=script&lang=ts"
export * from "./_Actions.vue?vue&type=script&lang=ts"
import style0 from "./_Actions.vue?vue&type=style&index=0&id=6662319c&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "../_locales/Actions.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fprototypes%2Forgans%2F_Actions.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports