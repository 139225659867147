<template>
  <div class="row">
    <div class="col-6">
      <select-input
        :value="value"
        :name="name"
        :select-id="selectId"
        :validation-id="validationId"
        :options="options"
        :rules="rules"
        :label="label"
        :disabled="disabled"
        :null-text="nullText"
        :text-key="textKey"
        :value-key="valueKey"
        :undefined-text="undefinedText"
        :select-class="selectClass"
        :readonly="readonly"
        :hide-label="hideLabel"
        :ruleKey="ruleKey"
        :crossValues="crossValues"
        :numeric="numeric"
        v-on="selectEvents()"
      />
    </div>
    <div class="col-6" v-if="!showProvince && !showState && !showOther">
      <span class="label-min-height"/>
      <input
    
        id="country_temp_input"
        type="text"
        class="form-control"
        disabled="true"
        tabindex="-1" />
    </div>
    <div class="col-6">
      <slot v-if="showProvince" name="province" />
      <slot v-else-if="showState" name="state" />
      <slot v-else-if="showOther" name="other" />
    </div>
  </div>
</template>

<script lang="ts">
import { CountryValue } from '@/store/lookups/types';
import SelectInput from '@/components/shared/SelectInput.vue';
import TextInput from '@/components/shared/TextInput.vue';
import { Component, Vue, Model, Prop } from 'vue-property-decorator';

@Component({
  components: {
    SelectInput,
    TextInput
  }
})

export default class CountryInput extends Vue {
  // V-model
  @Model('change') value!: any;

  // Standard properties
  @Prop({ required: true }) selectId!: string; // Set the ID
  @Prop({ required: true }) name!: string; // Set the label and name property
  @Prop({ required: true }) options!: any[]; // Enumerable data for building the options
  @Prop() validationId!: string; // Set the vid for validation reference

  // Optional properties
  @Prop({ default: null }) label!: string; // Optional label value
  @Prop({ default: false }) disabled!: boolean; // Turn input data entry off
  @Prop({ default: 'Not Applicable' }) nullText!: string; // Label used for null value
  @Prop({ default: 'value' }) textKey!: string; // Key for text displayed as option label
  @Prop({ default: 'code' }) valueKey!: string; // Key for code value associated with option
  @Prop({ default: 'Select...' }) undefinedText!: string; // Label used for unselected/undefined state
  @Prop({ default: 'form-control' }) selectClass!: string;
  @Prop({ default: false }) readonly!: boolean; // Render input as if it were plain text and turn input data entry off
  @Prop({ default: false }) hideLabel!: boolean; // Hide label visually, while still being readable for screen readers

  @Prop({ default: null }) rules!: string; // OPTIONAL lets us hard-code the client-side vee-validate rules in the front-end instead of using anything provided by the back-end
  @Prop({ default: '' }) ruleKey!: string
  @Prop({ default: null }) crossValues!: any; // valus needed for cross field validation for the asterix
  @Prop({ default: false }) numeric!: boolean; // If true handle option codes internally as strings, but emit numbers

  /**
   * Gets the full option document corresponding to the selected code value
   */
  get selectedOption(): any|undefined {
    if (!this.options || !this.value) {
      return undefined;
    }
    return this.options.find((option: any) => {
      return option.code == this.value;
    });
  }

  /**
   * Gets a boolean representation of whether or not to show the Province input slot
   * 
   * @returns {boolean} true if the Province input slot should be shown, false otherwise
   */
  get showProvince(): boolean {
    if (!this.selectedOption) {
      return false;
    }
    return this.selectedOption.code === CountryValue.Canada;
  }

  /**
   * Gets a boolean representation of whether or not to show the State input slot
   * 
   * @returns {boolean} true if the State input slot should be shown, false otherwise
   */
  get showState(): boolean {
    if (!this.selectedOption) {
      return false;
    }
    return this.selectedOption.code === CountryValue.USA;
  }

  /**
   * Gets a boolean representation of whether or not to show the Other input slot
   * 
   * @returns {boolean} true if the Other inout slot should be shown, false otherwise
   */
  get showOther(): boolean {
    if (!this.selectedOption) {
      return false;
    }
    return this.selectedOption.other_selected || false;
  }

  public selectEvents(): any {
    const _vm = this as CountryInput;
    return Object.assign({},
      // parent listeners
      this.$listeners,
      {
        // custom listeners
        change(value: any) {
          // Emit updated value for v-model
          _vm.$emit('change', value);
        }
      }
    );
  }
}
</script>
