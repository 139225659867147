<template>
  <card-section
    section-id="care-givers-section"
    :isAccordion="prototypeFeatureEnabled('proto_ability_to_expand_collapse')"
  >
    <template v-slot:header>
      {{ title }}
    </template>
    <template v-slot:body>
      <care-givers-section
        @clear="resetValidationErrors"
        :columns="getColumns('givers')"
        :title="title"
        :tableData="getTableData('givers')" 
        localStorageRef ="RecipientCareGivers"
      />
        
    </template>

  </card-section>
</template>


<script lang="ts">

import { Component, Vue, Prop} from 'vue-property-decorator';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import CardSection from '@/components/shared/CardSection.vue';
import SubSection from '@/components/shared/SubSection.vue';
import DateInput from '@/components/shared/DateInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import CareGiversSection from '@/components/prototypes/_CareGiversSection.vue';
import RecipientCareGiversColumns from '@/components/prototypes/json/Recipients/RecipientCareGiversColumns.json';
import RecipientCareGiversJson from '@/components/prototypes/json/Recipients/RecipientCareGiversData.json';
import { Getter } from 'vuex-class';

@Component({
  components: {
    CardSection,
    SubSection,
    SaveToolbar,
    SelectInput,
    DateInput,
    CheckboxInput,
    TextAreaInput,
    CareGiversSection
  }
})
export default class PaceAndEngagement extends Vue {  
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;


  title = "Care Givers"


  getTableData(type: any) {
    switch (type) {
      case 'givers':
        return RecipientCareGiversJson;
    }
  }

  getColumns(type: any) {
    switch (type) {
      case 'givers':
        return RecipientCareGiversColumns;
    }
  }

  resetValidationErrors() {
    this.$emit('clear');
  }

  
}
</script>

<style>


</style>
