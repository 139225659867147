<template>
  <validation-provider 
    ref="provider"
    :rules="formRules" 
    :name="name" 
    :vid="validationId ? validationId : inputId" 
    v-slot="{ errors }">
    <label :for="inputId" :class="{ 'sr-only': hideLabel }">
      {{label || name}}
      <validation-asterisk :rules=formRules :crossValues="crossValues" :ruleKey="ruleKey"/>
    </label>
    <span v-if="showCounter" class="character-counter">
      {{getCounter}} / {{maxLength}} chars
    </span>
    <span v-else>
      <Tooltip v-if="showToolTip" :toolTipText="toolTipText" :inputID="inputId"/>
    </span>
  
    <small class="w-100 d-flex mb-2" v-if="guidingText && guidingText.length > 0">{{guidingText}}</small>
    <textarea 
      ref="textarea"
      :id="inputId" 
      :maxlength="maxLength"
      @change="checkLength"
      type="text" 
      :class="{ 'is-invalid': !disabled && errors[0], 'form-control': !isReadOnly(readonly), 'form-control-plaintext': isReadOnly(readonly) }"
      :value="value"
      :placeholder="placeHolder"
      :readonly="isReadOnly(readonly||disabled)"
      :tabindex=" readonly ? -1 : 0"
      :rows="rows"
      :aria-describedby="`${inputId}-tooltip`"
      v-on="inputEvents()" />
    <div class="invalid-feedback" :id="`${inputId}-error`" v-if="errors[0]">
      <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
      {{ translateError(errors, label || name) }}
    </div>
    <div class="warning-message" :id="`${inputId}-warning`" v-if="showCharacterWarning">
      <font-awesome-icon :icon="['far', 'question-circle']" fixed-width />
      {{` ${ $t('maxLengthMessage')} ${ maxLength} ${ $t('characters')}`}}
    </div>
  </validation-provider>
</template>

<i18n src="@/components/shared/_locales/TextInput.json"></i18n>

<script lang="ts">
import '@/vee-validate-rules.ts';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';
import { Rules } from '@/store/validations/types';
import ValidationAsterisk from '@/components/shared/ValidationAsterisk.vue';
import { text } from '@fortawesome/fontawesome-svg-core';
import Tooltip from '@/components/shared/Tooltip.vue';

@Component({
  components: {
    ValidationAsterisk, Tooltip
  }
})
export default class TextAreaInput extends Vue {
  @Getter('getRuleSet', { namespace: 'validations' }) private ruleSet!: Rules;
  @Getter('getRules', { namespace: 'validations' }) private getRules!: (ruleSet: any, ruleKey: string, rules: string) => any;
  @Getter('isReadOnly', { namespace: 'validations' }) private isReadOnly!: (readonly?: any) => boolean;
  @Getter('translateError', { namespace: 'utilities' }) private translateError!: (error?: any, field?: string|null) => string;

  // V-model
  @Prop() value!: string;

  // Standard properties
  @Prop({ required: true }) inputId!: string; // MANDATORY actual HTML element ID, set indirectly using properties like 'inputId' and 'selectId'
  @Prop({ required: true }) name!: string; // Field name, also used as the label

  // Optional properties
  @Prop({ default: null }) validationId!: string; // OPTIONAL specify a 'vid' property for validation-provider, if it must be different than the element ID
                                                  // used by parent component after attempting to save to decide where server-side validation errors are shown
  @Prop({ default: null }) label!: string; // Alternate Label property
  @Prop({ default: false }) disabled!: boolean; // Turn input data entry off
  @Prop({ default: false }) readonly!: boolean; // Render input as if it were plain text and turn input data entry off
  @Prop({ default: 2 }) rows!: number; // Number of rows to show
  @Prop({ default: false }) hideLabel!: boolean; // Hide label visually, while still being readable for screen readers

  @Prop({ default: null }) rules!: string; // OPTIONAL lets us hard-code the client-side vee-validate rules in the front-end instead of using anything provided by the back-end
  @Prop({ default: null }) ruleKey!: string // OPTIONAL parameter path to load client-side validation e.g. new_validations, edit_validations
                                            // used by input components to set 'rules' properties in their validation providers based on the client-side validations loaded from the back-end
  @Prop({ default: null }) crossValues!: any; // valus needed for cross field validation for the asterix
  @Prop({ default: null }) guidingText!: any; 
  @Prop({ default: null }) placeHolder!: any;  // Allows Guiding text to be passes to the component

  @Prop({ default: false }) showToolTip!: boolean|string // Show tooltip
  @Prop({default: 'Calculated'}) toolTipText!: string|null; // Customize label for toolTipText
  @Prop({ default: null})  maxLength!: number;
  @Prop({ default: false})  showCounter!: number;

  private currentCounter = 0;
  private showCharacterWarning = false;

  get getCounter(): string|undefined {
    if (this.value) {
      return (this.value.length).toString();
    }
  }
  
  get formRules(): any {
    return this.getRules(this.ruleSet, this.ruleKey, this.rules);
  }

  public checkLength(event:any): any {
    this.currentCounter = event.target.value.length;
    this.showCharacterWarning = false;
  
    if ((event.target.value.length).toString() === this.maxLength) {
      this.showCharacterWarning = true;
    }
  }

  // Forward events to the parent component
  public inputEvents(): any {
    const _vm = this as TextAreaInput;
    return Object.assign({},
      // parent listeners
      this.$listeners,
      {
        // custom listeners
        input(event: any) {
          // Emit updated value for v-model
          _vm.$emit('input', event.target.value);
        }
      }
    );
  }
}
</script>
