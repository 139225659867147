<template>
  <sub-section
    title="Notes / Encounters"
    sub-section-id="notes_and_encounters"
    :table-config="tableConfig()"
    save-button="true"
    save-button-text="Save Note / Encounter"
  >
    <template v-slot:contents>
      <fieldset>
        <legend>
          <h5  class="legend-title">
           New Notes / Encounters
          </h5>
        </legend>
        <div class="row">
      
        </div>
      </fieldset>
    </template>
  </sub-section>
</template>

<i18n src="@/components/organs/shared/_locales/ConsultationDetialsSection.json"></i18n>
<i18n src="@/components/organs/shared/_locales/common.json"></i18n>

<script lang="ts">
import DateInput from '@/components/shared/DateInput.vue';
import TextInput from '@/components/shared/TextInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';


@Component({
  components: {
    TextInput,
    DateInput,
    SubSection
  }
})
export default class EncountersDetailsSection  extends Vue {  
  
tableConfig() {
  return {
    data: [
      {
        date: '23/07/2023',
        col2: '1'        
      },
      {
        date: '26/07/2023',
        col2: '2'        
      }
    ],
    columns: [
      { label: "Date of Encounter", field: 'date', width: '15%' },
      { label: 'col 2', field: 'col2' },
    ],
    empty: 'no records',
    createButton: true,
    createText: 'Create Note / Encounter'
  };

}



}

</script>
