<template>
  <router-link
    :to="{ name: getRouteName, params: { id: client_id }}"
    target="_blank">
  <i aria-hidden="true" class="far fa-fw fa-file-export"></i>{{$t('recipient_profile')}}
  </router-link>
</template>

<i18n src="../_locales/common.json"></i18n>
<i18n src="@/components/shared/_locales/single.json"></i18n>

<script lang="ts">
import { Getter, State } from 'vuex-class';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Recipient } from "@/store/recipients/types";

@Component
export default class RecipientLink extends Vue {
  // Properties
  @Prop({ required: true }) client_id!: string;
  @Prop({ required: true }) oop_recipient!: boolean;

  get getRouteName(): string {
    return this.oop_recipient ? 'edit-recipient-oop' : 'edit-recipient';
  }
}
</script>

