<template>
  <card-section
    section-id="gci"
    :lookupsToLoad="lookupsToLoad"
    @loaded="loaded()" 
    :isAccordion="prototypeFeatureEnabled('proto_ability_to_expand_collapse')">
    <template v-slot:header>
      {{$t('general_clinical_information')}}
    </template>
    <template v-slot:body>
      <sub-section
        :title="$t('clinical_data')"
        sub-section-id="gci-clinical"
        :saveButtonText="$t('save_clinical_data')"
        ref="saveGeneralClinical"
        :saveButton="!newRecipient && canSaveClinicalData"
        :disabled="!canSaveClinicalData"
        @save="savePatch()">
        <template v-slot:contents>
          <template v-if="editState && editState.clinicalData">
            <div class="row gci-clinical">
              <div class="standard-form-group">
                <select-input
                  ruleKey="diagnostics.blood.type"
                  selectId="gci-clinical-blood-type"
                  :name="$t('blood_type')"
                  v-model="editState.clinicalData.type"
                  :options="bloodTypeLookup"
                />
              </div>
              <div class="standard-form-group">
                <select-input
                  ruleKey="diagnostics.blood.rh_indicator"
                  selectId="gci-clinical-rh"
                  :name="$t('rh_factor')"
                  v-model="editState.clinicalData.rh_indicator"
                  :options="rhIndicatorLookup"
                />
              </div>
              <div class="standard-form-group">
                <checkbox-input
                  ruleKey="diagnostics.blood.type_verified"
                  input-id="gci-clinical-btv"
                  :labelName="$t('blood_type_verified')"
                  v-model="editState.clinicalData.type_verified"
                  :label="$t('yes')"
                  :disabled="isBloodTypeVerifiedDisabled()"
                />
              </div>
              <div class="standard-form-group-large">
                <text-input
                  inputId="gci-clinical-reaction-antibody"
                  :name="$t('calculated_panel_reactive_antibody')"
                  :disabled="true"
                  v-model="editState.clinicalData.cpra"
                />
              </div>
            </div>
            <div class="row" v-if="showNumberOfPreviousTransplants">
              <div class="standard-form-group">
                <number-input
                  ruleKey="diagnostics.number_of_previous_transplants"
                  inputId="gci-clinical-number-previous-transplants"
                  :name="$t('number_previous_transplants')"
                  step="1"
                  v-model="editState.clinicalData.number_of_previous_transplants"
                />
              </div>
            </div>
            <!-- Virtual field for cross-form conditional validations  -->
            <hidden-input
              inputId="eligible_assessment"
              validationId="eligible_assessment"
              v-model="editState.clinicalData.eligible_assessment"
            />
          </template>
        </template>
      </sub-section>


      <sub-section
        :title='$t("measurements")'
        sub-section-id="gci-measurements"
        ref="saveMeasurement"
        :saveButtonText="$t('save_measurement')"
        :tabbableValue="$t('measurement_date')"
        :saveButton="!newRecipient && canSaveMeasurement && isNewOrMostRecentEntry"
        :table-config="measurementsTableConfig()"
        @table-row-click="selectMeasurement($event)"
        @table-create-row="createMeasurement()"
        @save="saveMeasurement()" >
        <template v-slot:contents>
          <template v-if="editState && editState.measurement">
            <fieldset :disabled="!canSaveMeasurement || !isNewOrMostRecentEntry">
              <legend>
                <h5 v-if="!editState.measurement._id" class="legend-title">
                  {{$t('new_measurement')}}
                </h5>
                <h5 v-else class="legend-title">
                  {{$t('selected_measurement')}}
                </h5>
              </legend>
              <div class="row d-flex">
                <div class="measurements-form-group">
                  <date-input
                    validationId="date"
                    ruleKey="measurements.date"
                    inputId="gci-measurements-date"
                    :name="$t('measurement_date')"
                    v-model="editState.measurement.date"
                  />
                </div>
                <div class="measurements-form-group">
                  <text-input
                    inputId="gci-measurements-bmi"
                    :name="$t('bmi')"
                    v-model="editState.measurement.bmi"
                    :calculated="true"
                    :disabled="true"
                  />
                </div>
              </div>
              <div class="row d-flex">
                <div class="measurements-form-group measureUnits">
                  <div class="inner">
                    <div class="form-group">
                      <p>{{$t('metric')}}</p>
                      <number-input
                        validationId="weight"
                        ruleKey="measurements.weight"
                        inputId="gci-measurements-weight_kg"
                        :name="$t('weight')"
                        :append="true"
                        appendText="kg"
                        step="0.1"
                        :calculated="true"
                        @change="calculateMeasurement()"
                        :warningParams="{ min: 1, max: 225, message: $t('warning_1_225').toString()}"
                        v-model="editState.measurement.weight"
                      />
                    </div>
                    <div class="form-group">
                      <number-input
                        validationId="height"
                        ruleKey="measurements.height"
                        inputId="gci-measurements-height_cm"
                        :name="$t('height')"
                        :append="true"
                        appendText="cm"
                        step="0.1"
                        :calculated="true"       
                        @change="calculateMeasurement()"
                        :warningParams="{ min: 30, max: 215, message: $t('warning_30_215').toString()}"
                        v-model="editState.measurement.height"
                      />
                    </div>
                  </div>
                </div>
                <div class="measurements-form-group measureUnits last">
                  <div class="inner">
                  <p>{{$t('imperial')}}</p>
                  <div class="form-group">
                    <number-input
                      inputId="gci-measurements-weight_lbs"
                      :name="$t('weight')"
                      :append="true"
                      appendText="lbs"
                      step="0.1"
                      :calculated="true"
                      @change="calculateMeasurement('lbs')"
                      v-model="editState.measurement.weight_lbs"
                    />
                  </div>
                  <div class="form-group">
                    <number-input
                      inputId="gci-measurements-height_in"
                      :name="$t('height')"
                      :append="true"
                      appendText="in"
                      step="0.1"
                      :calculated="true"
                      @change="calculateMeasurement('in')"
                      v-model="editState.measurement.height_in"
                    />
                  </div>
                </div>
                </div>

              </div>
            </fieldset>
          </template>
        </template>
      </sub-section>
    </template>
  </card-section>
</template>

<i18n src="@/components/recipients/_locales/GeneralClinicalInformation.json"></i18n>
<i18n src="@/components/_locales/common.json"></i18n>

<script lang="ts">
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { Getter, State } from 'vuex-class';
import { BmiResult } from '@/store/tools/types';
import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import CardSection from '@/components/shared/CardSection.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import NumberInput from '@/components/shared/NumberInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import HiddenInput from '@/components/shared/HiddenInput.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Organ, Province, BloodType, RhIndicator, BloodTypeValue, RhIndicatorValue } from '@/store/lookups/types';
import { Recipient, RecipientMeasurement, RecipientValidations } from '@/store/recipients/types';
import { ObjectId } from '@/store/types';

// Declare all v-model fields used in the form
export interface GeneralClinicalForm {
  clinicalData: {
    type?: string;
    rh_indicator?: string;
    type_verified?: boolean;
    cpra?: number;
    number_of_previous_transplants?: number | null;
    eligible_assessment?: boolean; // virtual field for cross-form conditional validations
  };
  measurement: MeasurementForm;
}

interface MeasurementRow {
  _id?: { $oid: string };
  measurementDate: string;
  weightKg: string;
  heightCm: string;
  bmi: string;
}

interface MeasurementForm {
  _id?: { $oid: string };
  date?: string;
  weight?: number|null;
  height?: number|null;
  weight_lbs?: number;
  height_in?: number;
  bmi?: number;
}

@Component({
  components: {
    TextInput,
    DateInput,
    SubSection,
    CardSection,
    SaveToolbar,
    SelectInput,
    NumberInput,
    CheckboxInput,
    HiddenInput
  }
})
export default class GeneralClinicalInformation extends mixins(DateUtilsMixin) implements SaveableSection {
  // State
  @State(state => state.pageState.currentPage.generalClinical) editState!: GeneralClinicalForm;
  @State(state => state.recipients.selectedRecipient.validations) validations!: RecipientValidations;
  @State(state => state.lookups.rh_indicator) private rhIndicatorLookup!: RhIndicator;
  @State(state => state.tools.bmiResult) bmiResult!: BmiResult;

  // Properties
  @Prop({ default: false }) newRecipient!: boolean;

  // Instance attributes
  private isFinishedLoadingLookups = false;

  // Lookup tables to be loaded by the CardSection component
  public lookupsToLoad = ['blood_type', 'rh_indicator', 'country'];

  @Getter('show', { namespace: 'recipients' }) private recipient!: Recipient;
  @Getter('organ', { namespace: 'lookups' }) private organLookup!: Organ;
  @Getter('province', { namespace: 'lookups' }) private provinceLookup!: Province;
  @Getter('blood_type', { namespace: 'lookups' }) private bloodTypeLookup!: BloodType;
  @Getter('defaultLookup', { namespace: 'lookups' }) defaultLookup!: (lookupId: string) => any;
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;
  @Getter('hasJourneyWithAssessmentEligible', { namespace: 'recipients' }) private hasJourneyWithAssessmentEligible!: boolean;
  @Getter('isFieldWriteable', { namespace: 'validations' }) private isFieldWriteable!: (ruleKey: string|null, fallBackGroup?: string) => boolean;
  @Getter('isLastEntry', { namespace: 'utilities' }) private isLastEntry!: (id: ObjectId, entries: any[]) => boolean;
  @Getter('checkPropExists', { namespace: 'validations' }) checkPropExists!: (ruleKey: string) => boolean;
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;
  /**
   * Return true if we can save clinical diagnostics data
   *
   * @returns {boolean} true if we have permission
   */
  get canSaveClinicalData(): boolean {
    // recipient_medical
    return this.isGroupWriteable('recipient_diagnostics');
  }

  /**
   * Return true if we can save measurement i.e. recipient medical
   *
   * @returns {boolean} true if we have permission
   */
  get canSaveMeasurement(): boolean {
    // check role-based user permissions for the appropriate field group
    return this.isGroupWriteable('recipient_medical');
  }

  /**
   * Return true if we can edit the selected measurement
   *
   * Note: this check selected measurement if there is one,
   * because historical entries are read-only. If nothing
   * is selected, we return true since 'new' can be saved.
   *
   * @returns {boolean} true if we can edit
   */
  get isNewOrMostRecentEntry(): boolean {
    // no state and no recipient measurements
    if (!this.editState && !this.recipient.measurements) {
      return true;
    }
    // ensure we have a measurement selected
    if (!!this.editState?.measurement) {
      const measurementId = this.editState.measurement._id;
      const recipientMeasurements = this.recipient.measurements;
      if (!!measurementId && !!recipientMeasurements) {
        return this.isLastEntry(measurementId, recipientMeasurements);
      }
    }
    return true;
  }

  // Show the number of previous transplants field.
  get showNumberOfPreviousTransplants(): boolean {
    return this.checkPropExists('diagnostics.number_of_previous_transplants');
  }


  // Called when lookups finish loading
  private loaded(): void {
    this.isFinishedLoadingLookups = true;
    this.checkIfLoadingComplete();
  }

  // Section is finished loading when dependencies are loaded
  private checkIfLoadingComplete(): void {
    if (this.isFinishedLoadingLookups ) {
      this.initializeForm();
      this.$emit('loaded', 'generalClinical');
      if (!this.newRecipient) {
        this.loadMeasurements();
      }
    }
  }

  public loadMeasurements() {
    const recipientId = this.recipient.client_id;
    this.$store.dispatch('recipients/loadMeasurements', recipientId);
  }

  // Copy relevant data from recipient into the pageState
  public initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'generalClinical',
      value: this.buildGeneralClinicalForm(this.recipient)
    });
    setTimeout(() => {
      this.$emit('clear');
    }, 500);
  }

  /**
   * TODO: TECH_DEBT: this should be split into two peices of logic:
   * - boolean getter for whether or not the Verified checkbox is disabled
   * - event handlers on the Type and RH dropdowns to change edit state
   *
   * I.e. disabled prop in template should be based on getter, but getters
   * should not be mutating the form edit state
   */
  //Disable Blood Type Verified if Blood Type or Rh Factor is Unknown
  public isBloodTypeVerifiedDisabled(){
    const blood_type = this.editState.clinicalData.type;
    const rh_indicator = this.editState.clinicalData.rh_indicator;
    if(blood_type === BloodTypeValue.Unknown || rh_indicator === RhIndicatorValue.Unknown)
    {
      Vue.set(this.editState.clinicalData, 'type_verified', false);
      return true;
    }
    return false;
  }

  // Updates BMI, Height and Weight from API triggered on change
  // from height / weight fields, or selecting a measurement
  public calculateMeasurement(from?: string) {
    let params = {};
    if (from === 'in') {
      params = {
        height: this.editState.measurement!.height_in,
        height_unit: 'in',
        weight: this.editState.measurement!.weight,
        weight_unit: 'kg',
      };
    } else if (from === 'lbs') {
      params = {
        height: this.editState.measurement!.height,
        height_unit: 'cm',
        weight: this.editState.measurement!.weight_lbs,
        weight_unit: 'lbs',
      };
    } else {
      params = {
        height: this.editState.measurement!.height,
        height_unit: 'cm',
        weight: this.editState.measurement!.weight,
        weight_unit: 'kg',
      };
    }
    this.$store.dispatch('tools/loadBmiHeightWeight', params).then(() => {
      // update pageState with new values
      this.setMeasurement(this.editState.measurement, this.bmiResult);
    }).catch((error) => {
      console.warn(error.description);
    });
  }

  // Sanitize then save selected measurement into pageState
  public setMeasurement(existingMeasurement: MeasurementForm, bmiResponse: BmiResult) {
    const measurementChanges: MeasurementForm = {};
    // Set calculated BMI if it was provided in the calculation response
    if (bmiResponse.bmi !== undefined && bmiResponse.bmi !== null ) {
      measurementChanges.bmi = bmiResponse.bmi;
    }
    // Convert height if applicable
    if (bmiResponse.height_cm >= 0 && bmiResponse.height_in >= 0) {
      measurementChanges.height = parseFloat(this.toFixed(bmiResponse.height_cm, 1));
      measurementChanges.height_in = parseFloat(this.toFixed(bmiResponse.height_in, 1));
    }
    // Convert weight if applicable
    if (bmiResponse.weight_kg >= 0 && bmiResponse.weight_lbs >= 0) {
      measurementChanges.weight = parseFloat(this.toFixed(bmiResponse.weight_kg, 1));
      measurementChanges.weight_lbs = parseFloat(this.toFixed(bmiResponse.weight_lbs, 1));
    }
    // Merge existing measurement data with calculated data
    const sanitizeMeasurement = {
      _id: existingMeasurement._id,
      date: existingMeasurement.date,
      weight: measurementChanges.weight || existingMeasurement.weight,
      height: measurementChanges.height || existingMeasurement.height,
      weight_lbs: measurementChanges.weight_lbs || existingMeasurement.weight_lbs,
      height_in: measurementChanges.height_in || existingMeasurement.height_in,
      bmi: measurementChanges.bmi || existingMeasurement.bmi,
    };
    // Update form state
    this.$store.commit('pageState/set', {
      pageKey: 'generalClinical',
      componentKey: 'measurement',
      value: sanitizeMeasurement
    });
  }

  // Load selected measurement into pageState
  public selectMeasurement(event: any) {
    // Get Measurement ID from the table row referenced in the select event
    const selectedMeasurementId = event.row._id && event.row._id.$oid ? event.row._id!.$oid : undefined;
    // Retrieve the Measurement object from all the Measurements
    const foundMeasurement: RecipientMeasurement|undefined = this.recipient.measurements!
      .find((each: RecipientMeasurement) => {
        return each._id && each._id.$oid === selectedMeasurementId;
      });
    // If we found a Measurement
    if (foundMeasurement) {
      // Build form state
      const measurement = this.buildMeasurementForm(foundMeasurement);
      // Save it to the editState
      this.$store.commit('pageState/set', {
        pageKey: 'generalClinical',
        componentKey: 'measurement',
        value: measurement
      });
      // Update Height, Weight and BMI
      this.calculateMeasurement();
    }
  }

  // Modify the measurements to fit our format
  public buildMeasurementForm(measurement: RecipientMeasurement): MeasurementForm {
    const sanitizedMeasurement: RecipientMeasurement = {
      _id: measurement._id,
      date: this.parseDateUi(measurement.date),
      height: measurement.height,
      weight: measurement.weight
    };
    return sanitizedMeasurement;
  }

  // Prepare recipient measurements to be display in the historical table
  get measurementRows(): MeasurementRow[] {
    if (!this.editState || !this.recipient.measurements) {
      return [];
    }

    const rows = this.recipient.measurements.map((measurement: RecipientMeasurement) => {
      // Masking hides these value so convert to integers before attempting toFixed()
      const result = {
        _id: measurement._id,
        measurementDate: this.parseDisplayDateUi(measurement.date) || '-',
        weightKg: measurement.weight == null ? '-' : this.toFixed(measurement.weight, 1),
        heightCm: measurement.height == null ? '-' : this.toFixed(measurement.height, 1),
        bmi: measurement.bmi == null ? '-' : this.toFixed(measurement.bmi, 1),
      };
      return result;
    });
    return rows;
  }

  // Config options for the measurements table
  public measurementsTableConfig(): TableConfig {
    return {
      data: this.measurementRows,
      columns: [
        { label: this.$t('measurement_date').toString(), field: 'measurementDate', width: '25%' },
        { label: this.$t('weight_kg').toString(), field: 'weightKg', width: '25%' },
        { label: this.$t('height_cm').toString(), field: 'heightCm', width: '25%' },
        { label: this.$t('bmi').toString(), field: 'bmi', width: '25%' }
      ],
      empty: this.$t('use_form_below').toString(),
      createButton: this.canSaveMeasurement,
      createText: this.$t('create_measurement').toString()
    };
  }

  // Extract relevant parts from Recipient to match the form layout
  public buildGeneralClinicalForm(recipient: Recipient): GeneralClinicalForm {
    // Initialize form state for self, including sub-section states
    const recipientDiagnosticsBlood = recipient.diagnostics !== undefined && recipient.diagnostics.blood != null ? recipient.diagnostics.blood : {};
    const hlaDiagnostics = recipient && recipient.diagnostics && recipient.diagnostics.hla ? recipient.diagnostics.hla || {} : {};
    const result: GeneralClinicalForm = {
      clinicalData: {
        type: recipientDiagnosticsBlood.type == null ? this.defaultLookup('blood_type') : recipientDiagnosticsBlood.type,
        rh_indicator: recipientDiagnosticsBlood.rh_indicator == null ? this.defaultLookup('rh_indicator') : recipientDiagnosticsBlood.rh_indicator,
        type_verified: recipientDiagnosticsBlood.type_verified == null ? undefined : recipientDiagnosticsBlood.type_verified,
        number_of_previous_transplants: recipient.diagnostics !== undefined ? recipient.diagnostics.number_of_previous_transplants : null,
        cpra: hlaDiagnostics.cumulative_cpra == null ? undefined : hlaDiagnostics.cumulative_cpra,
        eligible_assessment: this.hasJourneyWithAssessmentEligible,
      },
      measurement: {},
    };
    return result;
  }

  // Set the measurement to an empty object
  public createMeasurement() {
    this.$store.commit('pageState/set', {
      pageKey: 'generalClinical',
      componentKey: 'measurement',
      value: {}
    });

    this.$emit('clear');
    this.resetSaveToolbar();
  }

  private clear() {
    this.$emit('clear');
  }

  // Translate the form structure into the Recipient data structure
  public extractPatch(potential_duplicate_profile_confirmed?: boolean): Recipient|null {
    if (this.editState) {
      return {
        diagnostics: {
          blood: {
            type: this.editState.clinicalData.type || null,
            type_verified: this.editState.clinicalData.type_verified ? true : false,
            rh_indicator: this.editState.clinicalData.rh_indicator || null,
          },
          number_of_previous_transplants: this.editState.clinicalData.number_of_previous_transplants
        },
        potential_duplicate_profile_confirmed: potential_duplicate_profile_confirmed || false,
      };
    } else {
      return null;
    }
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    // Note: We are not resetting measurements here because the parent saving does not save measurements.
    const gci = this.$refs.saveMeasurement as unknown as SaveProvider;
    // Reset the save provider's save toolbar
    gci.resetSaveToolbar();
  }

  /**
   * Saves the Clinical Data section
   *
   * Extracts the clinical data section from the editState and attempts to update the Recipient record.  This is a patch
   * update and only sends the `diagnostics` key from the Recipient object.  The dispatch returns a SaveResult promise
   * with the Recipient record, or an error object with an array or errors the were returned.
   */
  // Handle saving triggered by local save button
  public savePatch(potential_duplicate_profile_confirmed?: boolean): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveGeneralClinical as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'generalClinical');
    // Generate payload based on current edit state
    const recipientPatch = this.extractPatch(potential_duplicate_profile_confirmed);
    const recipientId = this.recipient.client_id;
    // Dispatch save action and register the response
    this.$store.dispatch('recipients/saveRecipient', { recipientId, recipient: recipientPatch }).then((success: SaveResult) => {
      // If successful, update the current recipient and show success notification
      this.$store.commit('recipients/set', success.responseData.recipient);
      saveProvider.registerSaveResult(success);
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.handleErrors(error);
      this.$emit('handleWarnings', error, 'saveGeneralClinicalInformation');
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  /**
   * Saves the Measurement section (selected or new item)
   *
   * Extract the measurement section from editState and package up a payload to send.  If the measurement already exists
   * the dispatch will have an id and use that to PATCH a measurement.  If the measurement is new, we then make a POST
   * request and create a new measurement.  After success we load the measurements and update the page.  The dispatch
   * returns a SaveResult promise with the Recipient record, or an error object with an array or errors the were returned.
   */
  public saveMeasurement() {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveMeasurement as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'measurement');
    // Generate payload based on current edit state
    const measurementPayload = {
      id: this.editState.measurement!._id,
      recipientId: this.recipient.client_id,
      measurement: this.extractMeasurementPatch()
    };
    // Dispatch save action and register the response
    this.$store.dispatch('recipients/saveMeasurement', measurementPayload).then((success: SaveResult) => {
      // If successful, reload all of the recipient's measurements and show success notification
      this.loadMeasurements();
      saveProvider.registerSaveResult(success);
      // Clear form for entry of a new measurement
      this.initializeForm();
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.handleErrors(error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  // Bubble up validation errors
  public handleErrors(errorResult: SaveResult): void {
    this.$emit('handleErrors', errorResult);
  }

  // Returns a RecipientMeasurement patch request payload or null if the measurement edit state is empty
  public extractMeasurementPatch(): RecipientMeasurement|any {
    if (!this.editState || !this.editState.measurement || this.isMeasurementEmpty(this.editState.measurement)) {
      return {
        date: null,
        height: null,
        weight: null,
      };
    }
    return {
      _id: this.editState.measurement!._id,
      date: this.sanitizeDateApi(this.editState.measurement.date),
      weight: this.editState.measurement!.weight,
      height: this.editState.measurement!.height
    };
  }

  // Returns whether or not the measurement state is empty
  get isEmpty(): boolean {
    const measurement = this.editState.measurement;
    const isEmpty = (
      measurement.date == null &&
      measurement.height == null &&
      measurement.weight == null
    );
    return isEmpty;
  }

  // Returns a number to a fixed number of sidigits if it's a number
  private toFixed(number: any, sigdits: number): any {
    if (Number.isFinite(number)) {
      return number.toFixed(sigdits);
    } else {
      return number;
    }
  }

  // Returns whether or not the measurement edit state is empty
  private isMeasurementEmpty(measurement: MeasurementForm): boolean {
    if (!measurement) {
      return true;
    }
    return (measurement.date == null || measurement.date.length === 0)
        && (measurement.weight == null || measurement.weight.toString().length === 0)
        && (measurement.height == null || measurement.height.toString().length === 0);
  }

  // Returns true if current state is non-empty and therefore has data that can be saved
  get hasMeasurementDataToSave(): boolean {
    return !this.isMeasurementEmpty(this.editState.measurement);
  }

  get idLookup(): {[key: string]: string} {
    const result = {
      'diagnostics.blood.type'                     : 'gci-clinical-blood-type',
      'diagnostics.blood.type_verified'            : 'gci-clinical-btv',
      'diagnostics.blood.rh_indicator'             : 'gci-clinical-rh',
      'diagnostics.number_of_previous_transplants' : 'gci-clinical-number-previous-transplants',
      'measurements.date'                          : 'date',
      'measurements.height'                        : 'height',
      'measurements.weight'                        : 'weight',
    };
    return result;
  }

  // Tp-5817 blood type required to be true when any of the recipient's journeys has assessment = eligible
  get isBloodTypeRequired(): boolean {
    return this.hasJourneyWithAssessmentEligible || false;
  }
}
</script>


