<template>
  <base-widget :title="$t('bookmarks')" :preferences="preferences.bookmarks" v-if="editState">
    <template v-slot:icon>
      <div class="circle dashboard-icon circle-active-donor">
        <font-awesome-icon :icon="['fas', 'user']" />
      </div>
    </template>
    <template v-slot:linkID>
      <a href="#idBookmarks" class="nav-link card-header-btn py-0" data-toggle="collapse">
        <font-awesome-icon :icon="['far', 'cog']" data-toggle="tooltip" :title="$t('customize_panel')" />
      </a>
      <a href="#bodyBookmarks" class="nav-link card-header-btn py-0" data-toggle="collapse">
        <font-awesome-icon :icon="['far', 'caret-down']" data-toggle="tooltip" :title="$t('collapse_panel')" />
      </a>
    </template>
    <template v-slot:options>
      <div class="card-body card-options p-0 collapse" id="idBookmarks">
        <div class="customize-panel">
          <h4>{{$t('panel_options')}}</h4>
          <!-- Widget options-->
          <slot name="options">
            <form>
              <div class="form-row mb-3">
                <div class="col-sm-6">
                  <select-input
                    name="panel-style"
                    select-id='dashBookmarksStyle'
                    v-model="editState.style"
                    :label="$t('panel_style')"
                    :options="getPanelStyles"
                    />
                </div>
              </div>
              <div class="sub-divider"></div>
              <div class="form-row mb-3">
                <div class="col-sm-12 col-lg-8">
                  <checkbox-input
                    input-id='dashBookmarksVisible'
                    v-model="editState.visible"
                    :label="$t('show_this_panel')"
                    :disabled="requiredWidget"
                  />
                </div>
              </div>
              <div class="sub-divider"></div>
              <div class="col-sm-12" v-if="errorMessage">
                <p>{{errorMessage}}</p>
              </div>
              <button
                type="button"
                class="btn btn-sm btn-wide btn-success"
                data-target="#idBookmarks"
                @click="saveSettings()"
              >
                {{$t('save')}}
              </button>
            </form>
          </slot>
        </div>
      </div>
    </template>
    <template v-slot:widget-contents>
      <div class="card-body collapse show" id="bodyBookmarks">
        <validation-observer ref="saveBookmarksValidations" autocomplete="off" tag="form" v-slot="{ handleSubmit }">
          <sub-section
            sub-section-id="bookmarks-table"
            ref="bookmarksTable"
            style-class="vgt-table table table-bordered table-hover bordered"
            row-style-class="tr-link"
            @table-row-click="selectBookmark($event)"
            @table-create-row="createBookmark()"
            :saveButton="false"
            :disabled="false"
            :save-button-text="$t('save_bookmark')"
            :total-records="bookmarksCount"
            :table-config="tableConfig"
            @on-page-change="updatePagination($event)"
            @on-per-page-change="updatePagination($event)"
            >
            <template v-slot:table-cell="props">
              <template v-if="props.column.field == 'delete'">
                <button
                  type="button"
                  class="btn btn-primary btn-sm"
                  :title="$t('delete_bookmark')"
                  @click="deleteBookmark(props.row.name)"
                >
                  <!-- Icon matches the overall save button label icon -->
                  <font-awesome-icon :icon="['fa', 'trash']" fixed-width />
                </button>
              </template>
            </template>
            <template v-slot:contents>
              <legend>
                <h5 v-if="!currentBookmark.index && currentBookmark.index != 0" class="legend-title">
                  {{$t('new_bookmark')}}
                </h5>
                <h5 v-else class="legend-title">
                  {{$t('selected_bookmark')}}
                </h5>
              </legend>
              <div class="row">
                <div class="form-group col-md-6 col-lg-4 col-xl-3">
                  <text-input
                    inputId="bookmark-name"
                    :name="$t('name')"
                    rules="required"
                    v-model="currentBookmark.name"
                  />
                </div>
                <div class="form-group col-md-6 col-lg-8 col-xl-9">
                  <text-input
                    inputId="bookmark-link"
                    :name="$t('link')"
                    rules="required"
                    v-model="currentBookmark.link"
                  />
                </div>
              </div>
              <save-toolbar
                class="action-row"
                buttonClass="btn btn-success"
                :label="$t('save_bookmark')"
                ref="saveBookmark"
                :savingText="$t('saving_bookmark')"
                :successText="$t('bookmark_saved')"
                :defaultErrorText="$t('problem_saving')"
                @save="handleSubmit(saveBookmarks)"
              >
              </save-toolbar>
            </template>
          </sub-section>
        </validation-observer>
      </div>
    </template>
  </base-widget>
</template>

<i18n src="./_locales/common.json"></i18n>
<i18n src="@/components/dashboard/widgets/_locales/WidgetBookmarks.json"></i18n>

<script lang="ts">
import { Getter, State } from 'vuex-class';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import BaseWidget from '@/components/dashboard/widgets/_BaseWidget.vue';
import { DashboardSettingState, Bookmark, BookmarksWidgetSetting } from '@/store/dashboard/types';
import { TableConfig } from '@/types';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import TextInput from '@/components/shared/TextInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { SaveResult, SaveProvider } from '@/types';

@Component({
  components: {
    BaseWidget,
    TextInput,
    CheckboxInput,
    SelectInput,
    SubSection,
    SaveToolbar
  }
})
export default class WidgetBookmarks extends Vue {
  @Prop({ default: false }) requiredWidget!: boolean;

  @State(state => state.users.user.preferences) private preferences!: any;
  @State(state => state.pageState.currentPage.dashBookmarks) private editState!: BookmarksWidgetSetting;
  @State(state => state.pageState.currentPage.currentBookmark) private currentBookmark!: Bookmark;

  @Getter('getPreferences', { namespace: 'users'}) getPreferences!: any;
  @Getter('getDonorsUrl', { namespace: 'users' }) private getDonorsUrl!: string;
  @Getter('getPanelStyles', { namespace: 'dashboard' }) getPanelStyles!: any[];

  public currentPage = 1;
  public perPage = 5;
  public errorMessage = null;

  get bookmarksExist(): boolean {
    return this.preferences.bookmarks && this.preferences.bookmarks > 0;
  }

  get bookmarksCount(): number {
    return this.editState && this.editState.entries ? this.editState.entries.length : 0;
  }

  public selectBookmark(event: any) {
    const bookmarks: Bookmark[] = this.editState?.entries || [];

    if (event.row.name !== undefined) {
      const name: string = event.row.name;
      const selectedBookmark = bookmarks.filter((b: Bookmark)  => b.name === name )[0];
      if(selectedBookmark) {
        this.$store.commit('pageState/set', {
          pageKey: 'currentBookmark',
          value:{
            index: selectedBookmark.index,
            name: selectedBookmark.name,
            link: selectedBookmark.link
          },
        });
        this.resetSaveToolbar();
      }
    } else {
      console.warn(this.$t('missing_bookmark_name'));
      this.$router.push({name: this.getDonorsUrl});
    }
  }

  /**
   * Clears all save notifications shown by the form.
   *
   * Gets the Save Provider associated with the form, and requests that it reset its own Save Toolbar
   */
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    const saveToolbar = this.$refs.saveBookmark as unknown as SaveToolbar;
    // Reset the save provider's save toolbar
    (this.$refs.saveBookmarksValidations as any).reset();

    if(!saveToolbar) {
      return;
    }

    saveToolbar.reset();
  }

  public createBookmark() {
    this.initializeBookmarkForm();
  }

  public updatePagination(event: any) {
    this.currentPage = event.currentPage;
    this.perPage = event.currentPerPage;
  }

  // Commit edit state field model values to vue-x store
  saveSettings(): void {
    const newState = this.extractDashboardSettings(this.preferences, this.editState);
    this.$store.dispatch('users/savePreferences', { preferences: newState }).then((success: SaveResult) => {
      // If successful dismiss dialog
      this.dismiss();
      this.initializeBookmarkWidget();
      // Reinitialize if overall dashboard settings change
      this.$emit('reloadDashboard');
    }).catch((error: any) => {
      // Show error notification
      this.errorMessage = error.message;
    });
  }

  saveBookmarks(): void {
    const saveToolbar = this.$refs.saveBookmark as SaveToolbar;

    if(this.currentBookmark && this.currentBookmark.name !== '' ){
      this.$emit('saving', 'bookmark');
      this.createOrUpdateBookmark();
    }
    const newState = this.extractDashboardSettings(this.preferences, this.editState);
    this.$store.dispatch('users/savePreferences', { preferences: newState })
    .then((success: SaveResult) => {
      this.initializeBookmarkForm();
      saveToolbar.stopSaving(success);
      (this.$refs.saveBookmarksValidations as any).reset();
      this.initializeBookmarkWidget();
      // Reinitialize if overall dashboard settings change
      this.$emit('reloadDashboard');
    })
    .catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveToolbar.stopSaving(error);
    });
  }

  public dismiss(): void {
    $("#idBookmarks").collapse('toggle');
  }

  deleteBookmark(name: string) {
    const saveToolbar = this.$refs.saveBookmark as SaveToolbar;

    this.$emit('saving', 'bookmark');

    const bookmarks: Bookmark[] = this.editState?.entries || [];
    const filteredBookmarks = bookmarks.filter((b: Bookmark)  => b.name !== name );

    this.preferences.bookmarks.entries = filteredBookmarks;
    this.$store.dispatch('users/savePreferences', { preferences: this.preferences })
    .then((success: SaveResult) => {
      Vue.set(this.editState, 'entries', filteredBookmarks);
      this.initializeBookmarkForm();
      (this.$refs.saveBookmarksValidations as any).reset();
      saveToolbar.stopSaving(success);
      this.initializeBookmarkWidget();
      // Reinitialize if overall dashboard settings change
      this.$emit('reloadDashboard');
    })
    .catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveToolbar.stopSaving(error);
    });
  }

  createOrUpdateBookmark(): void{
    const bookmarks: Bookmark[] = this.editState?.entries || [];
    const existentBookmark = bookmarks.filter((b: Bookmark)  => this.currentBookmark && this.currentBookmark.index === b.index )[0];
    if(this.currentBookmark && existentBookmark) {
      existentBookmark.link = this.currentBookmark.link;
      existentBookmark.name = this.currentBookmark.name;
    }
    else{
      const newBookmark = Object.assign({ index: bookmarks.length }, this.currentBookmark);
      bookmarks.push(newBookmark);
    }
    Vue.set(this.editState, 'entries', bookmarks);
  }

  // Build new overall dashboard settings with new settings for this specific widget
  extractDashboardSettings(currentSettings: DashboardSettingState, editState: BookmarksWidgetSetting): DashboardSettingState {
    const newSettings = Object.assign({ bookmarks: {} }, currentSettings);
    newSettings.bookmarks = {
      style: editState.style,
      visible: editState.visible,
      entries: editState.entries
    };
    return newSettings;
  }

  mounted(): void {
    this.initializeBookmarkWidget();
    this.initializeBookmarkForm();
  }

  // Setup temporary edit state for unsaved widget settings form fields
  initializeBookmarkWidget(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'dashBookmarks',
      value: this.buildBookmarksEditState(this.preferences),
    });
  }

  // Setup temporary edit state for unsaved widget settings form fields
  initializeBookmarkForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'currentBookmark',
      value: {},
    });
  }

  // Build edit state based on overall dashboard settings
  buildBookmarksEditState(preferences: DashboardSettingState): BookmarksWidgetSetting {
    return Object.assign({}, preferences.bookmarks);
  }

  get tableData() {
    const bookmarks: Bookmark[] = this.editState?.entries || [];
    return bookmarks.map((bookmark: Bookmark, i: number) => {
      return {
        index: i || null,
        name: bookmark.name,
        link: `<a href='${bookmark.link}' target='_blank'>${bookmark.link}</a>`
      };
    });
  }

  /**
   * Gets configuration for the table
   *
   * @returns {TableConfig} Configuration for the table
   */
  get tableConfig(): TableConfig {
    return {
      data: this.tableData,
      columns: [
        { label: this.$t('name').toString(), field: 'name' },
        { label: this.$t('link').toString(), field: 'link', html: true},
        { label: this.$t('delete').toString(), field: 'delete', width: '5%' }
      ],
      empty: this.$t('use_form_below').toString(),
      createButton: true,
      createText: this.$t('create_bookmark').toString(),
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPage: this.perPage,
        setCurrentPage: this.currentPage,
        mode: 'pages',
        perPageDropdown: [5, 10, 25, 100],
        dropdownAllowAll: false,
        nextLabel: '',
        prevLabel: '',
        rowsPerPageLabel: this.$t('results_per_page').toString(),
        position: 'bottom'
      }
    };
  }
}
</script>
