import { render, staticRenderFns } from "./_PostDonationFollowUp.vue?vue&type=template&id=8b161c98"
import script from "./_PostDonationFollowUp.vue?vue&type=script&lang=ts"
export * from "./_PostDonationFollowUp.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./_locales/PostDonation.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fprototypes%2Fliving_donor%2Forgans%2F_PostDonationFollowUp.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports