<template>
  <div v-if="isVerbose && isVxm && isEmpty">
    {{ isUntested ? $t('untested_vxm_result_long') : $t('empty_vxm_result') }}
  </div>
  <div v-else class="hla-input-group">
    <hla-input
      :value="value"
      :inputId="inputId"
      :name="name"
      :validationId="validationId"
      :label="label"
      :disabled="disabled"
      :placeholder="alleleGroupPlaceholder"
      :inputClass="inputClass"
      :disabledEmptyText="disabledEmptyText"
      :hideLabel="hideLabel"
      :highlightSelection="highlightSelection"
      :selectedAntibodies="selectedAntibodies"
      :readonly="readonly"
      :inferLocus="inferLocus"
      :rules="rules"
      :ruleKey="ruleKey"
      :crossValues="crossValues"
      @input="onAlleleGroupInput"
      @selected="onSelected"
      :invalidAntibodies="invalidAntibodies"
    />
    <hla-input
      v-if="showAlleleSpecific"
      :hideLabel="true"
      :disabled="disabled"
      :inputClass="inputClass"
      :disabledEmptyText="disabledEmptyText"
      :highlightSelection="highlightSelection"
      :selectedAntibodies="selectedAntibodies"
      :readonly="readonly"
      :inferLocus="inferLocus"
      :value="alleleSpecific"
      :inputId="alleleSpecificId"
      :name="alleleSpecificName"
      :placeholder="alleleSpecificPlaceholder"
      @input="onAlleleSpecificInput"
      @selected="onSelected"
      :invalidAntibodies="invalidAlleleSpecifics"
    />
    <hla-input
      v-if="showAlphaBeta"
      :hideLabel="true"
      :disabled="disabled"
      :inputClass="inputClass"
      :disabledEmptyText="disabledEmptyText"
      :highlightSelection="highlightSelection"
      :selectedAntibodies="selectedAntibodies"
      :readonly="readonly"
      :inferLocus="inferLocus"
      :value="alphaBeta"
      :inputId="alphaBetaId"
      :name="alphaBetaName"
      :placeholder="alphaBetaPlaceholder"
      @input="onAlphaBetaInput"
      @selected="onSelected"
      :invalidAntibodies="invalidAlphaBetas"
    />
    <hla-input
      v-if="showEpitopes"
      :hideLabel="true"
      :disabled="disabled"
      :inputClass="inputClass"
      :disabledEmptyText="disabledEmptyText"
      :highlightSelection="highlightSelection"
      :selectedAntibodies="selectedAntibodies"
      :readonly="readonly"
      :inferLocus="inferLocus"
      :value="epitopes"
      :inputId="epitopesId"
      :name="epitopesName"
      :placeholder="epitopesPlaceholder"
      @input="onEpitopesInput"
      @selected="onSelected"
      :invalidAntibodies="invalidEpitopes"
    />
  </div>
</template>

<i18n src="@/components/hla/_locales/HlaVirtualCrossmatchResult.json"></i18n>
<i18n src="@/components/shared/_locales/HlaInputGroup.json"></i18n>

<script lang="ts">
import '@/vee-validate-rules.ts';
import { ClassObject } from '@/types';
import { Getter, State } from 'vuex-class';
import { Rules } from '@/store/validations/types';
import { mergeClasses, uniqueElements } from '@/utils';
import HlaInput from '@/components/shared/HlaInput.vue';
import { HlaAntibodyTag, HlaTypingTag } from '@/store/labs/types';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ValidationAsterisk from '@/components/shared/ValidationAsterisk.vue';
import { VueTagsInput, createTag, createTags } from '@johmun/vue-tags-input';
import { VXM_VERBOSE_DISPLAY } from "@/store/administration/types";

@Component({
  components: {
    HlaInput,
    VueTagsInput,
    ValidationAsterisk
  }
})
export default class HlaInputGroup extends Vue {
  // Getters
  @Getter('parseHlaAntibodyTag', { namespace: 'labs' }) parseHlaAntibodyTag!: (rawInput: string) => HlaAntibodyTag;
  @Getter('extractAlleleSpecific', { namespace: 'labs', }) extractAlleleSpecific!: (antibodies: string[]) => string[];
  @Getter('extractAlphaBetaSpecific', { namespace: 'labs', }) extractAlphaBetaSpecific!: (antibodies: string[]) => string[];

  // V-model
  @Prop({ default: () => { return []; } }) value!: string[];
  @Prop({ default: () => { return []; } }) alleleSpecific!: string[];
  @Prop({ default: () => { return []; } }) alphaBeta!: string[];
  @Prop({ default: () => { return []; } }) epitopes!: string[];
  @Prop({ default: () => { return []; } }) invalidAntibodies!: string[];
  @Prop({ default: () => { return []; } }) invalidAlleleSpecifics!: string[];
  @Prop({ default: () => { return []; } }) invalidAlphaBetas!: string[];
  @Prop({ default: () => { return []; } }) invalidEpitopes!: string[];

  // Properties from HLA Input
  @Prop({ required: true }) inputId!: string; // MANDATORY actual HTML element ID, set indirectly using properties like 'inputId' and 'selectId'
  @Prop({ required: true }) name!: string; // Field name, also used as the label
  @Prop({ default: null }) validationId!: string; // OPTIONAL specify a 'vid' property for validation-provider, if it must be different than the element ID
                                                  // used by parent component after attempting to save to decide where server-side validation errors are shown
  @Prop({ default: null }) label!: string; // Alternate Label property
  @Prop({ default: false }) disabled!: boolean; // Turn input data entry off
  @Prop({ default: '' }) placeholder!: string; // Placeholder for Vue Tags Input
  @Prop({ default: 'hla-input' }) inputClass!: string; // Class string for Vue Tags Input component
  @Prop() disabledEmptyText!: string; // Value of static input element when disabled and empty
  @Prop({ default: false }) hideLabel!: boolean; // Hide label visually, while still being readable for screen readers
  @Prop({ default: false }) highlightSelection!: boolean; // When true antibody tags selected elsewhere are highlighted with validation classes
  @Prop({ default: () => { return []; } }) selectedAntibodies!: string[]; // Which antibody tags have been selected elsewhere and need highlighting
  @Prop({ default: false }) readonly!: boolean; // Render input as non-interactive tag badges and turn input data entry off
  @Prop({ default: false }) inferLocus!: boolean; // When true, use most recent locus letter if parsing fails (used to paste list of antibodies)
  @Prop({ default: null }) rules!: string; // OPTIONAL lets us hard-code the client-side vee-validate rules in the front-end instead of using anything provided by the back-end
  @Prop({ default: '' }) ruleKey!: string;
  @Prop({ default: null }) crossValues!: any; // valus needed for cross field validation for the asterix
  @Prop({ default: null }) vxm!: string|null; // set to top-level VXM result to support display rules, i.e. distinguished Negative vs Untested

  // Properties unique to HLA Input Group
  @Prop({ default: false }) enableEpitopes!: boolean; // When true, adds a fourth row for epitope data. Used for Class 1 groups

  private onAlleleGroupInput(eventDetails: any): void {
    // Fetch related HLA Input values
    let alleleSpecific = this.alleleSpecific || [];
    let alphaBeta = this.alphaBeta || [];
    let epitopes = this.epitopes || [];
    // Check raw input for each type of antibody;
    let sanitizedAlleleGroup: string[] = [];
    const rawAlleleGroup: string[] = eventDetails || [];
    rawAlleleGroup.forEach((antibody: string) => {
      // Identify the type of antibody
      const parsed = this.parseHlaAntibodyTag(antibody) || {};
      const standardizedText = parsed.standardText;
      const standardizedArray = standardizedText ? [standardizedText] : [];
      const alleleSpecificGroups = this.extractAlleleSpecific(standardizedArray);
      const alphaBetaGroups = this.extractAlphaBetaSpecific(standardizedArray);
      const isAlphaBeta = alphaBetaGroups.length > 0;
      const isAlleleSpecific = !isAlphaBeta && alleleSpecificGroups.length > 0;
      const isEpitope = parsed.epitopes && parsed.epitopes.length > 0;
      // Precedence: Alpha-beta > Allele-specific > Epitope > Allele group
      if (isAlphaBeta) {
        // Add new antibody to Alpha-beta
        alphaBeta.push(antibody);
      } else if (isAlleleSpecific) {
        // Add new antibody to Allele-specific
        alleleSpecific.push(antibody);
      } else if (isEpitope && this.enableEpitopes) {
        // Add new antibody to Epitopes
        epitopes.push(antibody);
      } else {
        // Keep antibody in Allele group
        sanitizedAlleleGroup.push(antibody);
      }
    });

    // Remove duplicates
    sanitizedAlleleGroup = uniqueElements(sanitizedAlleleGroup);
    alleleSpecific = uniqueElements(alleleSpecific);
    alphaBeta = uniqueElements(alphaBeta);
    epitopes = uniqueElements(epitopes);

    // Emit each updated list of antibodies
    this.$emit('input', sanitizedAlleleGroup);
    this.$emit('alleleSpecificInput', alleleSpecific);
    this.$emit('alphaBetaInput', alphaBeta);
    this.$emit('epitopesInput', epitopes);
  }

  private onAlleleSpecificInput(eventDetails: any): void {
    // Fetch related HLA Input values
    let alleleGroup = this.value || [];
    let alphaBeta = this.alphaBeta || [];
    let epitopes = this.epitopes || [];
    // Check raw input for each type of antibody;
    let sanitizedAlleleSpecific: string[] = [];
    const rawAlleleSpecific: string[] = eventDetails || [];
    rawAlleleSpecific.forEach((antibody: string) => {
      // Identify the type of antibody
      const parsed = this.parseHlaAntibodyTag(antibody) || {};
      const standardizedText = parsed.standardText;
      const standardizedArray = standardizedText ? [standardizedText] : [];
      const alleleSpecificGroups = this.extractAlleleSpecific(standardizedArray);
      const alphaBetaGroups = this.extractAlphaBetaSpecific(standardizedArray);
      const isAlphaBeta = alphaBetaGroups.length > 0;
      const isAlleleSpecific = !isAlphaBeta && alleleSpecificGroups.length > 0;
      const isEpitope = parsed.epitopes && parsed.epitopes.length > 0;
      // Precedence: Alpha-beta > Allele-specific > Epitope > Allele group
      if (isAlphaBeta) {
        // Add new antibody to Alpha-beta
        alphaBeta.push(antibody);
      } else if (isAlleleSpecific) {
        // Keep antibody in Allele-specific
        sanitizedAlleleSpecific.push(antibody);
      } else if (isEpitope && this.enableEpitopes) {
        // Add new antibody to Epitopes
        epitopes.push(antibody);
      } else {
        // Add new antibody to Allele group
        alleleGroup.push(antibody);
      }
    });

    // Remove duplicates
    alleleGroup = uniqueElements(alleleGroup);
    sanitizedAlleleSpecific = uniqueElements(sanitizedAlleleSpecific);
    alphaBeta = uniqueElements(alphaBeta);
    epitopes = uniqueElements(epitopes);

    // Emit each updated list of antibodies
    this.$emit('input', alleleGroup);
    this.$emit('alleleSpecificInput', sanitizedAlleleSpecific);
    this.$emit('alphaBetaInput', alphaBeta);
    this.$emit('epitopesInput', epitopes);
  }

  private onAlphaBetaInput(eventDetails: any): void {
    // Fetch related HLA Input values
    let alleleGroup = this.value || [];
    let alleleSpecific = this.alleleSpecific || [];
    let epitopes = this.epitopes || [];
    // Check raw input for each type of antibody;
    let sanitizedAlphaBeta: string[] = [];
    const rawAlphaBeta: string[] = eventDetails || [];
    rawAlphaBeta.forEach((antibody: string) => {
      // Identify the type of antibody
      const parsed = this.parseHlaAntibodyTag(antibody) || {};
      const standardizedText = parsed.standardText;
      const standardizedArray = standardizedText ? [standardizedText] : [];
      const alleleSpecificGroups = this.extractAlleleSpecific(standardizedArray);
      const alphaBetaGroups = this.extractAlphaBetaSpecific(standardizedArray);
      const isAlphaBeta = alphaBetaGroups.length > 0;
      const isAlleleSpecific = !isAlphaBeta && alleleSpecificGroups.length > 0;
      const isEpitope = parsed.epitopes && parsed.epitopes.length > 0;
      // Precedence: Alpha-beta > Allele-specific > Epitope > Allele group
      if (isAlphaBeta) {
        // Keep antibody in Alpha-beta
        sanitizedAlphaBeta.push(antibody);
      } else if (isAlleleSpecific) {
        // Add new antibody to Allele-specific
        alleleSpecific.push(antibody);
      } else if (isEpitope && this.enableEpitopes) {
        // Add new antibody to Epitopes
        epitopes.push(antibody);
      } else {
        // Add new antibody to Allele group
        alleleGroup.push(antibody);
      }
    });

    // Remove duplicates
    alleleGroup = uniqueElements(alleleGroup);
    alleleSpecific = uniqueElements(alleleSpecific);
    sanitizedAlphaBeta = uniqueElements(sanitizedAlphaBeta);
    epitopes = uniqueElements(epitopes);

    // Emit each updated list of antibodies
    this.$emit('input', alleleGroup);
    this.$emit('alleleSpecificInput', alleleSpecific);
    this.$emit('alphaBetaInput', sanitizedAlphaBeta);
    this.$emit('epitopesInput', epitopes);
  }

  private onEpitopesInput(eventDetails: any): void {
    // Fetch related HLA Input values
    let alleleGroup = this.value || [];
    let alleleSpecific = this.alleleSpecific || [];
    let alphaBeta = this.alphaBeta || [];
    // Check raw input for each type of antibody;
    let sanitizedEpitopes: string[] = [];
    const rawEpitopes: string[] = eventDetails || [];
    rawEpitopes.forEach((antibody: string) => {
      // Identify the type of antibody
      const parsed = this.parseHlaAntibodyTag(antibody) || {};
      const standardizedText = parsed.standardText;
      const standardizedArray = standardizedText ? [standardizedText] : [];
      const alleleSpecificGroups = this.extractAlleleSpecific(standardizedArray);
      const alphaBetaGroups = this.extractAlphaBetaSpecific(standardizedArray);
      const isAlphaBeta = alphaBetaGroups.length > 0;
      const isAlleleSpecific = !isAlphaBeta && alleleSpecificGroups.length > 0;
      const isEpitope = parsed.epitopes && parsed.epitopes.length > 0;
      // Precedence: Alpha-beta > Allele-specific > Epitope > Allele group
      if (isAlphaBeta) {
        // Add new antibody to Alpha-beta
        alphaBeta.push(antibody);
      } else if (isAlleleSpecific) {
        // Add new antibody to Allele-specific
        alleleSpecific.push(antibody);
      } else if (isEpitope && this.enableEpitopes) {
        // Keep antibody in Epitopes
        sanitizedEpitopes.push(antibody);
      } else {
        // Add new antibody to Allele group
        alleleGroup.push(antibody);
      }
    });

    // Remove duplicates
    alleleGroup = uniqueElements(alleleGroup);
    alleleSpecific = uniqueElements(alleleSpecific);
    alphaBeta = uniqueElements(alphaBeta);
    sanitizedEpitopes = uniqueElements(sanitizedEpitopes);

    // Emit each updated list of antibodies
    this.$emit('input', alleleGroup);
    this.$emit('alleleSpecificInput', alleleSpecific);
    this.$emit('alphaBetaInput', alphaBeta);
    this.$emit('epitopesInput', sanitizedEpitopes);
  }

  private onSelected(eventDetails: any): void {
    this.$emit('selected', eventDetails);
  }

  get showAlleleSpecific(): boolean {
    const antibodies = this.alleleSpecific || [];
    return antibodies.length > 0 || this.showAlphaBeta || this.showEpitopes;
  }

  get showAlphaBeta(): boolean {
    const antibodies = this.alphaBeta || [];
    return antibodies.length > 0;
  }

  get showEpitopes(): boolean {
    const enabled = this.enableEpitopes || false;
    const antibodies = this.epitopes || [];
    return enabled && antibodies.length > 0;
  }

  get alleleGroupPlaceholder(): string {
    if (this.readonly || this.disabled) {
      return '';
    }
    const alleleGroupOnly = !this.showAlleleSpecific && !this.showAlphaBeta;
    return alleleGroupOnly ? this.placeholder : this.$t('add_allele_group_antibody').toString();
  }

  get alleleSpecificPlaceholder(): string {
    if (this.readonly || this.disabled) {
      return '';
    }
    return this.$t('add_allele_specific_antibody').toString();
  }

  get alphaBetaPlaceholder(): string {
    if (this.readonly || this.disabled) {
      return '';
    }
    return this.$t('add_alpha_beta_antibody').toString();
  }

  get epitopesPlaceholder(): string {
    if (this.readonly || this.disabled) {
      return '';
    }
    return this.$t('add_epitope_antibody').toString();
  }

  get alleleSpecificId(): string {
    const base = this.inputId || this.$t('unknown_lower').toString();
    return `${base}_allele_specific`;
  }

  get alleleSpecificName(): string {
    const base = this.name || this.$t('unknown').toString();
    return `${base} allele-specific`;
  }

  get alphaBetaId(): string {
    const base = this.inputId || this.$t('unknown_lower').toString();
    return `${base}_alpha_beta`;
  }

  get alphaBetaName(): string {
    const base = this.name || this.$t('unknown').toString();
    return `${base} alpha-beta`;
  }

  get epitopesId(): string {
    const base = this.inputId || this.$t('unknown_lower').toString();
    return `${base}_epitopes`;
  }

  get epitopesName(): string {
    const base = this.name || this.$t('unknown').toString();
    return `${base} epitopes`;
  }

  // Use VXM display features specified by 2022-016 Change Request?
  get isVerbose(): boolean {
    return VXM_VERBOSE_DISPLAY || false;
  }

  // Are all of the antibody arrays empty?
  // NOTE: not affected by the 'invalid' arrays
  get isEmpty(): boolean {
    const numberOfValues = (this.value || []).length + (this.alleleSpecific || []).length + (this.alphaBeta || []).length + (this.epitopes || []).length;
    return numberOfValues === 0;
  }

  // Use VXM-related display rules?
  get isVxm(): boolean {
    return !!this.vxm || false;
  }

  // Should we show the Untested message?
  get isUntested(): boolean {
    if (!this.vxm) return false;

    return this.vxm === 'U';
  }
}
</script>
