<template>
  <card-section
    section-id="lab-section"
    :isAccordion="prototypeFeatureEnabled('proto_ability_to_expand_collapse')"
  >
    <template v-slot:header>
      {{ title }}
    </template>
    <template v-slot:body>
      <div data-v-b078911c="">
        <fieldset id="serology">
          <legend style="margin-bottom: 16px;">
            <h4 class="legend-title"><span>Serology results</span>
            <small class="w-100 d-flex my-2 guiding-text">Results for at least one test must be included in each entry</small></h4>
          </legend>
        </fieldset>
      </div>
        <div class="col-xs-12 d-flex flex-row-reverse">     
       
          <div class="form-group mb-0 align-self-end">
            <button type="button" class="btn btn-secondary btn-sm">Right</button>
          </div>
             <div class="form-group mb-0 mr-2 align-self-end">
            <button type="button" class="btn btn-secondary btn-sm">Left</button>
          </div>
        </div>
          <div class="row">
        <div class="col-sm-12">
             
          <div class="filterSectionWrapper">
            <div class="filter-section-action-row">
              <filter-component :displayFilter="displayFilter" @moveScroll="moveScroll" :serologyFilter="true" @updateFilter="updateFilter" fieldID='Serology' :setFilters='setFilters' :archivedFilter="true"/>
                 <button type="button" class="btn btn-primary createButton" @click.prevent="clearForm()">
                    Create Serology Result
                  </button>
            </div>
          </div>
       
      
        
        </div>
          </div>
      <div class="row">
        <div class="col-sm-12 mb-4">
          <div id="wrapper1" class="mt-4">
            <div id="fake-div"></div>
          </div>         
          <scroll-shadow>
            <div id="wrapper2">
              
              <div class="table-wrapper">
  
                <table class="table table-sm scroll-table">
                  <tbody id="first">
                    <tr class="header-row"
                      style="background: rgba(33, 37, 41, 0.04)">
                        <th scope="column" class="th-row-heading th-no-hover th-row table-cell-row-container">          
                          <div class="table-cell-row">Date</div>
                          <div class="table-cell-row">Time</div>
                        </th>
                        <template v-for="item in tableData.date_row">
                          <th class="table-cell-row-container th-top"  :class="`${item.sample_id} ${item.sample_id == selectedCol ? 'p-selected-col' : ''}`" :id="item.sample_id" @mouseover="setSelected($event, item.sample_id, false)" 
                           @click="setSelected(event, item.sample_id, true)"  :key="`${item.sample_id}`">
                            <a href="#" class="col-header-link">
                                <div class="table-cell-row">{{item.sample_date}}</div>
                                <div class="table-cell-row">{{item.sample_time}}</div>  
                            </a>
                          </th>
                        </template>
                      </tr>

                      <template v-for="(row, index) in tableData.test_rows">
                      <tr :key="index" :id="`row-${row.test_code}`">
                        <th class="th-row"  scope="row">
                          <span :title="row.test_name">{{row.test_name}}</span>
                        </th>
                        <template v-for="(result, index) in row.test_results">    
                          <td :class="`${result.sample_id} ${(result.sample_result).toLowerCase()}-result test-result ${result.sample_id == selectedCol ? 'p-selected-col' : ''} `"  :key="index">
                            <div class="wrapper-result">
                              <div>{{getResult(result.sample_result)}}</div>
                              <Tooltip v-if="result.sample_comments && result.sample_comments.length" icon="comment"  :toolTipText="result.sample_comments" :inputID="result.sample_id"/>
                            </div>
                          </td>
                        </template>
                      </tr>
                    </template>

          

                  </tbody>
                </table>
    
              </div>
                          
            </div>
          </scroll-shadow>
        </div>
      
          <div class="col-sm-12 w-100">
            <sub-section
              title='New Serology Result'
              sub-section-id="create-edit-form"
            >
            <template v-slot:contents>
        
                <fieldset>
                        <div class="row">
                    
                <div class="standard-form-group">
                    <date-input
                      inputId="startDate"
                      name="Collection Date"
                      v-model="resultDate"
                    />
                </div>
                <div class="standard-form-group">
                  <time-input
                    inputId="results-time"
                    name='Collection Time (ET)'
                    v-model="resultTime"
                  />
        
                </div>
                <div class="standard-form-group-large">
                  <text-area-input
                    inputId="results-comments"
                    name="Collection Comments"
                    v-model="resultComments"
                  />
                </div>
         
       
              </div>
                </fieldset>
           
            </template>
            </sub-section>
          </div>
        <div class="col-sm-12 mb-4">
          <fieldset>
            <fieldset class="legend-title-h6" v-if="selectedData.length > 0">
              <fieldset>
                <legend style="width: auto !important">
                  <h6 class="legend-title" style="">Results</h6>
                </legend>
              </fieldset>
              <div class="row iterative-form-section w-100">
                <div class="col-sm-12 mb-4">

                  <!-- {{ { "test_code": "CMV_IGG_AB", "test_name": "Cytomegalovirus - IgG Antibody", "sample_result": "Indeterminate", "sample_comments": "fvkldfnvjkldfjvlfkj", "result_date": "11/01/2023", "result_time": "09:03", "actions": "actions" }}} -->
                  <DataTable :showGridlines="true" :value="selectedData" responsiveLayout="stack">
                    <Column field="test_code" header="Marker" className="small-width-column">
                      <template #body="slotProps">
                        <select-input
                          select-id="discontinue-reason"
                          hideLabel="true"
                          rules="required"
                          name="test_code"
                          v-model="slotProps.data.test_code"
                          :options="markerList"
                        />
                      </template>
                    </Column>
                    <Column field="sample_result" header="Results">
                      <template #body="slotProps">
                        <select-input
                          select-id="discontinue-reason"
                          hideLabel="true"
                          rules="required"
                          name="sample_result"
                          v-model="slotProps.data.sample_result"
                          :options="resultOptions"
                        />
                      </template>
                    </Column>
                    <Column field="sample_comments" header="Result Comments">

                        <template #body="slotProps">
                          <text-area-input
                        inputId="results-comments"
                            name="results-comments"
                        hideLabel="true"
                        v-model="slotProps.data.sample_comments"
                      />
                      </template>

                  
                    </Column>
                    <Column field="result_date" header="Date">
                      <template #body="slotProps">
                        <date-input
                          inputId="startDate"
                        hideLabel="true"
                                name="results-date"
                          v-model="slotProps.data.result_date"
                        />
                      </template>
                    </Column>
                    <Column field="result_time" header="Result Time">
                      <template #body="slotProps">
                        <time-input
                        inputId="results-time"
                        hideLabel="true"
                        name="results-time"
                          v-model="slotProps.data.result_time"
                      />
                      </template>
                    </Column>
                    <Column field="actions" header="Actions">
                        <template #body="">
                          
                              <button
                type="button"
                class="btn btn-sm btn-danger"
              
              
              >
              Remove
              </button>
                        </template>
                    </Column>
                    <template #footer> 
                      <div class="d-flex flex-row-reverse">
                              <button
                type="button"
                class="btn btn-sm btn-primary"
              
              
              >
              Add
              </button></div></template>
                    

              
                  </DataTable>
                </div>


        
              </div>
            </fieldset>
            
            <div
              class="save-toolbar card-footer action-row temp-saving row d-flex">
              <button type="button" class="btn btn-success sec-submit">
                Save Serology Results</button
              ><button type="button" class="btn btn-wide btn-secondary ml-auto">
                Cancel
              </button>
            </div>
          </fieldset>
      </div>   
      </div> 
    
   
      
    </template>
  </card-section>
</template>




<script lang="ts">
import { Getter, State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import CardSection from "@/components/shared/CardSection.vue";
import DateInput from '@/components/shared/DateInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import TimeInput from '@/components/shared/TimeInput.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import tablejson from './json/Serlogyjson.json';
import TextInput from '@/components/shared/TextInput.vue';
import 'scroll-shadow-element';
import Tooltip from '@/components/shared/Tooltip.vue';
import SubSection from '@/components/shared/SubSection.vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
  import FilterComponent from '@/components/prototypes/shared/_FilterComponent.vue';



@Component({
  components: {
    CardSection,
    SubSection,
    DateInput,
    TimeInput,
    Tooltip, 
    TextInput,
    TextAreaInput,
    DataTable,
    SelectInput,
    Column,
    FilterComponent
  }
})
export default class LabSection extends Vue {  
  title = 'Serology';
  startDate = '';
  endDate= '';
  otherFilter="";
  tableData = tablejson;
  originalData = tablejson;
  offsetWidth = 0;
  displayFilter=true
  displayJson = {};
  private setFilters: any = {};
  private selectedCol = '';
  private selectedData = [];
  private resultDate = '';
  private resultTime = '';
  private resultComments = '';

  private markerList = [{
    code: "CMV_IGG_AB",
    value: 'Cytomegalovirus - IgG Antibody'
  }, {
    code: "CMV_IGM_AB",
    value: 'Cytomegalovirus - IgM Antibody'
  },{
    code: "EBV_NUCLEAR_AB",
    value: 'Epstein-Barr Virus - Nuclear Antigen IgG Antibody'
  }, {
    code: "EBV_CAPSID_AB",
    value: 'Epstein-Barr Virus - Capsid Antigen IgG Antibody'
  }, {
    code: "EBV_EARLY_AB",
    value: 'Epstein-Barr Virus - Early Antigen IgG Antibody'
  }, {
    code: 'HBV_CORE_AB',
    value: 'Hepatitis B Core Antibody'
  },{
    code: "HBV_AB",
    value: 'Hepatitis B Surface Antibody'
  }, {
    code: "HCV_AB",
    value: 'Hepatitis C Antibody'
  }, {
    code: "HBV_NAT",
    value: 'Hepatitis B NAT'
  }, {
    code: "HBV_Suf",
    value: 'Hepatitis B Surface Antigen'
  }, {
    code: 'HCV_NAT',
    value: 'Hepatitis C NAT'
  },{
    code: "HSV_AB",
    value: 'Herpes Simplex 1 / Herpes Simplex 2 IgG Antibody'
  }, {
    code: "HIV",
    value: 'Human Immunodeficiency Virus 1/2 Antibody/Antigen'
  }, {
    code: "HIV_NAT",
    value: 'Human Immunodeficiency Virus NAT'
  }, {
    code: "HTLV1",
    value: 'HTLV-1'
  }, {
    code: 'TOXOPLASMOSIS',
    value: 'Toxoplasmosis - IgG'
  }, {
    code: "VDRL",
    value: 'Syphilis (VDRL)'
  }, {
    code: 'VZV_AB',
    value: 'Varicella Zoster Virus - IgG Antibody'
  }, {
    code: "MEASLES_AB",
    value: 'Measles IgG Antibody'
  }, {
    code: 'MUMPS_AB',
    value: 'Mumps IgG Antibody'
  }, {
    code: 'RUBELLA_AB',
    value: 'Rubella IgG Antibody'
  }];


      private updateFilter(filterArray: any) {
      if (filterArray.length == 0) {
        return false;
      }

      this.setFilters = filterArray;
    }


    private getResult(val: any) {
      return val ? val : '-';
    }


    public clearForm(): any {
      this.selectedData = [];
    }

  private scrollToMethod(id: any): any {


    const location = document.getElementById(id);

    if (location) {
      const elementPosition = location.getBoundingClientRect().top;
      const offsetPosition = elementPosition - 105;

      window.scrollBy({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  }

  private resultOptions = [
    {code: 'Negative', value: 'Negative'},
    {code: "Positive", value: 'Positive'},
    {code: 'Indeterminate', value: 'Indeterminate'}, 
    {code: "Pending", value: 'Pending'},
   ];


  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;

  mounted() {
    this.resizeScrollBar();
    this.displayJson = tablejson;
  }

  resizeScrollBar() {
    let wrapper1 = document.getElementById('wrapper1');
    let wrapper2 = document.getElementById('wrapper2');
    let first = document.getElementById('first');
    let collection = document.getElementsByClassName("th-row");
    let inputList = Array.prototype.slice.call(collection);
  
    this.offsetWidth = first!.offsetWidth;
    let fakediv = document.getElementById('fake-div');
 
    fakediv!.style.width = this.offsetWidth + 'px';
      
    wrapper1!.onscroll = function() {
      wrapper2!.scrollLeft = wrapper1!.scrollLeft;  
      if (wrapper2!.scrollLeft > 5) {

        inputList.map((col,i) => {
          col.classList.add("addBoxShadow");
        });

      } else {
        inputList.map((col,i) => {
          col.classList.remove("addBoxShadow");
        });
          }
    };

    wrapper2!.onscroll = function() {
        wrapper1!.scrollLeft = wrapper2!.scrollLeft;  
    };
    
  }

  pullValues(value: any, isClick: any) {
    let tableData = this.tableData;
    let collectionDate = '';
    let collectionTime = '';
    let collectionComments = '';

    let tempArray :any = [];


    Object.entries(tableData.date_row).map(function(key, index) {
       let item = key[1];

       if (item.sample_id == value) {
          collectionDate =  new Date(item.sample_date).toLocaleDateString('en-CA'),
          collectionTime = item.sample_time;
          collectionComments = item.sample_comments || '';
       }
      });



    Object.entries(tableData.test_rows).map(function(key, index) {
      let item = key[1];

      Object.entries(item.test_results).map(function(key, index) {
          let myItem = key[1];
         
          if (myItem.sample_id == value ) {
            tempArray.push({
              test_code: item.test_code, 
              test_name: item.test_name,
              sample_result: myItem.sample_result ? myItem.sample_result : 'Untested',
              sample_comments: myItem.sample_comments,
              result_date: myItem.result_date,
              result_time: myItem.result_time,
              actions: 'actions'
              });
            }

    

          });       
    });
    if (isClick == true) {

    
    this.selectedData = tempArray || [];
    this.resultDate = collectionDate;
    this.resultTime = collectionTime;
    this.resultComments = collectionComments;  
 

    
       setTimeout(() => {
        this.scrollToMethod('create-edit-form');
      }, 500);
    }
  }



  setSelected(event: any, value: any, isClick: any) {

    event?.preventDefault();
    
    this.selectedCol = value;
    this.pullValues(value, isClick);
  }

  replaceDashes(value : any) {
    return value.sample_date.replace(/\//g, "-");
  }

  public moveScroll(val: any): void {
    let toDateID = '';
    let selectedDate = new Date(val).setUTCHours(0,0,0,0);
    for (const [key, value] of Object.entries(this.tableData.date_row)) {
      if ( new Date(this.replaceDashes(value)).setUTCHours(0,0,0,0) >= selectedDate && toDateID == '') {
        toDateID = value.sample_id;    
      } 
    }

    if (toDateID == '') {
      
      let first = Object.entries(tablejson.date_row)[0][1];
      let last = Object.entries(tablejson.date_row)[Object.entries(tablejson.date_row).length - 1] [1];
      
      if (selectedDate > new Date(last.sample_date).setUTCHours(0,0,0,0) ) {
        toDateID = last.sample_id;
      } else {
        toDateID = first.sample_id;
      }
    }
    
    let pos = $('#' + toDateID);

    let offset = pos.offset();
    let wrapper2 = document.getElementById('wrapper2');
    
    let sidebar = (document.querySelector('.menu-header-nav') as HTMLElement).offsetWidth;

    if (offset && wrapper2 && sidebar) {
      wrapper2.scrollLeft +=  offset.left - sidebar - 305 - 100;

      toDateID = '';
    } 
  
     
  }
  
 
  
}
</script>

<style>
 /* For the "lab panels" table */

.scroll-table {

  font-size: .875rem;
  margin-bottom: 0 !important;
}
/* .scroll-table tr th {
  background-color: #f5f5f5 !important;
} */
.scroll-table th {
  display: flex;
  /* -webkit-box-shadow:3px 0 2px -2px rgba(0,0,0,.3137254901960784);
  box-shadow:3px 0 2px -2px rgba(0,0,0,.3137254901960784); */
  position:sticky !important;
  z-index:299;
  left: 1px;
  text-align:left;
  text-indent: 0 !important;
  border-width:1px!important;
  min-width:230px!important;
    max-width:230px!important;
 
}
.scroll-table th span {

   white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  


}

.accordion.closed svg {
    transform: rotate(-90deg);
  }


.addBoxShadow:before  {
  content: "";
  position: absolute;
  right: -21px;
  height: 150px;
  width: 1px;
  z-index: -1;
  top: 7px;
  width: 32px;
  height: 48%;
  background: linear-gradient(transparent, #ccc);
  content: '';
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
}


.table-cell-row-container.addBoxShadow:before  {
    right: -42px;
    top: 16px;
    width: 72px;
    height: 28%;
}



.scroll-table tr td:not(:first-child) {
  text-align: center;
  box-shadow: 1px 0 0 #dee2e6 inset;
  border-left: 1.5px solid transparent;
}

.scroll-table tr:hover {
  background-color: #ecfaff;
}

.scroll-table tr:hover td {
  background-color: transparent;
}

.scroll-table tr:hover .th-row {
  background-color: white;
}

.scroll-table .p-selected-row {
 background-color: #ecfaff;
}

.scroll-table .p-selected-row .th-row {
  background-color: white;
}

.scroll-table .p-selected-col {
  background-color: #ecfaff;
}

.table-bordered th:not(.has-style), .table-bordered th:not(.vgt-small-column), .vue-good-table-bordered th:not(.has-style), .vue-good-table-bordered th:not(.vgt-small-column) {
  font-size: .875rem !important;
}

.scroll-table th, .scroll-table td {
  border-bottom: none !important;
}
.scroll-table td {
  border-right: none !important;
  text-align: center;
  position: relative !important;
  display: table-cell;
  z-index: auto;

  /* display: flex; */
}


.scroll-table .table-cell-row-container.th-top {
  position: relative !important;
  display: table-cell;
  min-width: 120px !important;
  max-width: 120px !important;
  z-index: auto;
}

.scroll-table  td.test-result {
  min-width: fit-content !important;
  vertical-align: middle;
}
.scroll-table td:last-child {
  border-right: 1px solid #dee2e6 !important;
}
.scroll-table  tr:last-child th, .scroll-table  tr:last-child td{
  border-bottom: 1px solid #dee2e6 !important;
}
.btn-xs {
  padding: 0 .5rem;
  font-size: .7rem;
  letter-spacing: 0.02rem;
}
.scroll-table th {
  text-indent: 1.5rem;

}
.scroll-table th.th-row-heading {
  text-indent: 0;

}
.scroll-table th {
  background: rgb(245, 245, 245);
  border-left: none !important;
  align-items: center;
}

.scroll-table th.th-row {
  border-left: 1px solid #dee2e6 !important;
}

.scroll-table th.th-row-heading {
  text-wrap: nowrap;
  font-weight: 700 !important;

}
.scroll-table th.th-row-heading span {
  padding-left: .5rem;
}


#wrapper1 {height: 20px; }

.fake-div {
width: 100%;
  height: 20px;
}

#wrapper1, #wrapper2{ 
overflow-x: auto; overflow-y:hidden;}
#wrapper1{height: 20px; }


.historical-transplants ul {
  padding-left:0
}
.list-inline-item:not(:last-child) {
  margin-right:.5rem!important;
}
.historical-transplants ul li:after {
  padding-left:1rem;
  font-weight:700;
  content:"\2215";
  margin-right:.25rem!important
}
.historical-transplants ul li:last-child:after {
  content:""
}
.scroll-table table tbody {
  border-top: none !important;
}

.table-section {
  display: none;
}

.table-section.show {
  display: contents;
}

.positive-result {
  color: #b43d02;
}

.wrapper-result {
  display: inline-flex;
}
.wrapper-result .tooltip {
  right: -1.2rem;
  z-index: -9;
  top: 6px;
}

.wrapper-result .tooltip-box {
  position: relative;
  top: 9px;
  right: -4px;

}
.wrapper-result .tooltip-toggle {
  padding: 0 0.75rem 0.5rem 0;
   
}



scroll-shadow {
  overflow: auto;
  width: 100%;
	display: inline-block;
	--scroll-shadow-size: 14;
	--scroll-shadow-left: linear-gradient(to left, transparent 0%, #0003 100%);
	--scroll-shadow-right:  linear-gradient(to right, transparent 0%, #0003 100%);
}

@media (max-width: 1500px) { 
    .scroll-table.table-bordered th:not(.vgt-small-column){
      min-width: 383px !important;
    }

}




.table-cell-row {
  width: 100%;
}
.btn.btn-outline-secondary {
  border-color: #6c757d !important;
}
.top-row .btn {
  margin-top: 0;
}
.table-cell-row-container{
  flex-direction: column;
  padding: 0 !important;
  border: none;
}
.scroll-table .table-cell-row-container.th-top {
  position: relative !important;
  display: table-cell;
  min-width: 120px !important;
  max-width: 120px !important;
  z-index: auto;
}
.table-cell-row {
  padding: .3rem;
}
.th-top .table-cell-row {
  display: block;
  text-align: center;
  border-left: 1px solid #dee2e6 !important;
  /*! padding-right: 1.4rem; */ /*! Total hack for now - won't centre without offset */
}
.th-top:first-child .table-cell-row {
  border-left: none !important;
}
.table-cell-row:first-of-type {
  border-bottom: 1px solid #c2c9d1 !important;
}

.scroll-table .table-sm td {
  padding: 0.5rem;
}

.pending-result  {
  background-color: #ffffeb;
}
</style>

