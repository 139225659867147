<template>
  <sub-section
    title="HLA Comments"
    sub-section-id="hla-comments"
    saveButtonText="Save HLA Comments"
    ref="saveHlaComments"
    :saveButton="true"
    :disabled="!canSave"
    @save="savePatch()">
    <template v-slot:contents>
      <template v-if="!editState">
        Loading...
        {{editState}}
      </template>
      <fieldset v-else :disabled="!canSave">
        <div class="row">
          <div class="standard-form-group-large">
            <text-area-input
              inputId="hla-comments-comments"
              name="Comments"
              v-model="editState.hlaAntibodiesComments"
            />
          </div>
          <div class="standard-form-group">
            <date-input
              inputId="hla-comments-updated-at"
              name="Updated At"
              :readonly="true"
              v-model="editState.hlaCommentsUpdatedAt" />
          </div>
        </div>
      </fieldset>
    </template>
  </sub-section>
</template>

<script lang="ts">
import { Getter, State } from 'vuex-class';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import { Recipient, RecipientDiagnosticsHla } from '@/store/recipients/types';

// Declare all v-model fields used in the form
export interface HlaCommentsForm {
  hlaAntibodiesComments?: string;
  hlaCommentsUpdatedAt?: string;
}

@Component({
  components: {
    TextAreaInput,
    DateInput,
    SubSection,
    SaveToolbar,
  }
})
export default class HlaAntibodiesComments extends Vue implements SaveableSection {
  // State
  @State(state => state.pageState.currentPage.hlaAntibodiesComments) editState!: HlaCommentsForm;
  @State(state => state.recipients.selectedDiagnosticsHla) private diagnosticsHla!: RecipientDiagnosticsHla

  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;

  // Properties
  @Prop({ default: false }) newRecipient!: boolean;
  @Prop({ default: false }) canSave!: boolean;


  @Getter('show', { namespace: 'recipients' }) private recipient!: Recipient;

  // Copy relevant data from recipient into the pageState
  public initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'hlaAntibodiesComments',
      value: this.buildHlaCommentsForm(this.recipient)
    });
    setTimeout(() => {
      this.$emit('clear');
    }, 500);
  }

  // Extract relevant parts from Recipient to match the form layout
  public buildHlaCommentsForm(recipient: Recipient): HlaCommentsForm {
    // Initialize form state for self, including sub-section states
    const hlaDiagnostics = this.diagnosticsHla || recipient.diagnostics?.hla;
    const result: HlaCommentsForm = {
      hlaAntibodiesComments: hlaDiagnostics.hla_comments,
      hlaCommentsUpdatedAt: hlaDiagnostics.hla_comments_updated_at
    };
    return result;
  }

  private clear() {
    this.$emit('clear');
  }

  // Translate the form structure into the diagnostics hla (comments) data structure
  public extractPatch(): RecipientDiagnosticsHla|null {
    if (this.editState) {
      return {
        hla_comments: this.editState.hlaAntibodiesComments,
      };
    } else {
      return null;
    }
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    // Note: We are not resetting measurements here because the parent saving does not save measurements.
    const gci = this.$refs.saveMeasurement as unknown as SaveProvider;
    // Reset the save provider's save toolbar
    gci.resetSaveToolbar();
  }

  /**
   * Saves the HLA Comments section
   *
   * Extracts the hla comments info from the editState and attempts to update the Recipient record.  This is a patch
   * update and only sends the `diagnostics_hla` key from the Recipient object.  The dispatch returns a SaveResult promise
   * with the Recipient record, or an error object with an array or errors the were returned.
   */
  // Handle saving triggered by local save button
  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveHlaComments as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'saveHlaComments');
    // Generate payload based on current edit state
    const diagnosticsHlaPatch = this.extractPatch();
    const recipientId = this.recipient.client_id;
    // Dispatch save action and register the response
    this.$store.dispatch('recipients/saveDiagnosticsHla', { recipientId, diagnosticsHla: diagnosticsHlaPatch }).then((success: SaveResult) => {
      // If successful, update the current recipient and show success notification
      this.$store.commit('recipients/setRecipientDiagnosticsHla', success.responseData.diagnostics_hlas);
      this.initializeForm();
      saveProvider.registerSaveResult(success);
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.handleErrors(error);
      this.$emit('handleWarnings', error, 'saveHlaComments');
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    return {
      'hla_comments' : 'hla-comments-comments',
    };
  }

  // Bubble up validation errors
  public handleErrors(errorResult: SaveResult): void {
    this.$emit('handleErrors', errorResult);
  }

}
</script>

