<template>
  <card-section section-id="proto_actions"
  :isAccordion="prototypeFeatureEnabled('proto_ability_to_expand_collapse')">
    <template v-slot:header>
      {{ title }}
    </template>
    <template v-slot:body>
      <div class="row">
        <div class="col-sm-12">
          <sub-section :title='`${title} List`' sub-section-id="recipients-uploaded-files">
            <template v-slot:contents>
              <div class="row">
                <!-- Uploaded Files -->
                <div class="col-sm-12">
                  <div class="row" style="width: 100%;">
                    <div class="standard-form-group d-flex align-items-end">
                      <span>
                        <div class="form-check form-check-inline">
                          <input id="gci-clinical-btv" v-model="showCompleted" aria-labelledby="gci-clinical-btv" type="checkbox" class="form-check-input">
                          <label for="gci-clinical-btv" class="form-check-label">
                            <span> Show Completed items </span>
                          </label>
                        </div>
                      </span>
                    </div>
                    <div class="standard-form-group-small-checkbox d-flex align-items-end"
                      style="margin-bottom: 0.75rem;">
                      <div class="row">
                        <div class="col-12" style="min-width: 150px;">
                          <span>
                            <label for="lab_code" style="min-width: 100%;">
                              Show Between
                            </label>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="standard-form-group-small d-flex align-items-end" style="margin-bottom: 0.75rem;">
                      <div class="row">
                        <div class="col-12">
                          <span>
                            <date-input input-id="effective_date" :name="$t('start_date')" hideLabel="true"
                              v-model="start_date" />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="standard-form-group-small d-flex align-items-end ml-2" style="margin-bottom: 0.75rem;">
                      <div class="row">
                        <div class="col-12">
                          <span>
                            <date-input input-id="effective_date" :name="$t('end_date')" hideLabel="true"
                              v-model="end_date" />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-1 d-flex align-items-center">
                      <button @click="clearDates()" type="button" class="btn btn-light btn-sm">
                        Reset
                      </button>
                    </div>
                  </div>

                  <div class="table-responsive-md">
                    <div>
                      <vue-good-table id='donorDocuments-uploadedFiles-table'
                        aria-labelledby='donorDocuments-uploadedFiles-label'
                        style-class='vgt-table table table-bordered table-hover' :columns="tableConfig.columns"
                        :rows="tableConfig.data" :sortOptions="tableConfig.sortOptions"
                        :row-style-class="rowStyleClassFn" :pagination-options="tableConfig.paginationOptions"
                        @on-row-click="editSelected">
                        <template v-slot:table-actions>
                          <button @click="clearForm()" type="button" class="btn btn-primary btn-sm">
                            {{ tableConfig.createText || 'Create Row' }}
                          </button>
                        </template>
                        <template slot="table-row" slot-scope="props">
                          <template v-if="props.column.field == 'date'">

                            <a href="#" class="table-link" @click.prevent.stop="">

                              {{props.row.date || '-'}}
                            </a>

                          </template>
                          <template v-else>
                            {{props.formattedRow[props.column.field] || '-' }}
                          </template>
                        </template>
                        <template slot="emptystate">
                          <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
                          {{ emptyMessage }}
                        </template>
                      </vue-good-table>
                      <p v-if="isTableUpdating"> {{ $t('loading') }}
                        <font-awesome-icon class="fa-1x fa-spin" :icon="['far', 'spinner-third']" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>

            </template>

          </sub-section>
          <sub-section ref="uploadDocument" :title="editstate ? 'Edit Action' : 'New Action'"
            sub-section-id="recipient-upload-document" saveButton="true"
            :save-button-text="editstate ? `${$t('Update Journey')}` : `${$t('Save Actions')}` "
            @save="uploadDocument()">
            <template v-slot:contents>
              <fieldset>
                <div class="row">
                  <!-- Clinical Attachments -->

                  <div class="standard-form-group-large">
                    <text-input rules="required" input-id="subject" :name="$t('Subject')" v-model="subject" />
                  </div>
                  <div class="standard-form-group">
                    <date-input input-id="scheduled_date" :name="$t('Scheduled Date')" v-model="scheduled_date" />
                  </div>
                  <div class="standard-form-group">
                    <date-input input-id="follow_up_date" :name="$t('Follow Up Date')" v-model="follow_up_date" />
                  </div>
                  <div class="standard-form-group">
                    <select-input select-id="assigned_to" rules="required" :name='$t("Assigned To")'
                      :options="userOptions" v-model="assigned_to">
                    </select-input>
                  </div>
                  <div class="standard-form-group">
                    <select-input select-id="status" rules="required" :name='$t("Status")' :options="statusOptions"
                      v-model="status">
                    </select-input>
                  </div>
                  <div class="row-break d-none d-xl-block"></div>
                  <div class="standard-full-width-group">
                    <text-area-input input-id="recipientAttachment-description" :name='$t("Description")' rows="4"
                      v-model="description">
                    </text-area-input>
                  </div>
                </div>

              </fieldset>
            </template>

          </sub-section>

        </div>
      </div>

    </template>
  </card-section>
</template>

<i18n src="../_locales/Actions.json"></i18n>

<script lang="ts">
  import {
    Getter,
    State
  } from 'vuex-class';
  import {
    mixins
  } from "vue-class-component";
  import {
    Component,
    Vue, Prop, Watch
  } from 'vue-property-decorator';
  import CardSection from "@/components/shared/CardSection.vue";
  import SaveToolbar from '@/components/shared/SaveToolbar.vue';
  import {
    VueGoodTable
  } from 'vue-good-table';
  import SelectInput from '@/components/shared/SelectInput.vue';
  import CheckboxInput from '@/components/shared/CheckboxInput.vue';
  import TextInput from '@/components/shared/TextInput.vue';
  import TextAreaInput from '@/components/shared/TextAreaInput.vue';
  import SubSection from '@/components/shared/SubSection.vue';
  import DateInput from '@/components/shared/DateInput.vue';
  import {
    TableConfig
  } from '@/types';
  import {
    Recipient
  } from "@/store/recipients/types";
  import {
    UserDetails
  } from '@/store/userAccounts/types';
  import {
    DateUtilsMixin
  } from "@/mixins/date-utils-mixin";

  interface TableRow {
    id: string;
    category ? : string;

    date ? : string;
    fileName ? : string;

    fileType ? : string;
    description ? : string;
    uploadedBy ? : string;

  }

  @Component({
    components: {
      CardSection,
      SaveToolbar,
      SubSection,
      SelectInput,
      TextInput,
      DateInput,
      CheckboxInput,
      TextAreaInput,
      VueGoodTable
    }
  })
  export default class Actions extends mixins(DateUtilsMixin) {

    @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
    @State(state => state.userAccounts.selected) private selected!: UserDetails;
    @State(state => state.pageState.currentPage.actions) editState!: any;

    @Getter('getUser', {
      namespace: 'users'
    }) private getUser!: any;
    @Getter('prototypeFeatureEnabled', {
      namespace: 'features'
    }) private prototypeFeatureEnabled!: (featureName: string) => boolean;

    @Prop({ default: null }) columns!: any;
    @Prop({ default: null }) tableData!: any;
    @Prop({ default: null }) title!: string;
    @Prop({ default: null }) localStorageRef!: string;

    loaded() {
      // Parse tasks from local storage if found, otherwise default to JSON file contents
      let formState;
      const defaultState = {
        actions: this.tableData.rows
      };
      const items: any = localStorage.getItem(this.localStorageRef);
      if (items) {
        try {
          formState = JSON.parse(items);
        } catch (error) {
          console.warn('Warning: Cannot parse Actions from local storage, setting default tasks.', error);
          formState = defaultState;
        }
      } else {
        formState = defaultState;
      }
      this.$store.commit("pageState/set", {
        pageKey: "Actions",
        value: formState,
      });
      this.actions = formState.actions || [];
    }

    @Watch('$route')
      loadFromStorage() {
      this.loaded();
    }

    mounted() {
      this.loaded();
    }

    rowStyleClassFn(row: any) {
      if (!this.showCompleted) {
        return row.status == "2" ? 'd-none' : '';
      } else {
        return row.status == "2" ? 'completedBackground' : '';
      }
    }

    private showCompleted = false;
    private id = null;
    private subject = '';
    private follow_up_date = '';
    private status = '';
    private assigned_to = '';
    private description = '';
    private scheduled_date: any = null;
    private isTableUpdating = false;
    private taskInProgress = false;
    public perPage = 10;
    public currentPageTable = 1;
    private actions: any = [];
    private start_date = "";
    private end_date = '';
    private linkedNote = '';


    private editstate = false

    private statusOptions = [{
        code: 0,
        value: 'Pending'
      }, {
        code: 1,
        value: 'Pending -No Response'
      },
      {
        code: 2,
        value: 'Completed'
      }
    ];

    private userOptions = [{
      code: 0,
      value: 'S. Wood'
    }, {
      code: 1,
      value: 'S. McPhee'
    }, {
      code: 2,
      value: 'M. Couch'
    }, {
      code: 3,
      value: 'A. Johnson'
    }];

    get tableRows() {
      const actions = this.actions || [];

      let data;

      if (this.start_date && this.end_date) {
        data = this.filteredData('both');
      } else if (this.start_date) {
        data = this.filteredData('startOnly');
      } else {
        data = actions;
      }
      return data.map((item: any) => {
        return {
          id: item.id,
          scheduled_date: this.parseDisplayDateUi(item.scheduled_date),
          subject: item.subject,
          follow_up_date: this.parseDisplayDateUi(item.follow_up_date),
          status: item.status,
          linkedNote: item.linkedNote,
          statusValue: item.status ? this.getOptionValue(item.status, this.statusOptions) : null,
          assigned_to: item.assigned_to,
          assignedToValue: item.assigned_to ? this.getOptionValue(item.assigned_to, this.userOptions) : null,
          description: item.description ? item.description : "None"
        };
      });
    }

    get tableConfig(): TableConfig {
      return {
        data: this.tableRows,
        columns: this.columns,
        createButton: true,
        createText: `${this.$t('Create Action')}`,
        sortOptions: {
          enabled: false
        },
        paginationOptions: {
          enabled: true,
          mode: 'records',
          perPage: this.perPage,
          perPageDropdown: [10, 25, 50],
          setCurrentPage: this.currentPageTable,
          dropdownAllowAll: true,
          nextLabel: '',
          prevLabel: '',
          rowsPerPageLabel: this.$t('table.results'),
        },
      };
    }

    private filteredData(val: any) {
      let start_date = new Date(this.start_date).setUTCHours(0, 0, 0, 0);
      let end_date = new Date(this.end_date).setUTCHours(0, 0, 0, 0);

      let data: any = [];

      if (val == 'startOnly') {
        this.actions.map((item: any) => {
          if (new Date(item.follow_up_date).setUTCHours(0, 0, 0, 0) >= start_date || new Date(item
              .scheduled_date).setUTCHours(0, 0, 0, 0) >= start_date) {
            data.push(item);
          }
        });
      } else {
        this.actions.map((item: any) => {

          if (
            (new Date(item.follow_up_date).setUTCHours(0, 0, 0, 0) >= start_date && new Date(item
              .follow_up_date).setUTCHours(0, 0, 0, 0) <= end_date) ||
            (new Date(item.scheduled_date).setUTCHours(0, 0, 0, 0) <= start_date && new Date(item
              .scheduled_date).setUTCHours(0, 0, 0, 0) <= end_date)) {
            data.push(item);
          }
        });
      }
      return data;
    }

    private editSelected(row: any) {
      if (row) {
        this.id = row.row.id;
        this.editstate = true;
        this.subject = row.row.subject;
        this.scheduled_date = row.row.scheduled_date;
        this.follow_up_date = row.row.follow_up_date;
        this.status = row.row.status;
        this.linkedNote = row.row.linkedNote;
        this.assigned_to = row.row.assigned_to;
        this.description = row.row.description !== "None" ? row.row.description : "";
      }
    }

    get emptyMessage(): string {
      if (!this.actions) {
        return this.$t('loading').toString();
      } else {
        return this.$t('use_form_below').toString();
      }
    }


    public resetSaveToolbar(): void {
      // Refer to the save toolbar that handles this page
      const saveToolbar = (this.$refs.uploadDocument as SubSection).$refs[
        'save-recipient-upload-document'] as SaveToolbar;
      saveToolbar.reset();
    }


    get getUserFullName(): string | undefined {
      let user = this.getUser;

      return user.name;
    }

    public getOptionValue(item: any, options: any) {
      return options[item as any].value;
    }

    private clearDates(): any {
      this.start_date = '';
      this.end_date = '';
    }

    public clearForm(): any {
      Vue.set(this, 'clinicalAttachments', []);
      this.id = null;
      this.subject = '';
      this.scheduled_date = null;
      this.follow_up_date = '';
      this.status = '';
      this.assigned_to = '';
      this.description = '';
      this.$emit('clear');
      this.resetSaveToolbar();
      this.editstate = false;
    }

    public uploadDocument(): any {
      this.isTableUpdating = true;
      let actions = this.actions || [];

      var today = new Date();

      if (!this.editstate) {
        const payload: any = {
          id: Math.random().toString(16).slice(2),
          scheduled_date: this.scheduled_date ? this.scheduled_date : new Date().toISOString().substring(0, 10),
          subject: this.subject,
          linkedNote: "",
          follow_up_date: this.follow_up_date,
          status: this.status,
          assigned_to: this.assigned_to,
          description: this.description
        };

        actions.push(payload);
      } else {
        actions.map((item: any) => {
          if (item.id === this.id) {
            item.subject = this.subject;
            item.linkedNote = this.linkedNote;
            item.status = this.status;
            item.scheduled_date = this.scheduled_date;
            item.follow_up_date = this.follow_up_date;
            item.assigned_to = this.assigned_to;
            item.description = this.description;
          }
        });

      }

      this.actions = actions;

      const defaultState = {
        actions: actions
      };
   
      localStorage.setItem(this.localStorageRef, JSON.stringify(defaultState));

      this.isTableUpdating = false;
      this.editstate = false;
      this.clearForm();
    }
  }

</script>

<style>
  .completedBackground {
    background: #edfdee;
  }

</style>
