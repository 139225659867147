<template>
  <card-section
    section-id="communication-information"
    :isAccordion="prototypeFeatureEnabled('proto_ability_to_expand_collapse')"
  >
    <template v-slot:header>
      {{ title }}
    </template>
    <template v-slot:body>
      <div class="row">
        <div class="col-sm-12">
          <sub-section
            :title='title'
            sub-section-id="communication-information-subsection"
          >
            <template v-slot:contents>

              <div class="row">
                <!-- Uploaded Files -->
                <div class="col-sm-12 considerations-section">
                  <fieldset>
                    <div class="row">
                      <div class="standard-form-group">
                        <checkbox-input            
                          name="interpreter-needed"
                          labelName="Interpreter Needed?"
                          inputId="interpreter-needed"
                          v-model="interpreter"
                          label='Yes'
                        />
                      </div>
                      <div class="standard-form-group">
                          <text-input
                           input-id="primary-language"
                            name="Primary Language"
                            validation-id="insuranceStatus"
                            v-model="language"
                          />
                      </div>
                      <div class="standard-form-group-small-checkbox">
                        <checkbox-input            
                          name="poa"
                          labelName="POA?"
                          inputId="poa"
                          v-model="poa"
                          label='Yes'
                        />
                      </div>
                      <div class="standard-form-group">
                        <text-input
                            input-id="Care-partner-name-poa"
                            name="Care Partner Name (POA)"
                            validation-id="CarePartnerName"
                            v-model="carePartnerName"
                          />
                      </div>
                      <div class="standard-form-group">
                        <checkbox-input            
                          name="decision-support"
                          labelName="Decision Support Needed?"
                          inputId="decision-support"
                          v-model="decision"
                          label='Yes'
                        />
                      </div>
                      <div class="standard-form-group">             
                        <text-input
                          inputId="care-partner-for-support"
                          name="Care Partner Name (Decision Support)"
                          v-model="supportPartner" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="standard-form-group-3-quarters">
                        <text-area-input
                          input-id="audiovisual-inpairments-devices"
                          name="Audiovisual Impairments and Devices"
                          rows="4"
                          :showToolTip="true"
                          toolTipText="Describe the patient's audiovisual impairments and devices and how they impact communication."
                          v-model="inpairmentsDevices" />
                        </div>
                    </div>
                    <div class="row">
                      <div class="standard-form-group-3-quarters">
                        <text-area-input
                          input-id="audiovisual-comments"
                          name="Comments"
                          rows="4"
                          v-model="comments" />
                        </div>
                    </div>


                  
                    <save-toolbar
                      class="save-toolbar action-row temp-saving d-flex"
                      buttonClass="btn btn-success sec-submit"
                      :label="`Save ${title}`"
                      @save="performSave()"  
                      showCancelButton="true" 
                      cancelButtonText="Cancel" 
                      @cancel="clearForm()"
                    />
                  </fieldset>

                </div>
              </div>
            </template>
          </sub-section>
        </div>
      </div>
        
    </template>

  </card-section>
</template>


<script lang="ts">

import { Component, Vue, Prop } from 'vue-property-decorator';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import CardSection from '@/components/shared/CardSection.vue';
import SubSection from '@/components/shared/SubSection.vue';
import TextInput from '@/components/shared/TextInput.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { Getter } from 'vuex-class';

@Component({
  components: {
    CardSection,
    SubSection,
    SaveToolbar,
    SelectInput,
    DateInput,
    CheckboxInput,
    TextInput,
    TextAreaInput
  }
})
export default class CommunicationInformation extends Vue {  
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;

  title = 'Communication Information';

  private interpreter = '';
  private language = '';
  private decision = '';
  private supportPartner = '';
  private inpairmentsDevices = '';
  private comments = '';
  private poa = '';
  private carePartnerName = '';
  
  @Prop({
    default: null
  }) localStorageRef!: string;

  loaded() {
    // Parse tasks from local storage if found, otherwise default to JSON file contents
    let formState;

    const items: any = localStorage.getItem(this.localStorageRef);
    if (items) {
      try {
        formState = JSON.parse(items);
      } catch (error) {
        console.warn('Warning: Cannot parse tasklist from local storage, setting default tasks.', error);
      }
    } 

    if (formState) {
      this.interpreter =  formState.interpreter;
      this.language = formState.language;
      this.decision = formState.decision;
      this.supportPartner = formState.supportPartner;
      this.inpairmentsDevices = formState.inpairmentsDevices;
      this.comments = formState.comments;
      this.poa = formState.poa;
      this.carePartnerName = formState.carePartnerName;
    }

  }

  private mounted(): void {
    this.loaded();
  }


  public performSave(): any {

    const payload: any = {
      interpreter: this.interpreter,
      language: this.language,
      decision: this.decision,
      supportPartner: this.supportPartner,
      inpairmentsDevices: this.inpairmentsDevices,
      comments: this.comments,
      poa: this.poa,
      carePartnerName : this.carePartnerName
    };
      
    localStorage.setItem(this.localStorageRef, JSON.stringify(payload));
  }
}
</script>

<style>


</style>
