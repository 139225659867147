<template>
  <h4 class="legend-title">
    <span :class="{ 'pt-3': collapsible, 'd-inline-block': collapsible }">
      {{ heading }}
    </span>
    <small v-if="guidingText" class="w-100 d-flex my-2 guiding-text">{{guidingText}}</small>

    <div class="btn-group float-right" role="group" aria-label="sub section button group" v-if="collapsible">
      <toggle-button :value="value" @change="changed" :toggleLabel="toggleLabel" :untoggleLabel="untoggleLabel" />
    </div>
  </h4>
</template>

<script lang="ts">
import ToggleButton from '@/components/shared/ToggleButton.vue';
import { Component, Vue, Model, Prop } from 'vue-property-decorator';

@Component({
  components: {
    ToggleButton,
  }
})
export default class CollapsibleHeader extends Vue {
  // V-model
  @Model('change') value!: boolean; // collapsed or not

  // Props
  @Prop({ required: true }) heading?: string; // heading text
  @Prop({ default: false }) collapsible?: string; // set to true to enable collapsible behaviour
  @Prop({ default: '' }) guidingText!: string;

  // Getters
  get toggleLabel(): string {
    return `Collapse ${this.heading}`;
  }

  get untoggleLabel(): string {
    return `Reveal ${this.heading}`;
  }

  // Event handlers
  private changed(event: any): void {
    this.$emit('change', event);
  }
}
</script>
