<template>
  <card-section
    section-id="liver-specific-details"
    :key="journeyId"
    :lookups-to-load="lookupsToLoad"
    @loaded="loaded()"
     :isAccordion="prototypeFeatureEnabled('proto_ability_to_expand_collapse')"
  >
    <template v-slot:header>
      {{$t('liver_specific_details')}}
    </template>
    <template v-slot:body v-if="editState">
      <latest-sodium-meld-calculation
        ref="latestSmc"
        :new-journey="newJourney"
        :canSave="canSave"
      />
      <exception-points
        ref="exceptionPoints"
        :new-journey="newJourney"
        @handleErrors="handleErrors"
        @clear="clear"
        :canSave="canSave"
      />
      <override-liver-score
        ref="overrideLiverScore"
        :new-journey="newJourney"
        @handleErrors="handleErrors"
        @clear="clear"
        :canSave="canSave"
        @reloadLiverExceptionPoints="reloadLiverExceptionPoints"
      />
      <meld-section
        ref="meldSection"
        :new-journey="newJourney"
        @clear="clear"
        @handleErrors="handleErrors"
        v-if="showMeld"
        :canSave="canSave"
        @reloadLiverExceptionPoints="reloadLiverExceptionPoints"
      />
      <sodium-peld
        ref="sodiumPeld"
        :new-journey="newJourney"
        @handleErrors="handleErrors"
        @clear="clear"
        v-if="showPeld && isLabLiverPeldEnabled"
        :canSave="canSave"
        @reloadLiverExceptionPoints="reloadLiverExceptionPoints"
      />
      <hcc-criteria
        ref="hccCriteria"
        :new-journey="newJourney"
        @handleErrors="handleErrors"
        @clear="clear"
        :canSave="canSave"
        @reloadLiverExceptionPoints="reloadLiverExceptionPoints"
        v-if="isHccCriteriaEnabled"
      />
      <hcc-result
        ref="hccResult"
        :new-journey="newJourney"
        @handleErrors="handleErrors"
        @clear="clear"
        :canSave="canSave"
        @reloadLiverExceptionPoints="reloadLiverExceptionPoints"
        v-if="isHccDataEnabled"
      />
      <bridging-therapy
        ref="bridgingTherapy"
        :new-journey="newJourney"
        @handleErrors="handleErrors"
        @clear="clear"
        :canSave="canSave"
        v-if="isBridgingTherapyEnabled"
      />
      <downstaging-therapy
        ref="downstagingTherapy"
        :new-journey="newJourney"
        @handleErrors="handleErrors"
        @clear="clear"
        :canSave="canSave"
        v-if="isDownstagingTherapyEnabled"
      />
      <diseases-section
        v-if="isLiverExceptionDiseases"
        ref="diseasesSection"
        :new-journey="newJourney"
        @handleErrors="handleErrors"
        @clear="clear"
        :canSave="canSave"
        @reloadLiverExceptionPoints="reloadLiverExceptionPoints"
      />
      <ald-program
        ref="aldProgram"
        v-if="isAldProgramParticipationEnabled && !ispaediatricPatient"
        :new-journey="newJourney"
        @handleErrors="handleErrors"
        @clear="clear"
        :canSave="canSave"
      />
       <sub-section
        sub-section-id="lsd-other-liver-details"
        :title="$t('other_liver_details')"
        ref="saveLiverSpecificDetails"
        @save="savePatch()"
        :save-button-text="$t('save_liver_specific_details')"
        :saveButton="canEdit"
        :disabled="!canSave || newJourney"
        >
        <template v-slot:contents>
          <fieldset :disabled="!canSave || newJourney">
            <div class="row">
               <div class="standard-form-group-large">
                <text-area-input
                  input-id="lsd-comments"
                  :name="$t('general_comments')"
                  v-model="editState.comments" />
              </div>
            </div>
          </fieldset>
        </template>
      </sub-section>
    </template>
  </card-section>
</template>

<i18n src="@/components/organs/liver/_locales/LiverSpecificDetails.json"></i18n>

<script lang="ts">
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State }  from 'vuex-class';
import { GenericCodeValue } from '@/store/types';
import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import CardSection from '@/components/shared/CardSection.vue';
import NumberInput from '@/components/shared/NumberInput.vue';
import { RecipientJourney } from '@/store/recipientJourney/types';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { LiverDetails, HistoryLiverSmc } from '@/store/organSpecificDetails/types';
import { calculateAge } from '@/utils';
import ExceptionPoints from '@/components/organs/liver/ExceptionPoints.vue';
import OverrideLiverScore, { OverrideLiverScoreForm } from '@/components/organs/liver/OverrideLiverScore.vue';
import LatestSodiumMeldCalculation, { ExceptionPointsForm } from '@/components/organs/liver/LatestSodiumMeldCalculation.vue';
import DiseasesSection, { DiseasesSectionRow,PediatricSectionRow } from '@/components/organs/liver/DiseasesSection.vue';
import { Recipient } from '@/store/recipients/types';
import TherapiesSection from '@/components/organs/liver/TherapiesSection.vue';
import SodiumPeld, { SodiumPeldForm } from '@/components/organs/liver/SodiumPeld.vue';
import MeldSection, { SodiumMeldForm } from '@/components/organs/liver/MeldSection.vue';
import BridgingTherapy, { BridgingTherapyForm } from '@/components/organs/liver/BridgingTherapy.vue';
import DownstagingTherapy, { DownstagingTherapyForm } from '@/components/organs/liver/DownstagingTherapy.vue';
import HccCriteria, { HccCriteriaForm } from '@/components/organs/liver/HccCriteria.vue';
import HccResult, { HccResultForm } from '@/components/organs/liver/HccResult.vue';
import AldProgram, { AldProgramPageState } from '@/components/organs/liver/AldProgram.vue';
import { SystemModules } from '@/store/features/types';

export interface LiverSpecificForm {
  exceptionPoints?: ExceptionPointsForm;
  overrideLiverScore?: OverrideLiverScoreForm;
  sodiumMeld?: SodiumMeldForm;
  sodiumPeld?: SodiumPeldForm;
  hccCriteria?: HccCriteriaForm;
  hccResult?: HccResultForm;
  bridgingTherapy?: BridgingTherapyForm;
  downstagingTherapy?: DownstagingTherapyForm;
  liverExceptionDiseases?: DiseasesSectionRow;
  liverPediatricCondition?: PediatricSectionRow;
  aldProgram?: AldProgramPageState;
  comments?: string;
}

@Component({
  components: {
    TextInput,
    DateInput,
    HccCriteria,
    HccResult,
    SubSection,
    SodiumPeld,
    AldProgram,
    CardSection,
    NumberInput,
    MeldSection,
    CheckboxInput,
    TextAreaInput,
    ExceptionPoints,
    DiseasesSection,
    TherapiesSection,
    BridgingTherapy,
    DownstagingTherapy,
    OverrideLiverScore,
    LatestSodiumMeldCalculation,
  }
})
export default class LiverSpecificDetails extends mixins(DateUtilsMixin) implements SaveableSection {
  // State
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.liverDetails) editState!: LiverSpecificForm;
  @State(state => state.lookups.liver_score_source_codes) organLookup!: GenericCodeValue[];

  // Properties
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  @Getter('showMeld', { namespace: 'labs' }) private showMeld!: boolean;
  @Getter('showPeld', { namespace: 'labs' }) private showPeld!: boolean;
  @Getter('clientId', { namespace: 'recipients' }) clientId!: string;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;
  @Getter("moduleEnabled", { namespace: "features" }) private moduleEnabled!: (module: string) => boolean;

  private lookupsToLoad = [
    'liver_score_source_codes',
  ];

  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;


  

  /**
   * Return true if other liver details section can be edited
   *
   * cannot be edited if new journey
   * cannot be edited if journey is completed
   *
   * @returns {boolean} true if we can edit
   */
  get canEdit(): boolean{
    if (this.newJourney || this.journey.completed) {
      return false;
    }
    return true;
  }

  // Comes from card-section after all the lookups are loaded
  public loaded(): void {
    // Load NaMELD labs if we need them
    this.initializeForm();
    this.$emit('loaded', 'liverDetails');
  }

  // Is the PELD module enabled
  get isLabLiverPeldEnabled(): boolean {
    return this.moduleEnabled(SystemModules.LAB_LIVER_PELD);
  }

  // Is the HCC Criteria system module enabled?
  get isHccCriteriaEnabled(): boolean {
    return this.moduleEnabled(SystemModules.HCC_CRITERIA);
  }

  // Is the HCC Data system module enabled?
  get isHccDataEnabled(): boolean {
    return this.moduleEnabled(SystemModules.HCC_DATA);
  }

  // Is the Bridging Therapy system module enabled?
  get isBridgingTherapyEnabled(): boolean {
    return this.moduleEnabled(SystemModules.BRIDGING_THERAPY);
  }

  // Is the Downstaging Therapy system module enabled?
  get isDownstagingTherapyEnabled(): boolean {
    return this.moduleEnabled(SystemModules.DOWNSTAGING_THERAPY);
  }

  // Is the ALD Program Participation system module enabled?
  get isAldProgramParticipationEnabled(): boolean {
    return this.moduleEnabled(SystemModules.ALD_PROGRAM_PARTICIPATION);
  }

  // Is Liver Exception diseases module enabled?
  get isLiverExceptionDiseases(): boolean {
    return this.moduleEnabled(SystemModules.LIVER_EXCEPTION_DISEASES);
  }

  /**
   * Copy relevant data from recipient into the pageState
   */
  public initializeForm(): void {
    // Initialize form edit state
    this.$store.commit('pageState/set', {
      pageKey: 'liverDetails',
      value: this.extractLiverSpecificDetailsForm(this.journey || {})
    });
    this.loadLabResults();
  }

  private loadLabResults(): void {
    this.$store.dispatch('organSpecificDetails/loadLiverMelds', this.clientId);
    this.$store.dispatch('organSpecificDetails/loadLiverPelds', this.clientId);
    this.$store.dispatch('organSpecificDetails/loadLiverHccs', this.clientId);
    this.$store.dispatch('organSpecificDetails/loadLiverBridgings', this.clientId);
    this.$store.dispatch('organSpecificDetails/loadLiverDownstagings', this.clientId);
    if(!this.ispaediatricPatient) {
      this.$store.dispatch('organSpecificDetails/loadAlcoholicLiverDiseaseProgram',{ recipientId: this.clientId, journeyId: this.journeyId });
    }
    if (!this.newJourney) {
      this.$store.dispatch('organSpecificDetails/loadHistoryLiverSmc', {
        recipientId: this.clientId,
        journeyId: this.journeyId
      });
    }
  }

  /**
   * Return if true if recipient is considered paediatric
   *
   * Recipient must be 12 or under
   *
   * @returns {boolean} true if paediatric
   */
  get ispaediatricPatient(): boolean {
    const dateOfBirth = this.recipient.patient_profile ? this.recipient.patient_profile.birth?.date : null;
    if (!dateOfBirth) return false;

    const age = calculateAge(dateOfBirth);
    return age ? age <= 12 : false;
  }

  /**
   * Extract relevant parts from recipient journey to match the form layout
   */
  public extractLiverSpecificDetailsForm(journey: RecipientJourney): LiverSpecificForm {
    /*
     * TODO: TECH_DEBT: Refactor initialization of Liver Specific Details and its sub-section components.
     * LiverSpecificDetails should be invoking the 'build form state' methods from its sub-sections instead
     * of defining things here. This is only possible after the subsections have mounted, and so what's
     * needed is a rework of the loading process for this section and its sub-sections.
     *
     * In other words, this method should be called only after all loading has finished (lookups,
     * subsections, and the action dispatches) and it will mostly just be invoking each sub-section's
     * own 'build form state' methods.
     */
    let out_of_criteria = undefined;
    if (journey) {
      const waitlistFactors = journey.stage_attributes?.waitlist?.factors || {};
      out_of_criteria = !waitlistFactors?.liver_hcc_in_criteria_date;
    }

    // Declare defaults
    const result: LiverSpecificForm = {
      exceptionPoints: {},
      sodiumMeld: { dateOfNaMeldData: this.currentDateUi() },
      sodiumPeld: { dateOfPeldData: this.currentDateUi() },
      hccCriteria: { hccOutOfCriteria: out_of_criteria },
      hccResult: { dateHccData: this.currentDateUi() },
      bridgingTherapy: { bridgingTherapyDate: this.currentDateUi() },
      downstagingTherapy: { downstagingTherapyDate: this.currentDateUi() },
      liverExceptionDiseases: {},
      liverPediatricCondition: {},
      aldProgram: {},
      comments: journey.comments
    };
    return result;
  }

  /**
   * Saves the form edit state.
   *
   * Prepares an update payload for Heart Specific Details,
   * dispatches a save action, and registers the save result.
   */
  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveLiverSpecificDetails as unknown as SaveProvider;
    // Generate payload based on current edit state
    const payload = {
      recipientId: this.clientId,
      journeyId: this.journeyId,
      journey: this.extractPatch()
    };
    // Dispatch save action and register the response
    this.$store.dispatch('journeyState/saveJourney', payload).then((success: SaveResult) => {
      // If successful, reload the current recipient
      this.$store.dispatch('recipients/get', this.clientId);
      // Register success result
      saveProvider.registerSaveResult(success);
    }).catch((error: SaveResult) => {
      // Show error notification
      saveProvider.registerSaveResult(error);
      // Emit event to handle errors
      this.$emit('handleErrors', error);
    });
  }

  /**
   * Gets changes from the editState as a patch for the journey's Heart Specific Details
   *
   * If the edit state doesn't exist return null
   *
   * @returns {any} object containing field changes
   */
  public extractPatch(): any {
    if (!this.editState) {
      return {};
    } else {
      return {
        comments: this.editState.comments
      };
    }
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    const gci = this.$refs.saveLiverSpecificDetails as unknown as SaveProvider;
    // Reset the save provider's save toolbar
    gci.resetSaveToolbar();
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    const result = {};

    // Exception Points
    const overrideLiverScore = this.$refs.overrideLiverScore as OverrideLiverScore;
    if (overrideLiverScore) {
      Object.assign(result, { ...overrideLiverScore.idLookup() });
    }

    // Sodium MELD
    const meldSection = this.$refs.meldSection as MeldSection;
    if (meldSection) {
      Object.assign(result, { ...meldSection.idLookup });
    }

    // Sodium PELD
    const sodiumPeld = this.$refs.sodiumPeld as SodiumPeld;
    if (sodiumPeld) {
      Object.assign(result, { ...sodiumPeld.idLookup });
    }

    // HCC Criteria
    const hccCriteria = this.$refs.hccCriteria as HccCriteria;
    if (hccCriteria) {
      Object.assign(result, { ...hccCriteria.idLookup });
    }

    // HCC Result
    const hccResult = this.$refs.hccResult as HccResult;
    if (hccResult) {
      Object.assign(result, { ...hccResult.idLookup });
    }

    // Bridging Therapy
    const bridgingTherapy = this.$refs.bridgingTherapy as BridgingTherapy;
    if (bridgingTherapy) {
      Object.assign(result, { ...bridgingTherapy.idLookup });
    }

    // Downstaging Therapy
    const downstagingTherapy = this.$refs.downstagingTherapy as DownstagingTherapy;
    if (downstagingTherapy) {
      Object.assign(result, { ...downstagingTherapy.idLookup });
    }

    // Exception Diseases Section
    const diseasesSection = this.$refs.diseasesSection as DownstagingTherapy;
    if (diseasesSection) {
      Object.assign(result, { ...diseasesSection.idLookup });
    }

    // ALD Program
    const aldProgram = this.$refs.aldProgram as AldProgram;
    if (aldProgram) Object.assign(result, { ...aldProgram.idLookup });

    // Return the error lookup
    return result;
  }

  // PRIVATE

  // Emit event to parent so it can handle validations
  private handleErrors(errors: any) {
    this.$emit('handleErrors', errors);
  }

  // Emit event to parent so it can handle clearing validations when saving
  private clear() {
    this.$emit('clear');
  }

  /**
   * Liver Specific Details card-section should manage cross-form reloading for its sub-sections
   *
   * Here we reload all the necessary data once, and then request each sub-section to re-initialize
   * their own edit states based on the latest information available
   *
   */
  public reloadLiverExceptionPoints() {
    // Sections with forms to be reloaded after SMC Score / Exception Points changes
    const latestSmc = this.$refs.latestSmc as unknown as LatestSodiumMeldCalculation;
    const overrideLiverScore = this.$refs.overrideLiverScore as unknown as OverrideLiverScore;
    const hccCriteria = this.$refs.hccCriteria as unknown as HccCriteria;
    const diseasesSection = this.$refs.diseasesSection as unknown as DiseasesSection;

    this.$store.dispatch('recipients/get', this.clientId).then(() => {
      this.$store.dispatch('journeyState/getJourney', this.journeyId).then(() => {
        this.initializeForm();
        this.$store.dispatch('organSpecificDetails/loadHistoryLiverSmc', {
          recipientId: this.clientId,
          journeyId: this.journeyId
        }).then(() => {
          if (latestSmc) latestSmc.reinitialize();
          if (overrideLiverScore) overrideLiverScore.reinitialize();
          if (hccCriteria) hccCriteria.reinitialize();
          if (diseasesSection) diseasesSection.reinitialize();
        });
      });
    });
  }
}
</script>
