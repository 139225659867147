import { render, staticRenderFns } from "./_ReferralSection.vue?vue&type=template&id=24f228df"
import script from "./_ReferralSection.vue?vue&type=script&lang=ts"
export * from "./_ReferralSection.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/components/organs/shared/_locales/ReferralSection.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Forgans%2Fshared%2F_ReferralSection.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/components/_locales/Organs.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Forgans%2Fshared%2F_ReferralSection.vue&external"
if (typeof block1 === 'function') block1(component)

export default component.exports