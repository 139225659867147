<template>
    <sub-section
    :title='title'
    sub-section-id="recipients-uploaded-files"
  >
    <template v-slot:contents>
    <div class="row">
      <!-- Uploaded Files -->
      <div class="col-sm-12">
        <fieldset>
          <div class="row" v-for="(comorbiditiesCode) in Object.values(comorbiditiesList)" :key="comorbiditiesCode.como_code">
            <div class="col-sm-12 col-md-6 col-lg-4 viralogy-section" v-for="(item, index)  in comorbiditiesCode" :key="index" >
              <div class="check-markers">
                <div class="form-check form-check-inline" @click="addComorbidity(item.como_code)">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    :checked="checkIndex(item.como_code)"
                  />
                  <label class="form-check-label form-check-label-main">{{ item.como_label }}</label>
                </div>

                <div class="additional_info pb-3" v-if="checkIndex(item.como_code)" >

                  <div v-for="(option, index) in item.additional_info" :key="index" >
                      <date-input
                      input-id="hemodynamic-date-lung"
                      :name="option.name"
                      rules="required"
                      v-model="option.value" />
                    </div>

                </div>
              </div>
            </div>
          </div>
          <save-toolbar
            class="save-toolbar action-row temp-saving"
            buttonClass="btn btn-success sec-submit"
            label="Save Comorbidities"
          />
        </fieldset>

      </div>
    </div>
    </template>

  </sub-section>
</template>


<script lang="ts">

import { Component, Vue } from 'vue-property-decorator';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import comorbiditiesJson from './json/ComorbiditiesJson.json';
import TempCormorditiesInfo from './json/TempCormorditiesInfo.json';
import DateInput from '@/components/shared/DateInput.vue';

@Component({
  components: {
    SubSection,
    SaveToolbar,
    SelectInput,
    DateInput
  }
})
export default class Comorbidities extends Vue {  
  title = 'Comorbidities';
  private previous_discussion = '';
  private comorbiditiesList = comorbiditiesJson;
  private selectedComorbidities :any = []
  private comorbiditiesArray = TempCormorditiesInfo
  private getOptions() {
    let options :any  = [];
    Object.values(this.comorbiditiesList).map((items: any) => {
      items.map((item: any) => {
        options.push({code: item.como_code, value: item.como_label });
      });
    
    });
    return options;
  }

  private addComorbidity(value: any) {
    if (!this.selectedComorbidities.some((e : any) => e.id === value)) {
      
      this.selectedComorbidities.push({id: value});
    } else {
    const pos = this.selectedComorbidities.findIndex((e : any) => e.id === value);
    if (pos >= 0) {
      this.selectedComorbidities.splice(pos, 1);
      }
    }
  }

  private checkIndex(value: any) {
    return this.selectedComorbidities.some((e : any) => e.id === value);
  }


  
}
</script>

<style>


</style>
