<template>
  <base-side-nav :class="{ closed: sideNavClosed }">
    <template v-slot:toolbar />
    <template v-slot:side-nav-content>
      <side-nav-toolbar @toggle="toggleSideNav">
        <template v-slot:return-link>
          <router-link class="nav-link no-scroll" :title="$t('return_to_prev')" :aria-label="$t('control_that_returns_to_previous_page')" :to="{ name: 'edit-recipient', params: { id: recipient.client_id }, hash: '#organ-referral' }">
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width />
            <span class="sr-only">
              {{$t('return_to_prev')}}
            </span>
          </router-link>
        </template>
      </side-nav-toolbar>
      <div class="side-nav-container">
        <!-- existing journey links -->
        <template v-for="journey in selectedRecipientJourneysList">
          <selected-recipient-journey-links @toggle="toggleSideNav" :journeyName="journey.name" :journeyId="journey.id" :organCode="journey.organCode" :key="journey.id" />
        </template>
        <!-- new journey links -->
        <template v-if="newOrgan">
          <div class="nav-block-wrap">
            <ul class="nav flex-column nav-block active">
              <li class="nav-item">
                <span class="nav-link no-scroll" style="text-transform: capitalize">
                  <font-awesome-icon class="nav-caret" :icon="['fas', (show ? 'chevron-down' : 'chevron-right')]" fixed-width @click.prevent="toggleNav" />
                  {{ $t(newOrgan) }}
                </span>
              </li>
              <ul class="nav flex-column" v-show="show">
                <div >

                  <li class="nav-item" @toggle="toggleSideNav">
                    <a class="nav-link" href="#journey_status">Journey Status</a>
                  </li>

                  <li class="nav-item" @toggle="toggleSideNav">
                    <a class="nav-link" href="#notes_and_encounters">Notes / Encounters</a>
                  </li>

                  <li class="nav-item" @toggle="toggleSideNav">
                    <a class="nav-link" href="#worksheet_and_tasklists">Worksheets / Tasklists</a>
                  </li>

                  <li class="nav-item" @toggle="toggleSideNav">
                    <a class="nav-link" href="#referral-section">{{$t('referral')}}</a>
                  </li>
                  <li class="nav-item" @toggle="toggleSideNav" v-if="groupExists('journey_decision') && isConsultationSectionEnabled">
                    <a class="nav-link" href="#consultation-section">{{$t('consultation')}}</a>
                  </li>
                  <li class="nav-item" @toggle="toggleSideNav" v-if="groupExists('journey_decision') && isMedicalAssessmentSectionEnabled">
                    <a class="nav-link" href="#medical-assessment-section">{{$t('medical_assessment')}}</a>
                  </li>
                  <li class="nav-item"  @toggle="toggleSideNav">
                    <a class="nav-link" :href="`#${organName}-specific-details`">{{$t('spec', { specific_details: $t('specific_details'), organ: $t(newOrgan+"1").toString() } )}}</a>
                  </li>
                  <li class="nav-item" @toggle="toggleSideNav">
                    <a class="nav-link" href="#donor-acceptability-section">{{$t('donor_acceptability_criteria')}}</a>
                  </li>
                  <li class="nav-item" @toggle="toggleSideNav">
                    <a class="nav-link" href="#waitlist-section">{{$t('waitlist')}}</a>
                  </li>
                  <li class="nav-item" @toggle="toggleSideNav">
                    <a class="nav-link" href="#transplant-details-section">{{$t('transplant_detail')}}s</a>
                  </li>
                  <li class="nav-item" @toggle="toggleSideNav">
                    <a class="nav-link" href="#post-transplant-section"> {{$t('post_transplant_follow_up')}}</a>
                  </li>
                </div>
              </ul>
            </ul>
          </div>

        <hr>
        </template>
        <hr>
        <selected-recipient-profile-links @toggle="toggleSideNav">
          <template v-slot:profile-return-link>
            <router-link class="no-scroll open" style="color: #2e2e2e !important" :to="{ name: 'edit-recipient', params: { id: recipient.client_id } }">
              {{$t('profile')}}
            </router-link>
            </template>
          </selected-recipient-profile-links>
        <hr>
   
        <selected-recipient-hla-links @toggle="toggleSideNav" />
      </div>
    </template>
  </base-side-nav>
</template>

<i18n src="@/components/_locales/common.json"></i18n>
<i18n src="@/components/organs/shared/_locales/common.json"></i18n>
<i18n src="@/components/_locales/Organs.json"></i18n>
<i18n src="@/components/organs/shared/_locales/SideNavJourney.json"></i18n>
<i18n src="@/components/recipients/_locales/SelectedRecipientJourneyLinks.json"></i18n>

<script lang="ts">
import { State, Getter } from 'vuex-class';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Recipient } from '@/store/recipients/types';
import { organCodeLookup } from '@/types';
import { RecipientJourney } from '@/store/recipientJourney/types';
import BaseSideNav from '@/components/shared/side-nav/BaseSideNav.vue';
import SelectedRecipientHlaLinks from '@/components/recipients/side-nav/SelectedRecipientHlaLinks.vue';
import SelectedRecipientJourneyLinks from '@/components/recipients/side-nav/SelectedRecipientJourneyLinks.vue';
import SelectedRecipientProfileLinks from '@/components/recipients/side-nav/SelectedRecipientProfileLinks.vue';
import SideNavToolbar from '@/components/shared/side-nav/SideNavToolbar.vue';
import { SystemModules } from '@/store/features/types';
import SelectedRecipientOfferLinks from '@/components/prototypes/offers/SelectedRecipientOfferLinks.vue';


@Component({
  components: {
    BaseSideNav,
    SideNavToolbar,
    SelectedRecipientHlaLinks,
    SelectedRecipientJourneyLinks,
    SelectedRecipientProfileLinks,
    SelectedRecipientOfferLinks
  }
})
export default class SideNavJourney extends Vue {
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) private journey!: RecipientJourney;
  
  @Getter('selectedRecipientJourneysList', { namespace: 'recipients' }) private selectedRecipientJourneysList!: { id: string; name: string, organCode: number }[];
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;
  @Getter('groupExists', { namespace: 'validations' }) private groupExists!: (groupName: string) => boolean;
  @Getter("moduleEnabled", { namespace: "features" }) private moduleEnabled!: (module: string) => boolean;

  // Props
  @Prop({ default: false }) active!: boolean;
  @Prop({ default: null }) newOrgan!: string|null;
  @Prop({ default: null }) organCode!: string;
  @Prop({ default: false }) newRecipient!: boolean;
  @Prop({ default: false }) newJourney!: boolean;

  public show = true;
  public sideNavClosed = false;

  public toggleNav() {
    this.show = !this.show;
  }

  /**
   * Return organ name from the organ code using organCodeLookup
   * 
   * @returns {string} organ name 
   */
  get organName(): string {
    return this.organCode ? organCodeLookup[this.organCode] : '';
  }

  // Is the Consultation Section module enabled?
  get isConsultationSectionEnabled(): boolean {
    return this.moduleEnabled(SystemModules.CONSULTATION_SECTION);
  }

  // Is the Medical Assessment Section module enabled?
  get isMedicalAssessmentSectionEnabled(): boolean {
    return this.moduleEnabled(SystemModules.MEDICAL_ASSESSMENT_SECTION);
  }

  /**
   * Boolean used to show the side menu, if true side-nav is open
   * Changes the 'closed' class on the nav
   *
   * @returns {void} changes the sideNavClosed boolean
   */
  public toggleSideNav(): void {
    this.sideNavClosed = !this.sideNavClosed;
  }

  
}
</script>
