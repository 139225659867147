<template>
  <card-section section-id="current-offers" :isAccordion="prototypeFeatureEnabled('proto_ability_to_expand_collapse')">
    <template v-slot:header>
      Current Offers - {{organDescription}}
    </template>
    <template v-slot:body>


      <sub-section sub-section-id="sub_current_offers">
        <template v-slot:contents>
          <div class="row">
            <div class="w-auto mr-5 col-12">
        <fieldset>
            <legend>
              <h4 class="legend-title"><span class="">Offer Responses</span></h4>
            </legend>

            <fieldset>
              <div class="table-responsive-md allocationOffers">
                <table class="table table table-bordered table-hover  table-allocation table-demo-offers">

                  <thead>
                    <tr class="fixed-head">
                      <th scope="col" class="">Donor</th>
                      <th scope="col" style="width: 11%;max-width: 11%;min-width: 11%;"><span>Offer Date Time</span></th>
                      <th scope="col" class="">Organ</th>
                      <th scope="col">Laterality</th>
                      <th scope="col">Offer Type</th>
                      <th scope="col">Response Date</th>
                      <th scope="col">Response Time</th>
                      <th scope="col">Coordinator</th>
                      <th scope="col">Surgeon</th>
                      <th scope="col">Offer Response</th>
                      <th scope="col">Response Category</th>
                      <th scope="col">Outcome / Dispostion</th>
                      <th scope="col">Notes</th>
                    </tr>
                  </thead>
                  <tfoot>
                    <tr class="no-hover action-table-row sticky-row">
                      <td colspan="100%">
                        <nav class="nav action-row flex-align-end">
                          <div class="offer-button-wrap"><button type="button"
                              class="btn btn-wide btn-sm btn-success w-auto">Save
                              Responses</button>
                          </div>
                          <div class="ml-auto offer-timers">
                            <div></div>
                          </div>
                        </nav>
                      </td>
                    </tr>
                  </tfoot>
                  <tbody>
                    <tr id="offer_0" class="offer-row-no-offer">
                      <td><a href="#" @click.prevent="openCompareModal(552288)"><span>552288</span></a></td>
                      <td><span>11-12-2023 14:00</span></td>
                      <td class="text-center">
                        <span>Liver</span>
                      </td>
                      <td class="text-center">
                        <span>Whole</span>
                      </td>
                      <td class="text-center">
                        <span>Primary</span>
                      </td>
                      <!-- This should change to the date component used in Afflo now -->
                      <td><input type="date" class="form-control" value="12/11/2023"></td>
                      <td><input type="time" class="form-control" value="14:00"></td>
                      <td>
                        <select id="table-coordinator" class="form-control">
                          <option value="0"> Select... </option>
                          <option value="D">S. Wood</option>
                          <option value="U" selected="">M. Couch</option>
                          <option value="H">S. Mcphee</option>
                          <option value="H">Ajohnson</option>
                        </select>
                      </td>
                      <td>
                        <div>
                          <select id="table-surgeon" class="form-control">
                            <option value="0"> Select... </option>
                            <option value="D" selected="">Bjones</option>
                            <option value="C">C. Bratton</option>
                            <option value="H">H. Payne</option>
                            <option value="M">M. Bridger</option>
                            <option value="W">W. Johnson</option>
                          </select>
                        </div>
                      </td>
                      <td>
                        <select id="table-responses" class="form-control">
                          <option value="0" selected=""> Select... </option>
                          <option value="D">Accepted</option>
                          <option value="U">Accepted with conditions</option>
                          <option value="H">Declined</option>
                          <option value="H">Extension Required</option>
                        </select>
                      </td>
                      <td>
                        <select id="table-responses" class="form-control">
                          <option value="0" selected=""> Select... </option>
                          <option value="D">Pending Diagnosis</option>
                          <option value="U">Pending Donor Info</option>
                        </select>
                      </td>
                      <td>
                        <select class="form-control" id="table-dispostion">
                          <option value="0" selected=""> Select... </option>
                          <option value="D">Offer not primary</option>
                          <option value="U">Offer withdrawn</option>
                          <option value="H">Not transplanted (patient reasons)</option>
                          <option value="H">Not transplanted (donor reasons)</option>
                          <option value="H">Not transplanted (logistics)</option>
                          <option value="D">Transplanted</option>
                        </select>
                      </td>
                      <td>
                        <input type="text" class="form-control" value="" placeholder="Add text">
                      </td>

                    </tr>
                    <tr id="offer_0" class="offer-row-no-offer">
                      <td><a href="#" @click.prevent="openCompareModal(552454)"><span>552454</span></a></td>
                      <td><span>11-12-2023 07:30</span></td>
                      <td class="text-center">
                        <span>Liver</span>
                      </td>
                      <td class="text-center">
                        <span>Whole</span>
                      </td>
                      <td class="text-center">
                        <span>Backup</span>
                      </td>
                      <!-- This should change to the date component used in Afflo now -->
                      <td><input type="date" class="form-control" value="12/11/2023"></td>
                      <td><input type="time" class="form-control" value="14:00"></td>
                      <td>
                        <select id="table-coordinator" class="form-control">
                          <option value="0"> Select... </option>
                          <option value="D">S. Wood</option>
                          <option value="U" selected="">M. Couch</option>
                          <option value="H">S. Mcphee</option>
                          <option value="H">A. Johnson</option>
                        </select>
                      </td>
                      <td>
                        <div><select id="table-surgeon" class="form-control">
                            <option value="0"> Select... </option>
                            <option value="D" selected="">Bjones</option>
                            <option value="U">A. Smith</option>
                            <option value="H">H. Payne</option>
                            <option value="H">M. Bridger</option>
                            <option value="H">W. Johnson</option>
                          </select></div>
                      </td>
                      <td>
                        <select id="table-responses" class="form-control">
                          <option value="0" selected=""> Select... </option>
                          <option value="D">Accepted</option>
                          <option value="U">Accepted with conditions</option>
                          <option value="H">Declined</option>
                          <option value="H">Extension Required</option>
                        </select>
                      </td>
                      <td>
                        <select id="table-responses" class="form-control">
                          <option value="0" selected=""> Select... </option>
                          <option value="D">Pending Diagnosis</option>
                          <option value="U">Pending Donor Info</option>
                        </select>
                      </td>
                      <td>
                        <select class="form-control" id="table-dispostion">
                          <option value="0" selected=""> Select... </option>
                          <option value="D">Offer not primary</option>
                          <option value="U">Offer withdrawn</option>
                          <option value="H">Not transplanted (patient reasons)</option>
                          <option value="H">Not transplanted (donor reasons)</option>
                          <option value="H">Not transplanted (logistics)</option>
                          <option value="D">Transplanted</option>
                        </select>
                      </td>
                      <td>
                        <input type="text" class="form-control" value="" placeholder="Add text">
                      </td>

                    </tr>
                  </tbody>
                </table>
              </div>
            </fieldset>


          </fieldset>
            </div>
          </div>

        </template>
      </sub-section>
      <!-- Confirmation details -->

    </template>
  </card-section>
</template>

<script lang="ts">
import { State, Getter } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { organCodeLookup } from '@/types';
import { OrganCodeValue } from '@/store/lookups/types';
import { RecipientJourney } from '@/store/recipientJourney/types';
import CardSection from "@/components/shared/CardSection.vue";
import SubSection from '@/components/shared/SubSection.vue';

  @Component({
    components: {
      CardSection,
      SubSection
    }
  })
export default class CurrentOffers extends Vue {
  @Getter('prototypeFeatureEnabled', { namespace: 'features'}) private prototypeFeatureEnabled!: (featureName: string) => boolean;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;

  @Getter("includeStomach", { namespace: "recipients" }) includeStomach!: (journeyId?: string) => boolean;
  @Getter('journeyId', { namespace: 'journeyState', }) journeyId!: string|undefined;

  /**
   * Return titlized organ name
   *
   * @returns {string} organ name titlized
   */
  get organDescription(): string {
    let organName = this.organComponent;
    if (this.includeStomach(this.journeyId) && this.journey.organ_code == OrganCodeValue.SmallBowel) {
      return organName = this.$t(`${organName} + Stomach`).toString();
    } else {
      return this.$t(organName).toString();
    }
  }

  /**
   * Gets the current journey as lower case
   *
   * Using the organ code, return the lower case organ name
   *
   * @returns {string} organ as lower case
   */
  get organComponent(): string {
    return organCodeLookup[`${this.journey ? this.journey.organ_code : ''}`] || '';
  }

  private openCompareModal(donorId: any) {
    this.$emit('openCompareModal', donorId);
  }
}
</script>

<style scoped>
.table-bordered.table-demo-offers th, .table-demo-offers td, .table-demo-offers td input, .table-demo-offers td select {
  font-size: .8rem !important;
  font-weight: 600 !important;
}

.table-allocation:not(.table-offers) tbody > tr:first-child td, .table-offers:not(.table-offers) tbody > tr:first-child td {
	border-bottom: 1px solid #dee2e6;;
}
.temp-form-subheader {
  font-weight: 600;
}
.offer-form-wrapper {
  padding-top: .5rem;
  border-bottom: 1px solid #dee2e6;
}
</style>
