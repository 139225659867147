<template>
  <validation-provider 
    ref="provider"
    :rules="formRules" 
    :name="labelName || inputId" 
    :vid="validationId ? validationId : inputId" 
    v-slot="{ errors }">
    <p class="p-label" :class="{ 'sr-only': hideLabel }" v-if="labelName">
      {{labelName}}
      <validation-asterisk :rules=formRules :crossValues="crossValues" :ruleKey="ruleKey"/>
    </p>
    <div class="form-check form-check-inline" :class="{ 'is-invalid': errors[0] }">
      <template v-if="isMasked">
        <input
          type="checkbox"
          class="form-check-input"
          :id="inputId"
          :disabled="true"
          :readonly="true"
          :value="value"
        />
      </template>
      <template v-else>
        <input
          :id="inputId"
          :aria-labelledby="inputId"
          v-model="isChecked"
          :disabled="isReadOnly(disabled)"
          type="checkbox"
          class="form-check-input"
          v-on="inputEvents()"
        />
      </template>
      <label class="form-check-label" :for="inputId">
        <span v-html="label || labelName"/>
      </label>
    </div>
    <div class="invalid-feedback" :id="`${inputId}-error`" v-if="errors[0]">
      <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
      {{ translateError(errors, labelName) }}
    </div>
  </validation-provider>
</template>

<script lang="ts">
import '@/vee-validate-rules.ts';
import { Component, Vue, Prop, Model, Watch } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';
import { Rules } from '@/store/validations/types';
import ValidationAsterisk from '@/components/shared/ValidationAsterisk.vue';
import { isMasked } from '@/utils';

@Component({
  components: {
    ValidationAsterisk
  }
})
export default class CheckboxInput extends Vue {
  @Getter('getRuleSet', { namespace: 'validations' }) private ruleSet!: Rules;
  @Getter('getRules', { namespace: 'validations' }) private getRules!: (ruleSet: any, ruleKey: string, rules: string) => any;
  @Getter('isReadOnly', { namespace: 'validations' }) private isReadOnly!: (readonly?: any) => boolean;
  @Getter('translateError', { namespace: 'utilities' }) private translateError!: (error?: any, field?: string|null) => string;

  // V-model
  @Model('change') value!: string;

  // Required props
  @Prop({ required: true }) inputId!: string; // HTML ID
  @Prop({ required: true }) label!: string; // Label text (what the user clicks on)

  // Optional props
  @Prop({ default: null }) labelName!: string|null; // Optional label to prepend the checkbox
  @Prop({ default: null }) validationId!: string; // OPTIONAL specify a 'vid' property for validation-provider, if it must be different than the element ID
                                                  // used by parent component after attempting to save to decide where server-side validation errors are shown 
  @Prop({ default: false }) hideLabel!: boolean; // Hide label visually
  @Prop({ default: false }) disabled!: boolean; // Sets the input to disabled

  @Prop({ default: null }) rules!: string; // OPTIONAL lets us hard-code the client-side vee-validate rules in the front-end instead of using anything provided by the back-end
  @Prop({ default: null }) ruleKey!: string // OPTIONAL parameter path to load client-side validation e.g. new_validations, edit_validations
                                            // used by input components to set 'rules' properties in their validation providers based on the client-side validations loaded from the back-end;
  @Prop({ default: null }) crossValues!: any; // valus needed for cross field validation for the asterix

  // Initialize local copy of the value so it's available at template render
  public isChecked = false;

  // masked input
  get isMasked(): boolean {
    if (!this.value) return false;
    return isMasked(this.value.toString());
  }

  get formRules(): any {
    return this.getRules(this.ruleSet, this.ruleKey, this.rules);
  }

  // Set local value to true (if checked) when mounting
  public mounted(): void {
    if (this.value != null) {
      this.isChecked = this.value ? true : false;
    }
  }

  // Forward events to the parent component
  public inputEvents(): any {
    const _vm = this as CheckboxInput;
    return Object.assign({},
      // parent listeners
      this.$listeners,
      {
        // custom listeners
        change(event: any) {
          // Emit updated value for v-model
          _vm.$emit('change', _vm.isChecked ? true : false);
        }
      }
    );
  }

  // Watch for changes to the prop value and update isChecked to match
  @Watch('value')
  private onValueChange(newValue: boolean, oldValue: boolean) {
    if (this.value != null) {
      this.isChecked = this.value ? true : false;
    }else {
      this.isChecked=false;
    }
  }
}
</script>
