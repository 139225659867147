<template>
  <div>
    <page-top>
      {{$t('search')}}
    </page-top>
    <template v-if="loading">
      {{$t('loading')}}
    </template>
    <full-page-list-layout v-else>
      <template v-slot:content>
        <template v-if="searchedTerm">
          <p>
            {{$t('you_searched_for')}} <strong>{{ searchedTerm }}</strong> {{$t('in')}} {{ $t(searchedType) }}.
          </p>
          <recipients-search-results :key="searchedTerm"
                                     :searched-term="searchedTerm"
                                     v-if="searchedType === 'recipients'">
          </recipients-search-results>
          <deceased-donors-search-results :key="searchedTerm"
                                     :searched-term="searchedTerm"
                                     v-if="searchedType === 'donors'">
          </deceased-donors-search-results>
        </template>
        <template v-else>
          <warning-banner>
            {{$t('enter_entity_id')}}
          </warning-banner>
        </template>
      </template>

    </full-page-list-layout>
  </div>
</template>

<i18n src="../_locales/common.json"></i18n>
<i18n src="@/views/search/SearchIndex.json"></i18n>
<i18n src="../../components/_locales/Organs.json"></i18n>

<script lang="ts">
import {Component} from "vue-property-decorator";

import SearchBar from "@/components/search/SearchBar.vue";
import PageTop from "@/components/shared/PageTop.vue";
import {mixins} from "vue-class-component";
import {UrlUtilsMixin} from "@/mixins/url-utils-mixin";
import FullPageListLayout from "@/components/layouts/FullPageListLayout.vue";
import WarningBanner from "@/components/shared/WarningBanner.vue";
import RecipientsSearchResults from "@/components/search/RecipientsSearchResults.vue";
import DeceasedDonorsSearchResults from "@/components/search/DeceasedDonorsSearchResults.vue";

@Component({
  components: {
    PageTop,
    SearchBar,
    RecipientsSearchResults,
    DeceasedDonorsSearchResults,
    FullPageListLayout,
    WarningBanner
  }
})
export default class SearchIndex extends mixins(UrlUtilsMixin) {
  loading = false;

  get searchedTerm() {
    return this.getUrlQueryValue('term') || '';
  }

  get searchedType() {
    return this.getUrlQueryValue('type') || 'recipients';
  }
}
</script>
