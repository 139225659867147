<template>
  <div>
    <template v-if="!dispatchEventsComplete">
      <page-top>
        <span class="skeleton-box" style="width: 200px" />
      </page-top>
       <div class="content-wrap">
        <div class="container-fluid">
          <div class="nav-wrapper">
            <loading-side-nav />
            <div class="page-content">
              <loading-recipients-page />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <page-top>
        <router-link :to="{ name: 'list-recipients' }">
          {{ $t('recipients') }}
        </router-link>
        / {{recipientDisplayName}}
      </page-top>
      <potential-duplicate-modal
        ref="potentialDuplicateModal"
        @savePatch="performSave(true)"
        @saveDemographics="handleContinueDemographics"
        @saveContactInfo="handleContinueContactInfo"
        @saveGeneralClinicalInformation="handleContinueGeneralClinicalInformation"
      />
      <recipient-sticky-summary />
      <div class="content-wrap">
        <div class="container-fluid">
          <div class="nav-wrapper">
            <side-nav-recipient-profile />
            <div class="page-content">
              <template>
                <validation-observer ref="validations" autocomplete="off" tag="form">
                  <recipient-summary />
                  <!-- Card Sections -->
                  <clinical-transaction
                    ref="clinicalTransaction"
                    v-if="isClinicalTransactionEnabled"
                    @loaded="(ref) => loaded(ref)"
                    @save="(details) => handleSectionSave(details)"
                    @handleErrors="(errors) => handleErrors(errors)"
                    @handleWarnings="(errors,target) => handleWarnings(errors,target)"
                    :canSave="isGroupWriteable('recipient_personal')"
                  />
                  <demographics
                    ref="demographics"
                    @loaded="(ref) => loaded(ref)"
                    @save="(details) => handleSectionSave(details)"
                    @handleErrors="(errors) => handleErrors(errors)"
                    @handleWarnings="(errors,target) => handleWarnings(errors,target)"
                    :canSave="isGroupWriteable('recipient_personal')"
                  />

                  <health-insurance
                    ref="health-insurance"
                    v-if="prototypeFeatureEnabled('proto_health_insurance')"
                  />
                  <contact-information
                    ref="contactInfo"
                    v-if="prototypeFeatureEnabled('proto_contact_information')"
                    @loaded="(ref) => loaded(ref)"
                    @save="(details) => handleSectionSave(details)"
                    @handleErrors="(errors) => handleErrors(errors)"
                    @handleWarnings="(errors,target) => handleWarnings(errors,target)"
                    :canSave="isGroupWriteable('recipient_personal')"
                  />

                  <proto-contact-information 
                    ref="contactInfo"
                    v-if="prototypeFeatureEnabled('proto_address_component_contact_information')"
                    @loaded="(ref) => loaded(ref)"
                    @save="(details) => handleSectionSave(details)"
                    @handleErrors="(errors) => handleErrors(errors)"
                    @handleWarnings="(errors,target) => handleWarnings(errors,target)"
                    :canSave="isGroupWriteable('recipient_personal')"
                  />

                  <care-givers
                    ref="careGiversSection"
                    v-if="prototypeFeatureEnabled('proto_care_givers_section')"
                  />
                  <communication-information 
                    ref="communicationInformation"
                    v-if="prototypeFeatureEnabled('proto_communication_information')"
                  />
                  <transportation-and-mobility
                    ref="transportationAndMobility"
                    v-if="prototypeFeatureEnabled('proto_transportation_and_mobility')"
                  />
                  <pace-and-engagement
                    ref="paceAndEngagement"
                    v-if="prototypeFeatureEnabled('proto_pace_and_engagement')"
                  />
                  <scheduling-preferences
                    ref="schedulingPreferences"
                    v-if="prototypeFeatureEnabled('proto_scheduling_preferences')"
                  />
                  <recipient-documents
                    @clear="resetValidationErrors"
                    v-if="prototypeFeatureEnabled('proto_recipient_documents')"
                   />
                  <link-to-donor
                    ref="linkToDonor"
                    @loaded="(ref) => loaded(ref)"
                    @save="(details) => handleSectionSave(details)"
                    @handleErrors="(errors) => handleErrors(errors)"
                    :canSave="true"
                  />

                  <organ-referrals
                    ref="organReferrals"
                    @loaded="(ref) => loaded(ref)"
                    @handleErrors="(errors) => handleErrors(errors)"
                    @clear="resetValidationErrors"
                    :canSave="isGroupWriteable('recipient_medical')"
                  />
                  <!-- some GCI field group permission are in recipient_medical, others in recipient_diagnostics -->
                  <general-clinical-information
                    ref="generalClinical"
                    @loaded="(ref) => loaded(ref)"
                    @save="(details) => handleSectionSave(details)"
                    @handleErrors="(errors) => handleErrors(errors)"
                    @handleWarnings="(errors,target) => handleWarnings(errors,target)"
                    @clear="resetValidationErrors"
                  />
                  <social-history
                    ref="socialHistory"
                    v-if="prototypeFeatureEnabled('proto_social_history')"
                  />

                  <medical-history
                    ref="medicalHistory"
                    v-if="prototypeFeatureEnabled('proto_medical_history')"
                  />
    
                  <serology
                    ref="serology"
                    v-if="prototypeFeatureEnabled('proto_chemistries')"
                  />

      
                  <recipient-death
                    ref="death"
                    :newRecord="false"
                    :enableDonor="false"
                    @loaded="(ref) => loaded(ref)"
                    @handleErrors="(errors) => handleErrors(errors)"
                    @clear="resetValidationErrors"
                    :canSave="isGroupWriteable('recipient_medical')"
                  />
                  <div class="form-btns">
                    <save-toolbar
                      ref="saveRecipient"
                      :label="$t('save_recipient')"
                      @save="performSave()"
                      v-if="canSaveGetter(false)"
                    />
                  </div>
                </validation-observer>
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<i18n src="../_locales/common.json"></i18n>

<script lang="ts">
import { Getter, State } from 'vuex-class';
import { recipients } from '@/store/recipients';
import { mixins } from "vue-class-component";
import { ValidationUtilsMixin } from "@/mixins/validation-utils-mixin";
import { IdLookup } from '@/store/validations/types';
import { VirologyType } from '@/store/labs/types';
import { Recipient } from '@/store/recipients/types';
import PageTop from '@/components/shared/PageTop.vue';
import { Component, Vue } from 'vue-property-decorator';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import ClinicalTransaction from '@/components/recipients/ClinicalTransaction.vue';
import Demographics from '@/components/recipients/Demographics.vue';
import { SaveProvider, SaveResult } from '@/types';
import OrganReferrals from '@/components/recipients/OrganReferrals.vue';
import RecipientSummary from '@/components/recipients/RecipientSummary.vue';
import ContactInformation from '@/components/recipients/ContactInformation.vue';
import GeneralClinicalInformation from '@/components/recipients/GeneralClinicalInformation.vue';
import VirologyResults from '@/components/virology/VirologyResults.vue';
import SideNavRecipientProfile from '@/components/recipients/side-nav/SideNavRecipientProfile.vue';
import RecipientStickySummary from '@/components/recipients/RecipientStickySummary.vue';
import OrganReferral from '@/components/recipients/OrganReferrals.vue';
import PotentialDuplicateModal from "@/components/recipients/PotentialDuplicateModal.vue";
import RecipientDeath from "@/components/recipients/RecipientDeath.vue";
import LoadingSideNav from '@/components/shared/side-nav/LoadingSideNav.vue';
import LoadingRecipientsPage from '@/components/shared/LoadingRecipientsPage.vue';
import RecipientDocuments from '@/components/prototypes/RecipientDocuments.vue';
import { SystemModules } from '@/store/features/types';
import LabsSection from '@/components/prototypes/LabsSection.vue';
import Serology from '@/components/prototypes/Serology.vue'
import CareGivers from '@/components/prototypes/CareGivers.vue';
import MedicalHistory from '@/components/prototypes/MedicalHistory.vue';
import SocialHistory from '@/components/prototypes/SocialHistory.vue';
import HealthInsurance from '@/components/prototypes/HealthInsurance.vue';
import LinkToDonor from '@/components/recipients/LinkToDonor.vue';
import ProtoContactInformation from '@/components/prototypes/ContactInformation.vue';
import CommunicationInformation from '@/components/prototypes/CommunicationInformation.vue';
import TransportationAndMobility from '@/components/prototypes/TransportationAndMobility.vue';
import PaceAndEngagement from '@/components/prototypes/PaceAndEngagement.vue';
import SchedulingPreferences from '@/components/prototypes//SchedulingPreferences.vue';

@Component({
  components: {
    PageTop,
    SaveToolbar,
    ClinicalTransaction,
    Demographics,
    OrganReferrals,
    RecipientDeath,
    VirologyResults,
    RecipientSummary,
    ContactInformation,
    SideNavRecipientProfile,
    GeneralClinicalInformation,
    RecipientStickySummary,
    PotentialDuplicateModal,
    LoadingSideNav,
    LoadingRecipientsPage,
    RecipientDocuments,
    LabsSection,
    CareGivers,
    MedicalHistory,
    SocialHistory,
    HealthInsurance,
    LinkToDonor,
    ProtoContactInformation,
    CommunicationInformation,
    TransportationAndMobility,
    PaceAndEngagement,
    SchedulingPreferences,
    Serology
  },
})
export default class EditRecipient extends mixins(ValidationUtilsMixin) implements SaveProvider {
  // State
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;

  // Getters
  @Getter('recipientDisplayName', { namespace: 'recipients' } ) private recipientDisplayName!: string;
  @Getter('clientId', { namespace: 'recipients' }) private clientId!: string|undefined;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;
  @Getter("moduleEnabled", { namespace: "features" }) private moduleEnabled!: (module: string) => boolean;
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;

  private dispatchEventsComplete = false;
  private sectionsLoaded = new Set();
  private allSectionsLoaded = false;

  /**
   * Returns enum value for VirologyType.Recipient
   *
   * @returns {number} enum value for Recipient
   */
  get virologyType(): number {
    return VirologyType.Recipient;
  }

  // Is the Clinical Transaction module enabled?
  get isClinicalTransactionEnabled(): boolean {
    return this.moduleEnabled(SystemModules.CLINICAL_TRANSACTIONS);
  }

  // Vue lifecycle hooks
  public mounted(): void {
    const recipientIdentifier = this.clientId;
    Promise.all([
      this.$store.commit('setPageTitle', `Patients / ${this.recipientDisplayName}`),
      this.$store.dispatch('validations/loadEdit', { view: 'recipients', action: 'edit', clientId: recipientIdentifier }),
    ]).finally(() => {
      this.dispatchEventsComplete = true;
    });
  }

  get isLoaded(): boolean {
    return this.allSectionsLoaded;
  }

  public loaded(ref: string): void {
    if (!ref) return;
    // Create a set of all the sections to load filtering out sections we don't care about
    const sectionsToLoad = new Set(Object.keys(this.$refs).filter((ref: string) => !ref.match(/validations|saveRecipient|potentialDuplicateModal/)));
    // Add the ref we just loaded
    this.sectionsLoaded.add(ref);
    if (this.sectionsLoaded.size === sectionsToLoad.size) {
      this.$store.dispatch('utilities/scrollBehavior');
      this.allSectionsLoaded = true;
    }
  }

  public handleContinueDemographics(): void {
    (this.$refs.demographics as Demographics).savePatch(true);
  }
  public handleContinueContactInfo(): void {
    (this.$refs.contactInfo as ContactInformation).savePatch(true);
  }
  public handleContinueGeneralClinicalInformation(): void {
    (this.$refs.generalClinical as GeneralClinicalInformation).savePatch(true);
  }

  public handleWarnings(errors: SaveResult, target: string): void {
    (this.$refs.potentialDuplicateModal as PotentialDuplicateModal).checkingDuplicateWarning(errors, target);
  }

  // Handle saving triggered by local save button
  public performSave(potential_duplicate_profile_confirmed?: boolean): void {
    // Refer to the save toolbar that handles this page
    const saveToolbar = this.$refs.saveRecipient as SaveToolbar;
    // Show appropriate notification
    saveToolbar.startSaving();
    // Ref for each component required in the patch
    const clinicalTransaction = this.$refs.clinicalTransaction as ClinicalTransaction;
    const demographics = this.$refs.demographics as Demographics;
    const contactInfo = this.$refs.contactInfo as ContactInformation;
    const generalClinical = this.$refs.generalClinical as GeneralClinicalInformation;
    const virology = this.$refs.virology as VirologyResults;
    const death = this.$refs.death as RecipientDeath;
    // Extract demographics and general clinical (urgent and diagnostics)
    const recipientPatch: Recipient = {
      ...generalClinical.extractPatch(),
      ...demographics.extractPatch(potential_duplicate_profile_confirmed),
      ...death.extractPatch()
    };

    // Extract clinical transaction call information
    if (clinicalTransaction) Object.assign(recipientPatch, clinicalTransaction.extractPatch());

    // Extract contact info and merge with patient profile
    recipientPatch.patient_profile = {
      ...recipientPatch.patient_profile,
      ...contactInfo.extractPatch().patient_profile,
    };
    // Extract and add virology if exists
    if (!virology.isEmpty) {
      recipientPatch.virology_labs = [virology.extractPatch()];
    }
    // Extract and add measurement if exists
    if (!generalClinical.isEmpty) {
      recipientPatch.measurements = [generalClinical.extractMeasurementPatch()];
    }
    // Clear any save toolbar messages
    if (clinicalTransaction) clinicalTransaction.resetSaveToolbar();
    demographics.resetSaveToolbar();
    contactInfo.resetSaveToolbar();
    generalClinical.resetSaveToolbar();
    death.resetSaveToolbar();

    // Clear previous errors
    (this.$refs.validations as any).reset();

    // Attempt to save the recipient
    this.$store.dispatch('recipients/saveRecipientPatch', recipientPatch).then((success: SaveResult) => {
      this.registerSaveResult(success);
    }).catch((error: SaveResult) => {
      this.registerSaveResult(error);
    });
  }

  // Handle result of save
  public registerSaveResult(result: SaveResult): void {
    /**
     * If successful, update the root record(s). Depending on what the page is intended to save, this could be one
     * record (e.g. EditRecipient updates the currently selected recipient), zero records, or multiple records.
     */
    if (result.success) {
      this.$store.commit('recipients/set', result.responseData.recipient);
    } else {
      // Handle errors
      this.handleErrors(result,true);
    }
    // Refer to the save toolbar that handles this page
    const saveToolbar = this.$refs.saveRecipient as SaveToolbar;
    // Show appropriate saving notification
    saveToolbar.stopSaving(result);
  }

  // Handle save events generated by descendent components
  private handleSectionSave(sectionSaved: string): void {
    this.resetValidationErrors();
    this.resetSaveToolbar();
  }

  private resetValidationErrors(): void {
    (this.$refs.validations as any).reset();
  }

  // Parse and highlight errors from api response
  private handleErrors(errors: SaveResult[]|SaveResult, mainSave?: boolean): void {
    mainSave = mainSave ? true : false;

    const idLookup: IdLookup = {
      ...(this.$refs.demographics as Demographics).idLookup,
      ...(this.$refs.contactInfo as ContactInformation).idLookup,
      ...(this.$refs.generalClinical as GeneralClinicalInformation).idLookup,
      ...(this.$refs.virology as VirologyResults).idLookup(),
      ...(this.$refs.organReferrals as OrganReferral).idLookup(),
      ...(this.$refs.death as RecipientDeath).idLookup
    };

    const clinicalTransaction = this.$refs.clinicalTransaction as ClinicalTransaction;
    if (clinicalTransaction) Object.assign(idLookup, clinicalTransaction.idLookup());

    // Standardize errors to an array of SaveResult objects to faciliate duplicate check
    errors = Array.isArray(errors) ? errors : [errors];

    // Derive errors for UI input fields based on API error results
    const formErrors = this.parseFormErrors(errors, idLookup);

    (this.$refs.validations as any).setErrors(formErrors);

    if(mainSave) {
      errors.forEach((err: SaveResult) => {
        (this.$refs.potentialDuplicateModal as PotentialDuplicateModal).checkingDuplicateWarning(err, 'savePatch');
      });
    }
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Refer to the save toolbar that handles this page
    const saveToolbar = this.$refs.saveRecipient as SaveToolbar;
    saveToolbar.reset();
  }
}
</script>
