<template>
  <div class="nav-main">
    <nav class="navbar navbar-expand-lg p-0">
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#nav-main"
        aria-controls="nav-main"
        aria-expanded="false"
        @click.prevent="toggleMenu"
        :aria-label="$t('toggle_navigation')"
      >
        <span class="far fa-w fa-hx fa-bars">
          <font-awesome-icon :icon="['far', 'bars']" />
        </span>
      </button>
      <div id="nav-main" class="collapse navbar-collapse" role="navigation" v-if="mainMenuOpen" @click.prevent="toggleMenu">
        <router-link class="nav-link" active-class="active" :to="{ name: 'index' }" exact>
          {{ $t('dashboard') }}
        </router-link>
        <router-link class="nav-link" active-class="active" :to="{ name: 'list-deceased-donors' }" @click.prevent="toggleMenu" v-if="showListDeceasedDonors">
          {{ $t('donors') }}
        </router-link>
        <router-link class="nav-link" active-class="active" :to="{ name: 'list-deceased-donors-active' }" @click.prevent="toggleMenu" v-if="isTransplantCoordinator">
          {{ $t('donors') }}
        </router-link>
        <router-link class="nav-link" :class="getRecipientsLinkClass" active-class="active" :to="{ name: getRecipientsLinkRoute }" v-if="showListRecipients" @click.prevent="toggleMenu">
          {{ $t('recipients') }}
        </router-link>
        <router-link class="nav-link" active-class="active" :to="{ name: 'list-living-donors' }" @click.prevent="toggleMenu" v-if="showListLivingDonors">
          {{ $t('living_donors') }}
        </router-link>
        <router-link class="nav-link" active-class="active" :to="{ name: 'waitlist' }" @click.prevent="toggleMenu" v-if="showListWaitlist && prototypeFeatureEnabled('demo_main_menu_waitlist')">
          {{ $t('waitlist') }}
        </router-link>
        <router-link class="nav-link" active-class="active" :to="{ name: 'reports' }" @click.prevent="toggleMenu" v-if="showListReports">
          {{ $t('reports') }}
        </router-link>
      </div>
    </nav>
  </div>
</template>

<i18n src="../_locales/MainMenu.json"></i18n>

<script lang="ts">
import { Getter, State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { APIRoute, EP } from '@/api-endpoints';

@Component
export default class MainMenu extends Vue {
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('isTransplantCoordinator', { namespace: 'users' }) private isTransplantCoordinator!: boolean;
  @Getter('canMakeReportRequests', { namespace: 'users' }) private canMakeReportRequests!: boolean;
  @Getter("prototypeFeatureEnabled", { namespace: "features" }) private prototypeFeatureEnabled!: boolean;


  public mainMenuOpen = false;

  // Check to see if we're on a page for out-of-province recipients 
  isOopPage(): boolean {
    const name = this.$route.name || null;
    return name == 'edit-recipient-oop' || name == 'edit-organ-oop';
  }

  // get url for recipients link
  get getRecipientsLinkRoute(): string {
    return this.isOopPage() ? 'list-recipients-oop' : 'list-recipients';
  }

  // force active if inside oop page
  get getRecipientsLinkClass(): string {
    return this.isOopPage() ? 'router-link-exact-active active' : '';
  }

  // Lifecycle events
  private mounted(): void {
    this.mainMenuOpen = window.innerWidth >= 992;
    window.addEventListener('resize', this.checkWindowWidth);
  }

  // Event listeners
  private checkWindowWidth(): void {
    // set to true if inner width greater than 992
    this.mainMenuOpen = window.innerWidth >= 992;
  }

  get showListDeceasedDonors(): boolean {
    return this.checkAllowed(EP.deceasedDonors.create, 'GET');
  }

  get showListRecipients(): boolean {
    return this.checkAllowed(EP.recipients.create, 'GET');
  }

  get showListLivingDonors(): boolean {
    return this.checkAllowed(EP.living_donors.all, 'GET');
  }
  
  get showListWaitlist(): boolean {
    return this.checkAllowed('/waitlist/:id/grid', 'POST');
  }  

  get showListReports(): boolean {
    return this.canMakeReportRequests;
  }

  public toggleMenu(): void {
    if (window.innerWidth < 992) { 
      this.$emit('toggle');
      this.mainMenuOpen = !this.mainMenuOpen;
    }
  } 
}
</script>
