<template>
  <validation-provider 
    ref="provider"
    :rules="formRules" 
    :name="name" 
    :vid="validationId ? validationId : inputId" 
    v-slot="{ errors }">
    <label :for="inputId">
      <span v-html="label || name"/>&nbsp; 
      <validation-asterisk :rules=formRules :crossValues="crossValues" :ruleKey="ruleKey"/>
    </label>
    <small v-if="calculated" class="form-text text-muted">
      <font-awesome-icon class="text-grey" :icon="['far', 'exclamation-circle']" fixed-width />
      {{calculatedText}}
    </small>
    <Tooltip v-if="showToolTip" :toolTipText="toolTipText" :inputID="inputId"/>
    <template v-if="append">
      <div class="input-group mb-3">
        <input 
          :id="inputId"
          type="text"
          :class="{ 
            'is-invalid': !disabled && errors[0], 
            'form-control': !isReadOnly(readonly), 
            'form-control-plaintext': isReadOnly(readonly),
            'is-warning': showWarning
          }"
          :value="value"
          :readonly="isReadOnly(readonly||disabled)"
          v-on="inputEvents()"
          :step="step"
          :min="min"
          :max="max"
          :aria-describedby="`${inputId}-tooltip`" />
        <div class="input-group-append">
          <span class="input-group-text">{{appendText}}</span>
        </div>
        <div class="invalid-feedback" :id="`${inputId}-error`" v-if="errors[0]">
          <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
          {{ translateError(errors, label || name) }}
        </div>
        <div class="warning-feedback" :id="`${inputId}-error`" v-if="!errors[0] && showWarning">
          <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
          <span v-html="getWarningMessage" />
        </div>
      </div>
    </template>
    <template v-else>
      <input 
        :id="inputId"
        type="text"
        :class="{ 
          'is-invalid': !disabled && errors[0], 
          'form-control': !isReadOnly(readonly), 
          'form-control-plaintext': isReadOnly(readonly),
          'is-warning': showWarning
        }"
        :value="value"
        :readonly="isReadOnly(readonly||disabled)"
        v-on="inputEvents()"
        :step="step"
        :min="min" 
        :aria-describedby="`${inputId}-tooltip`"
        :max="max" />
      <div class="invalid-feedback" :id="`${inputId}-error`" v-if="errors[0]">
        <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
        {{ translateError(errors, label || name) }}
      </div>
      <div class="warning-feedback" :id="`${inputId}-error`" v-if="!errors[0] && showWarning">
        <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
        <span v-html="getWarningMessage" />
      </div>
    </template>
  </validation-provider>
</template>

<script lang="ts">
import '@/vee-validate-rules.ts';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';
import { Rules } from '@/store/validations/types';
import { WarningParams } from '@/store/tools/types';
import ValidationAsterisk from '@/components/shared/ValidationAsterisk.vue';
import Tooltip from '@/components/shared/Tooltip.vue';
@Component({
  components: {
    ValidationAsterisk,
    Tooltip
  }
})
export default class NumberInput extends Vue {
  @Getter('getRuleSet', { namespace: 'validations' }) private ruleSet!: Rules;
  @Getter('getRules', { namespace: 'validations' }) private getRules!: (ruleSet: any, ruleKey: string, rules: string) => any;
  @Getter('isReadOnly', { namespace: 'validations' }) private isReadOnly!: (readonly?: any) => boolean;
  @Getter('translateError', { namespace: 'utilities' }) private translateError!: (error?: any, field?: string|null) => string;

  // V-model
  @Prop() value!: string;

  // Standard properties
  @Prop({ required: true }) inputId!: string; // MANDATORY actual HTML element ID, set indirectly using properties like 'inputId' and 'selectId'
  @Prop({ required: true }) name!: string; // Field name, also used as the label

  // Optional properties
  @Prop({ default: null }) validationId!: string; // OPTIONAL specify a 'vid' property for validation-provider, if it must be different than the element ID
                                                  // used by parent component after attempting to save to decide where server-side validation errors are shown
  @Prop({ default: null }) label!: string; // Alternate Label property
  @Prop({ default: false }) append!: boolean; // Input label addon
  @Prop({ default: '' }) appendText!: string; // Input label addon
  @Prop({ default: false }) showToolTip!: boolean|string // Show tooltip
  @Prop({ default: false }) calculated!: boolean|string // Show Calculated indicator
  @Prop({ default: 'Calculated' }) calculatedText!: string; // Customize label for Calculated indicator
  @Prop({default: ''}) toolTipText!: string|null; // Customize label for toolTipText
  @Prop({ default: false }) disabled!: boolean; // Turn input data entry off
  @Prop({ default: false }) readonly!: boolean; // Render input as if it were plain text and turn input data entry off

  @Prop({ default: null }) rules!: string; // OPTIONAL lets us hard-code the client-side vee-validate rules in the front-end instead of using anything provided by the back-end
  @Prop({ default: null }) ruleKey!: string // OPTIONAL parameter path to load client-side validation e.g. new_validations, edit_validations
                                            // used by input components to set 'rules' properties in their validation providers based on the client-side validations loaded from the back-end
  @Prop({ default: null }) crossValues!: any; // valus needed for cross field validation for the asterix

  @Prop({ default: null }) warningParams!: WarningParams; // OPTIONAL show warning message when outside bounds. Example prop { min: 0.5, max: 99.0, message: 'Note: Normal range between 0.5 and 99.0' }

  // Number input attributes
  @Prop() step!: number;
  @Prop() min!: number;
  @Prop() max!: number;

  get formRules(): any {
    return this.getRules(this.ruleSet, this.ruleKey, this.rules);
  }

  get showWarning(): boolean { 
    return this.getWarningMessage ? true : false; 
  }

  get getWarningMessage(): string|undefined {
    if (this.warningParams) {
      const message = this.warningParams.message || undefined;
      const min = this.warningParams.min || 0;
      const max = this.warningParams.max || 0;
      const currentValue = parseFloat(this.value);
      if (currentValue < min || currentValue > max) {
        return `&nbsp;${message}`;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  // Forward events to the parent component
  public inputEvents(): any {
    const _vm = this as NumberInput;
    return Object.assign({},
      // parent listeners
      this.$listeners,
      {
        // custom listeners
        input(event: any) {
          // Emit updated value for v-model
          _vm.$emit('input', event.target.value);
        }
      }
    );
  }
}
</script>
