<template>
  <modal-section
    modalId="waitlist-outcome-modal"
    ref="waitlistOutcomeModal"
    :modalClass='waitlistOutcomeModalClass'
    :centered="true"
  >
    <template v-slot:title>
      {{$t('waitlist_outcome')}}
    </template>
    <template v-slot:body>
      <template v-if="!editState">
        {{$t('loading')}}
      </template>
      <template v-else>
        <div v-html="editState.body" />
      </template>
    </template>
    <template v-slot:footer>
      <div class="modal-footer-body">
        <button
          type=button
          class="btn btn-success"
          @click="dismiss"
        >
          {{$t('ok')}}
        </button>
      </div>
    </template>
  </modal-section>
</template>

<i18n src="./_locales/WaitlistOutcomeModal.json"></i18n>
<i18n src="./_locales/common.json"></i18n>

<script lang="ts">
import { State } from 'vuex-class';
import { ModalContent, ClassObject } from '@/types';
import { Component, Vue } from 'vue-property-decorator';
import ModalSection from '@/components/shared/ModalSection.vue';

interface WaitlistOutcomeModalState {
  style: string;
  body: string;
}

@Component({
  components: {
    ModalSection,
  }
})
export default class WaitlistOutcomeModal extends Vue {
  @State(state => state.pageState.currentPage.wailistOutcomeModalState) editState!: WaitlistOutcomeModalState;

  // Reference modal component to provide access to its functions
  get modalSection(): ModalSection|null {
    const modalSection = this.$refs.waitlistOutcomeModal as ModalSection;
    return modalSection ? modalSection : null;
  }

  // Combine specified modal style without default classes
  get waitlistOutcomeModalClass(): string {
    if (!this.editState) {
      return 'modal-content';
    } else {
      return `modal-content ${this.editState.style}`;
    }
  }

  // Build and show the modal section
  public initialize(modalContent: ModalContent) {
    if (!this.modalSection) {
      return;
    }
    this.$store.commit('pageState/set', {
      pageKey: 'wailistOutcomeModalState',
      value: {
        style: modalContent.style,
        body: modalContent.body,
      }
    });
    this.modalSection.showModal();
  }

  // Hide the modal section
  public dismiss() {
    if (!this.modalSection) {
      return;
    }
    this.$store.commit('pageState/set', {
      pageKey: 'wailistOutcomeModalState',
      value: undefined,
    });
    this.modalSection.hideModal();
  }
}
</script>
