import Vue from 'vue';
import App from './App.vue';
import { router } from './router';
import store from './store';
import i18n from './i18n';
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';

// Set up jQuery
import * as jquery from 'jquery';
(window as any).jQuery = (window as any).$ = jquery;

// Set up bootstrap
import 'popper.js';
import 'bootstrap';

// Set up Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import './icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
Vue.component('font-awesome-icon', FontAwesomeIcon);

// Set up styles
import './assets/scss/application.scss';

// Set up VeeValidation
import { ValidationProvider, ValidationObserver } from 'vee-validate';
Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);

import '@duetds/date-picker/dist/duet/themes/default.css';

// applyPolyfills is needed for E11 and Edge 17/18 polyfills
// TP 6639 https://shore.tpondemand.com/RestUI/Board.aspx#page=profile&searchPopup=bug/6639
// https://github.com/duetds/date-picker#ie11-and-edge-1718-polyfills
import { applyPolyfills,defineCustomElements } from "@duetds/date-picker/dist/loader"; // Setup Duet Date Picker
Vue.config.ignoredElements = [/duet-\w*/]; // configure Vue.js to ignore Duet Date Picker

applyPolyfills().then(() => {
  defineCustomElements(window); // Register Duet Date Picker
});

// Set up v-mask
import VueMask from 'v-mask';
Vue.use(VueMask);

// Setup primevue
Vue.use(PrimeVue, {ripple: true});
Vue.use(ConfirmationService);
import axios from 'axios';

axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  return {
    ...config,
    validateStatus: function (status: any) {
      return (status >= 200 && status < 300 || status === 422) ? true : false;
    }
  };
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// attach access_token from local storage to every api request
const access_token = localStorage.getItem('access_token') || null;
const headers = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
  'Access-Control-Allow-Methods': 'POST, GET, PATCH, DELETE, OPTIONS'
};
if (access_token) {
  axios.defaults.headers.common = { 'Authorization': `Bearer ${access_token}`, ...headers };
} else {
  axios.defaults.headers.common = { ...headers };
}

Vue.config.productionTip = false;

if (process.env.NODE_ENV !== 'production' && !process.env.VUE_APP_DISABLE_AXE) {
  const VueAxe = require('vue-axe');
  Vue.use(VueAxe, {
    config: {
      rules: [
        { id: 'heading-order', enabled: true },
        { id: 'label-title-only', enabled: true },
        { id:  'scope-attr-valid', enabled: false}
      ]
    },
    clearConsoleOnUpdate: false
  });
}

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#tgln-app');
