import { render, staticRenderFns } from "./_AllocationOfferResponse.vue?vue&type=template&id=0157c527"
import script from "./_AllocationOfferResponse.vue?vue&type=script&lang=ts"
export * from "./_AllocationOfferResponse.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/components/_locales/Organs.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fallocations%2F_AllocationOfferResponse.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/components/allocations/_locales/common.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fallocations%2F_AllocationOfferResponse.vue&external"
if (typeof block1 === 'function') block1(component)
import block2 from "@/components/allocations/_locales/_CTRIntegrationWorkflows.json?vue&type=custom&index=2&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fallocations%2F_AllocationOfferResponse.vue&external"
if (typeof block2 === 'function') block2(component)
import block3 from "@/components/allocations/_locales/_AllocationRecommendationListing.json?vue&type=custom&index=3&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fallocations%2F_AllocationOfferResponse.vue&external"
if (typeof block3 === 'function') block3(component)
import block4 from "@/components/allocations/_locales/_AllocationOfferResponse.json?vue&type=custom&index=4&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fallocations%2F_AllocationOfferResponse.vue&external"
if (typeof block4 === 'function') block4(component)
import block5 from "@/components/_locales/iposFields.json?vue&type=custom&index=5&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fallocations%2F_AllocationOfferResponse.vue&external"
if (typeof block5 === 'function') block5(component)

export default component.exports