<template>
  <card-section
    section-id="health_insurance"
    :isAccordion="prototypeFeatureEnabled('proto_ability_to_expand_collapse')"
  >
    <template v-slot:header>
      {{ title }}
    </template>
    <template v-slot:body>
      <insurance-component
        @clear="resetValidationErrors"
        :columns="getColumns('contact')"
        :tableData="getTableData('contact')" 
        localStorageRef ="RecipientInsurance"
      />


      
    </template>
  </card-section>
</template>

<script lang="ts">
import { Getter, State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import CardSection from "@/components/shared/CardSection.vue";
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import InsuranceComponent from '@/components/prototypes/_InsuranceComponent.vue';
import RecipientInsuranceColumnsJson from '@/components/prototypes/json/Recipients/RecipientInsuranceColumns.json';
import RecipientInsuranceRowsJson from '@/components/prototypes/json/Recipients/RecipientInsuranceData.json';


@Component({
  components: {
    CardSection,
    SaveToolbar,
    InsuranceComponent
  }
})
export default class MedicalHistory extends Vue {    
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;

  title = 'Insurance';


  getTableData(type: any) {
    switch (type) {
      case 'contact':
        return RecipientInsuranceRowsJson;
    }
  }

  getColumns(type: any) {
    switch (type) {
      case 'contact':
        return RecipientInsuranceColumnsJson;
    }
  }

  resetValidationErrors() {
    this.$emit('clear');
  }
}
</script>

<style>


</style>

