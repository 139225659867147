<template>
  <sub-section
    :title="$t('wait_time_override')"
    :save-button-text="$t('save_override')"
    sub-section-id="override-wait-time"
    ref="saveOverrideWaitTime"
    :sub-section="true"
    :save-button="canSave && !isOverrideFormDisabled"
    :disabled="!canSave"
    @save="savePatch()"
    :confirmation="confirmationText"
    :table-config="overrideTableConfig"
    @table-row-click="selectWaitTimeOverrideEvent"
    @table-create-row="createWaitTimeOverrideEvent"
    :divider="divider"
  >
    <template v-slot:table-cell="props">
      <!-- Date/time table cells -->
      <template v-if="props.column.field == 'date'">
        <span :title="props.row.time">{{props.row.date}}</span>
      </template>
      <!-- Other table cells -->
      <span v-else>
        {{props.formattedRow[props.column.field] }}
      </span>
    </template>
    <template v-slot:contents>
      <fieldset v-if="editState" :disabled="!canSave || isOverrideFormDisabled">
        <legend>
          <h5 v-if="!selectedWaitTimeOverrideEvent" class="legend-title">
            {{$t('new_wait_time_override_event')}}
          </h5>
          <h5 v-else class="legend-title">
            {{$t('selected_wait_time_override_event')}}
          </h5>
        </legend>
        <div class="row">
          <div class="standard-form-group">
            <date-input
              :name="$t('date')"
              input-id="override-date"
              v-model="editState.date"
              :disabled="true"
            />
          </div>
          <select-other-input
            :name="$t('reason_for_override')"
            select-id="override-reason-for-override"
            v-model="editState.reasonForOverride"
            :options="reasonForOverrideOptions"
            @change="onReasonForOverrideChange"
            rules="required"
            :otherTitle="$t('reason_for_override_other')"
            colStyling="standard-form-group-with-other"
          >
            <template v-slot:other>
              <text-input
                :name="$t('reason_for_override_other')"
                input-id="override-reason-for-override-other"
                v-model="editState.reasonForOverrideOther"
                rules="required"
              />
            </template>
          </select-other-input>
          <div class="standard-form-group">
            <number-input
              :name="$t('override_days')"
              input-id="override-override-days"
              v-model="editState.overrideDays"
              :disabled="isOverrideDaysDisabled"
            />
          </div>
          <div class="standard-form-group-large">
            <text-area-input
              input-id="override-comments"
              :name="$t('comments')"
              v-model="editState.comments"
              :disabled="isSystemOnlyOverride"
            />
          </div>
        </div>
      </fieldset>
    </template>
  </sub-section>
</template>

<i18n src="@/components/organs/shared/_locales/common.json"></i18n>
<i18n src="@/components/organs/shared/_locales/OverrideWaitTime.json"></i18n>

<script lang="ts">
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { EP } from '@/api-endpoints';
import { Getter, State } from 'vuex-class';
import { NumericCodeValue } from '@/store/types';
import { TableConfig, ModalContent } from '@/types';
import { Recipient } from '@/store/recipients/types';
import DateInput from "@/components/shared/DateInput.vue";
import TextInput from "@/components/shared/TextInput.vue";
import SubSection from '@/components/shared/SubSection.vue';
import NumberInput from "@/components/shared/NumberInput.vue";
import { WaitDaysAdjustmentReasons } from '@/store/lookups/types';
import TextAreaInput from "@/components/shared/TextAreaInput.vue";
import { SaveResult, SaveableSection, SaveProvider } from '@/types';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import SelectOtherInput from "@/components/shared/SelectOtherInput.vue";
import { TranslationUtilsMixin } from "@/mixins/translation-utils-mixin";
import { RecipientJourney, RecipientWaitlistAttributes, WaitDaysAdjustmentEvent } from '@/store/recipientJourney/types';

interface OverrideWaitTimeState {
  date?: string;
  reasonForOverride?: number;
  reasonForOverrideOther?: string;
  overrideDays?: number;
  comments?: string;
}

interface WaitTimeOverrideRow {
  _id: { $oid: string };
  date: string;
  time: string;
  reasonForOverride: string;
  overrideDays: string;
}

// Define which 'Reason for Override' options should disable the Override Days field
const RELISTING_REASONS_FOR_OVERRIDE = [
  WaitDaysAdjustmentReasons.GraftFailureWithin90DaysOfTransplant,
  WaitDaysAdjustmentReasons.SccApprovedReinstatement
];

// Define which 'Reason for Override' options are not selectable by users
const SYSTEM_ONLY_REASONS_FOR_OVERRIDE = [
  WaitDaysAdjustmentReasons.GraftFailureWithin90DaysOfTransplant
];

@Component({
  components: {
    DateInput,
    TextInput,
    SubSection,
    NumberInput,
    TextAreaInput,
    SelectOtherInput,
  },
})
export default class OverrideWaitTime extends mixins(TranslationUtilsMixin, DateUtilsMixin) {
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.overrideWaitTime) editState!: OverrideWaitTimeState;
  @State(state => state.journeyState.waitTimeOverrideEvents) waitTimeOverrideEvents!: WaitDaysAdjustmentEvent[];
  @State(state => state.lookups.wait_days_adjustment_reasons) waitDaysAdjustmentReasonsLookup!: NumericCodeValue[];
  @State(state => state.journeyState.selectedWaitTimeOverrideEvent) selectedWaitTimeOverrideEvent!: WaitDaysAdjustmentEvent;

  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('isWaitlisted', { namespace: 'journeyState' }) isWaitlisted!: boolean;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;
  @Getter('waitTimeDays', { namespace: 'journeyState' }) waitTimeDays!: number|null;
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('lookupValueNumeric', { namespace: 'lookups' }) lookupValueNumeric!: (code: number, lookupId: string) => string|null;

  @Prop({default: false }) newJourney!: boolean;
  @Prop({ default: false }) divider!: boolean;

  // Component getters

  // Treat each override as an uneditable 'final decision' (see #9366)
  get isOverrideFormDisabled(): boolean {
    return !!this.selectedWaitTimeOverrideEvent;
  }

  get isSystemOnlyOverride(): boolean {
    if (!this.editState || this.editState.reasonForOverride == undefined) {
      return false;
    }
    return SYSTEM_ONLY_REASONS_FOR_OVERRIDE.includes(parseInt(`${this.editState.reasonForOverride}`)) || false;
  }

  get isAttemptingToRelist(): boolean {
    if (!this.editState || this.editState.reasonForOverride == undefined) {
      return false;
    }
    return RELISTING_REASONS_FOR_OVERRIDE.includes(parseInt(`${this.editState.reasonForOverride}`)) || false;
  }

  get isOverrideDaysDisabled(): boolean {
    return this.isAttemptingToRelist;
  }

  get reasonForOverrideOptions(): NumericCodeValue[] {
    // Check data dependencies
    if (!this.waitDaysAdjustmentReasonsLookup) {
      return [];
    }
    // Map raw lookup data to options array, where system-only options have 'disabled' boolean flag
    const options = this.waitDaysAdjustmentReasonsLookup.map((lookup: NumericCodeValue): NumericCodeValue => {
      const option = lookup;
      Object.assign(option, {
        disabled: SYSTEM_ONLY_REASONS_FOR_OVERRIDE.includes(lookup.code),
      });
      return option;
    });
    // Return mapped options
    return options;
  }

  get isOverrideAuthorized(): boolean {
    return this.checkAllowed(EP.recipients.journeys.waitlist.overrideWaitTime, 'PATCH');
  }

  get canSave(): boolean {
    return !this.newJourney
        && this.isWaitlisted
        && this.isOverrideAuthorized
        && !this.journey.completed;
  }

  // If Reason for Override changes, clear Override Days and Reason for Override - Other
  private onReasonForOverrideChange(): void {
    Vue.set(this.editState, 'overrideDays', undefined);
    Vue.set(this.editState, 'reasonForOverrideOther', undefined);
  }

  /**
   * Gets a string used to populate the 'confirmation' alert dialog based on the form edit state
   * 
   * @returns {string|null} text for confirmation property of Sub Section
  */
  get confirmationText(): string|null {
    // Fetch form state
    const form = this.editState || {};
    // Check if relisting or simply adjusting wait days
    const isRelisting = this.isAttemptingToRelist || false;
    // Generate appropriate confirmation text
    let result: string|null = null;
    if (isRelisting) {
      // User is attempting to submit a manual relisting based on a completed journey's graft-failure
      result = this.$t('this_action_apply').toString();
    } else {
      // User is simply adding or subtracting wait days
      const overrideDays = form.overrideDays || 0;
      const reasonCode = form.reasonForOverride != null ? parseInt(`${form.reasonForOverride}`) : null;
      const reasonForOverride = this.lookupValueNumeric(Number(reasonCode), 'wait_days_adjustment_reasons') || 'None';
      result = `${this.$t('this_action_insert')} ${overrideDays} ${this.$t('days_into_wait_time')} ${reasonForOverride}. ${this.$t('proceed')}`;
    }
    return result;
  }

  // Validation mapping

  public idLookup(): IdLookup {
    return {
      'overrideWaitTime.factors.wait_days_adjustment'              : 'override-override-days',
      'overrideWaitTime.factors.wait_days_adjustment_reason_code'  : 'override-reason-for-override',
      'overrideWaitTime.factors.wait_days_adjustment_other_reason' : 'override-reason-for-override-other',
      'overrideWaitTime.factors.wait_days_adjustment_comment'      : 'override-comments',
    };
  }

  // Define form state based on selected wait time override event
  private buildOverrideWaitTimeState(): OverrideWaitTimeState {
    // Define default form values
    const result: OverrideWaitTimeState = {
      date: this.isWaitlisted ? this.currentDateUi() : undefined,
    };
    if (!this.selectedWaitTimeOverrideEvent) {
      return result;
    }
    // Fetch form values from recipient significant event
    const event: WaitDaysAdjustmentEvent = this.selectedWaitTimeOverrideEvent;
    // Is this event relisting from a system level override
    const isRelistingForOverride = SYSTEM_ONLY_REASONS_FOR_OVERRIDE.includes(event.details.reason_code);
    Object.assign(result, {
      date:                   this.parseDateUiFromDateTime(event.event_date),
      overrideDays:           isRelistingForOverride ? '-' : event.to,
      reasonForOverride:      event.details.reason_code,
      reasonForOverrideOther: event.details.other_reason,
      comments:               event.details.reason_comment,
    });
    return result;
  }

  private extractWaitlistAttributesPatch(): RecipientWaitlistAttributes {
    // Fetch form data
    const form = this.editState || {};
    // Waitlist attributes with nested Override Wait Time factors
    const result: RecipientWaitlistAttributes = {
      factors: {
        wait_days_adjustment:              form.overrideDays != null ? form.overrideDays : null,
        wait_days_adjustment_reason_code:  form.reasonForOverride != null ? parseInt(`${form.reasonForOverride}`) : null,
        wait_days_adjustment_other_reason: form.reasonForOverrideOther || null,
        wait_days_adjustment_comment:      form.comments,
      }
    };
    return result;
  }

  // Loading

  private mounted(): void {
    // Load wait time override events
    const opts = {
      journeyId: this.journeyId,
      recipientId: this.recipientId,
    };
    this.$store.dispatch('journeyState/loadWaitTimeOverrideEvents', opts).then(() => {
      this.initializeForm();
    });
  }

  private initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'overrideWaitTime',
      value: this.buildOverrideWaitTimeState(),
    });
  }

  // Saving

  private savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveOverrideWaitTime as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('saving', 'overrideWaitTime');
    // Generate payload based on current edit state
    const waitlistAttributesPatch = this.extractWaitlistAttributesPatch();
    // Setup saving payload
    const payload = {
      journeyId: this.journeyId,
      recipientId: this.recipientId,
      waitlistAttributes: waitlistAttributesPatch,
      prefix: 'overrideWaitTime',
    };
    // Dispatch save action and register the response
    this.$store.dispatch('journeyState/saveOverrideWaitTime', payload).then((success: SaveResult) => {
      // Reload recipient, journey, and journey durations
      this.$store.dispatch('recipients/get', this.recipientId).then(() => {
        this.$store.dispatch('journeyState/getJourney', this.journeyId).then(() => {
          this.$store.dispatch('journeyState/loadJourneyDurations', { recipientId: this.recipientId, journeyId: this.journeyId }).then(() => {
            // Re-initialize form state based on response from API
            this.initializeForm();
            saveProvider.registerSaveResult(success);
            // Display outcome notification if needed
            this.displayOutcomeNotification();
            // Report to parent that saving has completed so it can clear validations and reload waitlist decisions
            this.$emit('saved', 'overrideWaitTime');
          });
        });
      });
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }


  // Handle translations for Wait Time
  get waitTimeDescription(): string {
    return this.translateWaitTime(this.waitTimeDays);
  }

  // Check if we need to display an outcome modal, and if so emit event
  private displayOutcomeNotification(): void {
    // Override Wait Time only shows outcome for SCC Approved Manual Relisting
    const factors = this.journey.stage_attributes?.waitlist?.factors || {};
    const isOutcomeNotificationNeeded = factors.wait_days_adjustment_reason_code === WaitDaysAdjustmentReasons.SccApprovedReinstatement;
    if (!isOutcomeNotificationNeeded) {
      return;
    }
    // Emit event for top-level waitlist section to show outcome
    const waitDays = this.waitTimeDescription || 'Error';
    const modalContent: ModalContent = {
      style: 'modal-success',
      body: `${this.$t('wait_time_reinstatement')} ${waitDays} ${this.$t('for_this_journey')}.`,
    };
    this.$emit('display-outcome-notification', modalContent);
  }

  /**
   * Gets table row data representing Wait Time Overrides for the selected recipient journey.
   * 
   * @returns {WaitTimeOverrideRow[]} wait time override rows 
   */
  get waitTimeOverrideEventRows(): WaitTimeOverrideRow[] {
    if (!this.waitTimeOverrideEvents) {
      return [];
    }
    const rows = this.waitTimeOverrideEvents.map((event: WaitDaysAdjustmentEvent) => {
      // Show other reason if defined, otherwise lookup text for reason code
      const otherReason = event.details.other_reason;
      const reasonCode = event.details.reason_code;
      const reasonForOverride = otherReason ? otherReason : this.lookupValueNumeric(reasonCode, 'wait_days_adjustment_reasons');
      // NOTE: Wait time adjustment for Relisting will be calculate dynamically based on the prior journey, 
      // and so the numeric wait days adjustment value in the event is not used for the relisting override. 
      // Here we show '-' so that the zero in the event is not misleading the user about the consequences of the relisting.
      const isRelistingForOverride = SYSTEM_ONLY_REASONS_FOR_OVERRIDE.includes(reasonCode);
      // Parse override adjustment number (note: it can be zero)
      const waitDaysAdjustment = isRelistingForOverride ? '-' : event.to;
      const overrideDays = waitDaysAdjustment != null ? `${isRelistingForOverride ? '-' : waitDaysAdjustment}` : '-';
      // Build row data
      const row: WaitTimeOverrideRow = {
        _id: event._id,
        date: this.parseDisplayDateUiFromDateTime(event.event_date) || '-',
        time: this.parseTimeUi(event.event_date) || '-',
        reasonForOverride: reasonForOverride || '-',
        overrideDays,
      };
      return row;
    });
    return rows;
  }

  // Configure the override history table
  get overrideTableConfig(): TableConfig {
    return {
      data: this.waitTimeOverrideEventRows,
      columns: [
        { label: this.$t('date').toString(), field: 'date', width: '25%' },
        { label: this.$t('reason_for_override').toString(), field: 'reasonForOverride', width: '50%', },
        { label: this.$t('override_days').toString(), field: 'overrideDays', width: '25%' },
      ],
      empty: this.$t('use_form_below').toString(),
      createButton: this.canSave,
      createText: this.$t('create').toString(),
    };
  }

  // Select recipient significant event loaded from API
  private selectWaitTimeOverrideEvent(event: any) {
    // Get selected ID from the table row reference in the select event
    const selectedId = event.row._id && event.row._id.$oid ? event.row._id!.$oid : undefined;
    if (!selectedId || !this.editState || !this.waitTimeOverrideEvents) {
      return;
    }
    // Find the selected source document
    const found = this.waitTimeOverrideEvents.find((event: WaitDaysAdjustmentEvent) => {
      return event._id && event._id.$oid === selectedId;
    });
    if (!found) {
      return;
    }
    // Store the selection
    this.$store.commit('journeyState/selectWaitTimeOverrideEvent', found);
    // Rebuild form state based on selected document
    this.initializeForm();
  }

  // Setup empty override form state for entry of new event
  private createWaitTimeOverrideEvent() {
    // Clear the selection
    this.$store.commit('journeyState/selectWaitTimeOverrideEvent', undefined);
    // Rebuild form state without any selection
    this.initializeForm();
  }
}
</script>
