<template>
  <div class="nav-block-wrap">
    <ul class="nav nav-block flex-column" :class="{ active: active }">
      <li class="nav-item">
        <span class="nav-link">
          <font-awesome-icon :icon="['fas', (show ? 'chevron-down' : 'chevron-right')]" class="nav-caret" fixed-width @click.prevent="toggleNav" />
          <router-link class="no-scroll open" style="color: #2e2e2e !important" :to="{ name: 'recipient-hla', params: { id: recipient.client_id } }">
            {{$t('hla_information')}}
          </router-link>
        </span>
      </li>
      <ul class="nav flex-column" v-if="show">
        <li class="nav-item" @click="toggleSideNav">
          <a class="nav-link" :href="getUrl('hla-typing')">{{$t('typing')}}</a>
        </li>
        <li class="nav-item" @click="toggleSideNav">
          <a class="nav-link" :href="getUrl('hla-antibodies')">{{$t('antibodies')}}</a>
        </li>
        <li class="nav-item" @click="toggleSideNav">
          <a class="nav-link" :href="getUrl('hla-serum')">{{$t('serum_summary&review')}}</a>
        </li>
        <li class="nav-item" @click="toggleSideNav">
          <a class="nav-link" :href="getUrl('hla-vxm')">{{$t('virtual_crossmatch')}}</a>
        </li>
      </ul>
    </ul>
  </div>
</template>

<i18n src="@/components/recipients/_locales/SelectedRecipientHlaLinks.json"></i18n>

<script lang="ts">
import { State, Getter } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Recipient } from "@/store/recipients/types";

@Component
export default class SelectedRecipientHlaLinks extends Vue {
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;

  @Prop({default: false}) active!: boolean;

  public show = true;

  public toggleNav() {
    this.show = !this.show;
  }

  public toggleSideNav(): void {
    if (window.innerWidth < 992) { 
       this.$emit("toggle");
    }
  }
  /**
   * Gets the recipient client_id.
   *
   * Converts the recipient client_id to a string
   *
   * @returns {string} client_id as a string
   */
  get clientId(): string {
    return this.recipient.client_id == null ? '' : this.recipient.client_id.toString();
  }

  public getUrl(hash: string): string {
    hash = `#${hash}`;
    if(this.active) {
      return hash;
    } else {
      // We are fine not checking for the existence of client_id as for new recipients we only ever show this in active mode
      const location = this.$router.resolve({ name: 'recipient-hla', params: { id: this.clientId }, hash: hash });
      return location.href;
    }
  }
}
</script>
