<template>
  <card-section
    section-id="scheduling-preferences"
    :isAccordion="prototypeFeatureEnabled('proto_ability_to_expand_collapse')"
  >
    <template v-slot:header>
      {{ title }}
    </template>
    <template v-slot:body>
      <div class="row">
        <div class="col-sm-12">
          <sub-section
            :title='title'
            sub-section-id="scheduling-preferences-subsection"
            guidingText="Does the patient have any preferences for when to schedule appointments?"
          >
            <template v-slot:contents>
              <fieldset>
               
                <div class="row">
                  <div class="col-sm-12 mb-4">
                    <div id="wrapper-scheduling">

                      <div class="table-wrapper">
                            
                        <table class="table table-sm table-bordered">
                          <tbody>
                            <tr>
                              <th scope="row" class="sp-th-row-heading th-row">
                                <td width="12.5%"  class="empty-cell" />
                                <template v-for="item in schedulingPrefs">
                                  <td width="12.5%" scope="column" class="sp-th-row-heading" :key="item.id">{{item.label}}</td>
                                </template> 
                              </th>
                            </tr>


                            <template>
                              <tr scope="row" class="scheduling-row">
                                <td width="12.5%">
                                  <span class="empty-label">AM</span></td>
                                <template v-for="(item, index) in schedulingPrefs">
                                  <td width="12.5%" :key="index" v-if="item.availability_am">  
                                    <span class="input-padding" :class="item.availability_am.value ? `prefs-${(getSchedulingValue(item.availability_am.value)).toLowerCase()}` : ''"> 
                                      <select-input
                                        select-id="recipientAttachment-category"
                                        hideLabel="true"
                                    
                                        :name='item.availability_am.value'
                                        :options="schedulingOptions"
                                        v-model="item.availability_am.value"
                                        @change="inputChanged('am', item.availability_am, index)"
                                      >
                                      </select-input>
                                    </span>
                                  </td>
                                </template>
                              </tr>
                              <tr scope="row" class="scheduling-row">
                                <td width="12.5%">
                                  <span class="empty-label">PM</span></td>
                                <template v-for="(item, index) in schedulingPrefs">
                                  <td width="12.5%" :key="index" v-if="item.availability_pm">
                                    <span                     
                                      class="input-padding" :class="item.availability_pm.value ? `prefs-${(getSchedulingValue(item.availability_pm.value)).toLowerCase()}` : ''"> 
                                      <select-input
                                        select-id="recipientAttachment-category"
                                        hideLabel="true"
                                    
                                        :name='item.availability_pm.value'
                                        :options="schedulingOptions"
                                        v-model="item.availability_pm.value"
                                        @change="inputChanged('pm', item.availability_pm, index)"
                                      >
                                      </select-input>
                                    </span>
                                  </td>

                                </template>
                              </tr>
                            </template>

                          </tbody>
                        </table>

                      </div>

                    </div>
                  </div>
                </div>
                <div class="row"> 
                  <div class="standard-form-group form-group-checkbox">
                      <checkbox-input            
                        name="employment"
                        labelName="Employment"
                        inputId="employment"
                        v-model="employment"
                        label='Yes'
                      />
                    </div>
                    <div class="standard-form-group form-group-checkbox">

                    <select-input
                        select-id="dialysis_modality"
                        name='Dialysis Modality'
                        :options="schedulingOptions"
                        v-model="dialysis_modality"
                      >
                      </select-input>
                    </div>
                </div>
                <div class="row">
                  <div class="standard-form-group-large">
                    <text-area-input input-id="scheduling-comments" name="Comments"
                      rows="4"
                      v-model="comments" />
                  </div>
                </div>

                <save-toolbar
                  class="save-toolbar action-row temp-saving d-flex"
                  buttonClass="btn btn-success sec-submit"
                  :label="`Save ${title}`"
                  @save="performSave()"  
                  showCancelButton="true" 
                  cancelButtonText="Cancel" 
                  @cancel="clearForm()"
                />
              </fieldset>
            </template>
          </sub-section>
        </div>
      </div>
        
    </template>

  </card-section>
</template>


<script lang="ts">
  import {
    Component,
    Vue,
    Prop
  } from 'vue-property-decorator';
  import SaveToolbar from '@/components/shared/SaveToolbar.vue';
  import SelectInput from '@/components/shared/SelectInput.vue';
  import CardSection from '@/components/shared/CardSection.vue';
  import SubSection from '@/components/shared/SubSection.vue';
  import DateInput from '@/components/shared/DateInput.vue';
  import CheckboxInput from '@/components/shared/CheckboxInput.vue';
  import TextAreaInput from '@/components/shared/TextAreaInput.vue';
  import { Getter } from 'vuex-class';

  @Component({
    components: {
      CardSection,
      SubSection,
      SaveToolbar,
      SelectInput,
      DateInput,
      CheckboxInput,
      TextAreaInput
    }
  })
  export default class SchedulingPreferences extends Vue {
      @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;
    title = 'Scheduling Preferences';

    private schedulingOptions = [{
      code: 0,
      value: 'Preferred'
    }, {
      code: 1,
      value: 'Available'
    }, {
      code: 2,
      value: 'Avoid'
    }, {
      code: 3,
      value: 'Dialysis'
    }];

    public getSchedulingValue (item: any) {
      if (item) {
       return (this.schedulingOptions[item as any].value).toLowerCase();
      }
    }
    
    private employment = '';
    private dialysis_modality = '';
    private comments = '';
    private schedulingPrefs = [
      {
        id: 'mon',
        dayOfWeek: 1,
        label: 'Mon',
        availability_am: {
          group: "availability_am",
          id: "mon_am",
          value: '',
          isSet: false
        },
        availability_pm: {
          group: "availability_pm",
          id: "mon_pm",
          value: '',
          isSet: false
        }
      },
      {
        id: 'tue',
        dayOfWeek: 2,
        label: 'Tue',
        availability_am: {
          id: "tue_am",
          value: '',
          isSet: false
        },
        availability_pm: {
          id: "tue_pm",
          value: '',
          isSet: false
        }
      },
      {
        id: 'wed',
        dayOfWeek: 3,
        label: 'Wed',
        availability_am: {
          id: "wed_am",
          value: '',
          isSet: false
        },
        availability_pm: {
          id: "wed_pm",
          value: '',
          isSet: false
        }
      },
      {
        id: 'thu',
        dayOfWeek: 4,
        label: 'Thu',
        availability_am: {
          id: "thu_am",
          value: '',
          isSet: false
        },
        availability_pm: {
          id: "thu_pm",
          value: '',
          isSet: false
        }
      },
      {
        id: 'fri',
        dayOfWeek: 5,
        label: 'Fri',
        availability_am: {
          id: "fri_am",
          value: '',
          isSet: false
        },
        availability_pm: {
          id: "fri_pm",
          value: '',
          isSet: false
        }
      }, {
        id: 'sat',
        dayOfWeek: 6,
        label: 'Sat',
        availability_am: {
          id: "sat_am",
          value: '',
          isSet: false
        },
        availability_pm: {
          id: "sat_pm",
          value: '',
          isSet: false
        }
      },
      {
        id: 'sun',
        dayOfWeek: 7,
        label: 'Sun',
        availability_am: {
          id: "sun_am",
          value: '',
          isSet: false
        },
        availability_pm: {
          id: "sun_pm",
          value: '',
          isSet: false
        }

      },

    ]
  inputChanged(type: string, item: any, index: any) {
    let newItem = item;

    if (type == 'am') {
      this.schedulingPrefs[index].availability_am = newItem;
    } else {
      this.schedulingPrefs[index].availability_pm = newItem;
    }

  }

   @Prop({
    default: null
  }) localStorageRef!: string;

  loaded() {
    // Parse tasks from local storage if found, otherwise default to JSON file contents
    let formState;

    const items: any = localStorage.getItem(this.localStorageRef);
    if (items) {
      try {
        formState = JSON.parse(items);
      } catch (error) {
        console.warn('Warning: Cannot parse tasklist from local storage, setting default tasks.', error);
      }
    } 

    if (formState) {
      this.employment =  formState.employment;
      this.dialysis_modality = formState.dialysis_modality;
      this.comments = formState.comments;
      this.schedulingPrefs = formState.schedulingPrefs;
    }

  }

  private mounted(): void {
    this.loaded();
  }


  public performSave(): any {
    const payload: any = {
      employment: this.employment,
      dialysis_modality: this.dialysis_modality,
      comments: this.comments,
      schedulingPrefs: this.schedulingPrefs
    };
      
    localStorage.setItem(this.localStorageRef, JSON.stringify(payload));
  }
}



</script>



<style>
  .empty-cell {
    height: 2rem;
  }

  #wrapper-scheduling {
    overflow: auto;
  }

  #wrapper-scheduling .table-bordered th.sp-th-row-heading {
    border: none;
    background: white;
    padding: 0;
    display: contents;
  }

  #wrapper-scheduling .table-bordered tr {
    border: none;
    padding: 0;
  }

  #wrapper-scheduling .sp-th-row-heading td {
    width: 12.5%;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
  
  }

  #wrapper-scheduling .scheduling-row td {
    padding: 0;
    /* border: none; */
  }

  #wrapper-scheduling .input-padding {
    padding: 0.3rem;
    display: flex;
    /* background-color: #72f07b; */
    /* border: 1px solid #dee2e6; */
  }

  #wrapper-scheduling .input-padding span {
     width: 100%;
     min-width: 100px;
    }

    #wrapper-scheduling .empty-label {
    padding-top: 0.7rem;
    min-width: 100px;
    display: flex;
    font-weight: bold;
    justify-content: center;
   }


   .prefs-preferred {
    background-color: #72f07b;
   }

   .prefs-available {
    background-color: #bff8c3;
   }

   .prefs-avoid {
    background-color: #f8c3bf;
   }

  .prefs-dialysis {
    background-color: #f29791;
   }


scroll-shadow {
  overflow: auto;
  width: 100%;
	display: inline-block;
	--scroll-shadow-size: 14;
	--scroll-shadow-left: linear-gradient(to left, transparent 0%, #0003 100%);
	--scroll-shadow-right:  linear-gradient(to right, transparent 0%, #0003 100%);
}





  

</style>
