<template>
  <modal-section
    modalId="override-mapping-modal"
    ref="overrideMappingModal"
    class="modal-sticky-header"
    modalClass="modal-content modal-danger"
    :centered="true"
    @hide="finishConfirm()"
  >
    <template v-slot:title>
      {{ $t('hla_typing_override_mapping_heading') }}
    </template>
    <template v-slot:body>
      <!-- Exception warning text -->
      <div class="row">
        <div class="col-md-12">
          <p>
            <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
            {{ $t('hla_typing_override_mapping_warning') }}
          </p>
          <p>
            {{ $t('hla_typing_override_mapping_explanation') }}
          </p>
          <p>
            {{ $t('hla_typing_override_mapping_note_about_molecular') }}
          </p>
        </div>
      </div>

      <hr>

      <!-- Exception incidents -->
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <vue-good-table
              style-class="vgt-table table table-sm table-borderless"
              :columns="tableConfig.columns"
              :rows="tableConfig.data"
              :sortOptions="tableConfig.sortOptions"
            >
              <template slot="table-row" slot-scope="props">
                <template v-if="props.column.field == 'molecular_locus'">
                  <!-- Molecular locus -->
                  <strong>{{props.row.molecular_locus}}*</strong>
                </template>
                <template v-if="props.column.field == 'molecular_value'">
                  <!-- Molecular -->
                  <hla-input
                    v-model="props.row.molecular_value"
                    :input-id="`typing-details-${props.row.molecular_locus}-${props.row.originalIndex}-molecular`"
                    class="form-group border-0 p-0 mb-2"
                    input-class="hla-typing border-0"
                    :name="props.row.molecular_locus"
                    :hide-label="true"
                    :readonly="true"
                    :max-tags="1"
                    :typing="true"
                  />
                </template>
                <template v-else-if="props.column.field == 'most_likely_allele'">
                  <!-- Most Likely Allele -->
                  <hla-input
                    v-model="props.row.most_likely_allele"
                    :input-id="`typing-details-${props.row.molecular_locus}-${props.row.originalIndex}-most_likely_allele`"
                    class="form-group"
                    input-class="hla-typing border-0"
                    :hide-label="true"
                    :name="props.row.molecular_locus"
                    :readonly="true"
                    :max-tags="1"
                    :typing="true"
                  />
                </template>
                <template v-if="props.column.field == 'serologic_locus'">
                  <!-- Serologic locus -->
                  <strong>{{props.row.serologic_locus}}</strong>
                </template>
                <template v-else-if="props.column.field == 'serologic_value'">
                  <!-- Serologic -->
                  <hla-input
                    v-model="props.row.serologic_value"
                    :input-id="`typing-details-${props.row.molecular_locus}-${props.row.originalIndex}-serologic`"
                    class="form-group duo-col"
                    input-class="hla-typing border-0"
                    :name="props.row.molecular_locus || props.row.serologic_locus"
                    :hide-label="true"
                    :readonly="true"
                    :typing="true"
                    :invalid-antibodies="props.row.invalidSerologicValues"
                  />
                </template>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <button type="button" data-dismiss="modal" class="btn btn-secondary mr-auto">
        {{$t('cancel')}}
      </button>
      <a class="btn btn-success" @click="beginConfirm()">
        {{$t('continue')}}
      </a>
    </template>
  </modal-section>
</template>

<i18n src="./_locales/OverrideMappingModal.json"></i18n>

<script lang="ts">
import { SaveResult } from "@/types";
import { TableConfig } from '@/types';
import { Getter, State } from 'vuex-class';
import { VueGoodTable } from 'vue-good-table';
import { Component, Vue } from "vue-property-decorator";
import HlaInput from '@/components/shared/HlaInput.vue';
import ModalSection from '@/components/shared/ModalSection.vue';
import { HlaTypingForm, HlaTypingDetailsForm, HlaTypingDetailRow } from '@/components/hla/HlaTyping.vue';

interface HlaOverrideMappingRow {
  sequence_number?: number;
  molecular_locus?: string;
  molecular_value?: string[];
  most_likely_allele?: string[];
  serologic_locus?: string;
  serologic_value?: string[];
  invalidSerologicValues?: string[],
}

@Component({
  components: {
    HlaInput,
    ModalSection,
    VueGoodTable,
  }
})
export default class OverrideMappingModal extends Vue {
  // Check HLA Typing form edit state directly
  @State(state => state.pageState.currentPage.hlaTyping) editState!: HlaTypingForm;

  // Getters
  @Getter('parseLowResValues', { namespace: 'labs' }) parseLowResValues!: (moleculars: string[], mostLikelyAlleles: string[]) => string[];

  // Local component state
  private error: SaveResult|null = null;
  private confirmed = false;

  // Rows of HLA typing antigen detail incidents
  get hlaTypingDetailRows(): HlaTypingDetailRow[] {
    // Get exceptions from stored error
    const exceptions = (this.error || {}).warningExceptions || [];
    if (exceptions.length === 0 || !this.editState.hla_typing_details) {
      return [];
    }
    // This assumes that handling the first exception is sufficient
    const firstException: { rule: string, incidents: { molecular_locus: string, sequence_number: number }[] } = exceptions[0];
    // Get all HLA typing detail row incidents from the exception
    const incidents = firstException.incidents || [];
    // Get current detail row data from the edit state
    const details: HlaTypingDetailsForm = this.editState.hla_typing_details;
    // Fetch the detail rows based on incident identifiers
    const rows: HlaOverrideMappingRow[] = [];
    incidents.forEach((incident: { molecular_locus: string, sequence_number: number }) => {
      const detail: HlaTypingDetailRow = details[incident.molecular_locus][incident.sequence_number];
      const row: HlaOverrideMappingRow = this.buildRow(detail);
      rows.push(row);
    });
    return rows;
  }

  // Generate row for the Override Mapping Modal based on existing HLA Typing form edit state
  private buildRow(detail?: HlaTypingDetailRow): HlaOverrideMappingRow {
    if (!detail) return {};

    const result: HlaOverrideMappingRow = {
      sequence_number: detail.sequence_number,
      molecular_locus: detail.molecular_locus,
      most_likely_allele: detail.most_likely_allele,
      serologic_locus: detail.serologic_locus,
      serologic_value: detail.serologic_value,
      // Molecular values cannot be saved independently from the Most Likely Allele, so they will basically be
      // 'auto-corrected' as part of the saving action. Here we ensure that such derived values are what will
      // be displayed to the user here for confirmation.
      molecular_value: this.parseLowResValues(detail?.molecular_value || [], detail?.most_likely_allele || []),
      // Here we assume that every serological value should be shown as invalid. This is because the API only
      // tells us which sequence has a mismatch, but doesn't indicate which serologic value caused it.
      invalidSerologicValues: detail.serologic_value,
    };
    return result;
  }

  // Configure the table in modal body
  get tableConfig(): TableConfig {
    return {
      data: this.hlaTypingDetailRows,
      columns: [
        { label: '', field: 'molecular_locus', width: '5%' },
        { label: this.$t('molecular').toString(), field: 'molecular_value', width: '30%' },
        { label: this.$t('most_likely_allele').toString(), field: 'most_likely_allele', width: '30%' },
        { label: '', field: 'serologic_locus', width: '5%' },
        { label: this.$t('serologic').toString(), field: 'serologic_value', width: '30%' },
      ],
      // Disable unused sorting feature, because Vue Good Table has sorting enabled by default
      sortOptions: {
        enabled: false,
      },
    };
  }

  // Store 'error' details, reset 'confirmed' boolean, and show modal
  public showException(error: SaveResult): void {
    this.error = error;
    this.confirmed = false;
    const targetModal = this.$refs.overrideMappingModal as ModalSection;
    targetModal.toggleModal();
  }

  // Update 'confirmed' boolean, and start hiding modal
  public beginConfirm(): void {
    this.confirmed = true;
    const targetModal = this.$refs.overrideMappingModal as ModalSection;
    targetModal.toggleModal();
  }

  /**
   * Finally emit the 'confirmed' event with the specific 'error' details being confirmed
   * 
   * This is connected to the modal component's 'hide' event, to ensure that we bubble up the
   * 'confirmed' event to this component's parent only after this modal has finished hiding.
   * 
   * This prevents an issue caused if the event where to be emitted immediately, because when
   * the parent component attempts to re-render at the same time that the bootstrap model is
   * attempting the 'unlock' its background container the page can enter an editable state.
   * 
   * Emitting this event only after the modal has finished disappearing ensures that the
   * parent component can do whatever it needs to immediately upon receiving the event.
   */
  public finishConfirm(): void {
    if (this.confirmed) {
      this.$emit('confirmed', this.error);
    }
  }
}

</script>

