<template>
  <div class="nav-block-wrap">
    <ul class="nav nav-block flex-column" :class="{ active: active }">
      <li class="nav-item">
        <a class="nav-link" :href="getUrl('hla-typing')">
           <font-awesome-icon :icon="['fas', (show ? 'chevron-down' : 'chevron-right')]" class="nav-caret" fixed-width @click.prevent="toggleNav"/>    
          {{ $t("hla_information") }}
          </a>
      </li>
      <ul class="nav flex-column" v-if="show">
        <li class="nav-item" @click="toggleSideNav">
          <a class="nav-link" :href="getUrl('hla-typing')">{{ $t("typing") }}</a>
        </li>
        <li class="nav-item" @click="toggleSideNav" v-if="!prototypeFeatureEnabled('demo_hide_serum_crossmatch')">
          <a class="nav-link" :href="getUrl('serum-crossmatch-results')">{{ $t("serum-crossmatch-results") }}</a>
        </li>
      </ul>
    </ul>
  </div>
</template>

<i18n src="../_locales/SideNav.json"></i18n>
<i18n src="../_locales/commonPatientShared.json"></i18n>


<script lang="ts">
import { State, Getter } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DeceasedDonor } from '@/store/deceasedDonors/types';

@Component
export default class SelectedDeceasedDonorHlaLinks extends Vue {
  @State(state => state.deceasedDonors.selected) private donor!: DeceasedDonor;
  @Getter('clientId', { namespace: 'deceasedDonors' }) clientId!: string|undefined;
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;

  @Prop({default: true}) open!: boolean;
  @Prop({default: false}) active!: boolean;

  public show = true;

  public toggleNav() {
    this.show = !this.show;
  }

  public toggleSideNav(): void {
    if (window.innerWidth < 992) { 
       this.$emit("toggle");
    }
  }

  public getUrl(hash: string): string {
    hash = `#${hash}`;
    if(this.active) {
      return hash;
    } else {
      // We are fine not checking for the existence of client_id as for new recipients we only ever show this in active mode
      const location = this.$router.resolve({ name: 'edit-deceased-donor', params: { id: (this.clientId || '') }, hash: hash });
      return location.href;
    }
  }
}
</script>
