import { render, staticRenderFns } from "./_AddRecipientModal.vue?vue&type=template&id=fb139bfe"
import script from "./_AddRecipientModal.vue?vue&type=script&lang=ts"
export * from "./_AddRecipientModal.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/components/allocations/_locales/_AddRecipientModal.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FlivingAllocations%2F_AddRecipientModal.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports