<template>
  <card-section
    section-id="kidney-specific-details"
    :key="journeyId"
    :lookups-to-load="lookupsToLoad"
    @loaded="loaded()"
    :isAccordion="prototypeFeatureEnabled('proto_ability_to_expand_collapse')"
  >
    <template v-slot:header>
      {{$t('kidney_specific_details')}}
    </template>
    <template v-slot:body v-if="editState">
      <sub-section
        :title="$t('general_kidney_details')"
        sub-section-id="ksd-general"
        :saveButton="canEdit"
        :disabled="!canSave || newJourney"
        :saveButtonText="$t('save_kidney_specific_details')"
        @save="savePatch()"
        ref="saveKidneySpecificDetails"
      >
        <template v-slot:contents>
          <template v-if="!editState || !editState.generalInfo">
            {{$t('loading')}}
          </template>
          <fieldset v-else :disabled="!canSave || newJourney">
            <div class="row">
              <div v-if="isKidneyGeneralExtendedDetailsEnabled" class="standard-form-group">
                <checkbox-input
                  input-id="ksd-staged-kidney-checkbox"
                  :labelName="$t('staged_kidney')"
                  v-model="editState.generalInfo.stagedKidney"
                  :label="$t('yes')"
                />
              </div>
              <div class="standard-form-group">
                <number-input
                  min="0"
                  step="1"
                  input-id="ksd-serum-creatinine"
                  :name="$t('serum_creatinine')"
                  v-model="editState.generalInfo.serumCreatinine"
                />
              </div>
              <div v-if="isKidneyGeneralExtendedDetailsEnabled" class="standard-form-group">
                <date-input
                  :crossValues="{ serumCreatinineValue: editState.generalInfo.serumCreatinine }"
                  rules='required_if_filled:@serumCreatinineValue'
                  :name="$t('serum_creatinine_date')"
                  input-id="ksd-serum-creatinine-date"
                  v-model="editState.generalInfo.serumCreatinineDate"
                />
              </div>
              <div class="standard-form-group">
                <number-input
                  min="0"
                  step="1"
                  input-id="ksd-egfr"
                  :name="$t('egfr')"
                  v-model="editState.generalInfo.egfr"
                />
              </div>
              <div v-if="isKidneyGeneralExtendedDetailsEnabled" class="standard-form-group">
                <date-input
                  :crossValues="{ egfrValue: editState.generalInfo.egfr }"
                  rules='required_if_filled:@egfrValue'
                  :name="$t('egfr_date')"
                  input-id="ksd-egfr-date"
                  v-model="editState.generalInfo.egfrDate"
                />
              </div>
              <div class="row-break d-none d-xxxl-block"></div>
              <div v-if="isKidneyGeneralExtendedDetailsEnabled" class="standard-form-group">
                <text-input
                  :name="$t('a1c')"
                  input-id="ksd-a1c"
                  v-model="editState.generalInfo.a1c"
                />
              </div>
              <div v-if="isKidneyGeneralExtendedDetailsEnabled" class="standard-form-group">
                <date-input
                  :crossValues="{ a1cValue: editState.generalInfo.a1c }"
                  rules='required_if_filled:@a1cValue'
                  :name="$t('a1c_date')"
                  input-id="ksd-a1c-date"
                  v-model="editState.generalInfo.a1cDate"
                />
              </div>
              <div class="standard-form-group-large">
                <text-area-input
                  input-id="ksd-comments"
                  :name="$t('general_comments')"
                  v-model="editState.comments"
                />
              </div>
            </div>
            <div class="hr-break" />
            <div class="row">
              <div class="standard-form-group">
                <checkbox-input
                  input-id="ksd-kidney-paired"
                  :labelName="$t('kidney_paired_donation')"
                  v-model="editState.recipientPrograms.kidneyPaired"
                  :label="$t('yes')"
                />
              </div>
              <div class="row-break d-none d-sm-block d-lg-none"></div>
              <div class="standard-form-group">
                <checkbox-input
                  input-id="ksd-list-exchange"
                  :labelName="$t('list_exchange')"
                  v-model="editState.recipientPrograms.listExchange"
                  :label="$t('yes')"
                />
              </div>
              <div class="standard-form-group">
                <date-input
                  :rules="'required_if:@listExchange'"
                  :name="$t('list_exchange_date')"
                  input-id="ksd-list-exchange-date"
                  :disabled="!editState.recipientPrograms.listExchange"
                  v-model="editState.recipientPrograms.listExchangeDate"
                />
              </div>
              <div class="row-break d-none d-xxl-block d-xxxl-none"></div>
              <div class="standard-form-group">
                <checkbox-input
                  input-id="ksd-previous-living-donor"
                  :labelName="$t('prev_living_donor')"
                  v-model="editState.recipientPrograms.previousLivingDonor"
                  :label="$t('yes')"
                  @change="clearDonationDate()"
                />
              </div>
              <div v-if="isKidneyGeneralExtendedDetailsEnabled" class="standard-form-group">
                <date-input
                  :rules="'required'"
                  :name="$t('donation_date')"
                  input-id="ksd-donation-date"
                  :disabled="!editState.recipientPrograms.previousLivingDonor"
                  v-model="editState.recipientPrograms.donationDate"
                />
              </div>
            </div>
            <div class="hr-break" />
            <sub-section :title="$t('dialysis')" sub-section-id="ksd-dialysis">
              <template v-slot:contents>
                <div class="row">
                  <div class="standard-form-group">
                    <checkbox-input
                      input-id="pre-emptive-gfr"
                      :labelName="$t('pre_emptive_dialysis_exemption')"
                      v-model="editState.generalInfo.preEmptiveGfr"
                      :label="$t('yes')"
                    />
                  </div>
                </div>
              </template>
            </sub-section>
          </fieldset>
        </template>
      </sub-section>
      <dialysis-section
        ref="dialysisSection"
        :new-journey="newJourney"
        :canSave="isGroupWriteable('journey_medical')"
        @saving="saving"
        @handleErrors="handleErrors"
        :divider="showOverridePaediatricAgeSection"
        @reloadWaitlistSummary="reloadWaitlistSummary"
      />
      <override-paediatric-age
        v-if="showOverridePaediatricAgeSection && isPaediatricAgeEnabled"
        ref="overridePaediatricAge"
        :new-journey="newJourney"
        @saving="saving"
        @handleErrors="handleErrors"
      />
    </template>
  </card-section>
</template>

<i18n src="@/components/organs/kidney/_locales/KidneySpecificDetails.json"></i18n>
<i18n src="@/components/organs/shared/_locales/common.json"></i18n>

<script lang="ts">
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-class';
import { SystemModules } from '@/store/features/types';
import TextInput from '@/components/shared/TextInput.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import SelectInput from '@/components/shared/SelectInput.vue';
import CardSection from '@/components/shared/CardSection.vue';
import NumberInput from '@/components/shared/NumberInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { RecipientJourney } from '@/store/recipientJourney/types';
import { KidneyDetails } from '@/store/organSpecificDetails/types';
import { RecipientPrograms } from '@/store/recipientJourney/types';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import SelectOtherInput from '@/components/shared/SelectOtherInput.vue';
import { Recipient } from '@/store/recipients/types';
import DialysisSection, { DialysisForm } from '@/components/organs/kidney/DialysisSection.vue';
import OverridePaediatricAge, { OverridePaediatricAgeForm } from '@/components/organs/kidney/OverridePaediatricAge.vue';

export interface KidneySpecificPageState {
  generalInfo: KidneySpecificDetailsForm;
  dialysis: DialysisForm;
  overridePaediatricAge: OverridePaediatricAgeForm;
  recipientPrograms: RecipientProgramForm;
  comments?: string;
}

interface KidneySpecificDetailsForm {
  stagedKidney?: boolean;
  serumCreatinine?: number;
  serumCreatinineDate?: string;
  egfr?: number;
  egfrDate?: string;
  a1c?: number;
  a1cDate?: string;
  preEmptiveGfr?: boolean;
}

interface RecipientProgramForm {
  kidneyPaired?: boolean;
  listExchange?: boolean;
  listExchangeDate?: string;
  previousLivingDonor?: boolean;
  donationDate?: string;
}

@Component({
  components: {
    TextInput,
    TextAreaInput,
    DateInput,
    SubSection,
    SelectInput,
    CardSection,
    NumberInput,
    CheckboxInput,
    DialysisSection,
    SelectOtherInput,
    OverridePaediatricAge,
  }
})
export default class KidneySpecificDetails extends mixins(DateUtilsMixin) implements SaveableSection {
  // State
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.kidneyDetails) editState!: KidneySpecificPageState;

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;
  @Getter("moduleEnabled", { namespace: "features" }) private moduleEnabled!: (module: string) => boolean;
  @Getter('canOverridePaediatricAge', { namespace: 'organSpecificDetails' }) canOverridePaediatricAge!: boolean;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;
  public lookupsToLoad: string[] = ['dialysis_type', 'dialysis_access_mode', 'dialysis_location_type', 'dialysis_providers'];

  /**
   * Return true if kidney specifc details section can be edited
   *
   * cannot be edited if new journey
   * cannot be edited if journey is completed
   *
   * @returns {boolean} true if we can edit
   */
  get canEdit(): boolean{
    if (this.newJourney || this.journey.completed) {
      return false;
    }
    return true;
  }

  // is the Paediatric Age enabled
  get isPaediatricAgeEnabled(): boolean {
    return this.moduleEnabled(SystemModules.KIDNEY_PAEDIATRIC_AGE_OVERRIDE);
  }

  // Whether or not kidney journey has value(s) in any of the override-related fields
  get hasPaediatricAgeOverride(): boolean {
    if (this.newJourney || !this.journey) return false;

    const kidneySpecificDetails: KidneyDetails|null = this.journey?.organ_specific_details || null;
    if (!kidneySpecificDetails) return false;

    return kidneySpecificDetails.override_pediatric_age
        || !!kidneySpecificDetails.override_start_date
        || !!kidneySpecificDetails.override_end_date
        || !!kidneySpecificDetails.override_comments;
  }

  get showOverridePaediatricAgeSection(): boolean {
    return this.canOverridePaediatricAge || this.hasPaediatricAgeOverride;
  }

  // clear Donation Date when Previous Living Donor is changed
  public clearDonationDate() {
    Vue.set(this.editState.recipientPrograms, "donationDate", undefined);
  }

  /**
    * Emits a loaded event after all subcomponents have finished loading.
    *
    * The Heart Specific Details card section emits a loaded event when
    * it finishes loading lookup tables (if any).
    *
    * @listens saveKidneySpecificDetails#loaded
    * @emits loaded
    */
  public loaded(): void {
    this.initializeForm();
    this.$emit('loaded', 'kidneyDetails');
  }

  // Is the general kidney general extended details system module enabled?
  get isKidneyGeneralExtendedDetailsEnabled(): boolean {
    return this.moduleEnabled(SystemModules.KIDNEY_GENERAL_EXTENDED_DETAILS);
  }

  /**
    * Populates the Kidney Specific Details form state with data from
    * the selected Journey.  Also triggers loading dialysis for the selected recipient.
    * Note: Dialysis entires exist on the recipient level and are not specific to a journey.
    */
  public initializeForm(): void {
    const overridePaediatricAgeComponent = (this.$refs.overridePaediatricAge as OverridePaediatricAge);
    this.$store.commit('pageState/set', {
      pageKey: 'kidneyDetails',
      value: {
        generalInfo: this.extractKidneySpecificDetails(this.journey),
        dialysis: {},
        overridePaediatricAge: overridePaediatricAgeComponent ? overridePaediatricAgeComponent.buildOverridePaediatricAgeFormState() : {},
        recipientPrograms: this.extractRecipientProgramDetails(this.journey),
        comments: (this.journey || {}).comments
      }
    });
    // Fetch Dialysis entires for the selected recipient
    this.$store.dispatch('recipients/get', this.recipientId);
    this.$store.dispatch('recipients/loadDialysis', this.recipientId);
  }

  /**
   * Generates Recipient Program form state based on the selected journey
   *
   * @param journey current selected jounrey
   * @returns {RecipientProgramForm} Recipient Program form state
   */
  public extractRecipientProgramDetails(journey: RecipientJourney): RecipientProgramForm {
    if (!journey) {
      return {};
    }
    const recipientPrograms = journey.recipient_programs
      ? (journey.recipient_programs as RecipientPrograms)
      : {};
    return {
      kidneyPaired: recipientPrograms ? recipientPrograms.kidney_paired_donation : undefined,
      listExchange: recipientPrograms ? recipientPrograms.list_exchange : undefined,
      listExchangeDate: recipientPrograms.list_exchange_date ? this.parseDateUi(recipientPrograms.list_exchange_date) : undefined,
      previousLivingDonor: recipientPrograms ? recipientPrograms.previous_living_donor : undefined,
      donationDate: recipientPrograms.previous_living_donation_date ? this.parseDateUi(recipientPrograms.previous_living_donation_date) : undefined
    };
  }

  /**
   * Generates Kidney Specific Details form state based on the selected journey
   *
   * @param journey current selected jounrey
   * @returns {KidneySpecificDetailsForm} Kidney Specific Details form state
   */
  public extractKidneySpecificDetails(journey: RecipientJourney): KidneySpecificDetailsForm {
    if (!journey) {
      return {};
    }
    const organSpecificDetails = journey.organ_specific_details as KidneyDetails;
    return {
      stagedKidney: organSpecificDetails.staged_kidney,
      serumCreatinine: organSpecificDetails.creatinine,
      serumCreatinineDate: organSpecificDetails.creatinine_date ? this.parseDateUi(organSpecificDetails.creatinine_date) : undefined,
      egfr: organSpecificDetails.egfr,
      egfrDate: organSpecificDetails.egfr_date ? this.parseDateUi(organSpecificDetails.egfr_date) : undefined,
      a1c: organSpecificDetails.a1c,
      a1cDate: organSpecificDetails.a1c_date ? this.parseDateUi(organSpecificDetails.a1c_date) : undefined,
      preEmptiveGfr: organSpecificDetails.pre_emptive_gfr
    };
  }

  /**
   * Saves the form edit state.
   *
   * Prepares an update payload for Kidney Specific Details,
   * dispatches a save action, and registers the save result.
   */
  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveKidneySpecificDetails as unknown as SaveProvider;
    // Clear validation errors
    this.$emit('clear');
    // Report to parent that saving has began
    this.$emit('save', 'saveKidneySpecificDetails');
    // Generate payload based on current edit state
    const payload = {
      recipientId: this.recipientId,
      journeyId: this.journeyId,
      journey: this.extractPatch()
    };
    // Dispatch save action and register the response
    this.$store.dispatch('journeyState/saveJourney', payload)
      .then((success: SaveResult) => {
        // If successful, reload the current recipient
        this.$store.dispatch('recipients/get', this.recipientId);
        // If successful, update the current recipient and show success notification
        this.$store.commit('journeyState/setJourney', success.responseData.journey);
        saveProvider.registerSaveResult(success);
      }).catch((error: SaveResult) => {
        // Show error notification
        saveProvider.registerSaveResult(error);
        // Emit event to handle errors
        this.$emit('handleErrors', error);
      });
  }

  /**
   * Gets changes from the editState as a patch for the journey's Kidney Specific Details
   *
   * If the edit state doesn't exist return {}
   *
   * @returns {any} object containing field changes
   */
  public extractPatch(): any {
    if (!this.editState || !this.editState.generalInfo) {
      return {};
    } else {
      return this.extractKidneySpecificDetailsPatch(this.editState.generalInfo, this.editState.recipientPrograms);
    }
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    // Note: We are not resetting measurements here because the parent saving does not save measurements.
    const gci = this.$refs.saveKidneySpecificDetails as unknown as SaveProvider;
    // Reset the save provider's save toolbar
    gci.resetSaveToolbar();
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    // General Kidney Details
    const result = {
      'comments'                                         : 'ksd-comments',
      'organ_specific_details.pre_emptive_gfr'           : 'pre-emptive-gfr',
      'organ_specific_details.dialysis_procedures'       : 'pre-emptive-gfr',
      'organ_specific_details.staged_kidney'             : 'ksd-staged-kidney-checkbox',
      'organ_specific_details.creatinine'                : 'ksd-serum-creatinine',
      'organ_specific_details.creatinine_date'           : 'ksd-serum-creatinine-date',
      'organ_specific_details.egfr'                      : 'ksd-egfr',
      'organ_specific_details.egfr_date'                 : 'ksd-egfr-date',
      'organ_specific_details.a1c'                       : 'ksd-a1c',
      'organ_specific_details.a1c_date'                  : 'ksd-a1c-date',
      'recipient_programs.kidney_paired_donation'        : 'ksd-kidney-paired',
      'recipient_programs.list_exchange'                 : 'ksd-list-exchange',
      'recipient_programs.list_exchange_date'            : 'ksd-list-exchange-date',
      'recipient_programs.previous_living_donor'         : 'ksd-previous-living-donor',
      'recipient_programs.previous_living_donation_date' : 'ksd-donation-date',
    };

    // Dialysis Section
    const dialysisSection = this.$refs.dialysisSection as DialysisSection;
    if (dialysisSection) {
      Object.assign(result, { ...dialysisSection.idLookup });
    }

    // Override Paediatric Age Section
    const overridePaediatricAge = this.$refs.overridePaediatricAge as OverridePaediatricAge;
    if (overridePaediatricAge) {
      Object.assign(result, { ...overridePaediatricAge.idLookup() });
    }

    return result;
  }

  /**
   * Get changes from the edit state as a patch for the journey's Kidney Specific Details
   * and Recipient Programs
   *
   * Changes to organ_specific_details and recipient_programs for this journey
   *
   * @param kidneySpecificDetails current state of kidney specific details
   * @param recipientPrograms current state of recipient programs
   * @returns {RecipientJourney} patch object containing changes
   */

  private extractKidneySpecificDetailsPatch(kidneySpecificDetails: KidneySpecificDetailsForm, recipientPrograms: RecipientProgramForm): RecipientJourney {

    return {
      comments: this.editState.comments,
      organ_specific_details: {
        staged_kidney: kidneySpecificDetails.stagedKidney,
        creatinine: kidneySpecificDetails.serumCreatinine == null  ? undefined : kidneySpecificDetails.serumCreatinine,
        creatinine_date: this.sanitizeDateApi(kidneySpecificDetails.serumCreatinineDate) ? this.sanitizeDateApi(kidneySpecificDetails.serumCreatinineDate) : null,
        egfr: kidneySpecificDetails.egfr == null ? undefined : kidneySpecificDetails.egfr,
        egfr_date: this.sanitizeDateApi(kidneySpecificDetails.egfrDate) ? this.sanitizeDateApi(kidneySpecificDetails.egfrDate) : null,
        a1c: kidneySpecificDetails.a1c == null ? undefined : kidneySpecificDetails.a1c,
        a1c_date: this.sanitizeDateApi(kidneySpecificDetails.a1cDate) ? this.sanitizeDateApi(kidneySpecificDetails.a1cDate) : null,
        pre_emptive_gfr: kidneySpecificDetails.preEmptiveGfr
      },
      recipient_programs: {
        kidney_paired_donation: recipientPrograms.kidneyPaired ? true : false,
        list_exchange: recipientPrograms.listExchange,
        list_exchange_date: this.sanitizeDateApi(recipientPrograms.listExchangeDate) || null,
        previous_living_donor: recipientPrograms.previousLivingDonor,
        previous_living_donation_date: this.sanitizeDateApi(recipientPrograms.donationDate)
      }
    };
  }

  // Emit event to parent so it can handle validations
  private handleErrors(errors: any) {
    this.$emit('handleErrors', errors);
  }

  // Emit event to parent to reload Waitlist Summary Section
  private reloadWaitlistSummary() {
    this.$emit('reloadWaitlistSummary');
  }

  // Emit event to parent so it can handle clearing validations when saving
  private saving(formReference: string) {
    this.$emit('saving', formReference);
  }
}
</script>
