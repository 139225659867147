<template>
  <card-section section-id="living_donor_phase_status"
    :isAccordion="prototypeFeatureEnabled('proto_ability_to_expand_collapse')">
    <template v-slot:header>
      {{ title }}
    </template>
    <template v-slot:body>
      <div class="row">
        <div class="col-sm-12">
          <sub-section 
            :title='title' 
            sub-section-id="living_donor_phase_status_table"
            :table-config="tableConfig"
            tabbableValue="Date"
            @table-row-click="editSelected"
            @table-create-row="clearForm"
            row-style-class="tr-link"
            @on-row-click="editSelected">
  
          </sub-section>
          <sub-section ref="statusSection" :title="editstate ? 'Edit Status' : 'New Status'"
            sub-section-id="living_donor_phase_status_form" saveButton="true"
            :save-button-text="editstate ? `${$t('Update Journey')}` : `${$t('Save Journey Status')}` "
            @save="uploadDocument()">
            <template v-slot:contents>
              <fieldset>
                <div class="row">
                  <div class="standard-form-group">
                    <select-input select-id="recipientAttachment-category" rules="required" :name='$t("Phase")'
                      :options="phaseOptions" v-model="phase">
                    </select-input>
                  </div>
                  <div class="standard-form-group">
                    <select-input select-id="recipientAttachment-category" rules="required" :name='$t("Status")'
                      :options="statusOptions" v-model="status">
                    </select-input>
                  </div>
                  <div class="standard-form-group">
                    <select-input select-id="recipientAttachment-category" :name='$t("Reason")'
                      :options="reasonOptions" v-model="reason">
                    </select-input>
                  </div>
                  <div class="standard-form-group">
                    <select-input select-id="recipientAttachment-category" rules="required" :name='$t("Entered By")'
                      :options="userOptions" v-model="entered_by">
                    </select-input>
                  </div>
                  <div class="row-break d-none d-xl-block"></div>
                  <div class="standard-full-width-group">
                    <text-area-input input-id="recipientAttachment-description" :name='$t("Comment")' rows="4"
                      v-model="comment">
                    </text-area-input>
                  </div>
                </div>

              </fieldset>
            </template>

          </sub-section>

        </div>
      </div>

    </template>
  </card-section>
</template>

<i18n src="./_locales/PhaseStatus.json"></i18n>

<script lang="ts">
  import {
    Getter,
    State,
  } from 'vuex-class';
  import {
    mixins
  } from "vue-class-component";
  import {
    Component,
    Vue, Prop
  } from 'vue-property-decorator';
  import CardSection from "@/components/shared/CardSection.vue";
  import SaveToolbar from '@/components/shared/SaveToolbar.vue';
  import {
    VueGoodTable
  } from 'vue-good-table';
  import SelectInput from '@/components/shared/SelectInput.vue';
  import CheckboxInput from '@/components/shared/CheckboxInput.vue';
  import TextInput from '@/components/shared/TextInput.vue';
  import TextAreaInput from '@/components/shared/TextAreaInput.vue';
  import SubSection from '@/components/shared/SubSection.vue';
  import {
    TableConfig
  } from '@/types';
  import {
    Recipient
  } from "@/store/recipients/types";
  import {
    UserDetails
  } from '@/store/userAccounts/types';
  import {
    DateUtilsMixin
  } from "@/mixins/date-utils-mixin";
  import { GenericCodeValue } from '@/store/types';

  interface TableRow {
    id: string;
    category ? : string;

    date ? : string;
    fileName ? : string;

    fileType ? : string;
    description ? : string;
    uploadedBy ? : string;
  }

  @Component({
    components: {
      CardSection,
      SaveToolbar,
      SubSection,
      SelectInput,
      TextInput,
      CheckboxInput,
      TextAreaInput,
      VueGoodTable
    }
  })
  export default class RecipientDocuments extends mixins(DateUtilsMixin) {

    @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
    @State(state => state.userAccounts.selected) private selected!: UserDetails;
    @State(state => state.pageState.currentPage.journeyStatus) editState!: any;

    @Prop({ default: null }) columns!: any;
    @Prop({ default: null }) tableData!: any;
    @Prop({ default: null }) title!: string;
    @Prop({ default: null }) localStorageRef!: string;
    @Getter('getUser', {
      namespace: 'users'
    }) private getUser!: any;
    @Getter('prototypeFeatureEnabled', {
      namespace: 'features'
    }) private prototypeFeatureEnabled!: (featureName: string) => boolean;

    @Getter('optionsFor', { namespace: 'lookups' }) optionsFor!: (items: string[]) => GenericCodeValue[];

    loaded() {
      // Parse tasks from local storage if found, otherwise default to JSON file contents
      let formState;
      const defaultState = {
        attachments: this.tableData.rows
      };
      const items: any = localStorage.getItem(this.localStorageRef);
      if (items) {
        try {
          formState = JSON.parse(items);
        } catch (error) {
          console.warn('Warning: Cannot parse tasklist from local storage, setting default tasks.', error);
          formState = defaultState;
        }
      } else {
        formState = defaultState;
      }
      this.$store.commit("pageState/set", {
        pageKey: "journeyStatus",
        value: formState,
      });
      this.attachments = formState.attachments || [];
    }

    mounted() {
      this.loaded();
    }
    private id = null;
    private phase = '';
    private status = '';
    private reason = '';
    private entered_by = '';
    private comment = '';
    private isTableUpdating = false;
    private taskInProgress = false;
    public perPage = 10;
    public currentPageTable = 1;
    private attachments: any = [];


    private editstate = false

    get phaseOptions() {
      return this.optionsFor([
        'Submission',
        'Eligibility Assessment',
        'Recipient Match Assessment',
        'Donation Surgery',
        'Post-Donation',
      ]);
    }

    get statusOptions() {
      return this.optionsFor([
        'New',
        'Active',
        'On Hold',
        'Inactive',
        'Donated',
      ]);
    }

    get reasonOptions() {
      return this.optionsFor([
        'Donor Cleared',
        'Medically unsuitable — Temporary',
        'Psychosocial issue(s) — Temporary',
        'Pending investigations',
        'Potential living donor paired exchange transplant',
        'Potential living donor — Desensitization (ABO or HLA)',
        'Not available (away)',
        'Other',
        'Unknown',
      ]);
    }

    private userOptions = [{
      code: 0,
      value: 'S. Wood'
    }, {
      code: 1,
      value: 'S. McPhee'
    }, {
      code: 2,
      value: 'A. Johnson'
    }];


    get tableRows() {
      const attachments = this.attachments || [];
      return attachments.map((item: any) => {
        return {
          id: item.id,
          date: this.parseDisplayDateUi(item.date),
          phase: item.phase,
          phaseValue: item.phase ? this.getOptionValue(item.phase, this.phaseOptions) : '-',
          status: item.status,
          statusValue: item.status ? this.getOptionValue(item.status, this.statusOptions) : '-',
          reason: item.reason,
          reasonValue: item.reason ? this.getOptionValue(item.reason, this.reasonOptions) : '-',
          entered_by: item.entered_by,
          enteredByValue: item.entered_by ? this.getOptionValue(item.entered_by, this.userOptions): '-',
          comment: item.comment ? item.comment : "None"
        };
      });
    }
    get tableConfig(): TableConfig {
      return {
        data: this.tableRows,
        columns: this.columns,
        createButton: true,
        createText: `${this.$t('attach_new_document')}`,
        paginationOptions: {
          enabled: true,
          mode: 'records',
          perPage: this.perPage,
          perPageDropdown: [10, 25, 50],
          setCurrentPage: this.currentPageTable,
          dropdownAllowAll: true,
          nextLabel: '',
          prevLabel: '',
          rowsPerPageLabel: this.$t('table.results'),
        },
      };
    }

    private editSelected(row: any) {
      if (row) {
        this.id = row.row.id;
        this.editstate = true;
        this.phase = row.row.phase;
        this.status = row.row.status;
        this.reason = row.row.reason;
        this.entered_by = row.row.entered_by;
        this.comment = row.row.comment !== "None" ? row.row.comment : "";
      }
    }

    get emptyMessage(): string {
      if (!this.attachments) {
        return this.$t('loading').toString();
      } else {
        return this.$t('use_form_below').toString();
      }
    }

    get getUserFullName(): string | undefined {
      let user = this.getUser;
      return user.name;
    }

    public getOptionValue(item: any, options: any): string {
      const match = (options || []).find((option: any) => { return option.code == item; });
      if (!match) return '-';

      return match.value;
    }

    public clearForm(): any {
      this.id = null;
      this.phase = '';
      this.status = '';
      this.reason = '';
      this.entered_by = '';
      this.comment = '';
      this.$emit('clear');
      this.editstate = false;
    }

    public uploadDocument(): any {
      this.isTableUpdating = true;
      let attachments = this.attachments || [];

      if (!this.editstate) {
        const payload: any = {
          id: Math.random().toString(16).slice(2),
          date: new Date().toLocaleDateString('en-CA'),
          phase: this.phase,
          status: this.status,
          reason: this.reason,
          entered_by: this.entered_by,
          comment: this.comment
        };
        
        attachments.push(payload);
      } else {
        attachments.map((item: any) => {
          if (item.id === this.id) {
            item.phase = this.phase;
            item.reason = this.reason;
            item.status = this.status;
            item.entered_by = this.entered_by;
            item.comment = this.comment;
          }
        });

      }

      this.attachments = attachments;

      const defaultState = {
        attachments: attachments
      };

      localStorage.setItem(this.localStorageRef, JSON.stringify(defaultState));

      this.isTableUpdating = false;
      this.editstate = false;
      this.clearForm();
    }
  }

</script>

<style>


</style>
